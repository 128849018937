<div class="text-search-container">
    <nz-input-group nzSize="large" [nzPrefix]="prefixTemplateSearch">
        <input
            data-cy="text-search-input"
            (ngModelChange)="onSearchValueChanged($event)"
            [(ngModel)]="value"
            type="text"
            nz-input
            [placeholder]="placeholder"
        />
    </nz-input-group>
    <div
        data-cy="text-search-input__clear"
        [hidden]="!value"
        (click)="onClear()"
        class="search__clear"
    >
        <i nz-icon nzType="close" theme="outline"></i>
    </div>
</div>
<ng-template #prefixTemplateSearch><i nz-icon nzType="search"></i></ng-template>
