<div class="lca-model-data-csv-form-container">
    <nz-alert
        nzType="info"
        nzMessage="Please upload files."
        nzShowIcon
    ></nz-alert>
    <nz-alert
        *ngIf="warnMessage"
        nzType="warning"
        [nzMessage]="warnMessage"
        nzShowIcon
    ></nz-alert>
    <form
        name="csv-files-form"
        [formGroup]="csvFilesForm"
        nz-form
        action="none"
    >
        <div nz-row nzGutter="4" formArrayName="files">
            <div
                nz-col
                nzSpan="24"
                *ngFor="let csvFileForm of files.controls; let i = index"
                [formGroup]="csvFileForm"
            >
                <nz-form-item>
                    <nz-form-control nzSpan="24">
                        <div class="upload-file-item">
                            <i
                                class="upload-file-item__icon"
                                nz-icon
                                nzType="file-csv"
                            ></i>
                            <span class="upload-file-item__name">
                                {{ fileLabels[csvFileForm.get('type')?.value] }}
                            </span>
                            <ng-container>
                                <div class="file-name">
                                    {{ csvFileForm.get('fileName')?.value }}
                                </div>
                                <button
                                    *ngIf="
                                        csvFileForm.get('fileName')?.value &&
                                        (!started || cancelled || completed)
                                    "
                                    (click)="
                                        onFileRemove(
                                            csvFileForm.get('type').value
                                        )
                                    "
                                    type="button"
                                    nz-button
                                    nzSize="large"
                                    nzType="danger"
                                >
                                    Delete
                                </button>
                                <nz-spin
                                    *ngIf="started && !cancelled && !completed"
                                    nzSimple
                                ></nz-spin>
                                <nz-form-control
                                    *ngIf="!csvFileForm.get('fileName')?.value"
                                    nzValidateStatus
                                    nzHasFeedback
                                    nzListType="picture"
                                >
                                    <nz-upload
                                        [nzBeforeUpload]="
                                            beforeFileUpload(
                                                csvFileForm.get('type').value
                                            )
                                        "
                                        [nzFileList]="
                                            getFileByType(
                                                csvFileForm.get('type').value
                                            )
                                        "
                                    >
                                        <button
                                            class="attach-file-button"
                                            nz-button
                                            nzSize="large"
                                            nzType="primary"
                                            type="button"
                                        >
                                            Upload CSV
                                        </button>
                                    </nz-upload>
                                </nz-form-control>
                            </ng-container>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </form>
    <nz-progress
        *ngIf="started && !completed && !cancelled"
        [nzPercent]="progress"
        nzSize="large"
        nzStatus="active"
    ></nz-progress>
    <nz-alert
        *ngIf="error"
        nzType="error"
        [nzMessage]="error"
        nzShowIcon
    ></nz-alert>
    <div nz-col class="drawer-actions-control" nzSpan="24">
        <div class="drawer-controls">
            <button
                [disabled]="(started && !completed) || !areProvidedFilesValid"
                type="button"
                nzSize="large"
                nz-button
                (click)="onSave()"
                nzType="primary"
            >
                <span>Save Changes</span>
            </button>
            <button type="button" nzSize="large" nz-button (click)="onClose()">
                <span>Close</span>
            </button>
        </div>
    </div>
</div>
