import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    Output,
    Sanitizer,
    EventEmitter,
} from '@angular/core';
import { filter } from 'rxjs/operators';
import { UploadProgressService } from '../../services/upload-progress/upload-progress.service';

export enum FileTypes {
    CSV = 'csv',
    PDF = 'pdf',
    XLSX = 'xlsx',
    DOCX = 'docx',
    PPTX = 'pptx',
    PNG = 'png',
}

@Component({
    selector: 'app-upload-file-tile',
    templateUrl: './upload-file-tile.component.html',
    styleUrls: ['./upload-file-tile.component.less'],
})
export class UploadFileTileComponent implements OnInit {
    @Input() fileSize: number;
    @Input() fileType: string;
    @Input() fileName: string = '';
    @Input() hideDelete: boolean = false;

    @Output() delete: EventEmitter<any> = new EventEmitter<any>();

    progressWidth: string;

    started: boolean = false;
    cancelled: boolean = false;
    completed: boolean = false;

    constructor(
        private uploadProgressService: UploadProgressService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        const key = btoa(encodeURIComponent(this.fileName));
        this.uploadProgressService.upload$
            .pipe(filter((uploadInfo) => key === uploadInfo.key))
            .subscribe(({ progress, started, cancelled, completed }) => {
                this.started = started;
                this.cancelled = cancelled;
                this.completed = completed;

                this.progressWidth = `${progress}%`;
                this.cdr.detectChanges();
            });
    }

    get fileIconClassname(): string {
        if (this.fileType.includes('image/')) return FileTypes.PNG;
        if (
            [
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ].includes(this.fileType)
        )
            return FileTypes.DOCX;
        if (
            [
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
            ].includes(this.fileType)
        )
            return FileTypes.XLSX;
        if (this.fileType === 'application/pdf') return FileTypes.PDF;
        if (
            [
                'application/vnd.ms-powerpoint',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
            ].includes(this.fileType)
        )
            return FileTypes.PPTX;

        return FileTypes.CSV;
    }

    onDelete() {
        this.delete.emit();
    }

    onCancelUpload() {
        this.uploadProgressService.cancel(
            btoa(encodeURIComponent(this.fileName))
        );
    }
}
