<ng-container *ngIf="loading; else content">
    <div class="loading-container">
        <nz-spin nzSimple [nzSize]="'large'" nzTip="Loading..."></nz-spin>
    </div>
</ng-container>
<ng-template #content>
    <div class="lca-engine-client-database-add-spa-page-container">
        <div class="lca-engine-client-database-spa-control">
            <h2
                class="breadcrumb-back"
                [class.disabled]="!clientId"
                queryParamsHandling="merge"
                [routerLink]="['/lca-engine/client-database-spas']"
                [queryParams]="{ clientId }"
            >
                <i nz-icon nzType="arrow-left"></i>
                <span
                    data-cy="lca-engine-client-database-spa-add-control___back-btn"
                    >LCA Engine (Client SPAs)&nbsp;-&nbsp;
                    <span class="client-name">{{ client?.clientName }}</span>
                </span>
            </h2>
            <div class="lca-engine-client-database-spa-control__stepper">
                <nz-steps nzProgressDot [nzCurrent]="currentStepIndex">
                    <nz-step nzDisabled nzTitle="ActivitySerial"> </nz-step>
                    <nz-step nzDisabled nzTitle="Impact Categories"></nz-step>
                    <nz-step nzDisabled nzTitle="SPA"></nz-step>
                </nz-steps>
            </div>
        </div>
        <div
            class="lca-engine-client-database-add-spa-content_activity-serials"
            *ngIf="currentStepIndex === steps.ActivitySerial"
        >
            <ng-container *ngIf="!fromFile; else filesForm">
                <app-lca-activityserial-selection-form
                    (changed)="onActivitySerialsChanged($event)"
                ></app-lca-activityserial-selection-form>
            </ng-container>
        </div>
        <div
            class="lca-engine-client-database-add-spa-content_impact-categories"
            *ngIf="currentStepIndex === steps.ImpactCategories"
        >
            <app-lca-impact-categories-selection-form
                (changed)="onImpactCategoriesChanged($event)"
            >
            </app-lca-impact-categories-selection-form>
        </div>
        <div
            class="lca-engine-client-database-add-spa-content_spa"
            *ngIf="currentStepIndex === steps.SPA"
        >
            <h4 style="margin-top: 2rem" class="lca-sandbox-header">
                SPA Threshold Parameters
            </h4>
            <h5 class="lca-sandbox-subheader">Please fill out all boxes</h5>
            <form
                class="lca-sandbox-spa-threshold-parameters__form"
                name="lca-sandbox-spa-threshold-parameters-form"
                [formGroup]="spaThresholdParamsFormGroup"
                action="none"
            >
                <div nz-row nzGutter="4">
                    <div class="spa-parameters-form__input" nz-col nzSpan="12">
                        <nz-form-item>
                            <nz-form-label>
                                spa life cycle stage to render</nz-form-label
                            >
                            <nz-form-control nzValidateStatus nzHasFeedback>
                                <nz-input-group
                                    [nzSuffix]="suffixSPALifeCycleStageTemplate"
                                    nzSize="large"
                                >
                                    <input
                                        formControlName="spaLifeCycleStage"
                                        min="1"
                                        max="19"
                                        step="1"
                                        type="number"
                                        nz-input
                                        placeholder="Enter whole number between 1-19"
                                    />
                                </nz-input-group>
                                <ng-template #suffixSPALifeCycleStageTemplate>
                                    <span
                                        nz-icon
                                        nz-tooltip
                                        nzTooltipTitle="This represents the number of life cycle stages to render for the SPA. A pathway at each successive stage up to this number is created if it meets the sensitivity threshold set below. The number of stages generated will always be n+1 because there will always be an additional “final” stage representing any “remainders” that do not meet the sensitivity threshold."
                                        nzType="info-circle"
                                    ></span>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-row nzGutter="8">
                    <div
                        *ngFor="
                            let impactCategoryCheckedKey of impactCategoriesCheckedKeys
                        "
                        class="spa-parameters-form__input"
                        nz-col
                        nzSpan="12"
                    >
                        <nz-form-item>
                            <nz-form-label>
                                spa sensitivity threshold -
                                {{ impactCategoryCheckedKey }} -
                                {{
                                    getImpactCategoryNameBySerial(
                                        impactCategoryCheckedKey
                                    )
                                }}</nz-form-label
                            >
                            <nz-form-control nzValidateStatus nzHasFeedback>
                                <nz-input-group
                                    [nzSuffix]="
                                        suffixSPASensitiveThresholdTemplate
                                    "
                                    [nzPrefix]="(spaThresholdParamsFormGroup.get('spaThreshold#' +
                                            impactCategoryCheckedKey)?.value ?? 0.5) < 0.01 ? prefixSpaSensitivity : undefined"

                                    [nzStatus]="(spaThresholdParamsFormGroup.get('spaThreshold#' +
                                            impactCategoryCheckedKey)?.value ?? 0.5) < 0.01 ? 'warning' : undefined"
                                    nzSize="large"
                                >
                                    <input
                                        [formControlName]="
                                            'spaThreshold#' +
                                            impactCategoryCheckedKey
                                        "
                                        min="0"
                                        max="1"
                                        step="0.01"
                                        type="number"
                                        nz-input
                                        placeholder="Enter decimal between 0-1"
                                    />
                                </nz-input-group>
                                <ng-template
                                    #suffixSPASensitiveThresholdTemplate
                                >
                                    <span
                                        nz-icon
                                        nz-tooltip
                                        nzTooltipTitle="This represents the threshold used when determining the stages rendered for a pathway (up to the number of stages defined above). When a pathway no longer meets this threshold, the pathway ends at this stage and the remainder is put into the next stage. This number represents decimal corresponding to the percentage of total impact. i.e. .05 sets the threshold to 5% of the total impact."
                                        nzType="info-circle"
                                    ></span>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </form>
        </div>
        <div class="lca-sandbox-stepper-actions">
            <button
                *ngIf="currentStepIndex > 0"
                (click)="onPreviousStep()"
                [disabled]="isPreviousDisabled"
                nz-button
            >
                Previous
            </button>
            <button
                *ngIf="currentStepIndex <= steps.SPA"
                (click)="onNextStep()"
                [nzLoading]="isNextLoading"
                [disabled]="isNextDisabled"
                nz-button
                nzType="primary"
            >
                {{ currentStepIndex === steps.SPA ? 'Add' : 'Next' }}
            </button>
            @if (currentStepIndex === steps.SPA) {
                <button
                    *hasPermission="['NEW_LCA_ENGINE']"
                    (click)="onAddSPAs(true)"
                    [nzLoading]="isNextLoading"
                    [disabled]="isNextDisabled"
                    nz-button
                    nzType="primary"
                    nzDanger
                >
                    Add (NEW ENGINE)
                </button>
            }
        </div>
    </div>

    <!-- files form -->
    <ng-template #filesForm>
        <nz-alert
            nzType="info"
            nzMessage="The .csv file should have 1 column with the header 'ActivitySerial'"
            nzShowIcon
        ></nz-alert>
        <form
            name="csv-files-form"
            [formGroup]="csvFilesForm"
            nz-form
            action="none"
        >
            <div nz-row nzGutter="4" formArrayName="files">
                <div
                    nz-col
                    nzSpan="24"
                    *ngFor="let csvFileForm of files.controls; let i = index"
                    [formGroup]="csvFileForm"
                >
                    <nz-form-item>
                        <nz-form-control nzSpan="24">
                            <div nzSpan="6" *ngIf="csvValidationErrors?.length">
                                <nz-alert
                                    nzType="error"
                                    nzCloseable
                                    nzMessage="CSV Validation Error"
                                    [nzDescription]="csvActivitySerialsValidationErrors"
                                ></nz-alert>
                            </div>
                            <div class="upload-file-item">
                                <i
                                    class="upload-file-item__icon"
                                    nz-icon
                                    nzType="file-csv"
                                ></i>
                                <span class="upload-file-item__name">
                                    List of ActivitySerials
                                </span>
                                <ng-container>
                                    <div
                                        class="file-name"
                                        *ngIf="
                                            csvFileForm.get('fileName')?.value
                                        "
                                    >
                                        {{ csvFileForm.get('fileName')?.value }}
                                    </div>
                                    <button
                                        *ngIf="
                                            csvFileForm.get('fileName')
                                                ?.value &&
                                            (!started || cancelled || completed)
                                        "
                                        (click)="
                                            onFileRemove(
                                                csvFileForm.get('type').value
                                            )
                                        "
                                        type="button"
                                        nz-button
                                        nzSize="large"
                                        nzType="danger"
                                    >
                                        Delete
                                    </button>
                                    <nz-spin
                                        *ngIf="
                                            started && !cancelled && !completed
                                        "
                                        nzSimple
                                    ></nz-spin>
                                    <nz-form-control
                                        *ngIf="
                                            !csvFileForm.get('fileName')?.value
                                        "
                                        nzValidateStatus
                                        nzHasFeedback
                                        nzListType="picture"
                                    >
                                        <nz-upload
                                            [nzBeforeUpload]="
                                                beforeFileUpload(
                                                    csvFileForm.get('type')
                                                        .value
                                                )
                                            "
                                            [nzFileList]="
                                                getFileByType(
                                                    csvFileForm.get('type')
                                                        .value
                                                )
                                            "
                                        >
                                            <button
                                                class="attach-file-button"
                                                nz-button
                                                nzSize="large"
                                                nzType="primary"
                                                type="button"
                                            >
                                                Upload CSV
                                            </button>
                                        </nz-upload>
                                    </nz-form-control>
                                </ng-container>
                            </div>
                            <h5
                                *ngIf="selectedActivitySerials?.length"
                                class="activity-serials-from-file__header"
                            >
                                Selected serials:
                            </h5>
                            <ul
                                class="activity-serials-from-file"
                                *ngIf="selectedActivitySerials?.length"
                            >
                                <li
                                    *ngFor="
                                        let serial of selectedActivitySerials
                                    "
                                >
                                    {{ serial }}
                                </li>
                            </ul>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </form>
        <nz-progress
            *ngIf="started && !completed && !cancelled"
            [nzPercent]="progress"
            nzSize="large"
            nzStatus="active"
        ></nz-progress>
        <nz-alert
            *ngIf="error"
            nzType="error"
            [nzMessage]="error"
            nzShowIcon
        ></nz-alert>
    </ng-template>
</ng-template>

<ng-template #csvActivitySerialsValidationErrors>
    <ul>
        <li *ngFor="let serialValidationError of csvValidationErrors" [innerHTML]="serialValidationError | safe: 'html'"></li>
    </ul>
</ng-template>

<ng-template #prefixSpaSensitivity>
    <i nz-icon nzType="warning" nzTheme="outline" nz-tooltip nzTooltipPlacement="top" nzTooltipTitle="Analysis could take a very long time."></i>
</ng-template>