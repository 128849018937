export const UPLOAD_PORTAL_LIST_REQUEST = 'Data template list request';
export const UPLOAD_PORTAL_LIST_RECEIVE = 'Data template list receive';
export const UPLOAD_PORTAL_LIST_FAILED = 'Data template list failed';

export const UPLOAD_PORTAL_CREATE_REQUEST = 'Data template create request';
export const UPLOAD_PORTAL_CREATE_RECEIVE = 'Data template create receive';
export const UPLOAD_PORTAL_CREATE_FAILED = 'Data template create failed';

export const UPLOAD_PORTAL_UPDATE_REQUEST = 'Data template update request';
export const UPLOAD_PORTAL_UPDATE_RECEIVE = 'Data template update receive';
export const UPLOAD_PORTAL_UPDATE_FAILED = 'Data template update failed';

export const UPLOAD_PORTAL_VERSIONS_REQUEST = 'Data template versions request';
export const UPLOAD_PORTAL_VERSIONS_RECEIVE = 'Data template versions receive';
export const UPLOAD_PORTAL_VERSIONS_FAILED = 'Data template versions failed';

export const UPLOAD_PORTAL_TYPES_REQUEST = 'Data template types request';
export const UPLOAD_PORTAL_TYPES_RECEIVE = 'Data template types receive';
export const UPLOAD_PORTAL_TYPES_FAILED = 'Data template types failed';

export const UPLOAD_PORTAL_DOWNLOAD_FILE_REQUEST =
    'Data template download file request';
export const UPLOAD_PORTAL_DOWNLOAD_FILE_RECEIVE =
    'Data template download file receive';
export const UPLOAD_PORTAL_DOWNLOAD_FILE_FAILED =
    'Data template download file failed';

export const UPLOAD_PORTAL_REQUEST = 'Upload data template request';
export const UPLOAD_PORTAL_RECEIVE = 'Upload data template receive';
export const UPLOAD_PORTAL_FAILED = 'Upload data template failed';

export const USER_UPLOAD_PORTAL_TYPES_REQUEST =
    'User data template types request';
export const USER_UPLOAD_PORTAL_TYPES_RECEIVE =
    'User data template types receive';
export const USER_UPLOAD_PORTAL_TYPES_FAILED =
    'User data template types failed';

export const USER_UPLOAD_PORTALS_REQUEST = 'User data templates request';
export const USER_UPLOAD_PORTALS_RECEIVE = 'User data templates receive';
export const USER_UPLOAD_PORTALS_FAILED = 'User data templates failed';

export const USER_DATA_UPLOAD_HISTORY_REQUEST =
    'User data upload history request';
export const USER_DATA_UPLOAD_HISTORY_RECEIVE =
    'User data upload history receive';
export const USER_DATA_UPLOAD_HISTORY_FAILED =
    'User data upload history failed';

export const CREATE_UPLOAD_PORTAL_TYPE_REQUEST =
    'Create data template type request';
export const CREATE_UPLOAD_PORTAL_TYPE_RECEIVE =
    'Create data template type receive';
export const CREATE_UPLOAD_PORTAL_TYPE_FAILED =
    'Create data template type failed';

export const UPDATE_UPLOAD_PORTAL_TYPE_REQUEST =
    'Update data template type request';
export const UPDATE_UPLOAD_PORTAL_TYPE_RECEIVE =
    'Update data template type receive';
export const UPDATE_UPLOAD_PORTAL_TYPE_FAILED =
    'Update data template type failed';

export const DATA_COLLECTION_STATUSES_REQUEST =
    'Data collection statuses request';
export const DATA_COLLECTION_STATUSES_RECEIVE =
    'Data collection statuses receive';
export const DATA_COLLECTION_STATUSES_FAILED =
    'Data collection statuses failed';

export const UPLOAD_PORTAL_STATUS_HISTORY_REQUEST =
    'Data template status history request';
export const UPLOAD_PORTAL_STATUS_HISTORY_RECEIVE =
    'Data template status history receive';
export const UPLOAD_PORTAL_STATUS_HISTORY_FAILED =
    'Data template status history failed';

export const UPLOAD_PORTAL_UPLOAD_STATUS_UPDATE_REQUEST =
    'Data template upload status update request';
export const UPLOAD_PORTAL_UPLOAD_STATUS_UPDATE_RECEIVE =
    'Data template upload status update receive';
export const UPLOAD_PORTAL_UPLOAD_STATUS_UPDATE_FAILED =
    'Data template upload status update failed';

export const DELETE_UPLOAD_PORTAL_REQUEST = 'Data template delete request';
export const DELETE_UPLOAD_PORTAL_RECEIVE = 'Data template delete receive';
export const DELETE_UPLOAD_PORTAL_FAILED = 'Data template delete failed';
