import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _orderBy from 'lodash/orderBy';
import _sortBy from 'lodash/sortBy';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { LCAImpactType } from 'src/app/store/models/lca-impact-type';
import { LCAActivitySerialLog } from 'src/app/store/models/lca-activityserial-log.model';
import { LCADataset } from 'src/app/store/models/lca-dataset.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TextSearchInputComponent } from 'src/app/core/components/text-search-input/text-search-input.component';
import { LcaService } from 'src/app/core/services/lca/lca.service';
import { GlobalService } from 'src/app/core/services/global/global.service';

export interface LCAClientActivitySerialsFilter {
    clientId?: string;
    years?: string[];
    impactTypeIds?: number[];
    datasetIds?: number[];
}

@Component({
    selector: 'app-lca-engine-client-database-spa-page',
    templateUrl: './lca-engine-client-database-spa-page.component.html',
    styleUrls: ['./lca-engine-client-database-spa-page.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LcaEngineClientDatabaseSpaPageComponent
    implements OnInit, OnDestroy
{
    filter: LCAClientActivitySerialsFilter = {};
    searchText: string;
    logs: LCAActivitySerialLog[] = [];
    notFilteredLogs: LCAActivitySerialLog[] = [];
    logsLoading: boolean = false;
    isClientDatabaseExist: boolean = true;

    debouncer: Subject<boolean> = new Subject();
    ngDestroyed$ = new Subject();

    @ViewChild(TextSearchInputComponent)
    textSearchInput: TextSearchInputComponent;

    impactYears: string[] = [];
    impactYearsLoading: boolean = false;
    selectedImpactYears: string[] = [];

    impactTypes: LCAImpactType[] = [];
    impactTypesLoading: boolean = false;
    selectedImpactTypeIds: number[] = [];

    datasets: LCADataset[] = [];
    datasetLoading: boolean = false;
    selectedDatasetIds: number[] = [];

    constructor(
        private cdr: ChangeDetectorRef,
        private lcaService: LcaService,
        private message: NzMessageService,
        private globalService: GlobalService
    ) {}

    ngOnInit(): void {
        this.debouncer
            .pipe(takeUntil(this.ngDestroyed$), debounceTime(350))
            .subscribe(() => {
                this.fetchLogs(this.filter);
                this.cdr.detectChanges();
            });

        this.globalService.clientId$.pipe(takeUntil(this.ngDestroyed$))
            .subscribe(
                clientId => {
                    if (clientId) {
                        this.onClientChanged(clientId);
                    }
                }
            )
    }

    ngOnDestroy(): void {
        this.ngDestroyed$.next(true);
    }

    onClientChanged(clientId: string) {
        this.filter = {
            clientId,
        };
        this.debouncer.next(true);
        this.cdr.detectChanges();

        if (
            !this.datasets?.length ||
            !this.impactTypes?.length ||
            !this.impactYears?.length
        ) {
            this.fetchImpactYears();
            this.fetchImpactTypes();
            this.fetchDatasets();
        }
    }

    onSearch(value: string) {
        this.searchText = value;

        if (!this.searchText) {
            this.logs = this.notFilteredLogs;
        } else {
            const query = this.searchText.toLocaleLowerCase();

            this.logs = this.notFilteredLogs.filter(
                (log) =>
                    log.activityName.toLowerCase().includes(query) ||
                    String(log.activitySerial).toLowerCase().includes(query) ||
                    String(log.spaStages).toLowerCase().includes(query) ||
                    String(log.spaThreshold).toLowerCase().includes(query) ||
                    String(log.impactSerial).toLowerCase().includes(query)
            );
        }
    }

    private fetchImpactYears() {
        this.impactYearsLoading = true;
        this.cdr.detectChanges();

        this.lcaService.getImpactYears().subscribe({
            next: (payload) => {
                this.impactYears = _sortBy(_get(payload, 'payload', []));
            },
            complete: () => {
                this.impactYearsLoading = false;
                this.cdr.detectChanges();
            },
        });
    }

    private fetchImpactTypes() {
        this.impactTypesLoading = true;
        this.cdr.detectChanges();

        this.lcaService.getImpactTypes().subscribe({
            next: (payload) => {
                this.impactTypes = _orderBy(_get(payload, 'payload', []), item => item.impactTypeName.toLowerCase(), ['asc']);
            },
            complete: () => {
                this.impactTypesLoading = false;
                this.cdr.detectChanges();
            },
        });
    }

    private fetchDatasets() {
        this.datasetLoading = true;
        this.lcaService.getDatasets().subscribe({
            next: (payload) => {
                this.datasets = _orderBy(_get(payload, 'payload', []), item => item.datasetName.toLowerCase(), ['asc']);
            },
            complete: () => {
                this.datasetLoading = false;
                this.cdr.detectChanges();
            },
        });
    }

    onDatasetSelected(datasetIds: number[]) {
        this.textSearchInput.onClear();
        if (datasetIds.length) {
            this.filter.datasetIds = datasetIds;
        } else {
            this.filter = _omit(this.filter, ['datasetIds']);
        }
        this.debouncer.next(true);
    }

    onYearsSelected(years: string[]) {
        this.textSearchInput.onClear();
        if (years.length) {
            this.filter.years = years;
        } else {
            this.filter = _omit(this.filter, ['years']);
        }
        this.debouncer.next(true);
    }

    onImpactTypeSelected(impactTypeIds: number[]) {
        this.textSearchInput.onClear();
        if (impactTypeIds.length) {
            this.filter.impactTypeIds = impactTypeIds;
        } else {
            this.filter = _omit(this.filter, ['impactTypeIds']);
        }
        this.debouncer.next(true);
    }

    get showResetFilters() {
        return !_isEmpty(_omit(this.filter, 'clientId'));
    }

    resetFilters() {
        this.filter = {
            clientId: this.filter.clientId,
        };
        this.selectedImpactYears = [];
        this.selectedImpactTypeIds = [];
        this.textSearchInput.onClear();
        this.cdr.detectChanges();
        this.debouncer.next(true);
    }

    fetchLogs(filter: LCAClientActivitySerialsFilter) {
        this.logsLoading = true;
        this.isClientDatabaseExist = true;
        this.cdr.detectChanges();
        this.lcaService
            .getClientActivitySerialsLogs(filter)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    this.logs = _get(payload, 'payload', []);
                },
                error: (error) => {
                    this.logs = [];
                    this.logsLoading = false;
                    if (error.status === 404) {
                        this.message.error('Client schema was not found.');
                        this.isClientDatabaseExist = false;
                    }
                    this.cdr.detectChanges();
                },
                complete: () => {
                    this.notFilteredLogs = this.logs;
                    this.logsLoading = false;
                    this.cdr.detectChanges();
                },
            });
    }
}
