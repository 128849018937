<div class="number-format">
    <span class="number-format__value">
        {{formattedNumber}}{{sign}}
    </span>
    @if (isTooltipVisible) {
        <span
            class="info-tooltip"
            nz-icon
            nz-tooltip
            [nzTooltipTitle]="value"
            nzType="info-circle"
        ></span>
    }
</div>