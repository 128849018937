import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export enum Mode {
  Admin = 0,
  Client
}

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  clientId$: BehaviorSubject<string | undefined> = new BehaviorSubject(undefined);
  mode: Mode = Mode.Client;

  constructor() { }

  onClientChanged(clientId: string) {
    this.clientId$.next(clientId);
  }

  onModeChanged(mode: Mode) {
    this.mode = mode;
  }
}
