<div class="process-history-page-container">
    <div nz-flex [nzGap]="8" nzJustify="space-between" nzAlign="center">
        <nz-breadcrumb nz-flex>
            <nz-breadcrumb-item>
                Process History
            </nz-breadcrumb-item>
        </nz-breadcrumb>
        <button
            nz-button
            nzGhost
            nzType="primary"
            (click)="onRefresh()"
            nzSize="small"
        >
            <span nz-icon nzType="redo"></span>
            Refresh
        </button>
    </div>
    <app-process-history-table></app-process-history-table>
</div>