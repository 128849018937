<nz-skeleton
    [nzLoading]="!reportGroupForm.dirty && (loading$ | async)"
    [nzActive]="true"
>
    <div
        class="report-group-form-container"
        *hasPermission="['REPORT_MGMT']; class: 'disabled'"
    >
        <form
            class="report-group__form"
            nz-form
            [formGroup]="reportGroupForm"
            (ngSubmit)="submitReportGroupForm()"
        >
            <div nz-row nzGutter="4">
                <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label nzRequired>Group name</nz-form-label>
                        <nz-form-control
                            [nzSpan]="24"
                            nzValidateStatus
                            nzHasFeedback
                            [nzErrorTip]="groupNameErrorTmpl"
                        >
                            <nz-input-group nzSize="large">
                                <input
                                    data-cy="report-group-name__input"
                                    formControlName="groupName"
                                    nz-input
                                    placeholder="Group name"
                                />
                            </nz-input-group>
                            <ng-template #groupNameErrorTmpl let-control>
                                <ng-container
                                    *ngIf="control.hasError('required')"
                                >
                                    Please input group name
                                </ng-container>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label nzRequired>Group color</nz-form-label>
                        <nz-form-control
                            [nzSpan]="24"
                            [nzErrorTip]="groupColorErrorTmpl"
                        >
                            <nz-input-group nzSize="large">
                                <app-color-input
                                    data-cy="report-group-color__input"
                                    [initColor]="
                                        reportGroupForm.value.groupColor
                                    "
                                    (colorChange)="onColorChanged($event)"
                                ></app-color-input>
                            </nz-input-group>
                            <ng-template #groupColorErrorTmpl let-control>
                                <ng-container
                                    *ngIf="control.hasError('required')"
                                >
                                    Please select group color
                                </ng-container>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label>Attribute types (optional)</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <nz-select
                                nzPlaceHolder="Select attribute types"
                                nzAllowClear
                                nzMode="multiple"
                                nzShowSearch
                                nzSize="large"
                                [nzLoading]="attributeTypesLoading || reportsLoading"
                                formControlName="attrTypeIds"
                            >
                                <ng-container *ngIf="!attributeTypesLoading && !reportsLoading">
                                    <nz-option
                                        *ngFor="let attrType of attributeTypes"
                                        [nzValue]="attrType.attrTypeId"
                                        [nzLabel]="attrType.attrTypeName"
                                        [nzCustomContent]="!attrType.attributes?.length"
                                        [nzDisabled]="!attrType.attributes?.length"
                                    >
                                        <div nz-flex nzAlign="center" nzJustify="space-between">
                                            <span>{{ attrType.attrTypeName }}</span>
                                            <span
                                                nz-icon
                                                nz-tooltip
                                                nzTooltipTitle="The attribute type must have at least one attribute to select."
                                                nzType="info-circle"
                                            ></span>
                                        </div>
                                    </nz-option>
                                </ng-container>
                                <nz-option *ngIf="attributeTypesLoading && reportsLoading" nzDisabled nzCustomContent>
                                    <span nz-icon nzType="loading" class="loading-icon"></span>
                                    Loading Data...
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-control
                            class="drawer-actions-control"
                            [nzSpan]="24"
                        >
                            <div class="drawer-controls">
                                <button
                                    data-cy="report-group-form__submit"
                                    *hasPermission="['REPORT_MGMT']"
                                    type="submit"
                                    nzSize="large"
                                    nz-button
                                    [disabled]="
                                        !reportGroupForm?.valid ||
                                        !reportGroupForm?.dirty ||
                                        !clientId ||
                                        (loading$ | async)
                                    "
                                    nzType="primary"
                                >
                                    <span>Save</span>
                                </button>
                                <button
                                    data-cy="report-group-form__close"
                                    type="button"
                                    [disabled]="loading$ | async"
                                    nzSize="large"
                                    nz-button
                                    (click)="onCloseReportGroupForm()"
                                >
                                    <span>Close</span>
                                </button>
                            </div>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </form>
    </div>
</nz-skeleton>
