import { Action, createReducer, on } from '@ngrx/store';
import * as AttributeActions from '../../actions/attribute/attribute.actions';
import _get from 'lodash/get';
import { Report } from '../../models/report.model';
import { AttributeType, Attribute } from '../../models/attribute.model';

export const attributeFeatureKey = 'attributes';

export interface AttributeState {
    loading: boolean;
    items: Attribute[];
    types: {
        loading: boolean;
        items: AttributeType[];
    };
    error: string;
}

export const initialState: AttributeState = {
    loading: false,
    items: null,
    types: {
        loading: false,
        items: [],
    },
    error: null,
};

const reportReducer = createReducer(
    initialState,
    // attributes types
    on(AttributeActions.attributesTypeListRequest, (state) => ({
        ...state,
        error: null,
        loading: true,
        types: {
            loading: true,
            items: state.types.items,
        },
    })),
    on(AttributeActions.attributesTypeListReceive, (state, { items }) => ({
        ...state,
        types: {
            loading: false,
            items,
        },
    })),
    on(AttributeActions.attributesTypeFailed, (state, { error }) => ({
        ...state,
        types: {
            loading: false,
            items: [],
        },
        error: _get(error, ['error', 'errors', 0], null),
    })),
    // attribues
    on(AttributeActions.attributesByTypeListRequest, (state) => ({
        ...state,
        error: null,
        loading: true,
    })),
    on(AttributeActions.attributesByTypeListReceive, (state, { items }) => ({
        ...state,
        items,
        loading: false,
    })),
    on(AttributeActions.attributesTypeFailed, (state, { error }) => ({
        ...state,
        items: [],
        loading: false,
        error: _get(error, ['error', 'errors', 0], null),
    }))
);

export function reducer(state: AttributeState | undefined, action: Action) {
    return reportReducer(state, action);
}
