<nz-result
    nzStatus="404"
    nzTitle="404"
    nzSubTitle="Sorry, the page you visited does not exist."
>
    <div nz-result-extra>
        <button nz-button nzType="primary" nzGhost routerLink="/">
            Back Home
        </button>
    </div>
</nz-result>
