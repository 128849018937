import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import { ClientDatabaseService } from '../../../core/services/client-database/client-database.service';
import {
    DbNaming,
    DB_NAMING_REGEXP_TYPES,
} from '../../../validators/dbNaming.validator';

@Component({
    selector: 'app-client-database-form',
    templateUrl: './client-database-form.component.html',
    styleUrls: ['./client-database-form.component.less'],
})
export class ClientDatabaseFormComponent implements OnInit {
    @Output() closed: EventEmitter<boolean> = new EventEmitter();
    @Input() clientId: string;

    databaseFormGroup: UntypedFormGroup;
    dabaseNameLength: number = 64;

    databaseSchemasLoading: boolean = false;
    databaseSchemas: string[] = [];

    saving: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private clientDatabaseService: ClientDatabaseService
    ) {
        this.initDatabaseForm();

        this.databaseSchemasLoading = true;
        this.clientDatabaseService.getClientSchemaSQLTemplates().subscribe({
            next: (payload) => {
                this.databaseSchemasLoading = false;
                this.databaseSchemas = _sortBy(_get(payload, 'payload', []));
            },
            error: () => {
                this.databaseSchemasLoading = false;
            },
        });
    }

    ngOnInit() {
        this.databaseFormGroup.patchValue({ clientId: this.clientId });
    }

    onSubmit() {
        this.saving = true;
        this.clientDatabaseService
            .createClientSchema(this.databaseFormGroup.value)
            .subscribe({
                next: () => {
                    this.closed.emit(true);
                    this.databaseFormGroup.reset();
                    this.saving = false;
                },
                error: () => {
                    this.saving = false;
                },
            });
    }

    onClose() {
        this.closed.emit(false);
    }

    initDatabaseForm() {
        this.databaseFormGroup = this.fb.group(
            {
                schemaSqlTemplate: ['', Validators.required],
                schemaName: ['', Validators.required],
                clientId: ['', Validators.required],
            },
            {
                validators: [
                    DbNaming('schemaName', DB_NAMING_REGEXP_TYPES.LONG),
                ],
            }
        );
    }
}
