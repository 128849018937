import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LCAImpactCategoriesFilter } from 'src/app/components/filters/lca-impact-categories-filter/lca-impact-categories-filter.component';
import { CSVFileKeys } from 'src/app/components/forms/lca-model-data-csv-form/lca-model-data-csv-form.component';
import { ImpactICL } from 'src/app/store/models/impact-icl.model';
import {
    LCAActivitySerial,
    LCAEngineSPAsInput,
} from 'src/app/store/models/lca-activity-serial.model';
import { LCADataset } from 'src/app/store/models/lca-dataset.model';
import { LCAImpactCateogry } from 'src/app/store/models/lca-impact-category';
import { LCAEngineStatus, LCAImpactType } from 'src/app/store/models/lca-impact-type';
import { ReportConfig } from 'src/app/store/models/report.model';
import { ApiService } from '../api/api.service';

import _omit from 'lodash/omit';
import { LCAClientActivitySerialsFilter } from 'src/app/components/pages/lca-engine-client-database-spa-page/lca-engine-client-database-spa-page.component';
import { LCAActivitySerialLog } from 'src/app/store/models/lca-activityserial-log.model';

@Injectable({
    providedIn: 'root',
})
export class LcaService {
    reportFailed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private api: ApiService) {}

    initSandbox(): Observable<any> {
        return this.api.post(`lcaengine/sandboxes`);
    }

    getSandboxStatus(): Observable<{ payload: LCAEngineStatus }> {
        return this.api.get(`lcaengine/sandboxes/status`);
    }

    getLCAImpactCategoriesLogs(params: {
        datasetId: string;
        datasetYear: number;
        searchText?: string;
        impactYears?: string[];
        impactTypeIds?: number[];
    }): Observable<{ payload: ImpactICL[] }> {
        return this.api.get(`impact-coefficients/impacts-icl`, params);
    }

    postLCAImpactCoeff(data: {
        datasetId: string;
        datasetYear: number;
        impactSerials: number[];
        supplyChainTiers?: number;
        useNewLcaEngine: boolean;
    }) {
        return this.api.post(`impact-coefficients`, data);
    }

    uploadModelDataFromCSV(
        modelName: string,
        files: { type: CSVFileKeys; file: NzUploadFile }[]
    ) {
        const formData = new FormData();

        files.forEach((file) => {
            formData.append(file.type, file.file as any);
        });

        const headers = new HttpHeaders();
        headers.set('Content-type', 'multipart/form-data');

        const options = {
            reportProgress: true,
            observe: 'events',
        };

        return this.api.request(
            'POST',
            `lcaengine/sandboxes/models/${modelName}/configs/files`,
            {},
            formData,
            headers,
            options
        );
    }

    uploadSandboxPBIReportTemplate(
        file: NzUploadFile
    ) {
        const formData = new FormData();

        formData.append('pbiReportTemplateFile', file as any);

        const headers = new HttpHeaders();
        headers.set('Content-type', 'multipart/form-data');

        const options = {
            reportProgress: true,
            observe: 'events',
        };

        return this.api.request(
            'POST',
            `lcaengine/sandboxes/pbi-report-templates`,
            {},
            formData,
            headers,
            options
        );
    }

    getDatasets(): Observable<{ payload: LCADataset[] }> {
        return this.api.get(`ai-schema/datasets`);
    }

    getActivitySerialsByDatasetId(
        datasetId: string,
        year?: number
    ): Observable<{ payload: LCAActivitySerial[] }> {
        const params = { datasetId };

        if (year) {
            params['year'] = year;
        }

        return this.api.get(`lcaengine/activities`, { datasetId, year });
    }

    putModelActivitySerials(modelName: string, activitySerials: number[]) {
        return this.api.put(
            `lcaengine/sandboxes/models/${modelName}/configs/activities`,
            activitySerials
        );
    }

    putImpactCategories(impacts: number[]): Observable<any> {
        return this.api.put(
            `lcaengine/sandboxes/models/configs/impacts`,
            impacts
        );
    }

    getImpactCategories(
        filter?: LCAImpactCategoriesFilter
    ): Observable<{ payload: LCAImpactCateogry[] }> {
        let data = filter;

        if (!filter.impactTypeIds) {
            data = _omit(data, 'impactTypeIds');
        }

        if (!filter.years) {
            data = _omit(data, 'years');
        }

        const params = new URLSearchParams();

        Object.entries(data)
            .forEach(([filterKey, filterValue]) => {
                if (Array.isArray(filterValue)) {
                    filterValue.forEach(value => {
                        params.append(filterKey, value)
                    })
                } else {
                    params.set(filterKey, filterValue);
                }
            })

        return this.api.get(`lcaengine/impacts?${params.toString()}`);
    }

    getSandboxSPAActivitySerials(
        modelName: string
    ): Observable<{ payload: LCAActivitySerial[] }> {
        return this.api.get(
            `lcaengine/sandboxes/models/${modelName}/activities`
        );
    }

    postSPAActivities(data: {
        spaActivities: { modelName: string; activitySerials: number[] }[];
        spaLifeCycleStage: number;
        impactSerialsThresholdValues: { impactSerial: number; value: number }[];
    }): Observable<{ payload: any }> {
        return this.api.put(
            `lcaengine/sandboxes/models/configs/spa/activities`,
            data
        );
    }

    postModelParams(data: {
        modelParams: {
            modelName: string;
            breakdownByTechflow: boolean;
            sumElemFlows: boolean;
            numTiers: number;
        }[];
        useNewLcaEngine: boolean;
    }) {
        return this.api.post(`lcaengine/sandboxes/runs`, data);
    }

    loadReport(): Observable<{ payload: ReportConfig }> {
        return this.api.get(`lcaengine/sandboxes/report`);
    }

    getImpactYears(): Observable<{ payload: string[] }> {
        return this.api.get(`lcaengine/impacts/years`);
    }

    getImpactTypes(): Observable<{ payload: LCAImpactType[] }> {
        return this.api.get(`lcaengine/impacts/impact-types`);
    }

    getActivityYears(datasetId: string): Observable<{ payload: number[] }> {
        return this.api.get(`lcaengine/activities/years`, { datasetId });
    }

    deleteModel(modelName: string): Observable<any> {
        return this.api.delete(`lcaengine/sandboxes/models/${modelName}`);
    }

    getClientActivitySerialsLogs(
        filter: LCAClientActivitySerialsFilter
    ): Observable<{ payload: LCAActivitySerialLog }> {
        const params = {};
        if (filter.datasetIds?.length) {
            params['datasetIds'] = filter.datasetIds;
        }
        if (filter.impactTypeIds?.length) {
            params['impactTypeIds'] = filter.impactTypeIds;
        }
        if (filter.years?.length) {
            params['datasetYears'] = filter.years;
        }
        return this.api.get(`spa-tool/spa-data/logs`, {
            clientId: filter.clientId,
            ...params
        });
    }

    addSPAs(clientId: string, input: LCAEngineSPAsInput) {
        return this.api.post(`spa-tool/spa-data/${clientId}`, input);
    }
}
