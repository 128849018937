import { Component, OnDestroy, OnInit, TemplateRef, viewChild, ViewChild } from '@angular/core';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import _orderBy from 'lodash/orderBy';
import { Subject } from 'rxjs';
import { Customer, MissingClientRequirement } from '../../../store/models/customer.model';
import {
    CustomerService,
    CustomerFilter,
    AI_CLIENT_ID,
} from '../../../core/services/customer/customer.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { debounceTime, takeUntil } from 'rxjs/operators';
import _last from 'lodash/last';
import { TextSearchInputComponent } from 'src/app/core/components/text-search-input/text-search-input.component';

const PAGE_SIZE = 100;

@Component({
    selector: 'app-customer-page',
    templateUrl: './customer-page.component.html',
    styleUrls: ['./customer-page.component.less'],
})
export class CustomerPageComponent implements OnInit, OnDestroy {
    customers: Customer[];
    nonFilteredCustomers: Customer[];
    customersLoading: Boolean = false;

    // edit customer data
    customerFormMode: boolean = false;
    ngDestroyed$ = new Subject();

    customerSSOConfigFormMode: boolean = false;

    filterForm: Subject<CustomerFilter> = new Subject();
    selectedClientId: string;
    customersLoaded: boolean = false;
    query: string;
    @ViewChild(TextSearchInputComponent) child: TextSearchInputComponent;

    performingOperationClientIds: string[] = [];

    missingSchemaRequirementsLoading: boolean;
    missingSchemaRequirements: MissingClientRequirement[] = [];
    missingSchemaRequirementsError: string;

    @ViewChild('validateSchemasModalTmpl') validateSchemasModalTmpl: TemplateRef<HTMLDivElement>;

    searchFilter = {
        searchText: '',
        columnName: 'client_name',
        sortDirection: 'ascend',
        lastClientId: '',
    };
    columns = [
        {
            title: 'Company Name',
            key: 'client_name',
            width: '40%',
            dataCy: 'clients-table__name-sort',
            sortDirections: ['descend', 'ascend', null],
        },
        {
            title: 'Contact Phone',
            key: 'Contact Phone',
            width: '25%',
            dataCy: 'clients-table__phone-sort',
            sortDirections: [null],
        },
        {
            title: 'Date Created',
            key: 'created_stamp',
            width: '20%',
            dataCy: 'clients-table__created-at-sort',
            sortDirections: ['ascend', 'descend', null],
        },
    ];

    constructor(
        private customerService: CustomerService,
        private modal: NzModalService,
        private message: NzMessageService
    ) {}

    ngOnInit() {
        this.filterForm
            .pipe(takeUntil(this.ngDestroyed$), debounceTime(400))
            .subscribe((filter) => {
                this.customersLoaded = false;
                this.fetchCustomers(filter);
            });

        //initial call
        this.fetchCustomers();
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    private fetchCustomers(filter?: CustomerFilter) {
        if (this.customersLoaded || this.customersLoading) return;
        
        this.customersLoading = true;
        this.customerService
            .getCustomers({ ...(filter ?? {}), view: 'MGMT', excludeClientIds: [AI_CLIENT_ID] }, PAGE_SIZE)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    const customers = _get(payload, 'payload', []);

                    if (customers.length < PAGE_SIZE) {
                        this.customersLoaded = true;
                    }
                    if (filter && filter.lastClientId) {
                        if (customers.length) {
                            this.customers = [...this.customers, ...customers];
                        }
                    } else {
                        this.customers = customers;
                    }
                },
                complete: () => {
                    this.customersLoading = false;
                    this.query = this.searchFilter.searchText;
                },
            });
    }

    onEditCustomer(clientId: string) {
        this.customerFormMode = true;
        this.selectedClientId = clientId;
    }

    onCreateCustomer() {
        this.customerFormMode = true;
    }

    onRemoveCustomer(clientId: string, clientName: string) {
        this.modal.confirm({
            nzTitle: `Do you want to delete client <b>${clientName}</b>?`,
            nzContent: `
        If you delete it you will not be able to recover client.<br>
        Do you want to delete the client?
      `,
            nzOnOk: () =>
                this.customerService
                    .deleteCustomer(clientId)
                    .pipe(takeUntil(this.ngDestroyed$))
                    .subscribe(() => {
                        this.customers = this.customers.filter(
                            (customer) => customer.clientId !== clientId
                        );
                        this.message.success(
                            `Client <b>${clientName}</b> has been deleted`,
                            { nzDuration: 3000 }
                        );
                    }),
            nzOkText: 'Yes, remove',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzCancelText: 'Close',
            nzClosable: false,
            nzOnCancel: () => console.log('Cancel'),
        });
    }

    closeEditCustomer(isSaving?: boolean) {
        this.customerFormMode = false;
        this.customersLoaded = false;
        if (isSaving) {
            this.child.onClear();
            this.fetchCustomers();
        } else if (this.selectedClientId) {
            this.filterForm.next({
                searchText: this.searchFilter.searchText,
                columnName: this.searchFilter.columnName,
                sortDirection: this.searchFilter.sortDirection,
            });
            this.selectedClientId = null;
        }
    }

    onSearch(value: string) {
        this.searchFilter.searchText = value;
        this.searchFilter.lastClientId = undefined;
        this.filterForm.next({
            searchText: this.searchFilter.searchText,
            columnName: this.searchFilter.columnName,
            sortDirection: this.searchFilter.sortDirection,
        });
    }
    onTableScrolled() {
        if (!this.customersLoaded) {
            const customer: Customer = _last(this.customers);
            if (customer && customer.clientId) {
                const { clientId, clientName, createdStamp } = customer;
                this.filterForm.next({
                    searchText: this.searchFilter.searchText,
                    lastClientId: clientId,
                    lastClientName: clientName,
                    columnName: this.searchFilter.columnName,
                    sortDirection: this.searchFilter.sortDirection,
                    lastCreatedStamp: createdStamp,
                });
            }
        }
    }
    orderChange(col: any) {
        if (col.key === 'Contact Phone') {
            return;
        }
        this.searchFilter.columnName = col.key;
        this.searchFilter.sortDirection = col.value;
        this.customersLoaded = false;
        this.filterForm.next({
            searchText: this.searchFilter.searchText,
            columnName: col.key,
            sortDirection: col.value,
        });
    }

    onClientSSOConfig(clientId: string) {
        this.customerSSOConfigFormMode = true;
        this.selectedClientId = clientId;
    }

    get missingRequirementsByModules(): Record<string, MissingClientRequirement[]> {
        return _groupBy(this.missingSchemaRequirements ?? [], 'moduleId')
    }

    getReqMessage(req: MissingClientRequirement) {
        if (req.columnName) {
            return `Missing column ${req.columnName} on table ${req.tableName}`;
        }

        return `Missing table ${req.tableName}`;
    }

    onValidateSchemas(clientId: string) {
        this.performingOperationClientIds.push(clientId);
        this.missingSchemaRequirementsLoading = true;
        this.missingSchemaRequirementsError = undefined;
        this.missingSchemaRequirements = [];

        const loadingId = this.message.loading(`Validating schema for ${this.customers.find(customer => customer.clientId === clientId)?.clientName}...`).messageId;

        this.customerService.getMissingSchemaRequirements(clientId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    this.missingSchemaRequirements = payload.payload;
                    this.modal.info({
                        nzTitle: ` ${this.customers.find(customer => customer.clientId === clientId)?.clientName} - Schema Validation Results`,
                        nzContent: this.validateSchemasModalTmpl,
                    })
                    this.message.remove(loadingId);
                },
                error: (error) => {
                    this.missingSchemaRequirementsLoading = false;
                    this.message.remove(loadingId);
                    this.performingOperationClientIds = this.performingOperationClientIds.filter(id => id !== clientId);
                    this.missingSchemaRequirementsError = error?.error?.message ?? 'Failed to validate schema';
                    this.message.error(this.missingSchemaRequirementsError);
                },
                complete: () => {
                    this.message.remove(loadingId);
                    this.missingSchemaRequirementsLoading = false;
                    this.performingOperationClientIds = this.performingOperationClientIds.filter(id => id !== clientId);
                }
            })
    }

    closeSSOConfigForm() {
        this.customerSSOConfigFormMode = false;
        this.selectedClientId = null;
    }
}
