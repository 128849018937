import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Subject, takeUntil } from 'rxjs';
import { User } from '../../../store/models/user.model';
import { selectAuthUser } from '../../../store/selectors/auth.selectors';
import { AppState } from '../../../store/state';
import _isEmpty from 'lodash/isEmpty';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent implements OnInit, OnDestroy {
    currentUser$: Observable<User>;
    ssoError: boolean = false;

    ngDestroyed$ = new Subject();

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private modal: NzModalService
    ) {
        this.currentUser$ = this.store.select(selectAuthUser);

        this.currentUser$.pipe(takeUntil(this.ngDestroyed$)).subscribe((user) => {
            if (user && !_isEmpty(user)) {
                this.activatedRoute.queryParams
                    .pipe(takeUntil(this.ngDestroyed$))
                    .subscribe((params) => {
                        const redirect = params['redirect'];
                        this.router.navigateByUrl(!!redirect ? decodeURI(redirect) : '/dashboard');
                    });
            }
        });
    }

    ngOnInit() {
        this.activatedRoute.queryParams.pipe(takeUntil(this.ngDestroyed$)).subscribe((params) => {
            this.ssoError = params['sso_err'];
            if (this.ssoError) {
                this.modal.info({
                    nzTitle: `SSO Authentication Error`,
                    nzContent: `
          There was an issue with signing you into AITrack using your identity provider.<br/><br/>
          <b>Please exit the browser and try again.</b> <br/><br/>
          If the issue persists please contact Aligned Incentives support at: <i>support@alignedincentives.com</i>
          `,
                    nzClosable: false,
                    nzOnOk: () => {
                        this.router.navigate(['/login']);
                    },
                });
            }
        });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }
}
