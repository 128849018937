<div class="preview-container">
    <div class="preview__controls">
        <h2 class="preview-back">
            <i
                nz-icon
                nzType="arrow-left"
                style="cursor: pointer"
                (click)="goBack()"
            ></i>
            {{ title }}
        </h2>
    </div>
    <nz-divider></nz-divider>
    <nz-spin [nzSpinning]="isSpinning" [nzDelay]="100">
        <div class="preview-container" style="height: 80vh">
            <div class="preview-container-box" style="display: inline-block">
                <iframe #preview src="" width="100%" height="100%"> </iframe>
            </div>
        </div>
    </nz-spin>
</div>
