import { Component, DestroyRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProcessHistoryFilters, ProcessService } from 'src/app/core/services/process/process.service';
import { Process, ProcessLog } from 'src/app/store/models/process.model';
import _get from 'lodash/get';
import _last from 'lodash/last';
import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';
import _compact from 'lodash/compact';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HelpService } from 'src/app/core/services/help/help.service';
import { GlobalService } from 'src/app/core/services/global/global.service';
import { GlobalNotificationService } from 'src/app/core/services/global-notification/global-notification.service';
import { NotificationEventType } from 'src/app/store/models/notification.model';
import { Subscription } from 'rxjs';
import { PipelinesService } from 'src/app/pipelines/services/pipelines/pipelines.service';

const PAGE_SIZE = 100;

export enum ProcessesCategory {
  All = 'All',
  Database = 'Database',
  Pipelines = 'Pipelines'
}

@Component({
  selector: 'app-process-history-table',
  templateUrl: './process-history-table.component.html',
  styleUrl: './process-history-table.component.less'
})
export class ProcessHistoryTableComponent implements OnInit {
  @Input() params: object;

  destroyRef = inject(DestroyRef);

  loading: boolean = false;

  processes: Process[] = [];
  notFilteredProcesses: Process[] = [];

  query: string;

  downloadingFileId: string;

  lastRunId: string;
  lastCreatedStamp: number;
  filter: ProcessHistoryFilters = {};

  selectedMessageJSON: object;
  messageDrawerTitle: string;
  showLogs: boolean;
  logsLoading: boolean = false;
  logs: ProcessLog[] = [];
  filteredLogs: ProcessLog[] = [];
  processesLoaded: boolean = false;

  private _category: ProcessesCategory = ProcessesCategory.All;
  categories = ProcessesCategory;

  @Input() get category() {
    return this._category;
  }
  set category(value) {
    this._category = value || ProcessesCategory.All;
  }

  columns = [];

  historyReqSub: Subscription;

  currentClientId: string;

  constructor(
      private processService: ProcessService,
      private message: NzMessageService,
      private helpService: HelpService,
      private globalService: GlobalService,
      private globalNotificationService: GlobalNotificationService,
      private pipeline: PipelinesService
  ) {
     
  }

  refresh() {
    this.onFilterChanged(this.filter);
  }

  fetchHistoryByFitler(
      filter: ProcessHistoryFilters,
      append: boolean = false
  ) {
      this.filter = {
        ...filter,
        limit: PAGE_SIZE,
        clientIds: _compact([...(filter.clientIds ?? []), this.category === ProcessesCategory.Database ? this.currentClientId : false])
      };
      this.loading = true;
      
      if (this.historyReqSub && !this.historyReqSub.closed) {
        this.historyReqSub.unsubscribe();
      }

      if (this.category === ProcessesCategory.Pipelines) {
        this.filter.clientId = this.params['clientId'];
        this.filter.reportYear = this.params['reportYear'];
      }

      this.historyReqSub = this.processService
          .getProcessesHistory(this.filter, this.category)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
              next: (payload) => {
                  this.loading = false;
                  const processes = _get(payload, 'payload', []);
                  if (append) {
                      if (processes.length) {
                          this.processes = [
                              ...this.processes,
                              ...processes,
                          ];
                      }
                  } else {
                      this.processes = processes;
                  }

                  if (processes.length < PAGE_SIZE) {
                    this.processesLoaded = true;
                  }
              },
              error: () => {
                  this.loading = false;
              },
          });
  }

  onFilterChanged(filter: ProcessHistoryFilters) {
      this.lastRunId = null;
      this.lastCreatedStamp = null;
      this.processesLoaded = false;
      delete filter.lastRunId;
      delete filter.lastCreatedStamp;
      this.fetchHistoryByFitler(filter);
  }

  ngOnInit() {
    if (this.category === ProcessesCategory.Database) {
      this.globalService.clientId$
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(
              clientId => {
                  if (clientId) {
                    this.currentClientId = clientId;
                    
                    this.onFilterChanged(this.filter);
                  }
              }
          )
    } else {
      this.onFilterChanged(this.filter);
    }

    this.globalNotificationService.listenNotifications(NotificationEventType.PROCESS_RUN_STATUS)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        message => {
          if (message.body.runId && this.processes.some(process => process.runId === message.body.runId)) {
            this.loading = true;
            const params = { runIds: [message.body.runId], clientIds: this.filter.clientIds ?? [] };

            if (this.category === ProcessesCategory.Pipelines) {
              params['clientId'] = this.params['clientId'];
              params['reportYear'] = this.params['reportYear'];
            }

            this.processService
                .getProcessesHistory(params, this.category)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (payload) => {
                      const processToUpdate = payload.payload?.[0];
                      if (processToUpdate) {
                        this.processes = this.processes.map(process => process.runId === message.body.runId ? processToUpdate : process);
                      }
                      this.loading = false;
                    },
                    error: () => {
                        this.loading = false;
                    },
                  });
          }
        }
      )


    this.columns =  _compact([
      this.category === ProcessesCategory.Pipelines ? {
        title: 'Pipeline Name',
        width: '17%',
      } : false,
      this.category === ProcessesCategory.Pipelines ? {
        title: 'Pipeline Type',
        width: '17%',
      } : false,
      this.category !== ProcessesCategory.Pipelines ? {
        title: 'Client',
        width: '17%',
      } : false,
      {
        title: 'User',
        width: '12%',
      },
      this.category === ProcessesCategory.Database
        ? { title: 'Schema Name',  width: '128px' }
        : false,
      this.category === ProcessesCategory.Database
        ? { title: 'Table Name', width: '136px' }
        : false,
      this.category !== ProcessesCategory.Pipelines
        ? { title: 'Process Type', width: '16%' }
        : false,
      {
        title: 'Start time',
        width: '15%',
      },
      {
        title: 'End time',
        width: '15%',
      },
      {
        title: 'Status',
        width: '15%',
      },
    ])
  }

  onTableScrolled() {
    if (!this.processesLoaded) {
      const lastProcess = _last(this.processes);
      if (lastProcess && lastProcess.runId) {
          const { runId, createdStamp } = lastProcess;
          this.filter.lastRunId = runId;
          this.filter.lastCreatedStamp = createdStamp;

          this.fetchHistoryByFitler(this.filter, true);
      }
    }
  }

  onCloseMessageDrawer() {
    this.selectedMessageJSON = undefined;
    this.messageDrawerTitle = undefined;
  }

  onCloseLogsDrawer() {
    this.messageDrawerTitle = undefined;
    this.showLogs = false;
    this.logs = [];
    this.filteredLogs = [];
    this.logsLoading = false;
  }

  isJSONViewObject(value: object | string) {
    return _isObject(value);
  } 

  onViewParameters(data: Process) {
    this.selectedMessageJSON = data.runParams;

    if (_isObject(this.selectedMessageJSON)) {
      this.selectedMessageJSON = Object.fromEntries(Object.entries(this.selectedMessageJSON).map(([key,value]) => {
        let parsedValue = value;

        try {
          parsedValue = JSON.parse(value);
        } catch {
        }

        return [key, parsedValue]
      }));
    }

    this.messageDrawerTitle = 'Run Parameters'
  }

  getStringMessage(obj: Object | string) {
    if (typeof obj === 'string') {
      return obj;
    }

    return JSON.stringify(obj);
  }

  onViewLogs(data: Process) {
    this.showLogs = true;
    this.logsLoading = true;
    this.logs = [];
    this.filteredLogs = [];

    if (this.category === ProcessesCategory.Pipelines) {
      this.pipeline.getPipelineRunLogs(data.dataFactoryPipelineRunId)
        .subscribe({
          next: (payload) => {
            this.logs = _get(payload, 'payload', []);
            this.filteredLogs = this.logs.filter(log => !!log.data?.messages?.length || log.data?.message || log.data?.error);
            this.logsLoading = false;
          },
          error: () => {
            this.logs = [];
            this.filteredLogs = [];
            this.logsLoading = false;
          }
        })

        this.messageDrawerTitle = `[${data.dataPipelineName}] Pipeline Run Logs`
    } else {
      this.processService.getProcessRunLogs(data.runId, this.category)
        .subscribe({
          next: (payload) => {
            this.logs = _get(payload, 'payload', []);
            this.filteredLogs = this.logs.filter(log => !!log.data?.messages?.length || log.data?.message || log.data?.error);
            this.logsLoading = false;
          },
          error: () => {
            this.logs = [];
            this.filteredLogs = [];
            this.logsLoading = false;
          }
        })

      this.messageDrawerTitle = 'Process Logs'
    }
 
    
  }

  get messageToCopy() {
    return this.selectedMessageJSON ? JSON.stringify(this.selectedMessageJSON) : ''
  }

  onDownloadDocument(fileId: string, clientId: string) {
    this.downloadFile(fileId, clientId);
  }

  downloadFile(fileId: string, clientId: string) {
      this.downloadingFileId = fileId;

      this.helpService
          .getAccessToken(clientId, fileId, 'data')
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
              next: (payload) => {
                  let sasToken = _get(payload, 'sasToken', '');
                  let docName = _get(payload, 'docName', '');
                  this.helpService.downloadFile(sasToken, docName);
              },
              error: (response) => {
                  const message =
                      response.error && response.error.message
                          ? response.error.message
                          : 'Cannot download the file.';
                  this.message.error(message, { nzDuration: 6000 });
                  this.downloadingFileId = undefined;
              },
              complete: () => {
                this.downloadingFileId = undefined;
              }
          });
  }
}
