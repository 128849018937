import { createAction, props } from '@ngrx/store';
import * as UploadPortalActionTypes from './constants';
import { UploadPortal } from '../../models/upload-portal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { UploadPortalVersion } from '../../models/upload-portal-version';
import { UploadPortalType } from '../../models/upload-portal-type.model';
import { DataUploadHistory } from '../../models/data-upload-history';
import {
    UploadPortalHistoryFilter,
    UploadPortalTypeInput,
} from '../../../core/services/upload-portal/upload-portal.service';
import { UploadPortalStatus } from '../../models/upload-portal-status.model';
import { UploadPortalStatusVersion } from '../../models/upload-portal-status-version';

export const uploadPortalListFailed = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_LIST_FAILED,
    props<{ error: Object }>()
);

export const uploadPortalCreateFailed = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_CREATE_FAILED,
    props<{ error: Object }>()
);

export const uploadPortalUpdateRequest = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_UPDATE_REQUEST,
    props<{ createUploadPortalReqJson: any; uploadPortalFile?: NzUploadFile }>()
);

export const uploadPortalUpdateReceive = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_UPDATE_RECEIVE,
    props<{ uploadPortal: UploadPortal }>()
);

export const uploadPortalUpdateFailed = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_UPDATE_FAILED,
    props<{ error: Object }>()
);
export const uploadPortalVersionsRequest = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_VERSIONS_REQUEST,
    props<{ uploadPortalId: string }>()
);

export const uploadPortalVersionsReceive = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_VERSIONS_RECEIVE,
    props<{ items: UploadPortalVersion[]; uploadPortalId: string }>()
);

export const uploadPortalVersionsFailed = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_VERSIONS_FAILED,
    props<{ error: Object; uploadPortalId: string }>()
);

export const uploadPortalTypesRequest = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_TYPES_REQUEST,
    props<{ clientId: string }>()
);

export const uploadPortalTypesReceive = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_TYPES_RECEIVE,
    props<{ items: UploadPortalType[] }>()
);

export const uploadPortalTypesFailed = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_TYPES_FAILED,
    props<{ error: Object }>()
);

export const downloadUploadPortalFileRequest = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_DOWNLOAD_FILE_REQUEST,
    props<{ uploadPortalId: string; uploadPortalFileName: string, filter?: UploadPortalHistoryFilter }>()
);

export const downloadUploadPortalFileReceive = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_DOWNLOAD_FILE_RECEIVE,
    props<{ uploadPortalId: string, filter?: UploadPortalHistoryFilter }>()
);

export const downloadUploadPortalFileFailed = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_DOWNLOAD_FILE_FAILED,
    props<{ error: Object }>()
);

export const uploadUploadPortalRequest = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_REQUEST,
    props<{ uploadPortalId: string; uploadPortalFiles: any[] }>()
);

export const uploadUploadPortalsReceive = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_RECEIVE
);

export const uploadUploadPortalFailed = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_FAILED,
    props<{ error: Object }>()
);

export const userUploadPortalsRequest = createAction(
    UploadPortalActionTypes.USER_UPLOAD_PORTALS_REQUEST,
    props<{ uploadPortalTypeId: string }>()
);
export const userUploadPortalTypesRequest = createAction(
    UploadPortalActionTypes.USER_UPLOAD_PORTAL_TYPES_REQUEST
);

export const userUploadPortalsReceive = createAction(
    UploadPortalActionTypes.USER_UPLOAD_PORTALS_RECEIVE,
    props<{ items: UploadPortal[] }>()
);

export const userUploadPortalTypesReceive = createAction(
    UploadPortalActionTypes.USER_UPLOAD_PORTAL_TYPES_RECEIVE,
    props<{ items: UploadPortalType[] }>()
);

export const userUploadPortalsFailed = createAction(
    UploadPortalActionTypes.USER_UPLOAD_PORTALS_FAILED,
    props<{ error: Object }>()
);
export const userUploadPortalTypesFailed = createAction(
    UploadPortalActionTypes.USER_UPLOAD_PORTAL_TYPES_FAILED,
    props<{ error: Object }>()
);

export const userDataUploadHistoryRequest = createAction(
    UploadPortalActionTypes.USER_DATA_UPLOAD_HISTORY_REQUEST,
    props<{
        uploadPortalId: string;
        reset?: boolean;
        filter?: UploadPortalHistoryFilter;
    }>()
);

export const userDataUploadHistoryReceive = createAction(
    UploadPortalActionTypes.USER_DATA_UPLOAD_HISTORY_RECEIVE,
    props<{ items: DataUploadHistory[]; reset?: boolean }>()
);

export const userDataUploadHistoryFailed = createAction(
    UploadPortalActionTypes.USER_DATA_UPLOAD_HISTORY_FAILED,
    props<{ error: Object }>()
);

export const createUploadPortalTypeRequest = createAction(
    UploadPortalActionTypes.CREATE_UPLOAD_PORTAL_TYPE_REQUEST,
    props<{ dateTemplateTypeInput: UploadPortalTypeInput }>()
);

export const createUploadPortalTypeReceive = createAction(
    UploadPortalActionTypes.CREATE_UPLOAD_PORTAL_TYPE_RECEIVE
);

export const createUploadPortalTypeFailed = createAction(
    UploadPortalActionTypes.CREATE_UPLOAD_PORTAL_TYPE_FAILED,
    props<{ error: Object }>()
);

export const updateUploadPortalTypeRequest = createAction(
    UploadPortalActionTypes.UPDATE_UPLOAD_PORTAL_TYPE_REQUEST,
    props<{ dateTemplateTypeInput: UploadPortalTypeInput }>()
);

export const updateUploadPortalTypeReceive = createAction(
    UploadPortalActionTypes.UPDATE_UPLOAD_PORTAL_TYPE_RECEIVE
);

export const updateUploadPortalTypeFailed = createAction(
    UploadPortalActionTypes.UPDATE_UPLOAD_PORTAL_TYPE_FAILED,
    props<{ error: Object }>()
);

export const dataCollectionStatusesRequest = createAction(
    UploadPortalActionTypes.DATA_COLLECTION_STATUSES_REQUEST,
    props<{ statusTypeId: string }>()
);

export const dataCollectionStatusesReceive = createAction(
    UploadPortalActionTypes.DATA_COLLECTION_STATUSES_RECEIVE,
    props<{ statusTypeId: string; items: UploadPortalStatus[] }>()
);

export const dataCollectionStatusesFailed = createAction(
    UploadPortalActionTypes.DATA_COLLECTION_STATUSES_FAILED,
    props<{ statusTypeId: string; error: Object }>()
);

export const uploadPortalStatusHistoryRequest = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_STATUS_HISTORY_REQUEST,
    props<{
        limit?: number;
        uploadPortalId: string;
        reset?: boolean;
        filter?: UploadPortalHistoryFilter;
    }>()
);

export const uploadPortalStatusHistoryReceive = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_STATUS_HISTORY_RECEIVE,
    props<{
        uploadPortalId: string;
        items: UploadPortalStatusVersion[];
        reset?: boolean;
    }>()
);

export const uploadPortalStatusHistoryFailed = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_STATUS_HISTORY_FAILED,
    props<{ uploadPortalId: string; error: Object }>()
);

export const updateUploadPortalUploadStatusRequest = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_UPLOAD_STATUS_UPDATE_REQUEST,
    props<{
        uploadPortalId: string;
        clientId: string;
        hint: string;
        collectionStatusId: string;
    }>()
);

export const updateUploadPortalUploadStatusReceive = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_UPLOAD_STATUS_UPDATE_RECEIVE,
    props<{ uploadPortalId: string; clientId: string }>()
);

export const updateUploadPortalUploadStatusFailed = createAction(
    UploadPortalActionTypes.UPLOAD_PORTAL_UPLOAD_STATUS_UPDATE_FAILED,
    props<{ error: object }>()
);
