<div
    class="portal-status-form-container"
    *hasPermission="['UPLOAD_PORTAL_MGMT']; class: 'disabled'"
>
    <form
        class="portal-status__form"
        [formGroup]="portalStatusFormGroup"
        nz-form
        (ngSubmit)="submitPortalStatusForm()"
    >
        <div nz-row nzGutter="4">
            <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label nzRequired>Status name</nz-form-label>
                    <nz-form-control
                        [nzSpan]="24"
                        nzValidateStatus
                        nzHasFeedback
                    >
                        <nz-input-group nzSize="large">
                            <input
                                formControlName="collectionStatusName"
                                nz-input
                                placeholder="Enter Status Name"
                            />
                        </nz-input-group>
                        <ng-template #portalTypeNameErrorTmpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please enter status name
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzRequired>Status color</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <nz-input-group nzSize="large">
                            <app-color-input
                                [initColor]="
                                    portalStatusFormGroup.value.statusColor
                                "
                                (colorChange)="onColorChanged($event)"
                            ></app-color-input>
                        </nz-input-group>
                        <ng-template #groupColorErrorTmpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please select status color
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control
                        class="drawer-actions-control"
                        [nzSpan]="24"
                    >
                        <div class="drawer-controls">
                            <button
                                *hasPermission="['UPLOAD_PORTAL_MGMT']"
                                type="button"
                                nzSize="large"
                                nz-button
                                [disabled]="
                                    !portalStatusFormGroup.valid ||
                                    !portalStatusFormGroup.dirty || saving
                                "
                                [nzLoading]="saving"
                                (click)="submitPortalStatusForm()"
                                nzType="primary"
                            >
                                <span>Save</span>
                            </button>
                            <button
                                type="button"
                                nzSize="large"
                                nz-button
                                (click)="onClosePortalTypeForm()"
                            >
                                <span>Close</span>
                            </button>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </form>
</div>
