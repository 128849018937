import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { TicketForm } from 'src/app/store/models/ticket-form.model';

@Component({
    selector: 'app-ticketing-form',
    templateUrl: './ticketing-form.component.html',
    styleUrls: ['./ticketing-form.component.less'],
})
export class TicketingFormComponent implements OnInit, AfterViewInit {
    @ViewChild('frame') frame: ElementRef<HTMLIFrameElement>;

    @Input() ticket: TicketForm;

    url: string = '';

    css: string = `
    #content_for_layout, body {
      background-color: white;
      font-family: revert;
    }
    .section-head {
      display: none;
    }
    .padding-for-content {
      padding: 16px 8px 0;
    }
    label .glyphicon {
      display: hidden;
    }
    label .field-prompt {
      background-color: #2f99cc;
    }
    #new-guest-request-div .label {
      display: hidden;
    }
    .form-footer {
      height: 50px;
      border-top: none;
      padding: 16px 8px 0;
    }
    .form-footer .btn {
      background-color: #2f99cc;
      border-color: #2f99cc;
      border-radius: 0;
    }
    input.form-control {
      color: rgba(0, 0, 0, 0.85);
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      box-shadow: none;
    }
    .form-group > label, #ticket_description_label {
      font-size: 1.5rem;
      font-weight: 500;
    }
    .btn-success {
      background-color: #2f99cc
    }
    .glyphicon-upload:before {
      content: "\\e022";
      font-size: 1.25rem;
    }
    .glyphicon-exclamation-sign:before {
      color: #3099cc;
    }
    .btn-default {
      color: #3099cc;
      border-radius: 0;
      border: 1px solid #d9d9d9;
    }
    select.form-control {
      color: rgba(0, 0, 0, 0.85);
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      box-shadow: none;
    }
    img[alt~="Calendar-button"] {
      filter: grayscale(1);
      margin: 0 8px 0 6px;
    }
    img[alt~="Calendar-button"] ~ span {
      display: none;
    }
    img[alt~="Calendar-button"] ~ a {
      color: #3099cc;
      font-weight: 400;
    }
    .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
      background-color: #f7f7f7;
    }
  `;

    constructor() {}

    ngOnInit(): void {
        this.url =
            'https://alignedincentives.mojohelpdesk.com/widgets/create_request#/ticket-form/' +
            this.ticket.id;
    }

    ngAfterViewInit() {
        this.frame.nativeElement.onload = () => {
            this.frame.nativeElement.contentWindow.postMessage(
                {
                    css: this.css,
                },
                '*'
            );
        };
    }
}
