import { Component } from '@angular/core';

@Component({
  selector: 'app-user-feedback-page',
  standalone: true,
  imports: [],
  templateUrl: './user-feedback-page.component.html',
  styleUrl: './user-feedback-page.component.less'
})
export class UserFeedbackPageComponent {

}
