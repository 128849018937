import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import _keys from 'lodash/keys';
import _reduce from 'lodash/reduce';

import { APP_CONFIG } from './config';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(private http: HttpClient) {}

    get(
        url: string,
        params: object = {},
        headers: HttpHeaders = null
    ): Observable<any> {
        return this.request('get', url, params, {}, headers);
    }

    post(url: string, body: object = {}): Observable<any> {
        return this.request('post', url, {}, body);
    }

    put(url: string, body: any): Observable<any> {
        return this.request('put', url, {}, body);
    }

    patch(url: string, body: object): Observable<any> {
        return this.request('patch', url, {}, body);
    }

    delete(url: string, body: object = {}): Observable<any> {
        return this.request('delete', url, {}, body);
    }

    request(
        method: string,
        url: string,
        params: object = {},
        body: object = {},
        headers: HttpHeaders = null,
        options: object = {}
    ): Observable<any> {
        const opts = {
            params: _reduce(
                _keys(params),
                (res, param) => res.set(param, params[param]),
                new HttpParams()
            ),
            body,
            headers,
            ...options,
        };

        return this.http.request(method, this.composeUrl(url), { ...opts });
    }

    postFormData(url: string, body: object): Observable<any> {
        let headers = new HttpHeaders();
        headers.set('Content-type', 'multipart/form-data');
        return this.request('POST', url, {}, body, headers);
    }

    postFormDataWithProgress(url: string, body: object): Observable<any> {
        let headers = new HttpHeaders();
        headers.set('Content-type', 'multipart/form-data');

        let options = {
            reportProgress: true,
            observe: 'events',
        };

        return this.request('POST', url, {}, body, headers, options);
    }

    putFormData(url: string, body: object): Observable<any> {
        let headers = new HttpHeaders();
        headers.set('Content-type', 'multipart/form-data');

        return this.request('PUT', url, {}, body, headers);
    }

    downloadFile(url: string, params: object = {}) {
        let headers = new HttpHeaders();

        return this.request('GET', url, params, {}, headers, {
            responseType: 'blob',
        });
    }

    private composeUrl(url: string): string {
        return environment.apiUrl + APP_CONFIG.prefix.default + url;
    }

    getBaseURL() {
        return this.composeUrl('');
    }
}
