import { createAction, props } from '@ngrx/store';
import * as UserActionTypes from './constants';
import { CustomerUser } from '../../models/customer-user.model';
import { UserInput } from '../../../core/services/user/user.service';

export const customerUsersListRequest = createAction(
    UserActionTypes.USERS_LIST_REQUEST,
    props<{ clientId: string }>()
);

export const customerUsersListReceive = createAction(
    UserActionTypes.USERS_LIST_RECEIVE,
    props<{ items: CustomerUser[] }>()
);

export const customerUsersFailed = createAction(
    UserActionTypes.USERS_LIST_FAILED,
    props<{ error: Error }>()
);

export const customerUserRequest = createAction(
    UserActionTypes.USER_REQUEST,
    props<{ userId: string }>()
);

export const customerUserReceive = createAction(
    UserActionTypes.USER_RECEIVE,
    props<{ customerUser: CustomerUser }>()
);

export const customerUserFailed = createAction(
    UserActionTypes.USER_FAILED,
    props<{ error: Error }>()
);

export const createCustomerUserRequest = createAction(
    UserActionTypes.CREATE_USER_REQUEST,
    props<{ userInput: UserInput }>()
);

export const createCustomerUserReceive = createAction(
    UserActionTypes.CREATE_USER_RECEIVE,
    props<{ customerUser: CustomerUser }>()
);

export const createCustomerUserFailed = createAction(
    UserActionTypes.CREATE_USER_FAILED,
    props<{ error: Error }>()
);

export const updateCustomerUserRequest = createAction(
    UserActionTypes.UPDATE_USER_REQUEST,
    props<{ input: UserInput }>()
);

export const updateCustomerUserReceive = createAction(
    UserActionTypes.UPDATE_USER_RECEIVE,
    props<{ user: CustomerUser }>()
);

export const updateCustomerUserFailed = createAction(
    UserActionTypes.UPDATE_USER_FAILED,
    props<{ error: string }>()
);
