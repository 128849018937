import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ElementRef } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ApiService } from '../api/api.service';
import { TicketForm } from 'src/app/store/models/ticket-form.model';

@Injectable({
    providedIn: 'root',
})
export class HelpService {
    constructor(
        private apiService: ApiService,
        private message: NzMessageService
    ) {}

    public getDocuments(clientId: string): Observable<any[]> {
        return this.apiService.get(`files?clientId=${clientId}`);
    }

    public getSheets(clientId: string): Observable<any[]> {
        return this.apiService.get(`smartsheets?clientId=${clientId}`);
    }

    public deleteDocument(clientId: string, fileId: string): Observable<any> {
        return this.apiService.delete(`files/${fileId}?clientId=${clientId}`);
    }

    public deleteSheet(clientId: string, sheetId: string): Observable<any> {
        return this.apiService.delete(
            `smartsheets/${sheetId}?clientId=${clientId}`
        );
    }

    public getAccessToken(
        clientId: string,
        fileId: string,
        type: string
    ): Observable<any> {
        return this.apiService.get(
            `files/${fileId}/token?clientId=${clientId}&type=${type}`
        );
    }

    public getSheetUrl(clientId: string, sheetId: string): Observable<any> {
        return this.apiService.get(
            `smartsheets/${sheetId}/url?clientId=${clientId}`
        );
    }

    public retrieveDocument(clientId: string, fileId: string): Observable<any> {
        return this.apiService.get(`files/${fileId}?clientId=${clientId}`);
    }

    public retrieveSheet(clientId: string, sheetId: string): Observable<any> {
        return this.apiService.get(
            `smartsheets/${sheetId}?clientId=${clientId}`
        );
    }

    public retrieveDefaultDocument(
        clientId: string,
        type: string
    ): Observable<any> {
        return this.apiService.get(
            `files/default?clientId=${clientId}&type=${type}`
        );
    }

    public retrieveDefaultClientId(): Observable<any> {
        return this.apiService.get(`files/default-client`);
    }

    public updateDocument(formData: any, fileId: string): Observable<any> {
        return this.apiService.request(
            'POST',
            `files/${fileId}`,
            null,
            formData,
            null,
            {
                reportProgress: true,
                observe: 'events',
            }
        );
    }

    public updateSheet(
        sheetData: SheetInput,
        sheetId: string
    ): Observable<any> {
        return this.apiService.put(`smartsheets/${sheetId}`, sheetData);
    }

    public createDocument(formData: any): Observable<any> {
        return this.apiService.request('POST', `files`, null, formData, null, {
            reportProgress: true,
            observe: 'events',
        });
    }

    public createSheet(sheetData: SheetInput): Observable<any> {
        return this.apiService.post(`smartsheets`, sheetData);
    }

    public fillPreviewFrame(
        tabPreview: ElementRef,
        contentType: string,
        content: string
    ) {
        const file = window.URL.createObjectURL(
            new Blob(
                [content ? content : '<html><head></head><body></body></html>'],
                { type: contentType }
            )
        );
        const preview = tabPreview.nativeElement;
        preview.src = file;
    }

    public fetchRemoteFile(
        url: string,
        tabPreview: ElementRef,
        callback: any,
        message?: string
    ) {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        const _this = this;
        xhr.onload = function (_) {
            if (this.status === 200) {
                _this.fillPreviewFrame(
                    tabPreview,
                    'application/pdf',
                    this.response
                );
                if (callback) callback();
            } else {
                if (callback) callback();
                if (message) {
                    _this.message.error(message, { nzDuration: 3000 });
                } else {
                    throw new Error('Error trying to load the file.');
                }
            }
        };
        xhr.send();
    }

    public downloadFile(url: string, docName: string) {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        const _this = this;
        xhr.onload = function (_) {
            if (this.status === 200) {
                var a = document.createElement('a');
                var url = window.URL.createObjectURL(this.response);
                a.href = url;
                a.download = docName;
                document.body.append(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            } else {
                _this.message.error('Error trying to download the file.', {
                    nzDuration: 3000,
                });
            }
        };
        xhr.send();
    }

    public fetchRemoteSmartsheet(
        url: string,
        tabPreview: ElementRef,
        callback: any,
        message?: string
    ) {
        tabPreview.nativeElement.src = url;
        if (callback) callback();
    }

    getHelpdeskTicketForms(): Observable<{ payload: TicketForm[] }> {
        return this.apiService.get(`helpdesk/ticket-forms`);
    }
}

export interface SheetInput {
    sheetId?: string;
    clientId: string;
    sheetNumber: number;
    sheetName: string;
    description: string;
    readWrite: boolean;
}
