import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import { AssuranceReportConfig, AssuranceReportFilter, AssuranceReportItem, AssuranceTotals, FilterQueryParam } from 'src/app/store/models/assurance-report.model';
import _uniq from 'lodash/uniq';
import _last from 'lodash/last';
import _pick from 'lodash/pick';
import _isNumber from 'lodash/isNumber';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

@Injectable({
  providedIn: 'root'
})
export class AssuranceService {

  constructor(private api: ApiService) { }

  putReportConfig(clientId: string, input: AssuranceReportConfig): Observable<{ payload: AssuranceReportConfig }> {
    return this.api.put(`report-portal/clients/${clientId}/assurance-report/config/report-years`, input)
  }

  getResultTableColumns(clientId: string, tableName: string): Observable<{ payload: { columnName: string, columnLabel: string }[] }> {
    return this.api.get(`report-portal/clients/${clientId}/assurance-report/tables/${tableName}/result-columns`)
  }

  putResultTableColumns(clientId: string, tableName: string, columns: { columnName: string, columnLabel: string }[]): Observable<{ payload: { columnName: string, columnLabel: string }[] }> {
    return this.api.put(`report-portal/clients/${clientId}/assurance-report/tables/${tableName}/result-columns`, columns)
  }

  getReportConfig(clientId: string): Observable<{ payload: AssuranceReportConfig }> {
    return this.api.get(`report-portal/clients/${clientId}/assurance-report/config/report-years`)
  }

  getTableColumns(clientId: string, tableName: string): Observable<{ payload: string[] }> {
    return this.api.get(`report-portal/clients/${clientId}/assurance-report/tables/${tableName}/columns`)
  }

  getAssuranceTables(clientId: string): Observable<{ payload: string[] }> {
    return this.api.get(`report-portal/clients/${clientId}/assurance-report/assurance-table-names`)
  }

  private parseAssuranceReportParams = (filter: AssuranceReportFilter): object => {
    const params = {};

    const TREE_VALUE_DELIMITER = '~~~';
    const TREE_VALUE_GROUP_DELIMITER = '___';
    
    Object.entries(filter).forEach(
        ([filterKey, filterValue]) => {
          if (filterKey === FilterQueryParam.Site && filterValue) {
            const result = [];

            (filterValue as string[]).map(value => {
              if (value.startsWith(`location${TREE_VALUE_DELIMITER}`)) {
                result.push({ location: _last(value.split(TREE_VALUE_DELIMITER)) })
              } else {
                const [,location,site] = value.split(TREE_VALUE_DELIMITER);
                result.push({ location, site })
              }
            });

            if (result.length) {
              params[FilterQueryParam.Site] = result;
            }
          } else if (filterKey === FilterQueryParam.Grouping && filterValue) {
            const result = [];

            (filterValue as string[]).map(value => {
              const groups = value.split(TREE_VALUE_DELIMITER);

              const groupValues = groups.map(group => group.split(TREE_VALUE_GROUP_DELIMITER));

              result.push(
                groupValues.reduce((result, groupValue) => {
                  result[groupValue[0]] = groupValue[1];

                  return result;
                }, {})
              );
            });

            if (result.length) {
              params[FilterQueryParam.Grouping] = result;
            }
          } else if (filterKey === FilterQueryParam.Date && Array.isArray(filterValue) && filterValue.length) {
            params['dataStartDate'] = dayjs(dayjs((filterValue[0] as Date).getTime()).format('YYYY-MM-DD')).utc(true).startOf('day').utc().unix();
            params['dataEndDate'] = dayjs(dayjs((filterValue[1] as Date).getTime()).format('YYYY-MM-DD')).utc(true).startOf('day').utc().unix();
          } else if (filterKey === FilterQueryParam.ObservationID && _isNumber(filterValue)) {
            params[filterKey] = +filterValue;
          } else {
            if (!!filterValue && Array.isArray(filterValue)) {
              if (filterValue.length) {
                params[filterKey] = filterValue;
              }
            } else if (!!filterValue) {
              params[filterKey] = String(filterValue);
            }
        }
      }
    );

    return params;
  }

  getFilterValues<T extends FilterQueryParam = FilterQueryParam>(clientId: string, filterKey: T, filter: AssuranceReportFilter): Observable<T extends FilterQueryParam.Site ? { payload: { values: { location: string, sites: string[] }[] } } : T extends FilterQueryParam.ImpactAssessmentMethodology ? { payload: { values: { impactSerial: number, impactName: string }[] } } : { payload: { values: string[] } }> {
    const params = this.parseAssuranceReportParams(filter);

    return this.api.get(`report-portal/clients/${clientId}/assurance-report/filters/${filterKey}/values?filter=${btoa(JSON.stringify(params))}`);
  }

  getAssuranceReportData(clientId: string, filter: AssuranceReportFilter): Observable<{ payload: AssuranceReportItem[] }> {
    const params = this.parseAssuranceReportParams(filter);

    return this.api.get(`report-portal/clients/${clientId}/assurance-report/data?filter=${btoa(JSON.stringify(params))}`);
  }

  getAssuranceReportTotals(clientId: string, filter: AssuranceReportFilter): Observable<{ payload: AssuranceTotals }> {
    const params = this.parseAssuranceReportParams(filter);

    return this.api.get(`report-portal/clients/${clientId}/assurance-report/totals?filter=${btoa(JSON.stringify(params))}`);
  }
}
