<div class="client-database-container">
    <nz-tabset [nzSelectedIndex]="selectedTabIndex" (nzSelectedIndexChange)="onSelectedTabChanged($event)">
        <nz-tab nzTitle="Tables">
            <div nz-flex nzAlign="center" nzJustify="space-between" class="client-database__controls">
                <div nz-flex nzAlign="center" nzGap="small">
                    <app-text-search-input
                        #tableNameSearch
                        (search)="onSearch($event)"
                    ></app-text-search-input>
                    <nz-select
                        *ngIf="selectedClientId === aiClientId"
                        [nzDisabled]="!selectedClientId"
                        [ngModel]="schemaName"
                        (ngModelChange)="onSchemaChanged($event)"
                        nzPlaceHolder="Select Schema"
                    >
                        <nz-option
                            *ngFor="let schema of schemas"
                            nzCustomContent
                            [nzValue]="schema.schemaName"
                            [nzLabel]="schema.schemaName"
                        >
                            <div class="schema-item-container">
                                <span>
                                    {{ schema.schemaName }}
                                </span>
                                <span
                                    *ngIf="schema.schemaDescription"
                                    nz-tooltip
                                    nzTooltipPlacement="top"
                                    [nzTooltipTitle]="schema.schemaDescription"
                                    nz-icon
                                    nzType="info-circle"
                                    nzTheme="outline"
                                ></span>
                            </div>
                        </nz-option>
                    </nz-select>
                </div>
                <div nz-flex nzAlign="center" nzGap="small">
                    <ng-container *hasPermission="['SUPERUSER']">
                        <button
                            *ngIf="!isProduction"
                            [disabled]="!!tablesLoading"
                            nz-button
                            nzType="primary"
                            nzGhost
                            (click)="onAddClientTable()"
                        >
                            <span nz-icon nzType="plus" nzTheme="outline"></span>
                            New Table
                        </button>
                    </ng-container>
                    <button
                        *ngIf="
                            tables?.length &&
                            clientService.aiClientId !== selectedClientId
                        "
                        [disabled]="!selectedClientId"
                        type="button"
                        nz-button
                        (click)="onCreateClientDataTable()"
                        nzType="primary"
                    >
                        <i nz-icon nzType="plus" nzTheme="outline"></i>
                        <span>Create ClientData table</span>
                    </button>
                    <button
                        *ngIf="!tablesLoading && !tables?.length"
                        [disabled]="!selectedClientId"
                        type="button"
                        nz-button
                        (click)="onCreateDatabase()"
                        nzType="primary"
                    >
                        <i nz-icon nzType="plus" nzTheme="outline"></i>
                        <span>Create database</span>
                    </button>
                </div>
            </div>
            <div
                class="client-database__empty"
                *ngIf="!tables?.length && !tablesLoading"
            >
                <i nz-icon nzType="empty-db" nzTheme="fill"></i>
                <p class="client-database-empty__text">Now your database is empty</p>
            </div>
            <div *ngIf="tables?.length" class="client-database__table">
                <app-infinite-scroll
                    [loaded]="tablesLoaded"
                    [loading]="tablesLoading"
                    (scrolled)="onTableScrolled()"
                >
                    <nz-table
                        [nzPageSize]="9999999"
                        #table
                        [nzLoading]="tablesLoading"
                        [nzSize]="'middle'"
                        nzHideOnSinglePage
                        [nzData]="tables"
                    >
                        <thead (nzSortOrderChange)="orderChange($event)">
                            <tr>
                                <th
                                    *ngFor="let column of columns"
                                    [nzSortFn]="true"
                                    [nzWidth]="column.width"
                                    [nzSortDirections]="column.sortDirections"
                                    nzColumnKey="{{ column.key }}"
                                >
                                    {{ column.title }}
                                </th>
                                <th nzWidth="35%"></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let data of table.data; let i = index"
                                class="editable-row"
                            >
                                <td
                                    [innerHTML]="
                                        data.tableName | highlight: query | safe: 'html'
                                    "
                                ></td>
                                <td>
                                    {{
                                        data.createdStamp | date: 'dd MMM yyyy, h:mm a'
                                    }}
                                </td>
                                <td class="actions__cell">
                                    <i nz-icon nzType="dots" nzTheme="fill"></i>
                                    <div class="table-actions-container">
                                        <i
                                            (click)="onInfo(data.tableName)"
                                            nz-tooltip
                                            nzTooltipPlacement="top"
                                            nzTooltipTitle="Information"
                                            nzTooltipOverlayClassName="primary-tooltip"
                                            class="action-icon"
                                            nz-icon
                                            nzType="info-circle"
                                            nzTheme="outline"
                                        >
                                        </i>
                                        <i
                                            *ngIf="data.canAppend"
                                            (click)="onAppend(data)"
                                            nz-tooltip
                                            nzTooltipPlacement="top"
                                            nzTooltipTitle="Append"
                                            nzTooltipOverlayClassName="primary-tooltip"
                                            class="action-icon"
                                            nz-icon
                                            nzType="plus"
                                            nzTheme="outline"
                                        ></i>
                                        <i
                                            *ngIf="data.canUpdate"
                                            (click)="onUpdate(data)"
                                            nz-tooltip
                                            nzTooltipPlacement="top"
                                            nzTooltipTitle="Update"
                                            nzTooltipOverlayClassName="primary-tooltip"
                                            class="action-icon"
                                            nz-icon
                                            nzType="update"
                                            nzTheme="outline"
                                        ></i>
                                        <i
                                            *ngIf="data.canDelete"
                                            (click)="onRemove(data)"
                                            nz-tooltip
                                            nzTooltipPlacement="top"
                                            nzTooltipTitle="Remove"
                                            nzTooltipOverlayClassName="danger-tooltip"
                                            class="action-icon"
                                            nz-icon
                                            nzType="minus"
                                        ></i>
                                        <i
                                            *ngIf="data.canModify"
                                            (click)="onModify(data)"
                                            nz-tooltip
                                            nzTooltipPlacement="top"
                                            nzTooltipTitle="Modify"
                                            nzTooltipOverlayClassName="primary-tooltip"
                                            class="action-icon"
                                            nz-icon
                                            nzType="modify"
                                            nzTheme="outline"
                                        ></i>
                                        <ng-container
                                            *ngIf="
                                                isExporting(data.tableName);
                                                else exportControl
                                            "
                                        >
                                            <i
                                                nz-icon
                                                class="action-icon"
                                                [style.color]="'#3099cc'"
                                                nzType="loading"
                                                nzTheme="outline"
                                            ></i>
                                        </ng-container>
                                        <ng-template #exportControl>
                                            <i
                                                *ngIf="data.canExport"
                                                (click)="onExport(data.tableName)"
                                                nz-tooltip
                                                nzTooltipPlacement="top"
                                                nzTooltipTitle="Export"
                                                nzTooltipOverlayClassName="primary-tooltip"
                                                class="action-icon"
                                                nz-icon
                                                nzType="export"
                                                nzTheme="outline"
                                            ></i>
                                        </ng-template>
                                        <i
                                            *ngIf="data.canTruncate"
                                            (click)="onTruncateTable(data.tableName)"
                                            nz-tooltip
                                            nzTooltipPlacement="top"
                                            nzTooltipTitle="Truncate"
                                            nzTooltipOverlayClassName="danger-tooltip"
                                            class="action-icon"
                                            nz-icon
                                            nzType="clean"
                                            nzTheme="outline"
                                        ></i>
                                        <i
                                            *ngIf="data.canDrop"
                                            (click)="onDeleteTable(data.tableName)"
                                            nz-tooltip
                                            nzTooltipPlacement="top"
                                            nzTooltipTitle="Delete"
                                            nzTooltipOverlayClassName="danger-tooltip"
                                            class="action-icon delete"
                                            nz-icon
                                            nzType="delete"
                                            nzTheme="outline"
                                        ></i>
                                        <i
                                            (click)="onHistory(data.tableName)"
                                            nz-tooltip
                                            nzTooltipPlacement="top"
                                            nzTooltipTitle="Update History"
                                            nzTooltipOverlayClassName="primary-tooltip"
                                            class="action-icon"
                                            nz-icon
                                            nzType="calendar"
                                            nzTheme="outline"
                                        ></i>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </app-infinite-scroll>
            </div>
        </nz-tab>
        <nz-tab nzTitle="Transaction History">
            <ng-template nz-tab>
                <app-process-history-table [category]="categories.Database" *ngIf="selectedTabIndex === 1"></app-process-history-table>
            </ng-template>
        </nz-tab>
    </nz-tabset>
    <nz-drawer
        [nzWidth]="920"
        [nzVisible]="modifyFormMode"
        [nzTitle]="drawerTitle"
        [nzMaskClosable]="false"
        (nzOnClose)="onCloseModify()"
    >
        <ng-container *nzDrawerContent>
            <app-client-database-table-modify-form
                (closed)="onCloseModify()"
                *ngIf="selectedTableName && selectedClientId"
                [clientId]="selectedClientId"
                [schemaName]="schemaName"
                [tableName]="selectedTableName"
            ></app-client-database-table-modify-form>
        </ng-container>
    </nz-drawer>
    <nz-drawer
        [nzWidth]="360"
        [nzVisible]="appendFormMode"
        [nzTitle]="drawerTitle"
        (nzOnClose)="onCloseAppend()"
    >
        <ng-container *nzDrawerContent>
            <app-client-database-table-append-form
                *ngIf="selectedTableName && selectedClientId"
                [clientId]="selectedClientId"
                [tableName]="selectedTableName"
                [schemaName]="schemaName"
                (closed)="onCloseAppend($event)"
            ></app-client-database-table-append-form>
        </ng-container>
    </nz-drawer>
    <nz-drawer
        [nzWidth]="360"
        [nzVisible]="updateFormMode"
        [nzTitle]="drawerTitle"
        (nzOnClose)="onCloseUpdate($event)"
    >
        <ng-container *nzDrawerContent>
            <app-client-database-table-update-form
                *ngIf="selectedTableName && selectedClientId"
                [tableName]="selectedTableName"
                [clientId]="selectedClientId"
                [schemaName]="schemaName"
                (closed)="onCloseUpdate($event)"
            ></app-client-database-table-update-form>
        </ng-container>
    </nz-drawer>
    <nz-drawer
        [nzWidth]="360"
        [nzVisible]="removeFormMode"
        [nzTitle]="drawerTitle"
        (nzOnClose)="onCloseRemove()"
    >
        <ng-container *nzDrawerContent>
            <app-client-database-table-remove-form
                *ngIf="selectedTableName && selectedClientId"
                [tableName]="selectedTableName"
                [clientId]="selectedClientId"
                [schemaName]="schemaName"
                (closed)="onCloseRemove()"
            ></app-client-database-table-remove-form>
        </ng-container>
    </nz-drawer>
    <ng-template #drawerTitle>
        <div class="drawer-title">
            <h3 *ngIf="updateFormMode">Update data to table</h3>
            <h3 *ngIf="appendFormMode">Append data to table</h3>
            <h3 *ngIf="modifyFormMode">Modify</h3>
            <h3 *ngIf="tableInfoMode">Table Information</h3>
            <h5>{{ selectedTableName }}</h5>
        </div>
    </ng-template>
    <nz-drawer
        nzMaskClosable
        [nzWidth]="360"
        [nzVisible]="createClientDataMode"
        nzTitle="Create ClientData & VersionMaster tables"
        (nzOnClose)="closeCreateClientDataMode()"
    >
        <ng-container *nzDrawerContent>
            <app-client-data-table-form
                *ngIf="createClientDataMode && selectedClientId"
                [clientId]="selectedClientId"
                (closed)="closeCreateClientDataMode($event)"
            ></app-client-data-table-form>
        </ng-container>
    </nz-drawer>
    <nz-drawer
        nzMaskClosable
        [nzWidth]="360"
        [nzVisible]="createDatabaseFormMode"
        nzTitle="Create Database"
        (nzOnClose)="closeCreateDatabaseFormMode()"
    >
        <ng-container *nzDrawerContent>
            <app-client-database-form
                [clientId]="selectedClientId"
                *ngIf="createDatabaseFormMode && selectedClientId"
                (closed)="closeCreateDatabaseFormMode($event)"
            ></app-client-database-form>
        </ng-container>
    </nz-drawer>
    <nz-drawer
        nzMaskClosable
        [nzWidth]="1140"
        [nzVisible]="tableInfoMode"
        [nzTitle]="drawerTitle"
        (nzOnClose)="closeTableInfoMode()"
    >
        <ng-container *nzDrawerContent>
            <app-table-information
                [clientId]="selectedClientId"
                [tableName]="selectedTableName"
                [schemaName]="schemaName"
                *ngIf="tableInfoMode && selectedClientId"
            ></app-table-information>
        </ng-container>
    </nz-drawer>
    <nz-drawer
        [nzWidth]="920"
        [nzVisible]="addTableMode"
        nzTitle="Add Table"
        (nzOnClose)="onCloseAddTable()"
    >
        <ng-container *nzDrawerContent>
            <app-client-database-table-add
                (closed)="onCloseAddTable($event)"
                *ngIf="addTableMode && selectedClientId"
                [clientId]="selectedClientId"
                [schemaName]="schemaName"
            ></app-client-database-table-add>
        </ng-container>
    </nz-drawer>
</div>
