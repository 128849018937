import { Pipe, PipeTransform } from '@angular/core';

interface Token {
    chunk: string;
    highlighted: boolean;
}

@Pipe({
    name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
    transform(rawValue: any, query: string): any {
        if (!query) {
            return rawValue;
        }

        const value = `${rawValue}`;

        let textCopied = value.slice(0);
        const chunks = value.toLowerCase().split(query.toLowerCase());

        const tokens: Token[] = chunks.reduce(
            (result: Token[], chunk: string, i: number) => {
                if (chunk.length) {
                    result.push({
                        chunk: textCopied.slice(0, chunk.length),
                        highlighted: false,
                    });
                }
                if (i !== chunks.length - 1) {
                    result.push({
                        chunk: textCopied.slice(
                            chunk.length,
                            chunk.length + query.length
                        ),
                        highlighted: true,
                    });
                }
                textCopied = textCopied.slice(chunk.length + query.length);

                return result;
            },
            []
        );

        return tokens
            .map(({ chunk, highlighted }) =>
                highlighted
                    ? `<span style="background-color: #3099cc; color: white;">${chunk}</span>`
                    : `<span>${chunk}</span>`
            )
            .join('');
    }
}
