export const USERS_LIST_REQUEST = 'users list request';
export const USERS_LIST_RECEIVE = 'users list receive';
export const USERS_LIST_FAILED = 'users list failed';

export const USER_REQUEST = 'user request';
export const USER_RECEIVE = 'user receive';
export const USER_FAILED = 'user failed';

export const CREATE_USER_REQUEST = 'create user request';
export const CREATE_USER_RECEIVE = 'create user receive';
export const CREATE_USER_FAILED = 'create user failed';

export const UPDATE_USER_REQUEST = 'Update user request';
export const UPDATE_USER_RECEIVE = 'Update user receive';
export const UPDATE_USER_FAILED = 'Update user failed';
