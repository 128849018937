import { Component, DestroyRef, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AI_TOKEN_KEY, AuthService } from 'src/app/core/services/auth/auth.service';
import { BrowserStorageService } from 'src/app/core/services/browser-storage/browser-storage.service';
import { GlobalNotificationService } from 'src/app/core/services/global-notification/global-notification.service';
import { GlobalService, Mode } from 'src/app/core/services/global/global.service';
import { authLoginReceive, authLogoutRequest } from 'src/app/store/actions/auth/auth.actions';
import { NotificationEventType } from 'src/app/store/models/notification.model';
import { User } from 'src/app/store/models/user.model';
import { AppState } from 'src/app/store/state';
import { environment } from 'src/environments/environment';
import { selectAuthUser } from 'src/app/store/selectors/auth.selectors';
import { Observable, Subscription } from 'rxjs';
import { BugsnagClient } from 'src/app/bugsnag';
import { AI_CLIENT_ID } from 'src/app/core/services/customer/customer.service';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.less'
})
export class AppLayoutComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  
  menuModeOptions = [
      { label: 'Clients', value: Mode.Client },
      { label: 'Aligned Incentives', value: Mode.Admin }
    ];
  menuModeIndex: number = 0;
  modes = Mode;
  openMenuMap: { [name: string]: boolean } = {
      admin: false,
      clinets: false,
      data: false,
      internal: false,
      internalData: false,
      procurement: false,
  };
  isCollapsed: boolean = false;

  roles: string[];

  currentUser$: Observable<User>;
  lcaSettingsMode: boolean = false;

  @ViewChild('lcaSandboxReadyNotification')
  lcaSandboxReadyNotification: TemplateRef<{}>;

  notificationsSubs: Subscription[] = [];

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private notification: NzNotificationService,
    public globalService: GlobalService,
    public authService: AuthService,
    private globalNotificationService: GlobalNotificationService
  ) {
    this.currentUser$ = this.store.select(selectAuthUser);
  }

  ngOnInit(): void {
    this.currentUser$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user) => {
      this.roles = user?.userRoles ?? [];

      if (user?.userLoginId && this.roles?.length) {
          BugsnagClient.addMetadata('user', {
              clientId: user.clientId,
              roles: user.userRoles,
              email: user.userLoginId
          });

          this.globalNotificationService.initNotificationSub();

          const sub1 = this.globalNotificationService.listenNotifications(NotificationEventType.PROCESS_RUN_STATUS)
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(
                  message => {
                      if (message.body.statusId === 'PROCESS_RUN_SUCCEEDED') {
                          this.notification.success('Process successfully finished', message.body.message, { nzDuration: 0 })
                      }
                  }
              );
          
          this.notificationsSubs.push(sub1);
      } else {
          this.notificationsSubs.forEach(sub => {
              if (sub && !sub.closed) {
                  sub.unsubscribe();
              }
          });

          this.notificationsSubs = [];
      }
  });

  this.router.events.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
          event => {
              if (event instanceof NavigationEnd) {
                  if (event.url.includes('/clients') || event.url.includes('/admin/users') ||  event.url.includes('/admin/roles') || event.url.includes('/admin/database') || event.url.includes('/lca-engine/impact-coefficients') || event.url.includes('/lca-engine/sandbox') || event.url.includes('/process-history')) {
                      // prev menu is not admin
                      if (this.menuModeIndex !== 1) {
                          this.globalService.onModeChanged(this.menuModeOptions[1].value);
                          this.globalService.clientId$.next(AI_CLIENT_ID);
                          this.menuModeIndex = 1;
                          this.openMenuHandler('none');
                      }
                  }
              }
          }
      )
  }

  isNavActive(base: string): boolean {
    return this.router.isActive(base, { paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored'});
  }

  logout() {
      this.globalNotificationService.disconnect();
      this.store.dispatch(authLogoutRequest());
      this.notification.remove();
  }

  onMenuModeChanged(index: number) {
      this.globalService.onModeChanged(this.menuModeOptions[index].value);
      
      if (this.menuModeOptions[index].value === Mode.Client) {
          this.globalService.clientId$.next(undefined);
      } else if (this.menuModeOptions[index].value === Mode.Admin) {
          this.globalService.clientId$.next(AI_CLIENT_ID);
      }

      this.menuModeIndex = index;

      this.openMenuHandler('none');

      if (this.router.url.startsWith('/help') || this.router.url.startsWith('/user-feedback')) {
          return;
      } else {
          this.router.navigate(['/dashboard']);
      }
  }

  openMenuHandler(value: string): void {
      for (const key in this.openMenuMap) {
        if (key !== value) {
          this.openMenuMap[key] = false;
        }
      }
    }

  onOpenLCASandboxSettings(event) {
      event.preventDefault();
      event.stopPropagation();

      this.lcaSettingsMode = true;
  }

  get isProduction(): boolean {
    return environment.production;
  }
}
