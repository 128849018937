import {
    Component,
    OnInit,
    OnDestroy,
    Output,
    EventEmitter,
    Input,
} from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
    UntypedFormControl,
} from '@angular/forms';
import {
    UploadPortalService,
    UploadPortalTypeInput,
} from '../../../core/services/upload-portal/upload-portal.service';
import { Subject } from 'rxjs';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import { UploadPortalType } from '../../../store/models/upload-portal-type.model';
import { takeUntil } from 'rxjs/operators';
import { PortalStatus } from '../../../store/models/upload-portal-status.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AttributeType } from 'src/app/store/models/attribute.model';
import { AttributeService } from 'src/app/core/services/attribute/attribute.service';
import { UploadPortal } from 'src/app/store/models/upload-portal';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-portal-type-form',
    templateUrl: './portal-type-form.component.html',
    styleUrls: ['./portal-type-form.component.less'],
})
export class PortalTypeFormComponent implements OnInit, OnDestroy {
    @Input() typeId: string;
    @Input() clientId: string;

    @Output() closed: EventEmitter<boolean> = new EventEmitter();

    type: UploadPortalType;
    portalTypeFormGroup: UntypedFormGroup;

    statusesLoading: boolean = false;
    statuses: PortalStatus[] = [];

    saving: boolean = false;

    collectionStatusEnabled: boolean = true;
    ngDestroyed$ = new Subject();

    //attributes
    attributeTypes: AttributeType[] = [];
    attributeTypesLoading: boolean = false;

    portals: UploadPortal[] = [];
    portalsLoading: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private uploadPortalService: UploadPortalService,
        private message: NzMessageService,
        private attributeService: AttributeService,
        private modal: NzModalService
    ) {}

    ngOnInit() {
        this.initPortaTypeForm();
        if (this.typeId) {
            this.uploadPortalService
                .getUploadPortalType(this.typeId)
                .pipe(takeUntil(this.ngDestroyed$))
                .subscribe((payload) => {
                    this.type = _get(payload, 'payload');
                    this.collectionStatusEnabled =
                        this.type.collectionStatusEnabled;
                    this.portalTypeFormGroup.patchValue({
                        uploadPortalTypeName: this.type.uploadPortalTypeName,
                        uploadPortalText: this.type.uploadPortalText,
                        collectionStatusEnabled:
                            this.type.collectionStatusEnabled,
                        clientId: this.clientId,
                        attrTypeIds: (this.type.attributeTypes ?? []).map(({ attrTypeId }) => attrTypeId)
                    });
                    this.fetchStatuses(this.clientId);
                });

                this.portalsLoading = true;

                this.uploadPortalService.getUploadPortals(this.clientId, { uploadPortalTypeId: this.typeId, limit: 1000 })
                    .pipe(takeUntil(this.ngDestroyed$))
                    .subscribe({
                        next: (payload) => {
                            this.portals = _get(payload, 'payload', []);
                            this.portalsLoading = false;
                        },
                        error: () => {
                            this.portals = [];
                            this.portalsLoading = false;
                        }
                    })

        } else {
            this.fetchStatuses(this.clientId);
        }

        this.fetchAttributeTypes(this.clientId);
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    submitPortalTypeForm() {
        const attrTypeIds = this.portalTypeFormGroup.get('attrTypeIds').value ?? [];

        if (this.portals.length && this.type.uploadPortalTypeId && !!this.type.attributeTypes?.length && !attrTypeIds.length) {
            this.modal.confirm({
                nzTitle: 'Confirm Changes',
                nzContent: `Please Note: Removing the attribute type associated with ${this.portalTypeFormGroup.get('uploadPortalTypeName').value} will remove all associated attributes for ${this.portals.length ?? 0} underlying upload portal${this.portals.length > 1 ? 's' : ''}. These associations cannot be recovered.`,
                nzOkType: 'primary',
                nzOkText: 'Yes, save',
                nzCancelText: 'Cancel',
                nzClosable: false,
                nzOnOk: () => this.save()
            });
        } else if (this.portals.length && this.type?.uploadPortalTypeId && attrTypeIds.length > 0 && (attrTypeIds.length !== this.type.attributeTypes?.length || !(this.type.attributeTypes ?? []).every(attrType => attrTypeIds.includes(attrType.attrTypeId)))) {
            this.modal.confirm({
                nzTitle: 'Confirm Changes',
                nzContent: `Please Note: Updating the attribute type associated with ${this.portalTypeFormGroup.get('uploadPortalTypeName').value} will require new attributes to be assigned to ${this.portals.length ?? 0} underlying upload portal${this.portals.length > 1 ? 's' : ''}. The upload portal${this.portals.length > 1 ? 's' : ''} will not be available to users until new attributes are assigned.`,
                nzOkType: 'primary',
                nzOkText: 'Yes, save',
                nzCancelText: 'Cancel',
                nzClosable: false,
                nzOnOk: () => this.save()
            });
        } else {
            this.save();
        }
    }

    save() {
        const input: UploadPortalTypeInput = {
            uploadPortalTypeName:
                this.portalTypeFormGroup.get('uploadPortalTypeName').value,
            uploadPortalText:
                this.portalTypeFormGroup.get('uploadPortalText').value,
            collectionStatusEnabled: this.portalTypeFormGroup.get(
                'collectionStatusEnabled'
            ).value,
            collectionStatusIds: [],
            attrTypeIds: this.portalTypeFormGroup.get('attrTypeIds').value ?? [], 
            clientId: this.clientId,
        };

        if (this.collectionStatusEnabled) {
            this.statuses.forEach(({ collectionStatusId }) => {
                const statusIncluded = _get(
                    this.portalTypeFormGroup.value,
                    `status#${collectionStatusId}`
                );
                if (statusIncluded) {
                    input.collectionStatusIds.push(collectionStatusId);
                }
            });
        } else {
            input.collectionStatusIds = [];
        }
        this.saving = true;
        if (this.type && this.type.uploadPortalTypeId) {
            input.uploadPortalTypeId = this.type.uploadPortalTypeId;
            this.uploadPortalService.putUploadPortalType(input).subscribe({
                next: () => {
                    this.saving = false;
                    this.message.success(
                        `Type <b>${this.type.uploadPortalTypeName}</b> has been edited`,
                        { nzDuration: 300 }
                    );
                    this.closed.emit(true);
                },
                complete: () => (this.saving = false),
            });
        } else {
            this.uploadPortalService.postUploadPortalType(input).subscribe({
                next: () => {
                    this.saving = false;
                    this.message.success(
                        `Type <b>${input.uploadPortalTypeName}</b> has been created`,
                        { nzDuration: 300 }
                    );
                    this.closed.emit(true);
                },
                complete: () => (this.saving = false),
            });
        }
    }

    private fetchStatuses(clientId: string) {
        this.statusesLoading = true;
        this.uploadPortalService.getAllStatuses(clientId).subscribe({
            next: (payload) => {
                this.statusesLoading = false;
                this.statuses = _get(payload, 'payload', []);
                const portalTypeStatusIds = this.type
                    ? this.type.collectionStatuses.map(
                          ({ collectionStatusId }) => collectionStatusId
                      )
                    : [];
                this.statuses.forEach(({ collectionStatusId }) => {
                    const controlName = `status#${collectionStatusId}`;
                    this.portalTypeFormGroup.addControl(
                        controlName,
                        new UntypedFormControl()
                    );
                    this.portalTypeFormGroup.patchValue({
                        [controlName]: this.type
                            ? portalTypeStatusIds.includes(collectionStatusId)
                            : false,
                    });
                });
            },
            complete: () => (this.statusesLoading = false),
        });
    }

    private initPortaTypeForm() {
        this.portalTypeFormGroup = this.fb.group({
            uploadPortalTypeName: ['', Validators.required],
            uploadPortalText: [''],
            collectionStatusEnabled: [false],
            collectionStatusIds: [[]],
            attrTypeIds: [[]],
        });
    }

    private fetchAttributeTypes(clientId: string) {
        this.attributeTypesLoading = true;
        this.attributeService
            .getAttributeTypesForUploadPortals(clientId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    this.attributeTypes = _orderBy(_get(payload, 'payload', []), item => item.attrTypeName, ['asc']);
                },
                complete: () => {
                    this.attributeTypesLoading = false;
                },
            });
    }

    onStatusEnabled(enabled: boolean) {
        this.collectionStatusEnabled = enabled;
    }

    onClosePortalTypeForm() {
        this.closed.emit(false);
    }

    onStatusesChanged() {
        this.portalTypeFormGroup.markAsDirty();
    }
}
