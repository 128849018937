import { Action, createReducer, on } from '@ngrx/store';
import * as UploadPortalActions from '../../actions/upload-portal/upload-portal.actions';
import _get from 'lodash/get';
import { UploadPortal } from '../../models/upload-portal';
import { UploadPortalVersion } from '../../models/upload-portal-version';
import { UploadPortalType } from '../../models/upload-portal-type.model';
import { DataUploadHistory } from '../../models/data-upload-history';
import { UploadPortalStatus } from '../../models/upload-portal-status.model';
import { UploadPortalStatusVersion } from '../../models/upload-portal-status-version';

export const uploadPortalFeatureKey = 'uploadPortals';

export interface UploadPortalState {
    loading: boolean;
    templateDownloadLoading: boolean;
    items: UploadPortal[];
    uploading: boolean;
    uploadPortal: {
        items: UploadPortal[];
        loading: boolean;
        error: string;
    };
    history: {
        loading: boolean;
        items: DataUploadHistory[];
        error: string;
    };
    versions: {
        [id: string]: {
            items: UploadPortalVersion[];
            loading: boolean;
            error: string;
        };
    };
    uploadPortalStatusVersions: {
        [id: string]: {
            items: UploadPortalStatusVersion[];
            loading: boolean;
            error: string;
        };
    };
    types: {
        loading: boolean;
        items: UploadPortalType[];
        error: string;
        selected: UploadPortalType;
    };
    userTypes: {
        loading: boolean;
        items: UploadPortalType[];
        error: string;
        selected: UploadPortalType;
    };
    collectionStatuses: {
        [id: string]: {
            items: UploadPortalStatus[];
            loading: boolean;
            error: string;
        };
    };
    error: string;
}

export const initialState: UploadPortalState = {
    loading: false,
    uploading: false,
    templateDownloadLoading: false,
    items: null,
    uploadPortal: {
        loading: false,
        items: [],
        error: null,
    },
    history: {
        loading: false,
        items: [],
        error: null,
    },
    versions: {},
    uploadPortalStatusVersions: {},
    collectionStatuses: {},
    types: {
        loading: false,
        items: [],
        error: null,
        selected: null,
    },
    userTypes: {
        loading: false,
        items: [],
        error: null,
        selected: null,
    },
    error: null,
};

const uploadPortalReducer = createReducer(
    initialState,
    on(UploadPortalActions.uploadPortalListFailed, (state, { error }) => ({
        ...state,
        loading: false,
        error: _get(error, ['error', 'errors', 0], null),
    })),
    on(
        UploadPortalActions.uploadPortalVersionsRequest,
        (state, { uploadPortalId }) => ({
            ...state,
            versions: {
                ...state.versions,
                [uploadPortalId]: {
                    loading: true,
                    error: null,
                    items: [],
                },
            },
        })
    ),
    on(
        UploadPortalActions.uploadPortalVersionsReceive,
        (state, { items, uploadPortalId }) => ({
            ...state,
            versions: {
                ...state.versions,
                [uploadPortalId]: {
                    loading: false,
                    error: null,
                    items,
                },
            },
        })
    ),
    on(
        UploadPortalActions.uploadPortalVersionsFailed,
        (state, { error, uploadPortalId }) => ({
            ...state,
            versions: {
                ...state.versions,
                [uploadPortalId]: {
                    loading: false,
                    error: error.toString(),
                    items: [],
                },
            },
        })
    ),
    on(UploadPortalActions.uploadPortalTypesRequest, (state) => ({
        ...state,
        types: {
            ...state.types,
            loading: true,
            error: null,
            selected: null,
        },
    })),
    on(UploadPortalActions.uploadPortalTypesReceive, (state, { items }) => ({
        ...state,
        types: {
            loading: false,
            items,
            error: null,
            selected: null,
        },
    })),
    on(UploadPortalActions.uploadPortalTypesFailed, (state, { error }) => ({
        ...state,
        types: {
            loading: false,
            items: [],
            error: error.toString(),
            selected: null,
        },
    })),

    on(UploadPortalActions.userUploadPortalTypesRequest, (state) => ({
        ...state,
        userTypes: {
            ...state.types,
            loading: true,
            error: null,
            selected: null,
        },
    })),
    on(
        UploadPortalActions.userUploadPortalTypesReceive,
        (state, { items }) => ({
            ...state,
            userTypes: {
                loading: false,
                items,
                error: null,
                selected: null,
            },
        })
    ),
    on(UploadPortalActions.userUploadPortalTypesFailed, (state, { error }) => ({
        ...state,
        userTypes: {
            loading: false,
            items: [],
            error: error.toString(),
            selected: null,
        },
    })),

    on(UploadPortalActions.userUploadPortalsRequest, (state) => ({
        ...state,
        uploadPortal: {
            ...state.uploadPortal,
            loading: true,
            error: null,
        },
    })),
    on(UploadPortalActions.userUploadPortalsReceive, (state, { items }) => ({
        ...state,
        uploadPortal: {
            loading: false,
            items,
            error: null,
        },
    })),
    on(UploadPortalActions.userUploadPortalsFailed, (state, { error }) => ({
        ...state,
        uploadPortal: {
            items: [],
            loading: false,
            error: error.toString(),
        },
    })),
    on(UploadPortalActions.downloadUploadPortalFileRequest, (state) => ({
        ...state,
        templateDownloadLoading: true,
    })),
    on(UploadPortalActions.downloadUploadPortalFileReceive, (state) => ({
        ...state,
        templateDownloadLoading: false,
    })),
    on(UploadPortalActions.downloadUploadPortalFileFailed, (state) => ({
        ...state,
        templateDownloadLoading: false,
    })),
    on(UploadPortalActions.userDataUploadHistoryRequest, (state, action) => {
        const {reset} = action;
        return {
            ...state,
            history: {
                ...state.history,
                items: reset ? [] : state.history.items,
                loading: true,
                error: null,
            },
        }
    }),
    on(
        UploadPortalActions.userDataUploadHistoryReceive,
        (state, { items, reset }) => ({
            ...state,
            history: {
                items:
                    reset === true ? items : state.history.items.concat(items),
                loading: false,
                error: null,
            },
        })
    ),
    on(UploadPortalActions.userDataUploadHistoryFailed, (state, { error }) => ({
        ...state,
        history: {
            loading: false,
            items: [],
            error: error.toString(),
        },
    })),
    on(
        UploadPortalActions.dataCollectionStatusesRequest,
        (state, { statusTypeId }) => ({
            ...state,
            collectionStatuses: {
                ...state.collectionStatuses,
                [statusTypeId]: {
                    loading: true,
                    error: null,
                    items: [],
                },
            },
        })
    ),
    on(
        UploadPortalActions.dataCollectionStatusesReceive,
        (state, { items, statusTypeId }) => ({
            ...state,
            collectionStatuses: {
                ...state.collectionStatuses,
                [statusTypeId]: {
                    loading: false,
                    error: null,
                    items,
                },
            },
        })
    ),
    on(
        UploadPortalActions.dataCollectionStatusesFailed,
        (state, { error, statusTypeId }) => ({
            ...state,
            collectionStatuses: {
                ...state.collectionStatuses,
                [statusTypeId]: {
                    loading: false,
                    error: error.toString(),
                    items: [],
                },
            },
        })
    ),
    on(
        UploadPortalActions.uploadPortalStatusHistoryRequest,
        (state, { uploadPortalId, reset }) => {
            return {
                ...state,
                uploadPortalStatusVersions: {
                    ...state.uploadPortalStatusVersions,
                    [uploadPortalId]: {
                        loading: true,
                        error: null,
                        items:
                            reset === true
                                ? []
                                : state.uploadPortalStatusVersions[uploadPortalId]
                                      .items,
                    },
                },
            };
        }
    ),
    on(
        UploadPortalActions.uploadPortalStatusHistoryReceive,
        (state, { items, uploadPortalId, reset }) => {
            return {
                ...state,
                uploadPortalStatusVersions: {
                    ...state.uploadPortalStatusVersions,
                    [uploadPortalId]: {
                        loading: false,
                        error: null,
                        items:
                            reset === true
                                ? items
                                : state.uploadPortalStatusVersions[
                                      uploadPortalId
                                  ].items.concat(items),
                    },
                },
            };
        }
    ),

    on(
        UploadPortalActions.uploadPortalStatusHistoryFailed,
        (state, { error, uploadPortalId }) => ({
            ...state,
            uploadPortalStatusVersions: {
                ...state.uploadPortalStatusVersions,
                [uploadPortalId]: {
                    loading: false,
                    error: error.toString(),
                    items: [],
                },
            },
        })
    ),
    on(UploadPortalActions.uploadUploadPortalRequest, (state) => ({
        ...state,
        uploading: true,
    })),
    on(UploadPortalActions.uploadUploadPortalsReceive, (state) => ({
        ...state,
        uploading: false,
    })),
    on(UploadPortalActions.uploadUploadPortalFailed, (state) => ({
        ...state,
        uploading: false,
    }))
);

export function reducer(state: UploadPortalState | undefined, action: Action) {
    return uploadPortalReducer(state, action);
}
