<div class="client-database-form-container">
    <form
        class="client-database__form"
        [formGroup]="databaseFormGroup"
        nz-form
        (ngSubmit)="onSubmit()"
    >
        <div nz-row nzGutter="4">
            <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label>Database schema</nz-form-label>
                    <nz-form-control
                        [nzSpan]="24"
                        nzValidateStatus
                        [nzErrorTip]="schemaSqlTemplateErrorTmpl"
                    >
                        <nz-select
                            nzShowSearch
                            nzSize="large"
                            nzPlaceHolder="Choose one"
                            nzAllowClear
                            [nzLoading]="databaseSchemasLoading"
                            formControlName="schemaSqlTemplate"
                        >
                            <nz-option
                                *ngFor="let schema of databaseSchemas"
                                [nzValue]="schema"
                                [nzLabel]="schema"
                            ></nz-option>
                        </nz-select>
                        <ng-template #schemaSqlTemplateErrorTmpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please select database schema
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzRequired>Database name</nz-form-label>
                    <nz-form-control
                        [nzSpan]="24"
                        nzValidateStatus
                        nzHasFeedback
                        [nzErrorTip]="databaseNameErrorTmpl"
                    >
                        <nz-input-group nzSize="large">
                            <input
                                formControlName="schemaName"
                                nz-input
                                placeholder="Enter Database Name"
                            />
                        </nz-input-group>
                        <ng-template #databaseNameErrorTmpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please enter database name
                            </ng-container>
                            <ng-container>
                                <span>
                                    Database name should be in snake_case
                                    without special characters #&#64;)([] and be a
                                    max of
                                    {{ dabaseNameLength }} characters in length.
                                </span>
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control
                        class="drawer-actions-control"
                        [nzSpan]="24"
                    >
                        <div class="drawer-controls">
                            <button
                                type="button"
                                nzSize="large"
                                nz-button
                                [disabled]="
                                    !databaseFormGroup.valid ||
                                    !databaseFormGroup.dirty ||
                                    saving
                                "
                                (click)="onSubmit()"
                                nzType="primary"
                            >
                                <span>Save</span>
                            </button>
                            <button
                                [nzLoading]="saving"
                                [disabled]="saving"
                                type="button"
                                nzSize="large"
                                nz-button
                                (click)="onClose()"
                            >
                                <span>Close</span>
                            </button>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </form>
</div>
