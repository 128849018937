import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LcaService } from 'src/app/core/services/lca/lca.service';
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';
import _concat from 'lodash/concat';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-lca-model-data-database-form',
    templateUrl: './lca-model-data-database-form.component.html',
    styleUrls: ['./lca-model-data-database-form.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LcaModelDataDatabaseFormComponent implements OnInit, OnDestroy {
    @Output() closed: EventEmitter<any> = new EventEmitter();
    @Input() model: any;

    selectedActivitySerials: number[] = [];

    ngDestroyed$ = new Subject();

    constructor(
        private lcaService: LcaService,
        private message: NzMessageService
    ) {}

    ngOnInit() {}

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    onActivitySerialsChanged(activitySerials: number[]) {
        this.selectedActivitySerials = activitySerials;
    }

    onSave() {
        if (this.selectedActivitySerials.length) {
            this.lcaService
                .putModelActivitySerials(
                    this.model.modelName,
                    this.selectedActivitySerials
                )
                .subscribe(() => {
                    this.closed.emit(
                        `${this.selectedActivitySerials.length} ActivitySerial${
                            this.selectedActivitySerials.length > 1 ? 's' : ''
                        } Selected`
                    );
                    this.message.success(
                        `${this.selectedActivitySerials.length} ActivitySerial${
                            this.selectedActivitySerials.length > 1 ? 's' : ''
                        } Selected`
                    );
                });
        }
    }

    onClose() {
        this.closed.emit(null);
    }

    get warnMessage(): string {
        const enabledOpts = [];
        if (this.model.elemFlowEnabled) {
            enabledOpts.push('ElemFlow');
        }
        if (this.model.techFlowEnabled) {
            enabledOpts.push('TechFlow');
        }

        return enabledOpts && enabledOpts.length > 0
            ? `${enabledOpts.join(
                  ', '
              )} enabled, provide 5 or less ActivitySerials for review. This options will significantly increase runtimes and data weight.`
            : null;
    }
}
