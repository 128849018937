import { Action, createReducer, on } from '@ngrx/store';
import * as ReportActions from '../../actions/report/report.actions';
import _get from 'lodash/get';
import { Report } from '../../models/report.model';
import _set from 'lodash/set';
import findIndex from 'lodash/findIndex';
import { ReportGroup } from '../../models/report-group.model';

export const reportFeatureKey = 'reports';

export interface ReportState {
    loading: boolean;
    items: Report[];
    error: string;
    management: {
        [clientId: string]: {
            items: Report[];
            loading: boolean;
            error: string;
        };
    };
    groups: {
        items: ReportGroup[];
        loading: boolean;
        error: string;
    };
    selected: {
        loading: boolean;
        error: string;
        item: Report;
    };
    selectedGroup: {
        loading: boolean;
        error: string;
        item: ReportGroup;
    };
}

export const initialState: ReportState = {
    loading: false,
    items: null,
    error: null,
    management: {},
    groups: {
        items: [],
        loading: false,
        error: null,
    },
    selectedGroup: {
        item: null,
        loading: false,
        error: null,
    },
    selected: {
        item: null,
        loading: false,
        error: null,
    },
};

const reportReducer = createReducer(
    initialState,
    on(ReportActions.createReportForClientRequest, (state) => ({
        ...state,
        selected: {
            ...state.selected,
            loading: true,
        },
    })),
    on(ReportActions.createReportForClientReceive, (state, { report }) => {
        const clientManagementState = state.management[report.clientId] ?? {
            items: [],
            loading: false,
            error: null,
        };

        return {
            ...state,
            management: {
                ...state.management,
                [report.clientId]: {
                    ...(state.management[report.clientId] ?? {
                        items: [],
                        loading: false,
                        error: null,
                    }),
                    items: [report, ...clientManagementState.items],
                },
            },
            selected: {
                loading: false,
                item: null,
                error: null,
            },
        };
    }),
    on(
        ReportActions.createReportForClientFailed,
        (state, { error, clientId }) => ({
            ...state,
            management: {
                [clientId]: {
                    ...(state.management[clientId] ?? {}),
                    items: _get(state, ['management', clientId, 'items'], []),
                    loading: false,
                    error,
                },
            },
        })
    ),
    on(ReportActions.reportGroupsListRequest, (state) => ({
        ...state,
        groups: { ...state.groups, loading: true },
    })),
    on(ReportActions.reportGroupsListReceive, (state, { reportGroups }) => ({
        ...state,
        groups: {
            items: reportGroups,
            loading: false,
            error: null,
        },
    })),
    on(ReportActions.reportGroupsListFailed, (state, { error }) => ({
        ...state,
        groups: {
            items: [],
            loading: false,
            error,
        },
    })),
    on(ReportActions.createReportGroupRequest, (state) => ({
        ...state,
        selectedGroup: {
            ...state.selectedGroup,
            loading: true,
        },
    })),
    on(ReportActions.createReportGroupReceive, (state, { reportGroup }) => ({
        ..._set(state, ['groups'], {
            items: [reportGroup, ..._get(state, ['groups', 'items'], [])],
            loading: false,
            error: null,
        }),
        selectedGroup: {
            loading: false,
            item: null,
            error: null,
        },
    })),
    on(ReportActions.createReportGroupFailed, (state, { error }) => ({
        ...state,
        groups: {
            items: _get(state, ['groups', 'items'], []),
            loading: false,
            error,
        },
        selectedGroup: {
            loading: false,
            item: null,
            error: null,
        },
    })),
    on(ReportActions.updateReportGroupRequest, (state) => ({
        ...state,
        selectedGroup: {
            ...state.selectedGroup,
            loading: true,
        },
    })),
    on(ReportActions.updateReportGroupReceive, (state, { reportGroup }) => {
        const index = findIndex(_get(state, ['groups', 'items'], []), {
            groupId: reportGroup.groupId,
        });
        const newGroups = _set(
            _get(state, ['groups', 'items'], []),
            [index],
            reportGroup
        );

        return {
            ..._set(state, ['groups'], {
                items: [...newGroups],
                loading: false,
                error: null,
            }),
            selectedGroup: {
                loading: false,
                item: null,
                error: null,
            },
        };
    }),
    on(ReportActions.updateReportGroupFailed, (state, { error }) => ({
        ..._set(state, ['groups'], {
            items: _get(state, ['groups', 'items'], []),
            loading: false,
            error,
        }),
    })),
    on(ReportActions.deleteReportGroupRequest, (state, { groupId }) => ({
        ..._set(state, ['groups'], {
            ..._get(state, 'groups'),
            items: _get(state, ['groups', 'items'], []).filter(
                ({ groupId: reportGroupId }) => groupId !== reportGroupId
            ),
        }),
    })),
    on(ReportActions.deleteReportRequest, (state, { reportId, clientId }) => {
        return {
            ..._set(
                state,
                ['management', clientId, 'items'],
                _get(state, ['management', clientId, 'items'], []).filter(
                    ({ reportId: rId }) => reportId !== rId
                )
            ),
        };
    }),
    on(ReportActions.reportGroupRequest, (state) => ({
        ...state,
        selectedGroup: {
            loading: true,
            item: null,
            error: null,
        },
    })),
    on(ReportActions.reportGroupReceive, (state, { reportGroup }) => ({
        ...state,
        selectedGroup: {
            loading: false,
            item: reportGroup,
            error: null,
        },
    })),
    on(ReportActions.reportGroupFailed, (state, { error }) => ({
        ...state,
        selectedGroup: {
            loading: false,
            item: null,
            error,
        },
    })),
    on(ReportActions.reportRequest, (state) => ({
        ..._set(state, ['selected'], {
            loading: true,
            item: null,
            error: null,
        }),
    })),
    on(ReportActions.reportReceive, (state, { report }) => ({
        ..._set(state, ['selected'], {
            loading: false,
            item: report,
            error: null,
        }),
    })),
    on(ReportActions.reportFailed, (state, { error }) => ({
        ..._set(state, ['selected'], {
            loading: false,
            item: null,
            error,
        }),
    })),
    on(ReportActions.updateReportForClientRequest, (state) => ({
        ...state,
        selected: {
            ...state.selected,
            loading: true,
        },
    })),
    on(ReportActions.updateReportForClientReceive, (state) => {
        return {
            ...state,
        };
    }),
    on(
        ReportActions.updateReportForClientFailed,
        (state, { error, clientId }) => {
            return {
                ...state,
                management: {
                    [clientId]: {
                        ...(state.management[clientId] ?? {}),
                        items: _get(
                            state,
                            ['management', clientId, 'items'],
                            []
                        ),
                        loading: false,
                        error,
                    },
                },
            };
        }
    )
);

export function reducer(state: ReportState | undefined, action: Action) {
    return reportReducer(state, action);
}
