<div class="users-container">
    <div
        nz-flex
        nzAlign="center"
        nzJustify="space-between"
        class="users__controls"
    >
        <div nz-flex nzAlign="center" nzWrap="wrap" nzGap="small">
            <app-text-search-input
                (search)="onSearch($event)"
            ></app-text-search-input>
            <nz-select
                nzAllowClear
                nzShowSearch
                data-cy="users-table__report-group-filter"
                [nzMaxTagCount]="1"
                [nzMaxTagPlaceholder]="maxTagGroupsPlaceholder"
                [(ngModel)]="selectedReportGroupIds"
                (ngModelChange)="onFilterReportGroupsChanged($event)"
                [nzDropdownStyle]="{ width: '17rem' }"
                nzSize="large"
                nzMode="multiple"
                [nzPlaceHolder]="
                    selectedReportGroupIds?.length
                        ? ''
                        : 'Filter by Report Group'
                "
                [nzLoading]="reportGroupsLoading"
            >
                <nz-option
                    *ngFor="let group of reportGroups"
                    [nzValue]="group.groupId"
                    [nzLabel]="group.groupName"
                    nzCustomContent
                >
                    <div class="group">
                        <div
                            class="group-color"
                            [style.backgroundColor]="group.groupColor"
                        ></div>
                        <div class="group-name">
                            {{ group.groupName }}
                        </div>
                    </div>
                </nz-option>
            </nz-select>
            <nz-select
                nzAllowClear
                nzShowSearch
                data-cy="users-table__upload-portal-type-filter"
                [nzMaxTagCount]="1"
                [nzMaxTagPlaceholder]="maxTagGroupsPlaceholder"
                [(ngModel)]="selectedUploadPortalTypeIds"
                (ngModelChange)="onFilterUploadPortalTypesChanged($event)"
                [nzDropdownStyle]="{ width: '17rem' }"
                nzSize="large"
                nzMode="multiple"
                [nzPlaceHolder]="
                    selectedUploadPortalTypeIds?.length
                        ? ''
                        : 'Filter by upload portal type'
                "
                [nzLoading]="uploadPortalTypesLoading"
            >
                <nz-option
                    *ngFor="let uploadPortalType of uploadPortalTypes"
                    [nzValue]="uploadPortalType.uploadPortalTypeId"
                    [nzLabel]="uploadPortalType.uploadPortalTypeName"
                    nzCustomContent
                >
                    {{ uploadPortalType.uploadPortalTypeName }}
                </nz-option>
            </nz-select>
            <nz-select
                nzShowSearch
                nzAllowClear
                data-cy="users-table__attributes-filter"
                [nzMaxTagCount]="1"
                [nzMaxTagPlaceholder]="maxTagGroupsPlaceholder"
                [(ngModel)]="selectedAttributeIds"
                (ngModelChange)="onFilterAttributesChanged($event)"
                [nzDropdownStyle]="{ width: '17rem' }"
                nzSize="large"
                nzMode="multiple"
                [nzPlaceHolder]="
                    selectedAttributeIds?.length ? '' : 'Filter by Attribute'
                "
                [nzLoading]="attributeTypesLoading"
            >
                <nz-option-group
                    *ngFor="let type of attributeTypes"
                    [nzLabel]="type.attrTypeName"
                >
                    <nz-option
                        *ngFor="let attr of type.attributes"
                        [nzValue]="attr.attrId"
                        [nzLabel]="attr.attrName"
                        nzCustomContent
                    >
                        <div class="attr-name">
                            {{ attr.attrName }}
                        </div>
                    </nz-option>
                </nz-option-group>
            </nz-select>
            <nz-select
                nzAllowClear
                nzShowSearch
                data-cy="users-table__roles-filter"
                [nzMaxTagCount]="1"
                [nzMaxTagPlaceholder]="maxTagGroupsPlaceholder"
                [(ngModel)]="selectedRoleIds"
                (ngModelChange)="onFilterRoleChanged($event)"
                [nzDropdownStyle]="{ width: '17rem' }"
                nzSize="large"
                nzMode="multiple"
                [nzPlaceHolder]="
                    selectedRoleIds?.length
                        ? ''
                        : 'Filter by Role'
                "
                [nzLoading]="clientRolesLoading"
            >
                <nz-option
                    *ngFor="let role of clientRoles"
                    [nzValue]="role.roleId"
                    [nzLabel]="role.roleName"
                    nzCustomContent
                >
                    {{ role.roleName }}
                </nz-option>
            </nz-select>
            <nz-select
                nzAllowClear
                nzShowSearch
                data-cy="users-table__admin-filter"
                [(ngModel)]="isAdmin"
                (ngModelChange)="onFilterAdminChanged($event)"
                [nzDropdownStyle]="{ width: '12rem' }"
                nzSize="large"
                [nzPlaceHolder]="'Admin filter'"
            >
                <nz-option
                    [nzValue]="'true'"
                    [nzLabel]="'Admin'"
                />
                <nz-option
                    [nzValue]="'false'"
                    [nzLabel]="'Non-Admin'"
                />
            </nz-select>
        </div>
        <button
            [disabled]="!selectedClientId"
            data-cy="add-user__control"
            *hasPermission="['USER_MGMT', 'LTD_USER_ADMIN']"
            type="button"
            nz-button
            (click)="onCreateCustomerUser()"
            nzType="primary"
        >
            <i nz-icon nzType="plus" nzTheme="outline"></i>
            <span>Create new user</span>
        </button>
    </div>
    <ng-template #maxTagGroupsPlaceholder let-selectedList>
        +{{ selectedList.length }}
    </ng-template>
    <div
        class="users__table"
        #usersTable
    >
        <app-infinite-scroll
            [loaded]="usersLoaded"
            [loading]="usersLoading"
            (scrolled)="onTableScrolled()"
        >
            <nz-table
                #table
                [nzLoading]="usersLoading"
                [nzSize]="'middle'"
                [nzPageSize]="999999999"
                nzHideOnSinglePage
                [nzData]="users"
            >
                <thead (nzSortOrderChange)="orderChange($event)">
                    <tr>
                        <th
                            *ngFor="let column of columns"
                            [attr.data-cy]="column.dataCy"
                            [nzSortFn]="true"
                            [nzWidth]="column.width"
                            [nzSortDirections]="column.sortDirections"
                            nzColumnKey="{{ column.key }}"
                        >
                            {{ column.title }}
                        </th>
                        <th nzWidth="15%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let data of table.data; let i = index"
                        class="editable-row"
                    >
                        <td
                            data-cy="users-table__email"
                            [innerHTML]="
                                data.userLoginId
                                    | highlight: query
                                    | safe: 'html'
                            "
                        ></td>
                        <td
                            data-cy="users-table__first-name"
                            [innerHTML]="
                                data.userFirstName
                                    | highlight: query
                                    | safe: 'html'
                            "
                        ></td>
                        <td
                            data-cy="users-table__last-name"
                            [innerHTML]="
                                data.userLastName
                                    | highlight: query
                                    | safe: 'html'
                            "
                        ></td>
                        <td data-cy="users-table__login-type">
                            {{ data.userLoginType?.loginTypeName }}
                        </td>
                        <td
                            data-cy="users-table__created-stamp"
                        >
                            {{
                                data.createdStamp | date: 'dd MMM yyyy, h:mm a'
                            }}
                        </td>
                        <td class="actions__cell">
                            <ng-container
                                *hasPermission="[
                                    'USER_MGMT',
                                    'USER_MGMT_VIEW',
                                    'LTD_USER_ADMIN',
                                    'SUPERUSER'
                                ]"
                            >
                                <ng-container
                                    *ngIf="
                                        customerService.aiClientId ===
                                            selectedClientId && data.isAdmin
                                    "
                                >
                                    <ng-container
                                        *hasPermission="['SUPERUSER']"
                                    >
                                        <ng-container
                                            *ngTemplateOutlet="actionsTmpl"
                                        >
                                            <!-- actions -->
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        customerService.aiClientId !==
                                            selectedClientId && data.isAdmin
                                    "
                                >
                                    <ng-container
                                        *hasPermission="[
                                            'AIADMIN',
                                            'AIUSER',
                                            'SUPERUSER',
                                            'CLIENTADMIN'
                                        ]"
                                    >
                                        <ng-container
                                            *ngTemplateOutlet="actionsTmpl"
                                        >
                                            <!-- actions -->
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="!data.isAdmin">
                                    <ng-container
                                        *ngTemplateOutlet="actionsTmpl"
                                    >
                                        <!-- actions -->
                                    </ng-container>
                                </ng-container>
                                <ng-template #actionsTmpl>
                                    <i
                                        nz-icon
                                        nzType="dots"
                                        nzTheme="fill"
                                        *hasPermission="[
                                            'USER_MGMT',
                                            'USER_MGMT_VIEW',
                                            'LTD_USER_ADMIN',
                                            'SUPERUSER'
                                        ]"
                                    ></i>
                                    <div class="table-actions-container">
                                        <i
                                            data-cy="user-delete__control"
                                            (click)="
                                                onRemoveCustomerUser(
                                                    data.userId,
                                                    data.userLoginId
                                                )
                                            "
                                            nz-tooltip
                                            nzTooltipPlacement="top"
                                            nzTooltipTitle="Delete"
                                            nzTooltipOverlayClassName="danger-tooltip"
                                            class="action-icon delete"
                                            nz-icon
                                            nzType="delete"
                                            nzTheme="outline"
                                        ></i>
                                        <i
                                            data-cy="user-edit__control"
                                            (click)="
                                                onEditCustomerUser(data.userId)
                                            "
                                            nz-tooltip
                                            nzTooltipPlacement="top"
                                            nzTooltipTitle="Edit"
                                            nzTooltipOverlayClassName="primary-tooltip"
                                            class="action-icon"
                                            nz-icon
                                            nzType="edit"
                                            nzTheme="outline"
                                        ></i>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </app-infinite-scroll>
    </div>
    <nz-drawer
        nzMaskClosable
        [nzWidth]="490"
        [nzVisible]="customerUserFormMode"
        [nzTitle]="user ? 'Edit user' : 'Create new user'"
        (nzOnClose)="closeEditCustomerUser($event)"
    >
        <ng-container *nzDrawerContent>
            <nz-alert
                *ngIf="customerUserError"
                nzType="error"
                [nzMessage]="customerUserError"
                nzShowIcon
            ></nz-alert>
            <form
                autocomplete="off"
                *ngIf="customerUserFormMode"
                class="customer-user__form"
                nz-form
                [formGroup]="customerUserForm"
                (ngSubmit)="submitCustomerUserForm()"
            >
                <div
                    *hasPermission="
                        ['USER_MGMT', 'LTD_USER_ADMIN'];
                        class: 'disabled'
                    "
                    nz-row
                    nzGutter="4"
                >
                    <div nz-col nzSpan="24">
                        <nz-form-item>
                            <nz-form-label nzRequired>First name</nz-form-label>
                            <nz-form-control
                                [nzSpan]="24"
                                nzValidateStatus
                                nzHasFeedback
                                [nzErrorTip]="firstNameErrorTmpl"
                            >
                                <nz-input-group nzSize="large">
                                    <input
                                        data-cy="user-first-name__input"
                                        formControlName="userFirstName"
                                        nz-input
                                        placeholder="Enter First Name"
                                    />
                                </nz-input-group>
                                <ng-template #firstNameErrorTmpl let-control>
                                    <ng-container
                                        *ngIf="control.hasError('required')"
                                    >
                                        Please enter first name
                                    </ng-container>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label nzRequired>Last name</nz-form-label>
                            <nz-form-control
                                [nzSpan]="24"
                                nzValidateStatus
                                nzHasFeedback
                                [nzErrorTip]="lastNameErrorTmpl"
                            >
                                <nz-input-group nzSize="large">
                                    <input
                                        data-cy="user-last-name__input"
                                        formControlName="userLastName"
                                        nz-input
                                        placeholder="Enter Last Name"
                                    />
                                </nz-input-group>
                                <ng-template #lastNameErrorTmpl let-control>
                                    <ng-container
                                        *ngIf="control.hasError('required')"
                                    >
                                        Please enter last name
                                    </ng-container>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label nzRequired>E-mail</nz-form-label>
                            <nz-form-control
                                [nzSpan]="24"
                                nzValidateStatus
                                nzHasFeedback
                                [nzErrorTip]="emailErrorTmpl"
                            >
                                <nz-input-group nzSize="large">
                                    <input
                                        data-cy="user-login-id__input"
                                        formControlName="userLoginId"
                                        nz-input
                                        placeholder="Enter e-mail"
                                    />
                                </nz-input-group>
                                <ng-template #emailErrorTmpl let-control>
                                    <ng-container
                                        *ngIf="control.hasError('required')"
                                    >
                                        Please enter e-mail
                                    </ng-container>
                                    <ng-container
                                        *ngIf="control.hasError('email')"
                                    >
                                        Please enter valid e-email address
                                    </ng-container>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item *ngIf="loginTypes?.length">
                            <nz-form-label nzRequired>Login Type</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                                <nz-radio-group
                                    formControlName="userLoginTypeId"
                                >
                                    <label
                                        *ngFor="let loginType of loginTypes"
                                        nz-radio
                                        [nzValue]="loginType.loginTypeId"
                                        [attr.data-cy]="'login-type-' + loginType.loginTypeId"
                                    >
                                        <span>{{
                                            loginType.loginTypeName
                                        }}</span>
                                        <span
                                            class="info-tooltip"
                                            *ngIf="
                                                loginType?.loginTypeId === 'SSO'
                                            "
                                            nz-icon
                                            nz-tooltip
                                            nzTooltipTitle="User will be redirected to identity provider for authentication. The login email in AITrack must match the email associated with the external account."
                                            nzType="info-circle"
                                        ></span>
                                        <span
                                            class="info-tooltip"
                                            *ngIf="
                                                loginType?.loginTypeId !== 'SSO'
                                            "
                                            nz-icon
                                            nz-tooltip
                                            nzTooltipTitle="Upon user creation, the new user will be sent an email to create their own password."
                                            nzType="info-circle"
                                        ></span>
                                    </label>
                                </nz-radio-group>
                            </nz-form-control>
                        </nz-form-item>
                        <ng-container *ngIf="isAIClient; else noneAIClient">
                            <ng-container *hasPermission="['SUPERUSER']">
                                <ng-container *hasPermission="['USER_MGMT']">
                                    <ng-container *ngTemplateOutlet="adminToggleTemplate"></ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-template #noneAIClient>
                            <ng-container *hasPermission="['CLIENTADMIN', 'AIADMIN', 'AIUSER', 'SUPERUSER']">
                                <ng-container *hasPermission="['LTD_USER_ADMIN', 'USER_MGMT']">
                                    <ng-container *ngTemplateOutlet="adminToggleTemplate"></ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                        <ng-template #adminToggleTemplate>
                            <div class="admin-role-form__status-switcher">
                                <h3>Admin</h3>
                                <nz-switch
                                    formControlName="isAdmin"
                                ></nz-switch>
                            </div>
                        </ng-template>
                        <ng-container *hasPermission="['AIADMIN', 'SUPERUSER']">
                            <nz-form-item
                                class="bg-inverted"
                                *ngIf="
                                    customerUserFormMode &&
                                    !userLoading &&
                                    isAIClient
                                "
                            >
                                <nz-form-label [nzSpan]="24"
                                    >Client Access</nz-form-label
                                >
                                <nz-skeleton
                                    [nzLoading]="clientsLoading"
                                    [nzActive]="true"
                                >
                                    <nz-form-control [nzSpan]="24">
                                        <nz-select
                                            nzPlaceHolder="Select clients"
                                            nzAllowClear
                                            nzMode="multiple"
                                            nzShowSearch
                                            nzSize="large"
                                            formControlName="userAllowedClientIds"
                                        >
                                            
                                            <nz-option
                                                *ngFor="let client of clientsDropdownOptions"
                                                nzCustomContent
                                                [nzValue]="client.clientId"
                                                [nzLabel]="client.clientName"
                                            >
                                                <div class="client-template">
                                                    <img
                                                        class="client-template__logo"
                                                        *ngIf="client?.logoImg; else defaultLogo"
                                                        [src]="client?.logoImg"
                                                    />
                                                    <ng-template #defaultLogo>
                                                        <div class="client-template__logo"></div>
                                                    </ng-template>
                                                    <div class="client-template__name">
                                                        {{ client.clientName }}
                                                    </div>
                                                </div>
                                            </nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-skeleton>
                            </nz-form-item>
                        </ng-container>
                        <nz-form-item
                            class="bg-inverted"
                            *ngIf="
                                customerUserFormMode &&
                                !userLoading &&
                                (attributeTypesLoading || !!attributesTreeNodes.length)
                            "
                        >
                            <nz-form-label [nzSpan]="24"
                                >User Attributes</nz-form-label
                            >
                            <ng-container *ngIf="!attributeTypesLoading">
                                <nz-form-control
                                    [nzSpan]="24"
                                >
                                <nz-tree-select
                                    data-cy="attributes-tree-select"
                                    nzShowSearch
                                    style="width: 100%"
                                    nzVirtualHeight="290px"
                                    [nzNodes]="attributesTreeNodes"
                                    nzPlaceHolder="Please select attributes"
                                    formControlName="attrIds"
                                    nzCheckable
                                    [nzNotFoundContent]="noData"
                                    [nzTreeTemplate]="treeNodeTmpl"
                                ></nz-tree-select>
                                <ng-template #noData>
                                    <div style="height: 200px; display: flex; justify-content: center; align-items: center">
                                        <nz-spin nzSimple></nz-spin>
                                    </div>
                                </ng-template>
                                <ng-template #treeNodeTmpl let-node>
                                    @if (node.isDisabled && !node.isLeaf) {
                                        <div data-cy="title-node-container" [id]="node.key" nz-flex nzAlign="center" nzJustify="space-between">
                                            <span data-cy="tree-node-title">{{ node.title }}</span>
                                            <span
                                                data-cy="tree-node-title-disable-info"
                                                [style.marginRight.rem]="0.5"
                                                nz-icon
                                                nz-tooltip
                                                nzTooltipTitle="The attribute type must have at least one attribute to select."
                                                nzType="info-circle"
                                            ></span>
                                        </div>
                                    } @else {
                                        <div data-cy="title-node-container" [id]="node.key">
                                            <span data-cy="tree-node-title">{{ node.title }}</span>
                                        </div>
                                    }
                                </ng-template>
                                </nz-form-control>
                            </ng-container>
                            <i
                                class="loading-icon"
                                nz-icon
                                [nzType]="'loading'"
                                [hidden]="!attributeTypesLoading"
                            ></i>
                        </nz-form-item>
                        <nz-form-item
                            class="bg-inverted"
                            *ngIf="
                                customerUserFormMode &&
                                !userLoading &&
                                !!clientRoles?.length
                            "
                        >
                            <nz-skeleton
                                [nzLoading]="clientRolesLoading"
                                [nzActive]="true"
                            >
                                <nz-form-label [nzSpan]="24"
                                    >User Roles</nz-form-label
                                >
                                <nz-form-control
                                    [nzSpan]="24"
                                    [nzErrorTip]="roleIdsErrorTmpl"
                                >
                                    <nz-select
                                        data-cy="roles-selector"
                                        nzPlaceHolder="Select roles"
                                        nzAllowClear
                                        nzMode="multiple"
                                        nzShowSearch
                                        nzSize="large"
                                        formControlName="roleIds"
                                    >
                                        <nz-option
                                            *ngFor="let role of clientRoles"
                                            [nzValue]="role.roleId"
                                            [nzLabel]="role.roleName"
                                        ></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-skeleton>
                            <ng-template #roleIdsErrorTmpl let-control>
                                <ng-container *ngIf="control.hasError('required')">
                                    Please select at least 1 role
                                </ng-container>
                            </ng-template>
                        </nz-form-item>
                        <nz-form-item
                            class="bg-inverted"
                            *ngIf="
                                customerUserFormMode &&
                                !userLoading &&
                                !!reportGroups?.length
                            "
                        >
                            <nz-form-label [nzSpan]="24"
                                >Reports</nz-form-label
                            >
                            <nz-form-control
                                [nzSpan]="24"
                                nzHasFeedback
                                nzValidateStatus
                                [nzErrorTip]="groupIdsErrorTmpl"
                            >
                                <nz-select
                                    data-cy="reports-selector"
                                    nzPlaceHolder="Select reports"
                                    nzAllowClear
                                    nzMode="multiple"
                                    nzShowSearch
                                    nzSize="large"
                                    formControlName="reportGroupIds"
                                    [nzCustomTemplate]="selectedReportTempl"
                                >
                                    <nz-option
                                        *ngFor="let reportGroup of reportGroups"
                                        nzCustomContent
                                        [nzValue]="reportGroup.groupId"
                                        [nzLabel]="reportGroup.groupName"
                                    >
                                        <ng-container *ngTemplateOutlet="reportOption; context: { $implicit: reportGroup }"></ng-container>
                                    </nz-option>
                                </nz-select>
                                <ng-template #selectedReportTempl let-selected>
                                    <ng-container *ngTemplateOutlet="reportOption; context: { $implicit: findReportGroup(selected.nzValue) }"></ng-container>
                                </ng-template>
                                <ng-template #reportOption let-group>
                                    <div nz-flex class="report-template">
                                        <div class="report__name">
                                            {{ group.groupName }}
                                        </div>
                                        <div d-flex [style.marginRight.rem]="0.25">
                                            <i class="info-tooltip" *ngIf="!!group.attributeTypes?.length" nz-icon nzTheme="outline" nzType="info-circle" nz-tooltip [nzTooltipTitle]="reportTypeInfoTmpl"></i>
                                            <ng-template #reportTypeInfoTmpl>
                                                <div nz-flex [nzVertical]="true" [nzGap]="8">
                                                    <div nz-flex [nzGap]="8">
                                                        <span nz-icon nzType="attributes" nzTheme="fill"></span>
                                                        <span>Attribute Types</span>
                                                    </div>
                                                    <div>
                                                        <nz-tag *ngFor="let attrType of (group.attributeTypes ?? [])" [nzColor]="'#3099cc'">
                                                            <div>{{attrType.attrTypeName}}</div>
                                                        </nz-tag>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </ng-template>
                            </nz-form-control>
                            <ng-template #groupIdsErrorTmpl let-control>
                                Please select at least 1 report group
                            </ng-template>
                        </nz-form-item>
                        <nz-form-item
                            class="bg-inverted"
                            *ngIf="
                                customerUserFormMode &&
                                !userLoading &&
                                !!uploadPortalTypes?.length
                            "
                        >
                            <nz-skeleton
                                [nzLoading]="uploadPortalTypesLoading"
                                [nzActive]="true"
                            >
                                <nz-form-label [nzSpan]="24"
                                    >Upload Portals</nz-form-label
                                >
                                <nz-form-control [nzSpan]="24">
                                    <nz-select
                                        data-cy="upload-portals-selector"
                                        nzPlaceHolder="Select upload portals"
                                        nzAllowClear
                                        nzMode="multiple"
                                        nzShowSearch
                                        nzSize="large"
                                        formControlName="userUploadPortalTypeIds"
                                        [nzCustomTemplate]="selectedUploadPortalTempl"
                                    >
                                        <nz-option
                                            *ngFor="let uploadPortalType of uploadPortalTypes"
                                            nzCustomContent
                                            [nzValue]="uploadPortalType.uploadPortalTypeId"
                                            [nzLabel]="uploadPortalType.uploadPortalTypeName"
                                        >
                                            <ng-container *ngTemplateOutlet="uploadPortalOption; context: { $implicit: uploadPortalType }"></ng-container>
                                        </nz-option>
                                    </nz-select>
                                    <ng-template #selectedUploadPortalTempl let-selected>
                                        <ng-container *ngTemplateOutlet="uploadPortalOption; context: { $implicit: findUploadPortalType(selected.nzValue) }"></ng-container>
                                    </ng-template>
                                    <ng-template #uploadPortalOption let-type>
                                        <div nz-flex class="upload-portal-template">
                                            <div class="upload-portal__name">
                                                {{ type.uploadPortalTypeName }}
                                            </div>
                                            <div d-flex [style.marginRight.rem]="0.25">
                                                <i class="info-tooltip" *ngIf="!!type.attributeTypes?.length" nz-icon nzTheme="outline" nzType="info-circle" nz-tooltip [nzTooltipTitle]="uploadPortalTypeInfoTmpl"></i>
                                                <ng-template #uploadPortalTypeInfoTmpl>
                                                    <div nz-flex [nzVertical]="true" [nzGap]="8">
                                                        <div nz-flex [nzGap]="8">
                                                            <span nz-icon nzType="attributes" nzTheme="fill"></span>
                                                            <span>Attribute Types</span>
                                                        </div>
                                                        <div>
                                                            <nz-tag *ngFor="let attrType of (type.attributeTypes ?? [])" [nzColor]="'#3099cc'">
                                                                <div>{{attrType.attrTypeName}}</div>
                                                            </nz-tag>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </ng-template>
                                </nz-form-control>
                            </nz-skeleton>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-control
                                class="drawer-actions-control"
                                [nzSpan]="24"
                            >
                                <div class="drawer-controls">
                                    <button
                                        data-cy="user-form__submit"
                                        *hasPermission="[
                                            'USER_MGMT',
                                            'LTD_USER_ADMIN'
                                        ]"
                                        type="submit"
                                        nzSize="large"
                                        nz-button
                                        [disabled]="
                                            !customerUserForm.valid ||
                                            !customerUserForm.dirty ||
                                            isMaxNumReached
                                        "
                                        [nzLoading]="saving"
                                        nzType="primary"
                                    >
                                        <span>Save</span>
                                    </button>
                                    <button
                                        data-cy="user-form__close"
                                        type="button"
                                        nzSize="large"
                                        nz-button
                                        (click)="closeEditCustomerUser()"
                                    >
                                        <span>Close</span>
                                    </button>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </form>
        </ng-container>
    </nz-drawer>
</div>
