import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import _get from 'lodash/get';
import _compact from 'lodash/compact';
import _sortBy from 'lodash/sortBy';
import _orderBy from 'lodash/orderBy';
import { ClientDatabaseService } from 'src/app/core/services/client-database/client-database.service';
import {
    TableColumn,
    TableInput,
    TablePurpose,
    TableType,
} from 'src/app/store/models/client-database-table.model';
import {
    DB_NAMING_REGEXP_TYPES,
    DbNaming,
} from 'src/app/validators/dbNaming.validator';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UUIDValidator } from 'src/app/validators/uuid.validator';

@Component({
    selector: 'app-client-database-table-add',
    templateUrl: './client-database-table-add.component.html',
    styleUrls: ['./client-database-table-add.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientDatabaseTableAddComponent implements OnInit {
    @Input() clientId: string;
    @Input() schemaName: string;

    saving: boolean = false;

    tablePurposes: TablePurpose[] = [];
    tableTypes: TableType[] = [];

    loading: boolean;

    tableFormGroup: UntypedFormGroup;

    tableNameSuffix: string;
    tableNamePrefix: string;

    columnNameLength: number = 20;

    dataTypes: string[] = ['uuid', 'integer', 'smallint', 'text', 'boolean'];
    dataTypesLoading: boolean = false;

    @Output('closed') closed: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private cdr: ChangeDetectorRef,
        private clientDatabaseService: ClientDatabaseService,
        private fb: UntypedFormBuilder,
        private message: NzMessageService
    ) {
        this.initTableForm();
        this.dataTypes = _sortBy(this.dataTypes);
    }

    ngOnInit(): void {
        this.fetchTableConstructors();
    }

    fetchDataTypes() {
        this.dataTypesLoading = true;
        this.clientDatabaseService.getDatabaseDataTypes().subscribe({
            next: (payload) => {
                this.dataTypesLoading = false;
                this.dataTypes = _get(payload, 'payload', []);
            },
            error: () => {
                this.dataTypesLoading = false;
            },
        });
    }

    fetchTableConstructors() {
        this.loading = true;
        this.cdr.detectChanges();

        this.clientDatabaseService.getTableConstructors().subscribe({
            next: (payload) => {
                this.tablePurposes = _orderBy(_get(
                    payload,
                    ['payload', 'tablePurposes'],
                    []
                ), item => item.tablePurpose.toLowerCase(), ['asc']);
                this.tableTypes = _orderBy(_get(payload, ['payload', 'tableTypes'], []), item => item.tableType.toLowerCase(), ['asc']);
            },
            error: () => {
                this.loading = false;
                this.cdr.detectChanges();
            },
            complete: () => {
                this.loading = false;
                this.cdr.detectChanges();
            },
        });
    }

    onSave() {
        this.saving = true;
        this.cdr.detectChanges();

        const formValue = this.tableFormGroup.value;
        const tableType = _get(formValue, 'tableType');

        const requiredColumns =
            this.tableTypes?.find((item) => item.tableType === tableType)
                ?.requiredColumns ?? [];

        const requiredColumnNames = requiredColumns.map(
            (column) => column.columnName
        );

        const tableColumnsArray = <UntypedFormArray>this.tableFormGroup.get('columns');

        const input: TableInput = {
            tableName: _get(formValue, 'tableName'),
            tablePurpose: _get(formValue, 'tablePurpose'),
            tableType,
            columns:
                tableColumnsArray.controls?.flatMap((control) => {
                    const columnFormValue = control.value;

                    const columnName = _get(columnFormValue, 'columnName');
                    const columnDefaultValue = _get(
                        columnFormValue,
                        'columnDefaultValue'
                    );

                    return !requiredColumnNames.includes(columnName)
                        ? [
                              {
                                  columnName,
                                  columnDefaultValue: !columnDefaultValue
                                      ? null
                                      : columnDefaultValue,
                                  dataType: _get(columnFormValue, 'dataType'),
                                  isNullable: !_get(
                                      columnFormValue,
                                      'isNotNull'
                                  ),
                                  isPrimaryKey: false,
                              },
                          ]
                        : [];
                }) ?? [],
        };

        this.clientDatabaseService
            .postTable(input, this.clientId, this.schemaName)
            .subscribe({
                next: () => {
                    this.message.success(
                        `Table has been created successfully!`,
                        { nzDuration: 4000 }
                    );
                },
                error: () => {
                    this.saving = false;
                    this.cdr.detectChanges();
                    this.message.error(`Failed to create table`);
                },
                complete: () => {
                    this.saving = false;
                    this.cdr.detectChanges();
                    this.closed.emit(true);
                },
            });
    }

    onClose() {
        this.closed.emit(false);
    }

    onChangeTablePurpose(value: string) {
        const purpose = this.tablePurposes.find(
            (item) => item.tablePurpose === value
        );

        if (purpose) {
            this.tableNameSuffix = purpose?.suffix;
            this.tableNamePrefix = purpose?.prefix;
        }

        this.cdr.detectChanges();
    }

    onChangeTableType(value: string) {
        const type = this.tableTypes.find((item) => item.tableType === value);
        const columnsFormArray = this.tableFormGroup?.get(
            'columns'
        ) as UntypedFormArray;
        columnsFormArray.clear();

        if (type && type.requiredColumns?.length) {
            type.requiredColumns.forEach((reqColumn) => {
                columnsFormArray.push(
                    this.createColumnFormGroup(reqColumn, true)
                );
            });
        }

        this.cdr.detectChanges();
    }

    private initTableForm() {
        this.tableFormGroup = this.fb.group(
            {
                tableName: ['', Validators.required],
                tablePurpose: ['', Validators.required],
                tableType: ['', Validators.required],
                columns: this.fb.array([], Validators.minLength(1)),
            },
            {
                validators: [
                    DbNaming('tableName', DB_NAMING_REGEXP_TYPES.LONG),
                ],
            }
        );
    }

    get columnsFormArray() {
        return this.tableFormGroup?.get('columns') as UntypedFormArray;
    }

    onAddColumn() {
        (this.tableFormGroup?.get('columns') as UntypedFormArray).push(
            this.createColumnFormGroup()
        );

        this.cdr.detectChanges();
    }

    private createColumnFormGroup(column?: TableColumn, disabled = false) {
        return this.fb.group({
            columnName: [
                { value: column?.columnName ?? '', disabled },
                [
                    Validators.required,
                ],
            ],
            dataType: [
                { value: column?.dataType ?? '', disabled },
                Validators.required,
            ],
            columnDefaultValue: [
                { value: column?.columnDefaultValue ?? '', disabled },
                !column?.isNullable ? [Validators.required] : []
            ],
            isNotNull: [
                { value: !column?.isNullable, disabled },
                Validators.required,
            ],
            isPrimaryKey: [
                { value: column?.isPrimaryKey ?? false, disabled },
                Validators.required,
            ],
            fk: [
                {
                    value:
                        _compact([
                            column?.foreignKey?.referencedSchema,
                            column?.foreignKey?.referencedTable,
                            column?.foreignKey?.referencedColumn,
                        ]).join('.') ?? '',
                    disabled,
                },
            ],
        },  {
            validators: [
                DbNaming('columnName', DB_NAMING_REGEXP_TYPES.SHORT),
            ],
        });
    }

    onNotNullToggleChanged(value: boolean, i: number) {
        const columnForm = (this.tableFormGroup?.get('columns') as UntypedFormArray).at(i);
        if (value) {
            columnForm.get('columnDefaultValue').addValidators(Validators.required);
        } else {
            columnForm.get('columnDefaultValue').removeValidators(Validators.required);
        }
        columnForm.get('columnDefaultValue').updateValueAndValidity();
        this.cdr.detectChanges();
    }

    onColumnDataTypeChanged(dataType: string, i: number) {
        const columnForm = (this.tableFormGroup?.get('columns') as UntypedFormArray).at(i);

        columnForm.get('columnDefaultValue').clearValidators();

        if (!!columnForm.get('isNotNull').value) {
            columnForm.get('columnDefaultValue').addValidators(Validators.required);
        }

        if (dataType === 'uuid') {
            columnForm.get('columnDefaultValue').addValidators(UUIDValidator());
        } else if (dataType === 'smallint') {
            columnForm.get('columnDefaultValue').addValidators([Validators.min(-32768), Validators.max(32767), Validators.pattern("^[0-9]*$")]);
        } else if (dataType === 'integer') {
            columnForm.get('columnDefaultValue').addValidators([Validators.min(-2147483648), Validators.max(2147483647), Validators.pattern("^[0-9]*$")]);
        }
        columnForm.get('columnDefaultValue').updateValueAndValidity();
        this.cdr.detectChanges();
        return;
    }

    onDeleteColumn(i: number) {
        (this.tableFormGroup?.get('columns') as UntypedFormArray).removeAt(i);
        this.cdr.detectChanges();
    }
}
