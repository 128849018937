import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { DataUploadHistory } from 'src/app/store/models/data-upload-history';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

@Component({
    selector: 'app-upload-history-tile',
    templateUrl: './upload-history-tile.component.html',
    styleUrls: ['./upload-history-tile.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadHistoryTileComponent implements OnInit {
    @Input() item: DataUploadHistory;

    constructor() {}

    ngOnInit(): void {}

    formatDate(date: number) {
        return dayjs().to(dayjs(date));
    }
}
