import { Component, OnDestroy, OnInit } from '@angular/core';
import _first from 'lodash/first';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _orderBy from 'lodash/orderBy';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state';
import { selectAuthUser } from '../../../store/selectors/auth.selectors';
import { Subject, takeUntil } from 'rxjs';
import { UploadPortal } from 'src/app/store/models/upload-portal';
import { UserUploadCollectionStatus } from 'src/app/store/models/upload-portal-status.model';
import { UploadPortalService } from 'src/app/core/services/upload-portal/upload-portal.service';
import { DataUploadHistory } from 'src/app/store/models/data-upload-history';
import { UploadPortalType } from 'src/app/store/models/upload-portal-type.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserReportLog } from 'src/app/store/models/user-report-log.model';
import { ReportService } from 'src/app/core/services/report/report.service';
import { ReportGroup } from 'src/app/store/models/report-group.model';
import { CustomerService } from 'src/app/core/services/customer/customer.service';
import { Customer } from 'src/app/store/models/customer.model';
import { UserLogin } from 'src/app/store/models/user-login.model';
import { UserService } from 'src/app/core/services/user/user.service';
import { ClientLogin } from 'src/app/store/models/client-login.model';
import { ClientMetrics } from 'src/app/store/models/client-metrics.model';
import { UserPermissions } from 'src/app/store/models/user-permissions.model';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const colorsPalette = [
    '#158FA7',
    '#5BBF21',
    '#F2BF49',
    '#FD625E',
    '#61C5DE',
    '#8CE35B',
    '#F7DE6F',
    '#FD817E',
    '#002C41',
    '#C0FAFF',
    '#599053',
    '#FA913B',
    '#CA6082',
    '#FFE9CF',
    '#F9F871',
    '#91705A',
];

@Component({
    selector: 'app-index-page',
    templateUrl: './index-page.component.html',
    styleUrls: ['./index-page.component.less'],
})
export class IndexPageComponent implements OnInit, OnDestroy {
    uploadPortalTypes: UploadPortalType[];
    uploadPortalTypesLoading: boolean;
    selectedUploadPortalTypeId: string;

    userUploadPortals: UploadPortal[];
    userUploadPortalsLoading: boolean;
    selectedUploadPortalId: string;

    userDataUploadHistory: DataUploadHistory[];
    userDataUploadHistoryVisibleRows = [];
    userDataUploadHistoryLoading: boolean;

    userReportLogs: UserReportLog[];
    userReportLogsLoading: boolean;

    userUploadStatuses: UserUploadCollectionStatus[];
    userUploadStatusesLoading: boolean;

    reportGroups: ReportGroup[];
    reportGroupsLoading: boolean;

    customers: Customer[];
    customersLoading: boolean;
    clientId: string;

    userLogins: UserLogin[];
    userLoginsLoading: boolean;
    userLoginsClientId: string;

    clientLogins: ClientLogin[];
    clientLoginsLoading: boolean;

    clientMetrics: ClientMetrics[];
    clientMetricsLoading: boolean;

    userPermissions: UserPermissions;
    userFullName: string;
    userLastLogin: number;

    pbiLink: string;

    option;
    clientMetricsOption;

    events: string[] = [];
    event: string = '';
    eventsLoading: boolean = false;
    userLoginId: string;

    constructor(
        private store: Store<AppState>,
        private uploadPortalService: UploadPortalService,
        private reportService: ReportService,
        private message: NzMessageService,
        private customerService: CustomerService,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.store.select(selectAuthUser).subscribe((user) => {
            if (
                user &&
                user.userRoles &&
                user.userLoginId !== this.userLoginId
            ) {
                this.userLoginId = user.userLoginId;
                this.userPermissions = this.processUserRoles(user.userRoles);
                if (
                    !(
                        this.userPermissions.isAiUser ||
                        this.userPermissions.isAiAdmin
                    )
                ) {
                    this.onClientChanged(user.clientId);
                }
                this.userFullName = `${user.userFirstName} ${user.userLastName}`;
                this.userLastLogin = user.userLastLogin;

                this.eventsLoading = true;
                if (
                    this.userPermissions.isAiAdmin ||
                    (this.userPermissions.isAiUser &&
                        (this.userPermissions.hasUploadPortalMgmt ||
                            this.userPermissions.hasUploadPortalMgmtView)) ||
                    this.userPermissions.isClientAdmin ||
                    (this.userPermissions.isClientUser &&
                        this.userPermissions.hasUploadPortal)
                ) {
                    this.uploadPortalService.getEvents().subscribe({
                        next: (payload) => {
                            this.events = _orderBy(_get(payload, 'payload', []), event => event.toLowerCase(), ['asc']);
                        },
                        error: (response) => {
                            this.displayErrorMessage(
                                response,
                                'Cannot load user report logs'
                            );
                        },
                        complete: () => {
                            this.eventsLoading = false;
                        },
                    });
                }

                if (
                    this.userPermissions.isAiAdmin ||
                    (this.userPermissions.isAiUser &&
                        (this.userPermissions.hasReportMgmt ||
                            this.userPermissions.hasReportMgmtView)) ||
                    this.userPermissions.isClientAdmin ||
                    (this.userPermissions.isClientUser &&
                        this.userPermissions.hasReportPortal)
                ) {
                    this.userReportLogsLoading = true;
                    this.reportService
                        .getUserReportLogs(5) // /api/report-portal/pbi-report/logs?num=5
                        .subscribe({
                            next: (result) => {
                                this.userReportLogs = _get(result, 'payload');
                                this.userReportLogsLoading = false;
                            },
                            error: (response) => {
                                this.displayErrorMessage(
                                    response,
                                    'Cannot load user report logs'
                                );
                            },
                        });
                }

                if (
                    this.userPermissions.isAiAdmin ||
                    this.userPermissions.isAiUser
                ) {
                    // clients (open to all roles)
                    this.customersLoading = true;
                    this.customerService
                        .getCustomers()
                        .subscribe({
                            next: (result) => {
                                const payload: Customer[] = _get(
                                    result,
                                    'payload',
                                    []
                                );

                                this.clientId = '';
                                if (
                                    this.userPermissions.isAiAdmin ||
                                    (this.userPermissions.isAiUser &&
                                        (this.userPermissions
                                            .hasUploadPortalMgmt ||
                                            this.userPermissions
                                                .hasUploadPortalMgmtView))
                                ) {
                                    this.customers = payload;
                                } else if (
                                    this.userPermissions.isClientAdmin ||
                                    (this.userPermissions.isClientUser &&
                                        this.userPermissions.hasUploadPortal)
                                ) {
                                    this.customers = payload.filter(
                                        ({ clientId }) => {
                                            return user.clientId === clientId;
                                        }
                                    );
                                    this.clientId = _get(
                                        _first(this.customers),
                                        'clientId'
                                    );
                                } else {
                                    this.customers = [];
                                }
                                this.customersLoading = false;
                                this.onClientChanged(this.clientId);
                            },
                            error: (response) => {
                                this.displayErrorMessage(
                                    response,
                                    'Cannot retrieve the clients list'
                                );
                            },
                        });
                } else {
                    this.onClientChanged('');
                }
                // statuses
                if (
                    this.userPermissions.hasLtdUserMgmt ||
                    (this.userPermissions.isClientUser &&
                        this.userPermissions.hasUploadPortal)
                ) {
                    this.userUploadStatusesLoading = true;
                    this.uploadPortalService
                        .getUserUploadCollectionStatuses()
                        .subscribe({
                            next: (result) => {
                                this.userUploadStatuses = _get(
                                    result,
                                    'payload'
                                );
                                this.userUploadStatusesLoading = false;
                            },
                            error: (response) => {
                                this.displayErrorMessage(
                                    response,
                                    'Cannot load user upload collection statuses'
                                );
                            },
                        });
                }
                if (this.userPermissions.isAiAdmin) {
                    // customer-logins
                    this.clientLoginsLoading = true;
                    this.customerService
                        .getCustomerLogins(10) // /apiclient/login-counts?limit=..
                        .subscribe({
                            next: (result) => {
                                this.clientLogins = _get(result, 'payload', []);
                                this.option = {
                                    title: {},
                                    tooltip: {
                                        trigger: 'item',
                                    },
                                    legend: {
                                        orient: 'horizontal',
                                        type: 'scroll',
                                    },
                                    series: [
                                        {
                                            name: 'Number of logins',
                                            type: 'pie',
                                            radius: '85%',
                                            center: ['45%', '57%'],
                                            label: {
                                                position: 'inner',
                                                fontSize: 14,
                                                formatter: '{c}',
                                            },
                                            labelLine: {
                                                show: false,
                                            },
                                            data: this.clientLogins.map(
                                                (item) => ({
                                                    name: item.clientName,
                                                    value: item.total,
                                                })
                                            ),
                                            emphasis: {
                                                itemStyle: {},
                                            },
                                        },
                                    ],
                                    color: colorsPalette,
                                };

                                this.clientLoginsLoading = false;
                            },
                            error: (response) => {
                                this.displayErrorMessage(
                                    response,
                                    'Cannot get client logins counts'
                                );
                            },
                        });

                    // customer-metrics (totals)
                    this.clientLoginsLoading = true;
                    this.customerService
                        .getCustomerMetrics()
                        .subscribe({
                            next: (result) => {
                                this.clientMetrics = _get(
                                    result,
                                    'payload',
                                    []
                                );
                                this.clientMetricsLoading = false;
                                this.clientMetricsOption = {
                                    title: {},
                                    tooltip: {
                                        trigger: 'item',
                                    },
                                    legend: {
                                        orient: 'horizontal',
                                        type: 'scroll',
                                    },
                                    series: [
                                        {
                                            name: 'Number of users',
                                            type: 'pie',
                                            radius: '85%',
                                            center: ['45%', '57%'],
                                            label: {
                                                position: 'inner',
                                                fontSize: 14,
                                                formatter: '{c}',
                                            },
                                            labelLine: {
                                                show: false,
                                            },
                                            data: this.clientMetrics.map(
                                                (item) => ({
                                                    name: item.clientName,
                                                    value: item.total,
                                                })
                                            ),
                                            emphasis: {
                                                itemStyle: {},
                                            },
                                        },
                                    ],
                                    color: colorsPalette,
                                };
                            },
                            error: (response) => {
                                this.displayErrorMessage(
                                    response,
                                    'Cannot get client metrics (total users)'
                                );
                            },
                        });

                    // recent-logins (all clients)
                    this.userLoginsLoading = true;
                    this.userService
                        .getRecentUserLogins(null, 40)
                        .subscribe({
                            next: (result) => {
                                this.userLogins = _get(result, 'payload');
                                this.userLoginsClientId = '';
                                this.userLoginsLoading = false;
                            },
                            error: (response) => {
                                this.displayErrorMessage(
                                    response,
                                    'Cannot load recent user login entries'
                                );
                            },
                        });
                }
            }
        });
    }

    displayErrorMessage(response: any, defaultMessage: string) {
        const message =
            response.error && response.error.message
                ? response.error.message
                : defaultMessage;
        this.message.error(message, { nzDuration: 3000 });
    }

    onClientOfUserLoginsSelected(clientId: string) {
        this.userLoginsLoading = true;
        this.userService
            .getRecentUserLogins(clientId, 40)
            .subscribe({
                next: (result) => {
                    this.userLogins = _get(result, 'payload');
                    this.userLoginsLoading = false;
                },
                error: (response) => {
                    this.displayErrorMessage(
                        response,
                        'Cannot load recent user login entries'
                    );
                },
            });
    }

    onClientChanged(clientId: string) {
        this.selectedUploadPortalTypeId = ''; // reset filters and arrays
        this.uploadPortalTypes = [];
        this.selectedUploadPortalId = '';
        this.userUploadPortals = [];

        if (
            this.userPermissions.isAiAdmin ||
            (this.userPermissions.isAiUser &&
                (this.userPermissions.hasUploadPortalMgmt ||
                    this.userPermissions.hasUploadPortalMgmtView)) ||
            this.userPermissions.isClientAdmin ||
            (this.userPermissions.isClientUser &&
                this.userPermissions.hasUploadPortal)
        ) {
            if (clientId) {
                this.uploadPortalTypesLoading = true;
                this.uploadPortalService
                    .getUploadPortalTypesByClientId(clientId)
                    .subscribe({
                        next: (result) => {
                            this.uploadPortalTypes = _orderBy(_get(result, 'payload'), uploadPortalType => uploadPortalType.uploadPortalTypeName.toLowerCase(), ['asc']);
                            this.uploadPortalTypesLoading = false;

                            this.onUploadPortalChanged();
                        },
                        error: (response) => {
                            this.displayErrorMessage(
                                response,
                                'Cannot load upload page types'
                            );
                        },
                    });
            } else {
                this.clientId = ''; // reset the filter variable
                this.onUploadPortalChanged();
            }
        }
    }

    onEventChanged(event: string) {
        this.event = event;
        this.onUploadPortalChanged();
    }

    onUploadPortalTypeChanged(uploadPortalTypeId: string) {
        this.selectedUploadPortalId = '';
        this.userUploadPortals = [];

        if (uploadPortalTypeId) {
            this.userUploadPortalsLoading = true;

            if (
                this.userPermissions.isAiAdmin ||
                this.userPermissions.hasUploadPortalMgmt ||
                this.userPermissions.hasUploadPortalMgmtView ||
                this.userPermissions.isClientAdmin ||
                this.userPermissions.hasUploadPortal
            ) {
                this.uploadPortalService
                    .getUploadPortalsByClientAndType(uploadPortalTypeId)
                    .subscribe({
                        next: (result) => {
                            this.userUploadPortals = _orderBy(_get(result, 'payload'), template => template.uploadPortalName.toLowerCase(), ['asc']);
                            this.userUploadPortalsLoading = false;
                            this.onUploadPortalChanged();
                        },
                        error: (response) => {
                            this.displayErrorMessage(
                                response,
                                'Cannot load user data templates'
                            );
                        },
                    });
            }
        } else {
            // reset the portals filter and the selector variables
            this.selectedUploadPortalTypeId = '';

            this.onUploadPortalChanged();
        }
    }

    onUploadPortalChanged() {
        this.userDataUploadHistoryLoading = true;
        this.userDataUploadHistoryVisibleRows = [];
        if (
            this.userPermissions.isClientUser &&
            this.userPermissions.hasUploadPortal
        ) {
            this.uploadPortalService
                .getUploadPortalHistoryByUser(
                    this.selectedUploadPortalTypeId,
                    this.selectedUploadPortalId,
                    this.event,
                    30
                )
                .subscribe((result) => {
                    this.userDataUploadHistory = _get(result, 'payload');
                    this.userDataUploadHistoryVisibleRows = this
                        .userDataUploadHistory.length
                        ? Array.from({
                              length: Math.max(
                                  Math.floor(
                                      this.userDataUploadHistory.length / 2
                                  ),
                                  1
                              ),
                          })
                        : [];
                    this.userDataUploadHistoryLoading = false;
                });
        } else {
            // admin users or users with broader scope
            this.uploadPortalService
                .getUploadPortalHistory(
                    this.clientId,
                    this.selectedUploadPortalTypeId,
                    this.selectedUploadPortalId,
                    this.event,
                    30
                )
                .subscribe((result) => {
                    this.userDataUploadHistory = _get(result, 'payload');
                    this.userDataUploadHistoryVisibleRows = this
                        .userDataUploadHistory.length
                        ? Array.from({
                              length: Math.max(
                                  Math.floor(
                                      this.userDataUploadHistory.length / 2
                                  ),
                                  1
                              ),
                          })
                        : [];
                    this.userDataUploadHistoryLoading = false;
                });
        }
    }

    processUserRoles(roles: string[]): UserPermissions {
        return {
            isAiAdmin: roles.includes('AIADMIN'),
            isAiUser: roles.includes('AIUSER'),
            isClientAdmin: roles.includes('CLIENTADMIN'),
            isClientUser: roles.includes('CLIENTUSER'),
            hasReportMgmt: roles.includes('REPORT_MGMT'),
            hasReportMgmtView: roles.includes('REPORT_MGMT_VIEW'),
            hasUploadPortalMgmt: roles.includes('UPLOAD_PORTAL_MGMT'),
            hasUploadPortalMgmtView: roles.includes('UPLOAD_PORTAL_MGMT_VIEW'),
            hasLtdUserMgmt: roles.includes('LTD_USER_ADMIN'),
            hasReportPortal: roles.includes('REPORT_PORTAL'),
            hasUploadPortal: roles.includes('UPLOAD_PORTAL'),
        };
    }

    get showClientReports(): boolean {
        return (
            this.userPermissions.isClientAdmin ||
            (this.userPermissions.isClientUser &&
                this.userPermissions.hasReportPortal)
        );
    }

    ngOnDestroy() {}

    formatDate(date: number) {
        return dayjs().to(dayjs(date));
    }
}
