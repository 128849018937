import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable, Subject } from 'rxjs';
import { CustomerUser } from '../../../store/models/customer-user.model';
import _get from 'lodash/get';
import { UserLogin } from 'src/app/store/models/user-login.model';
import { User } from 'src/app/store/models/user.model';

export interface UserInput {
    userId?: string;
    userLoginId: string;
    userLoginTypeId?: string;
    userFirstName: string;
    userLastName: string;
    clientId: string;
    attrIds: string[];
    roleIds: string[];
    reportGroupIds: string[];
    userUploadPortalTypeIds: string[];
    isAdmin: boolean;
    userAllowedClientIds: string[];
}

export interface UsersFilter {
    selectedUploadPortalTypeIds?: string[];
    selectedReportGroupIds?: string[];
    selectedAttributeIds?: string[];
    selectedRoleIds?: string[];
    isAdmin?: string;
    searchText?: string;
    lastUserId?: string;
    lastCreatedStamp?: number;
    lastUserLoginId?: string;
    columnDirection?: string;
    sortByColumnName?: string;
    lastLastName?: string;
    lastFirstName?: string;
    sortDirection?: string;
    limit?: number;
}

@Injectable({
    providedIn: 'root',
})
export class UserService {
    newUserCreated$: Subject<boolean> = new Subject<boolean>();

    constructor(private apiService: ApiService) {}

    getProcurementUsersByClientId(clientId: string, filter?: UsersFilter): Observable<{ payload: CustomerUser[] }> {
        const params = {};

        if (filter) {
            if (filter.searchText) {
                params['searchText'] = filter.searchText;
            }
            if (filter.limit) {
                params['limit'] = filter.limit;
            }
        }

        return this.apiService.get(`procurement/clients/${clientId}/users`, params)
    }

    getUsersByClientId(
        clientId: string,
        filter?: UsersFilter
    ): Observable<{ payload: CustomerUser[] }> {
        const params = {};
        if (filter) {
            if (filter.searchText) {
                params['searchText'] = filter.searchText;
            }
            if (
                filter.selectedAttributeIds &&
                filter.selectedAttributeIds.length
            ) {
                params['attrIds'] = filter.selectedAttributeIds;
            }
            if (
                filter.selectedReportGroupIds &&
                filter.selectedReportGroupIds.length
            ) {
                params['reportGroupIds'] = filter.selectedReportGroupIds;
            }
            if (
                filter.selectedUploadPortalTypeIds &&
                filter.selectedUploadPortalTypeIds.length
            ) {
                params['uploadPortalTypeIds'] = filter.selectedUploadPortalTypeIds;
            }
            if (filter.selectedRoleIds && filter.selectedRoleIds.length) {
                params['roleIds'] = filter.selectedRoleIds;
            }
            if (filter.lastUserId) {
                params['lastUserId'] = filter.lastUserId;
            }
            if (filter.lastCreatedStamp) {
                params['lastCreatedStamp'] = filter.lastCreatedStamp;
            }
            if (filter.lastUserLoginId) {
                params['lastUserLoginId'] = filter.lastUserLoginId;
            }
            if (filter.columnDirection) {
                params['sortDirection'] = filter.columnDirection;
            }
            if (filter.sortByColumnName) {
                params['sortByColumnName'] = filter.sortByColumnName;
            }
            if (filter.lastFirstName) {
                params['lastFirstName'] = filter.lastFirstName;
            }
            if (filter.lastLastName) {
                params['lastLastName'] = filter.lastLastName;
            }
            if (filter.limit) {
                params['limit'] = filter.limit;
            }
            if (!!filter.isAdmin) {
                params['isAdmin'] = filter.isAdmin;
            }
        }
        return this.apiService.get('users', { clientId, ...params });
    }

    getDBHistoryUsers(): Observable<{ payload: Pick<CustomerUser, 'userLoginId' | 'userId'>[] }> {
        return this.apiService.get('db-activity-history/users');
    }

    validateToken(token: string): Observable<any> {
        return this.apiService.get('user/validate-token?t=' + token);
    }

    resetPassword(token: string, password: string): Observable<boolean> {
        return this.apiService.put('user/reset-password', {
            token,
            password,
        });
    }

    sendResetPasswordRequest(email: string): Observable<boolean> {
        return this.apiService.post('user/reset-password-request', {
            email,
        });
    }

    getUser(userId: string): Observable<{ payload: CustomerUser }> {
        return this.apiService.get(`user/${userId}`);
    }

    putUser(userInput: UserInput): Observable<{ payload: CustomerUser }> {
        return this.apiService.put('user', userInput);
    }

    postUser(userInput: UserInput): Observable<{ payload: CustomerUser }> {
        return this.apiService.post('user', userInput);
    }

    deleteUser(userId: string): Observable<any> {
        return this.apiService.delete(`user/${userId}`);
    }

    getRecentUserLogins(
        clientId: string,
        limit: number
    ): Observable<{ payload: UserLogin }> {
        if (clientId) {
            return this.apiService.get(
                `users/recent-logins?client_id=${clientId}&limit=${limit}`
            );
        } else {
            return this.apiService.get(`users/recent-logins?limit=${limit}`);
        }
    }

    getUsersByReportGroupId(
        reportGroupId: string
    ): Observable<{ payload: User[] }> {
        return this.apiService.get(`users/for-report-group/${reportGroupId}`);
    }

    getUsersByUploadPortalTypeId(
        uploadPortalTypeId: string
    ): Observable<{ payload: User[] }> {
        return this.apiService.get(
            `users/for-upload-portal-type/${uploadPortalTypeId}`
        );
    }

    getUsersByAttributeId(
        attributeId: string
    ): Observable<{ payload: User[] }> {
        return this.apiService.get(`users/for-attribute/${attributeId}`);
    }
}
