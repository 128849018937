import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, Subject } from 'rxjs';
import {
    ClientDatabaseService,
    ClientDatabaseFilter,
} from '../../../core/services/client-database/client-database.service';
import { CoreService } from '../../../core/services/core/core.service';
import {
    AI_CLIENT_ID,
    CustomerService,
} from '../../../core/services/customer/customer.service';
import {
    ClientDatabaseTable,
    DatabaseSchema,
} from '../../../store/models/client-database-table.model';
import { debounceTime, takeUntil } from 'rxjs/operators';
import _last from 'lodash/last';
import _find from 'lodash/find';
import { AiDatabaseService } from 'src/app/core/services/ai-database/ai-database.service';
import { TextSearchInputComponent } from 'src/app/core/components/text-search-input/text-search-input.component';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/core/services/global/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessesCategory } from 'src/app/core/components/process-history-table/process-history-table.component';

const PAGE_SIZE = 100;

@Component({
    selector: 'app-database-page',
    templateUrl: './database-page.component.html',
    styleUrls: ['./database-page.component.less'],
})
export class DatabasePageComponent implements OnInit, OnDestroy {
    selectedClientId: string;
    clientId$: BehaviorSubject<string> = new BehaviorSubject(undefined);
    exportingTableByTableName: Map<string, boolean> = new Map();
    
    selectedTabIndex: number = 0;

    tables: ClientDatabaseTable[];
    tablesLoading: boolean = true;
    tablesLoaded: boolean = false;

    selectedTableName: string;

    appendFormMode: boolean = false;
    updateFormMode: boolean = false;
    modifyFormMode: boolean = false;
    removeFormMode: boolean = false;
    createDatabaseFormMode: boolean = false;
    createClientDataMode: boolean = false;
    tableInfoMode: boolean = false;
    addTableMode: boolean = false;

    query: string;
    searchFilter = {
        searchText: '',
        sortDirection: 'ascend',
        tableName: '',
    };
    columns = [
        {
            title: 'Table name',
            key: 'table_name',
            width: '100%',
            sortDirections: ['descend', 'ascend', null],
        },
    ];

    categories = ProcessesCategory;

    aiClientId = AI_CLIENT_ID;

    @ViewChild('tableNameSearch') tableNameSearchComp: TextSearchInputComponent;

    destroyed$ = new Subject();

    filterForm: Subject<ClientDatabaseFilter> = new Subject();

    schemaName: string;
    schemas: DatabaseSchema[] = [];
    schemasLoading: boolean = false;

    constructor(
        private clientDatabaseService: ClientDatabaseService,
        private aiDatabaseService: AiDatabaseService,
        private activatedRoute: ActivatedRoute,
        private message: NzMessageService,
        private modal: NzModalService,
        private core: CoreService,
        public clientService: CustomerService,
        private globalService: GlobalService,
        private router: Router
    ) {
        this.filterForm
            .pipe(takeUntil(this.destroyed$), debounceTime(400))
            .subscribe((filter) => {
                this.fetchSchemaTables(this.selectedClientId, filter);
            });
    }

    ngOnInit() {
        if (this.router.url.includes('/admin')) {
            this.onClientChanged(AI_CLIENT_ID);
        } else {
            this.globalService.clientId$
                .pipe(takeUntil(this.destroyed$))
                .subscribe((clientId: string) => {
                    this.onClientChanged(clientId);
                });
        }
    }

    onClientChanged(clientId: string) {
        this.tablesLoading = true;
        this.tables = null;
        this.selectedClientId = clientId;
        this.schemaName = undefined;
        this.tableNameSearchComp?.onClear();
        if (clientId) {
            this.tablesLoaded = false;
            if (clientId === AI_CLIENT_ID) {
                this.fetchSchemas(this.selectedClientId);
            } else {
                this.fetchSchemaTables(clientId);
            }
        }
    }

    onSchemaChanged(schemaName: string) {
        this.tableNameSearchComp.onClear();
        this.tables = [];
        this.tablesLoaded = false;
        this.schemaName = schemaName;

        this.fetchSchemaTables(this.selectedClientId);
    }

    fetchSchemaTables(clientId: string, filter?: ClientDatabaseFilter) {
        if (this.tablesLoaded) return;
        if (!this.selectedClientId) return;

        let params = { ...(filter ?? {}), schemaName: this.schemaName, limit: PAGE_SIZE };

        this.clientDatabaseService
            .getClientSchemaTables(clientId, params)
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: (payload) => {
                    const tables = _get(payload, 'payload', []);
                    if (tables.length < PAGE_SIZE) {
                        this.tablesLoaded = true;
                    }
                    if (filter && filter.lastTableName) {
                        if (tables.length) {
                            this.tables = [...this.tables, ...tables];
                        }
                    } else {
                        this.tables = tables;
                    }
                },
                complete: () => {
                    this.tablesLoading = false;
                    this.query = this.searchFilter.searchText;
                },
                error: () => {
                    this.tablesLoading = false;
                },
            });
    }

    fetchSchemas(clientId: string) {
        if (!this.selectedClientId) return;

        this.schemasLoading = true;
        this.schemas = [];

        this.aiDatabaseService
            .getSchemas(clientId)
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: (payload) => {
                    const data = _get(payload, 'payload', []);
                    this.schemas = _orderBy(Array.isArray(data) ? data : [data], item => item.schemaName, ['asc']);
                    this.schemasLoading = false;

                    data.forEach((item) => {
                        if (item.isDefault) {
                            this.schemaName = item.schemaName;
                            this.onSchemaChanged(this.schemaName);
                        }
                    });
                },
                complete: () => {
                    this.schemasLoading = false;
                },
                error: () => {
                    this.schemasLoading = false;
                },
            });
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
    }

    onCreateDatabase() {
        this.createDatabaseFormMode = true;
    }

    onCreateClientDataTable() {
        this.createClientDataMode = true;
    }

    closeCreateClientDataMode(withReload: boolean = false) {
        this.createClientDataMode = false;
        if (withReload) {
            this.fetchSchemaTables(this.selectedClientId);
        }
    }

    closeCreateDatabaseFormMode(withReload: boolean = false) {
        this.createDatabaseFormMode = false;
        if (withReload) {
            this.tablesLoaded = false;
            this.fetchSchemaTables(this.selectedClientId);
        }
    }

    onDeleteTable(tableName: string) {
        this.modal.confirm({
            nzTitle: `Do you want to delete table <b>${tableName}</b>?`,
            nzContent: `
        If you delete it you will not be able to recover table. <br>
        Do you want to delete the table?
      `,
            nzOnOk: () =>
                this.clientDatabaseService
                    .deleteTable(
                        this.selectedClientId,
                        tableName,
                        this.schemaName
                    )
                    .subscribe({
                        next: () => {
                            this.tablesLoaded = false;
                            this.filterForm.next({
                                searchText: this.searchFilter.searchText,
                                sortDirection: this.searchFilter.sortDirection,
                                lastTableName: this.searchFilter.tableName,
                            });
                            this.message.success(
                                `<b>${tableName}</b> data is being processed. Please check \"Transaction History\".`,
                                { nzDuration: 3500 }
                            );
                        },
                        error: (error) => {
                            this.message.error(
                                `Failed to delete table <b>${tableName}</b>: '${_get(
                                    error,
                                    ['error', 'errors', 0]
                                )}'!`,
                                {
                                    nzDuration: 6000,
                                }
                            );
                        },
                    }),
            nzOkText: 'Yes, delete',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzCancelText: 'Close',
            nzClosable: false,
            nzOnCancel: () => {},
        });
    }

    onTruncateTable(tableName: string) {
        this.modal.confirm({
            nzTitle: `Do you want to truncate table <b>${tableName}</b>?`,
            nzContent: `
        If you truncate the table you will not be able to restore its contents. <br>
        Do you want to truncate the table?
      `,
            nzOnOk: () =>
                this.clientDatabaseService
                    .truncateTable(
                        this.selectedClientId,
                        tableName,
                        this.schemaName
                    )
                    .subscribe(
                        () => {
                            this.fetchSchemaTables(this.selectedClientId);
                            this.message.success(
                                `<b>${tableName}</b> data is being processed. Please check \"Transaction History\".`,
                                { nzDuration: 3500 }
                            );
                        },
                        (error) => {
                            this.message.error(
                                `Failed to truncate table <b>${tableName}</b>: '${_get(
                                    error,
                                    ['error', 'errors', 0]
                                )}'!`,
                                {
                                    nzDuration: 6000,
                                }
                            );
                        }
                    ),
            nzOkText: 'Yes, truncate',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzCancelText: 'Close',
            nzClosable: false,
            nzOnCancel: () => {},
        });
    }

    onInfo(tableName: string) {
        this.selectedTableName = tableName;
        this.tableInfoMode = true;
    }

    closeTableInfoMode() {
        this.selectedTableName = null;
        this.tableInfoMode = false;
    }

    onSelectedTabChanged(index: number) {
        this.selectedTabIndex = index;
    }

    onAppend(table: ClientDatabaseTable) {
        this.selectedTableName = table.tableName;
        this.appendFormMode = true;
    }

    onCloseAppend(withReload: boolean = false) {
        this.selectedTableName = null;
        this.appendFormMode = false;
    }

    onUpdate(table: ClientDatabaseTable) {
        this.selectedTableName = table.tableName;
        this.updateFormMode = true;
    }

    onCloseUpdate(event) {
        this.selectedTableName = null;
        this.updateFormMode = false;
    }

    onModify(table: ClientDatabaseTable) {
        this.selectedTableName = table.tableName;
        this.modifyFormMode = true;
    }

    onRemove(table: ClientDatabaseTable) {
        this.selectedTableName = table.tableName;
        this.removeFormMode = true;
    }

    onCloseRemove() {
        this.selectedTableName = null;
        this.removeFormMode = false;
    }

    onCloseModify() {
        this.selectedTableName = null;
        this.modifyFormMode = false;
    }

    onHistory(tableName: string) {
        this.router.navigate(
            [], 
            {
              relativeTo: this.activatedRoute,
              queryParams: { tableNameSearchText: tableName },
              queryParamsHandling: 'merge'
            }
          );
        this.selectedTabIndex = 1;
    }

    onExport(tableName: string) {
        this.exportingTableByTableName.set(tableName, true);
        this.clientDatabaseService
            .exportTable(this.selectedClientId, tableName, this.schemaName)
            .subscribe({
                next: (payload) => {
                    this.core.saveZIPResponse(
                        `${tableName}_${new Date().getTime()}.zip`,
                        payload
                    );
                    this.exportingTableByTableName.set(tableName, false);
                },
                error: () => {
                    this.message.error(
                        `Failed to export table <b>${tableName}</b>!`,
                        { nzDuration: 4000 }
                    );
                    this.exportingTableByTableName.set(tableName, false);
                },
            });
    }

    isExporting(tableName: string): boolean {
        return this.exportingTableByTableName.get(tableName);
    }
    orderChange(col: any) {
        this.tablesLoaded = false;
        this.tablesLoading = true;
        this.searchFilter.sortDirection = col.value;
        this.filterForm.next({
            searchText: this.searchFilter.searchText,
            sortDirection: col.value,
        });
    }
    onTableScrolled() {
        if (!this.tablesLoaded) {
            const tbls: ClientDatabaseTable = _last(this.tables);
            if (tbls) {
                const { tableName } = tbls;
                this.filterForm.next({
                    searchText: this.searchFilter.searchText,
                    sortDirection: this.searchFilter.sortDirection,
                    lastTableName: tableName,
                });
            }
        }
    }
    onSearch(value: string) {
        this.tablesLoaded = false;
        this.tablesLoading = true;
        this.searchFilter.searchText = value;
        this.filterForm.next({
            searchText: this.searchFilter.searchText,
        });
    }

    onAddClientTable() {
        this.addTableMode = true;
    }

    onCloseAddTable(withReload = false) {
        this.addTableMode = false;

        if (withReload) {
            this.fetchSchemaTables(this.selectedClientId);
        }
    }

    get isProduction(): boolean {
        return environment.production;
    }
}
