<div class="lca-settings-form-container">
    <nz-alert
        nzType="info"
        nzMessage="Please upload files."
        nzShowIcon
    ></nz-alert>
    <form
        name="file-form"
        [formGroup]="fileForm"
        nz-form
        action="none"
    >
        <div nz-row nzGutter="4">
            <nz-form-item [style.width]="'100%'">
                <nz-form-control nzSpan="24">
                    <div class="upload-file-item">
                        <i
                            class="upload-file-item__icon"
                            nz-icon
                            nzType="file"
                        ></i>
                        <div class="file-name-container">
                            <span class="upload-file-item__name">
                                PowerBI Report Template
                            </span>
                            <div class="file-name">
                                {{ fileForm.get('fileName')?.value }}
                            </div>
                        </div>
                        <ng-container>
                            <button
                                *ngIf="
                                    fileForm.get('fileName')?.value &&
                                    (!started || cancelled || completed)
                                "
                                (click)="
                                    onFileRemove()
                                "
                                type="button"
                                nz-button
                                nzSize="large"
                                nzType="danger"
                            >
                                Delete
                            </button>
                            <nz-spin
                                *ngIf="started && !cancelled && !completed"
                                nzSimple
                            ></nz-spin>
                            <nz-form-control
                                *ngIf="!fileForm.get('fileName')?.value"
                                nzListType="picture"
                            >
                                <nz-upload
                                    [nzBeforeUpload]="
                                        beforeFileUpload()
                                    "
                                    [nzFileList]="file ? [file] : []"
                                >
                                    <button
                                        class="attach-file-button"
                                        nz-button
                                        nzSize="large"
                                        nzType="primary"
                                        type="button"
                                    >
                                        Upload File (.pbix)
                                    </button>
                                </nz-upload>
                            </nz-form-control>
                        </ng-container>
                    </div>
                </nz-form-control>
            </nz-form-item>
        </div>
    </form>
    <nz-progress
        *ngIf="started && !completed && !cancelled"
        [nzPercent]="progress"
        nzSize="large"
        nzStatus="active"
    ></nz-progress>
    <nz-alert
        *ngIf="error"
        nzType="error"
        [nzMessage]="error"
        nzShowIcon
    ></nz-alert>
    <div nz-col class="drawer-actions-control" nzSpan="24">
        <div class="drawer-controls">
            <button
                [disabled]="(started && !completed) || !fileForm.valid"
                type="button"
                nzSize="large"
                nz-button
                (click)="onSave()"
                nzType="primary"
            >
                <span>Save</span>
            </button>
            <button type="button" nzSize="large" nz-button (click)="onClose()">
                <span>Close</span>
            </button>
        </div>
    </div>
</div>
