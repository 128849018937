import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import { LCAActivitySerial } from 'src/app/store/models/lca-activity-serial.model';
import { LCADataset } from 'src/app/store/models/lca-dataset.model';
import { LcaService } from 'src/app/core/services/lca/lca.service';

@Component({
    selector: 'app-lca-activityserial-selection-form',
    templateUrl: './lca-activityserial-selection-form.component.html',
    styleUrls: ['./lca-activityserial-selection-form.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LcaActivityserialSelectionFormComponent
    implements OnInit, OnDestroy
{
    @Output() changed: EventEmitter<number[]> = new EventEmitter();
    showSelected: boolean = false;

    searchText: string;
    activitySerialsLoading: boolean = false;
    activitySerials: LCAActivitySerial[] = [];
    notFilteredActivitySerails: LCAActivitySerial[] = [];
    //activity serials table specific variables
    activitySerialsIndeterminate: boolean = false;
    activitySerialsAllChecked: boolean = false;
    setOfActivitySerialChecked = new Set<number>();

    selectedDatasetId: string;
    selectedYear: number;

    datasetLoading: boolean = false;
    yearsLoading: boolean = false;

    years: number[] = [];
    datasets: LCADataset[] = [];
    debouncer: Subject<boolean> = new Subject();

    ngDestroyed$ = new Subject();

    constructor(
        private lcaService: LcaService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.datasetLoading = true;
        this.cdr.detectChanges();

        this.lcaService.getDatasets().subscribe({
            next: (payload) => {
                this.datasets = _orderBy(
                    _get(payload, 'payload', []),
                    dataset => dataset.datasetName.toLowerCase(),
                    ['asc']
                );
            },
            complete: () => {
                this.datasetLoading = false;
                this.cdr.detectChanges();
            },
        });

        this.debouncer
            .pipe(takeUntil(this.ngDestroyed$), debounceTime(350))
            .subscribe(() => {
                this.activitySerialsLoading = true;
                this.cdr.detectChanges();

                if (!this.searchText) {
                    this.activitySerials = this.notFilteredActivitySerails;
                } else {
                    this.activitySerials =
                        this.notFilteredActivitySerails.filter(
                            ({
                                activityName,
                                activitySerial,
                                year,
                                datasetId,
                            }) => {
                                const query = this.searchText.toLowerCase();

                                return (
                                    activityName
                                        .toLowerCase()
                                        .includes(query) ||
                                    `${activitySerial}`
                                        .toLowerCase()
                                        .includes(query) ||
                                    `${year}`.toLowerCase().includes(query) ||
                                    `${datasetId}`.toLowerCase().includes(query)
                                );
                            }
                        );
                }

                if (this.showSelected) {
                    this.activitySerials = this.activitySerials.filter(
                        ({ activitySerial }) =>
                            this.setOfActivitySerialChecked.has(activitySerial)
                    );
                }

                this.refreshCheckedStatus();
                this.activitySerialsLoading = false;
                this.cdr.detectChanges();
            });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    onSearch(value: string) {
        this.searchText = value;
        this.debouncer.next(true);
    }

    onDatasetChange(datasetId: string) {
        this.selectedYear = null;
        this.fetchYears(datasetId);
    }

    onYearChange() {
        this.fetchActivitySerials();
    }

    private fetchYears(datasetId: string) {
        if (!datasetId) return;

        this.yearsLoading = true;
        this.cdr.detectChanges();

        this.lcaService.getActivityYears(datasetId).subscribe({
            next: (payload) => {
                this.years = _sortBy(_get(payload, 'payload', []));
            },
            error: () => {
                this.years = [];
            },
            complete: () => {
                this.yearsLoading = false;
                this.cdr.detectChanges();
            },
        });
    }

    private fetchActivitySerials() {
        this.activitySerialsLoading = true;
        this.activitySerialsAllChecked = false;
        this.activitySerialsIndeterminate = false;
        this.notFilteredActivitySerails = [];
        this.searchText = null;
        this.cdr.detectChanges();

        this.lcaService
            .getActivitySerialsByDatasetId(
                this.selectedDatasetId,
                this.selectedYear
            )
            .subscribe({
                next: (payload) => {
                    const checkedActivitySerials = this.activitySerials.filter(
                        ({ activitySerial }) =>
                            this.setOfActivitySerialChecked.has(activitySerial)
                    );
                    this.activitySerials = _uniqBy(
                        _get(payload, 'payload', [])
                            .map((item) => ({
                                ...item,
                                datasetId: this.selectedDatasetId,
                            }))
                            .concat(checkedActivitySerials),
                        'activitySerial'
                    );
                    this.notFilteredActivitySerails = this.activitySerials;
                    this.refreshCheckedStatus();

                    this.cdr.detectChanges();
                },
                error: () => {
                    this.activitySerials = [];
                },
                complete: () => {
                    this.activitySerialsLoading = false;
                    this.cdr.detectChanges();
                },
            });
    }

    updateCheckedSet(activitySerial: number, checked: boolean): void {
        if (checked) {
            this.setOfActivitySerialChecked.add(activitySerial);
        } else {
            this.setOfActivitySerialChecked.delete(activitySerial);
        }
        this.changed.emit(Array.from(this.setOfActivitySerialChecked));
    }

    private refreshCheckedStatus(): void {
        if (this.activitySerials && this.activitySerials.length) {
            this.activitySerialsAllChecked = this.activitySerials.every(
                ({ activitySerial }) =>
                    this.setOfActivitySerialChecked.has(activitySerial)
            );
            this.activitySerialsIndeterminate =
                this.activitySerials.some(({ activitySerial }) =>
                    this.setOfActivitySerialChecked.has(activitySerial)
                ) && !this.activitySerialsAllChecked;
            this.cdr.detectChanges();
        }
    }

    onActivitySerialsAllChecked(checked: boolean) {
        this.activitySerials.forEach(({ activitySerial }) =>
            this.updateCheckedSet(activitySerial, checked)
        );
        this.refreshCheckedStatus();
    }

    onActivitySerialChecked(activitySerial: number, checked: boolean) {
        this.updateCheckedSet(activitySerial, checked);
        this.refreshCheckedStatus();
    }

    onShowSelectedChanged() {
        this.debouncer.next(true);
    }
}
