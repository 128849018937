import { AbstractControl, ValidationErrors } from '@angular/forms';
import _isNumber from 'lodash/isNumber';
import _isNaN from 'lodash/isNaN';

export function TimestampValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;

  // Check if the value is a valid timestamp
  if ((!_isNumber(value) && !value) || !_isNaN(Date.parse(value))) {
    return null; // Valid timestamp
  }

  return { timestamp: true }; // Invalid timestamp
}