<div class="lca-activityserial-selection-form-container">
    <div class="lca-activityserial-filter">
        <nz-form-item>
            <nz-form-label>Dataset</nz-form-label>
            <nz-form-control nzSpan="24">
                <nz-select
                    [nzAllowClear]="true"
                    [nzLoading]="datasetLoading"
                    [(ngModel)]="selectedDatasetId"
                    (ngModelChange)="onDatasetChange($event)"
                    nzPlaceHolder="Select dataset"
                    nzShowSearch
                >
                    <nz-option
                        *ngFor="let dataset of datasets"
                        [nzLabel]="dataset.datasetName"
                        [nzValue]="dataset.datasetId"
                    >
                        {{ dataset.datasetName }}
                    </nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label>Year</nz-form-label>
            <nz-form-control nzSpan="24">
                <nz-select
                    nzShowSearch
                    [disabled]="!selectedDatasetId"
                    [nzAllowClear]="true"
                    [nzLoading]="yearsLoading"
                    [(ngModel)]="selectedYear"
                    (ngModelChange)="onYearChange()"
                    nzPlaceHolder="Select year"
                >
                    <nz-option
                        *ngFor="let year of years"
                        [nzLabel]="year"
                        [nzValue]="year"
                    >
                        {{ year }}
                    </nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
    </div>
    <nz-form-item *ngIf="selectedDatasetId && selectedYear">
        <nz-form-label
            >Activityserials
            {{
                activitySerials?.length > 0
                    ? '(' + activitySerials?.length + ')'
                    : ''
            }}</nz-form-label
        >
        <div class="activity-serials-filter">
            <app-text-search-input
                (search)="onSearch($event)"
            ></app-text-search-input>
            <nz-switch
                [(ngModel)]="showSelected"
                (ngModelChange)="onShowSelectedChanged()"
                nzCheckedChildren="Show all"
                nzUnCheckedChildren="Show selected"
            ></nz-switch>
            <p class="selected-items-text">
                {{ setOfActivitySerialChecked.size }} selected
            </p>
        </div>
        <nz-table
            #table
            [nzLoading]="activitySerialsLoading"
            [nzSize]="'middle'"
            nzHideOnSinglePage
            nzShowPagination="false"
            nzFrontPagination="false"
            [nzData]="activitySerials"
        >
            <thead>
                <tr class="not-sticky">
                    <th
                        nzWidth="2.5rem"
                        [nzChecked]="activitySerialsAllChecked"
                        [nzIndeterminate]="activitySerialsIndeterminate"
                        (nzCheckedChange)="onActivitySerialsAllChecked($event)"
                    ></th>
                    <th class="th-number" nzWidth="6rem">Dataset ID</th>
                    <th class="th-number" nzWidth="4rem">Year</th>
                    <th class="th-number" nzWidth="9.5rem">Activity serial</th>
                    <th>Activity name</th>
                </tr>
            </thead>
        </nz-table>
        <cdk-virtual-scroll-viewport itemSize="100">
            <nz-table
                #table
                [nzLoading]="activitySerialsLoading"
                [nzSize]="'middle'"
                nzShowPagination="false"
                nzFrontPagination="false"
                nzHideOnSinglePage
                [nzData]="activitySerials"
            >
                <thead>
                    <tr [style.display]="'none'">
                        <th nzWidth="2.5rem"></th>
                        <th nzWidth="6rem"></th>
                        <th nzWidth="4rem"></th>
                        <th nzWidth="9.5rem"></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *cdkVirtualFor="let item of table.data; let i = index">
                        <td
                            [nzChecked]="
                                setOfActivitySerialChecked.has(
                                    item.activitySerial
                                )
                            "
                            (nzCheckedChange)="
                                onActivitySerialChecked(
                                    item.activitySerial,
                                    $event
                                )
                            "
                        ></td>
                        <td
                            class="td-number"
                            [innerHTML]="
                                item.datasetId
                                    | highlight: searchText
                                    | safe: 'html'
                            "
                        ></td>
                        <td
                            class="td-number"
                            [innerHTML]="
                                item.year | highlight: searchText | safe: 'html'
                            "
                        ></td>
                        <td
                            class="td-number"
                            [innerHTML]="
                                item.activitySerial
                                    | highlight: searchText
                                    | safe: 'html'
                            "
                        ></td>
                        <td>
                            <div class="activity-name-text" [innerHTML]="item.activityName
                                | highlight: searchText
                                | safe: 'html'">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </cdk-virtual-scroll-viewport>
    </nz-form-item>
</div>
