import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
    authLoginRequest,
    authLogoutReceive,
    switchAccountRequest,
} from 'src/app/store/actions/auth/auth.actions';
import { AppState } from 'src/app/store/state';
import { Observable } from 'rxjs';
import {
    selectAuthLoading,
    selectAuthError,
    selectAuthStep,
    selectUserLoginId,
    isWaitingRedirectSSO,
    selectAuthErrorSSO,
} from 'src/app/store/selectors/auth.selectors';

import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/core/services/user/user.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.less'],
})
export class LoginFormComponent implements OnInit {
    loading$: Observable<Boolean>;
    error$: Observable<Object>;
    errorSSO$: Observable<Object> = new Observable<Object>();
    loginForm: UntypedFormGroup;

    resetForm: UntypedFormGroup;
    resetError: string;
    isResetRequestSent: boolean;

    step$: Observable<number>;
    step: number = 0;

    redirect: string = '/dashboard';

    userLoginId$: Observable<string>;

    waitingRedirectSSO$: Observable<boolean>;

    isForgotModalVisible: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private modal: NzModalService,
        private store: Store<AppState>,
        private route: ActivatedRoute,
        private userService: UserService,
        private cd: ChangeDetectorRef
    ) {
        this.initLoginForm();
        this.initResetForm();
        this.loading$ = this.store.select(selectAuthLoading);
        this.error$ = this.store.select(selectAuthError);
        this.errorSSO$ = this.store.select(selectAuthErrorSSO);
        this.step$ = this.store.select(selectAuthStep);

        this.userLoginId$ = this.store.select(selectUserLoginId);

        this.waitingRedirectSSO$ = this.store.select(isWaitingRedirectSSO);

        this.step$.subscribe((step) => {
            this.step = step;
            this.loginForm.controls.password.setErrors(null);
        });

        this.route.queryParams
            .subscribe((params) => {
                this.redirect = decodeURI(params['redirect'] ?? '/dashboard');
            });
    }

    handleSSOModalOk() {
        this.modal.closeAll();
        // to clear state
        this.store.dispatch(authLogoutReceive());
    }

    ngOnInit() {}

    initLoginForm() {
        this.loginForm = this.fb.group(
            {
                email: [null, [Validators.required, Validators.email]],
                password: [null, [Validators.required]],
                remember: [true],
            },
            { updateOn: 'submit' }
        );
    }

    initResetForm() {
        this.resetForm = this.fb.group({
            email: [null, [Validators.required, Validators.email]],
        });
    }

    submitForm() {
        const formValue = {
            userLoginId: this.loginForm.get('email').value,
            userPassword: this.loginForm.get('password').value,
            remember: this.loginForm.get('remember').value,
        };

        if (this.step === 0) {
            this.loginForm.clearValidators();
        }

        this.store.dispatch(
            authLoginRequest({
                ...formValue,
                redirect: this.redirect || '/dashboard',
                step: this.step + 1,
            })
        );
    }

    onForgotPassword() {
        this.isForgotModalVisible = true;
    }

    onGoToEmail() {
        window.open('mailto:support@alignedincentives.com');
        this.isForgotModalVisible = false;
    }

    switchToAnotherAccount() {
        this.store.dispatch(switchAccountRequest());
    }

    resetPassword() {
        const email = this.resetForm.get('email').value;

        this.resetError = '';

        this.userService
            .sendResetPasswordRequest(email)
            .subscribe({
                next: (result) => {
                    if (result) {
                        this.isResetRequestSent = true;
                    } else {
                        this.isResetRequestSent = false;
                        this.resetError =
                            'No user found with this email address.';
                    }
                    this.cd.markForCheck();
                },
                error: (_) => {
                    this.resetError =
                        'There was an error while processing your request.';
                    this.cd.markForCheck();
                }
            });
    }

    handleCancel() {
        this.resetForm.reset();
        this.isResetRequestSent = false;
        this.resetError = '';
        this.isForgotModalVisible = false;
    }
}
