import { DestroyRef, inject, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/state';
import { selectAuthUser } from '../../store/selectors/auth.selectors';
import _first from 'lodash/first';
import { catchError, first, map, mergeMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import _intersection from 'lodash/intersection';
import { BrowserStorageService } from '../services/browser-storage/browser-storage.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AI_TOKEN_KEY, AuthService } from '../services/auth/auth.service';
import { authLoginReceive } from 'src/app/store/actions/auth/auth.actions';
import { User } from 'src/app/store/models/user.model';

export const withoutRedirectUrls = ['/login', '/register'];

@Injectable({
    providedIn: 'root'
  })
  class PermissionsService {
    destroyRef = inject(DestroyRef);

    constructor(private router: Router, private store: Store<AppState>, private authService: AuthService, private browserStorageService: BrowserStorageService) {}
  
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.select(selectAuthUser).pipe(
            first(),
            mergeMap((user) => {
                const checkPermissions = (userToCheck?: User) => {
                    const permissions = route.data?.permissions ?? [];

                    if (!userToCheck) {
                        return false;
                    }

                    const { userLoginId, userRoles } = userToCheck;

                    if (userLoginId && state.url.includes('/admin') && (userRoles.includes('AIUSER') || userRoles.includes('AIADMIN') || userRoles.includes('SUPERUSER')) && (permissions ? !!_intersection(userRoles, permissions)?.length : true)) {
                        return true;
                    }

                    if (userRoles?.length && permissions?.length) {
                        const canAccess = !!_intersection(userRoles, permissions)?.length;
                        if (!canAccess) {
                            this.router.navigate(['/access-denied']);
                        }
                        return canAccess;
                    }
    
                    if (userLoginId) {
                        return true;
                    }
                   
                    // not logged in so redirect to login page with the return url
                    if (!state.url.includes('/login')) {
                        this.router.navigate(['/login'], {
                            queryParams: {
                                redirect: withoutRedirectUrls.includes(state.url)
                                    ? '/dashboard'
                                    : state.url,
                            },
                            queryParamsHandling: 'merge',
                        });
                    }

                    return false;
                }

                if (!user) {
                    return this.browserStorageService.getValue(AI_TOKEN_KEY).pipe(
                        takeUntilDestroyed(this.destroyRef),
                        mergeMap(token => {
                            if (!token) {
                                this.authService.appLoading = false;
                                this.navigateToLogin();

                                return of(false);
                            }

                            return this.authService.getUserProfile(token).pipe(
                                takeUntilDestroyed(this.destroyRef),
                                mergeMap(({ payload }) => {
                                    this.store.dispatch(authLoginReceive({ user: payload, roles: payload.userRoles, token }));
                                    return of(checkPermissions(payload));
                                }),
                                catchError((err) => {
                                    this.browserStorageService.removeValue(AI_TOKEN_KEY);
                                    this.navigateToLogin();

                                    return of(false);
                                }),
                              );
                        }),
                        catchError(() => {
                            return of(false);
                        })
                      );
                }

                return of(checkPermissions(user));
            })
        );
    }

    private navigateToLogin() {
        const params = new URLSearchParams(window.location.search);
  
        return this.router.navigate(['/login'], { queryParams: { redirect: `${window.location.pathname}?${params.toString()}` }});
    }
  }
  
export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    return inject(PermissionsService).canActivate(next, state);
}