import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable, Subject } from 'rxjs';
import { Report, ReportConfig } from '../../../store/models/report.model';
import { UserReportLog } from 'src/app/store/models/user-report-log.model';

export interface ReportInput {
    reportId?: string;
    reportName: string;
    groupId: string;
    attrIds: string[];
    pbiReportId: string;
    rlsEnabled: boolean;
    rlsAttributeTypeIds: string[];
}
export interface ReportFilter {
    selectedReportId?: string;
    selectedGroups?: string[];
    searchText?: string;
    lastReportId?: string;
    lastReportName?: string;
    columnName?: string;
    sortDirection?: string;
    limit?: number;
}
@Injectable({
    providedIn: 'root',
})
export class ReportService {
    onReport$: Subject<{ report: Report; isNew: boolean }> = new Subject();
    constructor(private apiService: ApiService) {}

    getReport(reportId: string): Observable<{ payload: Report }> {
        return this.apiService.get(`report/${reportId}`);
    }

    getStrategyReportConfig(
        clientId: string
    ): Observable<{ payload: ReportConfig }> {
        return this.apiService.get(`strategy/${clientId}/pbi-report`);
    }

    getReports(filter?: ReportFilter): Observable<{ payload: Report[] }> {
        const params = {};
        if (filter) {
            if (filter.searchText) {
                params['searchText'] = filter.searchText;
            }
            if (filter.lastReportId) {
                params['lastReportId'] = filter.lastReportId;
            }
            if (filter.columnName) {
                params['sortByColumnName'] = filter.columnName;
            }
            if (filter.sortDirection) {
                params['sortDirection'] = filter.sortDirection;
            }
            if (filter.lastReportName) {
                params['lastReportName'] = filter.lastReportName;
            }
            if (filter.selectedGroups) {
                params['selectedGroups'] = filter.selectedGroups;
            }
            if (filter.limit) {
                params['limit'] = filter.limit;
            }
        }
        return this.apiService.get('report-portal/reports', { ...params });
    }

    getReportsByClient(clientId: string, filter?: ReportFilter) {
        const params = {};
        if (filter) {
            if (filter.searchText) {
                params['searchText'] = filter.searchText;
            }
            if (filter.lastReportId) {
                params['lastReportId'] = filter.lastReportId;
            }
            if (filter.columnName) {
                params['sortByColumnName'] = filter.columnName;
            }
            if (filter.sortDirection) {
                params['sortDirection'] = filter.sortDirection;
            }
            if (filter.lastReportName) {
                params['lastReportName'] = filter.lastReportName;
            }
            if (filter.selectedGroups) {
                params['selectedGroups'] = filter.selectedGroups;
            }
            if (filter.limit) {
                params['limit'] = filter.limit;
            }
        }
        return this.apiService.get('reports', { clientId, ...params });
    }

    createReportForClient(
        reportInput: ReportInput
    ): Observable<{ payload: Report }> {
        return this.apiService.post('report', reportInput);
    }

    updateReport(reportInput: ReportInput): Observable<{ payload: Report }> {
        return this.apiService.put('report', reportInput);
    }

    deleteReport(reportId: string) {
        return this.apiService.delete(`report/${reportId}`);
    }

    getReportConfig(reportId: string): Observable<{ payload: ReportConfig }> {
        return this.apiService.get(`report-portal/pbi-report/${reportId}`);
    }

    getUserReportLogs(limit: number): Observable<{ payload: UserReportLog[] }> {
        return this.apiService.get(`report-portal/pbi-report/logs?limit=`);
    }

    testConnection(pbiReportId: string): Observable<{ payload: any }> {
        return this.apiService.get(
            `report-portal/pbi-report-tests/${pbiReportId}`
        );
    }

    testStrategyReportConnection(
        clientId: string,
        pbiReportId: string
    ): Observable<{ payload: any }> {
        return this.apiService.get(
            `strategy/${clientId}/pbi-report-tests/${pbiReportId}`
        );
    }
}
