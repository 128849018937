<div class="lca-engine-client-database-spa-page-container">
    <div nz-flex nzAlign="center" nzJustify="space-between" class="lca-engine-client-database-spa-control">
        <h4>ActivitySerials:</h4>
        <div nz-flex nzAlign="center" nzGap="small">
            <button
                nz-button
                nzType="primary"
                [disabled]="!filter.clientId || !isClientDatabaseExist"
                [routerLink]="[filter.clientId + '/add']"
            >
                <i nz-icon nzType="select"></i>
                Add by ActivitySerial Selection
            </button>
            <button
                nz-button
                nzType="primary"
                [disabled]="!filter.clientId || !isClientDatabaseExist"
                [routerLink]="[filter.clientId + '/add']"
                [queryParams]="{ f: 1 }"
            >
                <i nz-icon nzType="file-add"></i>
                Add by ActivitySerial .CSV
            </button>
        </div>
    </div>
    <div class="lca-engine-client-database-spa-content">
        <ng-container
            *ngIf="
                filter.clientId && isClientDatabaseExist;
                else emptyStateTmpl
            "
        >
            <div class="lca-engine-activityserials-log-filter">
                <app-text-search-input
                    style="margin-right: 1rem"
                    (search)="onSearch($event)"
                ></app-text-search-input>
                <nz-select
                    nzShowSearch
                    [nzDisabled]="datasetLoading"
                    [nzAllowClear]="true"
                    [nzMaxTagCount]="1"
                    [nzMaxTagPlaceholder]="maxTagPlaceholder"
                    nzMode="multiple"
                    (ngModelChange)="onDatasetSelected($event)"
                    [nzLoading]="datasetLoading"
                    [(ngModel)]="selectedDatasetIds"
                    [nzCustomTemplate]="selectedDatasetTemplate"
                    [nzPlaceHolder]="
                        selectedDatasetIds.length ? '' : 'All datasets'
                    "
                >
                    <nz-option
                        *ngFor="let dataset of datasets"
                        [nzLabel]="dataset.datasetName"
                        [nzValue]="dataset.datasetId"
                    >
                        {{ dataset.datasetName }}
                    </nz-option>
                </nz-select>
                <ng-template #selectedDatasetTemplate let-selected>
                    <div class="dataset-template">
                        {{ selected.nzLabel }}
                    </div>
                </ng-template>
                <!-- years filter -->
                <nz-select
                    nzShowSearch
                    [nzDisabled]="impactYearsLoading"
                    [nzAllowClear]="true"
                    [nzMaxTagCount]="2"
                    [nzMaxTagPlaceholder]="maxTagPlaceholder"
                    nzMode="multiple"
                    (ngModelChange)="onYearsSelected($event)"
                    [nzLoading]="impactYearsLoading"
                    [(ngModel)]="selectedImpactYears"
                    [nzCustomTemplate]="selectedYearTemplate"
                    [nzPlaceHolder]="
                        selectedImpactYears.length ? '' : 'All years'
                    "
                >
                    <nz-option
                        *ngFor="let year of impactYears"
                        [nzLabel]="year"
                        nzCustomContent
                        [nzValue]="year"
                    >
                        {{ year }}
                    </nz-option>
                </nz-select>
                <ng-template #selectedYearTemplate let-selected>
                    <div class="year-template">
                        {{ selected.nzLabel }}
                    </div>
                </ng-template>
                <!-- activity types filter -->
                <nz-select
                    nzShowSearch
                    [nzAllowClear]="true"
                    *ngIf="impactTypesLoading || impactTypes?.length > 0"
                    [nzMaxTagCount]="1"
                    [nzMaxTagPlaceholder]="maxTagPlaceholder"
                    nzMode="multiple"
                    (ngModelChange)="onImpactTypeSelected($event)"
                    [nzLoading]="impactTypesLoading"
                    [(ngModel)]="selectedImpactTypeIds"
                    [nzCustomTemplate]="selectedImpactTypeTemplate"
                    [nzPlaceHolder]="
                        selectedImpactTypeIds.length ? '' : 'All impact types'
                    "
                >
                    <nz-option
                        *ngFor="let impactType of impactTypes"
                        [nzLabel]="impactType.impactTypeName"
                        nzCustomContent
                        [nzValue]="impactType.impactTypeId"
                    >
                        {{ impactType.impactTypeName }}
                    </nz-option>
                </nz-select>
                <ng-template #selectedImpactTypeTemplate let-selected>
                    <div class="impact-type-template">
                        {{ selected.nzLabel }}
                    </div>
                </ng-template>
                <ng-template #maxTagPlaceholder let-selectedList>
                    +{{ selectedList.length }}
                </ng-template>
                <a
                    (click)="resetFilters()"
                    class="reset-filter"
                    *ngIf="showResetFilters"
                    nz-button
                    nzSize="small"
                    nzType="link"
                    >Reset filters</a
                >
            </div>
            <div class="logs-table" *ngIf="filter.clientId">
                <cdk-virtual-scroll-viewport itemSize="75">
                    <nz-table
                        #table
                        [nzLoading]="logsLoading"
                        [nzSize]="'middle'"
                        nzShowPagination="false"
                        nzFrontPagination="false"
                        nzHideOnSinglePage
                        [nzData]="logs"
                    >
                        <thead>
                            <tr>
                                <th class="th-number" nzWidth="12rem">
                                    ActivitySerial
                                </th>
                                <th nzWidth="25rem">Activity Name</th>
                                <th class="th-number">SPA Stages</th>
                                <th class="th-number">
                                    SPA Threshold
                                </th>
                                <th class="th-number">Impactserials</th>
                                <th>Date entered into database</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *cdkVirtualFor="
                                    let item of table.data;
                                    let i = index
                                "
                            >
                                <td
                                    class="td-number"
                                    [innerHTML]="
                                        item.activitySerial
                                            | highlight: searchText
                                            | safe: 'html'
                                    "
                                ></td>
                                <td>
                                    <div clsas="activity-name-text" [innerHTML]="
                                    item.activityName
                                        | highlight: searchText
                                        | safe: 'html'
                                ">

                                    </div>
                                </td>
                                <td
                                    class="td-number"
                                    [innerHTML]="
                                        item.spaStages
                                            | highlight: searchText
                                            | safe: 'html'
                                    "
                                ></td>
                                <td
                                    class="td-number"
                                    [innerHTML]="
                                        item.spaThreshold
                                            | highlight: searchText
                                            | safe: 'html'
                                    "
                                ></td>
                                <td class="td-number">
                                    <span
                                        [innerHTML]="
                                            item.impactSerial
                                                | highlight: searchText
                                                | safe: 'html'
                                        "
                                    ></span>
                                </td>
                                <td>
                                    {{
                                        item.logUpdatedStamp
                                            | date: 'dd MMM yyyy, h:mm a'
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </cdk-virtual-scroll-viewport>
            </div>
        </ng-container>
        <ng-template #emptyStateTmpl>
            <ng-container *ngIf="!!filter.clientId; else selectClientTmpl">
                <p class="cta-text" *ngIf="!isClientDatabaseExist">
                    Client schema was not found.
                </p>
            </ng-container>
        </ng-template>
        <ng-template #selectClientTmpl>
            <p class="cta-text">Please select a client to view SPAs</p>
        </ng-template>
    </div>
</div>
