import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import { Process, ProcessLog } from 'src/app/store/models/process.model';
import { User } from 'src/app/store/models/user.model';
import { ProcessType } from 'src/app/store/models/process-type.model';
import { Status } from 'src/app/store/models/status.model';
import { ProcessesCategory } from '../../components/process-history-table/process-history-table.component';

export type ProcessHistoryFilters = {
    clientIds?: string[];
    userIds?: string[];
    processTypeIds?: string[];
    runIds?: string[];
    runStatusIds?: string[];
    startedStampFrom?: number;
    startedStampTo?: number;
    //db transaction history
    tableNameSearchText?: string;
    notesSearchText?: string;
    //pipelines run history
    dataPipelineName?: string;
    dataPipelineType?: string;
    clientId?: string;
    reportYear?: number;
    //paging
    lastRunId?: string;
    lastCreatedStamp?: number;
    limit?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProcessService {
  constructor(private api: ApiService) {}

  getProcessStatuses(category = ProcessesCategory.All): Observable<{ payload: Pick<Status, 'statusId' | 'statusName'>[] }>  {
    return this.api.get(`processes/${category === ProcessesCategory.Database ? 'db-operations/' : ''}runs-history/statuses`);
  }

  getProcessTypes(category = ProcessesCategory.All): Observable<{ payload: Pick<ProcessType, 'processTypeId' | 'processTypeName'>[] }> {
    return this.api.get(`processes/${category === ProcessesCategory.Database ? 'db-operations/' : ''}runs-history/process-types`);
  }

  getProcessesRunsUsers(category = ProcessesCategory.All): Observable<{ payload: Pick<User, 'userLoginId' | 'userId'>[] }> {
    return this.api.get(`processes/${category === ProcessesCategory.Database ? 'db-operations/' : ''}runs-history/users`);
  }

  getProcessesHistory(filter: ProcessHistoryFilters, category: ProcessesCategory = ProcessesCategory.All): Observable<{ payload: Process[] }> {
    const params = new URLSearchParams();

    Object.entries(filter).forEach(
        ([filterKey, filterValue]) => {
            if (Array.isArray(filterValue)) {
                filterValue.forEach(value => {
                    params.append(filterKey, value);
                })
            } else {
                params.set(filterKey, String(filterValue));
            }
        }
    )

    return this.api.get(`${category === ProcessesCategory.Database ? 'processes/db-operations/' : category === ProcessesCategory.Pipelines ? 'data-pipelines/' : 'processes/'}runs-history?${params.toString()}`);
  }

  getProcessRunLogs(runId: string, category: ProcessesCategory = ProcessesCategory.All): Observable<{ payload: ProcessLog[] }> {
    return this.api.get(`processes/${category === ProcessesCategory.Database ? 'db-operations/' : ''}runs/${runId}/logs`);
  }
}
