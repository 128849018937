import { Component, ViewChild } from '@angular/core';
import _get from 'lodash/get';
import _last from 'lodash/last';
import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';
import { ProcessHistoryTableComponent } from 'src/app/core/components/process-history-table/process-history-table.component';

@Component({
  selector: 'app-process-history-page',
  templateUrl: './process-history-page.component.html',
  styleUrl: './process-history-page.component.less'
})
export class ProcessHistoryPageComponent {
  @ViewChild(ProcessHistoryTableComponent) processHistoryTableComp;

  onRefresh() {
    this.processHistoryTableComp.refresh();
  }
}
