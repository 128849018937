import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import * as CustomerActionTypes from '../../actions/customer/constants';
import { catchError, mergeMap } from 'rxjs/operators';
import { CustomerService } from '../../../core/services/customer/customer.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Customer } from '../../models/customer.model';

@Injectable()
export class CustomerEffects {
    constructor(
        private actions$: Actions,
        private customerService: CustomerService,
        private nzMessageService: NzMessageService
    ) {}
}
