import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError, first } from 'rxjs/operators';
import * as AuthActionTypes from '../../actions/auth/constants';
import {
    AuthService,
    AI_TOKEN_KEY,
} from 'src/app/core/services/auth/auth.service';
import { of } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { User } from '../../models/user.model';
import { BrowserStorageService } from 'src/app/core/services/browser-storage/browser-storage.service';

@Injectable()
export class AuthEffects {
    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router,
        private nzMessageService: NzMessageService,
        private nzModal: NzModalService,
        private browserStorageService: BrowserStorageService
    ) {}

    authLoginRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.AUTH_LOGIN_REQUEST),
            mergeMap(
                ({ userLoginId, userPassword, step, remember, redirect }) =>
                    this.authService
                        .authenticate(userLoginId, step, userPassword)
                        .pipe(
                            mergeMap(
                                ({
                                    token,
                                    nextStep,
                                    ssoUrl,
                                    showBanner,
                                    appResources,
                                    clientLogo,
                                    clientId,
                                    clientName,
                                    userFirstName,
                                    userLastName,
                                    userLastLogin,
                                }) => {
                                    let user: User = {} as User;

                                    if (token) {
                                        const decodedUser = jwt_decode(token);

                                        user.clientLogoImg = clientLogo;
                                        user.clientId = clientId;
                                        user.clientName = clientName;
                                        user.userFirstName = userFirstName;
                                        user.userLastName = userLastName;
                                        user.userLastLogin = userLastLogin;
                                        user.userRoles = appResources;
                                        user.userLoginId = decodedUser['sub'];

                                        if (showBanner) {
                                            this.nzModal.warning({
                                                nzClassName: 'info-modal',
                                                nzTitle: null,
                                                nzContent: `
                    <div class="info-mcd-logo"></div>
                    <ol>
                      <li>Users are accessing a McDonald’s corporate information system;</li>
                      <li>Information system usage may be monitored, recorded, and subject to audit;</li>
                      <li>Unauthorized use of the information system is prohibited and subject to criminal and civil penalties;</li>
                      <li>Use of the information system indicates consent to monitoring and recording;</li>
                    </ol>
                  `,
                                                nzOnOk: () => {},
                                                nzOkText: 'OK',
                                                nzClosable: false,
                                                nzMaskClosable: false,
                                            });
                                        }
                                    }

                                    return of({
                                        type: AuthActionTypes.AUTH_LOGIN_RECEIVE,
                                        userLoginId,
                                        token,
                                        step: nextStep,
                                        ssoUrl,
                                        user,
                                        clientLogo,
                                        clientId,
                                        clientName,
                                        userFirstName,
                                        userLastName,
                                        userLastLogin,
                                        remember,
                                        redirect,
                                        roles: user ? user.userRoles : [],
                                    });
                                }
                            ),
                            catchError((error) => {
                                return of({
                                    type: AuthActionTypes.AUTH_LOGIN_FAILED,
                                    error,
                                });
                            })
                        )
            )
        )
    );

    authLoginReceive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.AUTH_LOGIN_RECEIVE),
            mergeMap(({ user, token, ssoUrl, redirect = undefined }) =>
                this.authService.setToken(token).pipe(
                    first(),
                    mergeMap(() => {
                        if (user && user['userLoginId']) {
                            this.nzMessageService.success(
                                `You are logged in as <b>${user['userLoginId']}</b>`
                            );
                        }

                        if (ssoUrl) {
                            window.location.href = ssoUrl;
                        }

                        if (!!redirect && user) {
                            this.router.navigateByUrl(decodeURI(redirect));
                        }

                        return of({ type: 'EMPTY' });
                    })
                )
            )
        )
    );

    authenticateSSORequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.AUTHENTICATE_SSO_REQUEST),
            mergeMap(({ code, state }) =>
                this.authService.authenticateSSO(code).pipe(
                    mergeMap(
                        ({
                            token,
                            showBanner,
                            appResources,
                            clientLogo,
                            clientId,
                            clientName,
                            userFirstName,
                            userLastName,
                            userLastLogin,
                        }) => {
                            let user: User = {} as User;
                            if (token) {
                                const decodedUser = jwt_decode(token);

                                user.clientLogoImg = clientLogo;
                                user.clientId = clientId;
                                user.clientName = clientName;
                                user.userFirstName = userFirstName;
                                user.userLastName = userLastName;
                                user.userLastLogin = userLastLogin;
                                user.userRoles = appResources;
                                user.userLoginId = decodedUser['sub'];

                                if (showBanner) {
                                    this.nzModal.warning({
                                        nzClassName: 'info-modal',
                                        nzTitle: null,
                                        nzContent: `
                    <div class="info-mcd-logo"></div>
                    <ol>
                      <li>Users are accessing a McDonald’s corporate information system;</li>
                      <li>Information system usage may be monitored, recorded, and subject to audit;</li>
                      <li>Unauthorized use of the information system is prohibited and subject to criminal and civil penalties;</li>
                      <li>Use of the information system indicates consent to monitoring and recording;</li>
                    </ol>
                  `,
                                        nzOnOk: () => {},
                                        nzOkText: 'OK',
                                        nzClosable: false,
                                        nzMaskClosable: false,
                                    });
                                }
                            }

                            return of({
                                type: AuthActionTypes.AUTHENTICATE_SSO_RECEIVE,
                                token,
                                userLoginId: user.userLoginId,
                                user,
                                roles: user ? user.userRoles : [],
                            });
                        }
                    ),
                    catchError((error) =>
                        of({
                            type: AuthActionTypes.AUTHENTICATE_SSO_FAILED,
                            error,
                        })
                    )
                )
            )
        )
    );

    authenticateSSOReceive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.AUTHENTICATE_SSO_RECEIVE),
            mergeMap(({ user, token, redirect = '/dashboard' }) =>
                this.authService.setToken(token).pipe(
                    first(),
                    mergeMap(() => {
                        if (user && user['userLoginId']) {
                            this.nzMessageService.success(
                                `You are logged in as <b>${user['userLoginId']}</b>`
                            );
                        }

                        if (!!redirect && user) {
                            this.router.navigateByUrl(decodeURI(redirect));
                        } else if (user) {
                            this.router.navigate(['/dashboard']);
                        }
                        return of({ type: 'EMPTY' });
                    })
                )
            )
        )
    );

    authLogoutRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.AUTH_LOGOUT_REQUEST),
            mergeMap(() =>
                this.authService.logout().pipe(
                    mergeMap(() => {
                        return of({
                            type: AuthActionTypes.AUTH_LOGOUT_RECEIVE,
                        });
                    }),
                    catchError((_) =>
                        of({ type: AuthActionTypes.AUTH_LOGOUT_RECEIVE })
                    )
                )
            )
        )
    );

    authLogoutReceive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.AUTH_LOGOUT_RECEIVE),
            mergeMap(() => {
                this.router.navigate(['/login']);
                this.browserStorageService.removeValue(AI_TOKEN_KEY);
                this.nzMessageService.info('You are logged out');

                return of({ type: 'EMPTY' });
            })
        )
    );

    loginTypesRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.LOGIN_TYPES_REQUEST),
            mergeMap(() =>
                this.authService.getLoginTypes().pipe(
                    mergeMap(({ payload }) =>
                        of({
                            type: AuthActionTypes.LOGIN_TYPES_RECEIVE,
                            items: payload,
                        })
                    ),
                    catchError((error) =>
                        of({ type: AuthActionTypes.AUTH_LOGIN_FAILED, error })
                    )
                )
            )
        )
    );
}
