<nz-alert
    *ngIf="ssoConfigError"
    nzType="error"
    [nzMessage]="ssoConfigError"
    nzShowIcon
></nz-alert>

<form
    *hasPermission="['CLIENT_MGMT']; class: 'disabled'"
    nz-form
    [formGroup]="ssoConfigForm"
    (ngSubmit)="submitSSOConfigForm()"
    autocomplete="off"
>
    <div nz-row nzGutter="4">
        <div nz-col nzSpan="24">
            <ng-container *ngIf="!ssoConfigLoading; else ssoLoadingTmpl">
                <nz-form-item>
                    <nz-form-label nzRequired>App Client ID</nz-form-label>
                    <nz-form-control
                        [nzSpan]="24"
                        nzValidateStatus
                        nzHasFeedback
                        [nzErrorTip]="appClientIdErrorTmpl"
                    >
                        <nz-input-group nzSize="large">
                            <input
                                data-cy="client-sso-config-form__app-client-id"
                                formControlName="appClientId"
                                nz-input
                                placeholder="Input Application Client ID"
                            />
                        </nz-input-group>
                        <ng-template #appClientIdErrorTmpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please input app Client ID
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <!-- <nz-form-item>
                    <nz-form-label nzRequired>App Client Secret</nz-form-label>
                    <nz-form-control
                        [nzSpan]="24"
                        nzValidateStatus
                        nzHasFeedback
                        [nzErrorTip]="appClientSecretErrorTmpl"
                    >
                        <nz-input-group
                            nzSize="large"
                            [nzSuffix]="suffixTemplate"
                        >
                            <input
                                [type]="secretVisible ? 'text' : 'password'"
                                data-cy="client-sso-config-form__app-client-secret"
                                formControlName="appClientSecret"
                                nz-input
                                name="secret"
                                autocomplete="off"
                                placeholder="Input App Client Secret"
                            />
                        </nz-input-group>
                        <ng-template #appClientSecretErrorTmpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please input app Client Secret
                            </ng-container>
                        </ng-template>
                        <ng-template #suffixTemplate>
                            <span
                                nz-icon
                                [nzType]="
                                    secretVisible ? 'eye-invisible' : 'eye'
                                "
                                (click)="secretVisible = !secretVisible"
                            ></span>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item> -->
                <nz-form-item>
                    <nz-form-label nzRequired
                        >Identity Provider Server base URL</nz-form-label
                    >
                    <nz-form-control
                        [nzSpan]="24"
                        nzValidateStatus
                        nzHasFeedback
                        [nzErrorTip]="idpServerBaseUrlErrorTmpl"
                    >
                        <nz-input-group nzSize="large">
                            <input
                                data-cy="client-sso-config-form__idp-server-base-url"
                                formControlName="idpServerBaseUrl"
                                nz-input
                                placeholder="Input Server URL"
                            />
                        </nz-input-group>
                        <ng-template #idpServerBaseUrlErrorTmpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please input base Server URL
                            </ng-container>
                            <ng-container *ngIf="control.hasError('pattern')">
                                Please input valid Server URL
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label>Email Property Name</nz-form-label>
                    <nz-form-control
                        [nzSpan]="24"
                        nzValidateStatus
                        nzHasFeedback
                        [nzErrorTip]="emailPropertyNameErrorTmpl"
                    >
                        <nz-input-group nzSize="large">
                            <input
                                data-cy="client-sso-config-form__email-property-name"
                                formControlName="emailPropertyName"
                                nz-input
                                placeholder="Input Email Property Name"
                            />
                        </nz-input-group>
                        <ng-template #emailPropertyNameErrorTmpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please input email property name
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </ng-container>
            <ng-template #ssoLoadingTmpl>
                <div class="loading-container">
                    <nz-spin
                        nzSimple
                        [nzSize]="'large'"
                        nzTip="Loading..."
                    ></nz-spin>
                </div>
            </ng-template>
        </div>
    </div>
</form>

<div class="drawer-actions-control">
    <div class="drawer-controls">
        <button
            data-cy="client-sso-form__submit"
            type="button"
            [nzLoading]="ssoConfigSaving"
            nzSize="large"
            nz-button
            [disabled]="
                !ssoConfigForm?.valid ||
                !ssoConfigForm?.dirty ||
                ssoConfigLoading
            "
            (click)="submitSSOConfigForm()"
            nzType="primary"
        >
            <span>Save</span>
        </button>
        <button
            data-cy="client-sso-form__disconnect"
            type="button"
            [disabled]="ssoConfigSaving || ssoConfigLoading || ssoDisconnecting"
            [nzLoading]="ssoDisconnecting"
            nzSize="large"
            nz-button
            nzType="default"
            nzDanger
            (click)="onDisconnect()"
        >
            <span nz-icon nzType="disconnect" nzTheme="outline"></span>
            <span>Disconnect</span>
        </button>
        <button
            data-cy="client-sso-form__close"
            type="button"
            [disabled]="ssoConfigSaving"
            nzSize="large"
            nz-button
            (click)="onCancel()"
        >
            <span>Cancel</span>
        </button>
    </div>
</div>
