<div class="report-group-container">
    <div
        data-cy="report-group__name"
        class="report-group__name"
        [innerHTML]="
            reportGroup.groupName | highlight: highlightQuery | safe: 'html'
        "
    ></div>
    <div
        data-cy="report-group__color"
        class="report-group__color"
        [style.backgroundColor]="reportGroup.groupColor"
    ></div>
    <div class="report-group__actions">
        <i
            class="dots"
            *hasPermission="['REPORT_MGMT', 'REPORT_MGMT_VIEW']"
            nz-icon
            nzType="dots"
            nzTheme="fill"
        ></i>
        <div
            class="actions"
            *hasPermission="['REPORT_MGMT', 'REPORT_MGMT_VIEW']"
        >
            <app-users-popover
                [entityId]="reportGroup.groupId"
                entityType="ReportGroups"
            >
                <i
                    data-cy="report-group-users__control"
                    nz-icon
                    nz-tooltip
                    nzTooltipPlacement="top"
                    nzTooltipTitle="View users"
                    nzTooltipOverlayClassName="primary-tooltip"
                    class="action-icon"
                    nzType="users"
                    nzTheme="outline"
                ></i>
            </app-users-popover>
            <i
                data-cy="report-group-delete__control"
                *hasPermission="['REPORT_MGMT']"
                nz-icon
                nz-tooltip
                nzTooltipPlacement="top"
                nzTooltipTitle="Delete"
                nzTooltipOverlayClassName="danger-tooltip"
                class="delete"
                nzType="delete"
                nzTheme="outline"
                (click)="onDelete()"
            ></i>
            <i
                data-cy="report-group-edit__control"
                *hasPermission="['REPORT_MGMT', 'REPORT_MGMT_VIEW']"
                nz-icon
                nz-tooltip
                nzTooltipPlacement="top"
                nzTooltipTitle="Edit"
                nzTooltipOverlayClassName="primary-tooltip"
                nzType="edit"
                nzTheme="outline"
                (click)="onEdit()"
            ></i>
        </div>
    </div>
</div>
