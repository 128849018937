<div class="lca-modify-spa-form-container">
    <nz-alert
        nzType="info"
        nzMessage="Please select ActivitySerials for SPA analysis (up to 5)"
    ></nz-alert>
    <form name="lca-modify-spa-form" [formGroup]="spaFormGroup">
        <nz-form-item>
            <nz-form-label>Activityserials</nz-form-label>
            <nz-form-control>
                <nz-transfer
                    [nzDataSource]="list"
                    nzShowSearch
                    [nzListStyle]="{ 'width.rem': 19.125, 'height.rem': 20 }"
                    [nzRender]="render"
                    [nzTitles]="['Serials', 'Selected Serials']"
                    (nzSelectChange)="select()"
                    (nzChange)="change()"
                >
                    <ng-template #render let-item>
                        {{ item.title }}-{{ item.description }}
                    </ng-template>
                </nz-transfer>
            </nz-form-control>
        </nz-form-item>
        <h4 class="lca-modify-spa-threshold-header">
            SPA Threshold Parameters
        </h4>
        <nz-alert
            nzType="info"
            nzMessage="Please fill out all boxes"
        ></nz-alert>
        <nz-form-item>
            <nz-form-label>
                spa life cycle stage to render (default value is
                8)</nz-form-label
            >
            <nz-form-control nzValidateStatus nzHasFeedback>
                <nz-input-group nzSize="large">
                    <input
                        formControlName="spaLifeCycleStageToRender"
                        nzMin="1"
                        nzMax="19"
                        step="1"
                        type="number"
                        nz-input
                        placeholder="Enter a whole number between 1 and 19"
                    />
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label>
                spa sensitivity threshold - climate change (default value is
                0.5)</nz-form-label
            >
            <nz-form-control nzValidateStatus nzHasFeedback>
                <nz-input-group
                    nzSize="large"
                    [nzPrefix]="(spaFormGroup.get('spaSensitivityThresholdClimateChange')?.value ?? 0.5) < 0.01 ? prefixSpaSensitivity : undefined"
                    [nzStatus]="(spaFormGroup.get('spaSensitivityThresholdClimateChange')?.value ?? 0.5) < 0.01 ? 'warning' : undefined"
                >
                    <input
                        formControlName="spaSensitivityThresholdClimateChange"
                        nzMin="0"
                        nzMax="1"
                        step="0.01"
                        type="number"
                        nz-input
                        placeholder="Enter a decimal between 0 and 1"
                    />
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
    </form>
    <div nz-col class="drawer-actions-control" nzSpan="24">
        <div class="drawer-controls">
            <button
                type="button"
                nzSize="large"
                nz-button
                (click)="onSave()"
                nzType="primary"
            >
                <span>Update SPA</span>
            </button>
            <button type="button" nzSize="large" nz-button (click)="onClose()">
                <span>Close</span>
            </button>
        </div>
    </div>
</div>

<ng-template #prefixSpaSensitivity>
    <i nz-icon nzType="warning" nzTheme="outline" nz-tooltip nzTooltipPlacement="top" nzTooltipTitle="Analysis could take a very long time."></i>
</ng-template>