import { createSelector } from '@ngrx/store';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _reverse from 'lodash/reverse';
import _orderBy from 'lodash/orderBy';
import { AppState } from '../state';
import { UploadPortalState } from '../reducers/upload-portal/upload-portal.reducer';
import { UploadPortal } from '../models/upload-portal';
import { create } from 'domain';

export const selectUploadPortals = (state: AppState) => state.uploadPortals;

export const selectUploadPortalsLoading = createSelector(
    selectUploadPortals,
    (state: UploadPortalState) => state.loading
);

export const selectUploadPortalsItems = createSelector(
    selectUploadPortals,
    (state: UploadPortalState) => state.items
);

export const selectSortedUploadPortalItems = createSelector(
    selectUploadPortalsItems,
    //TODO: create more pretty function / interfaces for sorting
    (items: UploadPortal[], sort: any) => {
        if (sort && sort.key === 'index') {
            return sort.value === 'ascend' ? items : _reverse(items.slice());
        }

        return !sort || _isEmpty(sort)
            ? items
            : _orderBy(
                  items,
                  [sort.key],
                  [sort.value === 'ascend' ? 'asc' : 'desc']
              );
    }
);

export const selectUploadPortalsError = createSelector(
    selectUploadPortals,
    (state: UploadPortalState) => state.error
);

export const selectUploadPortalById = createSelector(
    selectUploadPortals,
    (state: UploadPortalState, { uploadPortalId }: any) =>
        _find(state.items, { uploadPortalId })
);

export const selectUploadPortalVersionsById = createSelector(
    selectUploadPortals,
    (state: UploadPortalState, { uploadPortalId }: any) =>
        _get(state.versions, `${uploadPortalId}.items`)
);

export const selectUploadPortalVersionsLoadingById = createSelector(
    selectUploadPortals,
    (state: UploadPortalState, { uploadPortalId }: any) =>
        _get(state.versions, `${uploadPortalId}.loading`)
);

export const selectUploadPortalTypes = createSelector(
    selectUploadPortals,
    (state: UploadPortalState) => state.types.items
);

export const selectUploadPortalTypesLoading = createSelector(
    selectUploadPortals,
    (state: UploadPortalState) => state.types.loading
);

export const selectUserUploadPortals = createSelector(
    selectUploadPortals,
    (state: UploadPortalState) => state.uploadPortal.items
);

export const selectUserUploadPortalsLoading = createSelector(
    selectUploadPortals,
    (state: UploadPortalState) => state.uploadPortal.loading
);

export const selectUserUploadPortalTypes = createSelector(
    selectUploadPortals,
    (state: UploadPortalState) => state.userTypes.items
);

export const selectUserUploadPortalTypesLoading = createSelector(
    selectUploadPortals,
    (state: UploadPortalState) => state.userTypes.loading
);

export const selectUserUploadPortalsUploading = createSelector(
    selectUploadPortals,
    (state: UploadPortalState) => state.uploading
);

export const selectDownloadUploadPortalLoading = createSelector(
    selectUploadPortals,
    (state: UploadPortalState) => state.templateDownloadLoading
);

export const selectUserUploadPortalById = createSelector(
    selectUploadPortals,
    (state: UploadPortalState, { uploadPortalId }) =>
        _find(state.uploadPortal.items, { uploadPortalId })
);

export const selectUserDataUploadHistoryLoading = createSelector(
    selectUploadPortals,
    (state: UploadPortalState) => state.history.loading
);

export const selectUserDataUploadHistory = createSelector(
    selectUploadPortals,
    (state: UploadPortalState) => {
        return state.history.items;
    }
);

export const selectUploadPortalStatusesByStatusTypeId = createSelector(
    selectUploadPortals,
    (state: UploadPortalState, { statusTypeId }: { statusTypeId: string }) =>
        _get(state, `collectionStatuses.${statusTypeId}.items`, [])
);

export const selectUploadPortalStatusesLoadingByStatusTypeId = createSelector(
    selectUploadPortals,
    (state: UploadPortalState, { statusTypeId }: { statusTypeId: string }) =>
        _get(state, `collectionStatuses.${statusTypeId}.loading`, false)
);

export const selectUploadPortalStatusVersionsById = createSelector(
    selectUploadPortals,
    (state: UploadPortalState, { uploadPortalId }: any) =>
        _get(state.uploadPortalStatusVersions, `${uploadPortalId}.items`)
);

export const selectUploadPortalStatusVersionsLoadingById = createSelector(
    selectUploadPortals,
    (state: UploadPortalState, { uploadPortalId }: any) =>
        _get(state.uploadPortalStatusVersions, `${uploadPortalId}.loading`)
);
