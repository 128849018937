import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import _get from 'lodash/get';
import * as ReportActionsTypes from '../../actions/report/constants';
import { catchError, mergeMap, flatMap, concat } from 'rxjs/operators';
import { ReportService } from 'src/app/core/services/report/report.service';
import { Report } from '../../models/report.model';
import { ReportGroupService } from '../../../core/services/report-group/report-group.service';

@Injectable()
export class ReportEffects {
    constructor(
        private actions$: Actions,
        private reportService: ReportService,
        private reportGroupService: ReportGroupService
    ) {}

    createReportForClientRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportActionsTypes.CREATE_REPORT_FOR_CLIENT_REQUEST),
            mergeMap(({ clientId, input }) =>
                this.reportService.createReportForClient(input).pipe(
                    mergeMap(({ payload: report }) => {
                        this.reportService.onReport$.next({
                            report,
                            isNew: true,
                        });

                        return of({
                            type: ReportActionsTypes.CREATE_REPORT_FOR_CLIENT_RECEIVE,
                            report,
                            clientId,
                        });
                    }),
                    catchError((error) =>
                        of({
                            type: ReportActionsTypes.CREATE_REPORT_FOR_CLIENT_FAILED,
                            error:
                                _get(error, ['error', 'errors', 0]) ??
                                _get(error, [
                                    'error',
                                    'fieldErrors',
                                    0,
                                    'fieldErrorMessage',
                                ]),
                            clientId,
                        })
                    )
                )
            )
        )
    );

    reportGroupsListRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportActionsTypes.REPORT_GROUPS_LIST_REQUEST),
            mergeMap(({ clientId }) =>
                this.reportGroupService.getReportGroups(clientId).pipe(
                    mergeMap(({ payload: reportGroups }) =>
                        of({
                            type: ReportActionsTypes.REPORT_GROUPS_LIST_RECEIVE,
                            reportGroups,
                        })
                    ),
                    catchError((error) =>
                        of({
                            type: ReportActionsTypes.REPORT_GROUPS_LIST_FAILED,
                            error: error.message,
                        })
                    )
                )
            )
        )
    );

    createReportGroupRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportActionsTypes.CREATE_REPORT_GROUP_REQUEST),
            mergeMap(({ input }) =>
                this.reportGroupService.createReportGroup(input).pipe(
                    mergeMap(({ payload: reportGroup }) => {
                        this.reportGroupService.onReportGroup$.next({
                            reportGroup,
                            new: true,
                        });

                        return of({
                            type: ReportActionsTypes.CREATE_REPORT_GROUP_RECEIVE,
                            reportGroup,
                        });
                    }),
                    catchError((error) =>
                        of({
                            type: ReportActionsTypes.CREATE_REPORT_FOR_CLIENT_FAILED,
                            error: _get(error, ['error', 'errors', 0]),
                        })
                    )
                )
            )
        )
    );

    updateReportForClientRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportActionsTypes.UPDATE_REPORT_FOR_CLIENT_REQUEST),
            mergeMap(({ input, clientId }) =>
                this.reportService.updateReport(input).pipe(
                    mergeMap(({ payload: report }) => {
                        this.reportService.onReport$.next({
                            report,
                            isNew: false,
                        });

                        return of({
                            type: ReportActionsTypes.UPDATE_REPORT_FOR_CLIENT_RECEIVE,
                            report,
                            clientId,
                        });
                    }),
                    catchError((error) => {
                        return of({
                            type: ReportActionsTypes.UPDATE_REPORT_FOR_CLIENT_FAILED,
                            error:
                                _get(error, ['error', 'errors', 0]) ??
                                _get(error, [
                                    'error',
                                    'fieldErrors',
                                    0,
                                    'fieldErrorMessage',
                                ]),
                            clientId,
                        });
                    })
                )
            )
        )
    );

    updateReportGroupRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportActionsTypes.UPDATE_REPORT_GROUP_REQUEST),
            mergeMap(({ input }) =>
                this.reportGroupService.updateReportGroup(input).pipe(
                    mergeMap(({ payload: reportGroup }) => {
                        this.reportGroupService.onReportGroup$.next({
                            reportGroup,
                            new: false,
                        });

                        return of({
                            type: ReportActionsTypes.UPDATE_REPORT_GROUP_RECEIVE,
                            reportGroup,
                        });
                    }),
                    catchError((error) =>
                        of({
                            type: ReportActionsTypes.UPDATE_REPORT_GROUP_FAILED,
                            error: error.toString(),
                        })
                    )
                )
            )
        )
    );

    updateReportRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportActionsTypes.UPDATE_REPORT_REQUEST),
            mergeMap(({ reportInput }) =>
                this.reportService.updateReport(reportInput).pipe(
                    mergeMap(({ payload: report }) => {
                        this.reportService.onReport$.next({
                            report,
                            isNew: false,
                        });

                        return of({
                            type: ReportActionsTypes.UPDATE_REPORT_RECEIVE,
                            report,
                        });
                    }),
                    catchError((error) =>
                        of({
                            type: ReportActionsTypes.UPDATE_REPORT_FAILED,
                            error: error.toString(),
                        })
                    )
                )
            )
        )
    );
    deleteReportGroupRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportActionsTypes.DELETE_REPORT_GROUP_REQUEST),
            mergeMap(({ groupId, clientId }) =>
                this.reportGroupService.deleteReportGroup(groupId).pipe(
                    mergeMap(() =>
                        of({
                            type: ReportActionsTypes.DELETE_REPORT_GROUP_RECEIVE,
                            groupId,
                            clientId,
                        })
                    ),
                    catchError((error) =>
                        of({
                            type: ReportActionsTypes.DELETE_REPORT_GROUP_FAILED,
                            error: error.toString(),
                        })
                    )
                )
            )
        )
    );

    // deleteReportGroupReceive$ = createEffect(() => this.actions$.pipe(
    //   ofType(ReportActionsTypes.DELETE_REPORT_GROUP_RECEIVE),
    //   mergeMap(
    //     ({ clientId }) => of({ type: ReportActionsTypes.REPORT_GROUPS_LIST_REQUEST, clientId })
    //   )
    // ));

    reportGroupRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportActionsTypes.REPORT_GROUP_REQUEST),
            mergeMap(({ groupId }) =>
                this.reportGroupService.getReportGroupById(groupId).pipe(
                    mergeMap(({ payload: reportGroup }) =>
                        of({
                            type: ReportActionsTypes.REPORT_GROUP_RECEIVE,
                            reportGroup,
                        })
                    ),
                    catchError((error) =>
                        of({
                            type: ReportActionsTypes.REPORT_GROUP_FAILED,
                            error: error.toString(),
                        })
                    )
                )
            )
        )
    );

    deleteReportRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportActionsTypes.DELETE_REPORT_REQUEST),
            mergeMap(({ reportId, clientId }) =>
                this.reportService.deleteReport(reportId).pipe(
                    mergeMap(() =>
                        of({
                            type: ReportActionsTypes.DELETE_REPORT_RECEIVE,
                            reportId,
                            clientId,
                        })
                    ),
                    catchError((error) =>
                        of({
                            type: ReportActionsTypes.DELETE_REPORT_FAILED,
                            error: error.toString(),
                            clientId,
                        })
                    )
                )
            )
        )
    );
}
