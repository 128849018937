import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    AfterViewInit,
} from '@angular/core';
import { CustomerUser } from '../../../store/models/customer-user.model';
import { forkJoin, Subject, Subscription } from 'rxjs';
import _find from 'lodash/find';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    UntypedFormControl,
} from '@angular/forms';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _orderBy from 'lodash/orderBy';
import _last from 'lodash/last';
import _intersection from 'lodash/intersection';
import _set from 'lodash/set';
import _first from 'lodash/first';
import _compact from 'lodash/compact';
import {
    UserInput,
    UserService,
    UsersFilter,
} from '../../../core/services/user/user.service';
import { Attribute, AttributeType } from '../../../store/models/attribute.model';
import { ClientRole } from '../../../store/models/client-role.model';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ReportGroup } from '../../../store/models/report-group.model';
import { Customer } from '../../../store/models/customer.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '../../../core/services/core/core.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AttributeService } from '../../../core/services/attribute/attribute.service';
import { AI_CLIENT_ID, CustomerService } from '../../../core/services/customer/customer.service';
import { UploadPortalService } from 'src/app/core/services/upload-portal/upload-portal.service';
import { UploadPortalType } from 'src/app/store/models/upload-portal-type.model';
import { ReportGroupService } from 'src/app/core/services/report-group/report-group.service';
import { TextSearchInputComponent } from 'src/app/core/components/text-search-input/text-search-input.component';
import { LoginType } from '../../../store/models/login-type';
import { GlobalService } from 'src/app/core/services/global/global.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';

const PAGE_SIZE = 100;

@Component({
    selector: 'app-user-page',
    templateUrl: './user-page.component.html',
    styleUrls: ['./user-page.component.less'],
})
export class UserPageComponent implements OnInit, OnDestroy {
    users: CustomerUser[] = [];
    usersLoading: boolean = true;
    usersLoaded: boolean = false;

    // filter
    filterForm: Subject<UsersFilter | undefined> = new Subject();
    selectedReportGroupIds: string[] = [];
    selectedAttributeIds: string[] = [];
    selectedUploadPortalTypeIds: string[] = [];
    selectedRoleIds: string[] = [];

    isAdmin: string = '';

    // customers
    selectedClientId: string;
    selectedClientSubscription: Subscription;

    isLmtdUsersPage: boolean;
    isMaxNumReached: boolean = false;

    // edit customer user data
    customerUserFormMode: boolean = false;
    customerUserForm: UntypedFormGroup;
    user: CustomerUser | undefined;
    userLoading: boolean;
    customerUserError: string | undefined;

    //updatePasswordFormMode: boolean = false;
    selectedUserId: string;

    newUserCreatedSubscription: Subscription;

    uploadPortalTypesLoading: boolean = false;
    uploadPortalTypes: UploadPortalType[] = [];

    //groups
    reportGroups: ReportGroup[];
    reportGroupsLoading: boolean = false;

    //roles by customer
    clientRoles: ClientRole[];
    clientRolesLoading: boolean = false;

    isPasswordRequired: boolean = true;

    public ngDestroyed$ = new Subject();

    saving: boolean = false;

    clientsLoading: boolean = false;
    clients: Customer[] = [];

    query: string;

    searchFilter = {
        searchText: '',
        lastUserId: '',
        lastLastName: '',
        lastFirstName: '',
        columnName: 'user_login_id',
        sortDirection: 'ascend',
    };

    columns = [
        {
            title: 'E-mail',
            key: 'user_login_id',
            width: '20%',
            dataCy: 'users-table__email-sort',
            sortDirections: ['descend', 'ascend', null],
        },
        {
            title: 'First name',
            key: 'user_first_name',
            width: '15%',
            dataCy: 'users-table__first-name-sort',
            sortDirections: ['ascend', 'descend', null],
        },
        {
            title: 'Last name',
            key: 'user_last_name',
            width: '20%',
            dataCy: 'users-table__last-name-sort',
            sortDirections: ['ascend', 'descend', null],
        },
        {
            title: 'Login Type',
            key: 'login_type_id',
            width: '15%',
            dataCy: 'users-table__login-type-sort',
        },
        {
            title: 'Date created',
            key: 'created_stamp',
            width: '15%',
            dataCy: 'users-table__created-stamp-sort',
            sortDirections: ['ascend', 'descend', null],
        },
    ];

    @ViewChild(TextSearchInputComponent) searchBox: TextSearchInputComponent;

    loginTypes: LoginType[] = [];
    loginTypesLoading: boolean = false;

    attributesTreeNodes: NzTreeNodeOptions[] = [];

    attributeTypes: AttributeType[] = [];
    attributeTypesLoading: boolean = false;

    constructor(
        private core: CoreService,
        private modal: NzModalService,
        private message: NzMessageService,
        private fb: UntypedFormBuilder,
        private userService: UserService,
        private attributeService: AttributeService,
        public customerService: CustomerService,
        private uploadPortal: UploadPortalService,
        private reportGroupService: ReportGroupService,
        private globalService: GlobalService
    ) {
        this.core.getProfile().subscribe((payload) => {
            const userRoles = _get(payload, [
                'payload',
                'userRoles',
            ]);

            if (userRoles.includes('AIADMIN') || userRoles.includes('AIUSER') || userRoles.includes('SUPERUSER')) {
                return;
            } else {
                this.onClientChanged(_get(payload, ['payload', 'clientId']));
            }
        });

        //init form
        this.initCustomerUserForm();
       
    }

    ngOnInit() {
        this.filterForm
            .pipe(debounceTime(100))
            .subscribe((filter) => {
                this.fetchUsers(filter);
            });

        this.globalService.clientId$.pipe(takeUntil(this.ngDestroyed$)).subscribe(
            clientId => {
                if (clientId) {
                    this.onClientChanged(clientId);
                }
            }
        )
    }

    onEditCustomerUser(userId: string) {
        this.customerUserFormMode = true;
        this.userLoading = true;

        this.fetchAttributeTypes(this.selectedClientId);
        this.fetchUploadPortalTypes(this.selectedClientId);
        this.fetchReportGroups(this.selectedClientId);

        this.userService
            .getUser(userId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    this.userLoading = false;
                    this.user = _get(payload, 'payload');
                    this.isMaxNumReached = false;
                    this.enableNewCustomerForm();
                    this.customerUserError = '';
                    this.initCustomerUserForm(this.user);
                },
                complete: () => {
                    this.userLoading = false;
                },
            });
    }

    onCreateCustomerUser() {
        this.customerUserForm.reset();
        this.customerUserForm.patchValue({ clientId: this.selectedClientId });
        this.customerUserFormMode = true;
        this.fetchAttributeTypes(this.selectedClientId);
        this.fetchReportGroups(this.selectedClientId);
        this.fetchUploadPortalTypes(this.selectedClientId);

        this.customerService
            .getMaxNumberOfUsersByClientId(this.selectedClientId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    let maxNumberOfUsers = _get(payload, 'payload', -1);
                    let totalUsers = this.users.length;

                    // maxNumberOfUsers = -1 indicates limitless number of users
                    if (
                        maxNumberOfUsers > -1 &&
                        maxNumberOfUsers <= totalUsers
                    ) {
                        this.isMaxNumReached = true;
                        this.disableNewCustomerForm();
                        this.customerUserError =
                            'You cannot create more users.';
                    } else {
                        this.isMaxNumReached = false;
                        this.enableNewCustomerForm();
                        this.customerUserError = '';
                    }
                },
                error: () => {
                    this.disableNewCustomerForm();
                    this.customerUserError =
                        'An error occurred while connecting to the server.';
                },
            });
    }

    private disableNewCustomerForm() {
        this.saving = false;
        this.customerUserForm.get('userLoginId')?.disable();
        this.customerUserForm.get('userFirstName')?.disable();
        this.customerUserForm.get('userLastName')?.disable();
    }

    private enableNewCustomerForm() {
        this.saving = false;
        this.customerUserForm.get('userLoginId')?.enable();
        this.customerUserForm.get('userFirstName')?.enable();
        this.customerUserForm.get('userLastName')?.enable();
    }

    private fetchUsers(filter?: UsersFilter) {
        if (this.usersLoaded) return;

        this.usersLoading = true;

        this.userService
            .getUsersByClientId(this.selectedClientId, filter ? { ...filter, limit: PAGE_SIZE } : { limit: PAGE_SIZE })
            .subscribe({
                next: (payload) => {
                    const users = _get(payload, 'payload', []);
                    if (users.length < PAGE_SIZE) {
                        this.usersLoaded = true;
                    }
                    if (filter && filter.lastUserId) {
                        if (users.length) {
                            this.users = [...this.users, ...users];
                        }
                    } else {
                        this.users = users;
                    }

                    this.usersLoading = false;
                },
                complete: () => {
                    this.usersLoading = false;
                    this.query = this.searchFilter.searchText;
                },
            });
    }

    onClientChanged(clientId: string) {
        this.selectedClientId = clientId;

        if (clientId === this.customerService.aiClientId) {
            this.clientsLoading = true;
            this.customerService.getCustomers({ excludeClientIds: [AI_CLIENT_ID] }).subscribe({
                next: (payload) => {
                    this.clients = _get(payload, 'payload', []);
                },
                complete: () => {
                    this.clientsLoading = false;
                },
            });
        } else {
            this.clients = [];
        }

        this.usersLoaded = false;

        this.customerService
            .getCustomerLoginTypes(clientId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((payload) => {
                this.loginTypes = _get(payload, 'payload', []);

                if (this.loginTypes?.length) {
                    this.customerUserForm
                        .get('userLoginTypeId')
                        ?.setValidators([Validators.required]);
                }
            });

        this.fetchAttributeTypes(this.selectedClientId);
        this.fetchUploadPortalTypes(this.selectedClientId);
        this.fetchReportGroups(this.selectedClientId);
        this.fetchRoles(clientId);

        this.selectedReportGroupIds = [];
        this.selectedAttributeIds = [];
        this.selectedUploadPortalTypeIds = [];
        this.selectedRoleIds = [];
        this.isAdmin = '';
        this.searchBox?.onClear();
        this.filterForm.next(undefined);

        this.customerUserForm.patchValue({ clientId });
    }

    private fetchRoles(clientId: string) {
        this.clientRolesLoading = true;
        this.customerService
            .getCustomerRolesForUsers(clientId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    this.clientRolesLoading = false;
                    this.clientRoles = _orderBy(_get(payload, 'payload', []), (role: ClientRole) => role.roleName.toLowerCase(), ['asc']);
                },
                complete: () => {
                    this.clientRolesLoading = false
                },
            });
    }

    private fetchUploadPortalTypes(clientId: string) {
        this.uploadPortalTypesLoading = true;
        this.uploadPortal
            .getUploadPortalTypesForUsers(clientId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (data) => {
                    this.uploadPortalTypes = _orderBy(_get(data, 'payload', []), item => item.uploadPortalTypeName.toLowerCase(), ['asc']);
                    this.uploadPortalTypesLoading = false;
                },
                complete: () => {
                    this.uploadPortalTypesLoading = false;
                },
            });
    }

    findUploadPortalType(uploadPortalTypeId: string) {
        return this.uploadPortalTypes.find(item => item.uploadPortalTypeId === uploadPortalTypeId) ?? {};
    }

    findReportGroup(groupId: string) {
        return this.reportGroups.find(item => item.groupId === groupId) ?? {};
    }

    private fetchReportGroups(clientId: string) {
        this.reportGroupsLoading = true;
        this.reportGroupService
            .getReportGroupsForUsers(clientId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (data) => {
                    this.reportGroups = _orderBy(_get(data, 'payload', []), item => item.groupName.toLowerCase(), ['asc']);
                    this.reportGroupsLoading = false;
                },
                complete: () => {
                    this.reportGroupsLoading = false;
                },
            });
    }

    private fetchAttributeTypes(clientId: string) {
        this.attributeTypesLoading = true;

        this.attributeService
            .getAttributeTypesForUsers(clientId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    this.attributeTypes = _get(payload, 'payload') || [];
                    this.attributeTypesLoading = false;
                    this.initAttributeNodesTree(this.attributeTypes);
                },
                error: () => {
                    this.attributeTypesLoading = false;
                }
            });
    }

    initAttributeNodesTree(attributeTypes: AttributeType[]) {
        this.attributesTreeNodes = [];

        if (!attributeTypes?.length) {
            return;
        }

        this.attributesTreeNodes = attributeTypes.flatMap<NzTreeNodeOptions>((attrType) => {
            return {
                title: attrType.attrTypeName,
                value: `attr-type~${attrType.attrTypeId}`,
                key: `attr-type~${attrType.attrTypeId}`,
                selectable: false,
                disabled: !attrType.attributes.length,
                children: _orderBy(attrType.attributes ?? [], (item: Attribute) => item.attrName.toLowerCase(), ['asc']).map((attribute: Attribute) => ({
                    title: attribute.attrName,
                    key: attribute.attrId,
                    value: attribute.attrId,
                    isLeaf: true
                }))
            };
        });
    }


    onRemoveCustomerUser(userId: string, userLoginId: string) {
        this.modal.confirm({
            nzTitle: `Do you want to remove user ${userLoginId}?`,
            nzContent: `
        If you delete it you will not be able to recover user.<br>
        Do you want to delete the user?
      `,
            nzOnOk: () =>
                this.userService.deleteUser(userId).subscribe(() => {
                    this.usersLoaded = false;
                    this.message.success(
                        `User  <b>${userLoginId}</b> has been deleted!`,
                        { nzDuration: 3000 }
                    );
                    this.filterForm.next({
                        searchText: this.searchFilter.searchText,
                        selectedAttributeIds: this.selectedAttributeIds,
                        selectedReportGroupIds: this.selectedReportGroupIds,
                        selectedUploadPortalTypeIds:
                            this.selectedUploadPortalTypeIds,
                        selectedRoleIds: this.selectedRoleIds,
                        isAdmin: this.isAdmin,
                        sortByColumnName: this.searchFilter.columnName,
                        columnDirection: this.searchFilter.sortDirection,
                        lastLastName: this.searchFilter.lastLastName,
                        lastFirstName: this.searchFilter.lastFirstName,
                    });
                }),
            nzOkText: 'Yes, remove',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzCancelText: 'Close',
            nzClosable: false,
            nzOnCancel: () => {},
        });
    }

    /*
  onUpdatePassword(userId: string) {
    this.updatePasswordFormMode = true;
    this.selectedUserId = userId;
  }
*/
    /*
  closeUpdatePassword() {
    this.updatePasswordFormMode = false;
    this.selectedUserId = null;
  }
*/

    closeEditCustomerUser(withReload: boolean = false) {
        this.customerUserFormMode = false;
        this.customerUserForm.reset();
        this.customerUserError = undefined;
        this.usersLoaded = false;
        this.user = undefined;
        if (withReload) {
            this.searchBox?.onClear();
            this.selectedReportGroupIds = [];
            this.selectedAttributeIds = [];
            this.selectedUploadPortalTypeIds = [];
            this.selectedRoleIds = [];
            this.isAdmin = '';
        } else if (this.user) {
            this.filterForm.next({
                searchText: this.searchFilter.searchText,
                sortByColumnName: this.searchFilter.columnName,
                columnDirection: this.searchFilter.sortDirection,
                selectedUploadPortalTypeIds: this.selectedUploadPortalTypeIds,
                selectedRoleIds: this.selectedRoleIds,
                isAdmin: this.isAdmin,
                selectedAttributeIds: this.selectedAttributeIds,
                selectedReportGroupIds: this.selectedReportGroupIds,
            });
        }
    }

    initCustomerUserForm(customerUser?: CustomerUser) {
        // TODO: disable loginId
        this.customerUserForm = this.fb.group(
            {
                userLoginId: [
                    _get(customerUser, 'userLoginId'),
                    [Validators.required, Validators.email],
                ],
                userFirstName: [
                    _get(customerUser, 'userFirstName'),
                    [Validators.required],
                ],
                userLastName: [
                    _get(customerUser, 'userLastName'),
                    [Validators.required],
                ],
                attrIds: [customerUser?.userAttributes?.map(attr => attr.attrId) ?? []],
                clientId: [
                    _get(customerUser, 'clientId', this.selectedClientId),
                    [Validators.required],
                ],
                reportGroupIds: [
                    _get(customerUser, ['reportGroups'], []).map(
                        ({ groupId }) => groupId
                    ),
                ],
                roleIds: [
                    _get(customerUser, ['userRoles'], []).map(
                        ({ roleId }) => roleId
                    ),
                ],
                userUploadPortalTypeIds: [
                    _get(customerUser, ['uploadPortalTypes'], []).map(
                        ({ uploadPortalTypeId }) => uploadPortalTypeId
                    ),
                ],
                userLoginTypeId: [
                    _get(customerUser, ['userLoginType', 'loginTypeId']),
                ],
                userAllowedClientIds: [
                    _get(customerUser, ['userAllowedClients'], []).map(
                        (client) => client.clientId
                    ),
                ],
                isAdmin: [!!_get(customerUser, 'isAdmin', false)],
            },
            {
                validators: customerUser ? [] : [],
            }
        );
    }

    get clientsDropdownOptions() {
        return _orderBy((this.clients ?? []), (client: Customer) => client.clientName, ['asc']);
    }

    submitCustomerUserForm() {
        for (const key in this.customerUserForm.controls) {
            this.customerUserForm.controls[key].markAsDirty();
            this.customerUserForm.controls[key].updateValueAndValidity();
        }

        if (
            this.newUserCreatedSubscription &&
            !this.newUserCreatedSubscription.closed
        ) {
            this.newUserCreatedSubscription.unsubscribe();
        }

        this.customerUserError = undefined;

        const formValue = this.customerUserForm.value;

        const userInput: UserInput = {
            userLoginId: _get(formValue, 'userLoginId'),
            userFirstName: _get(formValue, 'userFirstName'),
            userLastName: _get(formValue, 'userLastName'),
            userLoginTypeId: _get(formValue, 'userLoginTypeId'),
            attrIds: [],
            clientId: _get(formValue, 'clientId'),
            reportGroupIds: _get(formValue, 'reportGroupIds', []),
            roleIds: _get(formValue, 'roleIds', []),
            userUploadPortalTypeIds: _get(formValue, 'userUploadPortalTypeIds', []),
            isAdmin: !!_get(formValue, 'isAdmin', false),
            userAllowedClientIds: _get(formValue, 'userAllowedClientIds', []),
        };

        const attrIds = _get(formValue, 'attrIds', []);

        if (Array.isArray(attrIds)) {
            userInput['attrIds'] = _compact(attrIds.flatMap(id => {
                if (id.includes('~')) {
                    const attrTypeId = id.split('~')?.[1];

                    if (!attrTypeId) {
                        return [];
                    }

                    return this.attributeTypes.find(type => type.attrTypeId === attrTypeId)?.attributes?.map((attr: Attribute) => attr.attrId) ?? [];
                }

                return id;
            }))
        }

        this.saving = true;
        if (this.user) {
            userInput.userId = this.user.userId;
            this.userService
                .putUser(userInput)
                .pipe(takeUntil(this.ngDestroyed$))
                .subscribe({
                    next: (payload) => {
                        this.message.success(
                            `User <b>${payload.payload.userLoginId}</b> has been updated!`,
                            { nzDuration: 3000 }
                        );
                        this.saving = false;
                        this.closeEditCustomerUser();
                        this.filterForm.next({
                            searchText: this.searchFilter.searchText,
                            selectedAttributeIds: this.selectedAttributeIds,
                            selectedReportGroupIds: this.selectedReportGroupIds,
                            selectedUploadPortalTypeIds:
                                this.selectedUploadPortalTypeIds,
                            selectedRoleIds: this.selectedRoleIds,
                            isAdmin: this.isAdmin,
                            sortByColumnName: this.searchFilter.columnName,
                            columnDirection: this.searchFilter.sortDirection,
                            lastLastName: this.searchFilter.lastLastName,
                            lastFirstName: this.searchFilter.lastFirstName,
                        });
                    },
                    error: (error) => {
                        this.saving = false;
                        const errorMessage =
                            _get(error, ['error', 'errors', 0], '') ||
                            _get(error, 'message');
                        if (errorMessage) {
                            this.customerUserError = errorMessage;
                        }
                    },
                });
        } else {
            this.userService
                .postUser(userInput)
                .pipe(takeUntil(this.ngDestroyed$))
                .subscribe({
                    next: (payload) => {
                        this.message.success(
                            `User <b>${payload.payload.userLoginId}</b> has been created!`,
                            { nzDuration: 3000 }
                        );
                        this.saving = false;
                        this.closeEditCustomerUser(true);
                    },
                    error: (error) => {
                        this.saving = false;
                        const errorMessage =
                            _get(error, ['error', 'errors', 0], '') ||
                            _get(error, ['error', 'message']);
                        if (errorMessage) {
                            this.customerUserError = errorMessage;
                        }
                    },
                });
        }

        this.newUserCreatedSubscription = this.userService.newUserCreated$
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe(() => {});
    }

    get isAIClient(): boolean {
        return this.selectedClientId === this.customerService.aiClientId;
    }

    onSearch(value: string) {
        this.searchFilter.searchText = value;
        this.usersLoaded = false;
        this.users = [];
        
        this.filterForm.next({
            selectedReportGroupIds: this.selectedReportGroupIds,
            searchText: this.searchFilter.searchText,
            selectedAttributeIds: this.selectedAttributeIds,
            selectedUploadPortalTypeIds: this.selectedUploadPortalTypeIds,
            selectedRoleIds: this.selectedRoleIds,
            isAdmin: this.isAdmin,
        });
    }

    onFilterReportGroupsChanged(selectedReportGroupIds: string[]) {
        this.usersLoaded = false;
        this.selectedReportGroupIds = selectedReportGroupIds;

        this.filterForm.next({
            selectedReportGroupIds: this.selectedReportGroupIds,
            searchText: this.searchFilter.searchText,
            selectedAttributeIds: this.selectedAttributeIds,
            selectedUploadPortalTypeIds: this.selectedUploadPortalTypeIds,
            selectedRoleIds: this.selectedRoleIds,
            isAdmin: this.isAdmin,
            sortByColumnName: this.searchFilter.columnName,
            columnDirection: this.searchFilter.sortDirection,
        });
    }

    onFilterUploadPortalTypesChanged(selectedUploadPortalTypeIds: string[]) {
        this.usersLoaded = false;
        this.selectedUploadPortalTypeIds = selectedUploadPortalTypeIds;

        this.filterForm.next({
            selectedUploadPortalTypeIds,
            selectedReportGroupIds: this.selectedReportGroupIds,
            searchText: this.searchFilter.searchText,
            selectedAttributeIds: this.selectedAttributeIds,
            sortByColumnName: this.searchFilter.columnName,
            columnDirection: this.searchFilter.sortDirection,
            lastLastName: this.searchFilter.lastLastName,
            lastFirstName: this.searchFilter.lastFirstName,
        });
    }

    onFilterRoleChanged(selectedRoleIds: string[]) {
        this.usersLoaded = false;
        this.selectedRoleIds = selectedRoleIds;

        this.filterForm.next({
            selectedRoleIds,
            isAdmin: this.isAdmin,
            selectedUploadPortalTypeIds: this.selectedUploadPortalTypeIds,
            selectedReportGroupIds: this.selectedReportGroupIds,
            searchText: this.searchFilter.searchText,
            selectedAttributeIds: this.selectedAttributeIds,
            sortByColumnName: this.searchFilter.columnName,
            columnDirection: this.searchFilter.sortDirection,
            lastLastName: this.searchFilter.lastLastName,
            lastFirstName: this.searchFilter.lastFirstName,
        });
    }

    onFilterAdminChanged(isAdmin: string) {
        this.usersLoaded = false;
        this.isAdmin = isAdmin;

        this.filterForm.next({
            isAdmin: this.isAdmin,
            selectedRoleIds: this.selectedRoleIds,
            selectedUploadPortalTypeIds: this.selectedUploadPortalTypeIds,
            selectedReportGroupIds: this.selectedReportGroupIds,
            searchText: this.searchFilter.searchText,
            selectedAttributeIds: this.selectedAttributeIds,
            sortByColumnName: this.searchFilter.columnName,
            columnDirection: this.searchFilter.sortDirection,
            lastLastName: this.searchFilter.lastLastName,
            lastFirstName: this.searchFilter.lastFirstName,
        });
    }

    onFilterAttributesChanged(selectedAttributeIds: string[]) {
        this.usersLoaded = false;
        this.selectedAttributeIds = selectedAttributeIds;

        this.filterForm.next({
            selectedReportGroupIds: this.selectedReportGroupIds,
            selectedUploadPortalTypeIds: this.selectedUploadPortalTypeIds,
            selectedRoleIds: this.selectedRoleIds,
            isAdmin: this.isAdmin,
            searchText: this.searchFilter.searchText,
            selectedAttributeIds,
            sortByColumnName: this.searchFilter.columnName,
            columnDirection: this.searchFilter.sortDirection,

            lastLastName: this.searchFilter.lastLastName,
            lastFirstName: this.searchFilter.lastFirstName,
        });
    }

    onTableScrolled() {
        if (this.usersLoaded === false) {
            const user: CustomerUser = _last(this.users);
            if (user && user.userId) {
                const {
                    userId,
                    userLoginId,
                    createdStamp,
                    userLastName,
                    userFirstName,
                } = user;
                //this.lastUserId = userId.valueOf();
                this.filterForm.next({
                    searchText: this.searchFilter.searchText,
                    selectedAttributeIds: this.selectedAttributeIds,
                    selectedReportGroupIds: this.selectedReportGroupIds,
                    selectedUploadPortalTypeIds: this.selectedUploadPortalTypeIds,
                    selectedRoleIds: this.selectedRoleIds,
                    isAdmin: this.isAdmin,
                    lastUserId: userId,
                    lastUserLoginId: userLoginId,
                    lastCreatedStamp: createdStamp,
                    sortByColumnName: this.searchFilter.columnName,
                    columnDirection: this.searchFilter.sortDirection,
                    lastLastName: userLastName,
                    lastFirstName: userFirstName,
                });
            }
        }
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    orderChange(col: any) {
        this.usersLoaded = false;
        this.searchFilter.columnName = col.key;
        this.searchFilter.sortDirection = col.value;
        this.users = [];

        this.filterForm.next({
            searchText: this.searchFilter.searchText,
            sortByColumnName: this.searchFilter.columnName,
            columnDirection: this.searchFilter.sortDirection,
            selectedUploadPortalTypeIds: this.selectedUploadPortalTypeIds,
            selectedRoleIds: this.selectedRoleIds,
            isAdmin: this.isAdmin,
            selectedAttributeIds: this.selectedAttributeIds,
            selectedReportGroupIds: this.selectedReportGroupIds,
        });
    }
}
