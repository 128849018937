import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

registerLocaleData(en);

import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTransferModule } from 'ng-zorro-antd/transfer';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzGraphModule } from 'ng-zorro-antd/graph';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';

@NgModule({
    declarations: [],
    imports: [
        NzButtonModule,
        NzTabsModule,
        NzLayoutModule,
        NzDropDownModule,
        NzBreadCrumbModule,
        NzMenuModule,
        NzIconModule,
        NzFormModule,
        NzInputModule,
        NzInputNumberModule,
        NzCheckboxModule,
        NzAlertModule,
        NzMessageModule,
        NzGridModule,
        NzUploadModule,
        NzResultModule,
        NzPageHeaderModule,
        NzTableModule,
        NzStatisticModule,
        NzCardModule,
        NzTagModule,
        NzAvatarModule,
        NzSpinModule,
        NzDrawerModule,
        NzSelectModule,
        NzDividerModule,
        NzTypographyModule,
        NzRadioModule,
        NzStepsModule,
        NzTimelineModule,
        NzPopconfirmModule,
        NzCollapseModule,
        NzModalModule,
        NzPopoverModule,
        NzSwitchModule,
        NzDatePickerModule,
        NzProgressModule,
        NzEmptyModule,
        NzTransferModule,
        NzNotificationModule,
        NzSpaceModule,
        NzListModule,
        NzSegmentedModule,
        NzDescriptionsModule,
        NzAutocompleteModule,
        NzTimelineModule,
        NzFlexModule,
        NzTreeModule,
        NzGraphModule,
        NzTreeSelectModule
    ],
    exports: [
        NzButtonModule,
        NzTabsModule,
        NzLayoutModule,
        NzDropDownModule,
        NzBreadCrumbModule,
        NzMenuModule,
        NzIconModule,
        NzFormModule,
        NzInputModule,
        NzInputNumberModule,
        NzCheckboxModule,
        NzAlertModule,
        NzMessageModule,
        NzGridModule,
        NzUploadModule,
        NzResultModule,
        NzPageHeaderModule,
        NzTableModule,
        NzStatisticModule,
        NzCardModule,
        NzTagModule,
        NzAvatarModule,
        NzSpinModule,
        NzDrawerModule,
        NzSelectModule,
        NzDividerModule,
        NzToolTipModule,
        NzSkeletonModule,
        NzTypographyModule,
        NzRadioModule,
        NzStepsModule,
        NzTimelineModule,
        NzPopconfirmModule,
        NzCollapseModule,
        NzModalModule,
        NzPopoverModule,
        NzSwitchModule,
        NzDatePickerModule,
        NzProgressModule,
        NzEmptyModule,
        NzTransferModule,
        NzNotificationModule,
        NzSpaceModule,
        NzListModule,
        NzSegmentedModule,
        NzDescriptionsModule,
        NzAutocompleteModule,
        NzTimelineModule,
        NzFlexModule,
        NzTreeModule,
        NzTreeSelectModule,
        NzGraphModule
    ],
    providers: [{ provide: NZ_I18N, useValue: en_US }],
})
export class AntModule {}
