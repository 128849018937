import { Component, OnInit, OnDestroy, ViewChild, Input, input, SimpleChanges } from '@angular/core';
import _get from 'lodash/get';
import _first from 'lodash/first';
import _orderBy from 'lodash/orderBy';
import _find from 'lodash/find';
import _last from 'lodash/last';
import _compact from 'lodash/compact';
import { firstValueFrom, forkJoin, Observable, Observer, Subject } from 'rxjs';
import { UploadPortal } from '../../../../store/models/upload-portal';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UploadPortalType } from '../../../../store/models/upload-portal-type.model';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import {
    UploadPortalService,
    UploadPortalFilterForm,
} from '../../../../core/services/upload-portal/upload-portal.service';
import { UploadPortalStatus } from '../../../../store/models/upload-portal-status.model';
import { DataUploadHistory } from '../../../../store/models/data-upload-history';
import { FileWorkerService } from '../../data-page/file.worker.service';
import { DataDownloadHistoryItemAction } from 'src/app/store/models/data-download-history-item-action';
import {
    selectUploadPortalStatusVersionsById,
    selectUploadPortalStatusVersionsLoadingById,
    selectDownloadUploadPortalLoading,
} from 'src/app/store/selectors/upload-portal.selectors';
import { downloadUploadPortalFileRequest, uploadPortalStatusHistoryRequest } from 'src/app/store/actions/upload-portal/upload-portal.actions';
import { AppState } from 'src/app/store/state';
import { Store } from '@ngrx/store';
import { UploadPortalStatusVersion } from 'src/app/store/models/upload-portal-status-version';
import { selectAuthUser } from 'src/app/store/selectors/auth.selectors';
import { UserPermissions } from 'src/app/store/models/user-permissions.model';
import { GlobalService } from 'src/app/core/services/global/global.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { Attribute } from 'src/app/store/models/attribute.model';

const PAGE_SIZE = 100;

@Component({
    selector: 'app-portals-table',
    templateUrl: './portals-table.component.html',
    styleUrls: ['./portals-table.component.less'],
    providers: [FileWorkerService],
})
export class PortalsTableComponent implements OnInit, OnDestroy {
    uploadPortals: UploadPortal[] = [];
    uploadPortalsLoading: boolean = true;

    query: string;
    uploadedPageTemplate: string;

    availableStatusesLoading: boolean = false;
    availableStatuses: UploadPortalStatus[] = [];
    activeChangeStatusPageId: string;

    uploadPortalFormMode: boolean;

    historyLoading: boolean;
    historyMode: boolean;
    history: DataUploadHistory[] = [];
    uploadPortalId: string;
    historyLoaded = false;
    
    statuses: UploadPortalStatus[] = [];
    statusesLoading: boolean = false;

    selectedStatuses: string[] = [];
    userDataUploadHistory$: Observable<UploadPortalStatusVersion[]>;
    userDataUploadHistoryLoading$: Observable<boolean>;

    //history selected filed
    historyFilesIndeterminate: boolean = false;
    historyFilesAllChecked: boolean = false;
    setOfHistoryFileChecked = new Set<string>();

    selectedClientId: string;

    isFileDownloadModalVisible = false;
    totalFiles: number = 0;
    downloadedFiles: number = 0;
    failedFiles: number = 0;
    fileEntries = [];
    isLoading: boolean = false;

    selectedEventTypes: string[] = [];
    //forms
    uploadPortalForm: UntypedFormGroup;
    lastUploadHistoryItems: string[] = [];

    //upload files
    fileList: NzUploadFile[] = [];

    filter: Subject<UploadPortalFilterForm> = new Subject();

    lastCreatedStamp: number;

    @Input() searchText: string;

    searchFilter = {
        searchText: '',
        lastUploadPortalId: '',
        lastUploadPortalName: '',
        columnName: 'upload_portal_name',
        sortDirection: 'ascend',
    };

    historySearchFilter = {
        columnName: 'user_name',
        sortDirection: 'descend',
    };

    ngDestroyed$ = new Subject();
    uploadPortalsLoaded: boolean = false;

    saveError;

    columns = [
        {
            title: 'Upload Portal Name',
            key: 'upload_portal_name',
            sortDirections: ['descend', 'ascend', null],
            width: '30%',
        },
        {
            title: 'Upload Portal Type',
            key: 'Type',
            sortDirections: [null],
            width: '25%',
        },
        {
            title: 'Active',
            key: 'active',
            sortDirections: [null],
            width: '10%',
        },
        {
            title: 'Status',
            key: 'Status',
            sortDirections: [null],
            width: '10%',
        },
        {
            title: 'Date updated',
            key: 'updated_stamp',
            sortDirections: [null],
            width: '25%'
        }
    ];

    historyColumns = [
        {
            title: 'User name',
            key: 'user_name',
            width: '25%',
            sortDirections: [null],
        },
        {
            title: 'File name/Status name',
            key: 'file_name',
            width: '37%',
            sortDirections: [null],
        },
        {
            title: 'Event',
            key: 'event',
            width: '15%',
            sortDirections: [null],
        },
        {
            title: 'Date created',
            key: 'created_stamp',
            width: '20%',
            sortDirections: ['ascend', 'descend'],
        },
    ];

    userPermissions: UserPermissions;

    uploadPortalTypesLoading: boolean = false;
    uploadPortalTypes: UploadPortalType[] = [];

    attributesTreeNodes: NzTreeNodeOptions[] = [];
    attributesTreeNodesLoading: boolean = false;

    attributesByTypeId: Record<string, Attribute[]> = {};

    downloadingTemplate$: Observable<boolean>;

    constructor(
        private store: Store<AppState>,
        private fb: UntypedFormBuilder,
        private message: NzMessageService,
        private modal: NzModalService,
        private uploadPortalService: UploadPortalService,
        private globalService: GlobalService,
        private fileWorkerService: FileWorkerService,
    ) {

        this.downloadingTemplate$ = this.store.select(selectDownloadUploadPortalLoading);

        //retrieve clientId from query params and retrieve data
        this.globalService.clientId$
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((clientId) => {
                if (clientId) {
                    this.selectedClientId = clientId;
                    this.fetchUploadPortals(this.selectedClientId);
                    this.fetchTypes(this.selectedClientId);
                    this.fetchStatuses(this.selectedClientId);
                    this.selectedStatuses = [];
                    this.filter.next(undefined);
                }
            });

        //init form
        this.initUploadPortalForm();

        this.filter
            .pipe(takeUntil(this.ngDestroyed$), debounceTime(400))
            .subscribe((filter) => {
                this.fetchUploadPortals(
                    this.selectedClientId,
                    filter
                );
            });
    }

    fetchStatuses(clientId: string) {
        this.statusesLoading = true;
        this.uploadPortalService.getAllStatuses(clientId, { limit: 1000 }).subscribe({
            next: (payload) => {
                this.statuses = _get(payload, 'payload', []);
            },
            complete: () => {
                this.statusesLoading = false;
            },
        });
    }

    fetchTypes(clientId: string) {
        this.uploadPortalTypesLoading = true;

        this.uploadPortalService
            .getUploadPortalTypes(clientId, undefined, 1000)
            .subscribe({
                next: (payload) => {
                    this.uploadPortalTypes = _get(payload, 'payload', []);
                },
                complete: () => {
                    this.uploadPortalTypesLoading = false;
                },
            });
    }

    get portalTypeDropdownOptions() {
        return _orderBy(this.uploadPortalTypes ?? [], (item: UploadPortalType) => item.uploadPortalTypeName.toLowerCase(), ['asc']);
    }

    get statusesDropdownOptions() {
        return _orderBy(this.statuses ?? [], (item: UploadPortalStatus) => item.collectionStatusName.toLowerCase(), ['asc']);
    }

    //instead of onSearch
    ngOnChanges(changes: SimpleChanges) {
        let change = changes['searchText'];
        if (change) {
            if (change.currentValue != change.previousValue) {
                this.uploadPortalsLoaded = false;
            }
            this.searchFilter.searchText = change.currentValue;
            this.filter.next({
                selectedStatuses: this.selectedStatuses,
                searchText: change.currentValue,
                columnName: this.searchFilter.columnName,
                sortDirection: this.searchFilter.sortDirection,
            });
        }
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    ngOnInit() {
        this.store.select(selectAuthUser).subscribe((user) => {
            if (user && user.userRoles) {
                this.userPermissions = this.processUserRoles(user.userRoles);
            }
        });
    }

    handleClose(): void {
        this.isFileDownloadModalVisible = false;
        this.downloadedFiles = 0;
        this.setOfHistoryFileChecked.clear();
        this.refreshCheckedStatus();
        this.failedFiles = 0;
        this.totalFiles = 0;
    }

    handleCancel(): void {
        const confirmModal = this.modal.confirm({
            nzTitle: '<b>Confirm action</b>',
            nzContent: 'Do you really want to cancel the operation?',
            nzOnOk: () => {
                this.fileWorkerService.cancelOperation();
                confirmModal.close();
            },
            nzClosable: false,
        });
    }

    processDownloadedBlob(payload: any) {
        const blob = payload.blob;
        const docName = payload.docName;

        const url = window.URL.createObjectURL(blob);

        const tempEl = document.createElement('a');
        document.body.appendChild(tempEl);
        tempEl.href = url;
        tempEl.download = docName;
        tempEl.click();

        window.URL.revokeObjectURL(url);
    }

    findOrCreateProgressEntry(item: DataDownloadHistoryItemAction, event: any) {
        let entry = undefined;
        let filteredEntries = [];
        if (event) {
            filteredEntries = this.fileEntries.filter(
                (e) => e.id == event.recordId
            );
        }

        if (filteredEntries.length == 0) {
            entry = {
                id: item.recordId,
                name: item.docName,
                size:
                    item.fileSize > Math.pow(1024, 2)
                        ? (item.fileSize / Math.pow(1024, 2)).toFixed(1) + 'MB'
                        : (item.fileSize / 1024).toFixed(1) + 'KB',
                progress: 0,
                failed: false,
                message: 'Pending',
            };
            this.fileEntries.push(entry);
            this.fileEntries.sort((a, b) => (a.name > b.name && 1) || -1);
        } else {
            entry = filteredEntries[0];
        }

        return entry;
    }

    onDownloadDataHistory() {
        if (window.navigator.onLine === false) return; // prevent weird consequences if connection is lost

        this.isLoading = true;

        const selectedItems: Array<string> = [];
        this.userDataUploadHistory$.pipe(take(1)).forEach((items) => {
            items.forEach((item) => {
                if (this.setOfHistoryFileChecked.has(item.recordId)) {
                    selectedItems.push(item.recordId);
                }
            });
        });

        this.uploadPortalService
            .getDownloadActionsForSelectedUserPortalHistoryEntries(
                this.uploadPortalId,
                selectedItems
            )
            .pipe(take(1))
            .subscribe({
                next: (resp) => {
                    if (resp.payload) {
                        this.totalFiles = resp.payload.length;
                        this.downloadedFiles = 0;
                        this.failedFiles = 0;
                        this.isFileDownloadModalVisible = true;
                        this.isLoading = false;

                        this.fileEntries = [];
                        for (let i = 0; i < this.totalFiles; i++) {
                            const item = resp.payload[i];
                            item.uploadPortalId = this.uploadPortalId;

                            this.findOrCreateProgressEntry(item, null); // enforces addition

                            this.fileWorkerService.addWorkerTask(
                                item,
                                (event: any) => {
                                    const entry =
                                        this.findOrCreateProgressEntry(
                                            item,
                                            event
                                        );
                                    if (event.error) {
                                        console.log(
                                            'Service: got error=' +
                                                event.message +
                                                ' for id: ' +
                                                event.recordId
                                        );
                                        entry.failed = true;
                                        entry.message = event.message;

                                        this.failedFiles++;
                                    } else {
                                        if (event.completed) {
                                            console.log(
                                                'Service: task completed for id: ' +
                                                    event.recordId
                                            );
                                            entry.progress = Math.round(
                                                (event.loaded / event.total) *
                                                    100
                                            );
                                            entry.message = 'Success';
                                            this.processDownloadedBlob({
                                                docName: event.docName,
                                                blob: event.blob,
                                            });
                                            this.downloadedFiles++;
                                        } else {
                                            entry.progress = Math.round(
                                                (event.loaded / event.total) *
                                                    100
                                            );
                                            entry.message = 'Loading';
                                        }
                                    }
                                }
                            );
                        }
                    }
                },
                error: (_) => {
                    this.isLoading = false;
                    this.message.error(
                        `Cannot retrieve the metadata for the files. Please try later`,
                        { nzDuration: 3000 }
                    );
                },
            });
    }

    onChangeStatusVisible(visible: boolean, uploadPortalId: string) {
        if (visible) {
            this.activeChangeStatusPageId = uploadPortalId;
        } else {
            this.activeChangeStatusPageId = null;
        }
    }

    private initUploadPortalForm(uploadPortal?: UploadPortal) {
        this.uploadPortalForm = this.fb.group({
            uploadPortalId: [uploadPortal?.uploadPortalId ?? ''],
            uploadPortalName: [
                uploadPortal?.uploadPortalName ?? '',
                Validators.required,
            ],
            uploadPortalTypeId: [uploadPortal?.uploadPortalType?.uploadPortalTypeId ?? '', Validators.required],
            clientId: [this.selectedClientId, Validators.required],
            attrIds: [!uploadPortal?.attributes?.length ? [] : uploadPortal.attributes.map(attr => attr.attrId), Validators.required]
        });

        if (!uploadPortal?.uploadPortalType?.attributeTypes?.length || (uploadPortal?.uploadPortalType?.attributeTypes ?? []).every(type => !type.attributes.length)) {
            this.uploadPortalForm.get('attrIds').removeValidators(Validators.required);
            this.uploadPortalForm.updateValueAndValidity({ emitEvent: true });
        }
    }

    onUploadPortalTypeChanged(uploadPortalTypeId: string) {
        const portalType = this.uploadPortalTypes.find(item => item.uploadPortalTypeId === uploadPortalTypeId);

        if (portalType) {
            this.initAttributeNodesTree(portalType);
        } else {
            this.attributesTreeNodes = [];
        }

        this.uploadPortalForm.get('attrIds').patchValue([]);

        if (!!portalType?.attributeTypes?.length && (portalType?.attributeTypes ?? []).some(type => !!type.attributes.length)) {
            this.uploadPortalForm.get('attrIds').addValidators(Validators.required);
        } else {
            this.uploadPortalForm.get('attrIds').clearValidators();
            this.uploadPortalForm.get('attrIds').setErrors(null);
        }
        
        this.uploadPortalForm.updateValueAndValidity({ emitEvent: true });
    }

    initAttributeNodesTree(portalType: UploadPortalType) {
        if (!portalType.attributeTypes?.length) {
            this.attributesTreeNodes = [];
            this.attributesTreeNodesLoading = false;

            return;
        }

        this.attributesTreeNodes = [];
        this.attributesTreeNodesLoading = true;

        this.attributesByTypeId = portalType.attributeTypes.reduce((result, item) => {
            result[item.attrTypeId] = item.attributes ?? [];
            return result;
        }, {});

        this.attributesTreeNodes = portalType.attributeTypes.flatMap<NzTreeNodeOptions>((attrType) => {
            return {
                title: attrType.attrTypeName,
                value: `attr-type~${attrType.attrTypeId}`,
                key: `attr-type~${attrType.attrTypeId}`,
                selectable: false,
                children: _orderBy(attrType.attributes ?? [], (item: Attribute) => item.attrName.toLowerCase(), ['asc']).map((attribute: Attribute) => ({
                    title: attribute.attrName,
                    key: attribute.attrId,
                    value: attribute.attrId,
                    isLeaf: true
                }))
            };
        });

        this.attributesTreeNodesLoading = false;
    }

    onCreateUploadPortal() {
        this.uploadPortalForm.reset();
        this.initUploadPortalForm();
        this.uploadPortalFormMode = true;
    }

    closeUploadPortalFormMode() {
        this.fileList = [];
        this.uploadPortalFormMode = false;
        this.uploadedPageTemplate = null;
    }

    closeHistoryMode() {
        this.historyMode = false;
        this.setOfHistoryFileChecked.clear();
        this.uploadPortalId = null;
        this.refreshCheckedStatus();
    }

    onFilterTypeChanged(selectedEventTypes: string[]) {
        this.lastUploadHistoryItems = [];
        this.historySearchFilter.sortDirection = 'descend';
        this.store.dispatch(
            uploadPortalStatusHistoryRequest({
                uploadPortalId: this.uploadPortalId,
                limit: PAGE_SIZE,
                reset: true, // needs to reset the underlying array of items in the state object
                filter: {
                    eventTypes: selectedEventTypes,
                    sortDirection: this.historySearchFilter.sortDirection,
                },
            })
        );
    }

    onShowHistory(uploadPortalId: string) {
        this.historyFilesAllChecked = false;
        this.setOfHistoryFileChecked = new Set<string>();
        this.historyFilesIndeterminate = false;
        this.historySearchFilter.sortDirection = 'descend';
        this.uploadPortalId = uploadPortalId;
        this.selectedEventTypes = [];
        this.userDataUploadHistory$ = this.store.select(
            selectUploadPortalStatusVersionsById,
            { uploadPortalId }
        );
        this.userDataUploadHistoryLoading$ = this.store.select(
            selectUploadPortalStatusVersionsLoadingById,
            { uploadPortalId }
        );

        this.store.dispatch(
            uploadPortalStatusHistoryRequest({
                uploadPortalId: uploadPortalId,
                limit: PAGE_SIZE,
                reset: true, // needs to reset the underlying array of items in the state object
                filter: {
                    sortDirection: this.historySearchFilter.sortDirection,
                    eventTypes: this.selectedEventTypes,
                },
            })
        );

        this.historyMode = true;
    }

    async onHistoryTableScrolled() {
        const loading = await firstValueFrom(
            this.userDataUploadHistoryLoading$
        );

        if (!loading) {
            const data = await firstValueFrom(this.userDataUploadHistory$);
            const last: DataUploadHistory | undefined = _last(data);

            if (!last) {
                return;
            }

            if (!last?.recordId) {
                return;
            }

            if (this.lastUploadHistoryItems.includes(last.recordId)) {
                // in case of when all items size === 24 and already loaded
                return;
            }

            if (last) {
                const { createdStamp, recordId } = last;
                this.lastUploadHistoryItems.push(recordId);

                this.store.dispatch(
                    uploadPortalStatusHistoryRequest({
                        uploadPortalId: this.uploadPortalId,
                        limit: PAGE_SIZE,
                        filter: {
                            sortDirection:
                                this.historySearchFilter.sortDirection,
                            lastHistoryCreatedStamp: createdStamp,
                            lastRecordId: recordId,
                            eventTypes: this.selectedEventTypes,
                        },
                    })
                );
            }
        }
    }

    async orderHistoryChange(col: any) {
        if (col.key != 'created_stamp') return;
        this.lastUploadHistoryItems = [];
        const loading = await firstValueFrom(
            this.userDataUploadHistoryLoading$
        );
        if (!loading) {
            this.historySearchFilter.sortDirection = col.value;

            this.store.dispatch(
                uploadPortalStatusHistoryRequest({
                    uploadPortalId: this.uploadPortalId,
                    limit: PAGE_SIZE,
                    reset: true, // needs to reset the underlying array of items in the state object
                    filter: {
                        sortDirection: this.historySearchFilter.sortDirection,
                        eventTypes: this.selectedEventTypes,
                    },
                })
            );
        }
    }

    onChangeStatus(page: UploadPortal) {
        if (!this.availableStatuses || !this.availableStatuses.length) {
            this.availableStatusesLoading = true;
            this.uploadPortalService
                .getUploadPortalAvailableStatuses(
                    page.uploadPortalType.uploadPortalTypeId
                )
                .pipe(takeUntil(this.ngDestroyed$))
                .subscribe({
                    next: (payload) => {
                        this.availableStatusesLoading = false;
                        this.availableStatuses = _get(payload, 'payload', []);
                    },
                    error: () => (this.availableStatusesLoading = false),
                });
        }
    }

    changePageStatus(collectionStatusId: string) {
        this.uploadPortalService
            .updatePageStatus(this.activeChangeStatusPageId, collectionStatusId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe(() => {
                this.uploadPortalsLoaded = false;
                this.fetchUploadPortals(this.selectedClientId);
                this.message.success(`Status has been changed successfully!`, {
                    nzDuration: 3000,
                });
            });
    }

    submitUploadPortalForm() {
        const formValue = this.uploadPortalForm.value;

        const data = {
            uploadPortalName: _get(formValue, 'uploadPortalName'),
            uploadPortalTypeId: _get(formValue, 'uploadPortalTypeId'),
            clientId: _get(formValue, 'clientId'),
        };

        if (formValue.uploadPortalId) {
            data['uploadPortalId'] = formValue.uploadPortalId;
        }

        const attrIds = _get(formValue, 'attrIds', []);

        if (Array.isArray(attrIds)) {
            data['attrIds'] = _compact(attrIds.flatMap(id => {
                if (id.includes('~')) {
                    const attrTypeId = id.split('~')?.[1];

                    if (!attrTypeId) {
                        return [];
                    }

                    return (this.attributesByTypeId?.[attrTypeId] ?? []).map((attr: Attribute) => attr.attrId)
                }

                return id;
            }))
        }

        const createUploadPortalReqJson = JSON.stringify(data);

        this.saveError = undefined;

        if (formValue.uploadPortalId) {
            this.uploadPortalService
                .putUploadPortalFormData(
                    createUploadPortalReqJson,
                    this.fileList && this.fileList.length
                        ? _first(this.fileList)
                        : undefined
                )
                .pipe(takeUntil(this.ngDestroyed$))
                .subscribe({
                    next: () => {
                        this.lastCreatedStamp = undefined;
                        this.searchFilter.lastUploadPortalId = '';
                        this.searchFilter.lastUploadPortalName ='';

                        this.filter.next({
                            selectedStatuses: this.selectedStatuses,
                            searchText: this.searchFilter.searchText,
                            columnName: this.searchFilter.columnName,
                            sortDirection: this.searchFilter.sortDirection,
                        });
                        this.message.success(
                            `Upload portal <b>${data.uploadPortalName}</b> has been updated`,
                            { nzDuration: 4000 }
                        );
                        this.uploadPortalForm.reset();
                        this.fileList = [];
                        this.uploadPortalFormMode = false;
                    },
                    error: (error) => {
                        if (
                            error &&
                            error.error &&
                            error.error.fieldErrors &&
                            error.error.fieldErrors.length
                        ) {
                            this.saveError =
                                error.error.fieldErrors[0]['fieldErrorMessage'] ||
                                '';
                        }
                        if (error && error.error && error.message) {
                            this.saveError = error.error.message;
                        }
                    }
                });
        } else {
            this.uploadPortalService
                .postUploadPortalFormData(
                    createUploadPortalReqJson,
                    this.fileList && this.fileList.length
                        ? _first(this.fileList)
                        : undefined
                )
                .pipe(takeUntil(this.ngDestroyed$))
                .subscribe({
                    next: () => {
                        this.lastCreatedStamp = undefined;
                        this.searchFilter.lastUploadPortalId = '';
                        this.searchFilter.lastUploadPortalName ='';

                        this.filter.next({
                            selectedStatuses: this.selectedStatuses,
                            searchText: this.searchFilter.searchText,
                            columnName: this.searchFilter.columnName,
                            sortDirection: this.searchFilter.sortDirection,
                        });
                        this.message.success(
                            `Upload portal <b>${data.uploadPortalName}</b> has been created`,
                            { nzDuration: 4000 }
                        );
                        this.uploadPortalForm.reset();
                        this.fileList = [];
                        this.uploadPortalFormMode = false;
                    },
                    error: (error) => {
                        if (
                            error &&
                            error.error &&
                            error.error.fieldErrors &&
                            error.error.fieldErrors.length
                        ) {
                            this.saveError =
                                error.error.fieldErrors[0]['fieldErrorMessage'] ||
                                '';
                        }
                        if (error && error.error && error.message) {
                            this.saveError = error.error.message;
                        }
                    }
                });
        }
    }

    beforeTemplateUpload = (file: NzUploadFile) => {
        return new Observable((observer: Observer<boolean>) => {
            const isLt250M = file.size / 1024 / 1024 < 250;

            if (!isLt250M) {
                this.message.error('File should be smaller than 250MB!');
                observer.complete();
                return;
            }

            if (isLt250M) {
                this.fileList = [file];
            }

            if (this.fileList && this.fileList.length) {
                this.uploadPortalForm.markAsDirty();
            }

            // false for manual upload on form submit
            observer.next(isLt250M && false);
            observer.complete();
        });
    };

    onUploadNewVersion(uploadPortal: UploadPortal) {
        this.uploadedPageTemplate = uploadPortal.uploadPortalFileName;
        this.initUploadPortalForm(uploadPortal);

        if (uploadPortal?.uploadPortalType) {
            this.initAttributeNodesTree(uploadPortal.uploadPortalType);
        }
        this.uploadPortalFormMode = true;
    }

    onDeleteTemplate(uploadPortalId: string, uploadPortalName: string): void {
        this.modal.confirm({
            nzTitle: `Do you want to remove <b>${uploadPortalName}</b>?`,
            nzContent: `
        If you delete it you will not be able to recover portal.<br>
        Do you want to delete the portal?
      `,
            nzOnOk: () => {
                this.uploadPortalService
                    .deleteUploadPortal(uploadPortalId)
                    .pipe(takeUntil(this.ngDestroyed$))
                    .subscribe(() => {
                        this.uploadPortals = this.uploadPortals.filter(
                            (item) => uploadPortalId !== item.uploadPortalId
                        );
                        this.message.success(
                            `Upload portal <b>${uploadPortalName}</b> has been deleted`,
                            { nzDuration: 4000 }
                        );
                    });
            },
            nzOkText: 'Yes, remove',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzCancelText: 'Close',
            nzClosable: false,
            nzOnCancel: () => console.log('Cancel'),
        });
    }

    onFilterStatusSelected(statusIds: string[]) {
        this.uploadPortalsLoaded = false;
        this.uploadPortalsLoading = true;
        this.selectedStatuses = [];
        this.selectedStatuses = statusIds;
        this.filter.next({
            selectedStatuses: this.selectedStatuses,
            searchText: this.searchFilter.searchText,
            columnName: this.searchFilter.columnName,
            sortDirection: this.searchFilter.sortDirection,
        });
    }

    private fetchUploadPortals(
        clientId?: string,
        filter?: UploadPortalFilterForm
    ) {
        this.uploadPortalsLoading = true;

        this.uploadPortalService
            .getUploadPortals(clientId, filter ? { ...filter, limit: PAGE_SIZE } : { limit: PAGE_SIZE })
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    const uploadPortals = _get(payload, 'payload', []);

                    this.uploadPortalsLoading = false;

                    if (uploadPortals.length < PAGE_SIZE) {
                        this.uploadPortalsLoaded = true;
                    }
                    
                    if (filter && filter.lastUploadPortalId) {
                        if (uploadPortals.length) {
                            this.uploadPortals = [
                                ...this.uploadPortals,
                                ...uploadPortals,
                            ];
                        }
                    } else {
                        this.uploadPortals = uploadPortals;
                    }
                },
                complete: () => {
                    this.uploadPortalsLoading = false;
                    this.query = this.searchFilter.searchText;
                },
                error: () => {
                    this.uploadPortalsLoading = false;
                }
            });
    }

    onTableScrolled() {
        if (!this.uploadPortalsLoaded) {
            const dt: UploadPortal = _last(this.uploadPortals);
            if (dt && dt.uploadPortalId) {
                const { createdStamp, uploadPortalName, uploadPortalId } = dt;

                this.searchFilter.lastUploadPortalName = uploadPortalName.valueOf();
                this.searchFilter.lastUploadPortalId = uploadPortalId.valueOf();
                this.lastCreatedStamp = createdStamp;
                this.filter.next({
                    selectedStatuses: this.selectedStatuses,
                    searchText: this.searchFilter.searchText,
                    columnName: this.searchFilter.columnName,
                    sortDirection: this.searchFilter.sortDirection,
                    lastCreatedStamp: createdStamp,
                    lastUploadPortalId: this.searchFilter.lastUploadPortalId,
                    lastUploadPortalName: this.searchFilter.lastUploadPortalName,
                });
            }
        }
    }

    orderChange(col: any) {
        if (col.key === 'Type') {
            return;
        }
        if (col.key === 'Status') {
            return;
        }
        this.uploadPortals = [];
        this.uploadPortalsLoading = true;
        this.uploadPortalsLoaded = false;
        this.searchFilter.columnName = col.key;
        this.searchFilter.sortDirection = col.value;
        this.filter.next({
            selectedStatuses: this.selectedStatuses,
            searchText: this.searchFilter.searchText,
            columnName: this.searchFilter.columnName,
            sortDirection: this.searchFilter.sortDirection,
        });
    }

    updateCheckedSet(recordId: string, checked: boolean): void {
        if (checked) {
            this.setOfHistoryFileChecked.add(recordId);
        } else {
            this.setOfHistoryFileChecked.delete(recordId);
        }
    }

    private refreshCheckedStatus(): void {
        this.userDataUploadHistory$.pipe(take(1)).forEach((items) => {
            if (
                items &&
                items.length &&
                this.setOfHistoryFileChecked.size > 0
            ) {
                this.historyFilesAllChecked = items
                    .filter(({ event }) => event === 'Uploaded file')
                    .every(({ recordId }) =>
                        this.setOfHistoryFileChecked.has(recordId)
                    );

                this.historyFilesIndeterminate =
                    items
                        .filter(({ event }) => event === 'Uploaded file')
                        .some(({ recordId }) =>
                            this.setOfHistoryFileChecked.has(recordId)
                        ) && !this.historyFilesAllChecked;
            }
        });
    }

    onHistoryFilesAllChecked(checked: boolean) {
        this.userDataUploadHistory$.pipe(take(1)).forEach((items) => {
            if (items && items.length) {
                items
                    .filter(({ event }) => event === 'Uploaded file')
                    .forEach(({ recordId }) =>
                        this.updateCheckedSet(recordId, checked)
                    );
                this.refreshCheckedStatus();
            }
        });
    }

    onHistoryFileChecked(recordId: string, checked: boolean) {
        this.updateCheckedSet(recordId, checked);
        this.refreshCheckedStatus();
    }

    onActiveUploadPortalChange(data: UploadPortal, active: boolean) {
        this.uploadPortalService
            .patchActive(data.uploadPortalId, active)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: () => {
                    this.uploadPortals = this.uploadPortals.map((uploadPortal) => {
                        if (uploadPortal.uploadPortalId === data.uploadPortalId) {
                            uploadPortal.active = active;
                        }

                        return uploadPortal;
                    });
                    this.message.success(
                        `Upload portal is ${
                            active ? 'activated' : 'deactivated'
                        } upload portal ${data.uploadPortalName}`,
                        { nzDuration: 4000 }
                    );
                },
                error: () =>
                    this.message.error(
                        `Failed to ${
                            active ? 'activate' : 'deactivate'
                        } upload portal ${data.uploadPortalName}`
                    ),
            });
    }

    processUserRoles(roles: string[]): UserPermissions {
        return {
            isAiAdmin: roles.includes('AIADMIN'),
            isAiUser: roles.includes('AIUSER'),
            isClientAdmin: roles.includes('CLIENTADMIN'),
            isClientUser: roles.includes('CLIENTUSER'),
            hasReportMgmt: roles.includes('REPORT_MGMT'),
            hasReportMgmtView: roles.includes('REPORT_MGMT_VIEW'),
            hasUploadPortalMgmt: roles.includes('UPLOAD_PORTAL_MGMT'),
            hasUploadPortalMgmtView: roles.includes('UPLOAD_PORTAL_MGMT_VIEW'),
            hasLtdUserMgmt: roles.includes('LTD_USER_ADMIN'),
            hasReportPortal: roles.includes('REPORT_PORTAL'),
            hasUploadPortal: roles.includes('UPLOAD_PORTAL'),
        };
    }

    onDownloadTemplateFile() {
        const uploadPortalId = this.uploadPortalForm.get('uploadPortalId').value;

        if (!uploadPortalId) {
            return;
        }

        this.store.dispatch(
            downloadUploadPortalFileRequest({
                uploadPortalId: uploadPortalId,
                uploadPortalFileName: this.uploadedPageTemplate,
            })
        );
    }
}
