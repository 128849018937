<div class="data-container">
    <div class="data-controls">
        <div class="data-controls__page-selector">
            <div>
                <div class="label">Upload portal Types</div>
                <nz-select
                    nzShowSearch
                    nzSize="large"
                    [nzLoading]="userUploadPortalTypesLoading$ | async"
                    [(ngModel)]="selectedUploadPortalTypeId"
                    (ngModelChange)="onUploadPortalTypeChanged($event)"
                    nzPlaceHolder="All upload portals"
                >
                    <nz-option
                        *ngFor="
                            let uploadPortalType of uploadPortalTypes
                        "
                        [nzLabel]="uploadPortalType.uploadPortalTypeName"
                        [nzValue]="uploadPortalType.uploadPortalTypeId"
                    ></nz-option>
                </nz-select>
            </div>
            <div>
                <div class="label">Upload portal</div>
                <nz-select
                    nzShowSearch
                    nzSize="large"
                    [nzLoading]="userUploadPortalsLoading$ | async"
                    [(ngModel)]="selectedUploadPortalId"
                    (ngModelChange)="onUploadPortalChanged($event)"
                    nzPlaceHolder="All upload portals"
                >
                    <nz-option
                        *ngFor="let uploadPortal of uploadPortals"
                        [nzLabel]="uploadPortal.uploadPortalName"
                        [nzValue]="uploadPortal.uploadPortalId"
                    ></nz-option>
                </nz-select>
            </div>
        </div>
        <div
            class="data-controls__page-status"
            *ngIf="
                !uploadPortalCollectionStatusLoading && uploadPortalCollectionStatus
            "
        >
            <div class="label" [style.marginBottom.rem]="0.375">Status</div>
            <app-upload-portal-status
                [statusId]="
                    uploadPortalCollectionStatus?.collectionStatusId
                "
                [statusName]="
                    uploadPortalCollectionStatus?.collectionStatusName
                "
                [statusColor]="
                    uploadPortalCollectionStatus?.statusColor
                "
            >
            </app-upload-portal-status>
        </div>
        <button
            class="data-controls__download"
            nzSize="large"
            *ngIf="(selectedUploadPortal$ | async)?.uploadPortalFileName"
            [nzLoading]="uploadPortalDownloadLoading$ | async"
            [disabled]="!selectedUploadPortalId"
            nzType="primary"
            type="button"
            nz-button
            (click)="onDownloadUploadPortal(selectedUploadPortalId)"
        >
            <i nz-icon nzType="file-add" nzTheme="outline"></i>
            Download template
        </button>
    </div>
    <div class="info-content">
        <nz-collapse
            *ngIf="
                (selectedUploadPortal$ | async)?.uploadPortalType
                    ?.uploadPortalText
            "
            [nzBordered]="false"
        >
            <nz-collapse-panel
                (nzActiveChange)="onCollapsePanelChange($event)"
                [nzActive]="isPanelActive"
                [nzShowArrow]="false"
                [nzHeader]="collapseInstructionsHeader"
                [ngStyle]="{
                    background: '#f7f7f7',
                    'border-radius': '4px',
                    'margin-top': '1rem',
                    border: '0px'
                }"
            >
                <div
                    [innerHtml]="
                        (selectedUploadPortal$ | async)?.uploadPortalType
                            ?.uploadPortalText | safe: 'html'
                    "
                ></div>
            </nz-collapse-panel>
            <ng-template #collapseInstructionsHeader>
                <div class="instructions-header">
                    <span class="header-text">Instructions</span>
                    <span class="header-meta">
                        Click to {{ isPanelActive ? 'collapse' : 'expand' }}
                        <span
                            nz-icon
                            [style.transform]="
                                isPanelActive ? 'rotate(180deg)' : 'none'
                            "
                            nzType="down"
                            nzTheme="outline"
                        ></span>
                    </span>
                </div>
            </ng-template>
        </nz-collapse>
    </div>
    <div *ngIf="selectedUploadPortalId" class="uploads__drop-zone">
        <nz-upload
            nzType="drag"
            [nzMultiple]="true"
            [nzBeforeUpload]="beforeUpload"
            [nzLimit]="5"
            (nzChange)="onChangeFiles($event)"
        >
            <div class="upload-content">
                <p class="upload-content__text">Drag and Drop files here</p>
                <p
                    [style.margin.rem]="'0.375rem 0'"
                    class="upload-content__text secondary"
                >
                    OR
                </p>
                <button nzType="primary" type="button" nz-button>
                    <i nz-icon nzType="attach" nzTheme="outline"></i>
                    Add File(s)
                </button>
            </div>
            <div class="upload-hover-content">
                <i nz-icon nzType="subtract-filled" nzTheme="fill"></i>
                Drop your files here
            </div>
        </nz-upload>
        <div *ngIf="fileList && fileList.length" class="uploads__file-list">
            <app-upload-file-tile
                *ngFor="let file of fileList"
                (delete)="onFileDelete(file.name)"
                [fileName]="file.name"
                [fileType]="file.type"
                [fileSize]="file.size"
            ></app-upload-file-tile>
        </div>
        <button
            class="uploads__upload-btn"
            *ngIf="fileList.length && !isFilesUploaded"
            [nzLoading]="isUploading"
            nzType="primary"
            type="button"
            nz-button
            (click)="handleUpload()"
        >
            Upload files
        </button>
    </div>
    <!--<div *ngIf="(userDataUploadHistory$ | async)?.length" style="width:100%; padding-top: 15px;" >-->
    <div style="width: 100%; padding-top: 15px">
        <h3 style="float: left" class="activity-history__header">
            Activity history
        </h3>
        <button
            nzSize="large"
            [disabled]="!setOfHistoryFileChecked.size"
            nzType="primary"
            type="button"
            [nzLoading]="isLoading"
            nz-button
            (click)="onDownloadDataHistory()"
            style="float: right"
        >
            Download
            {{
                !!setOfHistoryFileChecked?.size
                    ? setOfHistoryFileChecked.size
                    : ''
            }}
            File{{
                !!setOfHistoryFileChecked?.size &&
                setOfHistoryFileChecked?.size > 1
                    ? 's'
                    : ''
            }}
        </button>
        <div style="float: right; padding-right: 10px">
            <nz-select
                nzShowSearch
                [nzAllowClear]="true"
                [nzLoading]="loadingEventTypes"
                [nzMaxMultipleCount]="2"
                [nzDisabled]="!selectedUploadPortalId"
                [nzMaxTagPlaceholder]="maxTagPlaceholder"
                nzMode="multiple"
                style="width: 310px"
                [(ngModel)]="selectedEventTypes"
                (ngModelChange)="onFilterTypeChanged($event)"
                [nzCustomTemplate]="selectedStatusTemplate"
                [nzPlaceHolder]="'All event types'"
                #eventType
            >
                <nz-option *ngFor="let eventType of eventTypes" [nzValue]="eventType" [nzLabel]="eventType"></nz-option>
            </nz-select>
            <ng-template #selectedStatusTemplate let-selected>
                <div class="status-template">
                    <div class="status-template__name">
                        {{ selected.nzLabel }}
                    </div>
                </div>
            </ng-template>
            <ng-template #maxTagPlaceholder let-selectedList>
                +{{ selectedList.length }}
            </ng-template>
        </div>
    </div>
    <div class="uploads-history__table">
        <app-infinite-scroll
            [loaded]="(userDataUploadHistoryLoading$ | async) === false"
            [loading]="userDataUploadHistoryLoading$ | async"
            (scrolled)="onTableScrolled()"
        >
            <nz-table
                [nzLoading]="userDataUploadHistoryLoading$ | async"
                nzHideOnSinglePage
                #filterTable
                [nzSize]="'middle'"
                [nzPageSize]="9999999"
                [nzData]="userDataUploadHistory$ | async"
            >
                <thead (nzSortOrderChange)="orderChange($event)">
                    <tr>
                        <th
                            nzWidth="3%"
                            [nzChecked]="historyFilesAllChecked"
                            [nzIndeterminate]="historyFilesIndeterminate"
                            (nzCheckedChange)="onHistoryFilesAllChecked($event)"
                        ></th>
                        <th
                            *ngFor="let column of columns"
                            [nzSortFn]="true"
                            [nzWidth]="column.width"
                            [nzSortDirections]="column.sortDirections"
                            nzColumnKey="{{ column.key }}"
                        >
                            {{ column.title }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of filterTable.data">
                        <td>
                            <span
                                *ngIf="item.event === 'Uploaded file'"
                                nz-checkbox
                                [nzChecked]="
                                    setOfHistoryFileChecked.has(item.recordId)
                                "
                                (nzCheckedChange)="
                                    onHistoryFileChecked(item.recordId, $event)
                                "
                            ></span>
                        </td>
                        <td>{{ item.userLoginId }}</td>
                        <td>{{ item.itemName }}</td>
                        <td>{{ item.event }}</td>
                        <td>
                            {{
                                item.createdStamp | date: 'dd MMM yyyy, h:mm a'
                            }}
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </app-infinite-scroll>
    </div>
    <!--</div>-->
    <nz-modal
        [(nzVisible)]="isFileDownloadModalVisible"
        nzTitle="Download selected files"
        (nzOnCancel)="handleCancel()"
        [nzMaskClosable]="false"
        [nzClosable]="false"
        [nzOkText]="null"
        [nzWidth]="750"
    >
        <div
            *nzModalContent
            style="height: 150px; overflow-y: scroll"
            id="entriesdiv"
        >
            <ng-container *ngFor="let entry of fileEntries; let i = index">
                <div>
                    <label
                        ><span style="font-weight: bold">{{ i + 1 }}:</span>
                        {{ entry.name }} ({{ entry.size }})
                        <span
                            nz-tooltip
                            [nzTooltipTitle]="entry.message"
                            style="cursor: hand"
                            >&#9432;</span
                        ></label
                    >
                    <nz-progress
                        *ngIf="!entry.failed"
                        [nzPercent]="entry.progress"
                    ></nz-progress>
                    <nz-progress
                        *ngIf="entry.failed"
                        [nzPercent]="entry.progress"
                        nzStatus="exception"
                    ></nz-progress>
                </div>
            </ng-container>
        </div>
        <div *nzModalFooter>
            <div style="float: left; width: 85%; text-align: left">
                Downloaded: {{ downloadedFiles }}/{{ totalFiles }}<br />
                Cancelled: {{ failedFiles }}/{{ totalFiles }}
            </div>
            <div>
                <button
                    style="width: 95px"
                    *ngIf="downloadedFiles + failedFiles >= totalFiles"
                    nz-button
                    nzType="default"
                    (click)="handleClose()"
                >
                    Close
                </button>
                <button
                    style="width: 95px"
                    *ngIf="downloadedFiles + failedFiles < totalFiles"
                    nz-button
                    nzType="primary"
                    (click)="handleCancel()"
                >
                    Cancel
                </button>
            </div>
        </div>
    </nz-modal>
</div>
