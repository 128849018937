import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppState } from 'src/app/store/state';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { Report } from 'src/app/store/models/report.model';
import _orderBy from 'lodash/orderBy';
import {
    ReportService,
    ReportFilter,
} from '../../../core/services/report/report.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import _get from 'lodash/get';
import { ReportGroup } from 'src/app/store/models/report-group.model';
import {
    ReportGroupFilter,
    ReportGroupService,
} from 'src/app/core/services/report-group/report-group.service';
import { User } from 'src/app/store/models/user.model';
import { selectAuthUser } from 'src/app/store/selectors/auth.selectors';
import { CoreService } from 'src/app/core/services/core/core.service';
import _last from 'lodash/last';

const PAGE_SIZE = 100;

@Component({
    selector: 'app-reports-page',
    templateUrl: './reports-page.component.html',
    styleUrls: ['./reports-page.component.less'],
})
export class ReportsPageComponent implements OnDestroy, OnInit {
    reports: Report[];
    reportsLoading: boolean = true;
    nonFilteredReports: Report[] = [];

    reportGroups: ReportGroup[] = [];
    reportGroupsLoading: boolean = false;
    selectedGroups: string[] = [];

    filterForm: Subject<ReportFilter> = new Subject();
    user$: Observable<User>;

    reportsLoaded: boolean = false;

    clientId: string;
    searchFilter = {
        searchText: '',
        lastReportId: '',
        selectedReportId: '',
        columnName: 'report_name',
        sortDirection: 'ascend',
    };

    columns = [
        {
            title: 'Report name',
            width: '48%',
            sortDirections: ['descend', 'ascend', null],
        },
    ];

    ngDestroyed$ = new Subject();

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    constructor(
        private store: Store<AppState>,
        private coreService: CoreService,
        private reportGroupService: ReportGroupService,
        private report: ReportService
    ) {
        this.user$ = this.store.select(selectAuthUser);
    }

    ngOnInit() {
        this.coreService
            .getProfile()
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((profile) => {
                const clientId = _get(profile, ['payload', 'clientId']);
                this.clientId = clientId;
                this.reportGroupsLoading = true;
                this.reportGroupService
                    .getReportGroups(clientId, null, 1000)
                    .pipe(takeUntil(this.ngDestroyed$))
                    .subscribe({
                        next: (payload) => {
                            this.reportGroups = _orderBy(_get(payload, 'payload', []), item => item.groupName.toLowerCase(), ['asc']);
                            this.reportGroupsLoading = false;
                        },
                        complete: () => {
                            this.reportGroupsLoading = false;
                        },
                    });
            });

        this.reportsLoading = true;
        this.report
            .getReports({ limit: PAGE_SIZE })
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    this.reports = _get(payload, 'payload');
                },
                complete: () => {
                    this.reportsLoading = false;
                },
            });

        this.filterForm
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((filter) => {
                this.fetchReports(filter);
            });
    }

    private fetchReports(filter?: ReportFilter) {
        if (!this.reportsLoaded) {
            this.reportsLoading = true;

            this.report
                .getReports({ ...(filter ?? {}), limit: PAGE_SIZE })
                .pipe(takeUntil(this.ngDestroyed$))
                .subscribe({
                    next: (payload) => {
                        const reps = _get(payload, 'payload');
                        if (reps.length < PAGE_SIZE) {
                            this.reportsLoaded = true;
                        }
                        if (filter && filter.lastReportId) {
                            if (reps.length) {
                                this.reports = [...this.reports, ...reps];
                            }
                        } else {
                            this.reports = reps;
                        }
                    },
                    complete: () => (this.reportsLoading = false),
                });
        }
    }
    onSearch(value: string) {
        this.reportsLoaded = false;
        this.reportsLoading = true;
        this.searchFilter.searchText = value;
        this.filterForm.next({
            searchText: this.searchFilter.searchText,
            selectedGroups: this.selectedGroups,
            lastReportId: this.searchFilter.lastReportId,
            selectedReportId: this.searchFilter.selectedReportId,
        });
    }

    onFilterGroupsSelected(groupIds: string[]) {
        this.reportsLoaded = false;
        this.reportsLoading = true;
        this.selectedGroups = groupIds;
        this.filterForm.next({
            searchText: this.searchFilter.searchText,
            selectedGroups: groupIds,
            lastReportId: this.searchFilter.lastReportId,
            selectedReportId: this.searchFilter.selectedReportId,
        });
    }

    onTableScrolled() {
        if (!this.reportsLoaded) {
            const rep: Report = _last(this.reports);
            if (rep && rep.reportId) {
                const { reportId, reportName } = rep;
                this.filterForm.next({
                    searchText: this.searchFilter.searchText,
                    columnName: this.searchFilter.columnName,
                    sortDirection: this.searchFilter.sortDirection,
                    selectedGroups: this.selectedGroups,
                    lastReportId: reportId,
                    lastReportName: reportName,
                });
            }
        }
    }
    orderChange(col: any) {
        this.reportsLoaded = false;
        this.reportsLoading = true;
        this.searchFilter.columnName = col.key;
        this.searchFilter.sortDirection = col.value;
        this.filterForm.next({
            searchText: this.searchFilter.searchText,
            columnName: this.searchFilter.columnName,
            sortDirection: this.searchFilter.sortDirection,
        });
    }
}
