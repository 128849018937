<div class="lca-sandbox-container">
    <ng-container *ngIf="loading || sandboxStatusLoading; else content">
        <div class="loading-container">
            <nz-spin nzSimple [nzSize]="'large'" nzTip="Loading"></nz-spin>
        </div>
    </ng-container>
    <ng-template #content>
        <ng-container *ngIf="sandboxError; else sandbox">
            <div class="not-available-banner">
                <nz-alert
                    nzBanner
                    nzType="error"
                    [nzMessage]="sandboxError"
                ></nz-alert>
            </div>
        </ng-container>
        <ng-template #sandbox>
            <div class="lca-sandbox-controls">
                <h5
                    *ngIf="reportLoaded && currentStepIndex === steps.Report"
                    class="lca-sandbox-controls__report-name"
                >
                    {{ reportName }}
                </h5>
                <div class="lca-sandbox-controls__wrapper">
                    <!-- <button *ngIf="currentStepIndex === steps.Sandbox" (click)="onModifySPA()" nz-button nzType="primary">Modify SPA</button> -->
                </div>
            </div>
            <nz-steps
                *ngIf="![steps.Sandbox, steps.Report].includes(currentStepIndex)"
                nzProgressDot
                [nzCurrent]="currentStepIndex"
            >
                <nz-step nzDisabled nzTitle="Model Data"> </nz-step>
                <nz-step nzDisabled nzTitle="Impact Categories"></nz-step>
                <nz-step nzDisabled nzTitle="SPA ActivitySerials"></nz-step>
                <nz-step nzDisabled nzTitle="Sandbox"></nz-step>
            </nz-steps>
            <!-- Model Data step -->
            <div
                *ngIf="currentStepIndex === steps.ModelData"
                class="lca-sandbox-data-model-step"
            >
                <nz-alert [style.marginTop.rem]="1" *ngIf="sandboxInitError" nzType="error" [nzMessage]="sandboxInitError" nzShowIcon></nz-alert>
                <h4 class="lca-sandbox-header">Model Data</h4>
                <h5 class="lca-sandbox-subheader">
                    Please select up to 3 models to run
                </h5>
                <form
                    class="sandbox-models-form"
                    [formGroup]="modelsForm"
                    name="sandbox-data-table-form"
                >
                    <nz-table [nzSize]="'middle'" nzHideOnSinglePage>
                        <thead>
                            <tr>
                                <th nzWidth="19.25%">Model name</th>
                                <th nzWidth="19.25%">
                                    Additional Supply Chain Tiers
                                </th>
                                <th nzWidth="10%">TechFlow Disaggregation</th>
                                <th nzWidth="10%">ElemFlow Disaggregation</th>
                                <th nzWidth="13.75%">Load from file</th>
                                <th nzWidth="13.75%">Load from database</th>
                                <th nzWidth="4%"></th>
                            </tr>
                        </thead>
                        <tbody formArrayName="models">
                            <tr
                                *ngFor="
                                    let modelForm of models.controls;
                                    let i = index
                                "
                                [formGroup]="modelForm"
                            >
                                <ng-container *ngIf="!modelForm.value?.deleted">
                                    <td>
                                        <nz-form-item>
                                            <nz-form-control
                                                [class.disabled]="
                                                    !!getModelLoadStatus(i)
                                                "
                                                nzValidateStatus
                                                nzHasFeedback
                                                [nzErrorTip]="
                                                    duplicatedNameErrorTmpl
                                                "
                                            >
                                                <nz-input-group nzSize="large">
                                                    <input
                                                        formControlName="modelName"
                                                        nz-input
                                                        placeholder="Enter Model Name"
                                                    />
                                                </nz-input-group>
                                                <ng-template
                                                    #duplicatedNameErrorTmpl
                                                    let-control
                                                >
                                                    <ng-container
                                                        *ngIf="
                                                            control.hasError(
                                                                'duplicatedName'
                                                            )
                                                        "
                                                    >
                                                        Duplicated model name
                                                    </ng-container>
                                                </ng-template>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </td>
                                    <td>
                                        <nz-form-item>
                                            <nz-form-control
                                                nzValidateStatus
                                                nzHasFeedback
                                            >
                                                <nz-input-group
                                                    [nzSuffix]="
                                                        inputSupplyInfoSuffixTemplate
                                                    "
                                                    nzSize="large"
                                                >
                                                    <input
                                                        formControlName="supplyChainTier"
                                                        min="0"
                                                        max="10"
                                                        type="number"
                                                        nz-input
                                                        placeholder="Enter Whole Number 1-10"
                                                    />
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <ng-template #inputSupplyInfoSuffixTemplate>
                                            <span
                                                nz-icon
                                                nz-tooltip
                                                nzTooltipTitle="Direct, Supply, and Total tiers are automatically generated, if those are the only desired tiers leave this box empty. This option will add the specified number of supply tiers along with 'Tier Other' to the results. e.g. '2' will create 3 additional tiers: 'Tier 1', 'Tier 2' and 'Tier Other'"
                                                nzType="info-circle"
                                            ></span>
                                        </ng-template>
                                    </td>
                                    <td>
                                        <nz-form-item>
                                            <nz-form-control>
                                                <ng-container>
                                                    <nz-checkbox-wrapper>
                                                        <label
                                                            formControlName="techFlowEnabled"
                                                            nz-tooltip
                                                            nzTooltipPlacement="bottom"
                                                            nzTooltipTitle="Warning: This option should only be checked when providing 5 or less ActivitySerials for review. This option will significantly increase runtimes and data weight."
                                                            nzTooltipOverlayClassName="primary-tooltip"
                                                            nz-checkbox
                                                        ></label>
                                                    </nz-checkbox-wrapper>
                                                </ng-container>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </td>
                                    <td>
                                        <nz-form-item>
                                            <nz-form-control>
                                                <ng-container>
                                                    <nz-checkbox-wrapper>
                                                        <label
                                                            nz-tooltip
                                                            nzTooltipPlacement="bottom"
                                                            nzTooltipTitle="Warning: This option should only be checked when providing 5 or less ActivitySerials for review. This option will significantly increase runtimes and data weight."
                                                            nzTooltipOverlayClassName="primary-tooltip"
                                                            formControlName="elemFlowEnabled"
                                                            nz-checkbox
                                                        ></label>
                                                    </nz-checkbox-wrapper>
                                                </ng-container>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </td>
                                    <td>
                                        <ng-container
                                            *ngIf="
                                                getModelLoadStatus(i)?.type ===
                                                    loadTypes.CSV;
                                                else loadCSVButtonTmpl
                                            "
                                        >
                                            <div class="loaded-text">
                                                <i nz-icon nzType="done"></i>
                                                Loaded from .CSVs
                                            </div>
                                        </ng-container>
                                        <ng-template #loadCSVButtonTmpl>
                                            <button
                                                *ngIf="!getModelLoadStatus(i)"
                                                [disabled]="!modelForm.valid"
                                                (click)="loadModelDataCSV(i)"
                                                nz-button
                                                nzType="primary"
                                            >
                                                Load .CSVs
                                            </button>
                                        </ng-template>
                                    </td>
                                    <td>
                                        <ng-container
                                            *ngIf="
                                                getModelLoadStatus(i)?.type ===
                                                    loadTypes.Database;
                                                else loadDBButtonTmpl
                                            "
                                        >
                                            <div class="loaded-text">
                                                <i nz-icon nzType="done"></i>
                                                {{ getModelLoadStatus(i)?.result }}
                                            </div>
                                        </ng-container>
                                        <ng-template #loadDBButtonTmpl>
                                            <button
                                                *ngIf="!getModelLoadStatus(i)"
                                                [disabled]="!modelForm.valid"
                                                (click)="loadModelDataDatabase(i)"
                                                nz-button
                                                nzType="primary"
                                            >
                                                Load from DB
                                            </button>
                                        </ng-template>
                                    </td>
                                    <td class="actions">
                                        <i
                                            *ngIf="
                                                modelsForm.get('models').value
                                                    ?.length > 0
                                            "
                                            nz-icon
                                            class="action-icon delete"
                                            nzType="delete"
                                            nz-tooltip
                                            nzTooltipPlacement="top"
                                            nzTooltipTitle="Delete"
                                            nzTooltipOverlayClassName="danger-tooltip"
                                            nzTheme="outline"
                                            (click)="onDeleteDatamodel(i)"
                                        ></i>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </nz-table>
                    <button
                        [disabled]="modelsForm.get('models').value?.length === 3"
                        (click)="onAddModelInput()"
                        nz-button
                        nzSize="small"
                        nzType="link"
                    >
                        <i nz-icon nzType="plus" nzTheme="outline"></i>
                        Add model
                    </button>
                </form>
                <nz-drawer
                    [nzMaskClosable]="false"
                    [nzWidth]="720"
                    [nzVisible]="modelDataCSVFormMode"
                    nzTitle="Model Data from File"
                >
                    <ng-container *nzDrawerContent>
                        <app-lca-model-data-csv-form
                            *ngIf="modelDataCSVFormMode"
                            [model]="selectedModel"
                            (closed)="closeModelDataCSVFormModel($event)"
                        ></app-lca-model-data-csv-form>
                    </ng-container>
                </nz-drawer>
                <nz-drawer
                    nzMaskClosable
                    [nzWidth]="700"
                    [nzVisible]="modelDataDatabaseFormMode"
                    nzTitle="Model Data from Database"
                    (nzOnClose)="closeModelDataDatabaseFormModel($event)"
                >
                    <ng-container *nzDrawerContent>
                        <app-lca-model-data-database-form
                            *ngIf="modelDataDatabaseFormMode"
                            [model]="selectedModel"
                            (closed)="closeModelDataDatabaseFormModel($event)"
                        >
                        </app-lca-model-data-database-form>
                    </ng-container>
                </nz-drawer>
            </div>
            <!-- Impact Categories step -->
            <div
                *ngIf="currentStepIndex === steps.ImpactCategories"
                class="lca-sandbox-impact-categories-step"
            >
                <app-lca-impact-categories-selection-form
                    (changed)="onImpactCategoriesChanged($event)"
                ></app-lca-impact-categories-selection-form>
            </div>
            <!-- SPA ActivitySerials step -->
            <div
                *ngIf="currentStepIndex === steps.SPAActivitySerials"
                class="lca-sandbox-spa-activity-serials-step"
            >
                <h4 class="lca-sandbox-header">SPA ActivitySerials</h4>
                <h5 class="lca-sandbox-subheader">
                    Please select ActivitySerials for SPA analysis (Maximum of 5
                    between all models)
                </h5>
                <div class="lca-spa-serials-filter">
                    <app-text-search-input
                        (search)="onSearchSPASerials($event)"
                    ></app-text-search-input>
                    <nz-switch
                        [(ngModel)]="showSelectedSPASerials"
                        (ngModelChange)="onShowSelectedSPASerialsChanged()"
                        nzCheckedChildren="Show all"
                        nzUnCheckedChildren="Show selected"
                    ></nz-switch>
                    <p class="selected-items-text">
                        {{ setOfSpaSerialsChecked.size }} selected
                    </p>
                </div>
                <nz-table
                    #table
                    [nzLoading]="spaSerialsLoading"
                    [nzSize]="'middle'"
                    nzShowPagination="false"
                    nzFrontPagination="false"
                    nzHideOnSinglePage
                    [nzData]="spaSerials"
                >
                    <thead>
                        <tr>
                            <th
                                nzWidth="5%"
                                [nzChecked]="spaSerialsAllChecked"
                                [nzIndeterminate]="spaSerialsIndeterminate"
                                (nzCheckedChange)="onSPASerialsAllChecked($event)"
                            ></th>
                            <th nzWidth="21%">model name</th>
                            <th class="th-number" nzWidth="20%">activityserial</th>
                            <th nzWidth="54%">activity name</th>
                        </tr>
                    </thead>
                </nz-table>
                <cdk-virtual-scroll-viewport itemSize="50">
                    <nz-table
                        #table
                        [nzLoading]="spaSerialsLoading"
                        [nzSize]="'middle'"
                        nzShowPagination="false"
                        nzFrontPagination="false"
                        nzHideOnSinglePage
                        [nzData]="spaSerials"
                    >
                        <thead>
                            <tr [style.display]="'none'">
                                <th nzWidth="5%"></th>
                                <th nzWidth="21%"></th>
                                <th class="th-number" nzWidth="20%"></th>
                                <th nzWidth="54%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *cdkVirtualFor="
                                    let item of table.data;
                                    let i = index
                                "
                            >
                                <td
                                    [nzChecked]="setOfSpaSerialsChecked.has(item)"
                                    (nzCheckedChange)="
                                        onSPASerialChecked(item, $event)
                                    "
                                ></td>
                                <td
                                    [innerHTML]="
                                        item.modelName
                                            | highlight: spaSerialsSearchText
                                            | safe: 'html'
                                    "
                                ></td>
                                <td
                                    class="td-number"
                                    [innerHTML]="
                                        item.activitySerial
                                            | highlight: spaSerialsSearchText
                                            | safe: 'html'
                                    "
                                ></td>
                                <td
                                    [innerHTML]="
                                        item.activityName
                                            | highlight: spaSerialsSearchText
                                            | safe: 'html'
                                    "
                                ></td>
                            </tr>
                        </tbody>
                    </nz-table>
                </cdk-virtual-scroll-viewport>
                <h4 style="margin-top: 2rem" class="lca-sandbox-header">
                    SPA Threshold Parameters
                </h4>
                <h5 class="lca-sandbox-subheader">Please fill out all boxes</h5>
                <form
                    class="lca-sandbox-spa-threshold-parameters__form"
                    name="lca-sandbox-spa-threshold-parameters-form"
                    [formGroup]="spaThresholdParamsFormGroup"
                    action="none"
                >
                    <div nz-row nzGutter="4">
                        <div class="spa-parameters-form__input" nz-col nzSpan="12">
                            <nz-form-item>
                                <nz-form-label>
                                    spa life cycle stage to render (default value is
                                    8)</nz-form-label
                                >
                                <nz-form-control nzValidateStatus nzHasFeedback>
                                    <nz-input-group nzSize="large">
                                        <input
                                            formControlName="spaLifeCycleStage"
                                            min="1"
                                            max="19"
                                            step="1"
                                            type="number"
                                            nz-input
                                            placeholder="Enter whole number between 1-19"
                                        />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div nz-row nzGutter="8">
                        <div
                            *ngFor="
                                let impactCategoryCheckedKey of impactCategoriesCheckedKeys
                            "
                            class="spa-parameters-form__input"
                            nz-col
                            nzSpan="12"
                        >
                            <nz-form-item>
                                <nz-form-label>
                                    spa sensitivity threshold -
                                    {{ impactCategoryCheckedKey }} -
                                    {{
                                        getImpactCategoryNameBySerial(
                                            impactCategoryCheckedKey
                                        )
                                    }}
                                    - (default value is 0.5)</nz-form-label
                                >
                                <nz-form-control nzValidateStatus nzHasFeedback>
                                    <nz-input-group
                                        nzSize="large"
                                        [nzPrefix]="(spaThresholdParamsFormGroup.get('spaThreshold#' +
                                        impactCategoryCheckedKey)?.value ?? 0.5) < 0.01 ? prefixSpaSensitivity : undefined"

                                [nzStatus]="(spaThresholdParamsFormGroup.get('spaThreshold#' +
                                        impactCategoryCheckedKey)?.value ?? 0.5) < 0.01 ? 'warning' : undefined"
                                    >
                                        <input
                                            [formControlName]="
                                                'spaThreshold#' +
                                                impactCategoryCheckedKey
                                            "
                                            min="0"
                                            max="1"
                                            step="0.01"
                                            type="number"
                                            nz-input
                                            placeholder="Enter decimal between 0-1"
                                        />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                </form>
            </div>
            <!-- Sandbox step -->
            <div
                *ngIf="
                    currentStepIndex === steps.Sandbox ||
                    currentStepIndex === steps.Report
                "
                class="lca-sandbox-sandbox-step"
            >
                <div class="logs" *ngIf="currentStepIndex === steps.Sandbox">
                    <div
                        class="waiting"
                        *ngIf="
                            [
                                'LCA_SANDBOX_RUNNING',
                                'LCA_SANDBOX_WAITING_VM',
                                'LCA_SANDBOX_CREATED',
                                'LCA_SANDBOX_ASSESSMENT_COMPLETE'
                            ].includes(status)
                        "
                    >
                        <div class="gears-container">
                            <div class="gear-rotate"></div>
                            <div class="gear-rotate-left"></div>
                        </div>
                        <div class="logs-status">
                            <h5>
                                Your LCA data is being processed, please wait...
                            </h5>
                            <p>
                                You may navigate away from this page while
                                processing. The page will update when complete.
                            </p>
                        </div>
                    </div>
                    <div class="failed" *ngIf="status === 'LCA_SANDBOX_RUN_FAILED'">
                        <i
                            style="font-size: 6rem; margin-bottom: 2rem"
                            nz-icon
                            nzType="warning"
                            [nzTheme]="'twotone'"
                            [nzTwotoneColor]="'#d63c24'"
                        ></i>
                        <div class="logs-status">
                            <h5>
                                An error occurred while processing your LCA data,
                                please try again.
                            </h5>
                            <p>
                                If the problem persists please contact your IT
                                administrator.
                            </p>
                            <div
                                *ngIf="reportFailedMsg"
                                class="failed-msg"
                                [innerHTML]="reportFailedMsg | safe: 'html'"
                            ></div>
                        </div>
                    </div>
                    <div
                        class="success"
                        *ngIf="status === 'LCA_SANDBOX_REPORT_READY'"
                    >
                        <i
                            style="font-size: 6rem; margin-bottom: 2rem"
                            nz-icon
                            nzType="check-circle"
                            [nzTheme]="'twotone'"
                            [nzTwotoneColor]="'#52c41a'"
                        ></i>
                        <div class="logs-status">
                            <h5>Your LCA data was successfully processed.</h5>
                            <p>Please load the results report.</p>
                        </div>
                    </div>
                </div>
                <div
                    class="report"
                    #reportContainer
                    *ngIf="currentStepIndex === steps.Report"
                >
                    <nz-spin nzSimple *ngIf="!loadedPowerBiReport"></nz-spin>
                </div>
            </div>
            <div class="lca-sandbox-stepper-actions">
                <button
                    *ngIf="currentStepIndex > 0 && currentStepIndex < steps.Sandbox"
                    (click)="onPreviousStep()"
                    [disabled]="isPreviousDisabled"
                    nz-button
                >
                    Previous
                </button>
                <button
                    *ngIf="currentStepIndex < steps.Sandbox"
                    (click)="onNextStep()"
                    [nzLoading]="isNextLoading"
                    [disabled]="isNextDisabled"
                    nz-button
                    nzType="primary"
                >
                    {{ currentStepIndex === 2 ? 'Run Sandbox' : 'Next' }}
                </button>
                @if (currentStepIndex === 2) {
                    <button
                        *hasPermission="['NEW_LCA_ENGINE']"
                        (click)="setSPAActivitiesConfig(true)"
                        [nzLoading]="isNextLoading"
                        [disabled]="isNextDisabled"
                        nz-button
                        nzDanger
                        nzType="primary"
                    >
                        Run Sandbox (NEW ENGINE)
                    </button>
                }
                <button
                    *ngIf="currentStepIndex === steps.Sandbox"
                    nz-button
                    [disabled]="status !== statuses.Failed"
                    (click)="rerunLCASandbox()"
                >
                    Start Over Sandbox
                </button>
                <button
                    *ngIf="currentStepIndex === steps.Sandbox"
                    [disabled]="!reportLoaded"
                    (click)="onLoadPowerBIReport()"
                    nz-button
                    nzType="primary"
                >
                    Load Results Report
                </button>
                <button
                    *ngIf="currentStepIndex === steps.Report"
                    (click)="rerunLCASandbox()"
                    nz-button
                    nzType="primary"
                >
                    Start Over Sandbox
                </button>
            </div>
            <nz-drawer
                nzMaskClosable
                [nzWidth]="720"
                [nzVisible]="modifySPAFormMode"
                nzTitle="Modify SPA Parameters"
                (nzOnClose)="closeModifySPA()"
            >
                <ng-container *nzDrawerContent>
                    <app-lca-modify-spa-form
                        *ngIf="modifySPAFormMode"
                        (closed)="closeModifySPA()"
                    >
                    </app-lca-modify-spa-form>
                </ng-container>
            </nz-drawer>
        </ng-template>
    </ng-template>
</div>

<ng-template #prefixSpaSensitivity>
    <i nz-icon nzType="warning" nzTheme="outline" nz-tooltip nzTooltipPlacement="top" nzTooltipTitle="Analysis could take a very long time."></i>
</ng-template>