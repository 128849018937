<div class="report-groups-container">
    <div nz-flex nzAlign="center" nzJustify="space-between" class="report-groups__controls">
        <div class="report-groups__search">
            <app-text-search-input
                (search)="onSearch($event)"
            ></app-text-search-input>
        </div>
        <button
            data-cy="add-report-group__control"
            *hasPermission="['REPORT_MGMT']"
            type="button"
            nz-button
            (click)="onCreateReportGroup()"
            nzType="primary"
        >
            <i nz-icon nzType="plus" nzTheme="outline"></i>
            <span>Create report group</span>
        </button>
    </div>
    @if (reportGroupsLoading) {
        <div class="loading">
            <nz-spin nzTip="Loading..." nzSimple></nz-spin>
        </div>
    } @else {
        <div class="report-groups__list">
            <ng-container *ngIf="reportGroups?.length">
                <app-report-group-item
                    (edit)="onEditReportGroup($event)"
                    (remove)="onDeleteReportGroup($event)"
                    *ngFor="let group of reportGroups"
                    [reportGroup]="group"
                    [highlightQuery]="query"
                ></app-report-group-item>
            </ng-container>
            <div class="report-groups-list__empty" *ngIf="!reportGroups?.length">
                <nz-divider></nz-divider>
                <nz-list [nzDataSource]="[]" [nzLoading]="reportGroupsLoading"></nz-list>
                <nz-divider></nz-divider>
            </div>
        </div>
    }
    <nz-drawer
        nzMaskClosable
        [nzWidth]="360"
        [nzVisible]="reportGroupFormMode"
        [nzTitle]="
            reportGroup ? 'Edit report group' : 'Create new report group'
        "
        (nzOnClose)="closeReportGroupFormMode()"
    >
        <ng-container *nzDrawerContent>
            <app-report-group-form
                *ngIf="reportGroupFormMode"
                [reportGroup]="reportGroup"
                (closed)="closeReportGroupFormMode($event)"
                [clientId]="selectedClientId"
            ></app-report-group-form>
        </ng-container>
    </nz-drawer>
</div>
