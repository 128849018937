import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Role } from '../../../store/models/role.model';
import _get from 'lodash/get';
import _find from 'lodash/find';
import { CoreService, RolesFilter } from '../../../core/services/core/core.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import _orderBy from 'lodash/orderBy';
import _last from 'lodash/last';
import { TextSearchInputComponent } from 'src/app/core/components/text-search-input/text-search-input.component';
import { GlobalService } from 'src/app/core/services/global/global.service';
import { Router } from '@angular/router';

const PAGE_SIZE = 100;

@Component({
    selector: 'app-roles-page',
    templateUrl: './roles-page.component.html',
    styleUrls: ['./roles-page.component.less'],
})
export class RolesPageComponent implements OnInit, OnDestroy {
    rolesLoading: boolean = true;
    roles: Role[];
    nonFilteredRoles: Role[];
    rolesLoaded: boolean = false;

    selectedRole: Role;
    roleFormMode: boolean = false;

    selectedClientId?: string;
    clientId$: BehaviorSubject<string> = new BehaviorSubject(undefined);
    filterForm: Subject<RolesFilter> = new Subject();
    query: string;

    @ViewChild(TextSearchInputComponent) child: TextSearchInputComponent;
    
    searchFilter = {
        searchText: '',
        lastRoleId: '',
        columnName: 'role_name',
        sortDirection: 'ascend',
    };

    columns = [
        {
            title: 'Role name',
            key: 'role_name',
            width: '70%',
            dataCy: 'roles-table__name-sort',
            sortDirections: ['descend', 'ascend', null],
        },
    ];

    ngDestroyed$ = new Subject();
    constructor(
        private modal: NzModalService,
        private message: NzMessageService,
        private coreService: CoreService,
        private globalService: GlobalService,
        private router: Router
    ) {}
    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    ngOnInit() {
        this.filterForm
            .pipe(takeUntil(this.ngDestroyed$), debounceTime(400))
            .subscribe((filter) => {
                this.fetchRoles(filter);
            });

        
        this.globalService.clientId$.pipe(takeUntil(this.ngDestroyed$))
            .subscribe(
                clientId => {
                    if (clientId) {
                        this.onClientSelected(clientId);
                    }
                }
            )
    }

    onClientSelected(clientId: string) {
        this.rolesLoaded = false;
        this.selectedClientId = clientId;
        this.fetchRoles(this.searchFilter);
    }

    private fetchRoles(filter?: RolesFilter) {
        this.rolesLoading = true;

        this.coreService
            .getRoles(this.selectedClientId, filter ? {...filter, limit: PAGE_SIZE} : { limit: PAGE_SIZE })
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    const roles = _get(payload, 'payload', []);
                    if (roles.length < PAGE_SIZE) {
                        this.rolesLoaded = true;
                    }
                    if (filter && filter.lastRoleId) {
                        if (roles.length) {
                            this.roles = [...this.roles, ...roles];
                        }
                    } else {
                        this.roles = roles;
                    }
                },
                error: () => {
                    this.message.error('Failed to fetch roles');
                    this.rolesLoading = false;
                },
                complete: () => {
                    this.rolesLoading = false;
                    this.query = this.searchFilter.searchText;
                },
            });
    }

    onCreateRole() {
        this.roleFormMode = true;
    }

    onEditRole(roleId: string) {
        this.selectedRole = _find(this.roles, { roleId });
        this.roleFormMode = true;
    }

    onRemoveRole(roleId: string, roleName: string) {
        this.modal.confirm({
            nzTitle: `Do you want to remove role <b>${roleName}</b>?`,
            nzContent: `
        If you delete it you will not be able to recover role.<br>
        Do you want to delete the role?
      `,
            nzOnOk: () =>
                this.coreService.deleteRole(roleId).subscribe(() => {
                    this.message.success(
                        `Role <b>${roleName}</b> has been deleted!`,
                        { nzDuration: 3000 }
                    );
                    this.filterForm.next({
                        searchText: this.searchFilter.searchText,
                        columnName: this.searchFilter.columnName,
                        sortDirection: this.searchFilter.sortDirection,
                    });
                }),
            nzOkText: 'Yes, remove',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzCancelText: 'Close',
            nzClosable: false,
            nzOnCancel: () => {},
        });
    }

    onSearch(value: string) {
        this.rolesLoaded = false;
        this.rolesLoading = true;
        this.searchFilter.searchText = value;

        this.filterForm.next({
            searchText: value,
            columnName: this.searchFilter.columnName,
            sortDirection: this.searchFilter.sortDirection,
        });
    }

    onCloseDrawer(withReload?: boolean) {
        this.roleFormMode = false;
        this.selectedRole = null;
        this.rolesLoaded = false;
        if (withReload) {
            this.child.onClear();
            this.fetchRoles();
        } else {
            this.filterForm.next({
                searchText: this.searchFilter.searchText,
                columnName: this.searchFilter.columnName,
                sortDirection: this.searchFilter.sortDirection,
            });
        }
    }
    onTableScrolled() {
        if (this.rolesLoaded === false && this.roles.length >= PAGE_SIZE) {
            const role: Role = _last(this.roles);
            if (role && role.roleId) {
                const { roleId, roleName } = role;
                this.filterForm.next({
                    searchText: this.searchFilter.searchText,
                    lastRoleId: roleId,
                    lastRoleName: roleName,
                    columnName: this.searchFilter.columnName,
                    sortDirection: this.searchFilter.sortDirection,
                });
            }
        }
    }

    orderChange(col: any) {
        this.rolesLoaded = false;
        this.rolesLoading = true;
        this.searchFilter.columnName = col.key;
        this.searchFilter.sortDirection = col.value;
        this.filterForm.next({
            searchText: this.searchFilter.searchText,
            columnName: this.searchFilter.columnName,
            sortDirection: this.searchFilter.sortDirection,
        });
    }
}
