<div class="portal-types-container">
    <div nz-flex nzAlign="center" nzJustify="space-between" class="portal-types__controls">
        <div class="portal-types__search">
            <app-text-search-input
                (search)="onSearch($event)"
            ></app-text-search-input>
        </div>
        <div nz-flex nzAlign="center" nzGap="small">
            <ng-container *hasPermission="['UPLOAD_PORTAL_MGMT']">
                <button
                    *ngIf="selectedTabIndex === 0" 
                    type="button"
                    nz-button
                    (click)="onCreateNewPortal()"
                    nzType="primary"
                >
                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                    <span>Create new portal</span>
                </button>
                <button
                    *ngIf="selectedTabIndex === 1"
                    type="button"
                    nz-button
                    (click)="onCreateNewType()"
                    nzType="primary"
                >
                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                    <span>Create new portal type</span>
                </button>
               
                <button
                    *ngIf="selectedTabIndex === 2"
                    type="button"
                    nz-button
                    (click)="onCreateNewStatus()"
                    nzType="primary"
                >
                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                    <span>Create new status</span>
                </button>
            </ng-container>
        </div>
    </div>
    <nz-tabset (nzSelectedIndexChange)="onSelectedTabChanged($event)">
        <nz-tab nzTitle="Upload Portals">
            <ng-template nz-tab *ngIf="selectedTabIndex === 0">
                <app-portals-table [searchText]="searchText"></app-portals-table>
            </ng-template>
        </nz-tab>
        <nz-tab nzTitle="Upload Portal Types">
            <ng-template nz-tab>
                <app-portal-types-table [searchText]="searchText"></app-portal-types-table>
            </ng-template>
        </nz-tab>
        <nz-tab nzTitle="Upload Portal Statuses">
            <ng-template nz-tab *ngIf="selectedTabIndex === 2">
                <app-portal-statuses-table [searchText]="searchText"></app-portal-statuses-table>
            </ng-template>
        </nz-tab>
    </nz-tabset>
</div>
