<form
    *hasPermission="['HELP_MGMT', 'HELP_MGMT_VIEW']; class: 'disabled'"
    nz-form
    [formGroup]="sheetForm"
>
    <nz-spin [nzSpinning]="isSpinning" [nzDelay]="500">
        <div nz-row nzGutter="24">
            <div nz-col nzSpan="24">
                <nz-alert
                    *ngIf="payload.error"
                    nzType="error"
                    [nzMessage]="payload.error"
                    nzShowIcon
                ></nz-alert>
                <nz-form-item>
                    <nz-form-label nzRequired>Sheet Number</nz-form-label>
                    <nz-form-control
                        [nzSpan]="24"
                        nzValidateStatus
                        nzHasFeedback
                        [nzErrorTip]="sheetNumberErrorTmpl"
                    >
                        <nz-input-group nzSize="large">
                            <nz-input-number
                                nzMin="0"
                                formControlName="sheetNumber"
                                *hasPermission="
                                    ['HELP_MGMT'];
                                    class: 'disabled'
                                "
                                nz-input
                                placeholder="Enter sheet number"
                            ></nz-input-number>
                        </nz-input-group>
                        <ng-template #sheetNumberErrorTmpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please enter sheet number
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzRequired>Name</nz-form-label>
                    <nz-form-control
                        [nzSpan]="24"
                        nzValidateStatus
                        nzHasFeedback
                        [nzErrorTip]="sheetNameErrorTmpl"
                    >
                        <nz-input-group nzSize="large">
                            <input
                                formControlName="sheetName"
                                *hasPermission="
                                    ['HELP_MGMT'];
                                    class: 'disabled'
                                "
                                nz-input
                                placeholder="Enter name"
                                maxlength="255"
                            />
                        </nz-input-group>
                        <ng-template #sheetNameErrorTmpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please enter name
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label>Description</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <nz-input-group nzSize="large">
                            <textarea
                                formControlName="description"
                                rows="8"
                                *hasPermission="
                                    ['HELP_MGMT'];
                                    class: 'disabled'
                                "
                                style="min-height: 12rem"
                                nz-input
                                placeholder="Enter description"
                                maxlength="2048"
                            ></textarea>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label>Enable Edit</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <nz-switch
                            formControlName="readWrite"
                            *hasPermission="['HELP_MGMT']; class: 'disabled'"
                        ></nz-switch>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <nz-form-item>
            <nz-form-control class="drawer-actions-control" [nzSpan]="24">
                <div class="drawer-controls">
                    <button
                        type="button"
                        nzSize="large"
                        *hasPermission="['HELP_MGMT']; class: 'disabled'"
                        nz-button
                        nzType="primary"
                        [disabled]="isNotEligibleToSubmit()"
                        (click)="saveSheet()"
                    >
                        <span>{{ action === 'add' ? 'Save' : 'Update' }}</span>
                    </button>
                    <button
                        type="button"
                        nzSize="large"
                        nz-button
                        (click)="onCancel()"
                    >
                        <span>Close</span>
                    </button>
                </div>
            </nz-form-control>
        </nz-form-item>
    </nz-spin>
</form>
