<div
    class="upload-portal-status-container"
    *ngIf="(statusId && statusName) || (statusName && statusColor)"
    nz-tooltip
    [nzTooltipTitle]="statusName"
    [style.color]="color"
    [style.backgroundColor]="bgColor"
>
    <div  [innerHTML]="statusName | highlight: query | safe: 'html'"></div>
</div>
