import { Action, createReducer, on } from '@ngrx/store';
import * as CustomerActions from '../../actions/customer/customer.actions';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _findIndex from 'lodash/findIndex';
import { Customer } from '../../models/customer.model';
import { ClientRole } from '../../models/client-role.model';

export const customerFeatureKey = 'customers';

interface EditCustomer {
    loading: boolean;
    customer: Customer;
    error: Error;
}

export interface CustomerState {
    loading: boolean;
    items: Customer[];
    error: Error;
    edit: EditCustomer;
    roles: {
        loading: boolean;
        error: Error;
        items: ClientRole[];
    };
}

export const initialState: CustomerState = {
    loading: false,
    items: null,
    error: null,
    edit: {
        loading: false,
        customer: null,
        error: null,
    },
    roles: {
        loading: false,
        error: null,
        items: [],
    },
};

const customerReducer = createReducer(
    initialState,
    on(CustomerActions.customersListRequest, (state) => ({
        ...state,
        loading: true,
    })),
    on(CustomerActions.customersListReceive, (state, { items }) => ({
        ...state,
        loading: false,
        items,
    })),
    on(CustomerActions.customersFailed, (state, { error }) => ({
        ...state,
        loading: false,
        error: _get(error, ['error', 'errors', 0], null),
    })),
    on(CustomerActions.customerRequest, (state) => ({
        ...state,
        edit: {
            ...state.edit,
            loading: true,
        },
    })),
    on(CustomerActions.customerReceive, (state, { customer }) => ({
        ...state,
        edit: {
            customer,
            loading: false,
            error: null,
        },
    })),
    on(CustomerActions.customerFailed, (state, { error }) => ({
        ...state,
        edit: {
            customer: null,
            loading: false,
            error: _get(error, ['error', 'errors', 0], null),
        },
    })),
    on(CustomerActions.createCustomerRequest, (state) => ({
        ...state,
        edit: {
            ...state.edit,
            loading: true,
        },
    })),
    on(CustomerActions.createCustomerReceive, (state) => ({
        ...state,
        edit: {
            ...state.edit,
            loading: false,
        },
    })),
    on(CustomerActions.createCustomerFailed, (state, { error }) => ({
        ...state,
        edit: {
            ...state.edit,
            error: _get(error, ['error', 'errors', 0], null),
        },
    })),
    on(CustomerActions.updateCustomerRequest, (state) => ({
        ...state,
        edit: {
            ...state.edit,
            loading: true,
        },
    })),
    on(CustomerActions.updateCustomerFailed, (state, { error }) => ({
        ...state,
        edit: {
            ...state.edit,
            loading: false,
            error: error,
        },
    })),
    // client roles
    on(CustomerActions.clientRolesRequest, (state) => ({
        ...state,
        roles: {
            ...state.roles,
            loading: true,
            error: null,
        },
    })),
    on(CustomerActions.clientRolesReceive, (state, { items }) => ({
        ...state,
        roles: {
            loading: false,
            error: null,
            items,
        },
    }))
);

export function reducer(state: CustomerState | undefined, action: Action) {
    return customerReducer(state, action);
}
