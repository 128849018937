import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
    selector: 'app-password-form',
    templateUrl: './password-form.component.html',
    styleUrls: ['./password-form.component.less'],
})
export class PasswordFormComponent implements OnInit, OnDestroy {
    passwordForm: UntypedFormGroup;

    error: string;
    patternRegex =
        /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%!\^\&\*\(\)\-_\=\+\?\/\.\>\,\<\[\]\{\}\\\|\`\~]).{8,20})/;
    _enabled: boolean;
    passwordVisible = false;
    confirmPasswordVisible = false;

    token: string;
    mode: string;

    ngDestroyed$ = new Subject();

    constructor(
        private fb: UntypedFormBuilder,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private modal: NzModalService
    ) {
        this.initForm();
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.token = params['t'];
            this.mode = params['m'];
        });
    }

    initForm() {
        this.passwordForm = this.fb.group(
            {
                password: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(8),
                        Validators.maxLength(20),
                        this.patternValidator,
                    ],
                ],
                confirmPassword: ['', []],
            },
            { validators: this.confirmValidator('password', 'confirmPassword') }
        );
    }

    confirmValidator = (controlName: string, matchingControlName: string) => {
        return (control: AbstractControl): ValidationErrors | null => {
            const input = control.get(controlName);
            const matchingInput = control.get(matchingControlName);

            if (input === null || matchingInput === null) {
                return null;
            }

            if (matchingInput?.errors && !matchingInput.errors.confirm) {
                return null;
            }

            if (input.value !== matchingInput.value) {
                matchingInput.setErrors({ confirm: true });
                return { confirm: true };
            } else {
                matchingInput.setErrors(null);
                return null;
            }
        };
    };

    patternValidator = (control: UntypedFormControl): { [s: string]: boolean } => {
        if (!control.value) {
            return { error: true, required: true };
        } else if (!this.patternRegex.test(control.value)) {
            return { pattern: true, error: true };
        }
        return {};
    };

    submitForm() {
        const password = this.passwordForm.get('password').value;

        this.userService
            .resetPassword(this.token, password)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe(
                (result) => {
                    if (!result) {
                        this.showModal(
                            'Error message',
                            'The token is invalid, you will be redirected to the AITrack login page.'
                        );
                    } else {
                        this.showModal(
                            'Success message',
                            'Your new password has been successfully set, you will be redirected to the AITrack login page.'
                        );
                    }
                },
                (_) => {
                    this.error =
                        'There was an error while processing your input.';
                }
            );
    }

    showModal(title, content) {
        this.modal.info({
            nzTitle: title,
            nzContent: content,
            nzClosable: false,
            nzOnOk: () => {
                this.router.navigateByUrl('/login');
            },
        });
    }

    @Input() set enabled(value: boolean) {
        this._enabled = value;
        if (!value) {
            this.passwordForm.disable();
        } else {
            this.passwordForm.enable();
        }
    }
    @Input() email: string;

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }
}
