<div class="attribute-types-container">
    <div nz-flex nzJustify="space-between" nzAlign="center" class="attribute-types__controls">
        <div class="attribute-types__search">
            <app-text-search-input
                (search)="onSearch($event)"
            ></app-text-search-input>
        </div>
        <button
            data-cy="attribute-type-controls__create-btn"
            *hasPermission="['ATTR_MGMT']"
            type="button"
            nz-button
            (click)="onCreateAttributeType()"
            nzType="primary"
        >
            <i nz-icon nzType="plus" nzTheme="outline"></i>
            <span>Create attribute type</span>
        </button>
    </div>
    <div class="attribute-types__table">
        <app-infinite-scroll
            [loaded]="attributeTypesLoaded"
            [loading]="attributeTypesLoading"
            (scrolled)="onTableScrolled()"
        >
            <nz-table
                #table
                [nzLoading]="attributeTypesLoading"
                [nzSize]="'middle'"
                [nzPageSize]="99999999"
                nzHideOnSinglePage
                [nzData]="attributeTypes"
            >
                <thead (nzSortOrderChange)="orderChange($event)">
                    <tr>
                        <th
                            *ngFor="let column of columns"
                            [nzSortFn]="true"
                            [nzWidth]="column.width"
                            [nzSortDirections]="column.sortDirections"
                            [attr.data-cy]="column.dataCy"
                            nzColumnKey="{{ column.key }}"
                            [class]="column.class"
                        >
                            {{ column.title }}
                        </th>
                        <th nzWidth="10%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of table.data">
                        <td
                            data-cy="attribute-type__name"
                            [innerHTML]="
                                data.attrTypeName
                                    | highlight: query
                                    | safe: 'html'
                            "
                        ></td>
                        <td
                            class="td-number"
                            data-cy="attribute-type__attr-count"
                        >
                            {{ data.attributes?.length ?? 0 }}
                        </td>
                        <td>
                            {{
                                data.createdStamp | date: 'dd MMM yyyy, h:mm a'
                            }}
                        </td>
                        <td class="actions__cell">
                            <i
                                nz-icon
                                nzType="dots"
                                nzTheme="fill"
                                *hasPermission="['ATTR_MGMT', 'ATTR_MGMT_VIEW']"
                            ></i>
                            <div
                                class="table-actions-container"
                                *hasPermission="['ATTR_MGMT', 'ATTR_MGMT_VIEW']"
                            >
                                <i
                                    data-cy="attribute-type-control__delete"
                                    *hasPermission="['ATTR_MGMT']"
                                    (click)="
                                        onRemoveAttributeType(
                                            data.attrTypeId,
                                            data.attrTypeName
                                        )
                                    "
                                    nz-tooltip
                                    nzTooltipPlacement="top"
                                    nzTooltipTitle="Delete"
                                    nzTooltipOverlayClassName="danger-tooltip"
                                    class="action-icon delete"
                                    nz-icon
                                    nzType="delete"
                                    nzTheme="outline"
                                ></i>
                                <i
                                    data-cy="attribute-type-control__edit"
                                    *hasPermission="[
                                        'ATTR_MGMT',
                                        'ATTR_MGMT_VIEW'
                                    ]"
                                    (click)="
                                        onEditAttributeType(data.attrTypeId)
                                    "
                                    nz-tooltip
                                    nzTooltipPlacement="top"
                                    nzTooltipTitle="Edit"
                                    nzTooltipOverlayClassName="primary-tooltip"
                                    class="action-icon"
                                    nz-icon
                                    nzType="edit"
                                    nzTheme="outline"
                                ></i>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </app-infinite-scroll>
    </div>
    <nz-drawer
        nzMaskClosable
        [nzWidth]="360"
        [nzVisible]="attributeTypeFormMode"
        [nzTitle]="
            selectedAttributeType
                ? 'Edit attribute type'
                : 'Create new attribute type'
        "
        (nzOnClose)="closeAttributeTypeFormMode($event)"
    >
        <ng-container *nzDrawerContent>
            <app-attribute-type-form
                *ngIf="attributeTypeFormMode"
                [attributeType]="selectedAttributeType"
                [clientId]="selectedClientId"
                (closed)="closeAttributeTypeFormMode($event)"
            ></app-attribute-type-form>
        </ng-container>
    </nz-drawer>
</div>
