<ng-container *ngIf="reportLoading; else reportFormContent">
    <div class="loading-container">
        <nz-spin
            nzTip="Loading report..."
            nzSize="'large'"
            nzSimple
        ></nz-spin>
    </div>
</ng-container>
<ng-template #reportFormContent>
    <div
        class="report-form-container"
        *hasPermission="['REPORT_MGMT']; class: 'disabled'"
    >
        <form
            class="report__form"
            nz-form
            [formGroup]="reportForm"
            (ngSubmit)="submitReportForm()"
        >
            <div nz-row nzGutter="4">
                <nz-alert
                    *ngIf="error$ | async"
                    nzType="error"
                    [nzMessage]="error$ | async"
                    nzShowIcon
                ></nz-alert>
                <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label nzRequired>Report name</nz-form-label>
                        <nz-form-control
                            [nzSpan]="24"
                            nzValidateStatus
                            nzHasFeedback
                            [nzErrorTip]="reportNameErrorTmpl"
                        >
                            <nz-input-group nzSize="large">
                                <input
                                    formControlName="reportName"
                                    nz-input
                                    placeholder="Enter Report Name"
                                />
                            </nz-input-group>
                            <ng-template #reportNameErrorTmpl let-control>
                                <ng-container *ngIf="control.hasError('required')">
                                    Please input Report name
                                </ng-container>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label nzRequired>PowerBI Report ID</nz-form-label>
                        <nz-form-control
                            [nzSpan]="24"
                            nzValidateStatus
                            nzHasFeedback
                            [nzErrorTip]="reportIDErrorTmpl"
                        >
                            <nz-input-group nzSize="large">
                                <input
                                    formControlName="pbiReportId"
                                    nz-input
                                    placeholder="Enter Report ID"
                                />
                            </nz-input-group>
                            <ng-template #reportIDErrorTmpl let-control>
                                <ng-container *ngIf="control.hasError('required')">
                                    Please input PowerBI Report ID
                                </ng-container>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                    <div class="report-form__rls-switcher">
                        <h3>RLS Enabled</h3>
                        <nz-switch formControlName="rlsEnabled"></nz-switch>
                    </div>
                    <nz-alert
                        *ngIf="testError"
                        nzType="error"
                        [nzMessage]="testError"
                        nzShowIcon
                    ></nz-alert>
                    <nz-alert
                        *ngIf="tested && !testError"
                        nzType="success"
                        nzMessage="Successful"
                        nzShowIcon
                    ></nz-alert>
                    <button
                        [disabled]="
                            !clientId ||
                            !reportForm?.value?.pbiReportId ||
                            reportLoading ||
                            testing
                        "
                        [nzLoading]="testing"
                        nz-button
                        [style.marginBottom.rem]="0.5"
                        type="button"
                        nzType="primary"
                        (click)="onTestConnection()"
                    >
                        Test Connection
                    </button>
                    <nz-skeleton
                        [nzLoading]="reportLoading"
                        [nzActive]="true"
                    >
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24"
                                >Report group</nz-form-label
                            >
                            <nz-form-control  nzValidateStatus nzHasFeedback [nzErrorTip]="reportGroupErrorTmpl"  [nzSpan]="24">
                                <nz-select
                                    nzPlaceHolder="Select report group"
                                    nzAllowClear
                                    nzShowSearch
                                    nzSize="large"
                                    [nzLoading]="reportGroupsLoading"
                                    formControlName="groupId"
                                    (ngModelChange)="onReportGroupChanged($event)"
                                >
                                    <nz-option
                                        *ngFor="let group of reportGroups"
                                        [nzValue]="group.groupId"
                                        [nzLabel]="group.groupName"
                                    ></nz-option>
                                </nz-select>
                            </nz-form-control>
                            <ng-template #reportGroupErrorTmpl let-control>
                                <ng-container *ngIf="control.hasError('required')">
                                    Please select report group
                                </ng-container>
                            </ng-template>
                        </nz-form-item>
                    </nz-skeleton>
                    @if (attributesTreeNodes?.length || attributesTreeNodesLoading) {
                        <nz-form-item>
                            <nz-form-label nzRequired>Report Access Attributes</nz-form-label>
                            <nz-form-control [nzSpan]="24" nzValidateStatus nzHasFeedback [nzErrorTip]="attributesErrorTmpl">
                                <nz-tree-select
                                    nzShowSearch
                                    style="width: 100%"
                                    nzVirtualHeight="290px"
                                    [nzNodes]="attributesTreeNodes"
                                    nzPlaceHolder="Please select attributes"
                                    formControlName="attrIds"
                                    nzCheckable
                                    [nzNotFoundContent]="noData"
                                ></nz-tree-select>
                                <ng-template #noData>
                                    <div style="height: 200px; display: flex; justify-content: center; align-items: center">
                                        <nz-spin nzSimple></nz-spin>
                                    </div>
                                </ng-template>
                            </nz-form-control>
                            <ng-template #attributesErrorTmpl let-control>
                                <ng-container *ngIf="control.hasError('required')">
                                    Please select attributes for each type
                                </ng-container>
                            </ng-template>
                        </nz-form-item>
                    }
                    <nz-form-item *ngIf="clientAttributeTypes?.length && reportForm?.value?.rlsEnabled">
                        <nz-form-label>
                            Row Level Security Attributes&nbsp;
                            <span
                                nz-icon
                                nz-tooltip
                                nzTooltipTitle="For informational purposes only. The report uses the attributes as configured in the report."
                                nzType="info-circle"
                            ></span>
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <nz-select
                                nzPlaceHolder="Select attribute type"
                                nzAllowClear
                                nzMode="multiple"
                                nzShowSearch
                                nzSize="large"
                                formControlName="rlsAttributeTypeIds"
                            >
                                <nz-option
                                    *ngFor="let attrType of clientAttributeTypes"
                                    [nzValue]="attrType.attrTypeId"
                                    [nzLabel]="attrType.attrTypeName"
                                ></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-control
                            class="drawer-actions-control"
                            [nzSpan]="24"
                        >
                            <div class="drawer-controls">
                                <button
                                    *hasPermission="['REPORT_MGMT']"
                                    type="button"
                                    nzSize="large"
                                    nz-button
                                    [disabled]="
                                        !reportForm.valid ||
                                        !reportForm.dirty ||
                                        reportLoading ||
                                        !clientId || saving
                                    "
                                    [nzLoading]="saving"
                                    (click)="submitReportForm()"
                                    nzType="primary"
                                >
                                    <span>Save</span>
                                </button>
                                <button
                                    type="button"
                                    nzSize="large"
                                    nz-button
                                    (click)="onCloseReportForm()"
                                >
                                    <span>Close</span>
                                </button>
                            </div>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </form>
    </div>
</ng-template>