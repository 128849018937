<div class="client-database-table-remove-form-container">
    <form
        class="client-database-table-remove__form"
        [formGroup]="removeTableFormGroup"
        nz-form
        (ngSubmit)="onSubmit()"
    >
        <nz-alert
            *ngIf="error"
            nzType="error"
            [nzMessage]="error"
            nzShowIcon
        ></nz-alert>
        <nz-alert
            nzType="info"
            nzMessage="This function will delete all rows in the table by the primary key. The .csv file must include all primary key headers (this could include multiple fields depending on the table) and only the primary keys of rows to be removed. Do not include fields that are not primary key fields."
        ></nz-alert>
        <div nz-row nzGutter="4">
            <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label>PLease upload CSV file</nz-form-label>
                    <nz-form-control
                        [nzSpan]="24"
                        [nzValidateStatus]="
                            !fileList?.length && formSubmitted
                                ? 'error'
                                : 'success'
                        "
                        nzListType="text/csv"
                        [nzErrorTip]="csvFileUploadErrorTmpl"
                    >
                        <nz-upload
                            [nzBeforeUpload]="beforeFileUpload"
                            [nzFileList]="fileList"
                        >
                            <button
                                class="attach-file-button"
                                nz-button
                                nzSize="large"
                                type="button"
                            >
                                <i nz-icon nzType="upload"></i>
                                <span>Choose File</span>
                            </button>
                        </nz-upload>
                        <ng-template #csvFileUploadErrorTmpl let-control>
                            <ng-container
                                *ngIf="!fileList?.length && formSubmitted"
                                >CSV file is required
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzRequired>Notes</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzValidateStatus>
                        <nz-input-group nzSize="large">
                            <textarea
                                style="min-height: 13.75rem"
                                formControlName="note"
                                nz-input
                                placeholder="Enter note here"
                            ></textarea>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control
                        class="drawer-actions-control"
                        [nzSpan]="24"
                    >
                        <div class="drawer-controls">
                            <button
                                type="submit"
                                nzSize="large"
                                nz-button
                                [nzLoading]="loading"
                                [disabled]="
                                    !removeTableFormGroup.valid ||
                                    !removeTableFormGroup.dirty
                                "
                                nzType="primary"
                            >
                                <span>Remove Rows</span>
                            </button>
                            <button
                                type="button"
                                nzSize="large"
                                nz-button
                                (click)="onClose()"
                            >
                                <span>Close</span>
                            </button>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </form>
</div>
