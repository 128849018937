import { Component, OnInit, ViewChild, ElementRef, DestroyRef, inject } from '@angular/core';
import * as pbi from 'powerbi-client';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ReportConfig } from 'src/app/store/models/report.model';
import { ReportService } from '../../../core/services/report/report.service';
import { IEmbedSettings } from 'embed';
import { RouterExtService } from 'src/app/core/services/router-ext/router-ext.service';
import { GlobalService } from 'src/app/core/services/global/global.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-powerbi-report-page',
    templateUrl: './powerbi-report-page.component.html',
    styleUrls: ['./powerbi-report-page.component.less'],
})
export class PowerbiReportPageComponent implements OnInit {
    destroyRef = inject(DestroyRef);

    @ViewChild('reportContainer', { static: true }) reportContainer: ElementRef;

    clientId: string;

    reportName: string;
    link: any[];
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private reportService: ReportService,
        private routerExtService: RouterExtService,
        private globalService: GlobalService
    ) {}

    ngOnInit() {
        this.route.params.subscribe(({ id }) => {
            this.reportService
                .getReportConfig(id)
                .subscribe(({ payload: config }) => {
                    this.reportName = config.reportName;
                    this.initReport(config);
                });
        });

        const previousURL = this.routerExtService.getPreviousUrl();

        if (previousURL.includes('manage-reports')) {
            this.link = ['/manage-reports'];
        } else if (previousURL.includes('/reports')) {
            this.link = ['/reports'];
        } else {
            this.link = ['/dashboard'];
        }

        this.globalService.clientId$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(
                clientId => {
                    if (clientId && this.clientId) {
                        this.router.navigate(this.link)
                    }
                    this.clientId = clientId;
                }
            )
    }

    private initReport(config: ReportConfig): void {
        // Embed URL
        const cfg = {
            type: 'report',
            tokenType: pbi.models.TokenType.Embed,
            accessToken: config.embedToken,
            embedUrl: `${config.embedUrl}&rf=${(Math.random() + 1)
                .toString(36)
                .substring(7)}`,
            id: config.pbiReportId,
            settings: {
                filterPaneEnabled: false,
            } as IEmbedSettings,
        };

        const powerbi = new pbi.service.Service(
            pbi.factories.hpmFactory,
            pbi.factories.wpmpFactory,
            pbi.factories.routerFactory
        );
        const rep = powerbi.embed(this.reportContainer.nativeElement, cfg);
        rep.off('loaded');
        // rep.on('loaded', () =>
        //     console.log(`Report loaded [${config.pbiReportId}]`)
        // );
        rep.on('error', console.error);
    }
}
