import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import _get from 'lodash/get';
import { AppState } from '../../../store/state';
import { ReportGroup } from '../../../store/models/report-group.model';
import { Subject, Observable } from 'rxjs';
import {
    createReportGroupRequest,
    updateReportGroupRequest,
} from '../../../store/actions/report/report.actions';
import {
    ReportGroupInput,
    ReportGroupService,
} from '../../../core/services/report-group/report-group.service';
import { takeUntil } from 'rxjs/operators';
import { selectSelectedReportGroupLoading } from '../../../store/selectors/report.selectors';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AttributeService } from '../../../core/services/attribute/attribute.service';
import _uniq from 'lodash/uniq';
import _flatten from 'lodash/flatten';
import { AttributeType } from 'src/app/store/models/attribute.model';
import { ReportService } from 'src/app/core/services/report/report.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-report-group-form',
    templateUrl: './report-group-form.component.html',
    styleUrls: ['./report-group-form.component.less'],
})
export class ReportGroupFormComponent implements OnInit, OnDestroy {
    @Input() clientId: string;
    @Input() reportGroup: ReportGroup;
    @Input() groupId: string;
    @Output() closed: EventEmitter<boolean>;
    reportGroupForm: UntypedFormGroup;
    loading$: Observable<boolean>;

    ngDestroyed$ = new Subject();
    attributeTypesLoading: boolean;
    attributeTypes: AttributeType[] = [];

    reports: Report[] = [];
    reportsLoading: boolean = false;

    constructor(
        private store: Store<AppState>,
        private fb: UntypedFormBuilder,
        private attributeService: AttributeService,
        private reportGroupService: ReportGroupService,
        private message: NzMessageService,
        private reportService: ReportService,
        private modal: NzModalService
    ) {
        this.closed = new EventEmitter<boolean>();

        this.reportGroupForm = this.fb.group({
            groupId: [''],
            groupName: ['', Validators.required],
            groupColor: ['', Validators.required],
            attrTypeIds: [[]],
        });
        this.loading$ = this.store.select(selectSelectedReportGroupLoading);
    }
    ngOnInit() {
        this.reportGroupService.onReportGroup$
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe(({ reportGroup, new: isNew }) => {
                this.ngDestroyed$.next(true);
                this.closed.emit(true);
                this.message.success(
                    `Report group <b>${reportGroup.groupName}</b> has been ${
                        isNew ? 'created' : 'updated'
                    }`,
                    { nzDuration: 4000 }
                );
            });

        this.groupId = _get(this.reportGroup, ['groupId']);
        this.initReportGroup();

        if (this.reportGroup) {
            this.reportsLoading = true;

            this.reportService.getReportsByClient(this.clientId, { selectedGroups: [this.reportGroup.groupId], limit: 1000 })
                .pipe(takeUntil(this.ngDestroyed$))
                .subscribe({
                    next: payload => {
                        this.reportsLoading = false;
                        this.reports = _get(payload, 'payload', []);
                    },
                    error: () => {
                        this.reportsLoading = false;
                        this.reports = [];
                    }
                });
        }
    }

    ngOnChanges() {
        this.initReportGroup();
    }

    initReportGroup() {
        this.reportGroupForm.patchValue({
            groupName: _get(this.reportGroup, 'groupName', ''),
            groupColor: _get(
                this.reportGroup,
                'groupColor',
                '#1a8737'
            ),
            attrTypeIds: (this.reportGroup?.attributeTypes ?? []).map(attrType => attrType.attrTypeId)
        });

        if (this.reportGroup && this.reportGroup.groupId) {
            this.reportGroupForm.patchValue({
                groupId: _get(this.reportGroup, ['groupId']),
            });
        }        

        this.fetchAttributeTypes(this.clientId);
    }
    
    private fetchAttributeTypes(clientId: string) {
        this.attributeTypesLoading = true;
        this.attributeService
            .getAttributeTypesForReports(clientId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    this.attributeTypes = _get(payload, 'payload');
                },
                complete: () => (this.attributeTypesLoading = false),
            });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    save() {
        for (const key in this.reportGroupForm.controls) {
            this.reportGroupForm.controls[key].markAsDirty();
            this.reportGroupForm.controls[key].updateValueAndValidity();
        }

        const input: ReportGroupInput = {
            clientId: this.clientId,
            groupName: this.reportGroupForm.get('groupName').value,
            groupColor: this.reportGroupForm.get('groupColor').value,
            attrTypeIds: this.reportGroupForm.get('attrTypeIds').value ?? []
        };

        if (this.reportGroup && this.reportGroup.groupId) {
            input.groupId = this.reportGroup.groupId;
            this.store.dispatch(updateReportGroupRequest({ input }));
        } else {
            input.groupId = null;
            this.store.dispatch(createReportGroupRequest({ input }));
        }
    }

    submitReportGroupForm() {
        const attrTypeIds = this.reportGroupForm.get('attrTypeIds').value;

        if (this.reports.length && this.reportGroup.groupId && !!this.reportGroup.attributeTypes?.length && !attrTypeIds.length) {
            this.modal.confirm({
                nzTitle: 'Confirm Changes',
                nzContent: `Please Note: Removing the attribute type associated with ${this.reportGroupForm.get('groupName').value} will remove all associated attributes for ${this.reports.length ?? 0} underlying report${this.reports.length > 1 ? 's' : ''}. These associations cannot be recovered.`,
                nzOkType: 'primary',
                nzOkText: 'Yes, save',
                nzCancelText: 'Cancel',
                nzClosable: false,
                nzOnOk: () => this.save()
            });
        } else if (this.reports.length && this.reportGroup?.groupId && attrTypeIds.length > 0 && (attrTypeIds.length !== this.reportGroup.attributeTypes?.length || !(this.reportGroup.attributeTypes ?? []).every(attrType => attrTypeIds.includes(attrType.attrTypeId)))) {
            this.modal.confirm({
                nzTitle: 'Confirm Changes',
                nzContent: `Please Note: Updating the attribute type associated with ${this.reportGroupForm.get('groupName').value} will require new attributes to be assigned to ${this.reports.length ?? 0} underlying report${this.reports.length > 1 ? 's' : ''}. The report${this.reports.length > 1 ? 's' : ''} will not be available to users until new attributes are assigned.`,
                nzOkType: 'primary',
                nzOkText: 'Yes, save',
                nzCancelText: 'Cancel',
                nzClosable: false,
                nzOnOk: () => this.save()
            });
        } else {
            this.save();
        }
    }

    onCloseReportGroupForm() {
        this.ngDestroyed$.next(true);
        this.closed.emit(false);
        this.reportGroupForm.reset();
    }

    onColorChanged(groupColor: string) {
        this.reportGroupForm.markAsDirty();
        this.reportGroupForm.patchValue({ groupColor });
    }
}
