<div
    class="upload-file-tile__container"
    [class.cancelled]="cancelled"
    [class.completed]="completed"
>
    <div [class]="fileIconClassname + ' file-tile__icon'"></div>
    <div class="file-tile__info">
        <div class="file-tile-info__name">
            {{ fileName }}
        </div>
        <div class="file-tile-info__size">
            {{ fileSize | fileSize }}
        </div>
    </div>
    <div
        class="upload-file-progress"
        *ngIf="started && !cancelled && !completed"
    >
        <div
            class="upload-file-progress__loaded"
            [style.width]="progressWidth"
        ></div>
    </div>
    <div
        [class.cancelled]="cancelled"
        [class.completed]="completed"
        class="upload-file-statuses"
        *ngIf="cancelled || completed"
    >
        <i
            *ngIf="cancelled"
            nz-icon
            nzType="close-circle"
            nzTheme="outline"
        ></i>
        <i
            *ngIf="completed"
            nz-icon
            nzType="check-circle"
            nzTheme="outline"
        ></i>
    </div>
    <div class="upload-file-actions" *ngIf="!cancelled && !completed">
        <i nz-icon nzType="dots" nzTheme="fill"></i>
        <div class="actions">
            @if (!hideDelete) {
                <i
                    *ngIf="!started"
                    nz-icon
                    nz-tooltip
                    nzTooltipPlacement="top"
                    nzTooltipTitle="Delete"
                    nzTooltipOverlayClassName="danger-tooltip"
                    nzType="delete"
                    nzTheme="outline"
                    (click)="onDelete()"
                ></i>
            }
            <i
                *ngIf="started && !cancelled"
                nz-icon
                nz-tooltip
                nzTooltipPlacement="top"
                nzTooltipTitle="Cancel"
                nzTooltipOverlayClassName="danger-tooltip"
                nzType="stop"
                nzTheme="outline"
                (click)="onCancelUpload()"
            ></i>
        </div>
    </div>
</div>
