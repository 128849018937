import { createAction, props } from '@ngrx/store';
import * as AuthActionTypes from './constants';
import { User } from '../../models/user.model';
import { LoginType } from '../../models/login-type';

export const authLoginRequest = createAction(
    AuthActionTypes.AUTH_LOGIN_REQUEST,
    props<{
        userLoginId: string;
        userPassword: string;
        step: number;
        redirect: string;
        remember: boolean;
    }>()
);

export const authLoginReceive = createAction(
    AuthActionTypes.AUTH_LOGIN_RECEIVE,
    props<{
        token: string;
        userLoginId?: string;
        user: User;
        step?: number;
        ssoUrl?: string;
        remember?: boolean;
        redirect?: string;
        roles: string[];
    }>()
);

export const authLoginFailed = createAction(
    AuthActionTypes.AUTH_LOGIN_FAILED,
    props<{ error: Object }>()
);

export const authenticateSSORequest = createAction(
    AuthActionTypes.AUTHENTICATE_SSO_REQUEST,
    props<{ code: string; state: string }>()
);

export const authenticateSSOReceive = createAction(
    AuthActionTypes.AUTHENTICATE_SSO_RECEIVE,
    props<{
        token: string;
        userLoginId: string;
        user: User;
        remember: boolean;
        roles: string[];
    }>()
);

export const authenticateSSOFailed = createAction(
    AuthActionTypes.AUTHENTICATE_SSO_FAILED,
    props<{ error: Object }>()
);

export const authLogoutRequest = createAction(
    AuthActionTypes.AUTH_LOGOUT_REQUEST
);

export const authLogoutReceive = createAction(
    AuthActionTypes.AUTH_LOGOUT_RECEIVE
);

export const loginTypesRequest = createAction(
    AuthActionTypes.LOGIN_TYPES_REQUEST
);

export const loginTypesReceive = createAction(
    AuthActionTypes.LOGIN_TYPES_RECEIVE,
    props<{ items: LoginType[] }>()
);

export const loginTypesFailed = createAction(
    AuthActionTypes.AUTH_LOGIN_FAILED,
    props<{ error: Object }>()
);

export const switchAccountRequest = createAction(
    AuthActionTypes.SWITCH_ACCOUNT_REQUEST
);
