<div
    class="portal-type-form-container"
    *hasPermission="['UPLOAD_PORTAL_MGMT']; class: 'disabled'"
>
    <form
        class="portal-type__form"
        [formGroup]="portalTypeFormGroup"
        nz-form
        (ngSubmit)="submitPortalTypeForm()"
    >
        <div nz-row nzGutter="4">
            <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label nzRequired
                        >Upload portal type name</nz-form-label
                    >
                    <nz-form-control
                        [nzSpan]="12"
                        nzValidateStatus
                        nzHasFeedback
                        [nzErrorTip]="uploadPortalTypeNameErrorTmpl"
                    >
                        <nz-input-group nzSize="large">
                            <input
                                formControlName="uploadPortalTypeName"
                                nz-input
                                placeholder="Enter Upload Portal Type Name"
                            />
                        </nz-input-group>
                        <ng-template #uploadPortalTypeNameErrorTmpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please enter upload portal type name
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label>Attribute types (optional)</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-select
                            nzPlaceHolder="Select attribute types"
                            nzAllowClear
                            nzMode="multiple"
                            nzShowSearch
                            nzSize="large"
                            [nzLoading]="attributeTypesLoading || portalsLoading"
                            formControlName="attrTypeIds"
                        >
                            <ng-container *ngIf="!attributeTypesLoading && !portalsLoading">
                                <nz-option
                                    *ngFor="let attrType of attributeTypes"
                                    [nzValue]="attrType.attrTypeId"
                                    [nzLabel]="attrType.attrTypeName"
                                    [nzCustomContent]="!attrType.attributes?.length"
                                    [nzDisabled]="!attrType.attributes?.length"
                                >
                                    <div nz-flex nzAlign="center" nzJustify="space-between">
                                        <span>{{ attrType.attrTypeName }}</span>
                                        <span
                                            nz-icon
                                            nz-tooltip
                                            nzTooltipTitle="The attribute type must have at least one attribute to select."
                                            nzType="info-circle"
                                        ></span>
                                    </div>
                                </nz-option>
                            </ng-container>
                            <nz-option *ngIf="attributeTypesLoading && portalsLoading" nzDisabled nzCustomContent>
                                <span nz-icon nzType="loading" class="loading-icon"></span>
                                Loading Data...
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label>Instructions</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <editor
                            [formControl]="
                                portalTypeFormGroup.controls.uploadPortalText
                            "
                            [init]="{
                                base_url: '/tinymce',
                                suffix: '.min',
                                height: 240,
                                plugins:
                                    'lists advlist preview searchreplace fullscreen table',
                                toolbar:
                                    'undo redo | bold italic underline backcolor | fontselect fontsizeselect forecolor formatselect | h1 h2 h3 h4 h5 h6 | alignleft aligncenter alignright alignjustify | font | bullist numlist outdent indent'
                            }"
                        ></editor>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control
                        class="drawer-actions-control"
                        [nzSpan]="24"
                    >
                        <div class="drawer-controls">
                            <button
                                *hasPermission="['UPLOAD_PORTAL_MGMT']"
                                type="button"
                                [nzLoading]="saving"
                                nzSize="large"
                                nz-button
                                [disabled]="
                                    !portalTypeFormGroup.valid ||
                                    !portalTypeFormGroup.dirty
                                "
                                (click)="submitPortalTypeForm()"
                                nzType="primary"
                            >
                                <span>Save</span>
                            </button>
                            <button
                                type="button"
                                nzSize="large"
                                nz-button
                                (click)="onClosePortalTypeForm()"
                            >
                                <span>Close</span>
                            </button>
                        </div>
                    </nz-form-control>
                </nz-form-item>
                
                <div class="portal-type-form__status-switcher">
                    <h3>Enable statuses</h3>
                    <nz-switch
                        formControlName="collectionStatusEnabled"
                    ></nz-switch>
                </div>
                <nz-form-item
                    style="margin-bottom: -1rem !important"
                    class="statuses-checkbox"
                    *ngIf="
                        portalTypeFormGroup.controls.collectionStatusEnabled
                            .value || statusesLoading
                    "
                >
                    <nz-skeleton
                        [nzLoading]="statusesLoading"
                        [nzActive]="true"
                    >
                        <nz-form-control [nzSpan]="24">
                            <nz-checkbox-wrapper
                                (nzOnChange)="onStatusesChanged()"
                            >
                                <div
                                    *ngFor="
                                        let status of statuses;
                                        let i = index
                                    "
                                    nz-col
                                    [nzSpan]="12"
                                >
                                    <label
                                        [formControlName]="
                                            'status#' +
                                            status.collectionStatusId
                                        "
                                        nz-checkbox
                                        [style.marginRight.rem]="
                                            i % 2 === 0 ? 1.25 : 0
                                        "
                                        [nzValue]="status.collectionStatusId"
                                    >
                                        <app-upload-portal-status
                                            [statusName]="
                                                status.collectionStatusName
                                            "
                                            [statusColor]="status.statusColor"
                                        >
                                            {{ status.collectionStatusName }}
                                        </app-upload-portal-status>
                                    </label>
                                </div>
                            </nz-checkbox-wrapper>
                        </nz-form-control>
                    </nz-skeleton>
                </nz-form-item>
            </div>
        </div>
    </form>
</div>
