import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import { LcaService } from 'src/app/core/services/lca/lca.service';
import { LCADataset } from 'src/app/store/models/lca-dataset.model';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import _orderBy from 'lodash/orderBy';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LCAImpactType } from 'src/app/store/models/lca-impact-type';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import { ImpactICL } from 'src/app/store/models/impact-icl.model';
import { TextSearchInputComponent } from 'src/app/core/components/text-search-input/text-search-input.component';

export enum LcaEngineImpactCoefViews {
    DatasetSelection = 0,
    ImpactCategories = 1,
}

export interface LCAImpactCoefFilter {
    years?: string[];
    impactTypeIds?: number[];
    showCoefficientsInDB?: boolean;
}
@Component({
    selector: 'app-lca-engine-impact-coef-page',
    templateUrl: './lca-engine-impact-coef-page.component.html',
    styleUrls: ['./lca-engine-impact-coef-page.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LcaEngineImpactCoefPageComponent implements OnInit {
    datasetLoading: boolean = false;
    yearsLoading: boolean = false;

    years: number[] = [];
    datasets: LCADataset[] = [];
    searchText: string;

    selectedDataset: LCADataset;
    selectedYear: number;

    impactCoefSettingForm: UntypedFormGroup;
    views = LcaEngineImpactCoefViews;

    //activity serials table specific variables
    impactSerialsIndeterminate: boolean = false;
    impactSerialsAllChecked: boolean = false;
    setOfImpactSerialChecked = new Set<number>();

    generatingCoef: boolean = false;
    generatingError: string = '';

    impactYears: string[] = [];
    impactYearsLoading: boolean = false;
    selectedImpactYears: string[] = [];

    impactTypes: LCAImpactType[] = [];
    impactTypesLoading: boolean = false;
    selectedImpactTypeIds: number[] = [];

    filter: LCAImpactCoefFilter = {};

    debouncer: Subject<boolean> = new Subject();

    logsLoading: boolean = false;
    notFilteredLogs: ImpactICL[] = [];
    logs: ImpactICL[] = [];

    @ViewChild(TextSearchInputComponent)
    textSearchInput: TextSearchInputComponent;

    ngDestroyed$ = new Subject();

    constructor(
        private lcaService: LcaService,
        private modals: NzModalService,
        private cdr: ChangeDetectorRef,
        private fb: UntypedFormBuilder
    ) {
        this.impactCoefSettingForm = this.fb.group({
            datasetId: [, Validators.required],
            year: [, Validators.required],
            supplyChainTier: [],
        });
    }

    ngOnInit(): void {
        this.datasetLoading = true;
        this.cdr.detectChanges();

        this.lcaService.getDatasets().subscribe({
            next: (payload) => {
                this.datasets = _orderBy(_get(payload, 'payload', []), item => item.datasetName.toLowerCase(), ['asc']);
            },
            complete: () => {
                this.datasetLoading = false;
                this.cdr.detectChanges();
            },
        });

        this.debouncer
            .pipe(takeUntil(this.ngDestroyed$), debounceTime(350))
            .subscribe(() => {
                this.fetchLogs();
                this.cdr.detectChanges();
            });
    }

    onDatasetChange(datasetId: string) {
        this.fetchYears(datasetId);
        this.setOfImpactSerialChecked.clear();
        this.refreshCheckedStatus();
        this.selectedDataset = this.datasets.find(
            (value) => `${value.datasetId}` === `${datasetId}`
        );
        this.selectedYear = null;
        this.filter = {};
        if (this.textSearchInput) {
            this.textSearchInput.onClear();
        }
        this.cdr.detectChanges();
    }

    onYearChange(year: number) {
        this.selectedYear = year;
        this.setOfImpactSerialChecked.clear();
        this.refreshCheckedStatus();
        this.filter = {};
        if (this.textSearchInput) {
            this.textSearchInput.onClear();
        }
        if (year) {
            this.fetchImpactTypes();
            this.fetchImpactYears();
            this.fetchLogs();
        }
    }

    private fetchImpactYears() {
        this.impactYearsLoading = true;
        this.cdr.detectChanges();

        this.lcaService.getImpactYears().subscribe({
            next: (payload) => {
                this.impactYears = _sortBy(_get(payload, 'payload', []));
            },
            complete: () => {
                this.impactYearsLoading = false;
                this.cdr.detectChanges();
            },
        });
    }

    private fetchImpactTypes() {
        this.impactTypesLoading = true;
        this.cdr.detectChanges();

        this.lcaService.getImpactTypes().subscribe({
            next: (payload) => {
                this.impactTypes = _orderBy(_get(payload, 'payload', []), item => item.impactTypeName.toLowerCase(), ['asc']);
            },
            complete: () => {
                this.impactTypesLoading = false;
                this.cdr.detectChanges();
            },
        });
    }

    onYearsSelected(years: string[]) {
        this.textSearchInput.onClear();
        if (years.length) {
            this.filter.years = years;
        } else {
            this.filter = _omit(this.filter, ['years']);
        }
        this.debouncer.next(true);
    }

    onImpactTypeSelected(impactTypeIds: number[]) {
        this.textSearchInput.onClear();
        if (impactTypeIds.length) {
            this.filter.impactTypeIds = impactTypeIds;
        } else {
            this.filter = _omit(this.filter, ['impactTypeIds']);
        }
        this.debouncer.next(true);
    }

    get showResetFilters() {
        return !_isEmpty(this.filter);
    }

    resetFilters() {
        this.filter = {};
        this.selectedImpactYears = [];
        this.selectedImpactTypeIds = [];
        this.textSearchInput.onClear();
        this.cdr.detectChanges();
        this.debouncer.next(true);
    }

    private fetchYears(datasetId: string) {
        if (!datasetId) return;

        this.yearsLoading = true;
        this.cdr.detectChanges();

        this.lcaService.getActivityYears(datasetId).subscribe({
            next: (payload) => {
                this.years = _sortBy(_get(payload, 'payload', []));
            },
            error: () => {
                this.years = [];
            },
            complete: () => {
                this.yearsLoading = false;
                this.cdr.detectChanges();
            },
        });
    }

    confirmOverride(useNewLcaEngine: boolean = false) {
        this.modals.confirm({
            nzTitle: 'Warning',
            nzContent:
                'You have selected an impact category that already exists in the database. Do you want to overwrite the existing data?',
            nzOnOk: () => {
                this.postCoeff(useNewLcaEngine);
            },
            nzOnCancel: () => {},
            nzOkText: 'Yes',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzCancelText: 'No',
            nzClosable: false,
        });
    }

    onGenerateImpactCoef(useNewLcaEngine: boolean = false) {
        const hasSerialsWhichAlreadyInDB = this.logs
            .filter(({ impactSerial }) =>
                this.setOfImpactSerialChecked.has(impactSerial)
            )
            .some(({ logCreatedStamp }) => !!logCreatedStamp);

        if (this.setOfImpactSerialChecked.size > 10) {
            this.modals.confirm({
                nzTitle: 'Warning',
                nzContent:
                    `Are you sure want to process ${this.setOfImpactSerialChecked.size} impact serials? \n\n This could take a while.`,
                nzOnOk: () => {
                    if (hasSerialsWhichAlreadyInDB) {
                        this.confirmOverride(useNewLcaEngine);
                    } else {
                        this.postCoeff(useNewLcaEngine);
                    }
                },
                nzOnCancel: () => {},
                nzOkText: 'Yes',
                nzOkType: 'primary',
                nzOkDanger: true,
                nzCancelText: 'No',
                nzClosable: false,
            });
        } else if (hasSerialsWhichAlreadyInDB) {
            this.confirmOverride(useNewLcaEngine);
        } else {
            this.postCoeff(useNewLcaEngine);
        }
    }

    private postCoeff(useNewLcaEngine: boolean = false) {
        const {
            datasetId,
            year: datasetYear,
            supplyChainTier: supplyChainTiers,
        } = this.impactCoefSettingForm.value;
        this.generatingError = null;

        const data = {
            datasetYear,
            datasetId,
            impactSerials: Array.from(this.setOfImpactSerialChecked),
            useNewLcaEngine,
        };

        if (supplyChainTiers) {
            data['supplyChainTiers'] = supplyChainTiers;
        }

        this.generatingCoef = true;
        this.cdr.detectChanges();

        this.lcaService.postLCAImpactCoeff(data).subscribe({
            next: () => {
                this.modals.confirm({
                    nzTitle: 'Info',
                    nzContent:
                        'The impact coefficients are being generated in the background. Please check their status in the database history table.',
                    nzOnOk: () => {
                        this.setOfImpactSerialChecked = new Set<number>();
                        this.onImpactSerialsAllChecked(false);
                        this.refreshCheckedStatus();
                        this.logs = [];
                        this.notFilteredLogs = [];
                        this.selectedDataset = null;
                        this.selectedYear = null;
                        this.searchText = null;
                        this.cdr.detectChanges();
                        this.impactCoefSettingForm.reset();
                    },
                    nzOnCancel: () => {},
                    nzOkText: 'Ok',
                    nzOkType: 'primary',
                    nzOkDanger: false,
                    nzCancelText: null,
                    nzClosable: false,
                });
            },
            error: (error) => {
                this.generatingError = _get(error, ['error', 'message'], null);
                this.cdr.detectChanges();
            },
            complete: () => {
                this.generatingCoef = false;
                this.cdr.detectChanges();
            },
        });
    }

    fetchLogs() {
        this.logsLoading = true;
        this.cdr.detectChanges();
        const { datasetId, year: datasetYear } =
            this.impactCoefSettingForm.value;
        const { years: impactYears, impactTypeIds } = this.filter;

        const params = { datasetId, datasetYear };

        if (impactTypeIds && impactTypeIds.length > 0) {
            params['impactTypeIds'] = impactTypeIds;
        }
        if (impactYears && impactYears.length > 0) {
            params['impactYears'] = impactYears;
        }

        this.lcaService.getLCAImpactCategoriesLogs(params).subscribe({
            next: (data) => {
                this.logs = _get(data, 'payload', []);
                if (this.filter.showCoefficientsInDB) {
                    this.logs = this.logs.filter(
                        (log) => !!log.logCreatedStamp
                    );
                }
                this.notFilteredLogs = this.logs;
                this.cdr.detectChanges();
            },
            complete: () => {
                this.logsLoading = false;
                this.cdr.detectChanges();
            },
        });
    }

    updateCheckedSet(impactSerial: number, checked: boolean): void {
        if (checked) {
            this.setOfImpactSerialChecked.add(impactSerial);
        } else {
            this.setOfImpactSerialChecked.delete(impactSerial);
        }
    }

    private refreshCheckedStatus(): void {
        if (this.logs && this.logs.length) {
            this.impactSerialsAllChecked = this.logs.every(({ impactSerial }) =>
                this.setOfImpactSerialChecked.has(impactSerial)
            );
            this.impactSerialsIndeterminate =
                this.logs.some(({ impactSerial }) =>
                    this.setOfImpactSerialChecked.has(impactSerial)
                ) && !this.impactSerialsAllChecked;
            this.cdr.detectChanges();
        }
    }

    onImpactSerialsAllChecked(checked: boolean) {
        this.logs.forEach(({ impactSerial }) =>
            this.updateCheckedSet(impactSerial, checked)
        );
        this.refreshCheckedStatus();
    }

    onImpactSerialChecked(impactSerial: number, checked: boolean) {
        this.updateCheckedSet(impactSerial, checked);
        this.refreshCheckedStatus();
    }

    onSearch(value: string) {
        this.searchText = value;

        if (!this.searchText) {
            this.logs = this.notFilteredLogs;
        } else {
            const query = this.searchText.toLocaleLowerCase();

            this.logs = this.notFilteredLogs.filter(
                (log) =>
                    log.impactName.toLowerCase().includes(query) ||
                    String(log.impactSerial).toLowerCase().includes(query) ||
                    String(log.supplyChainTiers)
                        .toLowerCase()
                        .includes(query) ||
                    String(log.unitAbbreviation)
                        .toLowerCase()
                        .includes(query) ||
                    String(log.year).toLowerCase().includes(query)
            );
        }
    }

    onShowCoefficientsInDB(value: boolean) {
        this.textSearchInput.onClear();
        if (value) {
            this.filter.showCoefficientsInDB = value;
        } else {
            this.filter = _omit(this.filter, ['showCoefficientsInDB']);
        }
        this.debouncer.next(true);
    }
}
