import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { LCAImpactCateogry } from 'src/app/store/models/lca-impact-category';
import { LCAImpactCategoriesFilter } from '../../filters/lca-impact-categories-filter/lca-impact-categories-filter.component';
import _uniqBy from 'lodash/uniqBy';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import { LcaService } from 'src/app/core/services/lca/lca.service';

@Component({
    selector: 'app-lca-impact-categories-selection-form',
    templateUrl: './lca-impact-categories-selection-form.component.html',
    styleUrls: ['./lca-impact-categories-selection-form.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LcaImpactCategoriesSelectionFormComponent
    implements OnInit, OnDestroy
{
    @Output() changed: EventEmitter<{
        checked: Set<number>;
        categories: LCAImpactCateogry[];
    }> = new EventEmitter();

    //impact categories table specific variables
    impactCategoriesFilter: LCAImpactCategoriesFilter = {};
    impactCategoriesLoading: boolean = false;
    impactCategoriesSearchText: string;
    impactCategories: LCAImpactCateogry[] = [];
    notFilteredImpactCategories: LCAImpactCateogry[] = [];
    impactCategoriesIndeterminate: boolean = false;
    impactCategoriesAllChecked: boolean = false;
    setOfImpactCategoriesChecked = new Set<number>();
    impactCategoriesDebouncer: Subject<boolean> = new Subject();

    destroyed$: Subject<any> = new Subject();

    constructor(
        private cdr: ChangeDetectorRef,
        private lcaService: LcaService
    ) {}

    ngOnInit(): void {
        this.fetchImpactCategories();

        this.impactCategoriesDebouncer
            .pipe(takeUntil(this.destroyed$), debounceTime(350))
            .subscribe(() => {
                this.fetchImpactCategories();
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.fetchImpactCategories();
    }

    private fetchImpactCategories(): void {
        this.impactCategoriesLoading = true;
        this.impactCategoriesAllChecked = false;
        this.impactCategoriesIndeterminate = false;
        this.notFilteredImpactCategories = [];
        this.impactCategoriesSearchText = null;
        this.cdr.detectChanges();

        this.lcaService
            .getImpactCategories(this.impactCategoriesFilter)
            .subscribe({
                next: (payload) => {
                    const checkedImpactCategories =
                        this.impactCategories.filter(({ impactSerial }) =>
                            this.setOfImpactCategoriesChecked.has(impactSerial)
                        );
                    this.impactCategories = _uniqBy(
                        _get(payload, 'payload', []).concat(
                            checkedImpactCategories
                        ),
                        'impactSerial'
                    );
                    this.notFilteredImpactCategories = this.impactCategories;

                    this.changed.emit({
                        checked: this.setOfImpactCategoriesChecked,
                        categories: this.impactCategories,
                    });

                    if (this.impactCategoriesFilter.searchText) {
                        this.impactCategories =
                            this.notFilteredImpactCategories.filter(
                                ({
                                    impactSerial,
                                    impactName,
                                    unitAbbreviation,
                                    year,
                                }) => {
                                    const query =
                                        this.impactCategoriesFilter.searchText.toLowerCase();

                                    return (
                                        impactName
                                            .toLowerCase()
                                            .includes(query) ||
                                        unitAbbreviation
                                            .toLowerCase()
                                            .includes(query) ||
                                        `${impactSerial}`
                                            .toLowerCase()
                                            .includes(query) ||
                                        `${year}`.toLowerCase().includes(query)
                                    );
                                }
                            );
                    }

                    if (this.impactCategoriesFilter.showSelected) {
                        this.impactCategories = this.impactCategories.filter(
                            ({ impactSerial }) =>
                                this.setOfImpactCategoriesChecked.has(
                                    impactSerial
                                )
                        );
                    }

                    this.refreshImpactCategoryCheckedStatus();

                    this.cdr.detectChanges();
                },
                error: () => {
                    this.impactCategories = [];
                },
                complete: () => {
                    this.impactCategoriesLoading = false;
                    this.cdr.detectChanges();
                },
            });
    }

    //Impact category table
    updateImpactCategoryCheckedSet(
        impactSerial: number,
        checked: boolean
    ): void {
        if (checked) {
            this.setOfImpactCategoriesChecked.add(impactSerial);
        } else {
            this.setOfImpactCategoriesChecked.delete(impactSerial);
        }
    }

    onImpactCategoriesFilterChanged(filter: LCAImpactCategoriesFilter) {
        this.impactCategoriesFilter = {
            ...this.impactCategoriesFilter,
            ...filter,
        };

        this.impactCategoriesDebouncer.next(true);
    }

    onSearchImpactCategories(value: string) {
        this.impactCategoriesFilter.searchText = value;
        this.impactCategoriesDebouncer.next(true);
    }

    onShowSelectedImpactCategoriesChanged() {
        if (this.impactCategoriesFilter.showSelected) {
            this.impactCategories = this.notFilteredImpactCategories.filter(
                ({ impactSerial }) =>
                    this.setOfImpactCategoriesChecked.has(
                        impactSerial
                    )
            );
        } else {
            this.impactCategories = this.notFilteredImpactCategories;
        }

        this.refreshImpactCategoryCheckedStatus();
    }

    private refreshImpactCategoryCheckedStatus(): void {
        if (this.impactCategories && this.impactCategories.length) {
            this.impactCategoriesAllChecked = this.impactCategories.every(
                ({ impactSerial }) =>
                    this.setOfImpactCategoriesChecked.has(impactSerial)
            );
            this.impactCategoriesIndeterminate =
                this.impactCategories.some(({ impactSerial }) =>
                    this.setOfImpactCategoriesChecked.has(impactSerial)
                ) && !this.impactCategoriesAllChecked;
            this.cdr.detectChanges();
        }

        this.changed.emit({
            checked: this.setOfImpactCategoriesChecked,
            categories: this.impactCategories,
        });
    }

    onImpactCategoriesAllChecked(checked: boolean) {
        this.impactCategories.forEach(({ impactSerial }) =>
            this.updateImpactCategoryCheckedSet(impactSerial, checked)
        );
        this.refreshImpactCategoryCheckedStatus();
    }

    onImpactCategoryChecked(impactSerial: number, checked: boolean) {
        this.updateImpactCategoryCheckedSet(impactSerial, checked);
        this.refreshImpactCategoryCheckedStatus();
    }
}
