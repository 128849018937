import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { filter, Subject, takeUntil, tap } from 'rxjs';
import _get from 'lodash/get';
import { LcaService } from 'src/app/core/services/lca/lca.service';
import { UploadProgressService } from 'src/app/core/services/upload-progress/upload-progress.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-lca-settings-form',
  templateUrl: './lca-settings-form.component.html',
  styleUrl: './lca-settings-form.component.less'
})
export class LcaSettingsFormComponent implements OnDestroy {
  @Output() closed: EventEmitter<boolean> = new EventEmitter();

  file: NzUploadFile;

  started: boolean = false;
  completed: boolean = false;
  cancelled: boolean = false;
  progress: number = 0;
  uploadKey: string;

  fileForm: UntypedFormGroup;

  error;

  destroyed$ = new Subject();

  ngOnDestroy() {
      this.destroyed$.next(true);
  }

  constructor(
    private fb: UntypedFormBuilder,
    private lcaService: LcaService,
    private uploadProgressService: UploadProgressService,
    private message: NzMessageService,
    private modal: NzModalService
  ) {
    this.initFileForm();
    this.file = undefined;
  }

  private initFileForm() {
    this.fileForm = this.fb.group({
      fileName: ['', Validators.required],
    });
  }

  private isValidMimeType(fileName: string): boolean {
    return fileName.includes('.pbix');
  }

  onFileRemove() {
    this.file = undefined;
    this.fileForm.reset();
  }

  beforeFileUpload() {
    return (file: NzUploadFile) => {
        if (!this.isValidMimeType(file.name)) {
            this.message.error(`Only .pbix file types are allowed!`, {
                nzDuration: 5000,
            });
            return false;
        } else {
            this.fileForm.patchValue({ fileName: file.name })
            this.file = file;

            // false for manual upload on form submit
            return false;
        }
    };
  }

  onClose() {
    if (this.started && !this.cancelled && !this.completed) {
        this.modal.confirm({
            nzTitle: `Do you want to stop the uploading process?`,
            nzContent: `
  Do you want to cancel the upload process? Upload progress will be lost. 
`,
            nzOnOk: () => {
                this.uploadProgressService.cancel(this.uploadKey);
                this.closed.emit(false);
            },
            nzOkText: 'Yes, cancel',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzCancelText: 'Continue upload',
            nzClosable: false,
            nzOnCancel: () => {},
        });
    } else {
        this.closed.emit(false);
    }
  }

  onSave() {
    if (!this.file) {
      this.message.warning('Please, select file');
      return;
    }

    this.uploadKey = `UPLOADING_LCA_SANDBOX_PBI_REPORT_TEMPLATE`;
    this.error = null;

    this.uploadProgressService.reset(this.uploadKey);

    this.uploadProgressService.upload$
        .pipe(
            takeUntil(this.destroyed$),
            filter((uploadInfo) => this.uploadKey === uploadInfo.key)
        )
        .subscribe(({ progress, started, cancelled, completed }) => {
            this.started = started;
            this.cancelled = cancelled;
            this.completed = completed;
            this.progress = progress;
        });

    this.lcaService
        .uploadSandboxPBIReportTemplate(this.file)
        .pipe(
            takeUntil(
                this.uploadProgressService.getCloseSubscriptionByKey(
                    this.uploadKey
                )
            ),
            tap((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.Sent:
                        this.uploadProgressService.start(this.uploadKey);
                        break;
                    case HttpEventType.UploadProgress:
                        this.uploadProgressService.calculateProgress(
                            this.uploadKey,
                            event.loaded,
                            event.total
                        );
                        break;
                    case HttpEventType.Response:
                        this.closed.emit(true);
                        this.message.success(`${this.file.name} template has been uploded!`)
                        this.uploadProgressService.complete(this.uploadKey);
                        break;
                }
            })
        )
        .subscribe({
            error: (error) => {
                const message = _get(error, ['error', 'message'], null);
                this.error = message ? message : 'Failed to upload template file.';
                this.uploadProgressService.complete(this.uploadKey);
            },
        });
  }
}
