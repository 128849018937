import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import _get from 'lodash/get';
import _find from 'lodash/find';
import { Customer } from 'src/app/store/models/customer.model';
import { ProcessHistoryFilters, ProcessService } from 'src/app/core/services/process/process.service';
import { User } from 'src/app/store/models/user.model';
import { Status } from 'src/app/store/models/status.model';
import { ProcessType } from 'src/app/store/models/process-type.model';
import _isEmpty from 'lodash/isEmpty';
import _omit  from 'lodash/omit';
import _orderBy from 'lodash/orderBy';
import { AI_CLIENT_ID, CustomerService } from 'src/app/core/services/customer/customer.service';
import { TextSearchInputComponent } from 'src/app/core/components/text-search-input/text-search-input.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessesCategory } from '../process-history-table/process-history-table.component';
import { PipelineType } from 'src/app/store/models/pipeline.model';
import { PipelinesService } from 'src/app/pipelines/services/pipelines/pipelines.service';

@Component({
  selector: 'app-process-history-filters',
  templateUrl: './process-history-filters.component.html',
  styleUrl: './process-history-filters.component.less'
})
export class ProcessHistoryFiltersComponent implements OnDestroy, OnInit, AfterViewInit {
  @Output() changed: EventEmitter<ProcessHistoryFilters> = new EventEmitter();
  @Input() category: ProcessesCategory = ProcessesCategory.All;
  @Input() params: object | undefined;

  categories = ProcessesCategory;

  debouncer: Subject<boolean> = new Subject();

  usersLoading: boolean = false;
  users: User[] = [];
  selectedUserIds: string[] = [];

  processTypes: Pick<ProcessType, 'processTypeId' | 'processTypeName'>[] = [];
  processTypesLoading: boolean = false;
  selectedProcessTypeIds: string[] = [];

  statuses: Pick<Status, 'statusId' | 'statusName'>[] = [];
  statusesLoading: boolean = false;
  selectedStatusIds: string[] = [];
  selectedDate = [];

  pipelineTypes: PipelineType[] = [];
  pipelineTypesLoading: boolean = false;
  selectedPipelineType: PipelineType;

  filter: ProcessHistoryFilters = {};

  clientsLoading: boolean = false;
  clients: Customer[] = [];
  selectedClientIds: string[] = [];

  ngDestroyed$ = new Subject();

  destroyed$: Subject<any> = new Subject();

  @ViewChild('tableNameSearch') tableNameSearchComp: TextSearchInputComponent;
  @ViewChild('pipelineNameSearch') pipelineNameSearchComp: TextSearchInputComponent;
  @ViewChild('notesSearch') notesSearchComp: TextSearchInputComponent;

  constructor(private clientsService: CustomerService, private processService: ProcessService, private activatedRoute: ActivatedRoute, private router: Router, private pipelineService: PipelinesService) {
    
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroyed$))
      .subscribe(
        queryParams => {
          if (queryParams['tableNameSearchText']) {
            this.filter.tableNameSearchText = queryParams['tableNameSearchText'];
            this.router.navigate(
              [], 
              {
                relativeTo: this.activatedRoute,
                queryParams: {},
              }
            );
            this.tableNameSearchComp?.setValue(this.filter.tableNameSearchText);
            this.changed.emit(this.filter);
          }

          if (queryParams['dataPipelineName']) {
            this.filter.dataPipelineName = queryParams['dataPipelineName'];
            this.router.navigate(
              [], 
              {
                relativeTo: this.activatedRoute,
                queryParams: { reportYear: this.params['reportYear'] },
              }
            );
            this.pipelineNameSearchComp?.setValue(this.filter.dataPipelineName);
            this.changed.emit(this.filter);
          }
        }
      )
  }

  ngOnInit(): void {
    if (this.category === ProcessesCategory.All) {
      this.fetchClients();
      this.fetchUsers();
    }

    if (this.category === ProcessesCategory.Database) {
      this.fetchProcessTypes();
      this.fetchStatusTypes();
      this.fetchUsers();
    }

    if (this.category === ProcessesCategory.Pipelines) {
      this.fetchPipelineTypes(this.params);
    }
  

    this.debouncer
        .pipe(takeUntil(this.ngDestroyed$))
        .subscribe(() => {
            this.changed.next(this.filter);
        })
  }

  getClientLogo(selectedClientId: string): string {
    return _get(
        _find(this.clients, { clientId: selectedClientId }),
        'logoImg'
    );
  }

  fetchUsers() {
    this.usersLoading = true;

    this.processService
        .getProcessesRunsUsers(this.category)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
            next: (payload) => {
                this.usersLoading = false;
                this.users = _get(payload, 'payload', []);
            },
            error: () => (this.usersLoading = false),
        });
  }

  fetchClients() {
    this.clientsLoading = true;
    this.clientsService
        .getCustomers({ excludeClientIds: [AI_CLIENT_ID] })
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
            next: (payload) => {
                this.clientsLoading = false;
                this.clients = _get(payload, 'payload', []);
            },
            error: () => (this.clientsLoading = false),
        });
  }

  onClientsSelected(clientIds: string[]) {
    if (clientIds.length) {
        this.filter.clientIds = clientIds;
    } else {
        this.filter = _omit(this.filter, ['clientIds']);
    }
    this.debouncer.next(true);
  }

  onTableNameSearchTextChange(value: string) {
    if (value) {
        this.filter.tableNameSearchText = value;
    } else {
        this.filter = _omit(this.filter, ['tableNameSearchText']);
    }
    this.debouncer.next(true);
  }

  onPipelineNameSearchTextChange(value: string) {
    if (value) {
        this.filter.dataPipelineName = value;
    } else {
        this.filter = _omit(this.filter, ['dataPipelineName']);
    }
    this.debouncer.next(true);
  }

  onNotesSearchTextChange(value: string) {
    if (value) {
        this.filter.notesSearchText = value;
    } else {
        this.filter = _omit(this.filter, ['notesSearchText']);
    }
    this.debouncer.next(true);
  }

  onUsersSelected(userIds: string[]) {
    if (userIds.length) {
        this.filter.userIds = userIds;
    } else {
        this.filter = _omit(this.filter, ['userIds']);
    }
    this.debouncer.next(true);
  }

  onStatusSelected(statusIds: string[]) {
    if (statusIds.length) {
        this.filter.runStatusIds = statusIds;
    } else {
        this.filter = _omit(this.filter, ['runStatusIds']);
    }
    this.debouncer.next(true);
  }

  onPipelineTypeSelected(type: string) {
    if (type) {
      this.filter.dataPipelineType = type;
    } else {
        this.filter = _omit(this.filter, ['dataPipelineType']);
    }
    this.debouncer.next(true);
  }

  onProcessTypeSelected(processTypeIds: string[]) {
    if (processTypeIds.length) {
        this.filter.processTypeIds = processTypeIds;
    } else {
        this.filter = _omit(this.filter, ['processTypeIds']);
    }
    this.debouncer.next(true);
  }

  onStartRangeChanged(date: Date[]) {
    if (date && date.length === 2) {
        this.filter.startedStampFrom = date[0].getTime();
        this.filter.startedStampTo = date[1].getTime();
    } else {
        this.filter = _omit(this.filter, [
            'startedStampFrom',
            'startedStampTo',
        ]);
    }
    this.selectedDate = date;
    this.debouncer.next(true);
  }

  fetchStatusTypes() {
    this.statusesLoading = true;
    this.processService.getProcessStatuses(this.category).subscribe({
        next: (payload) => {
            this.statusesLoading = false;
            this.statuses = _orderBy(_get(payload, 'payload', []), status => status.statusName, ['asc']);
        },
        error: () => {
            this.statusesLoading = false;
        },
    });
  }

  fetchPipelineTypes(params: object) {
    if (!params['clientId'] || !params['reportYear']) {
      return null;
    }

    this.pipelineTypesLoading = true;
    this.pipelineService.getPipelineTypes(params['clientId'], params['reportYear']).subscribe({
        next: (payload) => {
            this.pipelineTypesLoading = false;
            this.pipelineTypes = _orderBy(_get(payload, 'payload', []), typeName => typeName, ['asc']);
        },
        error: () => {
            this.pipelineTypesLoading = false;
        },
    });
  }


  fetchProcessTypes() {
    this.processTypesLoading = true;
    this.processService.getProcessTypes(this.category).subscribe({
        next: (payload) => {
            this.processTypesLoading = false;
            this.processTypes = _orderBy(_get(payload, 'payload', []), ['processTypeName'], ['asc']);
        },
        error: () => {
            this.processTypesLoading = false;
        },
    });
  }

  get showResetFilters() {
    return !_isEmpty(this.filter);
  }

  resetFilters() {
      this.filter = {};
      this.selectedClientIds = [];
      this.selectedStatusIds = [];
      this.selectedPipelineType = undefined;
      this.selectedUserIds = [];
      this.selectedProcessTypeIds = [];
      this.selectedDate = [];

      if (this.category === ProcessesCategory.Database) {
        this.tableNameSearchComp?.onClear();
        this.notesSearchComp?.onClear();
      }

      if (this.category === ProcessesCategory.Pipelines) {
        this.pipelineNameSearchComp?.onClear();
      }

      this.debouncer.next(true);
  }
}
