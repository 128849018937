<div class="sheet-container">
    <div class="sheet__controls">
        <div class="sheet-controls__left">
            <div class="sheet__search">
                <nz-select
                    nzAllowClear
                    nzShowSearch
                    [(ngModel)]="selectedClientId"
                    nzPlaceHolder="All clients"
                    [style.display]="clientSelectorVisible"
                    [disabled]="!clientSelectorActive"
                    (ngModelChange)="onClientSelected()"
                >
                    <nz-option
                        *ngFor="let customer of customers"
                        [nzLabel]="customer.clientName"
                        nzCustomContent
                        [nzValue]="customer.clientId"
                    >
                        <div class="client-template">
                            <img
                                class="client-template__logo"
                                *ngIf="customer?.logoImg; else defaultLogo"
                                [src]="customer?.logoImg"
                            />
                            <ng-template #defaultLogo>
                                <div class="client-template__logo"></div>
                            </ng-template>
                            <div class="client-template__name">
                                {{ customer.clientName }}
                            </div>
                        </div>
                    </nz-option>
                </nz-select>
            </div>
        </div>
        <button
            type="button"
            [disabled]="!buttonActive"
            nz-button
            (click)="onProcessSheet()"
            *hasPermission="['HELP_MGMT', 'HELP_MGMT_VIEW']"
            nzType="primary"
        >
            <i nz-icon nzType="plus" nzTheme="outline"></i>
            <span>{{ buttonAdd ? 'Add' : 'Edit' }} Feedback Report</span>
        </button>
    </div>
    <div class="sheets__table">
        <nz-table
            #sheetsTable
            [nzSize]="'middle'"
            nzHideOnSinglePage
            [nzData]="sheets"
        >
            <thead>
                <tr>
                    <th nzWidth="20%">Feedback Report Name</th>
                    <th nzWidth="45%">Description</th>
                    <th nzWidth="25%">Created</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of sheetsTable.data">
                    <td
                        [innerHTML]="
                            item.sheetName | highlight: query | safe: 'html'
                        "
                    ></td>
                    <td
                        (click)="showDescription(item.description)"
                    >
                        Click to view
                    </td>
                    <td>
                        {{ item.createdAt | date: 'dd MMM yyyy, h:mm a' }}
                    </td>
                    <td class="actions__cell">
                        <ng-container>
                            <i nz-icon nzType="dots" nzTheme="fill"></i>
                            <div class="table-actions-container">
                                <i
                                    (click)="onPreviewSheet(item.id)"
                                    nz-tooltip
                                    nzTooltipPlacement="top"
                                    nzTooltipTitle="Open Report"
                                    nzTooltipOverlayClassName="primary-tooltip"
                                    class="action-icon"
                                    nz-icon
                                    nzType="attributes"
                                    nzTheme="outline"
                                ></i>
                                <i
                                    *hasPermission="['HELP_MGMT']"
                                    (click)="
                                        onDeleteSheet(item.id, item.sheetName)
                                    "
                                    nz-tooltip
                                    nzTooltipPlacement="top"
                                    nzTooltipTitle="Delete"
                                    nzTooltipOverlayClassName="danger-tooltip"
                                    class="action-icon delete"
                                    nz-icon
                                    nzType="delete"
                                    nzTheme="outline"
                                ></i>
                                <i
                                    *hasPermission="[
                                        'HELP_MGMT',
                                        'HELP_MGMT_VIEW'
                                    ]"
                                    (click)="onEditSheet(item.id)"
                                    nz-tooltip
                                    nzTooltipPlacement="top"
                                    nzTooltipTitle="Edit"
                                    nzTooltipOverlayClassName="primary-tooltip"
                                    class="action-icon"
                                    nz-icon
                                    nzType="edit"
                                    nzTheme="outline"
                                ></i>
                            </div>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </div>
    <nz-drawer
        nzMaskClosable
        [nzWidth]="360"
        [nzVisible]="sheetAction"
        [nzTitle]="
            this.sheetAction !== 'edit'
                ? 'Add Feedback Report'
                : 'Edit Feedback Report'
        "
    >
        <ng-container *nzDrawerContent>
            <app-sheet-form
                *ngIf="sheetAction"
                (callback)="onSheetCallback($event)"
                [isSpinning]="isPopupSpinning"
                [action]="sheetAction"
                (spinning)="onSheetSpin($event)"
                [selectedSheet]="loadedSheet"
                (closed)="onCloseSheetDrawer()"
            ></app-sheet-form>
        </ng-container>
    </nz-drawer>

    <nz-drawer
        nzMaskClosable
        [nzWidth]="360"
        [nzVisible]="showDescrAction"
        [nzTitle]="'View Description'"
    >
        <ng-container *nzDrawerContent>
            <app-description-form
                *ngIf="showDescrAction"
                [description]="selDocDescription"
                (closed)="onCloseDescrDrawer()"
            ></app-description-form>
        </ng-container>
    </nz-drawer>
</div>
