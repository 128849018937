import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomerSSOConfig } from 'src/app/store/models/customer.model';
import _get from 'lodash/get';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CustomerService } from 'src/app/core/services/customer/customer.service';
import { NzModalService } from 'ng-zorro-antd/modal';

const URL_REGEXP =
    /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;

@Component({
    selector: 'app-client-sso-config-form',
    templateUrl: './client-sso-config-form.component.html',
    styleUrls: ['./client-sso-config-form.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientSsoConfigFormComponent implements OnInit {
    ssoConfigError;
    @Input() clientId: string;
    @Output() closed: EventEmitter<boolean>;

    ssoConfigLoading: boolean = false;
    ssoConfig: CustomerSSOConfig;

    ssoConfigSaving: boolean = false;

    ssoDisconnecting: boolean = false;

    ssoConfigForm: UntypedFormGroup;

    // secretVisible: boolean = false;

    constructor(
        private cdr: ChangeDetectorRef,
        private message: NzMessageService,
        private customerService: CustomerService,
        private fb: UntypedFormBuilder,
        private modal: NzModalService
    ) {
        this.closed = new EventEmitter<boolean>();
    }

    ngOnInit(): void {
        this.initSSOConfigForm();

        if (this.clientId) {
            this.fetchSSOConfig(this.clientId);
        } else {
            this.message.error(
                `Failed to fetch config for client ${this.clientId}`
            );
            this.closed.emit(false);
        }
    }

    private fetchSSOConfig(clientId: string) {
        this.ssoConfigLoading = true;
        this.cdr.detectChanges();

        this.customerService.getSSOConfig(clientId).subscribe({
            next: (payload) => {
                this.ssoConfig = _get(payload, 'payload');

                this.ssoConfigForm.patchValue({
                    appClientId: this.ssoConfig?.appClientId,
                    // appClientSecret: this.ssoConfig.appClientSecret,
                    idpServerBaseUrl: this.ssoConfig?.idpServerBaseUrl,
                    emailPropertyName: this.ssoConfig?.emailPropertyName,
                });
            },
            error: () => {
                this.ssoConfigLoading = false;
                this.cdr.detectChanges();
            },
            complete: () => {
                this.ssoConfigLoading = false;
                this.cdr.detectChanges();
            },
        });
    }

    private initSSOConfigForm(ssoConfig?: CustomerSSOConfig) {
        this.ssoConfigForm = this.fb.group({
            appClientId: [ssoConfig?.appClientId ?? '', Validators.required],
            // appClientSecret: [
            //     ssoConfig?.appClientId ?? '',
            //     Validators.required,
            // ],
            idpServerBaseUrl: [
                ssoConfig?.appClientId ?? '',
                [Validators.required, Validators.pattern(URL_REGEXP)],
            ],
            emailPropertyName: [ssoConfig?.appClientId ?? ''],
        });
    }

    submitSSOConfigForm() {
        this.ssoConfigSaving = true;
        this.cdr.detectChanges();

        this.customerService
            .postSSOConfig(this.clientId, this.ssoConfigForm.value)
            .subscribe({
                next: () => {
                    this.message.success(`SSO Config saved successfully`, {
                        nzDuration: 4000,
                    });
                },
                error: (error) => {
                    const message = error.error?.message;
                    this.message.error(
                        `Failed to save SSO Config: ${message ? `[${message}]` : ''}`,
                        {
                            nzDuration: 4000,
                        }
                    );
                    this.ssoConfigSaving = false;
                    this.cdr.detectChanges();
                },
                complete: () => {
                    this.ssoConfigSaving = false;
                    this.cdr.detectChanges();

                    this.closed.emit();
                },
            });
    }

    onCancel() {
        this.closed.emit();
    }

    onDisconnect() {
        this.modal.confirm({
            nzTitle: 'Disconnect SSO',
            nzContent: `Are you sure want to disconnect SSO from the client?\n\nAll users with SSO login type will be disconnected.`,
            nzOkDanger: true,
            nzOkText: 'Yes, Disconnect',
            nzCancelText: 'Cancel',
            nzOkLoading: this.ssoDisconnecting,
            nzMaskClosable: true,
            nzOnOk: () => {
                this.ssoDisconnecting = true;
                this.cdr.detectChanges();
                this.customerService.deleteSSOConfig(this.clientId).subscribe({
                    next: () => {
                        this.message.info(`SSO disconnected`, {
                            nzDuration: 3000,
                        });
                    },
                    error: (error) => {
                        const message = error.error.message;
                        this.message.error(
                            `Failed to disconnect SSO ${message ? `: ${message}` : ''}`
                        );
                        this.ssoDisconnecting = false;
                        this.cdr.detectChanges();
                    },
                    complete: () => {
                        this.ssoDisconnecting = false;
                        this.closed.emit();
                        this.cdr.detectChanges();
                    },
                });
            },
        });
    }
}
