<div class="lca-impact-categories-filters-container">
    <!-- years filter -->
    <nz-select
        nzShowSearch
        [nzAllowClear]="true"
        [nzMaxTagCount]="3"
        [nzMaxTagPlaceholder]="maxTagPlaceholder"
        nzMode="multiple"
        (ngModelChange)="onYearsSelected($event)"
        [nzLoading]="yearsLoading"
        [(ngModel)]="selectedYears"
        [nzCustomTemplate]="selectedYearTemplate"
        [nzPlaceHolder]="selectedYears.length ? '' : 'All years'"
    >
        <nz-option
            *ngFor="let year of years"
            [nzLabel]="year"
            nzCustomContent
            [nzValue]="year"
        >
            {{ year }}
        </nz-option>
    </nz-select>
    <ng-template #selectedYearTemplate let-selected>
        <div class="year-template">
            {{ selected.nzLabel }}
        </div>
    </ng-template>
    <!-- activity types filter -->
    <nz-select
        nzShowSearch
        [nzAllowClear]="true"
        *ngIf="impactTypesLoading || impactTypes?.length > 0"
        [nzMaxTagCount]="1"
        [nzMaxTagPlaceholder]="maxTagPlaceholder"
        nzMode="multiple"
        (ngModelChange)="onImpactTypeSelected($event)"
        [nzLoading]="impactTypesLoading"
        [(ngModel)]="selectedImpactTypeIds"
        [nzCustomTemplate]="selectedImpactTypeTemplate"
        [nzPlaceHolder]="selectedImpactTypeIds.length ? '' : 'All impact types'"
    >
        <nz-option
            *ngFor="let impactType of impactTypes"
            [nzLabel]="impactType.impactTypeName"
            nzCustomContent
            [nzValue]="impactType.impactTypeId"
        >
            {{ impactType.impactTypeName }}
        </nz-option>
    </nz-select>
    <ng-template #selectedImpactTypeTemplate let-selected>
        <div class="impact-type-template">
            {{ selected.nzLabel }}
        </div>
    </ng-template>
    <a
        (click)="resetFilters()"
        class="reset-filter"
        *ngIf="showResetFilters"
        nz-button
        nzSize="small"
        nzType="link"
        >Reset filters</a
    >
    <ng-template #maxTagPlaceholder let-selectedList>
        +{{ selectedList.length }}
    </ng-template>
</div>
