import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import _get from 'lodash/get';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AttributeService } from '../../../core/services/attribute/attribute.service';
import { AttributeType } from '../../../store/models/attribute.model';

@Component({
    selector: 'app-attribute-type-form',
    templateUrl: './attribute-type-form.component.html',
    styleUrls: ['./attribute-type-form.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeTypeFormComponent implements OnInit {
    @Input() clientId: string;
    @Input() attributeType: AttributeType;
    @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

    attributeTypeFormGroup: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private message: NzMessageService,
        private attributeService: AttributeService
    ) {}

    ngOnInit() {
        this.initAttributeForm();
    }

    private initAttributeForm() {
        this.attributeTypeFormGroup = this.fb.group({
            attrTypeName: [
                _get(this.attributeType, 'attrTypeName'),
                Validators.required,
            ],
            attributes: [(this.attributeType?.attributes ?? []).map(attribute => attribute.attrName)]
        });
    }

    onSubmit() {
        const attributes = (_get(this.attributeTypeFormGroup.value, 'attributes', []) ?? [])
            .map(
                attrName => {
                    const existingAttr = this.attributeType?.attributes?.find(attr => attr.attrName === attrName);
                    
                    return existingAttr ?? { attrName }
                }
            );

        if (this.attributeType && this.attributeType.attrTypeId) {
            this.attributeService
                .putAttributeType({
                    attrTypeId: this.attributeType.attrTypeId,
                    clientId: this.clientId,
                    ...this.attributeTypeFormGroup.value,
                    attributes
                })
                .subscribe(() => {
                    this.closed.emit(true);
                    this.message.success(
                        `Attribute type <b>${
                            this.attributeTypeFormGroup.get('attrTypeName')
                                .value
                        }</b> has been updated`,
                        {
                            nzDuration: 3000,
                        }
                    );
                });
        } else {
            this.attributeService
                .postAttributeType({
                    clientId: this.clientId,
                    ...this.attributeTypeFormGroup.value,
                    attributes
                })
                .subscribe(() => {
                    this.closed.emit(true);
                    this.message.success(
                        `Attribute type <b>${
                            this.attributeTypeFormGroup.get('attrTypeName')
                                .value
                        }</b> has been created`,
                        {
                            nzDuration: 3000,
                        }
                    );
                });
        }
    }

    onClose() {
        this.closed.emit(false);
    }
}
