import {
    Component,
    OnInit,
    OnDestroy,
    TemplateRef,
    ChangeDetectorRef,
} from '@angular/core';
import {
    HttpEventType,
    HttpErrorResponse,
    HttpEvent,
} from '@angular/common/http';
import { Subject, forkJoin, of } from 'rxjs';
import _get from 'lodash/get';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { HelpService } from '../../../core/services/help/help.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CoreService } from '../../../core/services/core/core.service';
import { TicketingService } from 'src/app/core/services/ticketing/ticketing.service';
import { TicketForm } from 'src/app/store/models/ticket-form.model';
import { GlobalService } from 'src/app/core/services/global/global.service';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.less'],
})
export class HelpComponent implements OnInit, OnDestroy {
    @ViewChild('termsPreview') termsPreview: ElementRef;
    @ViewChild('guidePreview') guidePreview: ElementRef;

    @ViewChild('ticketFormContentTemplate')
    ticketFormContentTemplate: TemplateRef<any>;

    selectedTabIndex: number; // the index of currently selected tab
    selectedClientId: string; // the current selected client from select box
    defaultClientId: string; // the default client id

    loadedFile: any; // used for editing a file
    termsFile: any; // used for keeping the terms file
    guideFile: any; // used for keeping the guide file
    docAction: string; // used for indicating an action (add or edit)
    showDescrAction: boolean; // used for showing description
    selDocDescription: string; // holds a document's description

    files: any[]; // keeps the retrieved documents
    type: string = 'guide'; // indicates a particular tab
    query: string; // may be used in querying documents

    buttonAdd: boolean = true; // is button in add or edit mode?
    buttonActive: boolean = true; // enables/disables the add button

    currentUser: {
        clientId: string;
        isSuperAdmin: boolean;
        isAiUser: boolean;
        isHelpEditor: boolean;
        isHelpViewer: boolean;
    }; // keeps current user's clientId and roles presence flags

    isSpinning: boolean = false;
    isPopupSpinning: boolean = false;

    ticketsLoading: boolean = false;
    tickets: TicketForm[] = [];

    documentsLoading: boolean = false;

    loading: boolean = false;

    ngDestroyed$ = new Subject();

    constructor(
        private helpService: HelpService,
        private modal: NzModalService,
        private message: NzMessageService,
        private router: Router,
        private coreService: CoreService,
        private ticketingService: TicketingService,
        private cdr: ChangeDetectorRef,
        private globalService: GlobalService
    ) {
        this.currentUser = {
            clientId: undefined,
            isSuperAdmin: false,
            isAiUser: false,
            isHelpEditor: false,
            isHelpViewer: false,
        };
    }

    // Lifecycle method callbacks
    ngOnInit() {
        this.ticketsLoading = true;
        this.helpService
            .getHelpdeskTicketForms()
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    this.tickets = _get(payload, 'payload', []);
                },
                error: (error) => {
                    this.message.error(
                        `Failed to fetch ticket forms: ${_get(error, [
                            'error',
                        ])}!`,
                        {
                            nzDuration: 6000,
                        }
                    );
                },
                complete: () => {
                    this.ticketsLoading = false;
                },
            });

        this.loading = true;

        this.globalService.clientId$.pipe(takeUntil(this.ngDestroyed$))
            .subscribe(
                clientId => {
                    if (clientId) {
                        this.onClientSelected(clientId);
                    }
                }
            )

        this.coreService
            .getProfile()
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    const userRoles = _get(payload, ['payload', 'userRoles']);

                    if (userRoles.indexOf('SUPERUSER') !== -1) {
                        this.currentUser.isSuperAdmin = true;
                    }
                    if (
                        userRoles.indexOf('AIUSER') !== -1 ||
                        userRoles.indexOf('AIADMIN') !== -1
                    ) {
                        this.currentUser.isAiUser = true;
                    }
                    if (userRoles.indexOf('HELP_MGMT_VIEW') !== -1) {
                        this.currentUser.isHelpViewer = true;
                    }
                    if (userRoles.indexOf('HELP_MGMT') !== -1) {
                        this.currentUser.isHelpEditor = true;
                    }

                    if (!this.currentUser.isAiUser && !this.currentUser.isSuperAdmin) {
                        this.currentUser.clientId = _get(payload, [
                            'payload',
                            'clientId',
                        ]);

                        this.selectedClientId = this.currentUser.clientId;
                    }

                    this.helpService
                        .retrieveDefaultClientId()
                        .pipe(takeUntil(this.ngDestroyed$))
                        .subscribe({
                            next: (payload) => {
                                this.defaultClientId = _get(payload, 'id', '');
                                this.loading = false;
                            },
                            error: (response) => {
                                this.displayErrorMessage(
                                    response,
                                    'Cannot fetch the file from server.'
                                );
                            },
                            complete: () => {
                                this.cdr.detectChanges();
                                this.onSelectedTabChanged(0);
                            },
                        });
                },
                error: (response) => {
                    this.displayErrorMessage(
                        response,
                        'Cannot retrieve the user profile.'
                    );
                },
            });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
        this.ngDestroyed$.complete();
    }

    // Listeners from tabs other than documents
    onEditDocument(fileId: string) {
        this.editFile({ fileId });
    }

    onPreviewDocument(fileId: string) {
        this.router.navigate(['/help/preview/document', fileId], {
            queryParams: { clientId: this.selectedClientId },
        });
    }

    onDownloadDocument(fileId: string) {
        this.downloadFile({ fileId });
    }

    onDeleteDocument(fileId: string, docName: string) {
        this.deleteFile({ fileId, docName });
    }

    onAddDocument() {
        this.loadedFile = null;
        this.isPopupSpinning = false;
        this.docAction = 'add';
    }

    showTicketingForm(ticket: TicketForm) {
        this.ticketingService.showTicketingForm(
            ticket,
            this.ticketFormContentTemplate
        );
    }

    onProcessDoc() {
        if (this.buttonAdd) {
            this.onAddDocument();
        } else {
            const docId = this.guideFile
                ? this.guideFile.id
                : this.termsFile.id;
            this.onEditDocument(docId);
        }
    }

    // Listeners from drawer events
    onCloseDocDrawer() {
        this.loadedFile = null;
        this.docAction = null;
    }

    onCloseDescrDrawer() {
        this.selDocDescription = null;
        this.showDescrAction = false;
    }

    onDocCallback(payload: any) {
        const action = _get(payload, 'action', '');

        if (action == 'add') {
            this.createFile(payload);
        } else if (action == 'edit') {
            this.editFile(payload);
        } else if (action == 'update') {
            this.updateFile(payload);
        } else if (action == 'delete') {
            this.deleteFile(payload);
        } else if (action == 'download') {
            this.downloadFile(payload);
        }
    }

    onDocSpin(status: any) {
        this.isPopupSpinning = status;
    }

    // Listeners from other components
    onClientSelected(clientId: string) {
        this.selectedClientId = clientId;
        this.buttonActive = true;
        this.loadTabContent();
    }

    onSelectedTabChanged(index: number) {
        this.selectedTabIndex = index;

        if (this.selectedTabIndex) {
            localStorage.removeItem('clientId');
            localStorage.removeItem('tabIndex');
        }

        // reset variable as required per case
        this.buttonAdd = true;
        this.buttonActive = true;
        this.guideFile = undefined;
        this.termsFile = undefined;

        if (this.selectedTabIndex == 0) {
            this.type = 'guide';
            this.retrieveGuide();
        } else if (this.selectedTabIndex == 1) {
            this.type = 'terms';
            this.retrieveTerms();
        } else if (this.selectedTabIndex == 2) {
            this.type = 'docs';
            this.files = [];
            this.retrieveFiles();
        }
    }

    // CRUD operations
    retrieveFiles() {
        this.documentsLoading = true;
        this.cdr.detectChanges();

        this.helpService
            .getDocuments(this.selectedClientId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    this.files = payload;
                },
                error: (response) => {
                    this.displayErrorMessage(
                        response,
                        'Cannot fetch the files from server.'
                    );
                    this.documentsLoading = false;
                    this.cdr.detectChanges();
                },
                complete: () => {
                    this.documentsLoading = false;
                    this.cdr.detectChanges();
                }
            });
    }

    retrieveGuide() {
        this.retrieveTabFile(0);
    }

    retrieveTerms() {
        this.retrieveTabFile(1);
    }

    retrieveTabFile(tabIndex: number) {
        this.setSpinningAndButtonWhileLoading();

        const tabPreview = this.getTabPreviewElement();

        if (!this.selectedClientId) {
            if (tabPreview) {
                this.helpService.fillPreviewFrame(
                    tabPreview,
                    'text/html',
                    null
                );
            }
            this.setSpinningAndButtonAfterLoading(tabIndex);
            return;
        }

        let clientId =
            this.selectedTabIndex === 0
                ? this.defaultClientId
                : this.selectedClientId;

        this.helpService
            .retrieveDefaultDocument(clientId, this.type)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    if (payload) {
                        this.setTabFileHolder(payload);
                        this.helpService
                            .getAccessToken(
                                clientId,
                                _get(this.getTabFileHolder(), 'id', ''),
                                this.type
                            )
                            .pipe(takeUntil(this.ngDestroyed$))
                            .subscribe({
                                next: (token) => {
                                    const url = _get(token, 'sasToken', '');
                                    this.helpService.fetchRemoteFile(
                                        url,
                                        tabPreview,
                                        () => {
                                            if (
                                                (tabIndex == 0 &&
                                                    this.currentUser
                                                        .isAiUser) ||
                                                tabIndex == 1
                                            ) {
                                                this.buttonAdd = false;
                                            }

                                            this.setSpinningAndButtonAfterLoading(
                                                tabIndex
                                            );
                                        }
                                    );
                                },
                                error: (response) => {
                                    this.setSpinningAndButtonAfterLoading(
                                        tabIndex
                                    );
                                    this.displayErrorMessage(
                                        response,
                                        'Cannot create a file access token.'
                                    );
                                },
                            });
                    } else {
                        this.setTabFileHolder(undefined);
                        this.buttonAdd = true;
                        this.setSpinningAndButtonAfterLoading(tabIndex);
                        this.helpService.fillPreviewFrame(
                            tabPreview,
                            'text/html',
                            null
                        );
                    }
                },
                error: (response) => {
                    this.setSpinningAndButtonAfterLoading(tabIndex);
                    this.displayErrorMessage(
                        response,
                        'Cannot fetch the file from server.'
                    );
                },
            });
    }

    createFile(payload: any) {
        const formData = this.constructDocFormDataToSubmit(payload);
        payload.inProgress = true;

        this.helpService
            .createDocument(formData)
            .pipe(
                map((event) => {
                    return this.handleUploadEvent(event, payload);
                }),
                catchError((response: HttpErrorResponse) => {
                    return this.handleUploadError(response, payload);
                })
            )
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (event: any) => {
                    this.handleUploadResult(event, payload);
                },
                error: (response) => {
                    this.isPopupSpinning = false;
                    this.displayErrorMessage(
                        response,
                        'Cannot create the file.'
                    );
                },
            });
    }

    updateFile(payload: any) {
        const formData = this.constructDocFormDataToSubmit(payload);
        payload.inProgress = true;

        this.helpService
            .updateDocument(formData, payload.id)
            .pipe(
                map((event) => {
                    return this.handleUploadEvent(event, payload);
                }),
                catchError((response: HttpErrorResponse) => {
                    return this.handleUploadError(response, payload, true);
                })
            )
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (event: any) => {
                    this.handleUploadResult(event, payload, true);
                },
                error: (response) => {
                    this.isPopupSpinning = false;
                    this.displayErrorMessage(
                        response,
                        'Cannot update the file.'
                    );
                },
            });
    }

    downloadFile(payload: any) {
        const fileId = _get(payload, 'fileId', '');

        this.helpService
            .getAccessToken(this.selectedClientId, fileId, this.type)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    let sasToken = _get(payload, 'sasToken', '');
                    let docName = _get(payload, 'docName', '');
                    this.helpService.downloadFile(sasToken, docName);
                },
                error: (response) => {
                    this.displayErrorMessage(
                        response,
                        'Cannot download the file.'
                    );
                },
            });
    }

    deleteFile(payload: any) {
        const fileId = _get(payload, 'fileId', '');
        const docName = _get(payload, 'docName', '');

        this.modal.confirm({
            nzTitle: `Do you want to remove document <b>${docName}</b>?`,
            nzContent: `
         If you delete it you will not be able to recover document.<br>
         Do you want to delete the document?
       `,
            nzOnOk: () =>
                this.helpService
                    .deleteDocument(this.selectedClientId, fileId)
                    .pipe(takeUntil(this.ngDestroyed$))
                    .subscribe({
                        next: () => {
                            this.message.success(
                                `Document <b>${docName}</b> has been deleted`,
                                { nzDuration: 3000 }
                            );

                            this.loadTabContent();
                        },
                        error: (response) => {
                            this.displayErrorMessage(
                                response,
                                'Cannot delete the file.'
                            );
                        },
                    }),
            nzOkText: 'Yes, Remove',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzCancelText: 'Close',
            nzClosable: false,
            nzOnCancel: () => console.log('Cancel'),
        });
    }

    editFile(payload: any) {
        const fileId = _get(payload, 'fileId', '');

        this.isPopupSpinning = true;

        let clientId = this.selectedClientId;
        if (this.selectedTabIndex == 0) {
            clientId = this.defaultClientId;
        }

        forkJoin([
            this.helpService.getAccessToken(clientId, fileId, this.type),
            this.helpService.retrieveDocument(clientId, fileId)
        ]).pipe(
            takeUntil(this.ngDestroyed$)
        ).subscribe({
            next: results => {
                const [token, payload] = results;

                this.loadedFile = {
                    id: _get(payload, 'id', ''),
                    docName: _get(payload, 'docName', ''),
                    description: _get(payload, 'description', ''),
                    blobName: _get(payload, 'blobName', ''),
                    fileName: _get(payload, 'fileName', ''),
                    url: _get(token, 'sasToken', ''),
                };

                this.docAction = 'edit';
            },
            error: (response) => {
                this.displayErrorMessage(
                    response,
                    'Cannot fetch the file from server.'
                );
            },
        })
    }

    showDescription(description: string) {
        this.selDocDescription = description;
        this.showDescrAction = true;
    }

    setSpinningAndButtonWhileLoading() {
        this.isSpinning = true;
        this.buttonActive = false;
    }

    setSpinningAndButtonAfterLoading(tabIndex: number) {
        this.isSpinning = false;

        if (tabIndex == 0) {
            if (this.currentUser.isAiUser) {
                this.buttonActive = true; // only superadmins can edit
            }
        } else if (tabIndex == 1) {
            if (
                (this.currentUser.isAiUser ||
                    this.currentUser.isHelpEditor ||
                    this.currentUser.isHelpViewer) &&
                this.selectedClientId
            ) {
                // if there is selected client and for editors only
                this.buttonActive = true;
            }
        } else {
            // 1st & 2nd tab by default have a disabled button
            this.buttonActive = true;
        }
    }

    loadTabContent() {
        if (this.selectedTabIndex == 0) {
            this.retrieveGuide();
        } else if (this.selectedTabIndex == 1) {
            this.retrieveTerms();
        } else if (this.selectedTabIndex == 2) {
            this.retrieveFiles();
        }
    }

    handleUploadEvent(event: HttpEvent<any>, payload: any) {
        switch (event.type) {
            case HttpEventType.UploadProgress:
                let progress = Math.round((event.loaded * 100) / event.total);
                payload.progress = progress > 5 ? progress - 5 : progress;
                break;
            case HttpEventType.Response:
                return event;
        }
    }

    handleUploadError(
        response: HttpErrorResponse,
        payload: any,
        editAction: boolean = false
    ) {
        this.isPopupSpinning = false;

        payload.inProgress = false;
        payload.error = response.error.message;

        this.message.error(response.error.message, { nzDuration: 3000 });
        if (editAction) {
            this.docAction = 'edit';
        }

        return of(
            `The file <b>${payload.docName}</b> failed to be ${
                editAction ? 'updated' : 'created'
            }!`
        );
    }

    handleUploadResult(event: any, payload: any, editAction: boolean = false) {
        if (typeof event === 'object') {
            payload.progress = 100;

            this.loadTabContent();

            this.message.success(
                `The file <b>${payload.docName}</b> has been ${
                    editAction ? 'updated' : 'created'
                }!`,
                { nzDuration: 3000 }
            );
            this.docAction = null;
        }
    }

    displayErrorMessage(response: any, defaultMessage: string) {
        const message =
            response.error && response.error.message
                ? response.error.message
                : defaultMessage;
        this.message.error(message, { nzDuration: 3000 });
    }

    getTabPreviewElement() {
        if (this.selectedTabIndex == 0) {
            return this.guidePreview;
        } else if (this.selectedTabIndex == 1) {
            return this.termsPreview;
        }
    }

    setTabFileHolder(payload: any) {
        if (this.selectedTabIndex == 0) {
            this.guideFile = payload;
        } else if (this.selectedTabIndex == 1) {
            this.termsFile = payload;
        }
    }

    getTabFileHolder() {
        if (this.selectedTabIndex == 0) {
            return this.guideFile;
        } else if (this.selectedTabIndex == 1) {
            return this.termsFile;
        }
    }

    constructDocFormDataToSubmit(payload: any) {
        const formData = new FormData();
        let clientId = this.selectedClientId;
        if (this.selectedTabIndex == 0) {
            clientId = this.defaultClientId;
        }
        formData.append('clientId', clientId);
        formData.append('type', this.type);
        formData.append('file', payload.data);
        formData.append('docName', payload.docName);
        if (payload.id) {
            formData.append('id', payload.id);
        }
        formData.append('description', payload.description);

        return formData;
    }
}
