import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    UntypedFormControl,
} from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LcaService } from 'src/app/core/services/lca/lca.service';
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';
import _concat from 'lodash/concat';
import _keyBy from 'lodash/keyBy';
import _flatten from 'lodash/flatten';
import {
    firstValueFrom,
    interval,
    Observable,
    Subject,
    Subscription,
} from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ReportConfig } from 'src/app/store/models/report.model';
import * as pbi from 'powerbi-client';
import { IEmbedSettings } from 'embed';
import { LCAImpactCateogry } from 'src/app/store/models/lca-impact-category';
import { LCAActivitySerial } from 'src/app/store/models/lca-activity-serial.model';
import { GlobalNotificationService } from 'src/app/core/services/global-notification/global-notification.service';
import { NotificationEventType } from 'src/app/store/models/notification.model';

enum LoadTypes {
    CSV = 0,
    Database,
}

enum Steps {
    ModelData = 0,
    ImpactCategories,
    SPAActivitySerials,
    Sandbox,
    Report,
}

export enum LCASandBoxStatuses {
    Running = 'LCA_SANDBOX_RUNNING',
    Created = 'LCA_SANDBOX_CREATED',
    Waiting = 'LCA_SANDBOX_WAITING_VM',
    ReportReady = 'LCA_SANDBOX_REPORT_READY',
    Failed = 'LCA_SANDBOX_RUN_FAILED',
    AssessmentComplete = 'LCA_SANDBOX_ASSESSMENT_COMPLETE'
}

@Component({
    selector: 'app-lca-engine-sandbox-page',
    templateUrl: './lca-engine-sandbox-page.component.html',
    styleUrls: ['./lca-engine-sandbox-page.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LcaEngineSandboxPageComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    loading: boolean = false;
    status: LCASandBoxStatuses;

    statuses = LCASandBoxStatuses;

    sandboxStatusLoading: boolean = false;
    sandboxError: string;

    selectedModelIndex: number = 0;
    modelDataCSVFormMode: boolean = false;
    modelDataDatabaseFormMode: boolean = false;
    modifySPAFormMode: boolean = false;

    loadTypes = LoadTypes;

    steps = Steps;
    currentStepIndex = 0;

    sandboxInitError: string;

    spaThresholdParamsFormGroup: UntypedFormGroup;

    impactCategoriesUpdating: boolean = false;

    modelsForm: UntypedFormGroup;

    reportName: string;
    loadedPowerBiReport: boolean = false;

    runningSandbox: boolean = false;
    cancelRun$: Observable<boolean>;

    loadResultByIndex: Map<number, { type: LoadTypes; result: string }> =
        new Map();
    modelsLoaded: boolean = false;

    //spa activity serials table specific variables
    spaSerialsLoading: boolean = false;
    spaSerialsSearchText: string;
    showSelectedSPASerials: boolean = false;
    spaSerials: LCAActivitySerial[] = [];
    impactCategoryNameByImpactSerial: {
        [key: number]: string;
    } = {};
    notFilteredSPASerials: LCAActivitySerial[] = [];
    spaSerialsIndeterminate: boolean = false;
    spaSerialsAllChecked: boolean = false;
    setOfSpaSerialsChecked = new Set<string>();
    spaSerialsDebouncer: Subject<boolean> = new Subject();

    reportLoaded: boolean = false;
    reportFailed: boolean = false;
    reportFailedMsg: string;

    setOfImpactCategoriesChecked = new Set<number>();

    destroyed$ = new Subject();

    @ViewChild('reportContainer') reportContainer: ElementRef;

    constructor(
        public lcaService: LcaService,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private message: NzMessageService,
        private modals: NzModalService,
        private router: Router,
        private globalNotifications: GlobalNotificationService
    ) {
        this.spaThresholdParamsFormGroup = this.fb.group({
            spaLifeCycleStage: ['', [Validators.min(1), Validators.max(19)]],
        });
    }

    ngOnInit() {
        this.loading = true;

        this.fetchSandboxStatus(false, true);

        this.initModelsForm();

        this.spaSerialsDebouncer
            .pipe(takeUntil(this.destroyed$), debounceTime(350))
            .subscribe(() => {
                this.spaSerialsLoading = true;
                this.cdr.detectChanges();

                if (!this.spaSerialsSearchText) {
                    this.spaSerials = this.notFilteredSPASerials;
                } else {
                    this.spaSerials = this.notFilteredSPASerials.filter(
                        ({ activityName, activitySerial, year, modelName }) => {
                            const query =
                                this.spaSerialsSearchText.toLowerCase();

                            return (
                                activityName.toLowerCase().includes(query) ||
                                `${activitySerial}`
                                    .toLowerCase()
                                    .includes(query) ||
                                `${modelName}`.toLowerCase().includes(query) ||
                                `${year}`.toLowerCase().includes(query)
                            );
                        }
                    );
                }

                if (this.showSelectedSPASerials) {
                    this.spaSerials = this.spaSerials.filter(
                        (item: LCAActivitySerial) =>
                            this.setOfSpaSerialsChecked.has(
                                this.getSerialKey(item)
                            )
                    );
                }

                this.refreshSPAActivitySerialCheckedStatus();

                this.spaSerialsLoading = false;
                this.cdr.detectChanges();
            });

        this.globalNotifications
            .listenNotifications(NotificationEventType.LCA_SANDBOX_STATUS)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
                () => {
                    this.fetchSandboxStatus(true);   
                }
            )
    }

    private onTabVisibilityChange() {
        if (document.visibilityState === "visible") {
            this.fetchSandboxStatus(true);
        }
    }

    ngAfterViewInit() {
        document.addEventListener("visibilitychange", this.onTabVisibilityChange.bind(this));
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        document.removeAllListeners('visibilitychange');
    }

    onDeleteDatamodel(index: number) {
        const controls = (
            this.modelsForm.get('models') as UntypedFormArray
        ).controls.filter((control) => !control.value.deleted);
        const modelControl = controls[index];
        const modelName = modelControl.get('modelName').value;

        if (!modelControl) {
            return;
        }

        if (this.loadResultByIndex.has(index)) {
            this.modals.confirm({
                nzTitle: `Do you want to delete model ${modelName}?`,
                nzContent: `
      If you delete the model, all associated uploaded data during setup will be deleted too.
    `,
                nzOnOk: () => {
                    this.lcaService.deleteModel(modelName).subscribe({
                        next: () => {
                            (
                                this.modelsForm.get('models') as UntypedFormArray
                            ).controls[index].patchValue({ deleted: true });
                            this.loadResultByIndex.delete(index);
                            this.modelsLoaded =
                                controls.length - 1 ===
                                this.loadResultByIndex.size;
                            this.cdr.detectChanges();
                            if (controls.length - 1 === 0) {
                                this.onAddModelInput();
                            }
                        },
                        error: (error) => {
                            this.message.error(
                                `Failed to delete model ${modelName}: '${_get(
                                    error,
                                    ['error', 'errors', 0]
                                )}'!`,
                                { nzDuration: 5000 }
                            );
                        },
                    });
                },
                nzOkText: 'Yes, delete',
                nzOkType: 'primary',
                nzOkDanger: true,
                nzCancelText: 'Close',
                nzClosable: false,
            });
        } else {
            (this.modelsForm.get('models') as UntypedFormArray).controls[
                index
            ].patchValue({ deleted: true });
            this.modelsLoaded =
                controls.length - 1 === this.loadResultByIndex.size;
            this.cdr.detectChanges();
        }
    }

    onAddModelInput() {
        const modelsControl = <UntypedFormArray>this.modelsForm.get('models');

        const modelFormGroup = this.fb.group({
            modelName: ['', Validators.required],
            deleted: [false],
            supplyChainTier: [
                undefined,
                [Validators.min(0), Validators.max(10)],
            ],
            techFlowEnabled: [false],
            elemFlowEnabled: [false],
        });

        modelsControl.push(modelFormGroup);

        this.modelsLoaded =
            (this.modelsForm.get('models') as UntypedFormArray).controls.filter(
                (control) => !control.value.deleted
            ).length === this.loadResultByIndex.size;
        this.cdr.detectChanges();
    }

    private initModelsForm() {
        this.modelsForm = this.fb.group({
            models: this.fb.array([
                this.fb.group({
                    modelName: ['', Validators.required],
                    deleted: [false],
                    supplyChainTier: [
                        undefined,
                        [Validators.min(0), Validators.max(10)],
                    ],
                    techFlowEnabled: [false],
                    elemFlowEnabled: [false],
                }),
            ]),
        });

        this.modelsForm
            .get('models')
            .valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe((value) => {
                value.forEach((formModelValue, index) => {
                    if (
                        formModelValue.elemFlowEnabled ||
                        formModelValue.techFlowEnabled
                    ) {
                        if (Number.isInteger(formModelValue.supplyChainTier)) {
                            (
                                this.modelsForm.get('models') as UntypedFormArray
                            ).controls[index].patchValue({
                                supplyChainTier: null,
                            });
                        }
                        if (
                            !(
                                this.modelsForm.get('models') as UntypedFormArray
                            ).controls[index].get('supplyChainTier').disabled
                        ) {
                            (
                                this.modelsForm.get('models') as UntypedFormArray
                            ).controls[index]
                                .get('supplyChainTier')
                                .disable();
                        }
                    } else {
                        if (
                            (
                                this.modelsForm.get('models') as UntypedFormArray
                            ).controls[index].get('supplyChainTier').disabled
                        ) {
                            (
                                this.modelsForm.get('models') as UntypedFormArray
                            ).controls[index]
                                .get('supplyChainTier')
                                .enable();
                        }
                    }

                    (
                        this.modelsForm.get('models') as UntypedFormArray
                    ).controls.forEach((control, i, array) => {
                        if (array && array.length > 1) {
                            const modelNamesBefore = array
                                .slice(0, i)
                                .map((item) => item.get('modelName').value);
                            if (
                                modelNamesBefore.includes(
                                    control.get('modelName').value
                                )
                            ) {
                                control
                                    .get('modelName')
                                    .setErrors({ duplicatedName: true });
                            } else {
                                control.get('modelName').setErrors(null);
                            }
                        }
                    });
                });
            });

        this.cdr.detectChanges();
    }

    loadModelDataCSV(index: number) {
        this.selectedModelIndex = index;
        this.modelDataCSVFormMode = true;

        this.cdr.detectChanges();
    }

    closeModelDataCSVFormModel(loaded: boolean = false) {
        if (loaded) {
            this.loadResultByIndex.set(this.selectedModelIndex, {
                type: LoadTypes.CSV,
                result: 'Loaded',
            });
            if (
                (this.modelsForm.get('models') as UntypedFormArray).controls.filter(
                    (control) => !control.value.deleted
                ).length === this.loadResultByIndex.size
            ) {
                this.modelsLoaded = true;
            }
        }

        this.selectedModelIndex = null;
        this.modelDataCSVFormMode = false;

        this.cdr.detectChanges();
    }

    loadModelDataDatabase(index: number) {
        this.selectedModelIndex = index;
        this.modelDataDatabaseFormMode = true;

        this.cdr.detectChanges();
    }

    closeModelDataDatabaseFormModel(result: string) {
        if (result) {
            this.loadResultByIndex.set(this.selectedModelIndex, {
                type: LoadTypes.Database,
                result,
            });
            if (
                (this.modelsForm.get('models') as UntypedFormArray).controls.filter(
                    (control) => !control.value.deleted
                ).length === this.loadResultByIndex.size
            ) {
                this.modelsLoaded = true;
            }
        }

        this.selectedModelIndex = null;
        this.modelDataDatabaseFormMode = false;

        this.cdr.detectChanges();
    }

    onModifySPA() {
        this.modifySPAFormMode = true;
        this.cdr.detectChanges();
    }

    closeModifySPA() {
        this.modifySPAFormMode = false;
        this.cdr.detectChanges();
    }

    onSearchSPASerials(value: string) {
        this.spaSerialsSearchText = value;
        this.spaSerialsDebouncer.next(true);
    }

    onImpactCategoriesChanged({
        checked,
        categories,
    }: {
        checked: Set<number>;
        categories: LCAImpactCateogry[];
    }) {
        this.setOfImpactCategoriesChecked = checked;
        this.impactCategoryNameByImpactSerial = _keyBy(
            categories,
            'impactSerial'
        );

        categories.forEach(({ impactSerial }) => {
            if (checked.has(impactSerial)) {
                this.spaThresholdParamsFormGroup.addControl(
                    `spaThreshold#${impactSerial}`,
                    new UntypedFormControl(undefined, [Validators.min(0), Validators.max(1)])
                );
            } else {
                this.spaThresholdParamsFormGroup.removeControl(
                    `spaThreshold#${impactSerial}`,
                    { emitEvent: false }
                );
            }
        });
    }

    private getSerialKey = (serial: LCAActivitySerial) => {
        return `${serial.modelName}_${serial.activitySerial}`;
    };

    //SPA Serials table
    updateSPAActivitySerialCheckedSet(
        item: LCAActivitySerial,
        checked: boolean
    ): void {
        if (checked) {
            this.setOfSpaSerialsChecked.add(this.getSerialKey(item));
        } else {
            this.setOfSpaSerialsChecked.delete(this.getSerialKey(item));
        }
    }

    private refreshSPAActivitySerialCheckedStatus(): void {
        if (this.spaSerials && this.spaSerials.length) {
            this.spaSerialsAllChecked = this.spaSerials.every(
                (item: LCAActivitySerial) =>
                    this.setOfSpaSerialsChecked.has(this.getSerialKey(item))
            );
            this.spaSerialsIndeterminate =
                this.spaSerials.some((item: LCAActivitySerial) =>
                    this.setOfSpaSerialsChecked.has(this.getSerialKey(item))
                ) && !this.spaSerialsAllChecked;
            this.cdr.detectChanges();
        }
    }

    onSPASerialsAllChecked(checked: boolean) {
        this.spaSerials.forEach((item: LCAActivitySerial) =>
            this.updateSPAActivitySerialCheckedSet(item, checked)
        );
        this.refreshSPAActivitySerialCheckedStatus();
        this.cdr.detectChanges();
    }

    onSPASerialChecked(item: LCAActivitySerial, checked: boolean) {
        this.updateSPAActivitySerialCheckedSet(item, checked);
        this.refreshSPAActivitySerialCheckedStatus();
    }

    onShowSelectedSPASerialsChanged() {
        this.spaSerialsDebouncer.next(true);
    }

    private async fetchSPAActivitySerials() {
        this.spaSerialsLoading = true;
        this.spaSerialsAllChecked = false;
        this.spaSerialsIndeterminate = false;
        this.notFilteredSPASerials = [];
        this.spaSerialsSearchText = null;
        this.showSelectedSPASerials = false;
        this.cdr.detectChanges();

        const serials = await Promise.all(
            (this.modelsForm.get('models') as UntypedFormArray).controls.map(
                async (modelControl) => {
                    const modelName = modelControl.get('modelName').value;
                    const payload = await firstValueFrom(
                        this.lcaService.getSandboxSPAActivitySerials(modelName)
                    );

                    return _get(payload, 'payload', []).map((item) => ({
                        ...item,
                        modelName,
                    }));
                }
            )
        );

        this.notFilteredSPASerials = this.spaSerials = _flatten(serials);
        this.spaSerialsLoading = false;

        this.cdr.detectChanges();
    }

    get models() {
        return this.modelsForm.get('models') as UntypedFormArray;
    }

    get selectedModel(): any {
        return (this.modelsForm.get('models') as UntypedFormArray).controls[
            this.selectedModelIndex || 0
        ].value;
    }

    getModelLoadStatus(index: number): { type: LoadTypes; result: string } {
        if (this.loadResultByIndex.has(index)) {
            return this.loadResultByIndex.get(index);
        }

        return undefined;
    }

    onNextStep() {
        if (this.currentStepIndex === Steps.ModelData) {
            this.currentStepIndex += 1;
        }
        if (this.currentStepIndex === Steps.ImpactCategories) {
            this.updateImpactCategories();
        }
        if (this.currentStepIndex === Steps.SPAActivitySerials) {
            this.setSPAActivitiesConfig();
        }
    }

    onPreviousStep() {
        this.currentStepIndex -= 1;
    }

    setSPAActivitiesConfig(useNewLcaEngine: boolean = false) {
        const { spaLifeCycleStage, ...impactSerialsThreshold } =
            this.spaThresholdParamsFormGroup.value;

        const spaActivitiesByModelName = this.spaSerials.reduce(
            (result, spaSerial) => {
                if (!result[spaSerial.modelName]) {
                    result[spaSerial.modelName] = [];
                }

                if (
                    this.setOfSpaSerialsChecked.has(
                        this.getSerialKey(spaSerial)
                    )
                ) {
                    result[spaSerial.modelName].push(spaSerial.activitySerial);
                }

                return result;
            },
            {}
        );

        const spaActivities = [];
        const impactSerialsThresholdValues = [];

        for (const impactSerialKey of Object.keys(impactSerialsThreshold)) {
            const [, impactSerial] = impactSerialKey.split('#');

            impactSerialsThresholdValues.push({
                impactSerial,
                value: impactSerialsThreshold[impactSerialKey] || 0.5,
            });
        }

        for (let modelName of Object.keys(spaActivitiesByModelName)) {
            if (
                spaActivitiesByModelName[modelName] &&
                spaActivitiesByModelName[modelName].length > 0
            ) {
                spaActivities.push({
                    modelName,
                    activitySerials: spaActivitiesByModelName[modelName],
                });
            }
        }

        this.runningSandbox = true;

        this.lcaService
            .postSPAActivities({
                spaLifeCycleStage,
                spaActivities,
                impactSerialsThresholdValues
            })
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: () => {
                    const modelParams = (
                        this.modelsForm.get('models') as UntypedFormArray
                    ).controls
                        .filter((control) => !control.value.deleted)
                        .reduce((result, modelControl) => {
                            result.push({
                                modelName: modelControl.get('modelName').value,
                                breakdownByTechflow:
                                    !!modelControl.get('techFlowEnabled').value,
                                sumElemFlows:
                                    !modelControl.get('elemFlowEnabled').value,
                                numTiers:
                                    modelControl.get('supplyChainTier').value ||
                                    0,
                            });

                            return result;
                        }, []);

                    this.lcaService.postModelParams({ modelParams, useNewLcaEngine }).pipe(takeUntil(this.destroyed$)).subscribe({
                        next: () => {
                            this.fetchSandboxStatus();
                            this.runningSandbox = false;
                        },
                        error: (error) => {
                            this.message.error(
                                `Failed to run sandbox: '${_get(error, [
                                    'error',
                                    'errors',
                                    0,
                                ])}'!`,
                                { nzDuration: 8000 }
                            );
                            this.runningSandbox = false;
                        },
                        complete: () => {
                            this.runningSandbox = false;
                            this.cdr.detectChanges();
                        },
                    });
                },
                error: (error) => {
                    this.message.error(
                        `Failed to run sandbox: '${_get(error, [
                            'error',
                            'errors',
                            0,
                        ])}'!`,
                        { nzDuration: 8000 }
                    );
                    this.runningSandbox = false;
                    this.cdr.detectChanges();
                },
            });
    }

    updateImpactCategories() {
        if (this.setOfImpactCategoriesChecked.size > 0) {
            this.impactCategoriesUpdating = true;
            this.cdr.detectChanges();
            this.lcaService
                .putImpactCategories(
                    Array.from(this.setOfImpactCategoriesChecked)
                )
                .pipe(takeUntil(this.destroyed$))
                .subscribe({
                    next: () => {
                        this.currentStepIndex += 1;
                        this.impactCategoriesUpdating = false;
                        this.fetchSPAActivitySerials();
                    },
                    complete: () => {
                        this.impactCategoriesUpdating = false;
                        this.cdr.detectChanges();
                    },
                });
        }
    }

    //TODO: check on which steps you can navigate to previous step
    get isPreviousDisabled(): boolean {
        return false;
    }

    get isNextDisabled(): boolean {
        switch (this.currentStepIndex) {
            case Steps.ModelData:
                return !this.modelsLoaded || !!this.sandboxInitError;
            case Steps.ImpactCategories:
            // return this.setOfImpactCategoriesChecked.size === 0;
                return false;
            case Steps.SPAActivitySerials:
                return !this.spaThresholdParamsFormGroup.valid;
            default:
                return false;
        }
    }

    get isNextLoading(): boolean {
        return this.impactCategoriesUpdating || this.runningSandbox;
    }

    onLoadPowerBIReport() {
        this.lcaService.loadReport().pipe(takeUntil(this.destroyed$)).subscribe((payload) => {
            this.loadedPowerBiReport = false;
            const config: ReportConfig = _get(payload, 'payload');

            this.reportName = config.reportName;
            this.currentStepIndex = Steps.Report;
            this.cdr.detectChanges();
            this.initReport(config);
        });
    }

    getImpactCategoryNameBySerial(impactSerial: number): string {
        return _get(
            this.impactCategoryNameByImpactSerial[impactSerial],
            'impactName'
        );
    }

    private initReport(config: ReportConfig) {
        // Embed URL
        const cfg = {
            type: 'report',
            tokenType: pbi.models.TokenType.Embed,
            accessToken: config.embedToken,
            embedUrl: `${config.embedUrl}&rf=${(Math.random() + 1)
                .toString(36)
                .substring(7)}`,
            id: config.pbiReportId,
            settings: {
                filterPaneEnabled: false,
            } as IEmbedSettings,
        };

        const powerbi = new pbi.service.Service(
            pbi.factories.hpmFactory,
            pbi.factories.wpmpFactory,
            pbi.factories.routerFactory
        );
        const rep = powerbi.embed(this.reportContainer.nativeElement, cfg);
        rep.off('loaded');
        rep.on('loaded', () => {
            this.loadedPowerBiReport = true;
            this.reportLoaded = true;
            this.cdr.detectChanges();
        });
        rep.on('error', console.error);
    }

    get impactCategoriesCheckedKeys(): number[] {
        return Array.from(this.setOfImpactCategoriesChecked);
    }

    rerunLCASandbox() {
        this.modals.confirm({
            nzTitle: `Do you want to re-run LCA Sandbox?`,
            nzContent: `
      If you re-run sandbox current report will be missed.<br>
      Do you want to re-run LCA Sandbox?
    `,
            nzOnOk: () => {
                this.sandboxInitError = undefined;
                this.cdr.detectChanges();
                this.lcaService.initSandbox().pipe(takeUntil(this.destroyed$)).subscribe({
                    error: (error) => {
                        if (error && error.message) {
                            this.sandboxInitError = error.error.message;
                            this.cdr.detectChanges();
                        }
                    }
                });
                this.reportLoaded = false;
                this.reportFailed = false;
                this.reportFailedMsg = undefined;
                this.currentStepIndex = Steps.ModelData;
                this.status = undefined;
                this.spaThresholdParamsFormGroup.reset();
                this.modelsForm.reset();
                this.selectedModelIndex = 0;
                this.sandboxError = undefined;
                this.loadedPowerBiReport = false;
                this.modelsLoaded = false;
                this.setOfSpaSerialsChecked.clear();
                this.setOfImpactCategoriesChecked.clear();
                this.loadResultByIndex.clear();
                this.cdr.detectChanges();

                this.router.navigate(['/lca-engine/sandbox'], { replaceUrl: true });
            },
            nzOkText: 'Yes, re-run',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzCancelText: 'Close',
            nzClosable: false,
        });
    }

    private fetchSandboxStatus(autorenewal: boolean = false, checkInitStatus: boolean = false) {
        this.sandboxStatusLoading = false;
        this.sandboxError = undefined;

        this.lcaService
            .getSandboxStatus()
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: (data) => {
                    this.status = _get(
                        data,
                        ['payload', 'statusId'],
                        LCASandBoxStatuses.Created
                    );

                    this.reportFailedMsg = _get(
                        data,
                        ['payload', 'statusMsg'],
                        ''
                    );

                    if (
                        !autorenewal &&
                        [LCASandBoxStatuses.Created].includes(this.status) &&
                        checkInitStatus
                    ) {
                        this.sandboxInitError = undefined;
                        this.cdr.detectChanges();
                        this.lcaService.initSandbox().pipe(takeUntil(this.destroyed$)).subscribe({
                            error: (error) => {
                                if (error && error.message) {
                                    this.sandboxInitError = error.error.message;
                                    this.cdr.detectChanges();
                                }
                            }
                        });
                    }

                    if (
                        [
                            LCASandBoxStatuses.Running,
                            LCASandBoxStatuses.AssessmentComplete,
                            LCASandBoxStatuses.Waiting,
                            LCASandBoxStatuses.Failed,
                        ].includes(this.status)
                    ) {
                        this.currentStepIndex = Steps.Sandbox;
                        this.cdr.detectChanges();
                    }

                    if (
                        !autorenewal &&
                        this.status === LCASandBoxStatuses.ReportReady
                    ) {
                        this.currentStepIndex = Steps.Report;
                        this.reportLoaded = true;
                        this.cdr.detectChanges();
                        this.onLoadPowerBIReport();
                    } else if (
                        autorenewal &&
                        this.status === LCASandBoxStatuses.ReportReady
                    ) {
                        this.reportLoaded = true;
                        this.cdr.detectChanges();
                    }

                    if (this.status === LCASandBoxStatuses.Failed) {
                        this.reportFailed = true;
                        this.cdr.detectChanges();
                    } else {
                        this.reportFailed = false;
                    }

                    this.cdr.detectChanges();
                    this.loading = false;
                },
                error: (error) => {
                    this.sandboxStatusLoading = false;
                    this.sandboxError = _get(error, ['error', 'errors', 0]);
                    this.loading = false;
                    this.cdr.detectChanges();
                },
                complete: () => {
                    this.sandboxStatusLoading = false;
                    this.loading = false;
                    this.cdr.detectChanges();
                },
            });
    }
}
