import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    ClientDatabaseColumn,
    ClientDatabaseTable,
    ClientDatabaseTableDetails,
    ClientDatabaseTableMetadata,
    ClientDatabaseTableUpdateHistory,
    TableConstructors,
    TableInput,
} from '../../../store/models/client-database-table.model';
import { QueryType } from '../../../store/models/query-type.model';
import { Status } from '../../../store/models/status.model';
import { ApiService } from '../api/api.service';
import { ClientDatabaseActivity, ReportingTableRun } from 'src/app/store/models/client-database-activity';
import { ProcessLog } from 'src/app/store/models/process.model';

export interface AppendDataInput {
    dataFile: any;
    notes: string;
}

export interface UpdateDataInput {
    dataFile: any;
    notes: string;
}

export interface ColumnInput {
    columnName: string;
    columnDefaultValue?: string;
    dataType: string;
    isNullable?: boolean;
}

export interface ReportingTableInput {
    clientDataFields: string[];
    factTableFields: string[];
    breakoutTablesImpactSerials: number[];
    clientDataTable: string;
    tableSuffix: string;
    includeFactTable: boolean;
    includeMaterialFlow: boolean;
    includeBreakoutTables: boolean;
    includedInSqlView: boolean;
    includeAssuranceTable: boolean;
    overrideExistingReportingTables: boolean;
    useNewLcaEngine: boolean;
}

export interface ClientDatabaseSchemaInput {
    clientId: string;
    schemaName: string;
    schemaSqlTemplate: string;
}

export interface ActivityHistoryFilter {
    clientIds?: string[];
    userIds?: string[];
    tableName?: string;
    queryTypeIds?: string[];
    activityIds?: string[];
    activityStatusIds?: string[];
    activityTableNames?: string[];
    tableNameSearchText?: string;
    notesSearchText?: string;
    startedStampFrom?: number;
    startedStampTo?: number;
    lastActivityId?: string;
    lastCreatedStamp?: number;
    limit?: number;
}

export interface ReportingTableRunHistoryFilter {
    runIds?: string[];
    lastRunId?: string;
    lastCreatedStamp?: number;
    limit?: number;
}

export interface ClientDatabaseFilter {
    searchText?: string;
    lastTableName?: string;
    columnName?: string;
    sortDirection?: string;
    lastCreatedStamp?: number;
    schemaName?: string;
    limit?: number;
}
@Injectable({
    providedIn: 'root',
})
export class ClientDatabaseService {
    constructor(private api: ApiService) {}

    createClientSchema({
        clientId,
        schemaName,
        schemaSqlTemplate,
    }: ClientDatabaseSchemaInput): Observable<any> {
        return this.api.post(`client-schema`, {
            clientId,
            schemaName,
            schemaSqlTemplate,
        });
    }

    getReportingTableLogs(runId: string): Observable<{ payload: ProcessLog[] }> {
        return this.api.get(`reporting-tables/runs/${runId}/logs`)
    }

    exportTable(
        clientId: string,
        tableName: string,
        schemaName?: string
    ): Observable<any> {
        const params = new URLSearchParams();

        if (schemaName) {
            params.set('schemaName', schemaName);
        }

        return this.api.downloadFile(
            `client-schema/${clientId}/tables/${tableName}/data?${params.toString()}`
        );
    }

    getClientSchemaSQLTemplates(): Observable<string[]> {
        return this.api.get(`client-schema/sql-templates`);
    }

    getClientSchemaTables(
        clientId: string,
        filter?: ClientDatabaseFilter
    ): Observable<ClientDatabaseTable[]> {
        const params = {};
        if (filter) {
            if (filter.searchText) {
                params['searchText'] = filter.searchText;
            }
            if (filter.columnName) {
                params['sortByColumnName'] = filter.columnName;
            }
            if (filter.sortDirection) {
                params['sortDirection'] = filter.sortDirection;
            }
            if (filter.lastTableName) {
                params['lastTableName'] = filter.lastTableName;
            }
            if (filter.schemaName) {
                params['schemaName'] = filter.schemaName;
            }
            if (filter.limit) {
                params['limit'] = String(filter.limit ?? 100);
            }
        }

        return this.api.get(`client-schema/${clientId}/tables`, { ...params });
    }

    getClientDataFields(clientId: string): Observable<string[]> {
        return this.api.get(`reporting-tables/${clientId}/client-data-fields`);
    }

    getClientDataTables(clientId: string): Observable<any> {
        return this.api.get(`reporting-tables/${clientId}/client-data-tables`);
    }

    getFactTableFields(clientId: string): Observable<string[]> {
        return this.api.get(`reporting-tables/${clientId}/fact-table-fields`);
    }

    getVersionMasterTables(clientId: string): Observable<any> {
        return this.api.get(
            `reporting-tables/${clientId}/version-master-tables`
        );
    }

    getImpactCategories(clientId: string): Observable<any> {
        return this.api.get(`reporting-tables/${clientId}/impact-categories`);
    }

    postReportingTable(
        clientId: string,
        input: ReportingTableInput
    ): Observable<any> {
        return this.api.post(`reporting-tables/${clientId}`, input);
    }

    postClientDataAndVMTable(
        clientId: string,
        data: { tableSuffix: string; includedInSqlView: boolean }
    ): Observable<any> {
        return this.api.post(
            `client-schema/${clientId}/cd-and-vm-tables`,
            data
        );
    }

    appendDataToTable(
        clientId: string,
        tableName: string,
        input: AppendDataInput,
        schemaName?: string
    ): Observable<any> {
        const formData = new FormData();

        formData.append('notes', input.notes);
        formData.append('dataFile', input.dataFile);

        const params = new URLSearchParams();

        if (schemaName) {
            params.set('schemaName', schemaName);
        }

        return this.api.postFormData(
            `client-schema/${clientId}/tables/${tableName}/data?${params.toString()}`,
            formData
        );
    }

    updateDataToTable(
        clientId: string,
        tableName: string,
        input: AppendDataInput,
        schemaName?: string
    ): Observable<any> {
        const formData = new FormData();

        formData.append('notes', input.notes);
        formData.append('dataFile', input.dataFile);
        formData.append('queryTypeId', 'UPDATE');

        const params = new URLSearchParams();

        if (schemaName) {
            params.set('schemaName', schemaName);
        }

        return this.api.putFormData(
            `client-schema/${clientId}/tables/${tableName}/data?${params.toString()}`,
            formData
        );
    }

    removeDataFromTable(
        clientId: string,
        tableName: string,
        input: AppendDataInput,
        schemaName?: string
    ): Observable<any> {
        const params = new URLSearchParams();

        if (schemaName) {
            params.set('schemaName', schemaName);
        }

        const formData = new FormData();

        formData.append('notes', input.notes);
        formData.append('dataFile', input.dataFile);
        formData.append('queryTypeId', 'DELETE');

        return this.api.putFormData(
            `client-schema/${clientId}/tables/${tableName}/data?${params.toString()}`,
            formData
        );
    }

    getTableHistory(
        clientId: string,
        tableName: string,
        schemaName?: string
    ): Observable<ClientDatabaseTableUpdateHistory> {
        const params = {};

        if (schemaName) {
            params['schemaName'] = schemaName;
        }

        return this.api.get(
            `client-schema/${clientId}/tables/${tableName}/activity-history`,
            params
        );
    }

    truncateTable(
        clientId: string,
        tableName: string,
        schemaName?: string
    ): Observable<any> {
        const params = new URLSearchParams();

        if (schemaName) {
            params.set('schemaName', schemaName);
        }

        return this.api.delete(
            `client-schema/${clientId}/tables/${tableName}/data?${params.toString()}`
        );
    }

    deleteTable(
        clientId: string,
        tableName: string,
        schemaName?: string
    ): Observable<any> {
        const params = new URLSearchParams();

        if (schemaName) {
            params.set('schemaName', schemaName);
        }

        return this.api.delete(
            `client-schema/${clientId}/tables/${tableName}?${params.toString()}`
        );
    }

    getTableColumns(
        clientId: string,
        tableName: string,
        schemaName?: string
    ): Observable<{ payload: ClientDatabaseColumn[] }> {
        const params = {};

        if (schemaName) {
            params['schemaName'] = schemaName;
        }

        return this.api.get(
            `client-schema/${clientId}/tables/${tableName}/columns`,
            params
        );
    }

    getTableMetadata(
        clientId: string,
        tableName: string,
        schemaName?: string
    ): Observable<{ payload: ClientDatabaseTableMetadata[] }> {
        const params = {};

        if (schemaName) {
            params['schemaName'] = schemaName;
        }

        return this.api.get(
            `client-schema/${clientId}/tables/${tableName}/metadata`,
            params
        );
    }

    postTableColumn(
        clientId: string,
        tableName: string,
        input: ColumnInput,
        schemaName?: string
    ): Observable<{ payload: ClientDatabaseColumn[]; rowsCount: number }> {
        const params = new URLSearchParams();

        if (schemaName) {
            params.set('schemaName', schemaName);
        }

        return this.api.post(
            `client-schema/${clientId}/tables/${tableName}/columns?${params.toString()}`,
            input
        );
    }

    deleteTableColumn(
        clientId: string,
        tableName: string,
        columnName: string,
        schemaName?: string
    ): Observable<any> {
        const params = new URLSearchParams();

        if (schemaName) {
            params.set('schemaName', schemaName);
        }

        return this.api.delete(
            `client-schema/${clientId}/tables/${tableName}/columns/${columnName}?${params.toString()}`
        );
    }

    getReportingTableQueries(clientId: string): Observable<any> {
        return this.api.get(`reporting-tables/${clientId}/queries`);
    }

    getReportingTableQueryStatuses(queryId: string): Observable<any> {
        return this.api.get(`reporting-tables/query/${queryId}/statuses`);
    }

    getQueryTypes(): Observable<{ payload: QueryType[] }> {
        return this.api.get(`db-tool/query-types`);
    }

    getQueryStatusTypes(): Observable<{ payload: Status[] }> {
        return this.api.get(`statuses?statusTypeId=QUERY_STATUS`);
    }

    deleteQuery(activityId: string): Observable<any> {
        return this.api.delete(
            `db-activity-history/running-activity/${activityId}`
        );
    }

    getDatabaseDataTypes(): Observable<{ payload: string[] }> {
        return this.api.get(`db-tool/data-types`);
    }

    getDatabaseActivityHistory(
        filter: ActivityHistoryFilter
    ): Observable<{ payload: ClientDatabaseActivity[] }> {
        const params = new URLSearchParams();

        Object.entries(filter).forEach(
            ([filterKey, filterValue]) => {
                if (Array.isArray(filterValue)) {
                    filterValue.forEach(value => {
                        params.append(filterKey, value);
                    })
                } else {
                    params.set(filterKey, filterValue);
                }
            }
        )
        
        return this.api.get(`db-activity-history/queries?${params.toString()}`);
    }

    getReportingTableRunHistory(
        clientId: string,
        filter: ReportingTableRunHistoryFilter
    ): Observable<{ payload: ReportingTableRun[] }> {
        const params = new URLSearchParams();

        Object.entries(filter).forEach(
            ([filterKey, filterValue]) => {
                if (Array.isArray(filterValue)) {
                    filterValue.forEach(value => {
                        params.append(filterKey, value);
                    })
                } else {
                    params.set(filterKey, filterValue);
                }
            }
        )
        
        return this.api.get(`reporting-tables/${clientId}/runs-history?${params.toString()}`);
    }


    getTableDetails(
        clientId: string,
        tableName: string
    ): Observable<{ payload: ClientDatabaseTableDetails }> {
        return this.api.get(`client-schema/${clientId}/tables/${tableName}`);
    }

    getTableConstructors(): Observable<{ payload: TableConstructors }> {
        return this.api.get(`db-tool/table-types-purposes`);
    }

    postTable(
        input: TableInput,
        clientId: string,
        schemaName?: string
    ): Observable<any> {
        const params = new URLSearchParams();

        if (schemaName) {
            params.set('schemaName', schemaName);
        }

        return this.api.post(
            `client-schema/${clientId}/tables?${params.toString()}`,
            input
        );
    }
}
