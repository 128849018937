import { Component, OnDestroy, OnInit } from '@angular/core';
import _last from 'lodash/last';
import _get from 'lodash/get';
import { interval, Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import { ReportingTableRunHistoryFilter, ClientDatabaseService } from 'src/app/core/services/client-database/client-database.service';
import { ReportingTableRun } from 'src/app/store/models/client-database-activity';
import { GlobalService } from 'src/app/core/services/global/global.service';
import { PipelineRunLog } from 'src/app/store/models/pipeline.model';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import { ProcessLog } from 'src/app/store/models/process.model';

const PAGE_SIZE = 100;

@Component({
  selector: 'app-reporting-table-runs',
  templateUrl: './reporting-table-runs.component.html',
  styleUrl: './reporting-table-runs.component.less'
})
export class ReportingTableRunsComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  runs: ReportingTableRun[] = [];

  lastRunId: string;
  lastCreatedStamp: number;

  loaded: boolean;

  selectedRunId: string;

  filter: ReportingTableRunHistoryFilter = {};

  showRunParams: boolean = false;
  selectedRunParams: any = {};

  clientId: string;

  showLogs: boolean;
  logsLoading: boolean = false;
  logs: ProcessLog[] = [];
  filteredLogs: ProcessLog[] = [];

  columns = [
      {
          title: 'Reporting Table Produced',
          width: '35%',
      },
      {
          title: 'User',
          width: '15%',
      },
      {
          title: 'Start Time',
          width: '20%',
      },
      {
          title: 'End Time',
          width: '20%',
      },
      {
          title: 'Status',
          width: '10%',
      },
  ];

  ngDestroyed$ = new Subject();

  public timeInterval$ = new Subscription();
  public stopTimer$ = new Subject();

  constructor(
      private clientDatabaseService: ClientDatabaseService,
      private globalService: GlobalService
  ) {
  }

  ngOnInit() {
    this.globalService.clientId$
        .pipe(takeUntil(this.ngDestroyed$))
        .subscribe(
            clientId => {
                if (clientId) {
                    this.clientId = clientId;

                    this.runs = [];
                    this.filter = {};

                    this.loaded = false;
                    this.fetchHistoryByFitler(this.clientId, this.filter);
                }
            }
        )
  }

  ngOnDestroy() {
      this.ngDestroyed$.next(true);
  }

  private poolPendingQueriesStatuses(runIds: string[]) {
      if (this.timeInterval$ && !this.timeInterval$.closed) {
          this.timeInterval$.unsubscribe();
      }

      const pendingStatusFilter: ReportingTableRunHistoryFilter = {
          runIds,
          limit: runIds.length
      };

      this.timeInterval$ = interval(30000)
          .pipe(
              switchMap(() =>
                  this.clientDatabaseService
                      .getReportingTableRunHistory(this.clientId, pendingStatusFilter)
                      
              )
          )
          .pipe(takeUntil(this.stopTimer$))
          .pipe(takeUntil(this.ngDestroyed$))  
          .subscribe({
              next: (payload) => {
                  const updatedActivities: ReportingTableRun[] = _get(payload, 'payload', []);
                  
                  this.runs = this.runs.map(
                      run => {
                          if (runIds.includes(run.runId)) {
                              const runToUpdate = updatedActivities.find(item => item.runId === run.runId);

                              return runToUpdate ?? run;
                          }

                          return run;
                      }
                  );
              }
          })          
  }

  fetchHistoryByFitler(
      clientId: string,
      filter: ReportingTableRunHistoryFilter,
      append: boolean = false
  ) {
      if (this.loaded) {
        return;
      }

      this.filter = filter;
      this.loading = true;

      this.clientDatabaseService
          .getReportingTableRunHistory(clientId, { ...filter, limit: PAGE_SIZE })
          .pipe(takeUntil(this.ngDestroyed$))
          .subscribe({
              next: (payload) => {
                  this.loading = false;
                  const runs = _get(payload, 'payload', []);
                  if (append) {
                      if (runs.length) {
                          this.runs = [
                              ...this.runs,
                              ...runs,
                          ];
                      }
                  } else {
                      this.runs = runs;
                  }

                  if (runs.length < PAGE_SIZE) {
                    this.loaded = true;
                  }

                  if (this.runs.some((run: ReportingTableRun) => run.runStatus.statusName === 'Running')) {
                      this.poolPendingQueriesStatuses(this.runs.flatMap(run => run.runStatus.statusName === 'Running' ? run.runId : []));
                  }
              },
              error: () => {
                  this.loading = false;
              },
          });
  }

  onQueryParams(data: any, runId: string) {
      this.selectedRunId = runId;
      this.showRunParams = true;

      this.selectedRunParams = data;

       if (_isObject(data)) {
        this.selectedRunParams = Object.fromEntries(Object.entries(data).map(([key,value]) => {
            let parsedValue = value;
    
            try {
            parsedValue = JSON.parse(value as string);
            } catch {
            }
    
            return [key, parsedValue]
        }));
      }
  }

  onCloseQueryParams() {
      this.showRunParams = false;
      this.selectedRunId = null;
      this.selectedRunParams = {};
  }

  onTableScrolled() {
      const run = _last(this.runs);
      if (run && run.runId) {
          const { runId, startedStamp } = run;
          this.filter.lastRunId = runId;
          this.filter.lastCreatedStamp = startedStamp;

          this.fetchHistoryByFitler(this.clientId, this.filter, true);
      }
  }

  onViewMessage(data: ReportingTableRun) {
    this.showLogs = true;
    this.logsLoading = true;
    this.logs = [];
    this.filteredLogs = [];

    this.clientDatabaseService.getReportingTableLogs(data.runId)
      .subscribe({
        next: (payload) => {
          this.logs = _get(payload, 'payload', []);
          this.filteredLogs = this.logs.filter(log => !!log.data?.messages?.length);
          this.logsLoading = false;
        },
        error: () => {
          this.logs = [];
          this.filteredLogs = [];
          this.logsLoading = false;
        }
      })
  }

  onCloseLogsDrawer() {
    this.showLogs = false;
    this.logs = [];
    this.filteredLogs = [];
    this.logsLoading = false;
  }

  getStringMessage(obj: Object | string) {
    if (typeof obj === 'string') {
      return obj;
    }

    return JSON.stringify(obj);
  }

  isJSONViewObject(value: object | string) {
    return _isObject(value);
  } 

  get messageToCopy() {
    return this.selectedRunParams ? JSON.stringify(this.selectedRunParams) : ''
  }
}
