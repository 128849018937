import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TransferItem } from 'ng-zorro-antd/transfer';

@Component({
    selector: 'app-lca-modify-spa-form',
    templateUrl: './lca-modify-spa-form.component.html',
    styleUrls: ['./lca-modify-spa-form.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LcaModifySpaFormComponent implements OnInit {
    spaFormGroup: UntypedFormGroup;

    list: TransferItem[] = [];

    @Output() closed: EventEmitter<any> = new EventEmitter();

    constructor(private fb: UntypedFormBuilder) {
        this.initiSPAFormGroup();
    }

    ngOnInit() {
        this.getData();
    }

    private initiSPAFormGroup() {
        this.spaFormGroup = this.fb.group({
            spaLifeCycleStageToRender: [
                '',
                [Validators.min(1), Validators.max(19)],
            ],
            spaSensitivityThresholdClimateChange: [
                '',
                [Validators.min(0), Validators.max(1)],
            ],
        });
    }

    onSave() {
        this.closed.emit();
    }

    onClose() {
        this.closed.emit();
    }

    getData(): void {
        const ret: TransferItem[] = [];
        for (let i = 0; i < 20; i++) {
            ret.push({
                key: i.toString(),
                title: `content${i + 1}`,
                description: `description of content${i + 1}`,
                direction: Math.random() * 2 > 1 ? 'right' : undefined,
            });
        }
        this.list = ret;
    }

    change() {}

    select() {}
}
