import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    UntypedFormBuilder,
    FormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ClientDatabaseService } from '../../../core/services/client-database/client-database.service';
import {
    DbNaming,
    DB_NAMING_REGEXP_TYPES,
} from '../../../validators/dbNaming.validator';
import _get from 'lodash/get';

@Component({
    selector: 'app-client-data-table-form',
    templateUrl: './client-data-table-form.component.html',
    styleUrls: ['./client-data-table-form.component.less'],
})
export class ClientDataTableFormComponent implements OnInit {
    tableNameLength: number = 20;
    tableFormGroup: UntypedFormGroup;
    error: string;

    saving: boolean = false;

    @Output() closed: EventEmitter<boolean> = new EventEmitter();
    @Input() clientId: string;

    constructor(
        private fb: UntypedFormBuilder,
        private clientDatabaseService: ClientDatabaseService,
        private message: NzMessageService
    ) {
        this.initTableForm();
    }

    ngOnInit() {}

    onClose() {
        this.closed.emit(false);
    }

    onSubmit() {
        this.saving = true;
        this.clientDatabaseService
            .postClientDataAndVMTable(this.clientId, this.tableFormGroup.value)
            .subscribe({
                next: () => {
                    this.message.success(
                        `ClientData table <b>${
                            this.tableFormGroup.get('tableName').value
                        }</b> has been created`,
                        {
                            nzDuration: 3500,
                        }
                    );
                    this.saving = false;
                    this.closed.emit(true);
                    this.tableFormGroup.reset();
                },
                error: (error) => {
                    this.error = _get(error, ['error', 'errors', 0]);
                    this.saving = false;
                },
            });
    }

    private initTableForm() {
        this.tableFormGroup = this.fb.group(
            {
                tableName: ['', [Validators.required]],
                includedInSqlView: [false],
            },
            {
                validators: [
                    DbNaming('tableName', DB_NAMING_REGEXP_TYPES.SHORT),
                ],
            }
        );
    }
}
