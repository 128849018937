import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

@Component({
    selector: 'app-color-input',
    templateUrl: './color-input.component.html',
    styleUrls: ['./color-input.component.less'],
})
export class ColorInputComponent implements OnInit, OnChanges {
    visible: boolean = false;
    color: string = '#00b5ff';

    @Input() initColor: string;
    @Output() colorChange: EventEmitter<string> = new EventEmitter();

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        const {
            initColor: { previousValue, currentValue },
        } = changes;
        if (previousValue !== currentValue) {
            this.color = currentValue;
        }
    }

    ngOnInit() {
        this.color = this.initColor;
    }

    onAddColor() {
        this.visible = false;
        this.colorChange.emit(this.color);
    }

    change(value: boolean): void {
        if (!value) {
            this.colorChange.emit(this.color);
        }
        setTimeout(() => this.initColorPicker(), 50);
        this.visible = value;
    }

    private rgbToHex(rgb) {
        const hex = Number(rgb).toString(16);
        return hex.length < 2 ? `0${hex}` : hex;
    }

    private initColorPicker() {
        const picker: HTMLCanvasElement = document.getElementById(
            'pallet-circle'
        ) as HTMLCanvasElement;
        if (picker) {
            picker.onmousedown = (e: MouseEvent) => {
                const pixel = picker
                    .getContext('2d')
                    .getImageData(e.offsetX, e.offsetY, 1, 1).data;
                const pixelColor = `#${this.rgbToHex(pixel[0])}${this.rgbToHex(
                    pixel[1]
                )}${this.rgbToHex(pixel[2])}`;
                this.selectColor(pixelColor);
            };

            const canvas = picker.getContext('2d'),
                centerX = picker.width / 2,
                centerY = picker.height / 2,
                sx = centerX,
                sy = centerY;

            for (var i = 0; i < 360; i += 0.1) {
                var rad = ((i - 45) * Math.PI) / 180;
                canvas.strokeStyle = 'hsla(' + i + ', 100%, 50%, 1.0)';
                canvas.beginPath();
                canvas.moveTo(centerX, centerY);
                canvas.lineTo(
                    centerX + sx * Math.cos(-rad),
                    centerY + sy * Math.sin(-rad)
                );
                canvas.stroke();
            }
        }
    }

    selectColor(hexColor: string) {
        this.colorChange.emit(hexColor);
        this.color = hexColor;
    }
}
