export const CUSTOMERS_LIST_REQUEST = 'customers list request';
export const CUSTOMERS_LIST_RECEIVE = 'customers list receive';
export const CUSTOMERS_LIST_FAILED = 'customers list failed';

export const CUSTOMER_REQUEST = 'customer request';
export const CUSTOMER_RECEIVE = 'customer receive';
export const CUSTOMER_FAILED = 'customer failed';

export const CREATE_CUSTOMER_REQUEST = 'create customer request';
export const CREATE_CUSTOMER_RECEIVE = 'create customer receive';
export const CREATE_CUSTOMER_FAILED = 'create customer failed';

export const UPDATE_CUSTOMER_REQUEST = 'update customer request';
export const UPDATE_CUSTOMER_RECEIVE = 'update customer receive';
export const UPDATE_CUSTOMER_FAILED = 'update customer failed';

export const CLIENT_ROLES_REQUEST = 'client roles request';
export const CLIENT_ROLES_RECEIVE = 'client roles receive';
export const CLIENT_ROLES_FAILED = 'client roles failed';
