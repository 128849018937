import {
    Directive,
    ElementRef,
    TemplateRef,
    ViewContainerRef,
    OnDestroy,
    Input,
    OnInit,
    Renderer2,
} from '@angular/core';
import { Store } from '@ngrx/store';
import _intersection from 'lodash/intersection';

import { Observable, Subject } from 'rxjs';
import { User } from '../../../store/models/user.model';
import { selectAuthUser } from '../../../store/selectors/auth.selectors';
import { AppState } from '../../../store/state';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnInit, OnDestroy {
    private user$: Observable<User>;
    private permissions = [];
    private class: string = '';

    @Input()
    set hasPermission(val) {
        this.permissions = val;
        this.updateView();
    }

    @Input()
    set hasPermissionClass(value: string) {
        this.class = value;
        this.updateView();
    }

    private ngDestroyed$ = new Subject();

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private store: Store<AppState>,
        private renderer2: Renderer2
    ) {
        this.user$ = this.store.select(selectAuthUser);
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    ngOnInit() {}

    private updateView() {
        this.viewContainer.clear();
        if (!this.permissions || !this.permissions.length) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            return;
        }
        this.user$.subscribe((user) => {
            if (!user || !user.userRoles) {
                this.viewContainer.clear();
                return;
            }
            if (
                user &&
                !!_intersection(user.userRoles, this.permissions).length
            ) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                if (!!this.class) {
                    const view = this.viewContainer.createEmbeddedView(
                        this.templateRef
                    );
                    this.renderer2.addClass(view.rootNodes[0], this.class);
                } else {
                    this.viewContainer.clear();
                }
            }
        });
    }
}
