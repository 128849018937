import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable } from 'rxjs';
import { WebsocketService } from '../websocket/websocket.service';
import { NotificationEventType, NotificationMessage } from 'src/app/store/models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalNotificationService {

  constructor(private notification: NzNotificationService, private websocket: WebsocketService) { }

  initNotificationSub() {
    this.websocket.init();
  }

  disconnect() {
    this.websocket.disconnect();
  }

	listenNotifications<T extends NotificationEventType = NotificationEventType>(eventType: T): Observable<NotificationMessage<T>> {
    return this.websocket.listen<T>(eventType);
  }

  updateCoeffAndSpaAndReportingTablesReminder() {
    this.notification.create(
      'info',
      'Reminder',
      'Please update any impact coefficients, client SPA data, or client reporting tables that have been affected by this change.',
      { nzDuration: 0 },
    );
  }

  updateReportingTablesReminder() {
    this.notification.create(
      'info',
      'Reminder',
      'Please update any client reporting tables that have been affected by this change.',
      { nzDuration: 0 },
    );
  }

  informPipelinesTeamReminder() {
    this.notification.create(
      'info',
      'Reminder',
      'Please inform the pipelines team which tables were updated, modified, or created and discuss any pipelines that may need to be refreshed due to this change.',
      { nzDuration: 0 },
    );
  }
}
