<nz-alert
    *ngIf="clientSubmissionError"
    nzType="error"
    [nzMessage]="clientSubmissionError"
    nzShowIcon
></nz-alert>
<form
    *hasPermission="['CLIENT_MGMT']; class: 'disabled'"
    nz-form
    [formGroup]="customerForm"
    (ngSubmit)="submitCustomerForm()"
>
    <div nz-row nzGutter="4">
        <div nz-col nzSpan="24">
            <nz-form-item>
                <nz-form-label>Upload logo</nz-form-label>
                <nz-form-control
                    [nzSpan]="24"
                    nzValidateStatus
                    nzHasFeedback
                    nzListType="picture"
                    [nzErrorTip]="logoImgUploadErrorTmpl"
                >
                    <nz-upload
                        data-cy="client-form__logo-input"
                        [nzBeforeUpload]="beforeLogoUpload"
                        [nzFileList]="fileList"
                    >
                        <button
                            class="attach-file-button"
                            nz-button
                            nzSize="large"
                            type="button"
                        >
                            <i nz-icon nzType="upload"></i>
                            <span>Choose File</span>
                        </button>
                    </nz-upload>
                    <ng-template #logoImgUploadErrorTmpl let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Client logo is required
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label nzRequired>Client name</nz-form-label>
                <nz-form-control
                    [nzSpan]="24"
                    nzValidateStatus
                    nzHasFeedback
                    [nzErrorTip]="nameErrorTmpl"
                >
                    <nz-input-group nzSize="large">
                        <input
                            data-cy="client-form__client-name-input"
                            formControlName="clientName"
                            nz-input
                            placeholder="Client name"
                        />
                    </nz-input-group>
                    <ng-template #nameErrorTmpl let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Please input client name
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label nzRequired>Primary contact name</nz-form-label>
                <nz-form-control
                    [nzSpan]="24"
                    nzValidateStatus
                    nzHasFeedback
                    [nzErrorTip]="primaryContactNameErrorTmpl"
                >
                    <nz-input-group nzSize="large">
                        <input
                            data-cy="client-form__contact-name-input"
                            formControlName="primaryContactName"
                            nz-input
                            placeholder="Primary contact name"
                        />
                    </nz-input-group>
                    <ng-template #primaryContactNameErrorTmpl let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Please input primary contact name
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label nzRequired>Primary contact e-mail</nz-form-label>
                <nz-form-control
                    [nzSpan]="24"
                    nzValidateStatus
                    nzHasFeedback
                    [nzErrorTip]="primaryContactEmailErrorTmpl"
                >
                    <nz-input-group nzSize="large">
                        <input
                            data-cy="client-form__contact-email-input"
                            formControlName="primaryContactEmail"
                            nz-input
                            placeholder="Primary contact e-mail"
                        />
                    </nz-input-group>
                    <ng-template #primaryContactEmailErrorTmpl let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Please input primary contact e-mail
                        </ng-container>
                        <ng-container *ngIf="control.hasError('email')">
                            Please input valid E-email address
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label nzRequired
                    >Primary contact phone #</nz-form-label
                >
                <nz-form-control
                    [nzSpan]="24"
                    nzValidateStatus
                    nzHasFeedback
                    [nzErrorTip]="primaryContactPhoneErrorTmpl"
                >
                    <nz-input-group nzSize="large">
                        <input
                            data-cy="client-form__contact-phone-input"
                            formControlName="primaryContactPhone"
                            nz-input
                            placeholder="Primary contact phone #"
                        />
                    </nz-input-group>
                    <ng-template #primaryContactPhoneErrorTmpl let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Please input primary contact phone
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label nzRequired>PowerBI Workspace ID</nz-form-label>
                <nz-form-control
                    [nzSpan]="24"
                    [nzErrorTip]="pbiWorkspaceIdErrorTmpl"
                >
                    <nz-input-group nzSize="large">
                        <input
                            formControlName="pbiWorkspaceId"
                            nz-input
                            placeholder="Enter ID"
                        />
                    </nz-input-group>
                    <ng-template #pbiWorkspaceIdErrorTmpl let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Please input PowerBI Workspace ID
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label>Max number of users</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-input-group nzSize="large">
                        <nz-input-number
                            data-cy="client-form__max-number-of-users-input"
                            nzMax="100000"
                            nzMin="0"
                            formControlName="maxNumberOfUsers"
                            nz-input
                            placeholder="Max number of users"
                        ></nz-input-number>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
</form>

<div class="drawer-actions-control">
    <div class="drawer-controls">
        <button
            data-cy="client-form__submit"
            type="button"
            [nzLoading]="customerLoading"
            nzSize="large"
            nz-button
            [disabled]="!customerForm?.valid || !customerForm?.dirty"
            (click)="submitCustomerForm()"
            nzType="primary"
        >
            <span>Save</span>
        </button>
        <button
            data-cy="client-form__close"
            type="button"
            [disabled]="customerLoading"
            nzSize="large"
            nz-button
            (click)="onCancel()"
        >
            <span>Close</span>
        </button>
    </div>
</div>
