import { Component, DestroyRef, ElementRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AssuranceService } from 'src/app/core/services/assurance/assurance.service';
import { GlobalService } from 'src/app/core/services/global/global.service';
import { AssuranceReportConfig, AssuranceReportConfigYear, AssuranceReportFilter, AssuranceReportItem, AssuranceReportItemKeys, AssuranceTotals, FilterQueryParam } from 'src/app/store/models/assurance-report.model';
import _get from 'lodash/get';
import { forkJoin, Subscription } from 'rxjs';
import _last from 'lodash/last';
import { selectAuthUser } from 'src/app/store/selectors/auth.selectors';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/state';

export type TableColumnType = 'string' | 'number' | 'boolean' | 'date' | 'number-fmt';

export type AssuranceTableColumn = {
  title: string;
  accessor: AssuranceReportItemKeys;
  accessorFn?: (data: AssuranceReportItem, totals: AssuranceTotals) => string | number;
  sortable?: boolean;
  emptyValueText?: string;
  type: TableColumnType;
  format?: string;
  suffix?: string;
  width?: number;
  tooltip?: boolean
}; 

const PAGE_SIZE = 100;

const GENERIC_COLUMNS_AFTER_COLUMN_ACCESSOR = 'impactAssessmentMethod';

@Component({
  selector: 'app-assurance-report-page',
  templateUrl: './assurance-report-page.component.html',
  styleUrl: './assurance-report-page.component.less'
})
export class AssuranceReportPageComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  reportLoaded: boolean = false;
  reportLoading: boolean = false;
  data: AssuranceReportItem[] = [];

  totals: AssuranceTotals;
  totalsLoading: boolean;
  totalsError: string;

  staticColumns: AssuranceTableColumn[] = [
  {
    title: 'Data ID',
    accessor: 'clientDataID',
    type: 'number',
    width: 100,
  }, {
    title: 'Observation ID',
    accessor: 'observationID',
    type: 'number',
    width: 120,
  }, {
    title: 'Data Source Line Number',
    accessor: 'modelFileLineReference',
    type: 'number',
    width: 140,
  }, {
    title: 'Data Source Name',
    accessor: 'dataSourceName',
    type: 'string',
    width: 140
  }, {
    title: 'Include in Analysis',
    accessor: 'includeAnalysis',
    type: 'boolean',
    width: 120,
  }, {
    title: 'Report Year',
    accessor: 'reportYear',
    type: 'number',
    width: 100,
  }, {
    title: 'Data Start Date',
    accessor: 'dataStartDate',
    type: 'date',
    width: 280,
  }, {
    title: 'Data End Date',
    accessor: 'dataEndDate',
    type: 'date',
    width: 280,
  }, {
    title: 'Impact Assessment Method',
    accessor: 'impactAssessmentMethod',
    type: 'string',
    width: 200,
    tooltip: true,
  }, {
    title: 'Reporting Category',
    accessor: 'reportingCategory',
    type: 'string',
    width: 110,
    tooltip: true,
  }, {
    title: 'Activity Name',
    accessor: 'activityName',
    type: 'string',
    tooltip: true,
    width: 220
  }, {
    title: 'Activity Description',
    accessor: 'activityDescription',
    type: 'string',
    tooltip: true,
    width: 220
  }, {
    title: 'Activity Location',
    accessor: 'locationName',
    type: 'string',
    width: 140,
  }, {
    title: 'Activity Site',
    accessor: 'siteName',
    type: 'string',
    width: 100,
  }, {
    title: 'Reported Activity Amount',
    accessor: 'reportedActivityAmount',
    type: 'number-fmt',
    width: 150,
  }, {
    title: 'Reported Activity Unit',
    accessor: 'reportedActivityUnit',
    type: 'string',
    width: 120
  }, {
    title: 'Unit Conversion Factor',
    accessor: 'unitConversionFactor',
    type: 'number',
    width: 140,
  }, {
    title: 'Accounting Multiplier',
    accessor: 'accountingMultiplier',
    type: 'number',
    width: 100,
  }, {
    title: 'Model Name',
    accessor: 'modelName',
    type: 'string',
    tooltip: true,
    width: 160,
  }, {
    title: 'Model Activity Amount',
    accessor: 'modelActivityAmount',
    type: 'number-fmt',
    width: 130,
  }, {
    title: 'Impact Coefficient',
    accessor: 'impactCoefficient',
    type: 'number-fmt',
    width: 120,
  }, {
    title: 'Impact Coefficient Unit',
    accessor: 'impactCoefficientUnit',
    type: 'string',
    width: 140,
  }, {
    title: 'Total Impact Amount',
    accessor: 'totalImpactAmount',
    type: 'number-fmt',
    width: 140,
  },
  {
    title: '% of Total Impact',
    accessor: 'percentOfTotalImpact',
    sortable: false,
    accessorFn: (data, totals) => (data && totals) ? +((data.totalImpactAmount / totals.totalImpactAmountSum) * 100).toFixed(2) : '0.00%',
    type: 'number-fmt',
    emptyValueText: '0.00%',
    suffix: '%',
    width: 140,
  },
  {
    title: 'Impact Unit',
    accessor: 'impactUnit',
    type: 'string',
    width: 80,
  }, {
    title: 'Source',
    accessor: 'source',
    type: 'string',
    width: 140,
    tooltip: true
  }, {
    title: 'Methodology',
    accessor: 'methodology',
    type: 'string',
    width: 120
  }];

  genericColumns: AssuranceTableColumn[] = [];
  filter: AssuranceReportFilter = {
    sortByColumnName: 'clientDataID',
    sortDirection: 'ascend'
  };

  dynamicColumnsTables: string[] = ['ClassificationMaster'];
  dynamicColumns: AssuranceTableColumn[] = [];
  dynamicColumnsLoading: boolean = false;

  clientId: string;

  fetchReportSub: Subscription;
  fetchReportTotalsSub: Subscription;

  showInfoPanel: boolean = false;
  infoPanelData: AssuranceReportItem;

  constructor(private assuranceService: AssuranceService, private globalService: GlobalService, private store: Store<AppState>, private el: ElementRef) {}

  ngOnInit(): void {
    this.store.select(selectAuthUser).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user) => {
      if (user && (user.userRoles.includes('SUPERUSER') || user.userRoles.includes('AIADMIN') || user.userRoles.includes('AIUSER'))) {
        this.globalService.clientId$.pipe(
          takeUntilDestroyed(this.destroyRef)
        ).subscribe(
          clientId => {
            if (clientId) {
              this.clientId = clientId;
              this.fetchDynamicColumns(this.clientId);
            }
          }
        );
      } else {
        if (user.clientId) {
          this.clientId = user.clientId;
          this.fetchDynamicColumns(this.clientId);
        }
      }
    });
  }

  onShowInfoPanel(data: AssuranceReportItem) {
    this.infoPanelData = data;
    this.showInfoPanel = true;
  }

  onCloseInfoPanel() {
    this.showInfoPanel = false;
    this.infoPanelData = undefined;
  }

  get topPageControlsHeight(): number {
    const block = this.el?.nativeElement?.querySelector('.assurance-report-page--top');
    
    //20 - padding top, 8 - gap, 44 - header, padding from bottom
    return (block?.offsetHeight ?? 0) + (20 + 8 + 44 + 16);
  }

  get tableYScroll(): string {
    return `calc(100vh - ${this.topPageControlsHeight ?? 0}px)`; 
  }

  private fetchDynamicColumns(clientId: string) {
    this.dynamicColumnsLoading = true;

    forkJoin(
      this.dynamicColumnsTables.map(
        tableName =>  this.assuranceService.getResultTableColumns(this.clientId, tableName)
      )
    ).pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe({
      next: payloads => {
        const columns = payloads.reduce((result, payload) => {
          return [...result, ..._get(payload, 'payload', [])];
        }, []);

        this.dynamicColumns = columns.map(column => ({ title: column.columnLabel ?? column.columnName, accessor: column.columnName, type: 'string', width: 130 }));
      },
      error: () => {
        this.dynamicColumnsLoading = false;
      },
      complete: () => {
        this.dynamicColumnsLoading = false;
      }
    })
  }

  trackByIndex(_: number, data: AssuranceReportItem): number {
    return data.clientDataID;
  }

  get columns() {
    const targetAccessorIndex = this.staticColumns.findIndex(col => col.accessor === GENERIC_COLUMNS_AFTER_COLUMN_ACCESSOR);
    const genericColumnsStartIndex = targetAccessorIndex + 1;

    if (genericColumnsStartIndex === -1) {
      return [...this.staticColumns, ...this.dynamicColumns];
    }

    return [...this.staticColumns.slice(0, genericColumnsStartIndex), ...this.dynamicColumns, ...this.staticColumns.slice(genericColumnsStartIndex, this.staticColumns.length)];
  }

  private isFilterValid(filter: AssuranceReportFilter) {
    return !!filter[FilterQueryParam.ReportYear] && !!filter[FilterQueryParam.ImpactAssessmentMethodology];
  }

  onSort(col: { key: string, value: 'descend' | 'ascend' | null }) {
    if (!col.value) {
      this.filter.sortDirection = undefined;
      this.filter.sortByColumnName = undefined;
    } else {
      this.filter.sortDirection = col.value;
      this.filter.sortByColumnName = col.key;
    }
   
    this.filter.lastClientDataID = null;

    this.reportLoaded = false;
    this.data = [];
    
    this.fetchReportData(this.filter);
  }

  onFilterChanged(filter: AssuranceReportFilter) {
    this.filter = { sortByColumnName: this.filter.sortByColumnName, sortDirection: this.filter.sortDirection,  ...(filter ?? {}) };
   
    this.filter.lastClientDataID = null;
    this.reportLoaded = false;
    this.data = [];
    this.totals = undefined;

    this.fetchReportTotals(this.filter);
    this.fetchReportData(this.filter);
  }

  onTableScrolled() {
    if (!this.reportLoaded && !this.reportLoading) {
      this.filter.lastClientDataID = _last(this.data)?.clientDataID ?? this.data[this.data.length - 2]?.clientDataID;
      this.fetchReportData(this.filter);
    }
  }

  onConfigChanged(data: { config: AssuranceReportConfig, filter?: AssuranceReportFilter }) {
    if (data.filter) {
      this.filter =  { sortByColumnName: this.filter.sortByColumnName, sortDirection: this.filter.sortDirection,  ...(data.filter ?? {}) };
    }

    this.fetchDynamicColumns(this.clientId);
    this.filter.lastClientDataID = null;
    this.reportLoaded = false;
    this.data = [];
    
    this.fetchReportData(this.filter);
  }

  fetchReportData(filter: AssuranceReportFilter) {
    if (this.fetchReportSub && !this.fetchReportSub.closed) {
      if (this.reportLoading) {
        this.reportLoading = false;
      }
      this.fetchReportSub.unsubscribe();
    }

    if (!this.isFilterValid(this.filter)) {
      return;
    }

    this.reportLoading = true;

    this.fetchReportSub = this.assuranceService.getAssuranceReportData(this.clientId, { ...(filter ?? {}), limit: PAGE_SIZE })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          this.reportLoading = false;
          const loadedData = _get(response, 'payload', []);

          this.data = this.data.filter(item => item['id'] !== 'anchor');

          if (loadedData.length < PAGE_SIZE) {
            this.reportLoaded = true;
            this.data = [...this.data, ...loadedData];
          } else {
            this.data = [...this.data, ...loadedData, { id: 'anchor' }];
          }
        },
        complete: () => {
          this.reportLoading = false;
        },
        error: () => {
          this.reportLoading = false;
        }
      })
  }

  fetchReportTotals(filter: AssuranceReportFilter) {
    if (this.fetchReportTotalsSub && !this.fetchReportTotalsSub.closed) {
      if (this.totalsLoading) {
        this.totalsLoading = false;
      }
      this.fetchReportTotalsSub.unsubscribe();
    }

    if (!this.isFilterValid(this.filter)) {
      return;
    }

    this.totalsLoading = true;
    this.totalsError = undefined;

    this.fetchReportTotalsSub = this.assuranceService.getAssuranceReportTotals(this.clientId, filter ?? {})
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          this.totalsLoading = false;
          this.totals = response.payload;
        },
        complete: () => {
          this.totalsLoading = false;
        },
        error: () => {
          this.totalsError = 'Failed to fetch stats'
          this.totalsLoading = false;
        }
      })
  }
}
