<div class="portals-container">
    <div class="portals__filter">
        <nz-select
            class="select__statuses"
            [nzMaxTagCount]="1"
            [nzMaxTagPlaceholder]="maxTagStatusesPlaceholder"
            [(ngModel)]="selectedStatuses"
            (ngModelChange)="onFilterStatusSelected($event)"
            [nzDropdownStyle]="{ width: '17rem' }"
            nzSize="large"
            nzMode="multiple"
            [nzPlaceHolder]="selectedStatuses?.length ? '' : 'Filter by Status'"
            [nzLoading]="statusesLoading"
            [nzOptionHeightPx]="42"
        >
            <nz-option
                *ngFor="let status of statusesDropdownOptions"
                [nzValue]="status.collectionStatusId"
                [nzLabel]="status.collectionStatusName"
                nzCustomContent
            >
                <app-upload-portal-status
                    [statusId]="status?.collectionStatusId"
                    [statusName]="status?.collectionStatusName"
                    [statusColor]="status?.statusColor"
                ></app-upload-portal-status>
            </nz-option>
        </nz-select>
        <ng-template #maxTagStatusesPlaceholder let-selectedList>
            +{{ selectedList.length }}
        </ng-template>
    </div>
    <div class="portals__table">
        <app-infinite-scroll
            [loading]="uploadPortalsLoading"
            [loaded]="uploadPortalsLoaded"
            (scrolled)="onTableScrolled()"
        >
            <nz-table
                #table
                [nzLoading]="uploadPortalsLoading"
                [nzSize]="'middle'"
                [nzPageSize]="9999999999"
                nzHideOnSinglePage
                [nzData]="uploadPortals"
            >
                <thead (nzSortOrderChange)="orderChange($event)">
                    <tr>
                        <th
                            *ngFor="let column of columns"
                            [nzSortFn]="true"
                            [nzWidth]="column.width"
                            [nzSortDirections]="column.sortDirections"
                            nzColumnKey="{{ column.key }}"
                        >
                            {{ column.title }}
                        </th>
                        <th nzWidth="5%"></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-template ngFor let-data [ngForOf]="table.data">
                        <tr>
                            <td
                                [innerHTML]="
                                    data.uploadPortalName
                                        | highlight: query
                                        | safe: 'html'
                                "
                            ></td>
                            <td
                                [innerHTML]="
                                    data.uploadPortalType.uploadPortalTypeName
                                "
                            ></td>
                            <td>
                                <ng-container
                                    *ngIf="
                                        userPermissions.hasUploadPortalMgmt;
                                        else statusTmpl
                                    "
                                >
                                    <nz-switch
                                        *hasPermission="
                                            ['UPLOAD_PORTAL_MGMT'];
                                            class: 'disabled'
                                        "
                                        nz-tooltip
                                        nzTooltipPlacement="top"
                                        [nzTooltipTitle]="
                                            data.active
                                                ? 'Deactivate upload portal'
                                                : 'Activate upload portal'
                                        "
                                        nzTooltipOverlayClassName="primary-tooltip"
                                        nzSize="small"
                                        [ngModel]="data.active"
                                        (ngModelChange)="
                                            onActiveUploadPortalChange(
                                                data,
                                                !data.active
                                            )
                                        "
                                    ></nz-switch>
                                </ng-container>
                                <ng-template #statusTmpl>
                                    <i
                                        class="template-status-icon"
                                        [class.active]="data.active"
                                        nz-icon
                                        [nzType]="
                                            data.active ? 'check' : 'close'
                                        "
                                        nzTheme="outline"
                                    ></i>
                                </ng-template>
                            </td>
                            <td>
                                <app-upload-portal-status
                                    [statusName]="
                                        data.dtCollectionStatus
                                            ?.collectionStatusName
                                    "
                                    [statusColor]="
                                        data.dtCollectionStatus?.statusColor
                                    "
                                ></app-upload-portal-status>
                            </td>
                            <td>
                                <ng-container *ngIf="data.updatedStamp">
                                    {{
                                        data.updatedStamp
                                            | date: 'dd MMM yyyy, h:mm a'
                                    }}
                                </ng-container>
                            </td>
                            <td
                                [class.active]="
                                    activeChangeStatusPageId ===
                                    data.uploadPortalId
                                "
                                class="actions__cell"
                            >
                                <i
                                    nz-icon
                                    nzType="dots"
                                    nzTheme="fill"
                                    *hasPermission="[
                                        'UPLOAD_PORTAL_MGMT',
                                        'UPLOAD_PORTAL_MGMT_VIEW'
                                    ]"
                                ></i>
                                <div
                                    class="table-actions-container"
                                    *hasPermission="[
                                        'UPLOAD_PORTAL_MGMT',
                                        'UPLOAD_PORTAL_MGMT_VIEW'
                                    ]"
                                >
                                    <i
                                        *hasPermission="[
                                            'UPLOAD_PORTAL_MGMT',
                                            'UPLOAD_PORTAL_MGMT_VIEW'
                                        ]"
                                        (click)="
                                            onShowHistory(data.uploadPortalId)
                                        "
                                        nz-tooltip
                                        nzTooltipPlacement="top"
                                        nzTooltipTitle="View activity history"
                                        nzTooltipOverlayClassName="primary-tooltip"
                                        class="action-icon"
                                        nz-icon
                                        nzType="calendar"
                                        nzTheme="outline"
                                    ></i>
                                    <span
                                        *ngIf="
                                            data.uploadPortalType
                                                ?.collectionStatusEnabled
                                        "
                                        (nzVisibleChange)="
                                            onChangeStatusVisible(
                                                $event,
                                                data.uploadPortalId
                                            )
                                        "
                                        nzPlacement="bottomRight"
                                        nzTrigger="click"
                                        nz-dropdown
                                        [nzDropdownMenu]="menu"
                                        (click)="onChangeStatus(data)"
                                    >
                                        <i
                                            *hasPermission="[
                                                'UPLOAD_PORTAL_MGMT'
                                            ]"
                                            nz-tooltip
                                            nzTooltipPlacement="top"
                                            nzTooltipTitle="Change status"
                                            nzTooltipOverlayClassName="primary-tooltip"
                                            class="action-icon"
                                            nz-icon
                                            nzType="change-status"
                                            nzTheme="outline"
                                        ></i>
                                    </span>
                                    <i
                                        *hasPermission="['UPLOAD_PORTAL_MGMT']"
                                        (click)="
                                            onDeleteTemplate(
                                                data.uploadPortalId,
                                                data.uploadPortalName
                                            )
                                        "
                                        nz-tooltip
                                        nzTooltipPlacement="top"
                                        nzTooltipTitle="Delete"
                                        nzTooltipOverlayClassName="danger-tooltip"
                                        class="action-icon delete"
                                        nz-icon
                                        nzType="delete"
                                        nzTheme="outline"
                                    ></i>
                                    <i
                                        *hasPermission="[
                                            'UPLOAD_PORTAL_MGMT',
                                            'UPLOAD_PORTAL_MGMT_VIEW'
                                        ]"
                                        (click)="onUploadNewVersion(data)"
                                        nz-icon
                                        nzType="edit"
                                        nz-tooltip
                                        nzTooltipPlacement="top"
                                        nzTooltipOverlayClassName="primary-tooltip"
                                        nzTooltipTitle="Edit"
                                        class="action-icon"
                                        nzTheme="outline"
                                    ></i>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </nz-table>
        </app-infinite-scroll>
    </div>
    <nz-drawer
        class="history"
        nzMaskClosable
        [nzWidth]="1080"
        [nzVisible]="historyMode"
        [nzTitle]="'Activity History'"
        (nzOnClose)="closeHistoryMode()"
    >
        <ng-container *nzDrawerContent>
            <button
                class="download-files__btn"
                nzSize="large"
                [disabled]="!setOfHistoryFileChecked.size"
                nzType="primary"
                type="button"
                [nzLoading]="isLoading"
                nz-button
                (click)="onDownloadDataHistory()"
            >
                Download
                {{
                    !!setOfHistoryFileChecked?.size
                        ? setOfHistoryFileChecked.size
                        : ''
                }}
                File{{
                    !!setOfHistoryFileChecked?.size &&
                    setOfHistoryFileChecked?.size > 1
                        ? 's'
                        : ''
                }}
            </button>
            <nz-select
                [nzAllowClear]="true"
                [nzMaxMultipleCount]="2"
                [nzMaxTagPlaceholder]="maxTagPlaceholder"
                nzMode="multiple"
                class="download-files__filter"
                style="width: 310px"
                [(ngModel)]="selectedEventTypes"
                (ngModelChange)="onFilterTypeChanged($event)"
                [nzCustomTemplate]="selectedStatusTemplate"
                [nzPlaceHolder]="'All event types'"
                #eventType
            >
                <nz-option
                    nzValue="Downloaded file"
                    nzLabel="Downloaded file"
                ></nz-option>
                <nz-option
                    nzValue="Status change"
                    nzLabel="Status change"
                ></nz-option>
                <nz-option
                    nzValue="Uploaded file"
                    nzLabel="Uploaded file"
                ></nz-option>
            </nz-select>
            <ng-template #selectedStatusTemplate let-selected>
                <div class="status-template">
                    <div class="status-template__name">
                        {{ selected.nzLabel }}
                    </div>
                </div>
            </ng-template>
            <ng-template #maxTagPlaceholder let-selectedList>
                +{{ selectedList.length }}
            </ng-template>
            <div [style.width.px]="1044" class="uploads-history__table">
                <app-infinite-scroll
                    [loading]="userDataUploadHistoryLoading$ | async"
                    (scrolled)="onHistoryTableScrolled()"
                >
                    <nz-table
                        [nzLoading]="userDataUploadHistoryLoading$ | async"
                        nzHideOnSinglePage
                        #historyTable
                        [nzSize]="'small'"
                        [nzPageSize]="999999999"
                        [nzData]="userDataUploadHistory$ | async"
                    >
                        <thead (nzSortOrderChange)="orderHistoryChange($event)">
                            <tr>
                                <th
                                    nzWidth="3%"
                                    [nzChecked]="historyFilesAllChecked"
                                    [nzIndeterminate]="
                                        historyFilesIndeterminate
                                    "
                                    (nzCheckedChange)="
                                        onHistoryFilesAllChecked($event)
                                    "
                                ></th>
                                <th
                                    *ngFor="let column of historyColumns"
                                    [nzSortFn]="true"
                                    [nzWidth]="column.width"
                                    [nzSortDirections]="column.sortDirections"
                                    nzColumnKey="{{ column.key }}"
                                >
                                    {{ column.title }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of historyTable.data">
                                <td>
                                    <span
                                        *ngIf="item.event === 'Uploaded file'"
                                        nz-checkbox
                                        [nzChecked]="
                                            setOfHistoryFileChecked.has(
                                                item.recordId
                                            )
                                        "
                                        (nzCheckedChange)="
                                            onHistoryFileChecked(
                                                item.recordId,
                                                $event
                                            )
                                        "
                                    ></span>
                                </td>
                                <td>{{ item.userLoginId }}</td>
                                <td>{{ item.itemName }}</td>
                                <td>{{ item.event }}</td>
                                <td>
                                    {{
                                        item.createdStamp
                                            | date: 'dd MMM yyyy, h:mm a'
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </app-infinite-scroll>
                <div class="drawer-actions-control" [style.width]="'100%'">
                    <div class="drawer-controls">
                        <button
                            type="button"
                            nzSize="large"
                            nz-button
                            (click)="closeHistoryMode()"
                        >
                            <span>Close</span>
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
    </nz-drawer>
    <nz-drawer
        nzMaskClosable
        [nzWidth]="360"
        [nzVisible]="uploadPortalFormMode"
        [nzTitle]="
            uploadPortalForm.value.uploadPortalId
                ? 'Edit upload portal'
                : 'Create new upload portal'
        "
        (nzOnClose)="closeUploadPortalFormMode()"
    >
        <ng-container *nzDrawerContent>
            <form
                nz-form
                [formGroup]="uploadPortalForm"
                (ngSubmit)="submitUploadPortalForm()"
            >
                <div nz-row nzGutter="4">
                    <nz-alert
                        *ngIf="saveError"
                        nzType="error"
                        [nzMessage]="saveError"
                        nzShowIcon
                    ></nz-alert>
                    <div
                        nz-col
                        nzSpan="24"
                        *hasPermission="
                            ['UPLOAD_PORTAL_MGMT'];
                            class: 'disabled'
                        "
                    >
                        <nz-form-item>
                            <nz-form-label nzRequired
                                >Upload portal type</nz-form-label
                            >
                            <nz-form-control
                                [nzSpan]="24"
                                nzValidateStatus
                                nzHasFeedback
                                [nzErrorTip]="uploadPortalTypeErrorTmpl"
                            >
                                <nz-select
                                    nzShowSearch
                                    nzSize="large"
                                    nzPlaceHolder="Choose one"
                                    nzAllowClear
                                    [nzLoading]="uploadPortalTypesLoading"
                                    formControlName="uploadPortalTypeId"
                                    (ngModelChange)="onUploadPortalTypeChanged($event)"
                                >
                                    <nz-option
                                        *ngFor="let portalType of portalTypeDropdownOptions"
                                        [nzValue]="portalType.uploadPortalTypeId"
                                        [nzLabel]="portalType.uploadPortalTypeName"
                                    ></nz-option>
                                </nz-select>
                                <ng-template #uploadPortalTypeErrorTmpl let-control>
                                    <ng-container *ngIf="control.hasError('required')">
                                        Please select upload portal type
                                    </ng-container>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label nzRequired
                                >Upload portal name</nz-form-label
                            >
                            <nz-form-control
                                [nzSpan]="24"
                                nzValidateStatus
                                nzHasFeedback
                                [nzErrorTip]="uploadPortalNameErrorTmpl"
                            >
                                <nz-input-group nzSize="large">
                                    <input
                                        formControlName="uploadPortalName"
                                        nz-input
                                        placeholder="Enter upload portal name"
                                    />
                                </nz-input-group>
                                <ng-template
                                    #uploadPortalNameErrorTmpl
                                    let-control
                                >
                                    <ng-container
                                        *ngIf="control.hasError('required')"
                                    >
                                        Please enter upload portal name
                                    </ng-container>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item *ngIf="uploadedPageTemplate">
                            <nz-form-label> Uploaded Data Template File </nz-form-label>
                            <div
                                nz-tooltip
                                nzTooltipPlacement="top"
                                [nzTooltipTitle]="uploadedPageTemplate"
                                class="uploaded-template-name"
                            >
                                {{ uploadedPageTemplate }}
                            </div>
                            <button *ngIf="uploadedPageTemplate" type="button" (click)="onDownloadTemplateFile()" [nzLoading]="downloadingTemplate$ | async" nz-button nzType="primary" nzGhost>
                                <span nz-icon nzType="download"></span>
                                Download Template File
                            </button>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label
                                >Data Template File (optional)</nz-form-label
                            >
                            <nz-form-control
                                [nzSpan]="24"
                                nzValidateStatus
                                nzHasFeedback
                                nzListType="picture"
                                [nzErrorTip]="uploadPortalUploadErrorTmpl"
                            >
                                <nz-upload
                                    [nzBeforeUpload]="beforeTemplateUpload"
                                    [nzFileList]="fileList"
                                >
                                    <button
                                        class="attach-file-button"
                                        nz-button
                                        nzSize="large"
                                        type="button"
                                    >
                                        <i nz-icon nzType="upload"></i>
                                        <span>Choose File</span>
                                    </button>
                                </nz-upload>
                                <ng-template
                                    #uploadPortalUploadErrorTmpl
                                    let-control
                                >
                                    <ng-container
                                        *ngIf="control.hasError('required')"
                                    >
                                        Upload portal file is required
                                    </ng-container>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                        @if (attributesTreeNodes?.length || attributesTreeNodesLoading) {
                            <nz-form-item>
                                <nz-form-label nzRequired>Attributes</nz-form-label>
                                <nz-form-control [nzSpan]="24" nzValidateStatus nzHasFeedback [nzErrorTip]="attributesErrorTmpl">
                                    <nz-tree-select
                                        nzShowSearch
                                        style="width: 100%"
                                        nzVirtualHeight="290px"
                                        [nzNodes]="attributesTreeNodes"
                                        nzPlaceHolder="Please select attributes"
                                        formControlName="attrIds"
                                        nzCheckable
                                        [nzNotFoundContent]="noData"
                                    ></nz-tree-select>
                                    <ng-template #noData>
                                        <div style="height: 200px; display: flex; justify-content: center; align-items: center">
                                            <nz-spin nzSimple></nz-spin>
                                        </div>
                                    </ng-template>
                                </nz-form-control>
                                <ng-template #attributesErrorTmpl let-control>
                                    <ng-container *ngIf="control.hasError('required')">
                                        Please select attributes for each type
                                    </ng-container>
                                </ng-template>
                            </nz-form-item>
                        }
                    </div>
                    <div nz-col nzSpan="24">
                        <nz-form-item>
                            <nz-form-control
                                class="drawer-actions-control"
                                [nzSpan]="24"
                            >
                                <div class="drawer-controls">
                                    <button
                                        *hasPermission="['UPLOAD_PORTAL_MGMT']"
                                        type="button"
                                        nzSize="large"
                                        nz-button
                                        [disabled]="
                                            !uploadPortalForm.valid ||
                                            !uploadPortalForm.dirty ||
                                            !selectedClientId
                                        "
                                        (click)="submitUploadPortalForm()"
                                        nzType="primary"
                                    >
                                        <span>Save</span>
                                    </button>
                                    <button
                                        type="button"
                                        nzSize="large"
                                        nz-button
                                        (click)="closeUploadPortalFormMode()"
                                    >
                                        <span>Close</span>
                                    </button>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </form>
        </ng-container>
    </nz-drawer>

    <nz-modal
        [(nzVisible)]="isFileDownloadModalVisible"
        nzTitle="Download selected files"
        (nzOnCancel)="handleCancel()"
        [nzMaskClosable]="false"
        [nzClosable]="false"
        [nzOkText]="null"
        [nzWidth]="750"
    >
        <div
            *nzModalContent
            style="height: 150px; overflow-y: scroll"
            id="entriesdiv"
        >
            <ng-container *ngFor="let entry of fileEntries; let i = index">
                <div>
                    <label
                        ><span style="font-weight: bold">{{ i + 1 }}:</span>
                        {{ entry.name }} ({{ entry.size }})
                        <span
                            nz-tooltip
                            [nzTooltipTitle]="entry.message"
                            style="cursor: hand"
                            >&#9432;</span
                        ></label
                    >
                    <nz-progress
                        *ngIf="!entry.failed"
                        [nzPercent]="entry.progress"
                    ></nz-progress>
                    <nz-progress
                        *ngIf="entry.failed"
                        [nzPercent]="entry.progress"
                        nzStatus="exception"
                    ></nz-progress>
                </div>
            </ng-container>
        </div>
        <div *nzModalFooter>
            <div style="float: left; width: 85%; text-align: left">
                Downloaded: {{ downloadedFiles }}/{{ totalFiles }}<br />
                Cancelled: {{ failedFiles }}/{{ totalFiles }}
            </div>
            <div>
                <button
                    style="width: 95px"
                    *ngIf="downloadedFiles + failedFiles >= totalFiles"
                    nz-button
                    nzType="default"
                    (click)="handleClose()"
                >
                    Close
                </button>
                <button
                    style="width: 95px"
                    *ngIf="downloadedFiles + failedFiles < totalFiles"
                    nz-button
                    nzType="primary"
                    (click)="handleCancel()"
                >
                    Cancel
                </button>
            </div>
        </div>
    </nz-modal>
</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
    <ul *ngIf="!availableStatusesLoading" nz-menu nzSelectable>
        <li nz-menu-item *ngFor="let status of availableStatuses">
            <app-upload-portal-status
                (click)="changePageStatus(status.collectionStatusId)"
                [statusName]="status.collectionStatusName"
                [statusColor]="status.statusColor"
            ></app-upload-portal-status>
        </li>
    </ul>
</nz-dropdown-menu>
