import { ChangeDetectorRef, Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NzMessageService } from 'ng-zorro-antd/message';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { AssuranceService } from 'src/app/core/services/assurance/assurance.service';
import { GlobalService } from 'src/app/core/services/global/global.service';
import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';
import _last from 'lodash/last';
import _isNumber from 'lodash/isNumber';
import _isEmpty from 'lodash/isEmpty';
import { AssuranceReportConfig, AssuranceReportConfigYear, AssuranceReportFilter, AssuranceTableFilterValues, EnergyAccountingTypes, FilterQueryParam } from 'src/app/store/models/assurance-report.model';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/state';
import { selectAuthUser } from 'src/app/store/selectors/auth.selectors';

const TREE_VALUE_DELIMITER = '~~~';
const TREE_VALUE_GROUP_DELIMITER = '___';

@Component({
  selector: 'app-assurance-table-filter',
  templateUrl: './assurance-table-filter.component.html',
  styleUrl: './assurance-table-filter.component.less'
})
export class AssuranceTableFilterComponent implements OnInit {
  @Output('changed') changed: EventEmitter<AssuranceReportFilter> = new EventEmitter();
  @Output('configChanged') configChanged: EventEmitter<{ config: AssuranceReportConfig, filter?: AssuranceReportFilter }> = new EventEmitter();

  destroyRef = inject(DestroyRef);

  settingsView: boolean = false;

  enabledFilters: { label: string, placeholder?: string, queryParam: FilterQueryParam }[] = [
    {
      label: 'Report Year',
      placeholder: 'Select year',
      queryParam: FilterQueryParam.ReportYear,
    },
    {
      label: 'Impact Assessment Method',
      placeholder: 'Select method',
      queryParam: FilterQueryParam.ImpactAssessmentMethodology,
    },
    {
      label: 'Reporting Category',
      placeholder: 'Select categories',
      queryParam: FilterQueryParam.ReportingCategory,
    },
    {
      label: 'Activity Unit',
      placeholder: 'Select units',
      queryParam: FilterQueryParam.ActivityUnit,
    },
    {
      label: 'Site',
      placeholder: 'Select locations',
      queryParam: FilterQueryParam.Site,
    },
    {
      label: 'Grouping',
      placeholder: 'Select groupings',
      queryParam: FilterQueryParam.Grouping,
    },
    {
      label: 'Observation ID',
      placeholder: 'Enter ID',
      queryParam: FilterQueryParam.ObservationID,
    },
    {
      label: 'Data Source Name',
      placeholder: 'Select names',
      queryParam: FilterQueryParam.DataSource,
    },
    {
      label: 'Model Names',
      placeholder: 'Select names',
      queryParam: FilterQueryParam.ModelName,
    },
    {
      label: 'Start/End Date',
      queryParam: FilterQueryParam.Date,
    }
  ];

  filterLoadingMap: Map<FilterQueryParam, boolean> = new Map();
  filterKeys = FilterQueryParam;
  filterValues: {
    [FilterQueryParam.ReportYear]: AssuranceTableFilterValues<FilterQueryParam.ReportYear>,
    [FilterQueryParam.ImpactAssessmentMethodology]: AssuranceTableFilterValues<FilterQueryParam.ImpactAssessmentMethodology>,
  } = {
    [FilterQueryParam.ReportYear]: null,
    [FilterQueryParam.ImpactAssessmentMethodology]: []
  };

  checkedColumnsByTable: Map<string, { value: string, label: string, checked: boolean }[]> = new Map();
  savedColumns: {columnName: string, columnLabel: string}[] = [];
  allCheckedColumnByTable: Map<string, boolean> = new Map();
  indenterminateColumnsByTable: Map<string, boolean> = new Map();

  clientId: string;

  columnsSettingTables: string[] = ['ClassificationMaster'];
  saving: boolean = false;

  loadingColumnsSettings: Map<string, boolean> = new Map();

  accountingTypeOptions: string[] = [EnergyAccountingTypes.Location, EnergyAccountingTypes.Market];

  filter: AssuranceReportFilter = { [FilterQueryParam.EnergyAccountingType]: EnergyAccountingTypes.Location };

  loadingAssuranceTables: boolean = false;
  assuranceTables: string[] = [];
  
  configLoading: boolean = false;
  config$: Subject<AssuranceReportConfig> = new Subject();

  reportYearsFormGroup: UntypedFormGroup;

  saveError: string;

  constructor(private assuranceService: AssuranceService, private globalService: GlobalService, private store: Store<AppState>, private message: NzMessageService, private fb: UntypedFormBuilder) {
    this.initReportYearsFormGroup();
  }

  private fetchConfig(clientId: string) {
    this.configLoading = true;

    this.assuranceService.getReportConfig(clientId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (payload) =>{
          const conf = _get(payload, 'payload');

          this.initReportYearsFormGroup(conf);
          this.config$.next(conf);
        },
        error: () => {
          this.message.error('Failed to fetch report config');
          this.configLoading = false;
        },
        complete: () => {
          this.configLoading = false;
        }
      })
  }

  private initReportYearsFormGroup(config?: AssuranceReportConfig) {
    this.reportYearsFormGroup = this.fb.group({
      reportYears: this.fb.array(config ? config.map(year => this.createReportYearFormGroup(year)) : [this.createReportYearFormGroup()], Validators.minLength(0))
    })
  }

  private createReportYearFormGroup(reportYearConfig?: AssuranceReportConfigYear): UntypedFormGroup {
    return this.fb.group({
      reportYear: [reportYearConfig?.reportYear ?? '', [Validators.required, Validators.min(2000), Validators.max(3000)]],
      assuranceTableName: [reportYearConfig?.assuranceTableName ?? '', Validators.required]
    })
  }

  onAddReportYearForm() {
    const arrayFormGroup = (this.reportYearsFormGroup?.get('reportYears') as UntypedFormArray);
    arrayFormGroup.push(this.createReportYearFormGroup());

    document.getElementById('new-report-year-' + (arrayFormGroup.length - 1))?.scrollIntoView({ behavior: 'smooth' });
  }

  onRemoveReportYear(i: number) {
    const arrayFormGroup = (this.reportYearsFormGroup?.get('reportYears') as UntypedFormArray);
    arrayFormGroup.removeAt(i);
  }

  get reportYearsFormArray() {
    return this.reportYearsFormGroup?.get('reportYears') as UntypedFormArray;
  }

  get isDynamicFiltersDisabled() {
    return !!this.filter[FilterQueryParam.ReportYear]
  }

  getEnergyAccountingIndex(type: EnergyAccountingTypes) {
      return this.accountingTypeOptions.findIndex(item => item === type);
  }

  getTableColumnOptions(tableName: string) {
    return this.checkedColumnsByTable.get(tableName);
  }

  ngOnInit(): void {
    this.store.select(selectAuthUser).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user) => {
      if (user && (user.userRoles.includes('SUPERUSER') || user.userRoles.includes('AIADMIN') || user.userRoles.includes('AIUSER'))) {
        this.globalService.clientId$.pipe(
          takeUntilDestroyed(this.destroyRef)
        ).subscribe(
          clientId => {
            if (clientId) {
              if (this.clientId && clientId !== this.clientId) {
                this.filterValues[FilterQueryParam.ReportYear] = undefined;
                this.resetFilters(true);
              } 
              this.clientId = clientId;
              this.fetchConfig(this.clientId);          
            }
          }
        );
      } else {
        if (user.clientId) {
          if (this.clientId && user.clientId !== this.clientId) {
            this.filterValues[FilterQueryParam.ReportYear] = undefined;
            this.resetFilters(true);
          } 
          this.clientId = user.clientId;
          this.fetchConfig(user.clientId);
        }
      }
    });

    this.config$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        conf => {
          this.filterValues[FilterQueryParam.ReportYear] = _orderBy(conf.map(item => item.reportYear), item => item, ['asc']);
        }
      )
  }

  onTableFilterChanged(values: string | string[] | Date[], key: FilterQueryParam) {
    if ([FilterQueryParam.ReportYear, FilterQueryParam.EnergyAccountingType].includes(key)) {
      if (key === FilterQueryParam.EnergyAccountingType) {
        this.filter[key] = this.accountingTypeOptions[Number(values)] as EnergyAccountingTypes;
      } else {
        this.filter[key] = values;
      }
      this.filter[FilterQueryParam.ReportingCategory] = [];
      this.filter[FilterQueryParam.Site] = [];
      this.filter[FilterQueryParam.Location] = [];
      this.filter[FilterQueryParam.ActivityUnit] = [];
      this.filter[FilterQueryParam.Grouping] = [];
      this.filter[FilterQueryParam.DataSource] = [];
      this.filter[FilterQueryParam.ModelName] = [];
      this.filter[FilterQueryParam.Date] = undefined;
      this.filter[FilterQueryParam.ObservationID] = undefined;
      this.filter[FilterQueryParam.ImpactAssessmentMethodology] = undefined;
    } else {
      this.filter[key] = values;
    }

    if (key === FilterQueryParam.ReportYear && !!values) {
      this.fetchFilterValues(FilterQueryParam.ImpactAssessmentMethodology);
    }

    if (this.filter[FilterQueryParam.ReportYear] && this.filter[FilterQueryParam.ImpactAssessmentMethodology]) {
      this.updateDependentFilterValues(key);
    }

    this.changed.emit(this.filter);
  }

  get isNonRequiredFiltersDisabled(): boolean {
    return !this.filter[this.filterKeys.ReportYear] || !_isNumber(this.filter[this.filterKeys.ImpactAssessmentMethodology]);
  }

  updateDependentFilterValues(changedKey?: FilterQueryParam) {
    [FilterQueryParam.ActivityUnit, FilterQueryParam.Site, FilterQueryParam.ReportingCategory, FilterQueryParam.Grouping, FilterQueryParam.DataSource, FilterQueryParam.ModelName]
      .filter(key => key !== changedKey)
      .forEach(key => this.fetchFilterValues(key));
  }

  isFilterLoading(key: FilterQueryParam) {
    return this.filterLoadingMap.get(key)
  }

  get isFiltersDirty() {
    return !_isEmpty(_omit(this.filter, FilterQueryParam.EnergyAccountingType));
  }

  resetFilters(emitEvent = true) {
    this.filter = {
      [FilterQueryParam.ReportYear]: null,
      [FilterQueryParam.Grouping]: [],
      [FilterQueryParam.EnergyAccountingType]: EnergyAccountingTypes.Location,
      [FilterQueryParam.Location]: [],
      [FilterQueryParam.Site]: [],
      [FilterQueryParam.ActivityUnit]: [],
      [FilterQueryParam.ReportingCategory]: [],
      [FilterQueryParam.ModelName]: [],
      [FilterQueryParam.DataSource]: [],
      [FilterQueryParam.Date]: null,
      [FilterQueryParam.ObservationID]: null,
      [FilterQueryParam.ImpactAssessmentMethodology]: null
    };

    this.filterValues[FilterQueryParam.ActivityUnit] = undefined;
    this.filterValues[FilterQueryParam.ReportingCategory] = undefined;
    this.filterValues[FilterQueryParam.Site] = undefined;
    this.filterValues[FilterQueryParam.Location] = undefined;
    this.filterValues[FilterQueryParam.Grouping] = undefined;
    this.filterValues[FilterQueryParam.DataSource] = undefined;
    this.filterValues[FilterQueryParam.ModelName] = undefined;
    this.filterValues[FilterQueryParam.ImpactAssessmentMethodology] = undefined;

    if (emitEvent) {
      this.changed.emit(this.filter);
    }
  }

  onSettingsClick() {
    this.settingsView = true;

    if (this.clientId) {
      this.fetchConfig(this.clientId);

      this.columnsSettingTables.forEach(
        tableName => {
          this.fetchColumns(tableName);
        }
      );

      this.fetchAssuranceTables(this.clientId);
    }
  }

  onCloseSettings() {
    this.settingsView = false;
    this.reportYearsFormGroup.reset();
  }

  onSubmitReportConfig() {
    this.saving = true;

    const { reportYears } = this.reportYearsFormGroup.value;

    const input: AssuranceReportConfig = (reportYears ?? []).reduce(
      (result, reportYearFormValue) => {
        const { reportYear, assuranceTableName } = reportYearFormValue;

        result = [...result, { reportYear, assuranceTableName }]

        return result;
      },
      [] as AssuranceReportConfig
    );

    const updateColumnsObs = this.columnsSettingTables.map(
      tableName => {
        const checkedColumnsByTable = this.checkedColumnsByTable.get(tableName);

        return this.assuranceService.putResultTableColumns(this.clientId, tableName, checkedColumnsByTable.filter(item => item.checked).map(item => ({ columnName:  item.value, columnLabel: item.label })))
      }
    );

    forkJoin(
      [
        ...updateColumnsObs,
        this.assuranceService.putReportConfig(this.clientId, input)
      ]
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.resetFilters();
          this.configChanged.emit({ config: input, filter: this.filter });
          this.config$.next(input);
          this.onCloseSettings();
        },
        complete: () => {
          this.saving = false;
        },
        error: (error) => {
          this.saving = false;
          if (error && error.error && error.message) {
              this.saveError = error.error.message;
          }
          if (
            error &&
            error.error &&
            error.error.fieldErrors &&
            error.error.fieldErrors.length
          ) {
              this.saveError =
                  error.error.fieldErrors[0]['fieldErrorMessage'] ||
                  '';
          }
          this.message.error(`Failed to save report config: [${this.saveError}]`);
        }
      });
  }

  getLocationColumnSettingsLoading(tableName: string): boolean{
    return this.loadingColumnsSettings.get(tableName);
  }

  fetchAssuranceTables(clientId: string) {
    this.loadingAssuranceTables = true;

    this.assuranceService.getAssuranceTables(clientId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: payload => {
          this.assuranceTables = _get(payload, 'payload', []);
        },
        complete: () => {
          this.loadingAssuranceTables = false;
        },
        error: () => {
          this.loadingAssuranceTables = false;
          this.message.error(`Failed to fetch "Assurance" tables`)
        }
      })
  }

  fetchColumns(tableName: string) {
    this.loadingColumnsSettings.set(tableName, true);
    this.savedColumns = [];
    this.checkedColumnsByTable.clear();

    forkJoin([
      this.assuranceService.getResultTableColumns(this.clientId, tableName),
      this.assuranceService.getTableColumns(this.clientId, tableName)
    ]).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: result => {
        const [{ payload: savedColumns = [] }, { payload: columns = [] }] = result;
        this.savedColumns = savedColumns;

        if (savedColumns.length !== columns.length) {
          this.indenterminateColumnsByTable.set(tableName, true);
        } else {
          this.indenterminateColumnsByTable.set(tableName, false);
        }

        if (savedColumns.length === columns.length && columns.length > 0) {
          this.allCheckedColumnByTable.set(tableName, true);
        } else {
          this.allCheckedColumnByTable.set(tableName, false);
        }
        
        this.checkedColumnsByTable.set(
          tableName,
          _orderBy(columns, col => col, 'asc').map(col => {
            const savedColumn = savedColumns.find(item => item.columnName === col);

            return ({
              value: col,
              label: savedColumn?.columnLabel ?? col,
              checked: !!savedColumn
            })
          })
        );
      },
      error: () => {
        this.message.error(`Failed to fetch columns for: ${tableName} table`);
        this.loadingColumnsSettings.set(tableName, false);
      },
      complete: () => {
        this.loadingColumnsSettings.set(tableName, false);
      }
    })
  }

  get hasLoadingFilter() {
    return Array.from(this.filterLoadingMap.values()).some(value => value)
  }

  fetchFilterValues<T extends FilterQueryParam = FilterQueryParam>(key: T) {
    this.filterLoadingMap.set(key, true);

    this.assuranceService.getFilterValues<T>(this.clientId, key, this.filter)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (payload) => {
          if ([FilterQueryParam.ActivityUnit, FilterQueryParam.ReportingCategory, FilterQueryParam.DataSource, FilterQueryParam.ModelName].includes(key)) {
            this.filterValues[key as FilterQueryParam] = _orderBy(_get(payload, ['payload', 'values'], []), item => item, ['asc']).filter(value => !!value);
          }

          if ([FilterQueryParam.ImpactAssessmentMethodology].includes(key)) {
            this.filterValues[key as FilterQueryParam.ImpactAssessmentMethodology] = _orderBy(_get(payload, ['payload', 'values'], []), item => item.impactName, ['asc']).map(item => ({ label: item.impactName, value: item.impactSerial }));
          }

          if (key === FilterQueryParam.Grouping) {
            this.filterValues[key as FilterQueryParam] = this.convertGroupingsToTreeStructure(_get(payload, ['payload', 'values'], []));
          }

          if (key === FilterQueryParam.Site) {
             this.filterValues[key as FilterQueryParam] = (payload?.payload?.values ?? []).map(
              item => ({
                key: `location${TREE_VALUE_DELIMITER}${item.location}`,
                value: item.location,
                title: item.location,
                children: (item.sites ?? []).map(
                  site => ({
                    value: site,
                    title: site,
                    key: `site${TREE_VALUE_DELIMITER}${item.location}${TREE_VALUE_DELIMITER}${site}`,
                    isLeaf: true
                  })
                )
              })
             )
          }
        },
        error: () => {
          console.error(`Failed to fetch filter values for: ${key}`);
          this.filterLoadingMap.set(key, false);
        },
        complete: () => {
          this.filterLoadingMap.set(key, false);
        }
      });
  }

  private convertGroupingsToTreeStructure(data: Record<string, string>[]) {
    const tree = [];
    const depth = Object.keys(data?.[0] ?? {}).length;

    data.forEach(item => {
      let currentLevel = tree;
      let keyParts = [];  // To store the parts of the key
  
      // Traverse each grouping from grouping1 to grouping9
      for (let i = 1; i <= depth; i++) {
        const groupingKey = `grouping${i}`;
        const groupingValue = item[groupingKey];
  
        // Build the key for the current level
        keyParts.push(`${groupingKey}${TREE_VALUE_GROUP_DELIMITER}${groupingValue}`);
        const nodeKey = keyParts.join(TREE_VALUE_DELIMITER);
        const nodeValue = nodeKey; // The value is the same as the key
        const title = groupingValue;
        const isLeaf = i === depth; // If we are at the last grouping (grouping9), it's a leaf node
  
        // Check if this node already exists at the current level
        let node = currentLevel.find(node => node.key === nodeKey);
  
        if (!node) {
          // If the node doesn't exist, create it
          node = {
            key: nodeKey,
            value: nodeValue,
            title,
            isLeaf: isLeaf,
            children: [],
          };
          currentLevel.push(node);
        }
  
        // Move down the hierarchy (go to the next level)
        currentLevel = node.children;
      }
    });
  
    return tree;
  }

  getFilterValues<T extends FilterQueryParam = FilterQueryParam>(key: T): AssuranceTableFilterValues<T> {
    return this.filterValues[key as FilterQueryParam] ?? [] as any[];
  }

  getAllChecked(tableName: string): boolean {
    return !!this.allCheckedColumnByTable.get(tableName);
  }
  
  getIndeterminate(tableName: string): boolean {
    return !!this.indenterminateColumnsByTable.get(tableName);
  }

  updateAllChecked(tableName: string) {
    this.indenterminateColumnsByTable.set(tableName, false);
    const current = (this.checkedColumnsByTable.get(tableName) ?? []);

    this.checkedColumnsByTable.set(tableName, current.map(item => ({
      ...item,
      checked: !current.every(item => item.checked)
    })));
  }

  updateSingleChecked(tableName: string, value: string): void {
    const options = this.getTableColumnOptions(tableName);
    const updatedOptions = options.map(item => item.value === value ? ({ ...item, checked: !item.checked }) : item);

    this.checkedColumnsByTable.set(tableName, updatedOptions);

    if (updatedOptions.every(item => !item.checked)) {
      this.allCheckedColumnByTable.set(tableName, false);
      this.indenterminateColumnsByTable.set(tableName, false);
    } else if (updatedOptions.every(item => item.checked)) {
      this.allCheckedColumnByTable.set(tableName, true);
      this.indenterminateColumnsByTable.set(tableName, false);
    } else {
      this.indenterminateColumnsByTable.set(tableName, true);
    }
  }

  isRowDuplicated(index: number) {
      const reportYearForm = (this.reportYearsFormGroup?.get('reportYears') as UntypedFormArray).at(index);
  
      const { reportYear } = reportYearForm.value;

      if (!reportYear) {
        return false;
      }

      return (this.reportYearsFormGroup?.get('reportYears') as UntypedFormArray).controls.some(
        (control, i) => i !== index  ? control.get('reportYear').value === reportYear : false
      );
    }
  
    get hasDuplicatedRows() {
      const controls = (this.reportYearsFormGroup?.get('reportYears') as UntypedFormArray).controls;
  
      return controls.some(
        (_, i) => this.isRowDuplicated(i)
      )
    }
}
 
