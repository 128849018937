import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import _get from 'lodash/get';
import { ClientDatabaseService } from 'src/app/core/services/client-database/client-database.service';
import { ClientDatabaseTableMetadata } from 'src/app/store/models/client-database-table.model';

@Component({
    selector: 'app-table-information',
    templateUrl: './table-information.component.html',
    styleUrls: ['./table-information.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableInformationComponent implements OnInit, OnDestroy {
    @Input() tableName: string;
    @Input() clientId: string;
    @Input() schemaName?: string;

    error: string;
    data: ClientDatabaseTableMetadata[];
    loading: boolean = false;
    destroyed$ = new Subject();

    columns = [
        {
            key: 'columnName',
            title: 'Field name',
            type: 'text',
            width: '14%',
        },
        {
            key: 'dataType',
            title: 'Data type',
            type: 'text',
            width: '14%',
        },
        {
            key: 'isNullable',
            title: 'Not null',
            type: 'checkbox',
            inverse: true,
            width: '9%',
        },
        {
            key: 'isPrimaryKey',
            title: 'Primary key',
            type: 'checkbox',
            width: '12%',
        },
        {
            key: 'fkConstraintDef',
            title: 'Foreign key constraint',
            type: 'sql',
            width: '17%',
        },
        {
            key: 'checkConstraintDef',
            title: 'Check constraint',
            type: 'sql',
            width: '17%',
        },
        {
            key: 'uniqueConstraintDef',
            title: 'Unique constraint',
            type: 'sql',
            width: '17%',
        },
    ];

    constructor(
        private clientDatabaseService: ClientDatabaseService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        if (this.tableName && this.clientId) {
            this.loading = true;
            this.error = null;

            this.cdr.detectChanges();
            this.clientDatabaseService
                .getTableMetadata(
                    this.clientId,
                    this.tableName,
                    this.schemaName
                )
                .pipe(takeUntil(this.destroyed$))
                .subscribe({
                    next: (data) => {
                        this.data = _get(data, 'payload', []);
                    },
                    error: (error) => {
                        this.error = _get(error, ['error', 'message'], null);
                        this.cdr.detectChanges();
                    },
                    complete: () => {
                        this.loading = false;
                        this.cdr.detectChanges();
                    },
                });
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
    }
}
