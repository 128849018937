<div class="assurance-report-page-container">
    <div nz-flex [nzGap]="8" [nzVertical]="true" class="assurance-report-page--top">
        <div class="assurance-page-filters">
            <app-assurance-table-filter (configChanged)="onConfigChanged($event)" (changed)="onFilterChanged($event)"></app-assurance-table-filter>
        </div>
        <ng-container *ngIf="!totalsError && !dynamicColumnsLoading">
            <nz-row class="totals-row" *ngIf="totals || totalsLoading" [nzGutter]="16">
                <nz-col [nzSpan]="6">
                    <div nz-flex [nzVertical]="true" nzAlign="start" [nzGap]="4" class="total">
                        <div class="total-label">
                            Reported Activity Amount
                        </div>
                        <div class="total-value">
                            <nz-skeleton [nzParagraph]="{ rows: 1, width: 160 }" [nzTitle]="false" [nzLoading]="totalsLoading" nzActive nzRound>
                                <app-number-format [value]="totals?.reportedActivityAmountSum ?? 0"></app-number-format>
                            </nz-skeleton>
                        </div>
                    </div>
                </nz-col>
                <nz-col [nzSpan]="6">
                    <div nz-flex [nzVertical]="true" nzAlign="start" [nzGap]="4" class="total">
                        <div class="total-label">
                            Model Activity Amount
                        </div>
                        <div class="total-value">
                            <nz-skeleton [nzParagraph]="{ rows: 1, width: 160 }" [nzTitle]="false" [nzLoading]="totalsLoading" nzActive nzRound>
                                <app-number-format [value]="totals?.modelActivityAmountSum ?? 0"></app-number-format>
                            </nz-skeleton>
                        </div>
                    </div>
                </nz-col>
                <nz-col [nzSpan]="6">
                    <div nz-flex [nzVertical]="true" nzAlign="start" [nzGap]="4" class="total">
                        <div class="total-label">
                            Total Impact Amount
                        </div>
                        <div class="total-value">
                            <nz-skeleton [nzParagraph]="{ rows: 1, width: 160 }" [nzTitle]="false" [nzLoading]="totalsLoading" nzActive nzRound>
                                <app-number-format [value]="totals?.totalImpactAmountSum ?? 0"></app-number-format>
                            </nz-skeleton>
                        </div>
                    </div>
                </nz-col>
            </nz-row>
        </ng-container>
        <nz-alert *ngIf="totalsError" [class.marginBottom.rem]="1" nzType="error" nzMessage="Failed to fetch totals"></nz-alert>
    </div>
    <ng-container *ngIf="dynamicColumnsLoading; else content">
        <div class="loading-container">
            <nz-spin nzTip="Loading..." nzSimple></nz-spin>
        </div>
    </ng-container>
    <ng-template #content>
        <div class="assurance-page-table">
            @if (data.length || reportLoading) {
                <nz-table
                    data-cy="assurance-report-table"
                    #table
                    [nzSize]="'middle'"
                    [nzLoading]="reportLoading"
                    nzHideOnSinglePage
                    [nzVirtualForTrackBy]="trackByIndex"
                    [nzVirtualItemSize]="50"
                    [nzVirtualMaxBufferPx]="4000"
                    [nzVirtualMinBufferPx]="2500"
                    [nzData]="data"
                    [nzFrontPagination]="false"
                    [nzShowPagination]="false"
                    [nzScroll]="{ x : '4100px', y: tableYScroll }"
                >
                    <thead (nzSortOrderChange)="onSort($event)">
                        <tr>
                            <th nzLeft="0px" nzWidth="32px">
                               &nbsp;
                            </th>
                            <th
                                *ngFor="let column of columns"
                                [nzColumnKey]="column.accessor"
                                [class.th-number]="['number', 'number-fmt'].includes(column.type)"
                                [nzWidth]="column.width ? column.width + 'px' : undefined"
                                [nzSortFn]="column.sortable === false ? false : true"
                                [nzShowSort]="column.sortable !== false"
                                [nzSortOrder]="filter.sortByColumnName === column.accessor ? filter.sortDirection : undefined"
                                [nzSortDirections]="column.sortable === false ? [null] : ['ascend', 'descend', null]"
                            >
                                {{ column.title }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-template nz-virtual-scroll let-data let-index="index">
                            <tr>
                                <td nzLeft="0px">
                                    <span *ngIf="data.id !== 'anchor'" class="info-button" (click)="onShowInfoPanel(data)"  nz-tooltip nzTooltipTitle="View Info" nz-icon nzType="info-circle"></span>
                                </td>
                                @if (data.id === 'anchor') {
                                    <td [colSpan]="columns.length">
                                        <app-infinite-scroll [staticAnchor]="true" [loaded]="reportLoaded" [loading]="reportLoading" (scrolled)="onTableScrolled()"></app-infinite-scroll>
                                    </td>
                                } @else {
                                    <td *ngFor="let column of columns" [class.td-number]="['number', 'number-fmt'].includes(column.type)">
                                        @switch (column.type) {
                                            @case ('number-fmt') {
                                                @if (data[column.accessor] === 0) {
                                                    <div>0</div>
                                                } @else if (!data[column.accessor] && !column.accessorFn?.(data, totals)) {
                                                    <div>
                                                        {{ column.emptyValueText ?? '-' }}
                                                    </div>
                                                } @else {
                                                    <app-number-format [zeroThresholdValue]="'0.00%'" [zeroThreshold]="0.01" [value]="column.accessorFn ? column.accessorFn(data, totals) : data[column.accessor]" [sign]="column.suffix"></app-number-format>
                                                }
                                            }
                                            @case ('number') {
                                                <div>{{data[column.accessor]}}{{column.suffix}}</div>
                                            }
                                            @case ('date') {
                                                <div>{{data[column.accessor] | date: 'MMM d, y' :'+0000'}}</div>
                                            }
                                            @case ('boolean') {
                                                <i
                                                    class="boolean-accessor-icon"
                                                    [class.approved]="data[column.accessor]"
                                                    nz-icon
                                                    [nzType]="data[column.accessor] ? 'check' : 'close'"
                                                    nzTheme="outline"
                                                ></i>
                                            }
                                            @default {
                                                <div class="text-accessor-ellipsis" nz-tooltip nzTooltipPlacement="top" [nzTooltipTitle]="column.tooltip ? data[column.accessor] : undefined">
                                                    {{ data[column.accessor] ?? '' }}
                                                </div>
                                            }
                                        }
                                    </td>
                                }
                            </tr>
                        </ng-template>
                    </tbody>
                </nz-table>
            } @else {
                <div class="no-data">No data, please check selected filters</div>
            }
        </div>
    </ng-template>
</div>


<nz-drawer
    nzMaskClosable
    [nzWidth]="640"
    [nzVisible]="showInfoPanel && !!infoPanelData"
    nzTitle="Info"
    (nzOnClose)="onCloseInfoPanel()"
>
    <ng-container *nzDrawerContent>
        <div *ngIf="!!infoPanelData" class="impact-calculations-section">
            <h5>Impact Calculations</h5>
            <nz-descriptions [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }">
                <nz-descriptions-item nzTitle="(A) Reported Activity Amount">
                    <app-number-format [value]="infoPanelData['reportedActivityAmount']"></app-number-format> {{infoPanelData['reportedActivityUnit']}}
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="(B) Unit Conversion Factor">
                    {{infoPanelData['unitConversionFactor']}} {{infoPanelData['modelActivityUnit']}}/{{infoPanelData['reportedActivityUnit']}}
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="(C) Accounting Multiplier">
                    {{infoPanelData['accountingMultiplier']}} 
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="(D) Model Activity Amount">
                    A x B x C =&nbsp;<app-number-format [value]="infoPanelData['modelActivityAmount']"></app-number-format> {{infoPanelData['modelActivityUnit']}}
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="(E) Impact Coefficient">
                    <app-number-format [value]="infoPanelData['impactCoefficient']"></app-number-format> {{infoPanelData['impactCoefficientUnit']}}
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="(F) Total Impact Amount">
                    D x E =&nbsp;<app-number-format [value]="infoPanelData['totalImpactAmount']"></app-number-format> {{infoPanelData['impactCoefficientUnit']}} 
                </nz-descriptions-item>
            </nz-descriptions>
        </div>
        <nz-divider></nz-divider>
        <nz-descriptions *ngIf="!!infoPanelData" [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }">
            @for (column of columns; track column) {
                <nz-descriptions-item [nzTitle]="column.title">
                    @switch (column.type) {
                        @case ('number-fmt') {
                            @if (infoPanelData[column.accessor] === 0) {
                                <div>0{{column.suffix ?? ''}}</div>
                            } @else if (!infoPanelData[column.accessor] && !column.accessorFn(infoPanelData, totals)) {
                                <div>
                                    {{ column.emptyValueText ?? '-' }}
                                </div>
                            } @else {
                                <app-number-format [zeroThresholdValue]="'0.00%'" [zeroThreshold]="0.01" [value]="column.accessorFn ? column.accessorFn(infoPanelData, totals) : infoPanelData[column.accessor]" [sign]="column.suffix"></app-number-format>
                            }
                        }
                        @case ('number') {
                            <div>{{infoPanelData[column.accessor]}}{{column.suffix}}</div>
                        }
                        @case ('date') {
                            <div *ngIf="infoPanelData[column.accessor]">{{infoPanelData[column.accessor]?.toString() | date: 'MMM d, y' :'+0000'}}</div>
                        }
                        @case ('boolean') {
                            <i
                                class="boolean-accessor-icon"
                                [class.approved]="infoPanelData[column.accessor]"
                                nz-icon
                                [nzType]="infoPanelData[column.accessor] ? 'check' : 'close'"
                                nzTheme="outline"
                            ></i>
                        }
                        @default {
                            <div class="text-accessor">
                                {{ infoPanelData[column.accessor] ?? '' }}
                            </div>
                        }
                    }
                </nz-descriptions-item>
            }
        </nz-descriptions>
    </ng-container>
</nz-drawer>