<nz-alert
    *ngIf="error$ | async"
    nzBanner
    nzType="error"
    [nzMessage]="error$ | async"
></nz-alert>
<div class="oauth-mcdonalds-container">
    <ng-container *ngIf="loading$ | async">
        <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
        <div class="mcdonalds__text">Authorizing...</div>
    </ng-container>
</div>
