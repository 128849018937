import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef,
} from '@angular/core';
import _first from 'lodash/first';
import _get from 'lodash/get';
import _find from 'lodash/find';
import {
    Observable,
    Subject,
    Observer,
    firstValueFrom,
    lastValueFrom,
    combineLatest,
} from 'rxjs';
import _orderBy from 'lodash/orderBy';
import { UploadPortal } from '../../../store/models/upload-portal';
import { AppState } from '../../../store/state';
import { Store } from '@ngrx/store';
import _last from 'lodash/last';

import {
    selectDownloadUploadPortalLoading,
    selectUserUploadPortals,
    selectUserUploadPortalsLoading,
    selectUserDataUploadHistory,
    selectUserDataUploadHistoryLoading,
    selectUserUploadPortalById,
    selectUserUploadPortalsUploading,
    selectUserUploadPortalTypes,
    selectUserUploadPortalTypesLoading,
} from '../../../store/selectors/upload-portal.selectors';
import {
    downloadUploadPortalFileRequest,
    userUploadPortalsRequest,
    userUploadPortalTypesRequest,
    userDataUploadHistoryRequest,
} from '../../../store/actions/upload-portal/upload-portal.actions';
import {
    take,
    takeUntil,
} from 'rxjs/operators';
import { NzUploadFile, NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DataUploadHistory } from '../../../store/models/data-upload-history';
import { UploadPortalService } from '../../../core/services/upload-portal/upload-portal.service';
import { UploadPortalCollectionStatus, UploadPortalStatus } from '../../../store/models/upload-portal-status.model';
import { FileWorkerService } from './file.worker.service';
import { DataDownloadHistoryItemAction } from 'src/app/store/models/data-download-history-item-action';
import {
    Aborter,
    AnonymousCredential,
    BlobURL,
    BlockBlobURL,
    ContainerURL,
    ServiceURL,
    StorageURL,
} from '@azure/storage-blob';
import { UploadProgressService } from 'src/app/core/services/upload-progress/upload-progress.service';
import { UploadPortalType } from 'src/app/store/models/upload-portal-type.model';
import { inject } from '@angular/core';

@Component({
    selector: 'app-data-page',
    templateUrl: './data-page.component.html',
    styleUrls: ['./data-page.component.less'],
    providers: [FileWorkerService],
})
export class DataPageComponent implements OnInit, OnDestroy {
    // portal types
    userUploadPortalTypes$: Observable<UploadPortalType[]>;
    userUploadPortalTypesLoading$: Observable<boolean>;
    selectedUploadPortalTypeId: string;
    selectedUploadPortalType$: Observable<UploadPortalType>;
    uploadPortalTypes: UploadPortalType[] = []; // TODO: to be removed

    // portals
    userUploadPortals$: Observable<UploadPortal[]>;
    userUploadPortalsLoading$: Observable<boolean>;
    selectedUploadPortalId: string;
    selectedUploadPortal$: Observable<UploadPortal>;
    uploadPortals: UploadPortal[] = [];

    uploadPortalDownloadLoading$: Observable<boolean>;

    public ngDestroyed$ = new Subject();
    public defaultStateLoaded$ = new Subject();

    //upload
    fileList: NzUploadFile[] = [];
    uploadPortalsUploading$: Observable<boolean>;

    //history
    userDataUploadHistory$: Observable<DataUploadHistory[]>;
    userDataUploadHistoryLoading$: Observable<boolean>;
    filteredData$: Observable<DataUploadHistory[]>;

    lastUploadHistoryItems: string[] = [];

    //collection status
    uploadPortalCollectionStatus: UploadPortalStatus;
    uploadPortalCollectionStatusLoading: boolean;

    filterType: number = 0;

    isPanelActive: boolean = false;
    isFilesUploaded: boolean = false;

    isFileDownloadModalVisible = false;
    totalFiles: number = 0;
    downloadedFiles: number = 0;
    failedFiles: number = 0;
    fileEntries = [];
    isLoading: boolean = false;
    isUploading: boolean = false;
    selectedEventTypes: string[] = [];

    loadingEventTypes: boolean = false;
    eventTypes: string[] = [];

    searchFilter = {
        searchText: '',
        lastHistoryCreatedStamp: '',
        lastRecordId: '',
        columnName: 'created_stamp',
        sortDirection: 'descend',
    };

    columns = [
        {
            title: 'User name',
            key: 'user_name',
            width: '25%',
            sortDirections: [null],
        },
        {
            title: 'File name/Status',
            key: 'file_name',
            width: '37%',
            sortDirections: [null],
        },
        {
            title: 'Event',
            key: 'event',
            width: '20%',
            sortDirections: [null],
        },
        {
            title: 'Date created',
            key: 'created_stamp',
            width: '15%',
            sortDirections: ['descend', 'ascend', null],
        },
    ];

    //history selected filed
    historyFilesIndeterminate: boolean = false;
    historyFilesAllChecked: boolean = false;
    setOfHistoryFileChecked = new Set<string>();

    constructor(
        private store: Store<AppState>,
        private message: NzMessageService,
        private modal: NzModalService,
        private uploadPortalService: UploadPortalService,
        private fileWorkerService: FileWorkerService,
        private uploadProgressService: UploadProgressService,
    ) {
        // portal types
        this.store.dispatch(userUploadPortalTypesRequest());
        this.userUploadPortalTypes$ = this.store.select(
            selectUserUploadPortalTypes
        );
        this.userUploadPortalTypesLoading$ = this.store.select(
            selectUserUploadPortalTypesLoading
        );

        // portals
        this.userUploadPortals$ = this.store.select(selectUserUploadPortals);
        this.userUploadPortalsLoading$ = this.store.select(
            selectUserUploadPortalsLoading
        );

        // data template download loading
        this.uploadPortalDownloadLoading$ = this.store.select(
            selectDownloadUploadPortalLoading
        );

        this.userDataUploadHistory$ = this.store.select(
            selectUserDataUploadHistory
        );

        this.userDataUploadHistoryLoading$ = this.store.select(
            selectUserDataUploadHistoryLoading
        );

        this.uploadPortalsUploading$ = this.store.select(
            selectUserUploadPortalsUploading
        );
    }

    ngOnInit() {
        this.userUploadPortalTypes$
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((uploadPortalTypes) => {
                if (uploadPortalTypes && uploadPortalTypes.length) {
                    const item = _first(uploadPortalTypes);

                    this.uploadPortalTypes = _orderBy(uploadPortalTypes, item => item.uploadPortalTypeName.toLowerCase(), ['asc']);

                    if (item) {
                        this.selectedUploadPortalTypeId = _get(item, 'uploadPortalTypeId');
                        this.onUploadPortalTypeChanged(this.selectedUploadPortalTypeId);
                    }
                }
            });

        this.userUploadPortals$
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((uploadPortals) => {
                if (uploadPortals && uploadPortals.length > 0) {
                    const item = _first(uploadPortals);

                    this.uploadPortals = _orderBy(uploadPortals, item => item.uploadPortalName.toLowerCase(), ['asc']);

                    if (item) {
                        this.selectedUploadPortalId = _get(item, 'uploadPortalId');
                        this.onUploadPortalChanged(this.selectedUploadPortalId);
                    }
                }
            });
        
        this.fetchEventTypes();
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    private fetchEventTypes() {
        this.loadingEventTypes = true;

        this.uploadPortalService.getEventTypes().pipe(takeUntil(this.ngDestroyed$)).subscribe({
            next: payload => {
                this.eventTypes = _get(payload, ['payload'], []);
            },
            error: () => {
                this.loadingEventTypes = false;
            },
            complete: () => {
                this.loadingEventTypes = false;
            }
        });
    }

    handleClose(): void {
        this.isFileDownloadModalVisible = false;
        this.downloadedFiles = 0;
        this.failedFiles = 0;
        this.totalFiles = 0;
        this.setOfHistoryFileChecked.clear();
        this.refreshCheckedStatus();
    }

    handleCancel(): void {
        const confirmModal = this.modal.confirm({
            nzTitle: '<b>Confirm action</b>',
            nzContent: 'Do you really want to cancel the operation?',
            nzOnOk: () => {
                this.fileWorkerService.cancelOperation();
                confirmModal.close();
            },
            nzClosable: false,
        });
    }

    processDownloadedBlob(payload: any) {
        const blob = payload.blob;
        const docName = payload.docName;

        const url = window.URL.createObjectURL(blob);

        const tempEl = document.createElement('a');
        document.body.appendChild(tempEl);
        tempEl.href = url;
        tempEl.download = docName;
        tempEl.click();

        window.URL.revokeObjectURL(url);
    }

    findOrCreateProgressEntry(item: DataDownloadHistoryItemAction, event: any) {
        let entry = undefined;
        let filteredEntries = [];
        if (event) {
            filteredEntries = this.fileEntries.filter(
                (e) => e.id == event.recordId
            );
        }

        if (filteredEntries.length == 0) {
            entry = {
                id: item.recordId,
                name: item.docName,
                size:
                    item.fileSize > Math.pow(1024, 2)
                        ? (item.fileSize / Math.pow(1024, 2)).toFixed(1) + 'MB'
                        : (item.fileSize / 1024).toFixed(1) + 'KB',
                progress: 0,
                failed: false,
                message: 'Pending',
            };
            this.fileEntries.push(entry);
            this.fileEntries.sort((a, b) => (a.name > b.name && 1) || -1);
        } else {
            entry = filteredEntries[0];
        }

        return entry;
    }

    onDownloadDataHistory() {
        if (window.navigator.onLine === false) return; // prevent weird consequences if connection is lost

        this.isLoading = true;

        const selectedItems: Array<string> = [];
        this.userDataUploadHistory$.pipe(take(1)).forEach((itemsInModel) => {
            itemsInModel.forEach((item) => {
                if (this.setOfHistoryFileChecked.has(item.recordId)) {
                    selectedItems.push(item.recordId);
                }
            });
        });

        this.uploadPortalService
            .getDownloadActionsForSelectedUserPortalHistoryEntries(
                this.selectedUploadPortalId,
                selectedItems
            )
            .pipe(take(1))
            .subscribe({
                next: (resp) => {
                    if (resp.payload) {
                        this.totalFiles = resp.payload.length;
                        this.downloadedFiles = 0;
                        this.failedFiles = 0;
                        this.isFileDownloadModalVisible = true;
                        this.isLoading = false;

                        this.fileEntries = [];
                        for (let i = 0; i < this.totalFiles; i++) {
                            const item = resp.payload[i];
                            item.uploadPortalId = this.selectedUploadPortalId;

                            this.findOrCreateProgressEntry(item, null); // enforces addition

                            this.fileWorkerService.addWorkerTask(
                                item,
                                (event: any) => {
                                    const entry =
                                        this.findOrCreateProgressEntry(
                                            item,
                                            event
                                        );
                                    if (event.error) {
                                        entry.failed = true;
                                        entry.message = event.message;

                                        this.failedFiles++;
                                    } else {
                                        if (event.completed) {
                                            entry.progress = Math.round(
                                                (event.loaded / event.total) *
                                                    100
                                            );
                                            entry.message = 'Success';
                                            this.processDownloadedBlob({
                                                docName: event.docName,
                                                blob: event.blob,
                                            });
                                            this.downloadedFiles++;
                                        } else {
                                            entry.progress = Math.round(
                                                (event.loaded / event.total) *
                                                    100
                                            );
                                            entry.message = 'Loading';
                                        }
                                    }
                                }
                            );
                        }
                    }
                },
                error: (_) => {
                    this.isLoading = false;
                    this.message.error(
                        `Cannot retrieve the metadata for the files. Please try later`,
                        { nzDuration: 3000 }
                    );
                },
            });
    }

    onUploadPortalTypeChanged(uploadPortalTypeId: string) {
        this.store.dispatch(userUploadPortalsRequest({ uploadPortalTypeId }));
    }

    onFilterTypeChanged(selectedEventTypes: string[]) {
        this.store.dispatch(
            userDataUploadHistoryRequest({
                uploadPortalId: this.selectedUploadPortalId,
                reset: true, // needs to reset the underlying array of items in the state object
                filter: {
                    columnName: this.searchFilter.columnName,
                    sortDirection: this.searchFilter.sortDirection,
                    lastHistoryCreatedStamp: null,
                    lastRecordId: null,
                    eventTypes: selectedEventTypes,
                },
            })
        );
    }

    onUploadPortalChanged(uploadPortalId: string) {
        this.selectedUploadPortal$ = this.store.select(
            selectUserUploadPortalById,
            { uploadPortalId }
        );
        this.store.dispatch(
            userDataUploadHistoryRequest({
                uploadPortalId: uploadPortalId,
                reset: true, // needs to reset the underlying array of items in the state object
                filter: {
                    columnName: this.searchFilter.columnName,
                    sortDirection: this.searchFilter.sortDirection,
                    lastHistoryCreatedStamp: null,
                    lastRecordId: null,
                    eventTypes: this.selectedEventTypes,
                },
            })
        );

        this.uploadPortalCollectionStatusLoading = true;
        this.uploadPortalService
            .getUploadPortalCollectionStatusByUploadPortalId(uploadPortalId)
            .pipe(take(1))
            .subscribe((result) => {
                if (!result) {
                    this.uploadPortalCollectionStatusLoading = false;
                    return;
                }
                this.uploadPortalCollectionStatus = _get(result, 'payload');
                this.uploadPortalCollectionStatusLoading = false;
            });
    }

    async onTableScrolled() {
        const data = await firstValueFrom(this.userDataUploadHistory$);
        const loading = await firstValueFrom(
            this.userDataUploadHistoryLoading$
        );

        if (!loading) {
            const last: DataUploadHistory | undefined = _last(data);

            if (!last) {
                return;
            }

            if (!last?.recordId) {
                return;
            }

            if (this.lastUploadHistoryItems.includes(last.recordId)) {
                // in case of when all items size === 24 and already loaded
                return;
            }

            if (last) {
                const { createdStamp, recordId } = last;
                this.lastUploadHistoryItems.push(recordId);

                this.store.dispatch(
                    userDataUploadHistoryRequest({
                        uploadPortalId: this.selectedUploadPortalId,
                        filter: {
                            columnName: this.searchFilter.columnName,
                            sortDirection: this.searchFilter.sortDirection,
                            lastHistoryCreatedStamp: createdStamp,
                            lastRecordId: recordId,
                            eventTypes: this.selectedEventTypes,
                        },
                    })
                );
            }
        }
    }

    async orderChange(col: any) {
        if (col.key != 'created_stamp') return;

        this.lastUploadHistoryItems = [];

        const loading = await firstValueFrom(
            this.userDataUploadHistoryLoading$
        );

        if (!loading) {
            this.searchFilter.columnName = col.key;
            this.searchFilter.sortDirection = col.value;

            this.store.dispatch(
                userDataUploadHistoryRequest({
                    uploadPortalId: this.selectedUploadPortalId,
                    reset: true, // needs to reset the underlying array of items in the state object
                    filter: {
                        columnName: this.searchFilter.columnName,
                        sortDirection: this.searchFilter.sortDirection,
                        lastHistoryCreatedStamp: null,
                        lastRecordId: null,
                        eventTypes: this.selectedEventTypes,
                    },
                })
            );
        }
    }

    onDownloadUploadPortal(uploadPortalId: string) {
        this.userUploadPortals$
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((templates) => {
                const template = _find(templates, { uploadPortalId });
                if (template) {
                    this.store.dispatch(
                        downloadUploadPortalFileRequest({
                            uploadPortalId: template.uploadPortalId,
                            uploadPortalFileName: template.uploadPortalFileName,
                            filter: {
                                columnName: this.searchFilter.columnName,
                                sortDirection: this.searchFilter.sortDirection,
                                lastHistoryCreatedStamp: null,
                                lastRecordId: null,
                                eventTypes: this.selectedEventTypes,
                            },
                        })
                    );
                    // TODO: if the file fails to download, no message is shown; should be fixed
                }
            });
    }

    onCollapsePanelChange(active: boolean) {
        this.isPanelActive = active;
    }

    handleUpload = () => {
        let uploadedCount = 0;

        this.fileList.forEach((content) => {
            this.isUploading = true;
            const uploadKey = btoa(encodeURIComponent(content.name));

            this.uploadProgressService.start(uploadKey);

            this.uploadPortalService
                .getUploadFileToken(this.selectedUploadPortalId)
                .pipe(take(1))
                .subscribe({
                    next: (response) => {
                        const payload = response.payload;

                        const anonymousCredential = new AnonymousCredential();
                        const pipeline =
                            StorageURL.newPipeline(anonymousCredential);

                        const serviceURL = new ServiceURL(
                            payload.token, // when using AnonymousCredential, following url should include a valid SAS or support public access
                            pipeline
                        );

                        const containerURL = ContainerURL.fromServiceURL(
                            serviceURL,
                            payload.containerId
                        );

                        const blobName = payload.fileId;
                        const blobURL = BlobURL.fromContainerURL(
                            containerURL,
                            blobName
                        );
                        const blockBlobURL = BlockBlobURL.fromBlobURL(blobURL);

                        // get the blob from the selected file and upload to azure
                        content
                            .arrayBuffer()
                            .then((blob) => {
                                blockBlobURL
                                    .upload(Aborter.none, blob, content.size, {
                                        progress: (event) => {
                                            this.uploadProgressService.calculateProgress(
                                                uploadKey,
                                                event.loadedBytes,
                                                content.size
                                            );
                                        },
                                    })
                                    .then((uploadBlobResponse) => {
                                        this.uploadProgressService.complete(
                                            uploadKey
                                        );
                                        const fileData = {
                                            containerId: payload.containerId,
                                            fileId: payload.fileId,
                                            fileName: content.name,
                                            fileSize: content.size,
                                            contentType: content.type,
                                        };

                                        // send the file metadata to the server so as the file is visible to backend logic
                                        this.uploadPortalService
                                            .completeClientFileUpload(
                                                this.selectedUploadPortalId,
                                                fileData
                                            )
                                            .pipe(take(1))
                                            .subscribe({
                                                next: () => {
                                                    uploadedCount++;
                                                    if (
                                                        uploadedCount ===
                                                        this.fileList.length
                                                    ) {
                                                        this.isFilesUploaded =
                                                            true;
                                                        this.uploadProgressService.clearUploadsMap();
                                                    }
                                                    this.setOfHistoryFileChecked.clear();
                                                    this.refreshCheckedStatus();
                                                    // reload entries in table
                                                    this.store.dispatch(
                                                        userDataUploadHistoryRequest(
                                                            {
                                                                uploadPortalId:
                                                                    this
                                                                        .selectedUploadPortalId,
                                                                reset: true, // needs to reset the underlying array of items in the state object
                                                                filter: {
                                                                    columnName:
                                                                        this
                                                                            .searchFilter
                                                                            .columnName,
                                                                    sortDirection:
                                                                        this
                                                                            .searchFilter
                                                                            .sortDirection,
                                                                    lastHistoryCreatedStamp:
                                                                        null,
                                                                    lastRecordId:
                                                                        null,
                                                                    eventTypes:
                                                                        this
                                                                            .selectedEventTypes,
                                                                },
                                                            }
                                                        )
                                                    );
                                                    this.isUploading = false;
                                                },
                                                error: (_) => {
                                                    /* TODO: here the file is in azure but not in the db; this is acceptable currently as an integrity breach
                        but should be fixed in the future */
                                                    this.isUploading = false;
                                                    this.message.error(
                                                        `Cannot complete uploading the ${content.name} file. Please try later`,
                                                        { nzDuration: 3000 }
                                                    );
                                                },
                                            });
                                    })
                                    .catch((_) => {
                                        // from upload itself
                                        /* TODO: here the file may be or may be not in azure but surely not in the db; this is acceptable currently as an integrity breach
                        but should be fixed in the future */
                                        this.isUploading = false;
                                        this.message.error(
                                            `Cannot upload the ${content.name} file. Please try later`,
                                            { nzDuration: 3000 }
                                        );
                                    });
                            })
                            .catch((_) => {
                                // from blob transformation; no integrity breach here
                                this.isUploading = false;
                                this.message.error(
                                    `Cannot upload the ${content.name} file. Please try later`,
                                    { nzDuration: 3000 }
                                );
                            })
                            .finally(() => {
                                uploadedCount;
                            });
                    },
                    error: (_) => {
                        // from access token retrieval; no security breach here
                        this.isUploading = false;
                        this.message.error(
                            `Cannot retrieve the access token for uploading the ${content.name} file. Please try later`,
                            {
                                nzDuration: 3000,
                            }
                        );
                    },
                });
        });
    };

    beforeUpload = (file: NzUploadFile) => {
        if (this.isFilesUploaded) {
            this.fileList = [];
            this.isFilesUploaded = false;
        }

        return new Observable((observer: Observer<boolean>) => {
            if (!this.isValidMimeType(file.type)) {
                this.message.error(
                    'Only .csv, .txt, .xlsx, .xls, .xlsm, .xlsb, .doc, .docx, .pptx, .pptm, .ppt, .pdf or any image file types are allowed!',
                    { nzDuration: 5000 }
                );
                observer.complete();
                return;
            }

            const isLt250MAndMtZeroM =
                file.size / 1024 / 1024 < 250 && file.size > 0;
            if (!isLt250MAndMtZeroM) {
                if (file.size == 0) {
                    this.message.error('File should be larger than 0MB');
                } else {
                    this.message.error('File should be smaller than 250MB');
                }
                observer.complete();
                return;
            } else {
                this.fileList = [...this.fileList, file];
            }

            // false for manual upload on form submit
            observer.next(isLt250MAndMtZeroM && false);
            observer.complete();
        });
    };

    onChangeFiles = (info: NzUploadChangeParam) => {
        this.fileList = info.fileList;
    };

    onFileDelete(fileName: string) {
        this.fileList = this.fileList.filter(({ name }) => fileName != name);
    }

    private isValidMimeType(type: string): boolean {
        return (
            type.includes('image/') ||
            [
                'text/csv', // .csv
                'application/msword', // .doc
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
                'application/vnd.ms-excel', // .xls
                'application/vnd.ms-excel.sheet.macroenabled.12', // .xlsm
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
                'application/vnd.ms-excel.sheet.binary.macroenabled.12', // .xlsb
                'application/pdf', // .pdf
                'text/plain', // .txt
                'application/vnd.ms-powerpoint', // .ppt
                'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
                'application/vnd.ms-powerpoint.presentation.macroenabled.12', // .pptm
            ].includes(type.toLowerCase())
        );
    }

    updateCheckedSet(recordId: string, checked: boolean): void {
        if (checked) {
            this.setOfHistoryFileChecked.add(recordId);
        } else {
            this.setOfHistoryFileChecked.delete(recordId);
        }
    }

    private refreshCheckedStatus(): void {
        this.userDataUploadHistory$.pipe(take(1)).forEach((items) => {
            if (items && items.length) {
                this.historyFilesAllChecked = items
                    .filter(({ event }) => event === 'Uploaded file')
                    .every(({ recordId }) =>
                        this.setOfHistoryFileChecked.has(recordId)
                    );
                this.historyFilesIndeterminate =
                    items
                        .filter(({ event }) => event === 'Uploaded file')
                        .some(({ recordId }) =>
                            this.setOfHistoryFileChecked.has(recordId)
                        ) && !this.historyFilesAllChecked;
            }
        });
    }

    onHistoryFilesAllChecked(checked: boolean) {
        this.userDataUploadHistory$.pipe(take(1)).forEach((items) => {
            if (items && items.length) {
                items
                    .filter(({ event }) => event === 'Uploaded file')
                    .forEach(({ recordId }) =>
                        this.updateCheckedSet(recordId, checked)
                    );
                this.refreshCheckedStatus();
            }
        });
    }

    onHistoryFileChecked(recordId: string, checked: boolean) {
        this.updateCheckedSet(recordId, checked);
        this.refreshCheckedStatus();
    }
}
