import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import _get from 'lodash/get';
import { takeUntil } from 'rxjs/operators';
import { Customer } from '../../../store/models/customer.model';
import { CustomerService } from '../../../core/services/customer/customer.service';
import {
    HelpService,
    SheetInput,
} from '../../../core/services/help/help.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';
import { CoreService } from '../../../core/services/core/core.service';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.less'],
})
export class FeedbackComponent implements OnInit, OnDestroy {
    customers: Customer[];

    selectedClientId: string; // the current selected client from select box
    defaultClientId: string; // the default client id

    loadedSheet: any; // used for editing a sheet
    sheetAction: string; // used for indicating an action (add or edit) on sheets
    showDescrAction: boolean; // used for showing description
    selDocDescription: string; // holds a document's description

    sheets: any[]; // keeps the retrieved sheets
    query: string; // may be used in querying documents

    buttonAdd: boolean = true; // is button in add or edit mode?
    buttonActive: boolean = true; // enables/disables the add button
    clientSelectorActive: boolean = true; // enables/disables the client select box
    clientSelectorVisible: string = 'none';

    currentUser: any; // keeps current user's clientId and roles presence flags

    isSpinning: boolean = false;
    isPopupSpinning: boolean = false;

    ngDestroyed$ = new Subject();

    constructor(
        private customersService: CustomerService,
        private helpService: HelpService,
        private modal: NzModalService,
        private message: NzMessageService,
        private router: Router,
        private coreService: CoreService
    ) {
        this.currentUser = {
            clientId: undefined,
            isSuperAdmin: false,
            isAiUser: false,
            isHelpEditor: false,
            isHelpViewer: false,
        };
    }

    // Lifecycle method callbacks
    ngOnInit() {
        this.customersService
            .getCustomers()
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    this.customers = _get(payload, 'payload', []);

                    this.coreService
                        .getProfile()
                        .pipe(takeUntil(this.ngDestroyed$))
                        .subscribe({
                            next: (payload) => {
                                this.currentUser.clientId = _get(payload, [
                                    'payload',
                                    'clientId',
                                ]);

                                const userRoles = _get(payload, [
                                    'payload',
                                    'userRoles',
                                ]);

                                if (userRoles.indexOf('SUPERUSER') > -1) {
                                    this.currentUser.isSuperAdmin = true;
                                }
                                if (userRoles.indexOf('AIUSER') > -1) {
                                    this.currentUser.isAiUser = true;
                                }
                                if (userRoles.indexOf('HELP_MGMT_VIEW') > -1) {
                                    this.currentUser.isHelpViewer = true;
                                }
                                if (userRoles.indexOf('HELP_MGMT') > -1) {
                                    this.currentUser.isHelpEditor = true;
                                }

                                const clientId =
                                    localStorage.getItem('clientId');
                                this.selectedClientId = clientId
                                    ? clientId
                                    : this.currentUser.isSuperAdmin
                                    ? undefined
                                    : this.currentUser.clientId;

                                this.onLoadContent();
                            },
                            error: (response) => {
                                this.displayErrorMessage(
                                    response,
                                    'Cannot retrieve the user profile.'
                                );
                            },
                        });
                },
                error: (response) => {
                    this.displayErrorMessage(
                        response,
                        'Cannot retrieve the clients list.'
                    );
                },
            });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
        this.ngDestroyed$.complete();
    }

    // Listeners from tabs other than documents
    onEditSheet(sheetId: string) {
        this.editSheet({ sheetId });
    }

    onPreviewSheet(sheetId: string) {
        localStorage.setItem('clientId', this.selectedClientId);

        this.router.navigate(['/help/preview/smartsheet', sheetId], {
            queryParams: { clientId: this.selectedClientId },
        });
    }

    onDeleteSheet(sheetId: string, sheetName: string) {
        this.deleteSheet({ sheetId, sheetName });
    }

    onAddSheet() {
        this.loadedSheet = null;
        this.isPopupSpinning = false;
        this.sheetAction = 'add';
    }

    onProcessSheet() {
        if (this.buttonAdd) {
            this.onAddSheet();
        } else {
            const sheetId = null;
            this.onEditSheet(sheetId);
        }
    }

    // Listeners from drawer events}
    onCloseSheetDrawer() {
        this.loadedSheet = null;
        this.sheetAction = null;
    }

    onSheetCallback(payload: any) {
        const action = _get(payload, 'action', '');

        if (action == 'add') {
            this.createSheet(payload);
        } else if (action == 'edit') {
            this.editSheet(payload);
        } else if (action == 'update') {
            this.updateSheet(payload);
        } else if (action == 'delete') {
            this.deleteSheet(payload);
        }
    }

    onSheetSpin(status: any) {
        this.isPopupSpinning = status;
    }

    // Listeners from other components
    onClientSelected() {
        if (!this.checkUserPrivileges()) return;

        this.buttonActive = true;
        this.loadTabContent();
    }

    onLoadContent() {
        localStorage.removeItem('clientId');

        // reset variable as required per case
        this.buttonAdd = true;
        this.buttonActive = true;
        this.clientSelectorActive = true;
        this.clientSelectorVisible =
            this.currentUser.isAiUser || this.currentUser.isSuperAdmin
                ? 'block'
                : 'none';

        this.sheets = [];
        this.retrieveSheets();
    }

    // CRUD operations
    retrieveSheets() {
        if (!this.selectedClientId) return;
        if (!this.checkUserPrivileges()) return;

        this.helpService
            .getSheets(this.selectedClientId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    this.sheets = payload;
                },
                error: (response) => {
                    this.displayErrorMessage(
                        response,
                        'Cannot fetch the files from server.'
                    );
                },
            });
    }

    showDescription(description: string) {
        this.selDocDescription = description;
        this.showDescrAction = true;
    }

    onCloseDescrDrawer() {
        this.selDocDescription = null;
        this.showDescrAction = false;
    }

    createSheet(payload: any) {
        const formData = this.constructSheetFormDataToSubmit(payload);

        this.helpService
            .createSheet(formData)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload: any) => {
                    this.handleResult(payload);
                },
                error: (response) => {
                    this.isPopupSpinning = false;
                    this.displayErrorMessage(
                        response,
                        'Cannot create the report.'
                    );
                },
            });
    }

    updateSheet(payload: any) {
        const formData = this.constructSheetFormDataToSubmit(payload);

        this.helpService
            .updateSheet(formData, payload.id)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: () => {
                    this.handleResult(payload, true);
                },
                error: (response) => {
                    this.isPopupSpinning = false;
                    this.displayErrorMessage(
                        response,
                        'Cannot update the report.'
                    );
                },
            });
    }

    deleteSheet(payload: any) {
        const sheetId = _get(payload, 'sheetId', '');
        const sheetName = _get(payload, 'sheetName', '');

        this.modal.confirm({
            nzTitle: `Do you want to remove report <b>${sheetName}</b>?`,
            nzContent: `
        Do you want to delete this report from clients list?
       `,
            nzOnOk: () =>
                this.helpService
                    .deleteSheet(this.selectedClientId, sheetId)
                    .pipe(takeUntil(this.ngDestroyed$))
                    .subscribe({
                        next: () => {
                            this.message.success(
                                `Report <b>${sheetName}</b> has been deleted!`,
                                { nzDuration: 3000 }
                            );

                            this.loadTabContent();
                        },
                        error: (response) => {
                            this.displayErrorMessage(
                                response,
                                'Cannot delete the report.'
                            );
                        },
                    }),
            nzOkText: 'Yes, remove',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzCancelText: 'Close',
            nzClosable: false,
            nzOnCancel: () => console.log('Cancel'),
        });
    }

    editSheet(payload: any) {
        const sheetId = _get(payload, 'sheetId', '');

        this.helpService
            .retrieveSheet(this.selectedClientId, sheetId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    this.loadedSheet = {
                        id: _get(payload, 'id', ''),
                        sheetName: _get(payload, 'sheetName', ''),
                        description: _get(payload, 'description', ''),
                        sheetNumber: _get(payload, 'sheetNumber', ''),
                        readWrite: _get(payload, 'readWrite', ''),
                    };

                    this.sheetAction = 'edit';
                },
                error: (response) => {
                    this.displayErrorMessage(
                        response,
                        'Cannot fetch the report from server.'
                    );
                },
            });
    }

    // Helper methods
    checkUserPrivileges() {
        if (
            (this.currentUser.isAiUser &&
                !this.currentUser.isHelpEditor &&
                !this.currentUser.isHelpViewer) || // if superadmin but with no help privileges // OR
            !this.currentUser.isAiUser
        ) {
            // simply not super admin
            if (
                this.selectedClientId &&
                this.selectedClientId != this.currentUser.clientId
            ) {
                this.displayErrorMessage(
                    {},
                    "Insufficient privileges to access other client's context."
                );
                return false;
            }
        }
        return true;
    }

    setSpinningAndButtonWhileLoading(tabIndex: number) {
        this.isSpinning = true;
        this.buttonActive = false;
    }

    setSpinningAndButtonAfterLoading(tabIndex: number) {
        this.isSpinning = false;

        if (tabIndex == 0) {
            if (this.currentUser.isAiUser) {
                this.buttonActive = true; // only superadmins can edit
            }
        } else if (tabIndex == 1) {
            if (
                (this.currentUser.isAiUser ||
                    this.currentUser.isHelpEditor ||
                    this.currentUser.isHelpViewer) &&
                this.selectedClientId
            ) {
                // if there is selected client and for editors only
                this.buttonActive = true;
            }
        } else {
            // 1st & 2nd tab by default have a disabled button
            this.buttonActive = true;
        }
    }

    loadTabContent() {
        this.retrieveSheets();
    }

    handleResult(payload: any, editAction: boolean = false) {
        this.loadTabContent();

        this.message.success(
            `The report <b>${payload.sheetName}</b> has been ${
                editAction ? 'updated' : 'created'
            }`,
            { nzDuration: 3000 }
        );
        this.sheetAction = null;
    }

    displayErrorMessage(response: any, defaultMessage: string) {
        const message =
            response.error && response.error.message
                ? response.error.message
                : defaultMessage;
        this.message.error(message, { nzDuration: 3000 });
    }

    constructSheetFormDataToSubmit(payload: any) {
        const data: SheetInput = {
            clientId: this.selectedClientId,
            sheetNumber: payload.sheetNumber,
            sheetName: payload.sheetName,
            description: payload.description,
            readWrite: payload.readWrite,
        };

        if (payload.id) {
            data.sheetId = payload.id;
        }

        return data;
    }
}
