<form
    data-cy="upload-form"
    *hasPermission="['HELP_MGMT', 'HELP_MGMT_VIEW']; class: 'disabled'"
    nz-form
    [formGroup]="fileForm"
>
    <nz-spin [nzSpinning]="isSpinning" [nzDelay]="500">
        <div nz-row nzGutter="24">
            <div nz-col nzSpan="17">
                <nz-form-item>
                    <nz-form-label>Preview</nz-form-label>
                    <iframe #filePreview src="" width="100%"> </iframe>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="1"></div>
            <div nz-col nzSpan="6">
                <nz-alert
                    *ngIf="payload.error"
                    nzType="error"
                    [nzMessage]="payload.error"
                    nzShowIcon
                ></nz-alert>
                <nz-form-item>
                    <nz-form-label nzRequired>Doc name</nz-form-label>
                    <nz-form-control
                        [nzSpan]="24"
                        nzValidateStatus
                        nzHasFeedback
                        [nzErrorTip]="docNameErrorTmpl"
                    >
                        <nz-input-group nzSize="large">
                            <input
                                formControlName="docName"
                                *hasPermission="
                                    ['HELP_MGMT'];
                                    class: 'disabled'
                                "
                                nz-input
                                placeholder="Enter doc name"
                                maxlength="100"
                            />
                        </nz-input-group>
                        <ng-template #docNameErrorTmpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please enter doc name
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label>Description</nz-form-label>
                    <nz-form-control
                        [nzSpan]="24"
                        nzValidateStatus
                        nzHasFeedback
                        [nzErrorTip]="fileDescrErrorTmpl"
                    >
                        <nz-input-group nzSize="large">
                            <textarea
                                formControlName="description"
                                rows="8"
                                *hasPermission="
                                    ['HELP_MGMT'];
                                    class: 'disabled'
                                "
                                style="min-height: 12rem"
                                nz-input
                                placeholder="Enter Description"
                                maxlength="2048"
                            ></textarea>
                        </nz-input-group>
                        <ng-template #fileDescrErrorTmpl let-control>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item *ngIf="selectedFile">
                    <nz-form-label nzRequired
                        >Current attached file</nz-form-label
                    >
                    <nz-form-control [nzSpan]="24">
                        <nz-tag
                            nzColor="success"
                            [style.backgroundColor]="'#3099cc'"
                        >
                            <i nz-icon nzType="attach"></i>
                            {{ selectedFile.fileName }}
                        </nz-tag>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzRequired
                        >Choose file for uploading</nz-form-label
                    >
                    <nz-form-control
                        [nzSpan]="24"
                        nzValidateStatus
                        nzHasFeedback
                    >
                        <nz-input-group>
                            <input
                                type="file"
                                formControlName="fileUpload"
                                #fileUpload
                                id="fileUpload"
                                name="fileUpload"
                                accept="application/pdf"
                                [hidden]="true"
                            />
                            <button
                                nz-button
                                nzType="default"
                                *hasPermission="
                                    ['HELP_MGMT'];
                                    class: 'disabled'
                                "
                                (click)="onOpenSelect()"
                            >
                                <i nz-icon nzType="upload"></i>Choose File
                            </button>
                            <div>
                                <nz-progress
                                    *ngIf="payload.data"
                                    [nzPercent]="payload.progress"
                                    nzSize="large"
                                    nzStatus="active"
                                ></nz-progress>
                            </div>
                        </nz-input-group>
                        <div *ngIf="payload.data">
                            <nz-tag
                                [nzMode]="'closeable'"
                                nzType="attach"
                                (nzOnClose)="resetFileToBeUploaded()"
                            >
                                <i nz-icon nzType="attach"></i>
                                {{ payload.data.name }}
                            </nz-tag>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <nz-form-item>
            <nz-form-control class="drawer-actions-control" [nzSpan]="24">
                <div class="drawer-controls">
                    <button
                        type="button"
                        nzSize="large"
                        *hasPermission="['HELP_MGMT']; class: 'disabled'"
                        nz-button
                        nzType="primary"
                        [disabled]="isNotEligibleToSubmit()"
                        (click)="uploadFile()"
                    >
                        <span>{{ action === 'add' ? 'Save' : 'Update' }}</span>
                    </button>
                    <button
                        type="button"
                        nzSize="large"
                        nz-button
                        (click)="onCancel()"
                    >
                        <span>Close</span>
                    </button>
                </div>
            </nz-form-control>
        </nz-form-item>
    </nz-spin>
</form>
