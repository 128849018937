import { ElementRef, Injectable, TemplateRef } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TicketingFormComponent } from 'src/app/components/ticketing-form/ticketing-form.component';
import { TicketForm } from 'src/app/store/models/ticket-form.model';

@Injectable({
    providedIn: 'root',
})
export class TicketingService {
    constructor(private modal: NzModalService) {}

    showTicketingForm(
        ticket: TicketForm,
        ticketFormContentTemplate: TemplateRef<any>
    ) {
        this.modal.create<TicketingFormComponent, { ticket: TicketForm }>({
            nzTitle: undefined,
            nzContent: ticketFormContentTemplate,
            nzFooter: null,
            nzData: { ticket },
        });
    }
}
