import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import _get from 'lodash/get';
import {
    PortalStatus,
    UploadPortalStatus,
} from '../../../store/models/upload-portal-status.model';
import { UploadPortalService } from '../../../core/services/upload-portal/upload-portal.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'app-portal-status-form',
    templateUrl: './portal-status-form.component.html',
    styleUrls: ['./portal-status-form.component.less'],
})
export class PortalStatusFormComponent implements OnInit, OnDestroy {
    @Input() clientId: string;
    @Input() status: UploadPortalStatus;
    @Output() closed: EventEmitter<boolean> = new EventEmitter();

    portalStatusFormGroup: UntypedFormGroup;

    saving: boolean = false;

    ngDestroyed$ = new Subject();

    statusesLoading: boolean = false;
    statuses: PortalStatus[] = [];

    constructor(
        private fb: UntypedFormBuilder,
        private message: NzMessageService,
        private uploadPortalService: UploadPortalService
    ) {}

    ngOnInit() {
        this.initPortalStatusForm();
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    submitPortalStatusForm() {
        this.saving = true;

        if (this.status && this.status.collectionStatusId) {
            this.uploadPortalService
                .putCollectionStatus({
                    collectionStatusId: this.status.collectionStatusId,
                    ...this.portalStatusFormGroup.value,
                })
                .subscribe({
                    next: () => {
                        this.message.success(
                            `Portal status <b>${
                                this.portalStatusFormGroup.get(
                                    'collectionStatusName'
                                ).value
                            }</b> has been updated`
                        );
                        this.closed.emit(true);
                        this.saving = false;
                    },
                    error: () => {
                        this.message.error('Failed to save portal status');
                        this.saving = false;
                    }
                });
        } else {
            this.uploadPortalService
                .postCollectionStatus(this.portalStatusFormGroup.value)
                .subscribe({
                    next: () => {
                        this.message.success(
                            `Portal status <b>${
                                this.portalStatusFormGroup.get(
                                    'collectionStatusName'
                                ).value
                            }</b> has been created`
                        );
                        this.closed.emit(true);
                        this.saving = false;
                    },
                    error: () => {
                        this.message.error('Failed to save portal status');
                        this.saving = false;
                    },
                });
        }
    }

    private initPortalStatusForm() {
        this.portalStatusFormGroup = this.fb.group({
            collectionStatusName: [
                _get(this.status, 'collectionStatusName'),
                Validators.required,
            ],
            statusColor: [
                _get(this.status, 'statusColor', '#1a8737'),
                Validators.required,
            ],
            clientId: [this.clientId, Validators.required],
        });
    }

    onColorChanged(statusColor: string) {
        this.portalStatusFormGroup.markAsDirty();
        this.portalStatusFormGroup.patchValue({ statusColor });
    }

    onClosePortalTypeForm() {
        this.closed.emit(false);
    }
}
