<div class="portal-types__table">
    <app-infinite-scroll
        [loaded]="uploadPortalTypesLoaded"
        [loading]="uploadPortalTypesLoading"
        (scrolled)="onTableScrolled()"
    >
        <nz-table
            #table
            [nzLoading]="uploadPortalTypesLoading"
            [nzSize]="'middle'"
            [nzPageSize]="99999999"
            nzHideOnSinglePage
            [nzData]="uploadPortalTypes"
        >
            <thead (nzSortOrderChange)="orderChange($event)">
                <tr>
                    <th
                        *ngFor="let column of columns"
                        [nzSortFn]="true"
                        [nzWidth]="column.width"
                        [nzSortDirections]="column.sortDirections"
                        nzColumnKey="{{ column.key }}"
                    >
                        {{ column.title }}
                    </th>
                    <th nzWidth="15%"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of table.data">
                    <td
                        [innerHTML]="
                            data.uploadPortalTypeName
                                | highlight: query
                                | safe: 'html'
                        "
                    ></td>
                    <td>
                        <nz-tag
                            *ngFor="let attributeType of data?.attributeTypes ?? []"
                        >
                            {{attributeType.attrTypeName}}
                        </nz-tag>
                    </td>
                    <td>
                        {{ data.createdStamp | date: 'dd MMM yyyy, h:mm a' }}
                    </td>
                    <td class="actions__cell">
                        <i
                            nz-icon
                            nzType="dots"
                            nzTheme="fill"
                            *hasPermission="['UPLOAD_PORTAL_MGMT']"
                        ></i>
                        <div
                            class="table-actions-container"
                            *hasPermission="[
                                'UPLOAD_PORTAL_MGMT',
                                'UPLOAD_PORTAL_MGMT_VIEW'
                            ]"
                        >
                            <ng-container *ngIf="!performingOperationPortalTypeIds.includes(data.uploadPortalTypeId); else operationSpinner">
                                <app-users-popover
                                    [entityId]="data.uploadPortalTypeId"
                                    entityType="PageTypes"
                                >
                                    <i
                                        nz-icon
                                        nz-tooltip
                                        nzTooltipPlacement="top"
                                        nzTooltipTitle="View users"
                                        nzTooltipOverlayClassName="primary-tooltip"
                                        class="action-icon"
                                        nzType="users"
                                        nzTheme="outline"
                                    ></i>
                                </app-users-popover>
                                <i
                                    *hasPermission="['UPLOAD_PORTAL_MGMT']"
                                    (click)="
                                        onActivatePortalsByType(
                                            data.uploadPortalTypeId,
                                            true
                                        )
                                    "
                                    nz-tooltip
                                    nzTooltipPlacement="top"
                                    nzTooltipTitle="Activate All Portals"
                                    nzTooltipOverlayClassName="primary-tooltip"
                                    class="action-icon"
                                    nz-icon
                                    nzType="eye"
                                    nzTheme="outline"
                                ></i>
                                <i
                                    *hasPermission="['UPLOAD_PORTAL_MGMT']"
                                    (click)="
                                        onActivatePortalsByType(
                                            data.uploadPortalTypeId,
                                            false
                                        )
                                    "
                                    nz-tooltip
                                    nzTooltipPlacement="top"
                                    nzTooltipTitle="Deactivate All Portals"
                                    nzTooltipOverlayClassName="danger-tooltip"
                                    class="action-icon delete"
                                    nz-icon
                                    nzType="eye-invisible"
                                    nzTheme="outline"
                                ></i>
                                <i
                                    *hasPermission="['UPLOAD_PORTAL_MGMT']"
                                    (click)="
                                        onRemoveType(
                                            data.uploadPortalTypeId,
                                            data.uploadPortalTypeName
                                        )
                                    "
                                    nz-tooltip
                                    nzTooltipPlacement="top"
                                    nzTooltipTitle="Delete"
                                    nzTooltipOverlayClassName="danger-tooltip"
                                    class="action-icon delete"
                                    nz-icon
                                    nzType="delete"
                                    nzTheme="outline"
                                ></i>
                                <i
                                    *hasPermission="[
                                        'UPLOAD_PORTAL_MGMT',
                                        'UPLOAD_PORTAL_MGMT_VIEW'
                                    ]"
                                    (click)="onEditType(data)"
                                    nz-tooltip
                                    nzTooltipPlacement="top"
                                    nzTooltipTitle="Edit"
                                    nzTooltipOverlayClassName="primary-tooltip"
                                    class="action-icon"
                                    nz-icon
                                    nzType="edit"
                                    nzTheme="outline"
                                ></i>
                            </ng-container>
                        </div>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </app-infinite-scroll>
</div>

<nz-drawer
    nzMaskClosable
    [nzWidth]="720"
    [nzVisible]="formMode"
    [nzTitle]="selectedPortalTypeId ? 'Edit portal type' : 'Create new portal type'"
    (nzOnClose)="closeFormMode()"
>
    <ng-container *nzDrawerContent>
        <app-portal-type-form
            [typeId]="selectedPortalTypeId"
            [clientId]="clientId"
            *ngIf="formMode"
            (closed)="closeFormMode($event)"
        ></app-portal-type-form>
    </ng-container>
</nz-drawer>

<ng-template #operationSpinner>
    <nz-spin nzTip="Processing..." nzSimple></nz-spin>
</ng-template>