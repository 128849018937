<div class="process-history-filters-container">
    @if (category !== categories.Pipelines) {
        @if (category === categories.All) {
            <nz-select
                nzShowSearch
                [nzAllowClear]="true"
                [nzMaxTagCount]="1"
                [nzMaxTagPlaceholder]="maxTagPlaceholder"
                nzMode="multiple"
                (ngModelChange)="onClientsSelected($event)"
                [nzLoading]="clientsLoading"
                [(ngModel)]="selectedClientIds"
                [nzCustomTemplate]="selectedClientTemplate"
                [nzPlaceHolder]="selectedClientIds.length ? '' : 'All clients'"
            >
                <nz-option
                    *ngFor="let client of clients"
                    [nzLabel]="client.clientName"
                    nzCustomContent
                    [nzValue]="client.clientId"
                >
                    <div class="client-template">
                        <img
                            class="client-template__logo"
                            *ngIf="client?.logoImg; else defaultLogo"
                            [src]="client?.logoImg"
                        />
                        <ng-template #defaultLogo>
                            <div class="client-template__logo"></div>
                        </ng-template>
                        <div class="client-template__name">
                            {{ client.clientName }}
                        </div>
                    </div>
                </nz-option>
            </nz-select>
        }
        <nz-select
            nzShowSearch
            [nzAllowClear]="true"
            [nzMaxTagCount]="1"
            [nzMaxTagPlaceholder]="maxTagPlaceholder"
            nzMode="multiple"
            (ngModelChange)="onUsersSelected($event)"
            [nzLoading]="usersLoading"
            [(ngModel)]="selectedUserIds"
            [nzCustomTemplate]="selectedUserTemplate"
            [nzPlaceHolder]="selectedUserIds.length ? '' : 'All users'"
        >
            <nz-option
                *ngFor="let user of users"
                [nzLabel]="user.userLoginId"
                nzCustomContent
                [nzValue]="user.userId"
            >
                {{ user.userLoginId }}
            </nz-option>
        </nz-select>
        <nz-select
            nzShowSearch
            [nzAllowClear]="true"
            *ngIf="processTypesLoading || processTypes?.length > 0"
            [nzMaxTagCount]="2"
            [nzMaxTagPlaceholder]="maxTagPlaceholder"
            nzMode="multiple"
            (ngModelChange)="onProcessTypeSelected($event)"
            [nzLoading]="processTypesLoading"
            [(ngModel)]="selectedProcessTypeIds"
            [nzPlaceHolder]="
                selectedProcessTypeIds.length ? '' : 'All process types'
            "
        >
            <nz-option
                *ngFor="let processType of processTypes"
                [nzLabel]="processType.processTypeName"
                nzCustomContent
                [nzValue]="processType.processTypeId"
            >
                {{ processType.processTypeName }}
            </nz-option>
        </nz-select>
        <nz-select
            nzShowSearch
            [nzAllowClear]="true"
            *ngIf="statusesLoading || statuses?.length > 0"
            [nzMaxTagCount]="2"
            [nzMaxTagPlaceholder]="maxTagPlaceholder"
            nzMode="multiple"
            (ngModelChange)="onStatusSelected($event)"
            [nzLoading]="statusesLoading"
            [(ngModel)]="selectedStatusIds"
            [nzPlaceHolder]="selectedStatusIds.length ? '' : 'All statuses'"
        >
            <nz-option
                *ngFor="let status of statuses"
                [nzLabel]="status.statusName"
                nzCustomContent
                [nzValue]="status.statusId"
            >
                {{ status.statusName }}
            </nz-option>
        </nz-select>
        @if (category === categories.Database) {
            <app-text-search-input
                placeholder="Search table names"
                #tableNameSearch
                (search)="onTableNameSearchTextChange($event)"
            ></app-text-search-input>
            <app-text-search-input
                placeholder="Search notes"
                #notesSearch
                (search)="onNotesSearchTextChange($event)"
            ></app-text-search-input>
        }
        <nz-range-picker
            nzMode="date"
            nzSize="large"
            [nzPlaceHolder]="['Start from', 'Start to']"
            nzFormat="yyyy/MM/dd"
            [(ngModel)]="selectedDate"
            (ngModelChange)="onStartRangeChanged($event)"
        >
        </nz-range-picker>
    } @else if (category === categories.Pipelines) {
        <app-text-search-input
            placeholder="Search pipeline names"
            #pipelineNameSearch
            (search)="onPipelineNameSearchTextChange($event)"
        ></app-text-search-input>
        <nz-select
            nzShowSearch
            [nzAllowClear]="true"
            *ngIf="pipelineTypesLoading || pipelineTypes?.length > 0"
            (ngModelChange)="onPipelineTypeSelected($event)"
            [nzLoading]="pipelineTypesLoading"
            [(ngModel)]="selectedPipelineType"
            [nzPlaceHolder]="selectedPipelineType ? '' : 'All pipeline types'"
        >
            <nz-option
                *ngFor="let pipelineType of pipelineTypes"
                [nzLabel]="pipelineType"
                nzCustomContent
                [nzValue]="pipelineType"
            >
                {{ pipelineType }}
            </nz-option>
        </nz-select>
    }
    <a
        (click)="resetFilters()"
        class="reset-filter"
        *ngIf="showResetFilters"
        nz-button
        nzSize="small"
        nzType="link"
        >Reset filters</a
    >
    <ng-template #maxTagPlaceholder let-selectedList>
        +{{ selectedList.length }}
    </ng-template>
</div>

<ng-template #selectedClientTemplate let-selected>
    <div class="client-template">
        <img
            class="client-template__logo"
            *ngIf="getClientLogo(selected.nzValue); else defaultLogo"
            [src]="getClientLogo(selected.nzValue)"
        />
        <ng-template #defaultLogo>
            <div class="client-template__logo"></div>
        </ng-template>
        <div class="client-template__name short">
            {{ selected.nzLabel }}
        </div>
    </div>
</ng-template>

<ng-template #selectedUserTemplate let-selected>
    <div class="user-template">
        <div class="user-template__email">
            {{ selected.nzLabel }}
        </div>
    </div>
</ng-template>