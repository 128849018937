<ng-container>
    <app-process-history-filters [params]="params" [category]="category" (changed)="onFilterChanged($event)"></app-process-history-filters>
    <div class="process-history__table">
        <app-infinite-scroll
            [loading]="loading"
            [loaded]="processesLoaded"
            (scrolled)="onTableScrolled()"
        >
            <nz-table
                [nzPageSize]="99999999"
                #table
                [nzSize]="'middle'"
                nzHideOnSinglePage
                [nzLoading]="loading"
                [nzData]="processes"
            >
                <thead>
                    <tr>
                        @for (column of columns; track column.title) {
                            <th
                                [nzSortFn]="column.compare"
                                [nzSortPriority]="column.priority"
                                [nzWidth]="column.width"
                            >
                                {{ column.title }}
                            </th>
                        }
                        <th nzWidth="10%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of table.data; let i = index">
                        @if (category === categories.Pipelines) {
                            <td>
                                <div *ngIf="data.dataPipelineName" [innerHTML]="
                                    data.dataPipelineName
                                        | highlight: filter.dataPipelineName
                                        | safe: 'html'
                                ">
                                </div>
                            </td>
                        }
                        @if (category === categories.Pipelines) {
                            <td>
                                {{ data.dataPipelineType }}
                            </td>
                        }
                        @if (category !== categories.Pipelines) {
                            <td>
                                <div *ngIf="data.client?.clientName" [innerHTML]="
                                    data.client?.clientName
                                        | highlight: query
                                        | safe: 'html'
                                ">
                                </div>
                            </td>
                        }
                        <td>
                            <div *ngIf="data.runBy" [innerHTML]="data.runBy
                                | highlight: query
                                | safe: 'html'">
                            </div>
                        </td>
                        @if (category === categories.Database) {
                            <td>
                                <div *ngIf="data.runParams?.schemaName" [innerHTML]="(data.runParams?.schemaName ?? '')
                                    | highlight: query
                                    | safe: 'html'">
                                </div>
                            </td>
                            <td>
                                <div *ngIf="data.runParams?.tableName" [innerHTML]="(data.runParams?.tableName ?? '')
                                    | highlight: query
                                    | safe: 'html'">
                                </div>
                            </td>
                        }
                        @if (category !== categories.Pipelines) {
                            <td>
                                <div
                                    *ngIf="data.processType?.processTypeName"
                                    [innerHTML]="
                                        data.processType?.processTypeName
                                            | highlight: query
                                            | safe: 'html'
                                    "
                                ></div>
                            </td>
                        }
                        <td
                            nz-tooltip
                            nzTooltipPlacement="top"
                            [nzTooltipTitle]="data.startedStamp | date: 'medium'"
                            nzTooltipOverlayClassName="primary-tooltip"
                        >
                            <div *ngIf="data.startedStamp">
                                {{ data.startedStamp | date: 'medium' }}
                            </div>
                            <span *ngIf="!data.startedStamp">N/A</span>
                        </td>
                        <td
                            nz-tooltip
                            nzTooltipPlacement="top"
                            [nzTooltipTitle]="data.endedStamp | date: 'medium'"
                            nzTooltipOverlayClassName="primary-tooltip"
                        >
                            <div *ngIf="data.endedStamp">
                                {{ data.endedStamp | date: 'medium' }}
                            </div>
                            <span *ngIf="!data.endedStamp">N/A</span>
                        </td>
                        <td class="status">
                            @switch (data.runStatus?.statusId) {
                                @case ('PROCESS_RUNNING') {
                                    <div class="status-name">
                                        <div class="ring-container">
                                            <div class="ringring"></div>
                                            <div class="circle"></div>
                                        </div>
                                        <div [style.color]="'#3099cc'">{{ data.runStatus.statusName }}</div>
                                    </div>
                                }
                                @case ('PROCESS_RUN_SUCCEEDED') {
                                    <app-upload-portal-status
                                        [statusName]="data.runStatus.statusName"
                                        [statusColor]="'#00D789'"
                                    ></app-upload-portal-status>
                                }
                                @case ('PROCESS_RUN_FAILED') {
                                    <app-upload-portal-status
                                        [statusName]="data.runStatus.statusName"
                                        [statusColor]="'#d61616'"
                                    ></app-upload-portal-status>
                                }
                                @case ('PROCESS_RUN_QUEUED') {
                                    <app-upload-portal-status
                                        [statusName]="data.runStatus.statusName"
                                        [statusColor]="'#3099cc'"
                                    ></app-upload-portal-status>
                                }
                                @case ('PROCESS_RUN_CANCELED') {
                                    <app-upload-portal-status
                                        [statusName]="data.runStatus.statusName"
                                        [statusColor]="'#f5a60a'"
                                    ></app-upload-portal-status>
                                }
                                @default {
                                    <app-upload-portal-status
                                        [statusName]="data.runStatus.statusName"
                                        [statusColor]="'#606a8a'"
                                    ></app-upload-portal-status>
                                }
                            }
                        </td>
                        <td class="actions__cell">
                            <i nz-icon nzType="dots" nzTheme="fill"></i>
                            <div class="table-actions-container">
                                <div class="table-actions-container">
                                    <i
                                        (click)="onViewParameters(data)"
                                        nz-tooltip
                                        nzTooltipPlacement="top"
                                        nzTooltipTitle="View Parameters"
                                        nzTooltipOverlayClassName="primary-tooltip"
                                        class="action-icon"
                                        nz-icon
                                        nzType="tool"
                                        nzTheme="outline"
                                    >
                                    </i>
                                    <i
                                        (click)="onViewLogs(data)"
                                        nz-tooltip
                                        nzTooltipPlacement="top"
                                        nzTooltipTitle="View Logs"
                                        nzTooltipOverlayClassName="primary-tooltip"
                                        class="action-icon"
                                        nz-icon
                                        nzType="error"
                                        nzTheme="outline"
                                    >
                                    </i>
                                    @if (this.category === categories.Database) {
                                        <ng-container>
                                            <i
                                                *ngIf="
                                                    data.runParams?.notes && ['DB Remove Data', 'DB Update Data', 'DB Append Data'].includes(
                                                        data.processType?.processTypeName
                                                    )
                                                "
                                                nz-tooltip
                                                nzTooltipPlacement="top"
                                                [nzTooltipTitle]="data.runParams?.notes ? data.runParams.notes : undefined"
                                                class="action-icon"
                                                nz-icon
                                                nzType="note"
                                                nzTheme="outline"
                                            ></i>
                                            <ng-container *ngIf="data.runParams?.fileId">
                                                <ng-container *ngIf="downloadingFileId !== data.runParams.fileId; else downloadingSpinner">
                                                    <i
                                                        *ngIf="['DB Remove Data', 'DB Update Data', 'DB Append Data'].includes(data.processType?.processTypeName)"
                                                        (click)="
                                                            onDownloadDocument(
                                                                data.runParams.fileId,
                                                                data.client.clientId
                                                            )
                                                        "
                                                        nz-tooltip
                                                        nzTooltipPlacement="top"
                                                        nzTooltipTitle="Download Transaction CSV"
                                                        nzTooltipOverlayClassName="primary-tooltip"
                                                        class="action-icon"
                                                        nz-icon
                                                        nzType="download"
                                                        nzTheme="outline"
                                                    >
                                                    </i>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    }
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </app-infinite-scroll>
    </div>
</ng-container>

<nz-drawer
    nzMaskClosable
    [nzWidth]="840"
    [nzVisible]="selectedMessageJSON"
    [nzTitle]="messageDrawerTitle"
    (nzOnClose)="onCloseMessageDrawer()"
>
    <ng-container *nzDrawerContent>
        <p [style.whiteSpace]="'pre-line'" nz-typography nzCopyable [nzCopyText]="messageToCopy">
            @if (isJSONViewObject(selectedMessageJSON)) {
                <ngx-json-viewer [json]="selectedMessageJSON"></ngx-json-viewer>
            } @else {
                {{selectedMessageJSON}}
            }
        </p>
    </ng-container>
</nz-drawer>

<ng-template #downloadingSpinner>
    <nz-spin nzTip="Downloading..." nzSimple [nzSize]="12" [style.marginLeft.rem]="0.75"></nz-spin>
</ng-template>

<nz-drawer
    nzMaskClosable
    [nzWidth]="840"
    [nzVisible]="showLogs"
    [nzTitle]="messageDrawerTitle"
    (nzOnClose)="onCloseLogsDrawer()"
>
    <ng-container *nzDrawerContent>
       <ng-container *ngIf="logsLoading; else logsContent">
            <div class="loading-container">
                <nz-spin nzTip="Loading..." nzSimple></nz-spin>
            </div>
       </ng-container>
       <ng-template #logsContent>
            <ng-container *ngIf="filteredLogs.length > 0; else noLogs">
                <div nz-typography nzCopyable [nzCopyText]="getStringMessage(filteredLogs)">
                    <nz-timeline>
                        <nz-timeline-item  *ngFor="let log of filteredLogs">
                            <div nz-flex [nzVertical]="true" [nzGap]="8">
                                <div [style.marginTop.rem]="0.375" nz-flex [nzVertical]="true" [nzGap]="4">
                                    @if (log.data.messages?.length) {
                                        @for (message of log.data.messages; track message) {
                                            <div [style.whiteSpace]="'pre-line'" class="log-message">
                                                {{message}}
                                            </div>
                                        }
                                    } @else if (log.data.message) {
                                        @if (log.eventTypeId === 'PROCESS_RUN_FAILED') {
                                            <ngx-json-viewer [expanded]="true" [json]="log.data" [depth]="3"></ngx-json-viewer>
                                        } @else {
                                            <div [style.whiteSpace]="'pre-line'" class="log-message">
                                                {{ log.data.message }}
                                            </div>
                                        }
                                    } @else if (log.data.error) {
                                        <div [style.whiteSpace]="'pre-line'" class="log-message error">
                                            {{ log.data.error }}
                                        </div>
                                    }
                                </div>
                                <div class="log-date">{{log.createdStamp | date: 'dd MMM yyyy, h:mm:ss a' }}</div>
                            </div>
                        </nz-timeline-item>
                    </nz-timeline>
                </div>
            </ng-container>
            <ng-template #noLogs>
                <p class="no-logs-text">No logs found</p>
            </ng-template>
       </ng-template>
    </ng-container>
</nz-drawer>