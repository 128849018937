<div class="customers-container">
    <div class="customers__controls">
        <div class="customer-controls__left">
            <div class="customers__search">
                <app-text-search-input
                    (search)="onSearch($event)"
                ></app-text-search-input>
            </div>
        </div>
        <button
            data-cy="add-client__control"
            type="button"
            *hasPermission="['CLIENT_MGMT']"
            nz-button
            (click)="onCreateCustomer()"
            nzType="primary"
        >
            <i class="button-icon" nz-icon nzType="plus" nzTheme="fill"></i>
            <span>Add client</span>
        </button>
    </div>
    <div class="customers__table">
        <app-infinite-scroll
            [loaded]="customersLoaded"
            [loading]="customersLoading"
            (scrolled)="onTableScrolled()"
        >
            <nz-table
                data-cy="clients-table"
                #table
                [nzLoading]="customersLoading"
                [nzPageSize]="9999999"
                [nzSize]="'middle'"
                nzHideOnSinglePage
                [nzData]="customers"
            >
                <thead (nzSortOrderChange)="orderChange($event)">
                    <tr>
                        <th
                            *ngFor="let column of columns"
                            [attr.data-cy]="column.dataCy"
                            [nzSortFn]="true"
                            [nzWidth]="column.width"
                            [nzSortDirections]="column.sortDirections"
                            nzColumnKey="{{ column.key }}"
                        >
                            {{ column.title }}
                        </th>
                        <th nzWidth="15%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let data of table.data; let i = index"
                        class="editable-row"
                    >
                        <td>
                            <div class="customers-table-cell__name">
                                <img
                                    class="customers-table-cell__logo"
                                    *ngIf="data.logoImg; else defaultLogo"
                                    [src]="data.logoImg"
                                />
                                <ng-template #defaultLogo>
                                    <div
                                        class="customers-table-cell__logo"
                                    ></div>
                                </ng-template>
                                <div
                                    data-cy="clients-table__name"
                                    class="customers-table-cell__name-text"
                                    [innerHTML]="
                                        data.clientName
                                            | highlight: query
                                            | safe: 'html'
                                    "
                                ></div>
                            </div>
                        </td>
                        <td
                            data-cy="clients-table__phone"
                            [innerHTML]="
                                data.primaryContactPhone | safe: 'html'
                            "
                        ></td>
                        <td data-cy="clients-table__created-at">
                            {{
                                data.createdStamp | date: 'dd MMM yyyy, h:mm a'
                            }}
                        </td>
                        <td
                            data-cy="clients-table__actions-cell"
                            class="actions__cell"
                        >
                            <i
                                nz-icon
                                nzType="dots"
                                nzTheme="fill"
                                *hasPermission="[
                                    'CLIENT_MGMT',
                                    'CLIENT_MGMT_VIEW'
                                ]"
                            ></i>
                            <div
                                data-cy="clients-table__actions"
                                class="table-actions-container"
                                *hasPermission="[
                                    'CLIENT_MGMT',
                                    'CLIENT_MGMT_VIEW'
                                ]"
                            >
                                <ng-container *ngIf="!performingOperationClientIds.includes(data.clientId); else operationSpinner">
                                    <i
                                        data-cy="client-sso-config__control"
                                        *hasPermission="[
                                            'CLIENT_MGMT',
                                            'CLIENT_MGMT_VIEW'
                                        ]"
                                        (click)="onClientSSOConfig(data.clientId)"
                                        nz-tooltip
                                        nzTooltipPlacement="top"
                                        nzTooltipTitle="SSO Configuration"
                                        nzTooltipOverlayClassName="primary-tooltip"
                                        class="action-icon"
                                        nz-icon
                                        nzType="key"
                                        nzTheme="outline"
                                    ></i>
                                    <i
                                        data-cy="client-validate-schemas__control"
                                        *hasPermission="[
                                            'CLIENT_MGMT',
                                            'CLIENT_MGMT_VIEW'
                                        ]"
                                        (click)="onValidateSchemas(data.clientId)"
                                        nz-tooltip
                                        nzTooltipPlacement="top"
                                        nzTooltipTitle="Validate schemas"
                                        nzTooltipOverlayClassName="primary-tooltip"
                                        class="action-icon"
                                        nz-icon
                                        nzType="safety"
                                        nzTheme="outline"
                                    ></i>
                                    <i
                                        data-cy="client-delete__control"
                                        *hasPermission="['CLIENT_MGMT']"
                                        (click)="
                                            onRemoveCustomer(
                                                data.clientId,
                                                data.clientName
                                            )
                                        "
                                        nz-tooltip
                                        nzTooltipPlacement="top"
                                        nzTooltipTitle="Delete"
                                        nzTooltipOverlayClassName="danger-tooltip"
                                        class="action-icon delete"
                                        nz-icon
                                        nzType="delete"
                                        nzTheme="outline"
                                    ></i>
                                    <i
                                        data-cy="client-edit__control"
                                        *hasPermission="[
                                            'CLIENT_MGMT',
                                            'CLIENT_MGMT_VIEW'
                                        ]"
                                        (click)="onEditCustomer(data.clientId)"
                                        nz-tooltip
                                        nzTooltipPlacement="top"
                                        nzTooltipTitle="Edit"
                                        nzTooltipOverlayClassName="primary-tooltip"
                                        class="action-icon"
                                        nz-icon
                                        nzType="edit"
                                        nzTheme="outline"
                                    ></i>
                                </ng-container>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </app-infinite-scroll>
    </div>
    <nz-drawer
        nzMaskClosable
        [nzWidth]="360"
        [nzVisible]="customerFormMode"
        [nzTitle]="selectedClientId ? 'Edit client' : 'Add new client'"
        (nzOnClose)="closeEditCustomer(false)"
    >
        <ng-container *nzDrawerContent>
            <app-client-form
                *ngIf="customerFormMode"
                [clientId]="selectedClientId"
                (closed)="closeEditCustomer($event)"
            ></app-client-form>
        </ng-container>
    </nz-drawer>
    <nz-drawer
        nzMaskClosable
        [nzWidth]="480"
        [nzVisible]="customerSSOConfigFormMode"
        nzTitle="SSO Configuration"
        (nzOnClose)="closeSSOConfigForm()"
    >
        <ng-container *nzDrawerContent>
            <app-client-sso-config-form
                *ngIf="customerSSOConfigFormMode"
                [clientId]="selectedClientId"
                (closed)="closeSSOConfigForm()"
            ></app-client-sso-config-form>
        </ng-container>
    </nz-drawer>
</div>

<ng-template #operationSpinner>
    <nz-spin nzTip="Processing..." nzSimple></nz-spin>
</ng-template>

<ng-template #validateSchemasModalTmpl>
    <div class="validation-results">
        @if (!missingSchemaRequirements.length) {
            <nz-alert nzShowIcon nzType="success" nzMessage="Client schema was successfully validated!"></nz-alert>
        } @else {
            @for (item of missingRequirementsByModules | keyvalue; track item.key) {
                <div class="module-group">
                    <div class="module-group-name">
                        {{item.key | titlecase }} Module
                    </div>
                    @for (req of item.value; track req) {
                        @switch(req.requirementType) {
                            @case ('SUGGESTED') {
                                <nz-alert nzShowIcon nzType="info" [nzMessage]="getReqMessage(req)"></nz-alert>
                            }
                            @case ('REQUIRED') {
                                <nz-alert nzShowIcon nzType="error" [nzMessage]="getReqMessage(req)"></nz-alert>
                            }
                            @default {
                                <nz-alert nzShowIcon nzType="warning" [nzMessage]="getReqMessage(req)"></nz-alert>
                            }
                        }
                    }
                </div>
            }
        }
    </div>
</ng-template>