import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { LcaService } from 'src/app/core/services/lca/lca.service';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import _omit from 'lodash/omit';
import _orderBy from 'lodash/orderBy';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { LCAImpactType } from 'src/app/store/models/lca-impact-type';

export interface LCAImpactCategoriesFilter {
    years?: string[];
    impactTypeIds?: number[];
    searchText?: string;
    showSelected?: boolean;
}

@Component({
    selector: 'app-lca-impact-categories-filter',
    templateUrl: './lca-impact-categories-filter.component.html',
    styleUrls: ['./lca-impact-categories-filter.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LcaImpactCategoriesFilterComponent implements OnInit, OnDestroy {
    @Output() changed: EventEmitter<LCAImpactCategoriesFilter> =
        new EventEmitter();

    years: string[] = [];
    yearsLoading: boolean = false;
    selectedYears: string[] = [];

    impactTypes: LCAImpactType[] = [];
    impactTypesLoading: boolean = false;
    selectedImpactTypeIds: number[] = [];

    filter: LCAImpactCategoriesFilter = {};

    debouncer: Subject<boolean> = new Subject();

    ngDestroyed$ = new Subject();

    constructor(
        private lcaService: LcaService,
        private cdr: ChangeDetectorRef
    ) {
        this.debouncer
            .pipe(takeUntil(this.ngDestroyed$), debounceTime(350))
            .subscribe(() => {
                this.changed.next(this.filter);
            });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    ngOnInit() {
        this.fetchYears();
        this.fetchImpactTypes();
    }

    private fetchYears() {
        this.yearsLoading = true;
        this.cdr.detectChanges();

        this.lcaService.getImpactYears().subscribe({
            next: (payload) => {
                this.years = _sortBy(_get(payload, 'payload', []));
            },
            complete: () => {
                this.yearsLoading = false;
                this.cdr.detectChanges();
            },
        });
    }

    private fetchImpactTypes() {
        this.impactTypesLoading = true;
        this.cdr.detectChanges();

        this.lcaService.getImpactTypes().subscribe({
            next: (payload) => {
                this.impactTypes = _orderBy(_get(payload, 'payload', []), impactType => impactType.impactTypeName.toLowerCase(), ['asc']);
            },
            complete: () => {
                this.impactTypesLoading = false;
                this.cdr.detectChanges();
            },
        });
    }

    onYearsSelected(years: string[]) {
        if (years.length) {
            this.filter.years = years;
        } else {
            this.filter.years = undefined;
        }
        this.debouncer.next(true);
    }

    onImpactTypeSelected(impactTypeIds: number[]) {
        if (impactTypeIds.length) {
            this.filter.impactTypeIds = impactTypeIds;
        } else {
            this.filter.impactTypeIds = undefined;
        }
        this.debouncer.next(true);
    }

    get showResetFilters() {
        return !_isEmpty(this.filter);
    }

    resetFilters() {
        this.filter = {};
        this.selectedYears = [];
        this.selectedImpactTypeIds = [];
        this.debouncer.next(true);
    }
}
