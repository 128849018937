import { UntypedFormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';

export enum DB_NAMING_REGEXP_TYPES {
    SHORT = 20,
    LONG = 64,
}

const namingRegExp: { [key in DB_NAMING_REGEXP_TYPES]: RegExp } = {
    [DB_NAMING_REGEXP_TYPES.SHORT]: new RegExp(`^[A-Za-z_0-9]{0,20}$`),
    [DB_NAMING_REGEXP_TYPES.LONG]: new RegExp(`^[A-Za-z_0-9]{0,64}$`),
};

// custom validator to check that two fields match
export function DbNaming(
    controlName: string,
    regExpType: DB_NAMING_REGEXP_TYPES = DB_NAMING_REGEXP_TYPES.LONG
): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors => {
        if (formGroup && formGroup.controls) {
            const control = formGroup.controls[controlName];
            if (control) {
                // set error on matchingControl if validation fails
                if (!namingRegExp[regExpType].test(control.value)) {
                    control.setErrors({ ...(control.errors ?? {}), dbNaming: true });
                } else {
                    control.setErrors(control.errors ? control.errors : null);
                }

                return;
            }
        }
    };
}
