import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/state';
import { selectAuthRemember } from 'src/app/store/selectors/auth.selectors';
import { of, Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class BrowserStorageService {
    storage: Storage;

    constructor(private store: Store<AppState>) {}

    private getStorage(): Observable<Storage> {
        return this.store.select(selectAuthRemember).pipe(
            mergeMap((remember) => {
                return remember
                    ? this.getLocalStorage()
                    : this.getSessionStorage();
            })
        );
    }

    private getLocalStorage(): Observable<Storage> {
        return of(window.localStorage);
    }

    private getSessionStorage(): Observable<Storage> {
        return of(window.sessionStorage);
    }

    getRawValue(key: string) {
        return (
            window.localStorage?.getItem(key) ??
            window.sessionStorage?.getItem(key)
        );
    }

    getValue(key: string): Observable<string | undefined> {
        return this.getLocalStorage().pipe(
            mergeMap((storage: Storage) => {
                const value = storage.getItem(key);

                if (!value) {
                    return this.getSessionStorage().pipe(
                        map((storage: Storage) => storage.getItem(key))
                    );
                }

                return of(value);
            })
        );
    }

    setValue(key: string, value: string): Observable<string> {
        return this.getStorage().pipe(
            mergeMap((storage: Storage) => {
                if (value) {
                    storage.setItem(key, value);
                }

                return of(value);
            })
        );
    }

    removeValue(key: string) {
        window.localStorage.removeItem(key);
        window.sessionStorage.removeItem(key);
    }
}
