import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    EventEmitter,
    Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-description-form',
    templateUrl: './description-form.component.html',
    styleUrls: ['./description-form.component.less'],
})
export class DescriptionFormComponent implements OnInit, OnDestroy {
    @Output() closed: EventEmitter<boolean> = new EventEmitter();
    @Input() description: string;

    descrForm: UntypedFormGroup;

    ngDestroyed$ = new Subject();

    constructor() {}

    ngOnInit() {}

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    onCancel() {
        this.closed.emit();
    }
}
