import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    Input,
    OnDestroy,
    AfterViewInit,
} from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import _get from 'lodash/get';
import { ViewChild, ElementRef } from '@angular/core';
import { HelpService } from 'src/app/core/services/help/help.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'app-upload-form',
    templateUrl: './upload-form.component.html',
    styleUrls: ['./upload-form.component.less'],
})
export class UploadFormComponent implements OnInit, OnDestroy, AfterViewInit {
    @Output() closed: EventEmitter<boolean> = new EventEmitter();
    @Output() callback: EventEmitter<any> = new EventEmitter();

    // file is used for passing the model ie. the file for editing or null for creating
    @Input() selectedFile: any;

    // the action indicates the action around the file (create/edit)
    @Input() action: string;

    @ViewChild('fileUpload') fileUpload: ElementRef;
    @ViewChild('filePreview') filePreview: ElementRef;

    fileForm: UntypedFormGroup;

    payload: any; // keeps temporarily the values of UI components
    @Input() isSpinning: boolean = false; // sets initially the spinning from parent component
    @Output() spinning: EventEmitter<any> = new EventEmitter(); // passes spinning status back to parent

    ngDestroyed$ = new Subject();

    constructor(
        private fb: UntypedFormBuilder,
        private helpService: HelpService,
        private message: NzMessageService
    ) {}

    // Lifecycle callbacks
    ngOnInit() {
        this.initFileForm();
    }

    ngAfterViewInit() {
        const filePreview = this.filePreview.nativeElement;

        // calculate iframe's parent element's height to adjust it
        const iframeParent = document.querySelector('.ant-drawer-body');
        let height = iframeParent.clientHeight - 60;
        if (height < 525) height = 525;
        filePreview.height = height;

        if (this.action == 'edit') {
            this.previewExistingFile();
        }
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    // Listeners from form
    onOpenSelect() {
        const fileUpload = this.fileUpload.nativeElement;
        const filePreview = this.filePreview.nativeElement;

        fileUpload.onchange = () => {
            if (fileUpload.files.length > 0) {
                this.payload.data = fileUpload.files[0];
                let url = URL.createObjectURL(this.payload.data);
                filePreview.src = url;
            }
        };

        fileUpload.click();
    }

    onCancel() {
        this.closed.emit();
    }

    // Action methods
    uploadFile() {
        this.payload.action = this.action; // passes back the action (add, edit)
        this.constructPayload();
        this.spinning.emit(true);
        if (this.action == 'edit') {
            this.payload.action = 'update';
        }

        this.callback.emit(this.payload);
    }

    previewExistingFile() {
        if (this.action == 'edit') {
            this.helpService.fetchRemoteFile(
                this.selectedFile.url,
                this.filePreview,
                () => {
                    this.spinning.emit(false);
                },
                'Cannot preview the selected file. Please try later.'
            );
        } else {
            this.helpService.fillPreviewFrame(
                this.filePreview,
                'text/html',
                null
            );
        }
    }

    resetFileToBeUploaded() {
        if (this.payload) {
            this.previewExistingFile();
            this.payload = Object.assign(this.payload, {
                inProgress: undefined,
                progress: undefined,
                data: undefined,
            });
            this.fileForm.get('fileUpload').reset();

            this.fileForm.markAsDirty();
            this.fileForm.markAllAsTouched();
        }
    }

    // Helper methods
    initFileForm() {
        this.fileForm = this.fb.group({
            docName: [
                this.action == 'edit' ? this.selectedFile.docName : '',
                [Validators.required],
            ],
            description: [
                this.action == 'edit' ? this.selectedFile.description : '',
                [],
            ],
            fileUpload: [
                '',
                this.action == 'edit' ? [] : [Validators.required],
            ],
        });

        if (this.action == 'edit') {
            this.fileForm.markAsDirty();
            this.fileForm.get('docName').markAsTouched();
            this.fileForm.get('description').markAsTouched();
        }

        this.constructPayload();
    }

    constructPayload() {
        if (this.action == 'add') {
            this.constructPayloadForAddition();
        } else if (this.action == 'edit') {
            this.constructPayloadForUpdate();
        }
    }

    constructPayloadForAddition() {
        if (!this.payload) {
            this.payload = {
                error: undefined,
            };
        } else {
            const docName = this.fileForm.get('docName').value;
            const description = this.fileForm.get('description').value;

            this.payload = Object.assign(this.payload, {
                inProgress: false,
                progress: 0,
                docName: docName,
                description: description,
            });
        }
    }

    constructPayloadForUpdate() {
        if (!this.payload) {
            this.payload = {
                id: this.selectedFile.id,
            };
        } else {
            const docName = this.fileForm.get('docName').value;
            const description = this.fileForm.get('description').value;

            this.payload = Object.assign(this.payload, {
                inProgress: false,
                progress: 0,
                docName: docName,
                description: description,
            });
        }
    }

    isNotEligibleToSubmit() {
        if (this.action == 'add') {
            return (
                !this.fileForm.valid ||
                !this.fileForm.dirty ||
                !this.payload ||
                (this.payload && this.payload.progress >= 100)
            );
        } else {
            return !this.fileForm.valid || !this.fileForm.dirty;
        }
    }
}
