<div class="client-database-table-modify-form-container">
    <div class="table-details">
        <nz-row [nzGutter]="16" *ngIf="!!tableDetails">
            <nz-col [nzSpan]="12">
                <nz-statistic
                    [nzValue]="tableDetails?.rowsCount"
                    [nzTitle]="'Records Count'"
                ></nz-statistic>
            </nz-col>
            <nz-col [nzSpan]="12">
                <nz-statistic
                    [nzValue]="tableDetails?.columns?.length ?? 0"
                    [nzTitle]="'Columns Count'"
                ></nz-statistic>
            </nz-col>
        </nz-row>
    </div>
    <form
        class="client-database-table-columns__form"
        [formGroup]="tableColumnsFormGroup"
        nz-form
        action="none"
    >
        <nz-table
            [nzPageSize]="1000"
            #table
            [nzLoading]="tableColumnsLoading || tableDetailsLoading"
            [nzSize]="'middle'"
            nzHideOnSinglePage
            [nzData]="tableColumns"
        >
            <thead>
                <tr>
                    <th nzWidth="1rem"></th>
                    <th nzWidth="14.75rem">Field name</th>
                    <th nzWidth="15.125rem">Data Type</th>
                    <th nzWidth="8.125rem">Default Value</th>
                    <th nzWidth="4rem">Not null</th>
                    <th nzWidth="4.5rem"></th>
                    <th nzWidth="1rem"></th>
                </tr>
            </thead>
            <tbody>
                @for(data of table.data; track data.columnName; let i = $index) {
                    <tr
                        class="editable-row"
                    >
                        <ng-container *ngIf="!!tableColumnsFormGroup.get('columnName#' + i)">
                            <td [style.width.rem]="1"></td>
                            <td [style.width.rem]="16.75">
                                <nz-form-item>
                                    <nz-form-control
                                        nzValidateStatus
                                        nzHasFeedback
                                        [nzErrorTip]="columnNameErrorTmpl"
                                    >
                                        <nz-input-group nzSize="large">
                                            <input
                                                [formControlName]="'columnName#' + i"
                                                nz-input
                                                placeholder="Enter Field Name"
                                            />
                                        </nz-input-group>
                                        <ng-template #columnNameErrorTmpl let-control>
                                            <ng-container
                                                *ngIf="control.hasError('required')"
                                                >Please enter field name
                                            </ng-container>
                                            <ng-container
                                                *ngIf="control.hasError('dbNaming')"
                                            >
                                                Please enter column without special
                                                characters #&#64;)([] and be a max of
                                                {{ columnNameLength }} characters in
                                                length.
                                            </ng-container>
                                        </ng-template>
                                    </nz-form-control>
                                </nz-form-item>
                            </td>
                            <td [style.width.rem]="19.125">
                                <nz-form-item>
                                    <nz-form-control
                                        nzValidateStatus
                                        nzHasFeedback
                                        [nzErrorTip]="dataTypeErrorTmpl"
                                    >
                                        <nz-select
                                            nzShowSearch
                                            nzAllowClear
                                            [nzLoading]="dataTypesLoading"
                                            nzSize="large"
                                            nzPlaceHolder="Field Type"
                                            (ngModelChange)="onColumnDataTypeChanged($event, i)"
                                            [formControlName]="'columnType#' + i"
                                        >
                                            <nz-option
                                                *ngFor="let dataType of dataTypes"
                                                [nzValue]="dataType"
                                                [nzLabel]="dataType"
                                            ></nz-option>
                                        </nz-select>
                                        <ng-template #dataTypeErrorTmpl let-control>
                                            <ng-container
                                                *ngIf="control.hasError('required')"
                                            >
                                                Please select data type
                                            </ng-container>
                                        </ng-template>
                                    </nz-form-control>
                                </nz-form-item>
                            </td>
                            <td [style.width.rem]="8.125">
                                <ng-container
                                    *ngIf="
                                        tableColumnsFormGroup.get(
                                            'columnDefaultValue#' + i
                                        )?.disabled;
                                        else defaultValueControl
                                    "
                                >
                                    {{ tableColumnsFormGroup.get('columnDefaultValue#' + i).value ?? '' }}
                                </ng-container>
                                <ng-template #defaultValueControl>
                                    <nz-form-item>
                                        <nz-form-control
                                            nzValidateStatus
                                            nzHasFeedback
                                            [nzErrorTip]="defaultValueError"
                                        >
                                            <nz-input-group nzSize="large">
                                                <nz-select *ngIf="tableColumnsFormGroup.get('columnType#' + i)?.value === 'boolean'" nzPlaceHolder="Select value" [formControlName]="
                                                'columnDefaultValue#' + i">
                                                    <nz-option *ngIf="!!tableColumnsFormGroup.get('columnNullable#' + i)?.value" [nzValue]="''" nzLabel="null"></nz-option>
                                                    <nz-option [nzValue]="true" nzLabel="True"></nz-option>
                                                    <nz-option [nzValue]="false" nzLabel="False"></nz-option>
                                                </nz-select>
                                                <input
                                                    *ngIf="tableColumnsFormGroup.get('columnType#' + i)?.value !== 'boolean'"
                                                    [formControlName]="
                                                        'columnDefaultValue#' + i
                                                    "
                                                    nz-input
                                                    placeholder="Enter Default Value"
                                                />
                                            </nz-input-group>
                                            <ng-template #defaultValueError let-control>
                                                <ng-container
                                                    *ngIf="control.hasError('required')"
                                                >
                                                    Please input default value
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="
                                                        control.hasError(
                                                            'uuid'
                                                        )
                                                    "
                                                >
                                                    Please input uuid
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="
                                                        control.hasError('pattern') || control.hasError('max') || control.hasError('min') || control.hasError('timestamp')
                                                    "
                                                >
                                                    Please input valid <b>{{tableColumnsFormGroup.get('columnType#' + i)?.value}}</b> value
                                                </ng-container>
                                            </ng-template>
                                        </nz-form-control>
                                    </nz-form-item>
                                </ng-template>
                            </td>
                            <td [style.width.rem]="5">
                                <nz-switch
                                    [formControlName]="'columnNullable#' + i"
                                    (ngModelChange)="onNotNullToggleChanged($event, i)"
                                ></nz-switch>
                            </td>
                            <td [style.width.rem]="2.75" class="actions__cell">
                                <i
                                    *ngIf="data.canDelete"
                                    (click)="onDeleteColumn(data, i)"
                                    nz-tooltip
                                    nzTooltipPlacement="top"
                                    [nzTooltipTitle]="
                                        data.isRequired
                                            ? 'Required columns cannot be removed'
                                            : data.isPrimaryKey
                                            ? 'Primary key cannot be removed'
                                            : 'Delete'
                                    "
                                    nzTooltipOverlayClassName="danger-tooltip"
                                    class="action-icon delete"
                                    nz-icon
                                    nzType="delete"
                                    nzTheme="outline"
                                    [class.not-allowed]="
                                        data.isRequired || data.isPrimaryKey || modifying
                                    "
                                ></i>
                                <button
                                    nz-button
                                    nzType="primary"
                                    *ngIf="data.canSave"
                                    [disabled]="modifying || !tableColumnsFormGroup.get('columnDefaultValue#' + i).valid"
                                    (click)="onSaveColumn(i)"
                                    [disabled]="disabledButtonsIndexes[i]"
                                    nzBlock
                                >
                                    <i
                                        nz-tooltip
                                        nzTooltipPlacement="top"
                                        nzTooltipTitle="Save column"
                                        nzTooltipOverlayClassName="primary-tooltip"
                                        class="action-icon"
                                        nz-icon
                                        nzType="done"
                                        nzTheme="outline"
                                    ></i
                                    >Save
                                </button>
                            </td>
                            <td [style.width.rem]="1"></td>
                        </ng-container>
                    </tr>
                }
            </tbody>
        </nz-table>
    </form>
    <nz-divider></nz-divider>
    <button
        class="btn-create-new-field"
        type="button"
        nzType="primary"
        nzSize="large"
        nz-button
        [disabled]="tableDetailsLoading || tableColumnsLoading"
        (click)="onCreateNewField()"
    >
        <i nz-icon nzType="plus" nzTheme="outline"></i>
        <span>Create New Field</span>
    </button>
    <div nz-col class="drawer-actions-control" nzSpan="24">
        <div nzSpan="24" class="drawer-controls">
            <button type="button" nzSize="large" nz-button (click)="onClose()">
                <span>Close</span>
            </button>
        </div>
    </div>
</div>
