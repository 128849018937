import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _first from 'lodash/first';
import _replace from 'lodash/replace';
import _isNumber from 'lodash/isNumber';
import _keyBy from 'lodash/keyBy';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Customer } from 'src/app/store/models/customer.model';
import { LCAEngineSPAsInput } from 'src/app/store/models/lca-activity-serial.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LCAActivitySerialLog } from 'src/app/store/models/lca-activityserial-log.model';
import { CustomerService } from 'src/app/core/services/customer/customer.service';
import { LcaService } from 'src/app/core/services/lca/lca.service';
import { inject } from '@angular/core';

enum Steps {
    ActivitySerial = 0,
    ImpactCategories,
    SPA,
}

enum CSVFileKeys {
    ACTIVITY_SERIALS = 'activitySerials',
}

@Component({
    selector: 'app-lca-engine-client-database-add-spa-page',
    templateUrl: './lca-engine-client-database-add-spa-page.component.html',
    styleUrls: ['./lca-engine-client-database-add-spa-page.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LcaEngineClientDatabaseAddSpaPageComponent implements OnInit {
    currentStepIndex: number = 0;
    steps = Steps;

    logs: LCAActivitySerialLog[] = [];
    logsLoading: boolean = false;

    //files
    csvFilesForm: UntypedFormGroup;
    fileList: { type: CSVFileKeys; file: any }[] = [];
    started: boolean = false;
    completed: boolean = false;
    cancelled: boolean = false;
    progress: number = 0;
    error: string;
    fromFile: boolean = false;
    addLoading: boolean = false;

    csvValidationErrors: string[] = [];

    // impact serials & categories
    impactCategoryNameByImpactSerial: {
        [key: number]: string;
    } = {};
    selectedActivitySerials: number[] = [];
    setOfImpactCategoriesChecked: Set<number> = new Set();

    //spa
    spaThresholdParamsFormGroup: UntypedFormGroup;

    client: Customer;
    clientId: string;
    loading: boolean = false;

    constructor(
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private message: NzMessageService,
        private router: Router,
        private modals: NzModalService,
        private clientService: CustomerService,
        private lcaService: LcaService,
    ) {
        this.spaThresholdParamsFormGroup = this.fb.group({
            spaLifeCycleStage: [
                '',
                [Validators.min(1), Validators.max(19), Validators.required],
            ],
        });
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.clientId = params['clientId'];
            this.loading = true;
            this.cdr.detectChanges();
            this.clientService.getCustomer(this.clientId).subscribe({
                next: (payload) => {
                    this.client = _get(payload, 'payload');
                    this.fetchLogs(this.client.clientId);
                },
                error: () => {
                    this.message.error(
                        `Failed to access LCA Engine SPAs page`,
                        { nzDuration: 4000 }
                    );
                    this.loading = false;
                    this.cdr.detectChanges();
                    this.router.navigate(['/lca-engine/impact-coefficients'], {
                        queryParamsHandling: 'preserve',
                    });
                },
                complete: () => {
                    this.loading = false;
                    this.cdr.detectChanges();
                },
            });
        });
        this.route.queryParams.subscribe((queryParams) => {
            if (queryParams['f'] === '1') {
                this.fromFile = true;
                this.initCSVFilesForm();
                this.cdr.detectChanges();
            }
        });
    }

    onPreviousStep() {
        this.currentStepIndex -= 1;
    }

    onNextStep() {
        switch (this.currentStepIndex) {
            case Steps.ActivitySerial:
                this.currentStepIndex += 1;
                break;
            case Steps.ImpactCategories:
                this.currentStepIndex += 1;
                break;
            case Steps.SPA:
                this.onAddSPAs();
                break;
            default:
                break;
        }
        this.cdr.detectChanges();
    }

    onAddSPAs(useNewLcaEngine: boolean = false) {
        const hasSerialsWhichAlreadyInDB = this.logs
            .filter(
                (item) =>
                    this.setOfImpactCategoriesChecked.has(item.impactSerial) &&
                    this.selectedActivitySerials.includes(item.activitySerial)
            )
            .some(({ logUpdatedStamp }) => !!logUpdatedStamp);

        if (hasSerialsWhichAlreadyInDB) {
            this.modals.confirm({
                nzTitle: 'Warning',
                nzContent:
                    'You have selected an impact category/impact serial that already exists in the database. Do you want to overwrite the existing data?',
                nzOnOk: () => {
                    this.onSave(useNewLcaEngine);
                },
                nzOnCancel: () => {},
                nzOkText: 'Yes',
                nzOkType: 'primary',
                nzOkDanger: true,
                nzCancelText: 'No',
                nzClosable: false,
            });
        } else {
            this.onSave(useNewLcaEngine);
        }
    }

    fetchLogs(clientId: string) {
        this.logsLoading = true;
        this.lcaService.getClientActivitySerialsLogs({ clientId }).subscribe({
            next: (payload) => {
                this.logs = _get(payload, 'payload', []);
            },
            error: () => {
                this.logs = [];
            },
            complete: () => {
                this.logsLoading = false;
                this.cdr.detectChanges();
            },
        });
    }

    private onSave(useNewLcaEngine: boolean = false) {
        const input: LCAEngineSPAsInput = {
            impactSerials: this.impactCategoriesCheckedKeys,
            activitySerials: this.selectedActivitySerials,
            spaLifeCycleStage:
                this.spaThresholdParamsFormGroup.value.spaLifeCycleStage,
            impactSerialsThresholdValues: this.impactCategoriesCheckedKeys?.map(
                (impactSerial) => ({
                    impactSerial,
                    value: _get(
                        this.spaThresholdParamsFormGroup.value,
                        `spaThreshold#${impactSerial}`
                    ),
                })
            ),
            useNewLcaEngine,
        };

        this.addLoading = true;
        this.cdr.detectChanges();
        this.lcaService.addSPAs(this.client.clientId, input).subscribe({
            next: () => {
                this.message.success(
                    `SPA data is being processed, please check the database history table for the status`,
                    { nzDuration: 4000 }
                );
                this.addLoading = false;
                this.router.navigate(['/lca-engine/client-database-spas']);
                this.cdr.detectChanges();
            },
            error: (error) => {
                let errorMessage;
                if (
                    error &&
                    error.error &&
                    error.error.fieldErrors &&
                    error.error.fieldErrors.length
                ) {
                    errorMessage =
                        error.error.fieldErrors[0]['fieldErrorMessage'] || '';
                }
                if (error && error.error && error.message) {
                    errorMessage = error.error.message;
                }
                this.addLoading = false;
                this.message.error(`Failed to add SPAs: ${errorMessage}`, {
                    nzDuration: 4000,
                });
                this.cdr.detectChanges();
            },
            complete: () => {
                this.addLoading = false;
                this.cdr.detectChanges();
            },
        });
    }

    //TODO: check on which steps you can navigate to previous step
    get isPreviousDisabled(): boolean {
        return false;
    }

    get isNextDisabled(): boolean {
        if (this.currentStepIndex === Steps.ActivitySerial) {
            return !this.selectedActivitySerials.length;
        }
        if (this.currentStepIndex === Steps.ImpactCategories) {
            return !Array.from(this.setOfImpactCategoriesChecked).length;
        }
        if (this.currentStepIndex === Steps.SPA) {
            return (
                !_isNumber(
                    this.spaThresholdParamsFormGroup.value.spaLifeCycleStage
                ) ||
                this.impactCategoriesCheckedKeys?.some(
                    (impactSerial) =>
                        !_isNumber(
                            _get(
                                this.spaThresholdParamsFormGroup.value,
                                `spaThreshold#${impactSerial}`
                            )
                        )
                ) ||
                !this.spaThresholdParamsFormGroup.valid
            );
        }
        if (this.addLoading) {
            return true;
        }
        return false;
    }

    get isNextLoading(): boolean {
        return this.addLoading;
    }

    onActivitySerialsChanged(activitySerials: number[]) {
        this.selectedActivitySerials = activitySerials;
        this.cdr.detectChanges();
    }

    onImpactCategoriesChanged({ checked, categories }) {
        this.setOfImpactCategoriesChecked = checked;
        this.impactCategoryNameByImpactSerial = _keyBy(
            categories,
            'impactSerial'
        );
        this.cdr.detectChanges();
        categories?.forEach(({ impactSerial }) => {
            if (checked.has(impactSerial)) {
                this.spaThresholdParamsFormGroup.addControl(
                    `spaThreshold#${impactSerial}`,
                    new UntypedFormControl()
                );
            } else {
                this.spaThresholdParamsFormGroup.removeControl(
                    `spaThreshold#${impactSerial}`,
                    { emitEvent: false }
                );
            }
        });
        this.cdr.detectChanges();
    }

    private initCSVFilesForm() {
        this.csvFilesForm = this.fb.group({
            files: this.fb.array([
                this.fb.group({
                    type: [CSVFileKeys.ACTIVITY_SERIALS],
                    fileName: ['', Validators.required],
                }),
            ]),
        });
    }

    get files(): UntypedFormArray {
        return this.csvFilesForm.get('files') as UntypedFormArray;
    }

    get areProvidedFilesValid(): boolean {
        //Activity master file is always required
        const isActivitySerials = _find(this.fileList, {
            type: CSVFileKeys.ACTIVITY_SERIALS,
        });

        return isActivitySerials;
    }

    beforeFileUpload(type: CSVFileKeys) {
        return async (file: NzUploadFile) => {
            this.csvValidationErrors = [];
            this.cdr.detectChanges();

            if (!this.isValidMimeType(file.type)) {
                this.message.error('Only .csv file types are allowed!', {
                    nzDuration: 5000,
                });
                return false;
            } else {
              
                const reader = new FileReader();
                reader.readAsText(file as any);
                reader.onload = () => {
                    const [, ...values] = String(reader.result)?.split('\n');
                    this.selectedActivitySerials = values.flatMap((value) => {
                        const parsedValue = Number.parseInt(
                            _replace(value, '\r', '')
                        );

                        return !parsedValue ? [] : [parsedValue];
                    });

                    console.log(this.selectedActivitySerials);
                    const uniqSerialIndexes = this.selectedActivitySerials.reduce((res, serial, index) => {
                        if (!res[serial]) {
                            res[`${serial}`] = [];
                        }

                        res[`${serial}`].push(index);

                        return res;
                    }, {})
                    
                    this.selectedActivitySerials.forEach(
                        (serial, i) => {
                            if (`${serial}`.length !== 14) {
                                this.csvValidationErrors.push(`[Line number: <b>${i}</b>]: ActivitySerial (<b>${serial}</b>) should be 14 digits in length.`);
                            }
                            if (uniqSerialIndexes[`${serial}`]?.length > 1) {
                                this.csvValidationErrors.push(`Duplicated ActivitySerial at line numbers: [<b>${uniqSerialIndexes[`${serial}`]?.join(', ')}]</b>`);
                                //clean up if already logged
                                uniqSerialIndexes[`${serial}`] = [];
                            }
                        }
                    );

                    if (this.csvValidationErrors.length) {
                        this.selectedActivitySerials = [];
                    } else {
                        (this.csvFilesForm.get('files') as UntypedFormArray).controls.map(
                            (control) => {
                                if (control.get('type').value === type) {
                                    control.patchValue({ fileName: file.name });
                                }
                            }
                        );
        
                        this.fileList = this.fileList.filter(
                            ({ type: fileType }) => fileType !== type
                        );
                        this.fileList.push({ type, file });
                    }

                    this.cdr.detectChanges();
                };

                return false;
            }
        };
    }

    private isValidMimeType(type: string): boolean {
        return type.includes('text/csv');
    }
    getFileByType(fileType: CSVFileKeys) {
        const file = _get(
            this.fileList.filter(({ type }) => fileType === type),
            [0, 'file']
        );
        return file ? [file] : [];
    }

    onFileRemove(type: CSVFileKeys) {
        (this.csvFilesForm.get('files') as UntypedFormArray).controls.map(
            (control) => {
                if (control.get('type').value === type) {
                    control.patchValue({ fileName: undefined });
                }
                this.fileList = this.fileList.filter(
                    ({ type: fileType }) => fileType !== type
                );
            }
        );
        this.selectedActivitySerials = [];
        this.cdr.detectChanges();
    }

    get impactCategoriesCheckedKeys(): number[] {
        return Array.from(this.setOfImpactCategoriesChecked);
    }

    getImpactCategoryNameBySerial(impactSerial: number): string {
        return _get(
            this.impactCategoryNameByImpactSerial[impactSerial],
            'impactName'
        );
    }
}
