<div class="reports-container">
    <div class="reports__filter">
        <div class="reports-filter__search">
            <app-text-search-input
                (search)="onSearch($event)"
            ></app-text-search-input>
        </div>
        <nz-select
            nzShowSearch
            nzAllowClear
            [nzMaxTagCount]="1"
            [nzMaxTagPlaceholder]="maxTagGroupsPlaceholder"
            [(ngModel)]="selectedGroups"
            (ngModelChange)="onFilterGroupsSelected($event)"
            [nzDropdownStyle]="{ width: '17rem' }"
            nzSize="large"
            nzMode="multiple"
            [nzPlaceHolder]="
                selectedGroups?.length ? '' : 'Filter by Report Group'
            "
            [nzLoading]="reportGroupsLoading"
            [nzOptionHeightPx]="37"
        >
            <nz-option
                *ngFor="let group of reportGroups"
                [nzValue]="group.groupId"
                [nzLabel]="group.groupName"
                nzCustomContent
            >
                <div class="group">
                    <div
                        class="group-color"
                        [style.backgroundColor]="group.groupColor"
                    ></div>
                    <div class="group-name">
                        {{ group.groupName }}
                    </div>
                </div>
            </nz-option>
        </nz-select>
        <ng-template #maxTagGroupsPlaceholder let-selectedList>
            +{{ selectedList.length }}
        </ng-template>
    </div>
    <div class="reports__table">
        <app-infinite-scroll
            [loading]="reportsLoading"
            [loaded]="reportsLoaded"
            (scrolled)="onTableScrolled()"
        >
            <nz-table
                #table
                [nzLoading]="reportsLoading"
                [nzSize]="'middle'"
                [nzPageSize]="999999999"
                nzHideOnSinglePage
                [nzData]="reports"
            >
                <thead (nzSortOrderChange)="orderChange($event)">
                    <tr>
                        <th
                            *ngFor="let column of columns"
                            [nzSortFn]="true"
                            [nzWidth]="column.width"
                            [nzSortDirections]="column.sortDirections"
                            nzColumnKey="{{ column.title }}"
                        >
                            {{ column.title }}
                        </th>
                        <th nzWidth="30%">Report Group</th>
                        <th nzWidth="15%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let data of table.data; let i = index"
                        class="editable-row"
                    >
                        <td
                            data-cy="report__name"
                            [innerHTML]="
                                data.reportName
                                    | highlight: searchFilter.searchText
                                    | safe: 'html'
                            "
                        ></td>
                        <td>
                            <div
                                data-cy="report__group-color"
                                class="group-color"
                                [style.backgroundColor]="data.reportGroup.groupColor"
                            ></div>
                            <span data-cy="report__group-name">{{
                                data.reportGroup.groupName
                            }}</span>
                        </td>
                        <td>
                            <button
                                nz-button
                                [routerLink]="'/reports/pbi/' + data.reportId"
                            >
                                View Report
                            </button>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </app-infinite-scroll>
    </div>
</div>
