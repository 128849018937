import { createAction, props } from '@ngrx/store';
import * as ReportActionTypes from './constants';
import { Report } from '../../models/report.model';
import { ReportInput } from '../../../core/services/report/report.service';
import { ReportGroupInput } from '../../../core/services/report-group/report-group.service';
import { ReportGroup } from '../../models/report-group.model';

export const createReportForClientRequest = createAction(
    ReportActionTypes.CREATE_REPORT_FOR_CLIENT_REQUEST,
    props<{ clientId: string; input: ReportInput }>()
);

export const createReportForClientReceive = createAction(
    ReportActionTypes.CREATE_REPORT_FOR_CLIENT_RECEIVE,
    props<{ report: Report }>()
);

export const createReportForClientFailed = createAction(
    ReportActionTypes.CREATE_REPORT_FOR_CLIENT_FAILED,
    props<{ error: string; clientId: string }>()
);

export const reportRequest = createAction(
    ReportActionTypes.REPORT_REQUEST,
    props<{ reportId: string }>()
);

export const reportReceive = createAction(
    ReportActionTypes.REPORT_RECEIVE,
    props<{ report: Report }>()
);

export const reportFailed = createAction(
    ReportActionTypes.REPORT_FAILED,
    props<{ error: string }>()
);

export const updateReportForClientRequest = createAction(
    ReportActionTypes.UPDATE_REPORT_FOR_CLIENT_REQUEST,
    props<{ clientId: string; input: ReportInput }>()
);

export const updateReportForClientReceive = createAction(
    ReportActionTypes.UPDATE_REPORT_FOR_CLIENT_RECEIVE,
    props<{ report: Report; clientId: string }>()
);

export const updateReportForClientFailed = createAction(
    ReportActionTypes.UPDATE_REPORT_FOR_CLIENT_FAILED,
    props<{ error: string; clientId: string }>()
);

export const createReportGroupRequest = createAction(
    ReportActionTypes.CREATE_REPORT_GROUP_REQUEST,
    props<{ input: ReportGroupInput }>()
);

export const createReportGroupReceive = createAction(
    ReportActionTypes.CREATE_REPORT_GROUP_RECEIVE,
    props<{ reportGroup: ReportGroup }>()
);

export const createReportGroupFailed = createAction(
    ReportActionTypes.CREATE_REPORT_GROUP_FAILED,
    props<{ error: string }>()
);

export const updateReportGroupRequest = createAction(
    ReportActionTypes.UPDATE_REPORT_GROUP_REQUEST,
    props<{ input: ReportGroupInput }>()
);

export const updateReportRequest = createAction(
    ReportActionTypes.UPDATE_REPORT_REQUEST,
    props<{ reportInput: ReportInput }>()
);

export const updateReportGroupReceive = createAction(
    ReportActionTypes.UPDATE_REPORT_GROUP_RECEIVE,
    props<{ reportGroup: ReportGroup }>()
);

export const updateReportGroupFailed = createAction(
    ReportActionTypes.UPDATE_REPORT_GROUP_FAILED,
    props<{ error: string }>()
);

export const deleteReportGroupRequest = createAction(
    ReportActionTypes.DELETE_REPORT_GROUP_REQUEST,
    props<{ groupId: string; clientId: string }>()
);

export const deleteReportGroupReceive = createAction(
    ReportActionTypes.DELETE_REPORT_GROUP_RECEIVE,
    props<{ groupId: string; clientId: string }>()
);

export const deleteReportGroupFailed = createAction(
    ReportActionTypes.DELETE_REPORT_GROUP_FAILED,
    props<{ error: string }>()
);

export const reportGroupsListRequest = createAction(
    ReportActionTypes.REPORT_GROUPS_LIST_REQUEST,
    props<{ clientId: string }>()
);

export const reportGroupsListReceive = createAction(
    ReportActionTypes.REPORT_GROUPS_LIST_RECEIVE,
    props<{ reportGroups: ReportGroup[] }>()
);

export const reportGroupsListFailed = createAction(
    ReportActionTypes.REPORT_GROUPS_LIST_FAILED,
    props<{ error: string }>()
);

export const reportGroupRequest = createAction(
    ReportActionTypes.REPORT_GROUP_REQUEST,
    props<{ groupId: string }>()
);

export const reportGroupReceive = createAction(
    ReportActionTypes.REPORT_GROUP_RECEIVE,
    props<{ reportGroup: ReportGroup }>()
);

export const reportGroupFailed = createAction(
    ReportActionTypes.REPORT_GROUP_FAILED,
    props<{ error: string }>()
);

export const deleteReportRequest = createAction(
    ReportActionTypes.DELETE_REPORT_REQUEST,
    props<{ reportId: string; clientId: string }>()
);

export const deleteReportReceive = createAction(
    ReportActionTypes.DELETE_REPORT_RECEIVE,
    props<{ reportId: string; clientId: string }>()
);

export const deleteReportFailed = createAction(
    ReportActionTypes.DELETE_REPORT_FAILED,
    props<{ error: string; clientId: string }>()
);
