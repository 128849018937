import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    Input,
    OnDestroy,
    AfterViewInit,
} from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import _get from 'lodash/get';
import { HelpService } from 'src/app/core/services/help/help.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'app-sheet-form',
    templateUrl: './sheet-form.component.html',
    styleUrls: ['./sheet-form.component.less'],
})
export class SheetFormComponent implements OnInit, OnDestroy, AfterViewInit {
    @Output() closed: EventEmitter<boolean> = new EventEmitter();
    @Output() callback: EventEmitter<any> = new EventEmitter();

    // selectedSheet is used for passing the model ie. the sheet for editing or null for creating
    @Input() selectedSheet: any;

    // the action indicates the action around the sheet (create/edit)
    @Input() action: string;

    sheetForm: UntypedFormGroup;

    payload: any; // keeps temporarily the values of UI components
    @Input() isSpinning: boolean = false; // sets initially the spinning from parent component
    @Output() spinning: EventEmitter<any> = new EventEmitter(); // passes spinning status back to parent

    ngDestroyed$ = new Subject();

    constructor(
        private fb: UntypedFormBuilder,
        private helpService: HelpService,
        private message: NzMessageService
    ) {}

    // Lifecycle callbacks
    ngOnInit() {
        this.initSheetForm();
    }

    ngAfterViewInit() {}

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    // Listeners from form
    onOpenSelect() {}

    onCancel() {
        this.closed.emit();
    }

    // Action methods
    saveSheet() {
        this.payload.action = this.action; // passes back the action (add, edit)
        this.constructPayload();

        if (this.action == 'edit') {
            this.payload.action = 'update';
        }

        this.callback.emit(this.payload);
    }

    // Helper methods
    initSheetForm() {
        this.sheetForm = this.fb.group({
            sheetNumber: [
                this.action == 'edit' ? this.selectedSheet.sheetNumber : '',
                [Validators.required],
            ],
            sheetName: [
                this.action == 'edit' ? this.selectedSheet.sheetName : '',
                [Validators.required],
            ],
            description: [
                this.action == 'edit' ? this.selectedSheet.description : '',
                [],
            ],
            readWrite: [
                this.action == 'edit' ? this.selectedSheet.readWrite : true,
                [],
            ],
        });

        if (this.action == 'edit') {
            this.sheetForm.markAsDirty();
            this.sheetForm.get('sheetNumber').markAsTouched();
            this.sheetForm.get('sheetName').markAsTouched();
            this.sheetForm.get('description').markAsTouched();
            this.sheetForm.get('readWrite').markAsTouched();
        }

        this.constructPayload();
    }

    constructPayload() {
        if (this.action == 'add') {
            this.constructPayloadForAddition();
        } else if (this.action == 'edit') {
            this.constructPayloadForUpdate();
        }
    }

    constructPayloadForAddition() {
        if (!this.payload) {
            this.payload = {
                error: undefined,
            };
        } else {
            const sheetNumber = this.sheetForm.get('sheetNumber').value;
            const sheetName = this.sheetForm.get('sheetName').value;
            const description = this.sheetForm.get('description').value;
            const readWrite = this.sheetForm.get('readWrite').value;

            this.payload = Object.assign(this.payload, {
                sheetNumber: sheetNumber,
                sheetName: sheetName,
                description: description,
                readWrite: readWrite,
            });
        }
    }

    constructPayloadForUpdate() {
        if (!this.payload) {
            this.payload = {
                id: this.selectedSheet.id,
            };
        } else {
            const sheetNumber = this.sheetForm.get('sheetNumber').value;
            const sheetName = this.sheetForm.get('sheetName').value;
            const description = this.sheetForm.get('description').value;
            const readWrite = this.sheetForm.get('readWrite').value;

            this.payload = Object.assign(this.payload, {
                inProgress: false,
                progress: 0,
                sheetNumber: sheetNumber,
                sheetName: sheetName,
                description: description,
                readWrite: readWrite,
            });
        }
    }

    isNotEligibleToSubmit() {
        if (this.action == 'add') {
            return (
                !this.sheetForm.valid || !this.sheetForm.dirty || !this.payload
            );
        } else {
            return !this.sheetForm.valid || !this.sheetForm.dirty;
        }
    }
}
