import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { NotfoundPageComponent } from './components/pages/notfound-page/notfound-page.component';
import { DataPageComponent } from './components/pages/data-page/data-page.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ReportsPageComponent } from './components/pages/reports-page/reports-page.component';
import { PowerbiReportPageComponent } from './components/pages/powerbi-report-page/powerbi-report-page.component';
import { CustomerPageComponent } from './components/pages/customer-page/customer-page.component';
import { McdonaldsComponent } from './components/pages/oauth/mcdonalds/mcdonalds.component';
import { UserPageComponent } from './components/pages/user-page/user-page.component';
import { RolesPageComponent } from './components/pages/roles-page/roles-page.component';
import { ManageReportsPageComponent } from './components/pages/manage-reports-page/manage-reports-page.component';
import { PortalTypesPageComponent } from './components/pages/portal-types-page/portal-types-page.component';
import { AttributeTypesPageComponent } from './components/pages/attribute-types-page/attribute-types-page.component';
import { IndexPageComponent } from './components/pages/index-page/index-page.component';
import { DatabasePageComponent } from './components/pages/database-page/database-page.component';
import { HelpComponent } from './components/pages/help-page/help.component';
import { PreviewComponent } from './components/preview/preview.component';
import { LcaEngineSandboxPageComponent } from './components/pages/lca-engine-sandbox-page/lca-engine-sandbox-page.component';
import { FeedbackComponent } from './components/pages/feedback-page/feedback.component';
import { PasswordPageComponent } from './components/pages/password-page/password-page.component';
import { LcaEngineImpactCoefPageComponent } from './components/pages/lca-engine-impact-coef-page/lca-engine-impact-coef-page.component';
import { RouterExtService } from './core/services/router-ext/router-ext.service';
import { LcaEngineClientDatabaseSpaPageComponent } from './components/pages/lca-engine-client-database-spa-page/lca-engine-client-database-spa-page.component';
import { LcaEngineClientDatabaseAddSpaPageComponent } from './components/pages/lca-engine-client-database-add-spa-page/lca-engine-client-database-add-spa-page.component';
import { UserFeedbackPageComponent } from './components/user-feedback-page/user-feedback-page.component';
import { ReportingTablePageComponent } from './components/pages/reporting-table-page/reporting-table-page.component';
import { AccessDeniedPageComponent } from './components/pages/access-denied-page/access-denied-page.component';
import { ProcessHistoryPageComponent } from './components/pages/process-history-page/process-history-page.component';
import { AssuranceReportPageComponent } from './components/pages/assurance-report-page/assurance-report-page.component';
import { AppLayoutComponent } from './components/layouts/app-layout/app-layout.component';
import { NonAuthLayoutComponent } from './components/layouts/non-auth-layout/non-auth-layout.component';

const routes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            {
                path: '',
                redirectTo: '/dashboard',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                component: IndexPageComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'strategy',
                loadChildren: () =>
                    import('./strategy/strategy.module').then((m) => m.StrategyModule),
                data: {
                    permissions: ['STRATEGY_MGMT', 'STRATEGY_APPROVER', 'STRATEGY_USER', 'STRATEGY_MODULE_VIEWER']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'data-mapping',
                loadChildren: () =>
                    import('./activity-mapping/activity-mapping.module').then(
                        (m) => m.ActivityMappingModule
                    ),
                data: {
                    permissions: ['DATA_MAPPING_TOOL_USER', 'DATA_MAPPING_TOOL_MGMT']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'pipelines',
                loadChildren: () =>
                    import('./pipelines/pipelines.module').then(
                        (m) => m.PipelinesModule
                    ),
                data: {
                    permissions: ['DATA_PIPELINE_MGMT', 'DATA_PIPELINE_MGMT_VIEW', 'DATA_PIPELINE_USER', 'DATA_PIPELINE_APPROVER']
                },
                canActivate: [AuthGuard],
            },
            { path: 'data', component: DataPageComponent,  data: { permissions: ['UPLOAD_PORTAL'] }, canActivate: [AuthGuard], },
            { path: 'help', component: HelpComponent, canActivate: [AuthGuard], },
            {
                path: 'help/preview/:type/:id',
                component: PreviewComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'feedback',
                component: FeedbackComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'user-feedback',
                component: UserFeedbackPageComponent,
                data: {
                    permissions: ['AIUSER', 'AIADMIN', 'SUPERUSER']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'admin/database',
                component: DatabasePageComponent,
                data: {
                    permissions: ['AI_DB_TOOL_MGMT']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'database',
                component: DatabasePageComponent,
                data: {
                    permissions: ['CLIENT_DB_TOOL_MGMT']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'database/generate-reporting-table',
                component: ReportingTablePageComponent,
                data: {
                    permissions: ['CLIENT_DB_TOOL_MGMT']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'clients',
                component: CustomerPageComponent,
                data: {
                    permissions: ['CLIENT_MGMT', 'CLIENT_MGMT_VIEW']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'admin/users',
                component: UserPageComponent,
                data: {
                    permissions: ['USER_MGMT', 'USER_MGMT_VIEW']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'users',
                component: UserPageComponent,
                data: {
                    permissions: ['LTD_USER_ADMIN', 'USER_MGMT_VIEW', 'USER_MGMT']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'manage-reports',
                component: ManageReportsPageComponent,
                data: {
                    permissions: ['REPORT_MGMT', 'REPORT_MGMT_VIEW']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'attributes',
                component: AttributeTypesPageComponent,
                data: {
                    permissions: ['ATTR_MGMT', 'ATTR_MGMT_VIEW']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'admin/roles',
                component: RolesPageComponent,
                data: {
                    permissions: ['ROLES_MGMT', 'ROLES_MGMT_VIEW']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'roles',
                component: RolesPageComponent,
                data: {
                    permissions: ['ROLES_MGMT', 'ROLES_MGMT_VIEW']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'portal-types',
                component: PortalTypesPageComponent,
                data: {
                    permissions: ['UPLOAD_PORTAL_MGMT', 'UPLOAD_PORTAL_MGMT_VIEW']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'reports',
                component: ReportsPageComponent,
                data: {
                    permissions: ['REPORT_PORTAL']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'reports/pbi/:id',
                component: PowerbiReportPageComponent,
                data: {
                    permissions: ['REPORT_PORTAL', 'REPORT_MGMT', 'REPORT_MGMT_VIEW']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'lca-engine/sandbox',
                component: LcaEngineSandboxPageComponent,
                data: {
                    permissions: ['LCA_SANDBOX']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'lca-engine/client-database-spas/:clientId/add',
                component: LcaEngineClientDatabaseAddSpaPageComponent,
                data: {
                    permissions: ['CLIENT_DB_TOOL_MGMT']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'lca-engine/client-database-spas',
                component: LcaEngineClientDatabaseSpaPageComponent,
                data: {
                    permissions: ['CLIENT_DB_TOOL_MGMT']
                },
                canActivate: [AuthGuard],
            },
            {
                path: 'lca-engine/impact-coefficients',
                component: LcaEngineImpactCoefPageComponent,
                data: {
                    permissions: ['AI_DB_TOOL_MGMT']
                },
                canActivate: [AuthGuard],
            },
            {   path: 'process-history', component: ProcessHistoryPageComponent, data: { permissions: ['AIADMIN', 'SUPERUSER'] }, canActivate: [AuthGuard], },
            {   path: 'assurance-report', component: AssuranceReportPageComponent, data: { permissions: ['ASSURANCE_MGMT', 'ASSURANCE_VIEW'] }, canActivate: [AuthGuard], },
            {
                path: 'procurement',
                loadChildren: () =>
                    import('./procurement/procurement.module').then((m) => m.ProcurementModule),
            },
            { path: 'access-denied', component: AccessDeniedPageComponent, canActivate: [AuthGuard] },
        ]
    },
    {
        path: '',
        component: NonAuthLayoutComponent,
        children: [
            {
                path: 'sso',
                children: [
                    { path: 'callback', component: McdonaldsComponent },
                    { path: 'verification', component: McdonaldsComponent },
                ],
            },
            {
                path: 'procurement-portal',
                loadChildren: () =>
                    import('./procurement-portal/procurement-portal.module').then((m) => m.ProcurementPortalModule),
            },
            { path: 'login', component: LoginPageComponent },
            { path: 'reset-password', component: PasswordPageComponent },
            { path: '**', component: NotfoundPageComponent }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {}),
    ],
    exports: [RouterModule],
    providers: [RouterExtService],
})
export class AppRoutingModule {}
