import { OnDestroy } from '@angular/core';
import { APP_CONFIG } from '../../../core/services/api/config';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state';
import { selectAuthToken } from '../../../store/selectors/auth.selectors';
import { environment } from 'src/environments/environment';

function Pool(size: number, name: string) {
    const _this = this;

    this.taskQueue = [];
    this.workerQueue = [];
    this.workerInUseQueue = [];
    this.poolSize = size;
    this.name = name;

    const interval = setInterval(() => {
        _this.log('Running task loop timeout handler');
        const message = 'Connection is lost';
        if (window.navigator.onLine === false) {
            _this.log('Offline status is detected');
            _this.workerInUseQueue.forEach((workerThread) => {
                workerThread.worker.postMessage({
                    offline: true,
                    message: message,
                });
            });

            while (_this.taskQueue.length > 0) {
                const workerTask = _this.taskQueue.shift();
                workerTask.callback({
                    error: true,
                    attempts: workerTask.attempt,
                    recordId: workerTask.data.recordId,
                    docName: workerTask.data.docName,
                    message: message,
                    completed: false,
                });
            }
        } else {
            if (_this.taskQueue.length > 0) {
                const workerTask = _this.taskQueue.shift();
                const workerThread = _this.workerQueue.shift(); // get the worker from the front of the queue
                if (workerThread && workerTask) {
                    _this.log(
                        'Got worker thread id: ' +
                            workerThread.id +
                            ' to run a new task (' +
                            workerTask.data.recordId +
                            ')'
                    );
                    workerThread.run(workerTask);
                    _this.workerInUseQueue.push(workerThread);
                } else {
                    // if any of the 2 references is not valid for any reason clear the other
                    if (workerThread && !workerTask) {
                        _this.workerQueue.push(workerThread);
                    } else if (!workerThread && workerTask) {
                        _this.taskQueue.push(workerTask);
                    }
                }
            }
        }
    }, 2000);

    this.log = function (message: string) {
        console.log(`${message} - (${_this.name})`);
    };

    this.addWorkerTask = function (workerTask: WorkerTask) {
        _this.taskQueue.push(workerTask); // no free workers
        _this.log('Queueing the task (' + _this.taskQueue.length + ')');
    };

    this.getRandomInt = function () {
        const min = Math.ceil(1000);
        const max = Math.floor(10000000);
        return Math.floor(Math.random() * (max - min + 1) + min);
    };

    this.init = function () {
        const base = _this.getRandomInt();
        for (let i = base; i < base + size; i++) {
            // create 'size' number of worker threads
            let workerThread = new WorkerThread(_this, i);
            _this.log('Created worker thread with id: ' + workerThread.id);
            _this.workerQueue.push(workerThread);
        }
        _this.log(
            'Created totally ' + _this.workerQueue.length + ' worker threads'
        );
    };

    this.freeWorkerThread = function (workerThread: any) {
        _this.log('WorkingThread: ' + workerThread.id + ' - went back to pool');
        let index = _this.workerInUseQueue.indexOf(workerThread);
        if (index !== -1) {
            _this.workerInUseQueue.splice(index, 1);
            _this.log(
                'Removing worker thread id: ' +
                    workerThread.id +
                    ' from in-use queue, ' +
                    _this.workerInUseQueue.length
            );
        }
        _this.workerQueue.push(workerThread);
    };

    this.release = function () {
        _this.log('Destroying Pool object and releasing resources');
        clearInterval(interval);
    };

    this.cancel = function (message: string = 'Cancelled') {
        _this.log('Cancelling current operations');
        while (_this.taskQueue.length > 0) {
            const workerTask = _this.taskQueue.shift();
            workerTask.callback({
                error: true,
                attempts: workerTask.attempt,
                recordId: workerTask.data.recordId,
                docName: workerTask.data.docName,
                message: message,
                completed: false,
            });
        }

        _this.workerInUseQueue.forEach((workerThread) => {
            workerThread.worker.postMessage({
                cancelled: true,
                message: message,
            });
        });
    };
}
function WorkerThread(parentPool: any, index: number) {
    const _this = this;

    this.id = index;
    this.parentPool = parentPool;
    this.workerTask = {};
    this.worker;
    this.startTime;

    this.run = function (workerTask: WorkerTask) {
        this.startTime = new Date().getTime();
        this.workerTask = workerTask;

        // create a new web worker
        this.log('WorkingThread: ' + _this.id + ' - Creating new worker');
        const worker = new Worker(new URL('./file.worker', import.meta.url), {
            type: 'module',
        });

        worker.addEventListener('message', responseHandler, false);
        worker.addEventListener('error', errorHandler, false);

        worker.postMessage({
            serverUrl: workerTask.serverUrl,
            data: workerTask.data,
            headers: [
                {
                    name: 'Authorization',
                    value: `Bearer ${workerTask.authToken}`,
                },
            ],
        });

        this.worker = worker;
    };

    this.log = function (message) {
        this.parentPool.log(message);
    };

    function responseHandler(event: any) {
        const message = event.data;
        _this.log(
            'WorkingThread: ' +
                _this.id +
                ' - got message: ' +
                JSON.stringify(
                    Object.assign({}, message.data, { blob: undefined })
                )
        );

        const isDone = message.data.status == '--done--';

        let total = message.data.total;
        let docName = _this.workerTask.data.docName;
        let loaded = message.data.loaded;

        if (isDone) {
            if (_this.worker) {
                _this.log(
                    'WorkingThread: ' + _this.id + ' - Terminating worker'
                );
                _this.worker.terminate();
            }

            if (total == 0) {
                // for server side processed files
                // set it to filesize to mark completion
                loaded = _this.workerTask.data.fileSize;
                docName = docName + '.gz'; // gzip algorithm
            }

            let endTime = new Date().getTime();
            _this.log(
                'WorkingThread: ' +
                    _this.id +
                    ' - Took time: ' +
                    (endTime - _this.startTime)
            );

            _this.log(
                'WorkingThread: ' + _this.id + ' - Freeing working thread'
            );
            _this.parentPool.freeWorkerThread(_this);
        }

        if (total == 0) {
            // for server side processed files
            total = _this.workerTask.data.fileSize;

            const maxShown = total * 0.98;
            const fakeLoaded = loaded * 1.2;
            if (loaded < maxShown) {
                loaded = fakeLoaded < maxShown ? fakeLoaded : maxShown;
            }
        }

        _this.workerTask.callback({
            error: false,
            attempts: _this.workerTask.attempt,
            recordId: _this.workerTask.data.recordId,
            docName: docName,
            blob: message.data.blob,
            loaded: loaded,
            total: total,
            message: 'Success',
            completed: isDone,
        }); // pass to original callsback // pass to original callback
    }

    function errorHandler(error: any) {
        let message = error.message.split(':').pop();
        if (!message) message = error.message;

        _this.workerTask.callback({
            error: true,
            attempts: _this.workerTask.attempt,
            recordId: _this.workerTask.data.recordId,
            docName: _this.workerTask.data.docName,
            message: message,
            completed: false,
        }); // pass to original callsback

        if (_this.worker) {
            _this.log('WorkingThread: ' + _this.id + ' - Terminating worker');
            _this.worker.terminate();
        }

        _this.log('WorkingThread: ' + _this.id + ' - Freeing working thread');
        _this.parentPool.freeWorkerThread(_this);
    }
}

// task to run
class WorkerTask {
    callback: any;
    data: any;
    attempt: number = 0;
    serverUrl: string;
    authToken: string;

    constructor(data, serverUrl, authToken, callback) {
        this.callback = callback;
        this.data = data;
        this.serverUrl = serverUrl;
        this.authToken = authToken;
    }
}

@Injectable()
export class FileWorkerService implements OnDestroy {
    pool: any;
    poolMono: any;
    cancelled: boolean = false;
    serverUrl: string;
    authToken: string;
    maxSize: number = 100 * 1024 * 1024;

    constructor(private store: Store<AppState>) {
        console.log('Creating FileWorkerService instance');

        this.pool = new Pool(3, 'P3'); // for handling files < 100MB
        this.pool.init();

        this.poolMono = new Pool(1, 'P1'); // for handling large files
        this.poolMono.init();

        this.serverUrl = environment.apiUrl + APP_CONFIG.prefix.default;

        this.store.select(selectAuthToken).subscribe((token) => {
            this.authToken = token;
        });
    }

    addWorkerTask(data, callback) {
        const workerTask = new WorkerTask(
            data,
            this.serverUrl,
            this.authToken,
            callback
        );

        if (workerTask.data.fileSize < this.maxSize) {
            this.pool.addWorkerTask(workerTask);
        } else {
            this.poolMono.addWorkerTask(workerTask);
        }
    }

    cancelOperation() {
        this.pool.cancel();
        this.poolMono.cancel();
    }

    ngOnDestroy() {
        console.log('Destroy the FileWorkerService service');
        this.pool.release();
        this.poolMono.release();
    }
}
