import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface UploadInfo {
    key: string;
    started?: boolean;
    completed?: boolean;
    cancelled?: boolean;
    progress?: number;
}

@Injectable({
    providedIn: 'root',
})
export class UploadProgressService {
    upload$: Subject<UploadInfo> = new Subject<UploadInfo>();

    uploadsMap: Map<string, UploadInfo> = new Map<string, UploadInfo>();
    uploadRequestMap$: Map<string, Subject<any>> = new Map<
        string,
        Subject<any>
    >();

    interruptConfirmation: Subject<boolean> = new Subject<boolean>();

    constructor() {}

    start(key: string) {
        let info = this.uploadsMap.get(key);

        if (info) {
            info.started = true;
            info.cancelled = false;
        } else {
            info = {
                key,
                started: true,
                completed: false,
                cancelled: false,
                progress: 0,
            };
            this.upload$.next(info);
        }

        this.upload$.next(info);
        this.uploadsMap.set(key, info);
    }

    cancel(key: string) {
        const info = this.uploadsMap.get(key);

        if (!info) return;

        info.cancelled = true;

        this.upload$.next(info);
        this.uploadsMap.set(key, info);

        const subj = this.uploadRequestMap$.get(key);

        if (subj) {
            subj.next(true);
        }
    }

    complete(key: string) {
        const info = this.uploadsMap.get(key);

        if (!info) return;

        info.completed = true;

        this.upload$.next(info);
        this.uploadsMap.set(key, info);
    }

    reset(key: string) {
        const info = this.uploadsMap.get(key);

        if (!info) return;

        info.completed = false;
        info.started = false;
        info.cancelled = false;
        info.progress = 0;

        this.upload$.next(info);
        this.uploadsMap.set(key, info);
    }

    calculateProgress(uploadKey: string, loaded: number, total: number) {
        const info = this.uploadsMap.get(uploadKey);

        if (!info) return;

        info.progress = Math.trunc((loaded / total) * 100);

        this.uploadsMap.set(uploadKey, info);
        this.upload$.next(info);
    }

    getCloseSubscriptionByKey(key: string): Subject<any> {
        let subj = this.uploadRequestMap$.get(key);

        if (!subj) {
            subj = new Subject();
            this.uploadRequestMap$.set(key, subj);
        }

        return subj;
    }

    public setInterruptResultObservable(confirmed: boolean) {
        if (confirmed) {
            // this.cancel();
        }

        this.interruptConfirmation.next(confirmed);
    }

    public getInterruptResultObservable(): Observable<boolean> {
        return this.interruptConfirmation.asObservable();
    }

    clearUploadsMap() {
        this.uploadsMap = new Map();
        this.uploadRequestMap$ = new Map();
        this.upload$ = new Subject<UploadInfo>();
    }
}
