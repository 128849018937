<div class="client-data-table-form-container">
    <nz-alert
        *ngIf="error"
        nzType="error"
        [nzMessage]="error"
        nzShowIcon
    ></nz-alert>
    <form
        class="client-data-table__form"
        [formGroup]="tableFormGroup"
        nz-form
        (ngSubmit)="onSubmit()"
    >
        <div nz-row nzGutter="4">
            <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label nzRequired>Table name</nz-form-label>
                    <nz-form-control
                        [nzSpan]="24"
                        nzValidateStatus
                        [nzErrorTip]="tableNameErrorTmpl"
                    >
                        <nz-input-group
                            [nzSuffix]="inputLengthTracker"
                            nzSize="large"
                        >
                            <input
                                [maxlength]="tableNameLength"
                                formControlName="tableName"
                                nz-input
                                placeholder="Enter Table Name"
                            />
                        </nz-input-group>
                        <ng-template #tableNameErrorTmpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please enter table name
                            </ng-container>
                            <ng-container>
                                <span>
                                    Table name should be in snake_case without
                                    special characters #&#64;)([] and be a max of
                                    {{ tableNameLength }} characters in length.
                                </span>
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <!-- <nz-form-item>
          <nz-form-label nzRequired>Database View</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <label formControlName="includedInSqlView" nz-checkbox> Include in Database View </label>
          </nz-form-control>
        </nz-form-item> -->
                <nz-form-item>
                    <nz-form-control
                        class="drawer-actions-control"
                        [nzSpan]="24"
                    >
                        <div class="drawer-controls">
                            <button
                                type="submit"
                                nzSize="large"
                                nz-button
                                [disabled]="
                                    !tableFormGroup.valid ||
                                    !tableFormGroup.dirty ||
                                    saving
                                "
                                nzType="primary"
                            >
                                <span>Create the tables</span>
                            </button>
                            <button
                                [nzLoading]="saving"
                                [disabled]="saving"
                                type="button"
                                nzSize="large"
                                nz-button
                                (click)="onClose()"
                            >
                                <span>Close</span>
                            </button>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </form>
</div>
<ng-template #inputLengthTracker>
    <span class="input-length-tracker">
        {{ tableFormGroup.value?.tableName?.length || 0 }} /
        {{ tableNameLength }}
    </span>
</ng-template>
