import { createSelector } from '@ngrx/store';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _reverse from 'lodash/reverse';
import _orderBy from 'lodash/orderBy';
import _isEmpty from 'lodash/isEmpty';
import { AppState } from '../state';
import { ReportState } from '../reducers/report/report.reducer';
import { ReportGroup } from '../models/report-group.model';
import { Report } from '../models/report.model';

export const selectReports = (state: AppState) => state.reports;

export const selectReportsItems = createSelector(
    selectReports,
    (state: ReportState) => state.items
);

export const selectReportsError = createSelector(
    selectReports,
    (state: ReportState) => state.error
);

export const selectReportErrorByClientId = createSelector(
    selectReports,
    (state: ReportState, { clientId }) => {
        return _get(state.management, [clientId, 'error'], false);
    }
);

export const selectReportById = createSelector(
    selectReports,
    (state: ReportState, { reportId }: any) => _find(state.items, { reportId })
);

export const selectReportsByClientId = createSelector(
    selectReports,
    (state: ReportState, { clientId }: any) =>
        _get(state.management, [clientId, 'items'], [])
);

export const selectReportsLoadingByClientId = createSelector(
    selectReports,
    (state: ReportState, { clientId }: any) =>
        _get(state.management, [clientId, 'loading'], false)
);

export const selectReportGroupsItems = createSelector(
    selectReports,
    (state: ReportState) => state?.groups?.items || []
);

export const selectReportGroupsItemsLoading = createSelector(
    selectReports,
    (state: ReportState) => _get(state.groups, 'loading', [])
);

export const selectSortedReportGroupsItems = createSelector(
    selectReportGroupsItems,
    //TODO: create more pretty function / interfaces for sorting
    (items: ReportGroup[], sort: any) => {
        if (sort && sort.key === 'index') {
            return sort.value === 'ascend'
                ? items
                : _reverse((items || []).slice());
        }

        return !sort || _isEmpty(sort)
            ? items
            : _orderBy(
                  items,
                  [sort.key],
                  [sort.value === 'ascend' ? 'asc' : 'desc']
              );
    }
);

export const selectSelectedReportGroupLoading = createSelector(
    selectReports,
    (state: ReportState) => _get(state.selectedGroup, 'loading', false)
);

export const selectSelectedReportGroup = createSelector(
    selectReports,
    (state: ReportState) => _get(state.selectedGroup, 'item')
);

export const selectSelectedReport = createSelector(
    selectReports,
    (state: ReportState) => _get(state.selected, 'item')
);

export const selectSortedReportsItems = createSelector(
    selectReportsItems,
    //TODO: create more pretty function / interfaces for sorting
    (items: Report[], sort: any) => {
        if (sort && sort.key === 'index') {
            return sort.value === 'ascend'
                ? items
                : _reverse((items || []).slice());
        }

        return !sort || _isEmpty(sort)
            ? items
            : _orderBy(
                  items,
                  [sort.key],
                  [sort.value === 'ascend' ? 'asc' : 'desc']
              );
    }
);
