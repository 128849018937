import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, Observer } from 'rxjs';
import { ClientDatabaseService } from '../../../core/services/client-database/client-database.service';
import _get from 'lodash/get';
import { GlobalNotificationService } from 'src/app/core/services/global-notification/global-notification.service';

@Component({
    selector: 'app-client-database-table-update-form',
    templateUrl: './client-database-table-update-form.component.html',
    styleUrls: ['./client-database-table-update-form.component.less'],
})
export class ClientDatabaseTableUpdateFormComponent implements OnInit {
    @Input() tableName: string;
    @Input() clientId: string;
    @Input() schemaName?: string;

    @Output() closed: EventEmitter<boolean> = new EventEmitter();

    updateTableFormGroup: UntypedFormGroup;
    formSubmitted: boolean = false;
    fileList: NzUploadFile[] = [];
    error: string;
    loading: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private message: NzMessageService,
        private clientDatabaseService: ClientDatabaseService,
        private globalNotifications: GlobalNotificationService,
    ) {
        this.initUpdateTableForm();
    }

    ngOnInit() {}

    onSubmit() {
        this.formSubmitted = true;
        this.error = null;
        this.loading = true;
        this.clientDatabaseService
            .updateDataToTable(
                this.clientId,
                this.tableName,
                {
                    dataFile: this.fileList[0],
                    notes: this.updateTableFormGroup.get('note').value,
                },
                this.schemaName
            )
            .subscribe({
                next: () => {
                    this.loading = false;
                    this.message.success(
                         `<b>${this.tableName}</b> data is being processed. Please check \"Transaction History\".`
                    );
             
                    this.updateTableFormGroup.reset();
                    this.closed.emit(false);
                    this.fileList = [];

                    //reminders
                    if (this.schemaName === 'public' && ['AOriginal', 'BOriginal'].includes(this.tableName)) {
                        this.globalNotifications.updateCoeffAndSpaAndReportingTablesReminder();
                        return;
                    }
                    if (this.tableName === 'ImpactCategories') {
                        this.globalNotifications.updateReportingTablesReminder();
                        return;
                    }
                    this.globalNotifications.informPipelinesTeamReminder();
                },
                error: (error) => {
                    this.error =
                        _get(error, ['error', 'errors', 0]) ??
                        _get(error, [
                            'error',
                            'fieldErrors',
                            0,
                            'fieldErrorMessage',
                        ]);
                    this.loading = false;
                },
            });
    }

    onClose() {
        this.closed.emit(false);
    }

    initUpdateTableForm() {
        this.updateTableFormGroup = this.fb.group({
            note: [''],
        });
    }

    beforeFileUpload = (file: NzUploadFile) => {
        return new Observable((observer: Observer<boolean>) => {
            if (!this.isValidMimeType(file.type)) {
                this.message.error('Only .csv file types are allowed!', {
                    nzDuration: 5000,
                });
                observer.complete();
                return;
            } else {
                this.fileList = [file];
            }

            if (this.fileList && this.fileList.length) {
                this.updateTableFormGroup.markAsDirty();
            }

            // false for manual upload on form submit
            observer.next(false);
            observer.complete();
        });
    };

    private isValidMimeType(type: string): boolean {
        return type.includes('text/csv');
    }
}
