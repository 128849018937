import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import _get from 'lodash/get';
import { HelpService } from '../../core/services/help/help.service';
import { takeUntil } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-help-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.less'],
})
export class PreviewComponent implements OnInit, OnDestroy {
    @ViewChild('preview') preview: ElementRef;

    title: string;
    type: string;
    isSpinning: boolean = false;
    ngDestroyed$ = new Subject();

    constructor(
        private helpService: HelpService,
        private message: NzMessageService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    // Lifecycle method callbacks
    ngOnInit() {
        this.type = this.route.snapshot.params['type'];

        if (this.type == 'document') {
            this.title = 'View Document';
            this.previewDocument();
        } else if (this.type == 'smartsheet') {
            this.title = 'View Report';
            this.previewSmartsheet();
        }
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    // Fetch operation
    previewDocument() {
        const clientId = this.route.snapshot.queryParams['clientId'];
        const fileId = this.route.snapshot.params['id'];

        this.isSpinning = true;

        if (!clientId) {
            if (this.preview) {
                this.helpService.fillPreviewFrame(
                    this.preview,
                    'text/html',
                    null
                );
            }
            this.isSpinning = false;
            return;
        }

        this.helpService
            .getAccessToken(clientId, fileId, 'docs')
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (token) => {
                    if (token) {
                        const url = _get(token, 'sasToken', '');
                        this.helpService.fetchRemoteFile(
                            url,
                            this.preview,
                            () => {
                                this.isSpinning = false;
                            }
                        );
                    } else {
                        this.isSpinning = false;
                        this.helpService.fillPreviewFrame(
                            this.preview,
                            'text/html',
                            null
                        );
                    }
                },
                error: (response) => {
                    this.isSpinning = false;
                    this.displayErrorMessage(
                        response,
                        'Cannot create a file access token.'
                    );
                },
            });
    }

    previewSmartsheet() {
        const clientId = this.route.snapshot.queryParams['clientId'];
        const sheetId = this.route.snapshot.params['id'];

        this.isSpinning = true;

        if (!clientId) {
            if (this.preview) {
                this.helpService.fillPreviewFrame(
                    this.preview,
                    'text/html',
                    null
                );
            }
            this.isSpinning = false;
            return;
        }

        this.helpService
            .getSheetUrl(clientId, sheetId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (token) => {
                    if (token) {
                        const url = _get(token, 'url', '');
                        this.helpService.fetchRemoteSmartsheet(
                            url,
                            this.preview,
                            () => {
                                this.isSpinning = false;
                            }
                        );
                    } else {
                        this.isSpinning = false;
                        this.helpService.fillPreviewFrame(
                            this.preview,
                            'text/html',
                            null
                        );
                    }
                },
                error: (response) => {
                    this.isSpinning = false;
                    this.displayErrorMessage(
                        response,
                        'Cannot access the remote smartsheet'
                    );
                },
            });
    }

    // Helper methods
    displayErrorMessage(response: any, defaultMessage: string) {
        const message =
            response.error && response.error.message
                ? response.error.message
                : defaultMessage;
        this.message.error(message, { nzDuration: 3000 });
    }

    goBack() {
        if (this.type == 'document') {
            this.router.navigateByUrl('/help');
        } else if (this.type == 'smartsheet') {
            this.router.navigateByUrl('/feedback');
        }
    }
}
