<nz-select
    nzShowSearch
    [nzShowArrow]="!mini"
    data-cy="client-selector"
    [nzLoading]="customersLoading"
    [(ngModel)]="selectedClientId"
    [nzCustomTemplate]="selectedClientTemplate"
    (ngModelChange)="onClientChanged($event)"
    [nzPlaceHolder]="placeholder"
    [nzDropdownMatchSelectWidth]="false"
    [nzDisabled]="disabled"
>
    <nz-option
        *ngFor="let customer of customers"
        [nzLabel]="customer.clientName"
        nzCustomContent
        [nzValue]="customer.clientId"
    >
        <div class="client-template">
            <img
                class="client-template__logo"
                *ngIf="customer?.logoImg; else defaultLogo"
                [src]="customer?.logoImg"
            />
            <ng-template #defaultLogo>
                <div class="client-template__logo"></div>
            </ng-template>
            <div class="client-template__name">
                {{ customer.clientName }}
            </div>
        </div>
    </nz-option>
</nz-select>
<ng-template #selectedClientTemplate let-selected>
    <div class="client-template">
        <img
            class="client-template__logo"
            *ngIf="selectedClientLogo; else defaultLogo"
            [src]="selectedClientLogo"
        />
        <ng-template #defaultLogo>
            <div class="client-template__logo"></div>
        </ng-template>
        <div class="client-template__name" *ngIf="!mini">
            {{ selected.nzLabel }}
        </div>
    </div>
</ng-template>
