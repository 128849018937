<div class="password-form-container">
    <nz-alert
        *ngIf="error"
        nzType="error"
        [nzMessage]="error"
        nzShowIcon
    ></nz-alert>
    <div class="logo">
        <i nz-icon nzType="ai-logo-detailed" nzTheme="fill"></i>
    </div>
    <h1 data-cy="login-step-header" class="step-header">Setup Password</h1>
    <h3 *ngIf="mode === '1'">
        Please enter a new password for the AITrack account associated with
        <i>{{ email }}</i
        >. Once the password is reset you will be redirected to the AITrack
        login page.
    </h3>
    <h3 *ngIf="mode === '0'">
        Please enter a password for the AITrack account associated with
        <i>{{ email }}</i
        >. Once the password is created you will be redirected to the AITrack
        login page
    </h3>
    <form nz-form [formGroup]="passwordForm">
        <div style="padding-top: 20px">
            <ng-container>
                <nz-form-item>
                    <nz-form-label data-cy="password-input-label" nzRequired
                        >Password:
                    </nz-form-label>
                    <nz-form-control
                        nzValidateStatus
                        nzHasFeedback
                        [nzErrorTip]="passwordErrorTmpl"
                    >
                        <nz-input-group
                            nzSize="large"
                            nzPrefixIcon="lock"
                            [nzSuffix]="suffixPasswordTemplate"
                        >
                            <input
                                data-cy="password-input"
                                [type]="passwordVisible ? 'text' : 'password'"
                                nz-input
                                nzSize="large"
                                formControlName="password"
                                placeholder="Password"
                            />
                            <ng-template #passwordErrorTmpl let-control>
                                <ng-container
                                    *ngIf="control.hasError('required')"
                                    >* Password is required<br />
                                </ng-container>
                                <ng-container
                                    *ngIf="control.hasError('minlength')"
                                    >* Password must be at least 8 characters
                                    long<br />
                                </ng-container>
                                <ng-container
                                    *ngIf="control.hasError('maxlength')"
                                    >* Password can be 20 characters long
                                    maximum<br />
                                </ng-container>
                                <ng-container
                                    *ngIf="control.hasError('pattern')"
                                    >* Password should contain at least one
                                    digit, one lower case letter, one upper case
                                    letter, one special char out of ! &#64; # % ^ &
                                    * ( ) - _ = + ? / . > , < [ ] {{ '{' }}
                                    {{ '}' }} \ | ` ~ and be min 8 and max 20
                                    symbols length.
                                </ng-container>
                            </ng-template>
                            <ng-template #suffixPasswordTemplate>
                                <i
                                    nz-icon
                                    [nzType]="
                                        passwordVisible
                                            ? 'eye-invisible'
                                            : 'eye'
                                    "
                                    (click)="passwordVisible = !passwordVisible"
                                ></i>
                            </ng-template>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label data-cy="confirm-input-label" nzRequired
                        >Confirm Password:</nz-form-label
                    >
                    <nz-form-control
                        nzHasFeedback
                        [nzErrorTip]="confirmErrorTpl"
                    >
                        <nz-input-group
                            nzSize="large"
                            nzPrefixIcon="lock"
                            [nzSuffix]="suffixConfirmPasswordTemplate"
                        >
                            <input
                                data-cy="password-input"
                                [type]="
                                    confirmPasswordVisible ? 'text' : 'password'
                                "
                                nz-input
                                nzSize="large"
                                formControlName="confirmPassword"
                                placeholder="Confirm Password"
                            />
                            <ng-template #confirmErrorTpl let-control>
                                <ng-container
                                    *ngIf="control.hasError('required')"
                                    >* Please confirm your password<br
                                /></ng-container>
                                <ng-container
                                    *ngIf="control.hasError('confirm')"
                                    >* Password doesn't match</ng-container
                                >
                            </ng-template>
                            <ng-template #suffixConfirmPasswordTemplate>
                                <i
                                    nz-icon
                                    [nzType]="
                                        confirmPasswordVisible
                                            ? 'eye-invisible'
                                            : 'eye'
                                    "
                                    (click)="
                                        confirmPasswordVisible =
                                            !confirmPasswordVisible
                                    "
                                ></i>
                            </ng-template>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <div class="form-buttons">
                    <button
                        data-cy="submit"
                        nzSize="large"
                        nz-button
                        nzType="primary"
                        type="submit"
                        [disabled]="
                            !passwordForm.valid ||
                            !passwordForm.dirty ||
                            !_enabled
                        "
                        (click)="submitForm()"
                    >
                        Submit
                    </button>
                </div>
            </ng-container>
        </div>
    </form>
</div>
