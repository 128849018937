<div
    class="attribute-type-form-container"
    *hasPermission="['ATTR_MGMT']; class: 'disabled'"
>
    <form
        class="attribute-type__form"
        [formGroup]="attributeTypeFormGroup"
        nz-form
        (ngSubmit)="onSubmit()"
    >
        <div nz-row nzGutter="4">
            <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label nzRequired
                        >Attribute type name</nz-form-label
                    >
                    <nz-form-control
                        [nzSpan]="24"
                        nzValidateStatus
                        nzHasFeedback
                        [nzErrorTip]="attrTypeNameErrorTmpl"
                    >
                        <nz-input-group nzSize="large">
                            <input
                                data-cy="attr-type-input__name"
                                formControlName="attrTypeName"
                                nz-input
                                placeholder="Enter Attribute Type Name"
                            />
                        </nz-input-group>
                        <ng-template #attrTypeNameErrorTmpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please enter attribute type name
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label>
                        Attributes
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <nz-select
                            nzMode="tags"
                            [nzTokenSeparators]="[',']"
                            formControlName="attributes"
                            nzPlaceHolder="Define a list (type a value and press ','' or 'enter')"
                        >
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control
                        class="drawer-actions-control"
                        [nzSpan]="24"
                    >
                        <div class="drawer-controls">
                            <button
                                data-cy="attr-type-form__submit-btn"
                                *hasPermission="['ATTR_MGMT']"
                                type="button"
                                nzSize="large"
                                nz-button
                                [disabled]="
                                    !attributeTypeFormGroup.valid ||
                                    !attributeTypeFormGroup.dirty
                                "
                                (click)="onSubmit()"
                                nzType="primary"
                            >
                                <span>Save</span>
                            </button>
                            <button
                                data-cy="attr-type-form__close-btn"
                                type="button"
                                nzSize="large"
                                nz-button
                                (click)="onClose()"
                            >
                                <span>Close</span>
                            </button>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </form>
</div>
