import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AttributeActionTypes from '../../actions/attribute/constants';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AttributeService } from '../../../core/services/attribute/attribute.service';

@Injectable()
export class AttributeEffects {
    constructor(
        private actions$: Actions,
        private attributeService: AttributeService
    ) {}

    getAttributeTypesRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AttributeActionTypes.ATTRIBUTE_TYPE_LIST_REQUEST),
            mergeMap(({ clientId }) =>
                this.attributeService.getAttributeTypesByClient(clientId).pipe(
                    mergeMap(({ payload }) => {
                        return of({
                            type: AttributeActionTypes.ATTRIBUTE_TYPE_LIST_RECEIVE,
                            items: payload,
                        });
                    }),
                    catchError((error) => {
                        return of({
                            type: AttributeActionTypes.ATTRIBUTE_TYPE_LIST_FAILED,
                            error,
                        });
                    })
                )
            )
        )
    );

    getAttributesByTypeRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AttributeActionTypes.ATTRIBUTES_BY_TYPE_LIST_REQUEST),
            mergeMap(({ attrTypeId, limit }) =>
                this.attributeService
                    .getAttributesByTypeId(attrTypeId, limit)
                    .pipe(
                        mergeMap(({ payload }) => {
                            return of({
                                type: AttributeActionTypes.ATTRIBUTES_BY_TYPE_LIST_RECEIVE,
                                items: payload,
                            });
                        }),
                        catchError((error) => {
                            console.log(error);
                            return of({
                                type: AttributeActionTypes.ATTRIBUTES_BY_TYPE_LIST_FAILED,
                                error,
                            });
                        })
                    )
            )
        )
    );
}
