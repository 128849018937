import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { AppState } from '../state';
import { environment } from '../../../environments/environment';
import * as fromAuth from './auth/auth.reducer';
import * as fromReports from './report/report.reducer';
import * as fromCustomers from './customer/customer.reducer';
import * as fromCustomerUsers from './user/user.reducer';
import * as fromAttributes from './attribute/attribute.reducer';
import * as fromUploadPortals from './upload-portal/upload-portal.reducer';
import { InjectionToken } from '@angular/core';
import * as AuthActionTypes from '../actions/auth/constants';

export const reducers: ActionReducerMap<AppState> = {
    [fromAuth.authFeatureKey]: fromAuth.reducer,
    [fromReports.reportFeatureKey]: fromReports.reducer,
    [fromCustomers.customerFeatureKey]: fromCustomers.reducer,
    [fromCustomerUsers.customerUsersFeatureKey]: fromCustomerUsers.reducer,
    [fromAttributes.attributeFeatureKey]: fromAttributes.reducer,
    [fromUploadPortals.uploadPortalFeatureKey]: fromUploadPortals.reducer,
};

export function clearState(reducer) {
    return function (state, action) {
        if (action.type === AuthActionTypes.AUTH_LOGOUT_RECEIVE) {
            state = undefined;
        }

        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
    ? [clearState]
    : [clearState];

export const REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<AppState>>(
    'App Reducers'
);
export const reducerProvider = { provide: REDUCERS_TOKEN, useValue: reducers };
