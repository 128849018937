import { createAction, props } from '@ngrx/store';
import * as AttributeActionTypes from './constants';
import { AttributeType, Attribute } from '../../models/attribute.model';

export const attributesTypeListRequest = createAction(
    AttributeActionTypes.ATTRIBUTE_TYPE_LIST_REQUEST,
    props<{ clientId: string }>()
);

export const attributesTypeListReceive = createAction(
    AttributeActionTypes.ATTRIBUTE_TYPE_LIST_RECEIVE,
    props<{ items: AttributeType[] }>()
);

export const attributesTypeFailed = createAction(
    AttributeActionTypes.ATTRIBUTE_TYPE_LIST_FAILED,
    props<{ error: Object }>()
);

export const attributesByTypeListRequest = createAction(
    AttributeActionTypes.ATTRIBUTES_BY_TYPE_LIST_REQUEST,
    props<{ attrTypeId: string }>()
);

export const attributesByTypeListReceive = createAction(
    AttributeActionTypes.ATTRIBUTES_BY_TYPE_LIST_RECEIVE,
    props<{ items: Attribute[] }>()
);

export const attributesByTypeFailed = createAction(
    AttributeActionTypes.ATTRIBUTES_BY_TYPE_LIST_FAILED,
    props<{ error: Object }>()
);
