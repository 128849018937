import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import {
    DbNaming,
    DB_NAMING_REGEXP_TYPES,
} from '../../../validators/dbNaming.validator';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ClientDatabaseService, ColumnInput } from '../../../core/services/client-database/client-database.service';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import { Subject } from 'rxjs';
import {
    ClientDatabaseColumn,
    ClientDatabaseTableDetails,
} from '../../../store/models/client-database-table.model';
import { UUIDValidator } from 'src/app/validators/uuid.validator';
import { GlobalNotificationService } from 'src/app/core/services/global-notification/global-notification.service';
import { TimestampValidator } from 'src/app/validators/timestamp.validator';

@Component({
    selector: 'app-client-database-table-modify-form',
    templateUrl: './client-database-table-modify-form.component.html',
    styleUrls: ['./client-database-table-modify-form.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientDatabaseTableModifyFormComponent
    implements OnInit, OnDestroy
{
    @Input() tableName: string;
    @Input() clientId: string;
    @Input() schemaName: string;

    @Output() closed: EventEmitter<boolean> = new EventEmitter();

    tableColumns: ClientDatabaseColumn[] = [];
    tableColumnsLoading: boolean = false;
    tableColumnsFormGroup: UntypedFormGroup;

    showReminderOnClose: boolean = false;

    columnNameLength: number = 20;

    dataTypes: string[] = ['uuid', 'integer', 'smallint', 'text', 'boolean'];
    dataTypesLoading: boolean = false;

    ngDestroyed$ = new Subject();

    tableDetailsLoading: boolean;
    tableDetails: ClientDatabaseTableDetails;

    modifying: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private modal: NzModalService,
        private message: NzMessageService,
        private clientDatabaseService: ClientDatabaseService,
        private globalNotifications: GlobalNotificationService,
        private cdr: ChangeDetectorRef
    ) {
        this.initTableColumnsForm();
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    ngOnInit() {
        this.dataTypesLoading = true;
        this.cdr.detectChanges();
        this.clientDatabaseService.getDatabaseDataTypes().subscribe({
            next: (payload) => {
                this.dataTypesLoading = false;
                this.dataTypes = _sortBy(_get(payload, 'payload', []), item => item.toLowerCase());
                this.cdr.detectChanges();
            },
            error: () => {
                this.dataTypesLoading = false;
                this.cdr.detectChanges();
            },
        });

        this.fetchTableDetails();
    }

    fetchTableDetails() {
        this.tableDetailsLoading = true;
        this.cdr.detectChanges();
        this.clientDatabaseService
            .getTableDetails(this.clientId, this.tableName)
            .subscribe({
                next: (payload) => {
                    this.tableDetailsLoading = false;
                    this.tableDetails = _get(payload, 'payload');
                },
                error: () => {
                    this.tableDetailsLoading = false;
                    this.cdr.detectChanges();
                },
                complete: () => {
                    this.fetchTableColumns();
                    this.cdr.detectChanges();
                },
            });
    }

    fetchTableColumns() {
        this.tableColumnsLoading = true;
        this.cdr.detectChanges();
        this.clientDatabaseService
            .getTableColumns(this.clientId, this.tableName, this.schemaName)
            .subscribe({
                next: (payload) => {
                    this.tableColumnsLoading = false;
                    this.tableColumns = _get(payload, 'payload', []);
                    this.tableColumns = this.tableColumns.map((column) => ({
                        canSave: false,
                        canDelete: true,
                        ...column,
                    }));
                    this.initTableColumnsForm();
                    this.onCreateNewField();
                    this.cdr.detectChanges();
                },
                error: () => {
                    this.onCreateNewField();
                    this.tableColumnsLoading = false;
                    this.cdr.detectChanges();
                },
            });
    }

    private initTableColumnsForm() {
        this.tableColumnsFormGroup = this.fb.group(
            {},
            {
                validators: this.tableColumns.map(
                    (tableColumn, index: number) =>
                        DbNaming(
                            `columnName#${index}`,
                            DB_NAMING_REGEXP_TYPES.SHORT
                        )
                ),
            }
        );
        this.tableColumns.forEach((tableColumn, index: number) => {
            this.tableColumnsFormGroup.addControl(
                `columnName#${index}`,
                new UntypedFormControl({
                    value: tableColumn.columnName,
                    disabled: !tableColumn.canSave,
                })
            );
            this.tableColumnsFormGroup.addControl(
                `columnNullable#${index}`,
                new UntypedFormControl({
                    value: !tableColumn.isNullable,
                    disabled: !tableColumn.canSave,
                })
            );
            this.tableColumnsFormGroup.addControl(
                `columnType#${index}`,
                new UntypedFormControl(
                    { value: tableColumn.dataType, disabled: !tableColumn.canSave },
                    Validators.required
                )
            );
            this.tableColumnsFormGroup.addControl(
                `columnDefaultValue#${index}`,
                new UntypedFormControl({ value: tableColumn.columnDefaultValue, disabled: !tableColumn.canSave })
            );
        });
    }

    onCreateNewField() {
        this.tableColumnsFormGroup.addControl(
            `columnName#${this.tableColumns.length}`,
            new UntypedFormControl('', Validators.required)
        );
        this.tableColumnsFormGroup.addControl(
            `columnNullable#${this.tableColumns.length}`,
            //in case of changing default value to true pls check columnDefaultValue validator (when new column added)
            //default value should NOT be required if records count is 0
            new UntypedFormControl(false, Validators.required)
        );
        this.tableColumnsFormGroup.addControl(
            `columnType#${this.tableColumns.length}`,
            new UntypedFormControl('', Validators.required)
        );
        this.tableColumnsFormGroup.addControl(
            `columnDefaultValue#${this.tableColumns.length}`,
            new UntypedFormControl(null)
        );
        this.tableColumns = [
            ...this.tableColumns,
            {
                columnName: '',
                dataType: '',
                columnDefaultValue: null,
                canSave: true,
                canDelete: false,
                isNullable: true,
            },
        ];
        this.cdr.detectChanges();
    }

    onDeleteColumn(column: ClientDatabaseColumn, formIndex: number) {
        if (column.isPrimaryKey || column.isRequired) {
            return;
        }

        this.modifying = true;

        this.modal.confirm({
            nzTitle: `Do you want to delete field <b>${column.columnName}</b>?`,
            nzContent: `
        If you delete the field you will not be able to restore it and content of this field too. <br>
        Do you want to delete the field?
      `,
            nzOnOk: () => {
                this.clientDatabaseService
                    .deleteTableColumn(
                        this.clientId,
                        this.tableName,
                        column.columnName,
                        this.schemaName
                    )
                    .subscribe({
                        next: () => {
                            this.message.success(
                                `Table column <b>${column.columnName}</b> is being processed. Please check \"Transaction History\".`,
                                { nzDuration: 3500 }
                            );

                            this.tableColumns = [...this.tableColumns.filter(({ columnName }) => columnName !== column.columnName)];
                        },
                        error: (error) => {
                            this.modifying = false;

                            this.message.error(
                                `Failed to delete column <b>${column.columnName}</b>: ${_get(
                                    error,
                                    ['error', 'errors', 0]
                                )}!`,
                                {
                                    nzDuration: 6000,
                                }
                            );
                            this.cdr.detectChanges();
                        },
                        complete: () => {
                            this.modifying = false;
                            setTimeout(() => {
                                this.initTableColumnsForm();
                                this.tableColumnsFormGroup.updateValueAndValidity();
                            }, 200);
                            this.cdr.detectChanges();
                        }
                    });
            },
            nzOkText: 'Yes, delete',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzCancelText: 'Close',
            nzClosable: false,
            nzOnCancel: () => {},
        });
    }

    onSaveColumn(formIndex: number) {
        const value = this.tableColumnsFormGroup.value;

        this.modifying = true;

        const input: ColumnInput = {
            columnName: _get(value,  `columnName#${formIndex}`),
            dataType: _get(value, `columnType#${formIndex}`),
            isNullable: !_get(value, `columnNullable#${formIndex}`),
            columnDefaultValue: _get(value, `columnDefaultValue#${formIndex}`)
        }

        this.cdr.detectChanges();

        this.clientDatabaseService
            .postTableColumn(
                this.clientId,
                this.tableName,
                input,
                this.schemaName
            )
            .subscribe({
                next: () => {
                    this.message.success(
                        `Table column <b>${input.columnName}</b> is being processed. Please check \"Transaction history\".`,
                        { nzDuration: 3500 }
                    );
                    this.tableColumns[formIndex].canSave = false;
                    this.tableColumns[formIndex].canDelete = false;
                    this.tableColumns[formIndex].columnName = input.columnName;
                    this.tableColumns[formIndex].columnDefaultValue = input.columnDefaultValue === '' ? null : input.columnDefaultValue;
                    this.tableColumns[formIndex].isNullable = input.isNullable;
                    this.tableColumns[formIndex].dataType = input.dataType;
 
                    this.tableColumnsFormGroup.get(`columnName#${formIndex}`).disable();
                    this.tableColumnsFormGroup.get(`columnType#${formIndex}`).disable();
                    this.tableColumnsFormGroup.get(`columnNullable#${formIndex}`).disable();
                    this.tableColumnsFormGroup.get(`columnDefaultValue#${formIndex}`).disable();

                    this.showReminderOnClose = true;
                    this.cdr.detectChanges();
                },
                error: (error) => {
                    this.modifying = false;
                    this.message.error(
                        `Failed to create column <b>${input.columnName}</b>: ${_get(
                            error,
                            ['error', 'errors', 0]
                        )}!`,
                        { nzDuration: 6000 }
                    );
                    this.cdr.detectChanges();
                },
                complete: () => {
                    this.modifying = false;
                    this.cdr.detectChanges();
                }
            });
    }

    get disabledButtonsIndexes() {
        const formValue = this.tableColumnsFormGroup.value;

        return this.tableColumns?.map(
            (_, index) =>
                !_get(formValue, `columnName#${index}`) ||
                !_get(formValue, `columnType#${index}`) ||
                (this.tableDetails?.rowsCount > 0 &&
                !!_get(formValue, `columnNullable#${index}`)
                    ? !_get(formValue, `columnDefaultValue#${index}`)
                    : false)
        );
    }

    onNotNullToggleChanged(enabled: boolean, i: number) {
        if (enabled && !!this.tableDetails?.rowsCount) {
            this.tableColumnsFormGroup
                .get(`columnDefaultValue#${i}`)
                .addValidators(Validators.required);
        } else {
            this.tableColumnsFormGroup
                .get(`columnDefaultValue#${i}`)
                .removeValidators(Validators.required);
        }

        this.tableColumnsFormGroup
            .get(`columnDefaultValue#${i}`)
            .updateValueAndValidity({ emitEvent: true, onlySelf: true });

        this.cdr.detectChanges();
    }

    onColumnDataTypeChanged(dataType: string, i: number) {
        const control =  this.tableColumnsFormGroup.get(`columnDefaultValue#${i}`);
        const isNotNull =  this.tableColumnsFormGroup.get(`columnNullable#${i}`).value;

        control.clearValidators();

        if (dataType === 'uuid') {
            control.addValidators(UUIDValidator());
        } else if (dataType === 'smallint') {
            control.addValidators([Validators.min(-32768), Validators.max(32767), Validators.pattern("^\-?[0-9]*$")]);
        } else if (dataType === 'bigint') {
            control.addValidators([Validators.min(-9223372036854775808), Validators.max(9223372036854775807), Validators.pattern("^\-?[0-9]*$")]);
        } else if (dataType === 'integer') {
            control.addValidators([Validators.min(-2147483648), Validators.max(2147483647), Validators.pattern("^\-?[0-9]*$")]);
        } else if (dataType === 'binary') {
            control.addValidators([Validators.pattern("^[0-1]{1,}$")]);
        } else if (dataType === 'double precision') {
            control.addValidators([Validators.pattern("^\-?[0-9]*\.?[0-9]{1,100}$")])
        } else if (dataType === 'numeric') {
            control.addValidators([Validators.pattern("^\-?[0-9]*\.?[0-9]{1,100}$")])
        } else if (dataType === 'real') {
            control.addValidators([Validators.pattern("^\-?[0-9]*\.?[0-9]{1,100}$")])
        } else if (dataType === 'timestamp') {
            control.addValidators([TimestampValidator])
        } else if (dataType === 'timestamp without time zone') {
            control.addValidators([TimestampValidator])
        }

        if (isNotNull) {
            control.addValidators([Validators.required]);
        }
 
        control.updateValueAndValidity();
        this.cdr.detectChanges();
        return;
    }

    onClose() {
        if (!(this.schemaName === 'public' && ['AOriginal', 'BOriginal'].includes(this.tableName)) && this.tableName !== 'ImpactCategories') {
            this.globalNotifications.informPipelinesTeamReminder();
        }
        this.closed.emit(false);
    }
}
