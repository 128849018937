import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
} from '@angular/core';
import { Report } from '../../../store/models/report.model';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import _flatten from 'lodash/flatten';
import _orderBy from 'lodash/orderBy';
import _compact from 'lodash/compact';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state';
import {
    createReportForClientRequest,
    updateReportForClientRequest,
} from '../../../store/actions/report/report.actions';
import {
    ReportInput,
    ReportService,
} from '../../../core/services/report/report.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { ReportGroup } from '../../../store/models/report-group.model';
import { takeUntil } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
    Attribute,
    AttributeType,
} from '../../../store/models/attribute.model';
import { selectReportErrorByClientId } from 'src/app/store/selectors/report.selectors';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AttributeService } from 'src/app/core/services/attribute/attribute.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { ReportGroupService } from 'src/app/core/services/report-group/report-group.service';
import { error } from 'console';

interface AttributesTypeMapping {
    indeterminate: boolean;
    checked: string[];
    hidden?: boolean;
    attrTypeName: string;
    attributes: Attribute[];
}

@Component({
    selector: 'app-report-form',
    templateUrl: './report-form.component.html',
    styleUrls: ['./report-form.component.less'],
})
export class ReportFormComponent implements OnInit, OnDestroy {
    @Input() clientId: string;
    @Output() closed: EventEmitter<boolean>;
    @Input() clientAttributeTypes: AttributeType[];
    @Input() reportId: string;

    report: Report;

    attributesTreeNodes: NzTreeNodeOptions[] = [];
    attributesTreeNodesLoading: boolean = false;

    reportGroups: ReportGroup[];
    reportGroupsLoading: boolean = false;

    attributesByTypeId: Record<string, Attribute[]> = {};

    reportForm: UntypedFormGroup;
    reportLoading: boolean;
    reportName: string;

    attributes: Attribute[];
    attributesLoading: boolean = false;

    error$: Observable<string>;

    ngDestroyed$ = new Subject();

    tested: boolean = false;
    testing: boolean = false;
    testError;

    saving: boolean = true;

    constructor(
        private store: Store<AppState>,
        private fb: UntypedFormBuilder,
        private reportService: ReportService,
        private message: NzMessageService,
        private modal: NzModalService,
        private reportGroupService: ReportGroupService,
    ) {
        this.closed = new EventEmitter<boolean>();
    }

    ngOnInit() {
        this.reportService.onReport$
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe(({ report, isNew }) => {
                this.ngDestroyed$.next(true);
                this.closed.emit(true);
                this.saving = false;
                this.message.success(
                    `Report <b>${report.reportName}</b> has been ${
                        isNew ? 'created' : 'updated'
                    }`,
                    { nzDuration: 4000 }
                );
            });
            

        if (this.reportId) {
            this.reportLoading = true;

            this.reportService.getReport(
                this.reportId
            ).pipe(
                takeUntil(this.ngDestroyed$)
            ).subscribe({
                next: payload => {
                    this.report = _get(payload, 'payload');
                    this.initReportForm(this.report);
                    this.reportLoading = false;
                    this.initAttributeNodesTree(this.report.reportGroup);
                },
                error: () => {
                    this.message.error(`Failed to fetch report`);
                    this.reportLoading = false;
                }
            });
        } else {
            this.initReportForm();
        }

        this.reportGroupsLoading = true;
        this.reportGroupService.getReportGroups(this.clientId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: payload => {
                    this.reportGroups = _orderBy(_get(payload, 'payload', []), group => group.groupName.toLowerCase(), ['asc']);
                    this.reportGroupsLoading = false;
                },
                error: () => {
                    this.reportGroupsLoading = false;
                }
            })
    }

    private initReportForm(report?: Report) {
        this.error$ = this.store.select(selectReportErrorByClientId, {
            clientId: this.clientId,
        });
        this.error$.pipe(takeUntil(this.ngDestroyed$))
            .subscribe(() => {
                this.saving = false;
            });
        this.reportForm = this.fb.group(
            {
                pbiReportId: [
                    _get(this.report, 'pbiReportId'),
                    Validators.required,
                ],
                reportName: [
                    _get(this.report, 'reportName'),
                    Validators.required,
                ],
                rlsEnabled: [_get(this.report, 'rlsEnabled', false)],
                groupId: [this.report?.reportGroup?.groupId ?? '', Validators.required],
                attrIds: [!this.report?.attributes?.length ? [] : this.report.attributes.map(attr => attr.attrId), Validators.required],
                clientId: [this.clientId, Validators.required],
                rlsAttributeTypeIds: []
            }
        );

        if (!this.report?.reportGroup?.attributeTypes?.length || (this.report?.reportGroup?.attributeTypes ?? []).every(type => !type.attributes.length)) {
            this.reportForm.get('attrIds').removeValidators(Validators.required);
            this.reportForm.updateValueAndValidity({ emitEvent: true });
        }

        const isRlsEnabled = _get(this.report, 'rlsEnabled', false);

        this.reportForm.patchValue({
            rlsAttributeTypeIds: isRlsEnabled ? _get(report, 'rlsAttributeTypeIds', []) : [],
        });
    }

    onReportGroupChanged(reportGroupId: string) {
        const reportGroup = this.reportGroups.find(item => item.groupId === reportGroupId);

        if (!!reportGroup) {
            this.initAttributeNodesTree(reportGroup);
        } else {
            this.attributesTreeNodes = [];
        }

        this.reportForm.get('attrIds').patchValue([]);

        if (!!reportGroup?.attributeTypes?.length && reportGroup?.attributeTypes?.some(type => !!type.attributes.length)) {
            this.reportForm.get('attrIds').addValidators(Validators.required);
        } else {
            this.reportForm.get('attrIds').clearValidators();
            this.reportForm.get('attrIds').setErrors(null);
        }
        
        this.reportForm.updateValueAndValidity({ emitEvent: true });
    }

    initAttributeNodesTree(reportGroup: ReportGroup) {
        if (!reportGroup?.attributeTypes?.length) {
            this.attributesTreeNodes = [];
            this.attributesTreeNodesLoading = false;

            return;
        }

        this.attributesTreeNodes = [];
        this.attributesTreeNodesLoading = true;

        this.attributesByTypeId = reportGroup.attributeTypes.reduce((result, item) => {
            result[item.attrTypeId] = item.attributes ?? [];
            return result;
        }, {});

        this.attributesTreeNodes = reportGroup.attributeTypes.flatMap<NzTreeNodeOptions>((attrType) => {
            return {
                title: attrType.attrTypeName,
                value: `attr-type~${attrType.attrTypeId}`,
                key: `attr-type~${attrType.attrTypeId}`,
                selectable: false,
                children: _orderBy(attrType.attributes ?? [], (item: Attribute) => item.attrName.toLowerCase(), ['asc']).map((attribute: Attribute) => ({
                    title: attribute.attrName,
                    key: attribute.attrId,
                    value: attribute.attrId,
                    isLeaf: true
                }))
            };
        });

        this.attributesTreeNodesLoading = false;
    }

    submitReportForm() {
        const isRlsEnabled = _get(this.reportForm.value, 'rlsEnabled', false);
        if (isRlsEnabled) {
            this.saveReport();
        } else {
            this.modal.confirm({
                nzTitle: 'Confirmation',
                nzContent: 'Please confirm that the RLS setting for this report is correct. If RLS is active, make sure that all user attributes are appropriately assigned or unauthorized access can occur.',
                nzMaskClosable: true,
                nzOnCancel: () => {},
                nzWidth: 560,
                nzCancelText: 'Cancel - Edit report',
                nzOkText: 'Save - RLS is configured correctly',
                nzOnOk: () => {
                    this.saveReport();
                },
            });
        }
    }

    saveReport() {
        this.saving = true;

        for (const key in this.reportForm.controls) {
            this.reportForm.controls[key].markAsDirty();
            this.reportForm.controls[key].updateValueAndValidity();
        }

        const input: ReportInput = {
            pbiReportId: _get(this.reportForm.value, 'pbiReportId', ''),
            groupId: _get(this.reportForm.value, 'groupId', ''),
            reportName: _get(this.reportForm.value, 'reportName', ''),
            rlsEnabled: _get(this.reportForm.value, 'rlsEnabled', false),
            rlsAttributeTypeIds: [],
            attrIds: []
        };

        if (input.rlsEnabled) {
            input['rlsAttributeTypeIds'] = _get(this.reportForm.value, 'rlsAttributeTypeIds', []);
        }

        const attrIds = _get(this.reportForm.value, 'attrIds', []);

        if (Array.isArray(attrIds)) {
            input['attrIds'] = _compact(attrIds.flatMap(id => {
                if (id.includes('~')) {
                    const attrTypeId = id.split('~')?.[1];

                    if (!attrTypeId) {
                        return [];
                    }

                    return (this.attributesByTypeId?.[attrTypeId] ?? []).map((attr: Attribute) => attr.attrId)
                }

                return id;
            }))
        }

        if (this.report && this.report.reportId) {
            input.reportId = this.report.reportId;

            this.store.dispatch(
                updateReportForClientRequest({ input, clientId: this.clientId })
            );
        } else {
            this.store.dispatch(
                createReportForClientRequest({ clientId: this.clientId, input })
            );
        }
    }

    onCloseReportForm() {
        this.ngDestroyed$.next(true);
        this.reportForm.reset();
        this.report = null;
        this.closed.emit(true);
    }

    onTestConnection() {
        this.testError = undefined;
        this.testing = false;
        this.tested = false;

        const { pbiReportId } = this.reportForm.value;
        this.reportService.testStrategyReportConnection(this.clientId, pbiReportId).subscribe({
            next: () => {
                this.testing = false;
            },
            error: (error) => {
                this.testError = error?.error?.error ?? 'Failed';
                this.message.error(`PowerBI connection test failed`, {
                    nzDuration: 3000,
                });
                this.testing = false;
                this.tested = true;
            },
            complete: () => {
                this.tested = true;
            },
        });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }
}
