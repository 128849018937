import {
    HttpErrorResponse,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import _get from 'lodash/get';

import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/state';
import { authLogoutReceive } from '../../store/actions/auth/auth.actions';
import { NzNotificationService } from 'ng-zorro-antd/notification';

export const withoutRedirectUrls = ['/login', '/register'];
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private auth: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private store: Store<AppState>,
        private notifications: NzNotificationService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {
                        if (request.url.includes('/authenticate')) {
                            return throwError(error);
                        }

                        this.auth.removeToken();
                        // to clear state
                        this.store.dispatch(authLogoutReceive());
                        const redirect = _get(this.route, [
                            'snapshot',
                            'queryParams',
                            'redirect',
                        ]);

                        if (redirect) {
                            this.router.navigate(['/login'], {
                                queryParams: { redirect },
                            });
                        } else {
                            this.router.navigate(['/login'], {
                                queryParams: {
                                    redirect: withoutRedirectUrls.includes(
                                        this.router.url
                                    )
                                        ? '/dashboard'
                                        : this.router.url,
                                },
                            });
                        }
                    }
                    if (error.status === 403) {
                        this.router.navigate(['/access-denied']);
                    }
                    if (error.status === 409) {
                        if (
                            this.router.url?.includes('/strategy/interventions')
                        ) {
                            this.notifications.create(
                                'warning',
                                'Interaction with interventions is disabled',
                                'AITrack strategy module is undergoing maintenance. Adding/modifying interventions is currently disabled. Please try again later after reloading the page.',
                                { nzDuration: 7000 }
                            );
                            const refresh =
                                Number(
                                    _get(
                                        this.route,
                                        ['snapshot', 'queryParams', 'r'],
                                        1
                                    )
                                ) + 1;
                            this.router.navigateByUrl(
                                `/strategy/interventions?r=${refresh}}`
                            );
                        }
                    }
                }

                return throwError(error);
            })
        );
    }
}
