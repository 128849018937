import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    OnDestroy,
} from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
    UntypedFormControl,
} from '@angular/forms';
import { Subject } from 'rxjs';
import _get from 'lodash/get';
import { Permission } from '../../../store/models/permission.model';
import { Role } from '../../../store/models/role.model';
import {
    CoreService,
    RoleInput,
} from '../../../core/services/core/core.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CustomerService } from 'src/app/core/services/customer/customer.service';

@Component({
    selector: 'app-role-form',
    templateUrl: './role-form.component.html',
    styleUrls: ['./role-form.component.less'],
})
export class RoleFormComponent implements OnInit, OnDestroy {
    @Input() role: Role;
    @Input() clientId: string;

    @Output() closed: EventEmitter<boolean> = new EventEmitter();

    permissions: Permission[];
    permissionsLoading: boolean;
    error: string;
    roleForm: UntypedFormGroup;
    saving: boolean;

    ngDestroyed$ = new Subject();

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    constructor(
        private fb: UntypedFormBuilder,
        private coreService: CoreService,
        private message: NzMessageService,
        public clientService: CustomerService
    ) {}

    ngOnInit() {
        this.initRoleForm();
        if (this.role && this.role.roleId) {
            this.roleForm.patchValue({
                roleName: this.role.roleName,
                isAdmin: this.role.isAdmin,
                appResourceIds: this.role.appResources
                    ? this.role.appResources.map(
                          ({ appResourceId }) => appResourceId
                      )
                    : [],
                clientId: this.clientId,
            });
            this.fetchPermissions();
        } else {
            this.fetchPermissions();
        }
    }

    fetchPermissions() {
        this.permissionsLoading = true;
        this.coreService.getPermissions(this.clientId).subscribe({
            next: (payload) => {
                this.permissionsLoading = false;
                this.permissions = _get(payload, 'payload', []);
                const rolePermissionIds =
                    this.role && this.role.appResources
                        ? this.role.appResources.map(
                              ({ appResourceId }) => appResourceId
                          )
                        : [];
                this.permissions.forEach(({ appResourceId }) => {
                    const controlName = `permission#${appResourceId}`;
                    this.roleForm.addControl(controlName, new UntypedFormControl());
                    this.roleForm.patchValue({
                        [controlName]: this.role
                            ? rolePermissionIds.includes(appResourceId)
                            : false,
                    });
                });
            },
            complete: () => {
                this.permissionsLoading = false;
            },
        });
    }

    submitRoleForm() {
        const input: RoleInput = {
            roleName: this.roleForm.get('roleName').value,
            appResourceIds: this.roleForm.get('appResourceIds').value,
            // for security purpose, check roles here too
            isAdmin: this.roleForm.get('isAdmin').value || false,
            clientId: this.clientId,
        };
        if (this.role) {
            input.roleId = this.role.roleId;
        }
        this.saving = true;
        if (this.role && this.role.roleId) {
            this.coreService.updateRole(input).subscribe({
                next: (payload) => {
                    this.saving = false;
                    this.closed.emit(true);
                    this.coreService.onRolesChanged$.next(true);
                    this.message.success(
                        `Role <b>${payload.payload.roleName}</b> has been updated`,
                        { nzDuration: 3000 }
                    );
                },
                error: (error) => {
                    if (
                        error &&
                        error.error &&
                        error.error.fieldErrors &&
                        error.error.fieldErrors.length
                    ) {
                        this.error =
                            error.error.fieldErrors[0]['fieldErrorMessage'] ||
                            '';
                    }
                    this.saving = false;
                },
            });
        } else {
            this.coreService.createRole(input).subscribe({
                next: (payload) => {
                    this.saving = false;
                    this.closed.emit(true);
                    this.coreService.onRolesChanged$.next(true);
                    this.message.success(
                        `Role <b>${payload.payload.roleName}</b> has been created`,
                        { nzDuration: 3000 }
                    );
                },
                error: (error) => {
                    if (
                        error &&
                        error.error &&
                        error.error.fieldErrors &&
                        error.error.fieldErrors.length
                    ) {
                        this.error =
                            error.error.fieldErrors[0]['fieldErrorMessage'] ||
                            '';
                    }
                    if (error && error.error && error.error.message) {
                        this.error = error.error.message;
                    }
                    this.saving = false;
                },
            });
        }
    }

    initRoleForm() {
        this.roleForm = this.fb.group({
            roleName: ['', Validators.required],
            appResourceIds: [[]],
            isAdmin: [false],
            clientId: [this.clientId],
        });
    }

    onPermissionCheckboxesChange(selectedPermissionIds: string[]) {
        this.roleForm.markAsDirty();
        this.roleForm.updateValueAndValidity();
        this.roleForm.patchValue({ appResourceIds: selectedPermissionIds });
    }

    onCancel() {
        this.closed.emit();
    }
}
