import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import {
    Customer,
    CustomerSSOConfig,
    MissingClientRequirement,
} from '../../../store/models/customer.model';
import { Observable, Subject } from 'rxjs';
import { ClientRole } from '../../../store/models/client-role.model';
import { ClientLogin } from 'src/app/store/models/client-login.model';
import { ClientMetrics } from 'src/app/store/models/client-metrics.model';

export const AI_CLIENT_ID = '6606b9c3-e904-4108-bdbd-3ff199bf3ec4';

export interface CustomerInput {
    clientId?: string;
    clientName: string;
    primaryContactName: string;
    primaryContactEmail: string;
    primaryContactPhone: string;
    pbiWorkspaceId?: string;
    maxNumberOfUsers?: number;
    logoImg: any;
}
export interface CustomerFilter {
    searchText?: string;
    lastClientId?: string;
    lastCreatedStamp?: number;
    lastUserLoginId?: string;
    columnName?: string;
    sortDirection?: string;
    lastClientName?: string;
    excludeClientIds?: string[];
    view?: string;
}
export interface LoginType {
    loginTypeId: string;
    loginTypeName: string;
}

@Injectable({
    providedIn: 'root',
})
export class CustomerService {
    aiClientId: string = AI_CLIENT_ID;

    constructor(private apiService: ApiService) {}

    getCustomers(
        filter?: CustomerFilter,
        limit: number = 1000
    ): Observable<{ payload: Customer[] }> {
        let params = new URLSearchParams();
        
        if (limit) {
            params.set('limit', String(limit));
        };

        if (filter) {
            if (filter.excludeClientIds?.length) {
                filter.excludeClientIds.forEach(
                    clientIdToExclude => {
                        params.append('excludeClientIds', clientIdToExclude)
                    }
                )
            }
            if (filter.searchText) {
                params.set('searchText', filter.searchText);
            }
            if (filter.lastClientName) {
                params.set('lastClientName', filter.lastClientName)
            }
            if (filter.lastCreatedStamp) {
                params.set('lastCreatedStamp', String(filter.lastCreatedStamp))
            }
            if (filter.columnName) {
                params.set('sortByColumnName', filter.columnName)
            }
            if (filter.sortDirection) {
                params.set('sortDirection', filter.sortDirection)
            }
            if (filter.lastClientId) {
                params.set('lastClientId', filter.lastClientId)
            }
            if (filter.view) {
                params.set('view', filter.view);
            }
        }
        return this.apiService.get(`clients${params.size ? `?${params.toString()}` : ''}`);
    }
    getCustomer(clientId: string): Observable<{ payload: Customer }> {
        return this.apiService.get(`client/${clientId}`);
    }

    getCustomerLogins(limit: number): Observable<{ payload: ClientLogin[] }> {
        return this.apiService.get(`client/login-counts?limit=${limit}`);
    }

    getCustomerLoginTypes(clientId: string): Observable<{
        payload: LoginType[];
    }> {
        return this.apiService.get(`login-types`, { clientId });
    }

    getCustomerMetrics(): Observable<{ payload: ClientMetrics[] }> {
        return this.apiService.get(`client/metrics`);
    }

    getCustomerRoles(
        clientId: string,
        limit: number = 1000
    ): Observable<{ payload: ClientRole[] }> {
        return this.apiService.get(`roles`, { clientId, limit });
    }

    getCustomerRolesForUsers(
        clientId: string,
        limit: number = 1000
    ): Observable<{ payload: ClientRole[] }> {
        return this.apiService.get(`roles-for-users`, { clientId, limit });
    }

    postCustomer(input: CustomerInput): Observable<{ payload: Customer }> {
        return this.apiService.post('client', input);
    }

    putCustomer(input: CustomerInput): Observable<{ payload: Customer }> {
        return this.apiService.put('client', input);
    }

    deleteCustomer(clientId: string): Observable<any> {
        return this.apiService.delete(`client/${clientId}`);
    }

    getMaxNumberOfUsersByClientId(clientId: string): Observable<any> {
        return this.apiService.get(`client/${clientId}/maxusers`);
    }

    getSSOConfig(clientId: string): Observable<{ payload: CustomerSSOConfig }> {
        return this.apiService.get(`client/${clientId}/sso-config`);
    }

    postSSOConfig(
        clientId: string,
        input: CustomerSSOConfig
    ): Observable<{ payload: CustomerSSOConfig }> {
        return this.apiService.post(`client/${clientId}/sso-config`, input);
    }

    deleteSSOConfig(clientId: string): Observable<void> {
        return this.apiService.delete(`client/${clientId}/sso-config`);
    }

    getMissingSchemaRequirements(clientId: string): Observable<{ payload: MissingClientRequirement[] }> {
        return this.apiService.get(`client/${clientId}/missing-schema-requirements`);
    }
}
