<div class="lca-impact-coef-page-container">
    <div nz-flex nzAlign="center" nzJustify="space-between">
        <h4>Please select a dataset for generating impact coefficients</h4>
        <button
            [disabled]="!setOfImpactSerialChecked.size"
            (click)="onGenerateImpactCoef()"
            nz-button
            nzType="primary"
        >
            Generate impact coefficients
            {{
                setOfImpactSerialChecked.size > 0
                    ? '(' + setOfImpactSerialChecked.size + ')'
                    : ''
            }}
        </button>
        <button
            *hasPermission="['NEW_LCA_ENGINE']"
            [disabled]="!setOfImpactSerialChecked.size"
            (click)="onGenerateImpactCoef(true)"
            nz-button
            nzType="primary"
            nzDanger
        >
            Generate impact coefficients (NEW ENGINE)
            {{
                setOfImpactSerialChecked.size > 0
                    ? '(' + setOfImpactSerialChecked.size + ')'
                    : ''
            }}
        </button>
    </div>
    <div class="lca-impact-coef-content" [class.hasError]="generatingError">
        <nz-alert
            *ngIf="generatingError"
            nzType="error"
            [nzMessage]="generatingError"
            nzShowIcon
        ></nz-alert>
        <form
            nz-form
            [formGroup]="impactCoefSettingForm"
            class="lca-dataset-select__form"
        >
            <nz-form-item>
                <nz-form-label>Dataset</nz-form-label>
                <nz-form-control nzSpan="24">
                    <nz-select
                        nzShowSearch
                        [nzAllowClear]="true"
                        [nzLoading]="datasetLoading"
                        (ngModelChange)="onDatasetChange($event)"
                        formControlName="datasetId"
                        nzPlaceHolder="Select dataset"
                    >
                        <nz-option
                            *ngFor="let dataset of datasets"
                            [nzLabel]="dataset.datasetName"
                            [nzValue]="dataset.datasetId"
                        >
                            {{ dataset.datasetName }}
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label>Year</nz-form-label>
                <nz-form-control nzSpan="24">
                    <nz-select
                        nzShowSearch
                        nz-tooltip
                        nzTooltipPlacement="top"
                        nzTooltipOverlayClassName="danger-tooltip"
                        nzTooltipTitle="{{
                            !impactCoefSettingForm.get('datasetId')?.value
                                ? 'Please, select dataset'
                                : ''
                        }}"
                        [nzDisabled]="
                            !impactCoefSettingForm.get('datasetId')?.value
                        "
                        [nzAllowClear]="true"
                        [nzLoading]="yearsLoading"
                        (ngModelChange)="onYearChange($event)"
                        [(ngModel)]="selectedYear"
                        formControlName="year"
                        nzPlaceHolder="Select year"
                    >
                        <nz-option
                            *ngFor="let year of years"
                            [nzLabel]="year"
                            [nzValue]="year"
                        >
                            {{ year }}
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item
                nz-tooltip
                nzTooltipPlacement="bottom"
                nzTooltipTitle="Direct, Supply, and Total tiers are automatically generated, if those are the only desired tiers leave this box empty. This option will add the specified number of supply tiers along with 'Tier Other' to the results. e.g. '2' will create 3 additional tiers: 'Tier 1', 'Tier 2' and 'Tier Other'"
                nzTooltipOverlayClassName="primary-tooltip"
            >
                <nz-form-label>Additional Supply Chain Tiers</nz-form-label>
                <nz-form-control nzValidateStatus nzHasFeedback>
                    <nz-input-group nzSize="large">
                        <input
                            formControlName="supplyChainTier"
                            min="0"
                            max="10"
                            type="number"
                            nz-input
                            placeholder="Enter Whole Number 1-10"
                        />
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
        </form>
        <ng-container *ngIf="selectedDataset && selectedYear">
            <h4>
                <b>Impact categories:</b> <br />
                Please click checkbox(s) to select Impact categories for impact
                coefficients creation for the
                <b>{{ selectedDataset?.datasetName }}</b> dataset
            </h4>
            <div class="impact-coef-categories-log-filter">
                <app-text-search-input
                    style="margin-right: 1rem"
                    (search)="onSearch($event)"
                ></app-text-search-input>
                <!-- years filter -->
                <nz-select
                    nzShowSearch
                    [nzAllowClear]="true"
                    [nzMaxTagCount]="3"
                    [nzMaxTagPlaceholder]="maxTagPlaceholder"
                    nzMode="multiple"
                    (ngModelChange)="onYearsSelected($event)"
                    [nzLoading]="impactYearsLoading"
                    [(ngModel)]="selectedImpactYears"
                    [nzCustomTemplate]="selectedYearTemplate"
                    [nzPlaceHolder]="
                        selectedImpactYears.length ? '' : 'All years'
                    "
                >
                    <nz-option
                        *ngFor="let year of impactYears"
                        [nzLabel]="year"
                        nzCustomContent
                        [nzValue]="year"
                    >
                        {{ year }}
                    </nz-option>
                </nz-select>
                <ng-template #selectedYearTemplate let-selected>
                    <div class="year-template">
                        {{ selected.nzLabel }}
                    </div>
                </ng-template>
                <!-- activity types filter -->
                <nz-select
                    nzShowSearch
                    [nzAllowClear]="true"
                    *ngIf="impactTypesLoading || impactTypes?.length > 0"
                    [nzMaxTagCount]="1"
                    [nzMaxTagPlaceholder]="maxTagPlaceholder"
                    nzMode="multiple"
                    (ngModelChange)="onImpactTypeSelected($event)"
                    [nzLoading]="impactTypesLoading"
                    [(ngModel)]="selectedImpactTypeIds"
                    [nzCustomTemplate]="selectedImpactTypeTemplate"
                    [nzPlaceHolder]="
                        selectedImpactTypeIds.length ? '' : 'All impact types'
                    "
                >
                    <nz-option
                        *ngFor="let impactType of impactTypes"
                        [nzLabel]="impactType.impactTypeName"
                        nzCustomContent
                        [nzValue]="impactType.impactTypeId"
                    >
                        {{ impactType.impactTypeName }}
                    </nz-option>
                </nz-select>
                <nz-switch
                    [ngModel]="filter.showCoefficientsInDB"
                    (ngModelChange)="onShowCoefficientsInDB($event)"
                    nzCheckedChildren="Coefficients in database"
                    nzUnCheckedChildren="Coefficients in database"
                ></nz-switch>
                <ng-template #selectedImpactTypeTemplate let-selected>
                    <div class="impact-type-template">
                        {{ selected.nzLabel }}
                    </div>
                </ng-template>
                <ng-template #maxTagPlaceholder let-selectedList>
                    +{{ selectedList.length }}
                </ng-template>
                <a
                    (click)="resetFilters()"
                    class="reset-filter"
                    *ngIf="showResetFilters"
                    nz-button
                    nzSize="small"
                    nzType="link"
                    >Reset filters</a
                >
            </div>
            <nz-table
                #table
                [nzLoading]="logsLoading"
                [nzSize]="'middle'"
                nzShowPagination="false"
                nzFrontPagination="false"
                nzHideOnSinglePage
                [nzData]="logs"
            >
                <thead>
                    <tr>
                        <th
                            nzWidth="3rem"
                            [nzChecked]="impactSerialsAllChecked"
                            [nzIndeterminate]="impactSerialsIndeterminate"
                            (nzCheckedChange)="
                                onImpactSerialsAllChecked($event)
                            "
                        ></th>
                        <th class="th-number" nzWidth="10rem">Impact serial</th>
                        <th nzWidth="13rem">Impact name</th>
                        <th nzWidth="10rem" class="th-number">Impact year</th>
                        <th nzWidth="12rem">Impact unit</th>
                        <th nzWidth="12rem">Coefficients in database</th>
                        <th nzWidth="10rem" class="th-number">
                            Additional Supply Chain Tiers
                        </th>
                        <th>Date entered into database</th>
                    </tr>
                </thead>
            </nz-table>
            <cdk-virtual-scroll-viewport itemSize="50">
                <nz-table
                    #table
                    [nzLoading]="logsLoading"
                    [nzSize]="'middle'"
                    nzShowPagination="false"
                    nzFrontPagination="false"
                    nzHideOnSinglePage
                    [nzData]="logs"
                >
                    <thead>
                        <tr [style.display]="'none'">
                            <th nzWidth="3rem"></th>
                            <th nzWidth="10rem"></th>
                            <th nzWidth="13rem"></th>
                            <th nzWidth="10rem"></th>
                            <th nzWidth="12rem"></th>
                            <th nzWidth="12rem"></th>
                            <th nzWidth="10rem"></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *cdkVirtualFor="
                                let item of table.data;
                                let i = index
                            "
                        >
                            <td
                                [nzChecked]="
                                    setOfImpactSerialChecked.has(
                                        item.impactSerial
                                    )
                                "
                                (nzCheckedChange)="
                                    onImpactSerialChecked(
                                        item.impactSerial,
                                        $event
                                    )
                                "
                            ></td>
                            <td
                                class="td-number"
                                [innerHTML]="
                                    item.impactSerial
                                        | highlight: searchText
                                        | safe: 'html'
                                "
                            ></td>
                            <td
                                [innerHTML]="
                                    item.impactName
                                        | highlight: searchText
                                        | safe: 'html'
                                "
                            ></td>
                            <td
                                class="td-number"
                                [innerHTML]="
                                    item.year
                                        | highlight: searchText
                                        | safe: 'html'
                                "
                            ></td>
                            <td
                                [innerHTML]="
                                    item.unitAbbreviation
                                        | highlight: searchText
                                        | safe: 'html'
                                "
                            ></td>
                            <td [style.textAlign]="'center'">
                                <i
                                    style="color: #a1d487"
                                    *ngIf="item.logCreatedStamp"
                                    nz-icon
                                    nzType="check"
                                    nzTheme="outline"
                                ></i>
                                <i
                                    nz-icon
                                    nzType="close"
                                    style="
                                        color: #e82e2e;
                                        font-size: 1.5rem;
                                        margin-left: -0.375rem;
                                    "
                                    *ngIf="!item.logCreatedStamp"
                                    nzTheme="outline"
                                ></i>
                            </td>
                            <td class="td-number">
                                <span
                                    *ngIf="item.logCreatedStamp"
                                    [innerHTML]="
                                        item.supplyChainTiers
                                            | highlight: searchText
                                            | safe: 'html'
                                    "
                                ></span>
                            </td>
                            <td>
                                {{
                                    item.logUpdatedStamp
                                        | date: 'dd MMM yyyy, h:mm a'
                                }}
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </cdk-virtual-scroll-viewport>
        </ng-container>
    </div>
</div>
