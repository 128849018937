import { DecimalPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import _isNumber from 'lodash/isNumber';

@Component({
  selector: 'app-number-format',
  templateUrl: './number-format.component.html',
  styleUrl: './number-format.component.less',
  providers: [DecimalPipe]
})
export class NumberFormatComponent {
  @Input() value: number;
  @Input() threshold: number = 1e-4;
  @Input() zeroThreshold = 1e-10;
  @Input() sign: string = '';
  @Input() zeroThresholdValue = 0;

  constructor(private decimalPipe: DecimalPipe) {}

  get formattedNumber() {
    let number = +this.value;

    if (!_isNumber(number)) {
      return '';
    }

    if (number > -this.zeroThreshold && number < this.zeroThreshold) {
      return this.zeroThresholdValue ?? 0;
    }

    if (number > -this.threshold && number < this.threshold) {
      return Number(number.toPrecision(3)).toExponential(2);
    }

    if (number < 1 && number > -1) {
      return this.decimalPipe.transform(number, '1.2-4');
    }

    if (number < 100 && number > -100) {
      return this.decimalPipe.transform(number, '1.2-2');
    }

    if (number < 1000 && number > -1000) {
      return this.decimalPipe.transform(number, '1.1-1');
    }

    return this.decimalPipe.transform(Math.round(number), '1.0-0');
  }

  get isTooltipVisible(): boolean {
    if (this.value > -1e-10 || this.value < 1e-10) {
      return false;
    }

    return this.value < 1000 && `${this.value}`.length > 6;
  }
}
