import { AppState } from '../state';
import { createSelector } from '@ngrx/store';
import { AuthState } from '../reducers/auth/auth.reducer';

export const selectAuth = (state: AppState) => state.auth;

export const selectAuthLoading = createSelector(
    selectAuth,
    (state: AuthState) => state.loading
);

export const selectAuthUser = createSelector(
    selectAuth,
    (state: AuthState) => state.user
);

export const selectAuthRemember = createSelector(
    selectAuth,
    (state: AuthState) => state.remember
);

export const selectAuthToken = createSelector(
    selectAuth,
    (state: AuthState) => state.token
);

export const selectAuthError = createSelector(
    selectAuth,
    (state: AuthState) => state.error
);

export const selectAuthErrorSSO = createSelector(
    selectAuth,
    (state: AuthState) => state.sso
);

export const selectAuthStep = createSelector(
    selectAuth,
    (state: AuthState) => state.step
);

export const selectAuthLoginTypes = createSelector(
    selectAuth,
    (state: AuthState) => state.loginTypes
);

export const selectUserLoginId = createSelector(
    selectAuth,
    (state: AuthState) => state.userLoginId
);

export const isWaitingRedirectSSO = createSelector(
    selectAuth,
    (state: AuthState) => state.waitingRedirectSSO
);
