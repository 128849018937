<div class="powerbi-report-container">
    <div class="powerbi-report__controls">
        <h2
            class="reports-back"
            queryParamsHandling="merge"
            [routerLink]="link"
        >
            <i nz-icon nzType="arrow-left"></i>
            {{ reportName }}
        </h2>
    </div>
    <div
        [style.width]="'100%'"
        [style.height]="'calc(100vh - 80px)'"
        #reportContainer
    ></div>
</div>
