import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _set from 'lodash/set';
import _first from 'lodash/first';
import _orderBy from 'lodash/orderBy';
import _reverse from 'lodash/reverse';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { updateReportForClientFailed } from '../../../store/actions/report/report.actions';
import { Report } from '../../../store/models/report.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
    ReportService,
    ReportFilter,
} from '../../../core/services/report/report.service';
import { ReportGroup } from '../../../store/models/report-group.model';
import {
    ReportGroupFilter,
    ReportGroupService,
} from '../../../core/services/report-group/report-group.service';
import _last from 'lodash/last';
import { TextSearchInputComponent } from 'src/app/core/components/text-search-input/text-search-input.component';
import { GlobalService } from 'src/app/core/services/global/global.service';
import { AttributeService } from 'src/app/core/services/attribute/attribute.service';
import { AttributeType } from 'src/app/store/models/attribute.model';

const PAGE_SIZE = 100;

@Component({
    selector: 'app-manage-reports-page',
    templateUrl: './manage-reports-page.component.html',
    styleUrls: ['./manage-reports-page.component.less'],
})
export class ManageReportsPageComponent implements OnInit {
    selectedClientId: string;
    selectedReport: Report;
    reports: Report[] = [];

    reportsLoading: boolean = true;
    reportGroups: ReportGroup[] = [];
    reportGroupsLoading: boolean = false;

    nonFilteredReports: Report[];

    // edit customer user data
    reportFormMode: boolean = false;
    reportLoading: boolean = false;

    attributeTypesLoading: boolean = false;
    attributeTypes: AttributeType[] = [];

    filterForm: Subject<ReportFilter> = new Subject();

    @ViewChild(TextSearchInputComponent) child: TextSearchInputComponent;

    public ngDestroyed$ = new Subject();

    columns = [
        {
            title: 'Report name',
            width: '15%',
            sortDirections: ['descend', 'ascend', null],
            sorter: 'active',
        },
    ];
    reportsLoaded: boolean = false;
    reportGroupsLoaded: boolean;
    selectedGroups: string[];
    query: string;

    searchFilter = {
        searchText: '',
        lastReportId: '',
        selectedGroups: [],
        columnName: 'report_name',
        sortDirection: 'ascend',
        selectedReportId: '',
    };
    constructor(
        private store: Store<AppState>,
        private reportService: ReportService,
        private reportGroupService: ReportGroupService,
        private message: NzMessageService,
        private modal: NzModalService,
        private globalService: GlobalService,
        private attributeService: AttributeService
    ) {
        this.filterForm
            .pipe(takeUntil(this.ngDestroyed$), debounceTime(400))
            .subscribe((filter) => {
                this.fetchReports(filter);
            });
    }

    ngOnInit() {
        this.globalService.clientId$.pipe(takeUntil(this.ngDestroyed$)).subscribe(
            clientId => {
                if (clientId) {
                   this.onClientSelected(clientId)
                }
            }
        )
    }
    
    onEditReport(report: Report) {
        this.selectedReport = report;
        this.searchFilter.selectedReportId = this.selectedReport.reportId;
        this.reportFormMode = true;
        this.filterForm.next({
            searchText: this.searchFilter.searchText,
            columnName: this.searchFilter.columnName,
            sortDirection: this.searchFilter.sortDirection,
            lastReportId: this.searchFilter.lastReportId,
            selectedGroups: this.searchFilter.selectedGroups,
        });
    }

    onCreateCustomerReport() {
        this.selectedReport = null;
        this.searchFilter.selectedReportId = null;
        this.reportFormMode = true;
    }

    onClientSelected(clientId: string) {
        this.reportsLoading = true;
        this.reportsLoaded = false;
        this.selectedClientId = clientId;
        this.fetchReports();
        this.fetchReportGroups();
        this.fetchAttributeTypes();
    }

    onReportGroupDeleted() {
        this.reportsLoaded = false;
        this.fetchReports();
    }

    closeEditReport(withReload: boolean = false) {
        this.reportFormMode = false;

        this.store.dispatch(
            updateReportForClientFailed({
                error: null,
                clientId: this.selectedClientId,
            })
        );

        if (withReload) {
            this.fetchReports();
            this.fetchReportGroups();
            this.child.onClear();
            this.searchFilter.selectedGroups = null;
        } else {
            this.filterForm.next({
                searchText: this.searchFilter.searchText,
                columnName: this.searchFilter.columnName,
                sortDirection: this.searchFilter.sortDirection,
                lastReportId: this.searchFilter.lastReportId,
                selectedGroups: this.searchFilter.selectedGroups,
            });
            this.searchFilter.selectedReportId = null;
        }
    }

    onRemoveReport(reportId: string, reportName: string) {
        this.modal.confirm({
            nzTitle: `Do you want to remove <b>${reportName}</b>?`,
            nzContent: `
        If you delete it you will not be able to recover report.<br>
        Do you want to delete the report?
      `,
            nzOnOk: () =>
                this.reportService.deleteReport(reportId).subscribe({
                    next: () => {
                        this.message.success(
                            `Report <b>${reportName}</b> has been deleted`
                        );
                        this.reports = this.reports.filter(
                            (report) => report.reportId !== reportId
                        );
                        this.fetchReports();
                    },
                    error: () => {
                        this.message.warning(
                            `Failed to delete report <b>${reportName}<b>!`,
                            { nzDuration: 4000 }
                        );
                    },
                }),
            nzOkText: 'Yes, remove',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzCancelText: 'Close',
            nzClosable: false,
            nzOnCancel: () => console.log('Cancel'),
        });
    }

    onSearch(value: string) {
        this.reportsLoaded = false;
        this.reportsLoading = true;
        this.searchFilter.searchText = value;
        this.filterForm.next({
            searchText: this.searchFilter.searchText,
            selectedGroups: this.searchFilter.selectedGroups,
            lastReportId: this.searchFilter.lastReportId,
            selectedReportId: this.searchFilter.selectedReportId,
        });
    }

    private fetchAttributeTypes() {
        this.attributeTypesLoading = true;
        this.attributeService
            .getAttributeTypesForReports(this.selectedClientId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    this.attributeTypesLoading = false;
                    this.attributeTypes = _get(payload, 'payload') || [];
                },
                complete: () => {
                    this.attributeTypesLoading = false;
                },
            });
    }

    private fetchReportGroups(filter?: ReportGroupFilter) {
        this.reportGroupsLoading = true;
        this.reportGroupService
            .getReportGroups(this.selectedClientId, filter)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    const reportGroups = _get(payload, 'payload', []);
                    this.reportGroupsLoading = false;
                    if (!reportGroups.length) {
                        this.reportGroupsLoaded = true;
                    }
                    if (filter && filter.lastReportGroupId) {
                        if (reportGroups.length) {
                            this.reportGroups = [
                                ...this.reportGroups,
                                ...reportGroups,
                            ];
                        }
                    } else {
                        this.reportGroups = reportGroups;
                    }

                    this.reportGroups = _orderBy(this.reportGroups, item => item.groupName.toLowerCase(), ['asc']);
                },
                complete: () => {
                    this.reportGroupsLoading = false;
                },
            });
    }

    private fetchReports(filter?: ReportFilter) {
        if (this.reportsLoaded) return;

        this.reportsLoading = true;
        this.reportService
            .getReportsByClient(this.selectedClientId, {...filter, limit: PAGE_SIZE })
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    const reps = _get(payload, 'payload');
                    if (reps.length < PAGE_SIZE) {
                        this.reportsLoaded = true;
                    }
                    if (filter && filter.lastReportId) {
                        if (reps.length) {
                            this.reports = [...this.reports, ...reps];
                        }
                    } else {
                        this.reports = reps;
                    }
                },
                complete: () => (
                    (this.reportsLoading = false),
                    (this.query = this.searchFilter.searchText)
                ),
            });
    }

    onFilterGroupsSelected(groupIds: string[]) {
        this.reportsLoaded = false;
        this.reportsLoading = true;
        this.searchFilter.selectedGroups = groupIds;
        this.selectedGroups = groupIds;
        this.filterForm.next({
            searchText: this.searchFilter.searchText,
            selectedGroups: groupIds,
            lastReportId: this.searchFilter.lastReportId,
            selectedReportId: this.searchFilter.selectedReportId,
        });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    onTableScrolled() {
        if (this.reportsLoaded === false) {
            const rep: Report = _last(this.reports);
            this.searchFilter.selectedGroups = this.selectedGroups;
            if (rep && rep.reportId) {
                const { reportId, reportName } = rep;
                this.filterForm.next({
                    searchText: this.searchFilter.searchText,
                    sortDirection: this.searchFilter.sortDirection,
                    selectedGroups: this.searchFilter.selectedGroups,
                    lastReportId: reportId,
                    lastReportName: reportName,
                });
            }
        }
    }
    orderChange(col: any) {
        this.reportsLoaded = false;
        this.reportsLoading = true;
        this.searchFilter.columnName = col.key;
        this.searchFilter.sortDirection = col.value;
        this.searchFilter.selectedGroups = this.selectedGroups;
        this.filterForm.next({
            searchText: this.searchFilter.searchText,
            sortDirection: this.searchFilter.sortDirection,
            lastReportId: this.searchFilter.lastReportId,
            selectedGroups: this.searchFilter.selectedGroups,
        });
    }
}
