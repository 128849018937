import {
    Component,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    ViewChild,
    AfterViewInit,
    OnChanges,
    SimpleChanges,
    OnDestroy,
} from '@angular/core';
import { debounceTime, Subject } from 'rxjs';

@Component({
    selector: 'app-infinite-scroll',
    templateUrl: './infinite-scroll.component.html',
    styleUrls: ['./infinite-scroll.component.less'],
})
export class InfiniteScrollComponent
    implements AfterViewInit, OnDestroy
{
    @Output() scrolled = new EventEmitter();
    @ViewChild('anchor', { static: true }) anchor: ElementRef<HTMLElement>;

    //TODO: depricate it
    @Input() loaded: boolean = false;
    @Input() loading: boolean = false;
    //sometimes we should show it without margins (Assurance table)
    @Input() staticAnchor: boolean = false;

    calculateAnchorVisibleDebounced$ = new Subject();

    private observer: IntersectionObserver;

    constructor() {}

    ngAfterViewInit() {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        this.observer = new IntersectionObserver(([entry]) => {
            entry.isIntersecting && !this.loaded && !this.loading && this.scrolled.emit();
        }, options);

        this.observer.observe(this.anchor.nativeElement);
    }

    ngOnDestroy() {
        this.observer.unobserve(this.anchor.nativeElement);
    }
}
