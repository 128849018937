import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PortalStatus } from '../../../store/models/upload-portal-status.model';
import { UploadPortalService, UploadPortalFilterForm } from '../../../core/services/upload-portal/upload-portal.service';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import { UploadPortalType } from '../../../store/models/upload-portal-type.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TextSearchInputComponent } from 'src/app/core/components/text-search-input/text-search-input.component';
import { GlobalService } from 'src/app/core/services/global/global.service';
import { PortalsTableComponent } from './portals-table/portals-table.component';
import { UploadPortal } from 'src/app/store/models/upload-portal';
import { PortalStatusesTableComponent } from './portal-statuses-table/portal-statuses-table.component';
import { PortalTypesTableComponent } from './portal-types-table/portal-types-table.component';

@Component({
    selector: 'app-portal-types-page',
    templateUrl: './portal-types-page.component.html',
    styleUrls: ['./portal-types-page.component.less'],
})
export class PortalTypesPageComponent implements OnInit, OnDestroy {
    selectedTabIndex: number = 0;
    portalTypeFormMode: boolean = false;
    selectedType: UploadPortalType;

    uploadPortals: UploadPortal[] = [];
    uploadPortalsLoading: boolean = false;
    notFilteredUploadPortals: UploadPortal[] = [];
    
    selectedClientId?: string;
    clientId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

    searchText: string = '';
    ngDestroyed$ = new Subject();

    @ViewChild(TextSearchInputComponent) searchInput: TextSearchInputComponent;
    @ViewChild(PortalsTableComponent) portalsTable: PortalsTableComponent;
    @ViewChild(PortalStatusesTableComponent) portalStatusesTable: PortalStatusesTableComponent;
    @ViewChild(PortalTypesTableComponent) portalTypesTable: PortalTypesTableComponent;

    constructor(private uploadPortalService: UploadPortalService, private globalService: GlobalService) {}

    ngOnInit() {
        this.globalService.clientId$
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((clientId) => {
                if (
                    clientId &&
                    ((!this.selectedClientId && clientId) ||
                    clientId !== this.selectedClientId)
                ) {
                    this.clientId$.next(clientId);
                }
                this.selectedClientId = clientId;
            });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    onSelectedTabChanged(index: number) {
        this.selectedTabIndex = index;
        this.searchInput.onClear();
    }

    onCreateNewType() {
        this.portalTypesTable.onCreateUploadPortalType();
    }

    onCreateNewPortal() {
        this.portalsTable.onCreateUploadPortal();
    }

    onCreateNewStatus() {
        this.portalStatusesTable.onCreateNewPortalStatus();
    }

    onSearch(value: string) {
        this.searchText = value;
    }
}
