import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, concat } from 'rxjs';
import _get from 'lodash/get';

import * as UploadPortalActionTypes from '../../actions/upload-portal/constants';
import { catchError, mergeMap, flatMap, switchMap } from 'rxjs/operators';
import { UploadPortalHistoryFilter, UploadPortalService } from '../../../core/services/upload-portal/upload-portal.service';
import { NzMessageService } from 'ng-zorro-antd/message';
@Injectable()
export class UploadPortalEffects {
    constructor(
        private actions$: Actions,
        private uploadPortalService: UploadPortalService,
        private message: NzMessageService
    ) {}

    uploadPortalUpdateRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadPortalActionTypes.UPLOAD_PORTAL_UPDATE_REQUEST),
            mergeMap(({ createUploadPortalReqJson, uploadPortalFile }) =>
                this.uploadPortalService
                    .putUploadPortalFormData(
                        createUploadPortalReqJson,
                        uploadPortalFile
                    )
                    .pipe(
                        mergeMap(({ payload }) => {
                            return of({
                                type: UploadPortalActionTypes.UPLOAD_PORTAL_UPDATE_RECEIVE,
                                uploadPortal: payload,
                            });
                        }),
                        catchError((error) =>
                            of({
                                type: UploadPortalActionTypes.UPLOAD_PORTAL_UPDATE_FAILED,
                                error,
                            })
                        )
                    )
            )
        )
    );

    uploadPortalUpdateReceive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadPortalActionTypes.UPLOAD_PORTAL_UPDATE_RECEIVE),
            flatMap(({ uploadPortal }) =>
                concat(
                    of({
                        type: UploadPortalActionTypes.UPLOAD_PORTAL_VERSIONS_REQUEST,
                        uploadPortalId: _get(uploadPortal, 'uploadPortalId'),
                    }),
                    of({
                        type: UploadPortalActionTypes.UPLOAD_PORTAL_LIST_REQUEST,
                        clientId: _get(uploadPortal, 'uploadPortalType.clientId'),
                    })
                )
            )
        )
    );

    uploadPortalVersionsRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadPortalActionTypes.UPLOAD_PORTAL_VERSIONS_REQUEST),
            mergeMap(({ uploadPortalId }) =>
                this.uploadPortalService.getUploadPortalVersions(uploadPortalId).pipe(
                    mergeMap(({ payload }) => {
                        return of({
                            type: UploadPortalActionTypes.UPLOAD_PORTAL_VERSIONS_RECEIVE,
                            items: payload,
                            uploadPortalId,
                        });
                    }),
                    catchError((error) =>
                        of({
                            type: UploadPortalActionTypes.UPLOAD_PORTAL_VERSIONS_FAILED,
                            error,
                            uploadPortalId,
                        })
                    )
                )
            )
        )
    );

    uploadPortalTypesRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadPortalActionTypes.UPLOAD_PORTAL_TYPES_REQUEST),
            mergeMap(({ clientId }) =>
                this.uploadPortalService.getUploadPortalTypes(clientId).pipe(
                    mergeMap(({ payload }) => {
                        return of({
                            type: UploadPortalActionTypes.UPLOAD_PORTAL_TYPES_RECEIVE,
                            items: payload,
                        });
                    }),
                    catchError((error) =>
                        of({
                            type: UploadPortalActionTypes.UPLOAD_PORTAL_TYPES_FAILED,
                            error,
                        })
                    )
                )
            )
        )
    );

    downloadUploadPortalFileRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadPortalActionTypes.UPLOAD_PORTAL_DOWNLOAD_FILE_REQUEST),
            mergeMap(
                ({
                    uploadPortalId,
                    uploadPortalFileName,
                    filter
                }: {
                    uploadPortalId: string;
                    uploadPortalFileName: string;
                    filter?: UploadPortalHistoryFilter;
                }) =>
                    this.uploadPortalService
                        .downloadUploadPortalFile(uploadPortalId)
                        .pipe(
                            switchMap((stream: any) => {
                                // It is necessary to create a new blob object with mime-type explicitly set
                                // otherwise only Chrome works like it s    hould
                                let [_name, type] =
                                    uploadPortalFileName.split(',');

                                switch (type) {
                                    case 'xml':
                                        type = 'xml';
                                        break;
                                    case 'xlsx':
                                        type =
                                            'vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                                    default:
                                        type = 'xml';
                                }

                                var newBlob = new Blob([stream], {
                                    type: `application/${type}`,
                                });
                                // IE doesn't allow using a blob object directly as link href
                                // instead it is necessary to use msSaveOrOpenBlob
                                //@ts-ignore
                                if (
                                    window.navigator &&
                                    //@ts-ignore
                                    window.navigator.msSaveOrOpenBlob
                                ) {
                                    //@ts-ignore
                                    window.navigator.msSaveOrOpenBlob(newBlob);
                                    return of({
                                        type: UploadPortalActionTypes.UPLOAD_PORTAL_DOWNLOAD_FILE_RECEIVE,
                                        uploadPortalId,
                                        filter
                                    });
                                }

                                // For other browsers:
                                // Create a link pointing to the ObjectURL containing the blob.
                                const data =
                                    window.URL.createObjectURL(newBlob);

                                var link = document.createElement('a');
                                link.href = data;
                                link.download = uploadPortalFileName;
                                // this is necessary as link.click() does not work on the latest firefox
                                link.dispatchEvent(
                                    new MouseEvent('click', {
                                        bubbles: true,
                                        cancelable: true,
                                        view: window,
                                    })
                                );

                                setTimeout(function () {
                                    // For Firefox it is necessary to delay revoking the ObjectURL
                                    window.URL.revokeObjectURL(data);
                                    link.remove();
                                }, 100);
                                return of({
                                    type: UploadPortalActionTypes.UPLOAD_PORTAL_DOWNLOAD_FILE_RECEIVE,
                                    uploadPortalId,
                                    filter
                                });
                            }),
                            catchError((error) => {
                                console.log(error);
                                return of({
                                    type: UploadPortalActionTypes.UPLOAD_PORTAL_DOWNLOAD_FILE_FAILED,
                                    error,
                                });
                            })
                        )
            )
        )
    );

    downloadUploadPortalReceive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadPortalActionTypes.UPLOAD_PORTAL_DOWNLOAD_FILE_RECEIVE),
            mergeMap(({ uploadPortalId, filter }: { uploadPortalId: string , filter: UploadPortalHistoryFilter}) =>
                of({
                    type: UploadPortalActionTypes.USER_DATA_UPLOAD_HISTORY_REQUEST,
                    uploadPortalId,
                    reset: true,
                    filter: filter ? {
                        ...filter,
                        lastHistoryCreatedStamp: undefined,
                        lastRecordId: undefined
                    } : {}
                })
            )
        )
    );

    userUploadPortalsRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadPortalActionTypes.USER_UPLOAD_PORTALS_REQUEST),
            mergeMap(({ uploadPortalTypeId }) =>
                this.uploadPortalService
                    .getUploadPortalUploadPortalsByType(uploadPortalTypeId)
                    .pipe(
                        mergeMap(({ payload }) => {
                            return of({
                                type: UploadPortalActionTypes.USER_UPLOAD_PORTALS_RECEIVE,
                                items: payload,
                            });
                        }),
                        catchError((error) =>
                            of({
                                type: UploadPortalActionTypes.USER_UPLOAD_PORTALS_FAILED,
                                error,
                            })
                        )
                    )
            )
        )
    );

    userUploadPortalTypesRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadPortalActionTypes.USER_UPLOAD_PORTAL_TYPES_REQUEST),
            mergeMap(() =>
                this.uploadPortalService.getUploadPortalTypesByUser().pipe(
                    mergeMap(({ payload }) => {
                        return of({
                            type: UploadPortalActionTypes.USER_UPLOAD_PORTAL_TYPES_RECEIVE,
                            items: payload,
                        });
                    }),
                    catchError((error) =>
                        of({
                            type: UploadPortalActionTypes.USER_UPLOAD_PORTAL_TYPES_FAILED,
                            error,
                        })
                    )
                )
            )
        )
    );

    userDataUploadHistoryRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadPortalActionTypes.USER_DATA_UPLOAD_HISTORY_REQUEST),

            mergeMap(({ uploadPortalId, reset, filter }) => {
                return this.uploadPortalService
                    .getUserUploadPortalHistory(uploadPortalId, null, filter)
                    .pipe(
                        mergeMap(({ payload }) => {
                            return of({
                                type: UploadPortalActionTypes.USER_DATA_UPLOAD_HISTORY_RECEIVE,
                                items: payload,
                                reset,
                            });
                        }),
                        catchError((error) =>
                            of({
                                type: UploadPortalActionTypes.USER_UPLOAD_PORTALS_FAILED,
                                error,
                            })
                        )
                    );
            })
        )
    );

    createUploadPortalTypeRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadPortalActionTypes.CREATE_UPLOAD_PORTAL_TYPE_REQUEST),
            mergeMap(({ dateTemplateTypeInput }) =>
                this.uploadPortalService
                    .postUploadPortalType(dateTemplateTypeInput)
                    .pipe(
                        mergeMap(() =>
                            of({
                                type: UploadPortalActionTypes.CREATE_UPLOAD_PORTAL_TYPE_RECEIVE,
                            })
                        ),
                        catchError((error) =>
                            of({
                                type: UploadPortalActionTypes.CREATE_UPLOAD_PORTAL_TYPE_FAILED,
                                error,
                            })
                        )
                    )
            )
        )
    );

    updateUploadPortalTypeRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadPortalActionTypes.UPDATE_UPLOAD_PORTAL_TYPE_REQUEST),
            mergeMap(({ dateTemplateTypeInput }) =>
                this.uploadPortalService
                    .putUploadPortalType(dateTemplateTypeInput)
                    .pipe(
                        mergeMap(() =>
                            of({
                                type: UploadPortalActionTypes.UPDATE_UPLOAD_PORTAL_TYPE_RECEIVE,
                            })
                        ),
                        catchError((error) =>
                            of({
                                type: UploadPortalActionTypes.UPDATE_UPLOAD_PORTAL_TYPE_FAILED,
                                error,
                            })
                        )
                    )
            )
        )
    );

    createUploadPortalTypeReceive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadPortalActionTypes.CREATE_UPLOAD_PORTAL_TYPE_RECEIVE),
            mergeMap(() =>
                of({
                    type: UploadPortalActionTypes.UPLOAD_PORTAL_TYPES_REQUEST,
                })
            )
        )
    );

    updateUploadPortalTypeReceive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadPortalActionTypes.UPDATE_UPLOAD_PORTAL_TYPE_RECEIVE),
            mergeMap(() =>
                of({
                    type: UploadPortalActionTypes.UPLOAD_PORTAL_TYPES_REQUEST,
                })
            )
        )
    );

    dataCollectionStatusesRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadPortalActionTypes.DATA_COLLECTION_STATUSES_REQUEST),
            mergeMap(({ statusTypeId }) =>
                this.uploadPortalService
                    .getUploadPortalStatuses(statusTypeId)
                    .pipe(
                        mergeMap(({ payload }) =>
                            of({
                                type: UploadPortalActionTypes.DATA_COLLECTION_STATUSES_RECEIVE,
                                items: payload,
                                statusTypeId,
                            })
                        ),
                        catchError((error) =>
                            of({
                                type: UploadPortalActionTypes.UPDATE_UPLOAD_PORTAL_TYPE_FAILED,
                                statusTypeId,
                                error,
                            })
                        )
                    )
            )
        )
    );

    uploadPortalStatusHistoryRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                UploadPortalActionTypes.UPLOAD_PORTAL_STATUS_HISTORY_REQUEST
            ),
            mergeMap(({ limit, uploadPortalId, reset, filter }) => {
                return this.uploadPortalService
                    .getUploadPortalStatusHistory(uploadPortalId, limit, filter)
                    .pipe(
                        mergeMap(({ payload }) =>
                            of({
                                type: UploadPortalActionTypes.UPLOAD_PORTAL_STATUS_HISTORY_RECEIVE,
                                uploadPortalId,
                                items: payload,
                                reset,
                            })
                        ),
                        catchError((error) =>
                            of({
                                type: UploadPortalActionTypes.UPLOAD_PORTAL_STATUS_HISTORY_FAILED,
                                uploadPortalId,
                                error,
                            })
                        )
                    );
            })
        )
    );

    updateUploadPortalUploadStatusRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                UploadPortalActionTypes.UPLOAD_PORTAL_UPLOAD_STATUS_UPDATE_REQUEST
            ),
            mergeMap(({ uploadPortalId, hint, collectionStatusId, clientId }) =>
                this.uploadPortalService
                    .postUploadPortalUploadStatus(
                        uploadPortalId,
                        collectionStatusId,
                        hint
                    )
                    .pipe(
                        mergeMap(() =>
                            of({
                                type: UploadPortalActionTypes.UPLOAD_PORTAL_UPLOAD_STATUS_UPDATE_RECEIVE,
                                uploadPortalId,
                                clientId,
                            })
                        ),
                        catchError((error) =>
                            of({
                                type: UploadPortalActionTypes.UPLOAD_PORTAL_UPLOAD_STATUS_UPDATE_FAILED,
                                error,
                            })
                        )
                    )
            )
        )
    );

    updateUploadPortalUploadStatusReceive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                UploadPortalActionTypes.UPLOAD_PORTAL_UPLOAD_STATUS_UPDATE_RECEIVE
            ),
            mergeMap(({ uploadPortalId, clientId }) => {
                return concat(
                    of({
                        type: UploadPortalActionTypes.UPLOAD_PORTAL_STATUS_HISTORY_REQUEST,
                        uploadPortalId,
                    }),
                    of({
                        type: UploadPortalActionTypes.UPLOAD_PORTAL_LIST_REQUEST,
                        clientId,
                    })
                );
            })
        )
    );
}
