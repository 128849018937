import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-notfound-page',
    templateUrl: './notfound-page.component.html',
    styleUrls: ['./notfound-page.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotfoundPageComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
