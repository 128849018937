import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { MarkdownModule } from 'ngx-markdown';
import { AppComponent } from './app.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { IconModule } from '@ant-design/icons-angular';
import { AntModule } from './modules/ant.module';
import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { NotfoundPageComponent } from './components/pages/notfound-page/notfound-page.component';
import { LoginFormComponent } from './components/forms/login-form/login-form.component';
import { DataPageComponent } from './components/pages/data-page/data-page.component';
import { ReportsPageComponent } from './components/pages/reports-page/reports-page.component';
import { PowerbiReportPageComponent } from './components/pages/powerbi-report-page/powerbi-report-page.component';
import { CustomerPageComponent } from './components/pages/customer-page/customer-page.component';
import { McdonaldsComponent } from './components/pages/oauth/mcdonalds/mcdonalds.component';
import { UserPageComponent } from './components/pages/user-page/user-page.component';
import { ClientFormComponent } from './components/forms/client-form/client-form.component';
import { RolesPageComponent } from './components/pages/roles-page/roles-page.component';
import { RoleFormComponent } from './components/forms/role-form/role-form.component';
import { ManageReportsPageComponent } from './components/pages/manage-reports-page/manage-reports-page.component';
import { ReportFormComponent } from './components/forms/report-form/report-form.component';
import { ReportGroupsPageComponent } from './components/pages/report-groups-page/report-groups-page.component';
import { ReportGroupFormComponent } from './components/forms/report-group-form/report-group-form.component';
import { ReportGroupItemComponent } from './components/report-group-item/report-group-item.component';
import { PortalTypesPageComponent } from './components/pages/portal-types-page/portal-types-page.component';
import { PortalStatusesTableComponent } from './components/pages/portal-types-page/portal-statuses-table/portal-statuses-table.component';
import { PortalTypesTableComponent } from './components/pages/portal-types-page/portal-types-table/portal-types-table.component';
import { PortalTypeFormComponent } from './components/forms/portal-type-form/portal-type-form.component';
import { PortalStatusFormComponent } from './components/forms/portal-status-form/portal-status-form.component';
import { AttributeTypesPageComponent } from './components/pages/attribute-types-page/attribute-types-page.component';
import { AttributeTypeFormComponent } from './components/forms/attribute-type-form/attribute-type-form.component';
import { IndexPageComponent } from './components/pages/index-page/index-page.component';
import { UpdatePasswordFormComponent } from './components/forms/update-password-form/update-password-form.component';
import { DatabasePageComponent } from './components/pages/database-page/database-page.component';
import { ClientDatabaseFormComponent } from './components/forms/client-database-form/client-database-form.component';
import { UpdateDataToTableFormComponent } from './components/forms/update-data-to-table-form/update-data-to-table-form.component';
import { ClientDataTableFormComponent } from './components/forms/client-data-table-form/client-data-table-form.component';
import { ClientDatabaseTableAppendFormComponent } from './components/forms/client-database-table-append-form/client-database-table-append-form.component';
import { ClientDatabaseTableUpdateFormComponent } from './components/forms/client-database-table-update-form/client-database-table-update-form.component';
import { ClientDatabaseTableModifyFormComponent } from './components/forms/client-database-table-modify-form/client-database-table-modify-form.component';
import { HelpComponent } from './components/pages/help-page/help.component';
import { UploadFormComponent } from './components/forms/upload-form/upload-form.component';
import { DescriptionFormComponent } from './components/forms/description-form/description-form.component';
import { SheetFormComponent } from './components/forms/sheet-form/sheet-form.component';
import { LcaEngineSandboxPageComponent } from './components/pages/lca-engine-sandbox-page/lca-engine-sandbox-page.component';
import { LcaModelDataDatabaseFormComponent } from './components/forms/lca-model-data-database-form/lca-model-data-database-form.component';
import { LcaModelDataCsvFormComponent } from './components/forms/lca-model-data-csv-form/lca-model-data-csv-form.component';
import { LcaModifySpaFormComponent } from './components/forms/lca-modify-spa-form/lca-modify-spa-form.component';
import { FeedbackComponent } from './components/pages/feedback-page/feedback.component';
import { LcaImpactCategoriesFilterComponent } from './components/filters/lca-impact-categories-filter/lca-impact-categories-filter.component';
import { PasswordPageComponent } from './components/pages/password-page/password-page.component';
import { PasswordFormComponent } from './components/forms/password-form/password-form.component';
import { ClientDatabaseTableRemoveFormComponent } from './components/forms/client-database-table-remove-form/client-database-table-remove-form.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { LcaEngineImpactCoefPageComponent } from './components/pages/lca-engine-impact-coef-page/lca-engine-impact-coef-page.component';
import { RouterExtService } from './core/services/router-ext/router-ext.service';
import { TicketingFormComponent } from './components/ticketing-form/ticketing-form.component';
import { TableInformationComponent } from './components/table-information/table-information.component';
import { PreviewComponent } from './components/preview/preview.component';
import { UploadHistoryTileComponent } from './components/upload-history-tile/upload-history-tile.component';
import { LcaEngineClientDatabaseSpaPageComponent } from './components/pages/lca-engine-client-database-spa-page/lca-engine-client-database-spa-page.component';
import { LcaEngineClientDatabaseAddSpaPageComponent } from './components/pages/lca-engine-client-database-add-spa-page/lca-engine-client-database-add-spa-page.component';
import { LcaActivityserialSelectionFormComponent } from './components/forms/lca-activityserial-selection-form/lca-activityserial-selection-form.component';
import { LcaImpactCategoriesSelectionFormComponent } from './components/forms/lca-impact-categories-selection-form/lca-impact-categories-selection-form.component';
import { ClientDatabaseTableAddComponent } from './components/forms/client-database-table-add/client-database-table-add.component';
import { ClientSsoConfigFormComponent } from './components/forms/client-sso-config-form/client-sso-config-form.component';

import { CoreModule } from './core/core.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
    reducerProvider,
    metaReducers,
    REDUCERS_TOKEN,
} from './store/reducers';
import { environment } from 'src/environments/environment';
import { AuthEffects } from './store/effects/auth/auth.effects';
import { ReportEffects } from './store/effects/report/report.effects';
import { CustomerEffects } from './store/effects/customer/customer.effects';
import { AttributeEffects } from './store/effects/attribute/attribute.effects';
import { UserEffects } from './store/effects/user/user.effects';
import { UploadPortalEffects } from './store/effects/upload-portal/upload-portal.effects';
import { UsersPopoverComponent } from './components/users-popover/users-popover.component';
import { PortalsTableComponent } from './components/pages/portal-types-page/portals-table/portals-table.component';
import { ReportingTablePageComponent } from './components/pages/reporting-table-page/reporting-table-page.component';

import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { BugsnagClient } from './bugsnag';
import { AuthService } from './core/services/auth/auth.service';

// create a factory which will return the BugSnag error handler
export function errorHandlerFactory() {
    return new BugsnagErrorHandler(BugsnagClient);
}

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ReportingTableRunsComponent } from './components/pages/reporting-table-runs/reporting-table-runs.component';
import { LcaSettingsFormComponent } from './components/forms/lca-settings-form/lca-settings-form.component';
import { AccessDeniedPageComponent } from './components/pages/access-denied-page/access-denied-page.component';
import { ProcessHistoryPageComponent } from './components/pages/process-history-page/process-history-page.component';
import { AssuranceReportPageComponent } from './components/pages/assurance-report-page/assurance-report-page.component';
import { AssuranceTableFilterComponent } from './components/filters/assurance-table-filter/assurance-table-filter.component';
import { AppLayoutComponent } from './components/layouts/app-layout/app-layout.component';
import { NonAuthLayoutComponent } from './components/layouts/non-auth-layout/non-auth-layout.component';

registerLocaleData(localeFr);

@NgModule({
    declarations: [
        //components
        AppComponent,
        LoginPageComponent,
        NotfoundPageComponent,
        LoginFormComponent,
        DataPageComponent,
        ReportsPageComponent,
        PowerbiReportPageComponent,
        CustomerPageComponent,
        McdonaldsComponent,
        UserPageComponent,
        ClientFormComponent,
        RolesPageComponent,
        RoleFormComponent,
        PortalsTableComponent,
        ManageReportsPageComponent,
        ReportFormComponent,
        ReportGroupsPageComponent,
        ReportGroupFormComponent,
        ReportGroupItemComponent,
        PortalTypesPageComponent,
        PortalStatusesTableComponent,
        PortalTypesTableComponent,
        PortalTypeFormComponent,
        PortalStatusFormComponent,
        AttributeTypesPageComponent,
        AttributeTypeFormComponent,
        IndexPageComponent,
        UpdatePasswordFormComponent,
        DatabasePageComponent,
        ClientDatabaseFormComponent,
        UpdateDataToTableFormComponent,
        ClientDataTableFormComponent,
        ReportingTablePageComponent,
        ClientDatabaseTableAppendFormComponent,
        ClientDatabaseTableUpdateFormComponent,
        ClientDatabaseTableModifyFormComponent,
        HelpComponent,
        UploadFormComponent,
        DescriptionFormComponent,
        SheetFormComponent,
        LcaEngineSandboxPageComponent,
        LcaModelDataDatabaseFormComponent,
        LcaModelDataCsvFormComponent,
        LcaModifySpaFormComponent,
        FeedbackComponent,
        LcaImpactCategoriesFilterComponent,
        PasswordPageComponent,
        PasswordFormComponent,
        ClientDatabaseTableRemoveFormComponent,
        LcaEngineImpactCoefPageComponent,
        TicketingFormComponent,
        TableInformationComponent,
        PreviewComponent,
        UploadHistoryTileComponent,
        LcaEngineClientDatabaseSpaPageComponent,
        LcaEngineClientDatabaseAddSpaPageComponent,
        LcaActivityserialSelectionFormComponent,
        LcaImpactCategoriesSelectionFormComponent,
        UsersPopoverComponent,
        ClientDatabaseTableAddComponent,
        ClientSsoConfigFormComponent,
        ReportingTableRunsComponent,
        LcaSettingsFormComponent,
        AccessDeniedPageComponent,
        ProcessHistoryPageComponent,
        AssuranceReportPageComponent,
        AssuranceTableFilterComponent,
        AppLayoutComponent,
        NonAuthLayoutComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        DragDropModule,
        ScrollingModule,
        EditorModule,
        AntModule,
        CoreModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        StoreModule.forRoot(REDUCERS_TOKEN, { metaReducers }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        EffectsModule.forRoot([
            AuthEffects,
            ReportEffects,
            CustomerEffects,
            UserEffects,
            AttributeEffects,
            UploadPortalEffects,
        ]),
        MarkdownModule.forRoot(),
        IconModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: LOCALE_ID,
            deps: [AuthService],
            useFactory: (authService) => authService.getLocale(),
        },
        reducerProvider,
        { provide: ErrorHandler, useFactory: errorHandlerFactory },
    ],
})
export class AppModule {
    constructor(private routerExtService: RouterExtService) {}
}
