import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import * as UserActionTypes from '../../actions/user/constants';
import { catchError, mergeMap } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from '../../../core/services/user/user.service';
import { CustomerUser } from '../../models/customer-user.model';

@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private userService: UserService,
        private nzMessageService: NzMessageService
    ) {}

    customerUsersListRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActionTypes.USERS_LIST_REQUEST),
            mergeMap(({ clientId }) =>
                this.userService.getUsersByClientId(clientId).pipe(
                    mergeMap(({ payload }) => {
                        return of({
                            type: UserActionTypes.USERS_LIST_RECEIVE,
                            items: payload,
                        });
                    }),
                    catchError((error) =>
                        of({ type: UserActionTypes.USERS_LIST_FAILED, error })
                    )
                )
            )
        )
    );

    customerUserRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActionTypes.USER_REQUEST),
            mergeMap(({ userId }) =>
                this.userService.getUser(userId).pipe(
                    mergeMap(({ payload }) => {
                        return of({
                            type: UserActionTypes.USER_RECEIVE,
                            customerUser: payload,
                        });
                    }),
                    catchError((error) =>
                        of({ type: UserActionTypes.USER_FAILED, error })
                    )
                )
            )
        )
    );

    updateCustomerUserRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActionTypes.UPDATE_USER_REQUEST),
            mergeMap(({ input }) =>
                this.userService.putUser(input).pipe(
                    mergeMap(({ payload: user }) => {
                        this.userService.newUserCreated$.next(false);

                        return of({
                            type: UserActionTypes.UPDATE_USER_RECEIVE,
                            user,
                        });
                    }),
                    catchError((error) =>
                        of({
                            type: UserActionTypes.USER_FAILED,
                            error: error.toString(),
                        })
                    )
                )
            )
        )
    );

    createCustomerUserRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActionTypes.CREATE_USER_REQUEST),
            mergeMap(({ userInput }) =>
                this.userService.postUser(userInput).pipe(
                    mergeMap(({ payload }) => {
                        this.userService.newUserCreated$.next(true);
                        return of({
                            type: UserActionTypes.CREATE_USER_RECEIVE,
                            customerUser: payload,
                        });
                    }),
                    catchError((error) =>
                        of({ type: UserActionTypes.CREATE_USER_FAILED, error })
                    )
                )
            )
        )
    );

    createCustomerUserReceive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActionTypes.CREATE_USER_RECEIVE),
            mergeMap(({ customerUser }: { customerUser: CustomerUser }) => {
                this.userService.newUserCreated$.next(true);
                this.nzMessageService.success(
                    `Customer ${customerUser.userLoginId} has been created!`
                );
                return of({
                    type: UserActionTypes.USERS_LIST_REQUEST,
                    clientId: customerUser.clientId,
                });
            })
        )
    );
}
