<div class="help-container">
    <div class="help__controls">
        <div class="help-page-top-right">
            <button
                [disabled]="ticketsLoading || !tickets.length || loading"
                nzType="link"
                nz-button
                nz-dropdown
                [nzDropdownMenu]="menu"
                nzPlacement="bottomRight"
                data-cy="help-page__submit-ticket"
            >
                <i nz-icon nzType="flag"></i>
                Submit a support ticket
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu data-cy="help-page__tickets-list">
                    <li
                        class="ticket-form-list__item"
                        *ngFor="let ticket of tickets"
                        nz-menu-item
                        (click)="showTicketingForm(ticket)"
                    >
                        <div class="ticket-form-list-item-name">
                            {{ ticket.name }}
                        </div>
                        <div class="ticket-form-list-item-description">
                            {{ ticket.description }}
                        </div>
                    </li>
                </ul>
            </nz-dropdown-menu>
            <div
                class="help-controls__actions"
                *hasPermission="['HELP_MGMT', 'HELP_MGMT_VIEW']"
            >
                <button
                    type="button"
                    [disabled]="!selectedClientId || loading"
                    nz-button
                    (click)="onProcessDoc()"
                    nzType="primary"
                    data-cy="help-page__add-document"
                >
                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                    <span>{{ buttonAdd ? 'Add' : 'Edit' }} Document</span>
                </button>
            </div>
        </div>
    </div>
    <ng-container *ngIf="loading; else tabsContent">
        <div class="loading-container" data-cy="help-page__loading">
            <nz-spin nzTip="Please, wait..."></nz-spin>
        </div>
    </ng-container>
    <ng-template #tabsContent>
        <nz-tabset
            [nzSelectedIndex]="selectedTabIndex"
            (nzSelectedIndexChange)="onSelectedTabChanged($event)"
        >
            <nz-tab
                data-cy="help-page__user-guide-tab"
                [nzDisabled]="loading"
                [nzTitle]="userGuideTitle"
            >
                <nz-spin [nzSpinning]="isSpinning" [nzDelay]="100">
                    <div class="guide-container" style="height: 80vh">
                        <div class="guide-container-box">
                            <iframe
                                #guidePreview
                                src=""
                                width="100%"
                                height="100%"
                                data-cy="guide-preview"
                            >
                            </iframe>
                        </div>
                    </div>
                </nz-spin>
            </nz-tab>
            <ng-template #userGuideTitle>
                <div data-cy="user-guide__tab">User Guide</div>
            </ng-template>
            <nz-tab
                data-cy="help-page__glossary"
                [nzDisabled]="loading"
                [nzTitle]="glossaryOfTermsTitle"
            >
                <nz-spin [nzSpinning]="isSpinning" [nzDelay]="100">
                    <div class="terms-container" style="height: 80vh">
                        <div class="terms-container-box">
                            <iframe
                                #termsPreview
                                data-cy="terms-preview"
                                src=""
                                width="100%"
                                height="100%"
                            >
                            </iframe>
                        </div>
                    </div>
                </nz-spin>
            </nz-tab>
            <ng-template #glossaryOfTermsTitle>
                <div data-cy="glossary-of-terms__tab">Glossary of Terms</div>
            </ng-template>
            <nz-tab
                data-cy="help-page__documents"
                [nzDisabled]="loading"
                [nzTitle]="technicalDocsTitle"
            >
                <div class="docs__table" data-cy="help-page__documents-table">
                    <nz-table
                        #docsTable
                        [nzSize]="'middle'"
                        nzHideOnSinglePage
                        [nzData]="files"
                        [nzLoading]="documentsLoading"
                    >
                        <thead>
                            <tr>
                                <th
                                    nzWidth="15%"
                                    data-cy="help-page-documents-table__doc-name"
                                >
                                    Doc name
                                </th>
                                <th
                                    nzWidth="50%"
                                    data-cy="help-page-documents-table__doc-desc"
                                >
                                    Description
                                </th>
                                <th
                                    nzWidth="25%"
                                    data-cy="help-page-documents-table__doc-created-at"
                                >
                                    Created
                                </th>
                                <th
                                    data-cy="help-page-documents-table__doc-actions"
                                ></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of docsTable.data">
                                <td
                                    data-cy="help-page-documents-table__doc-name"
                                    [innerHTML]="
                                        item.docName
                                            | highlight: query
                                            | safe: 'html'
                                    "
                                    (click)="showDescription(item.description)"
                                ></td>
                                <td
                                    data-cy="help-page-documents-table__doc-desc"
                                    (click)="showDescription(item.description)"
                                >
                                    Click to view
                                </td>
                                <td
                                    data-cy="help-page-documents-table__doc-created-at"
                                    (click)="showDescription(item.description)"
                                >
                                    {{
                                        item.createdAt
                                            | date: 'dd MMM yyyy, h:mm a'
                                    }}
                                </td>
                                <td
                                    class="actions__cell"
                                    data-cy="help-page-documents-table__doc-actions"
                                >
                                    <ng-container>
                                        <i
                                            nz-icon
                                            nzType="dots"
                                            nzTheme="fill"
                                        ></i>
                                        <div class="table-actions-container">
                                            <i
                                                data-cy="help-page-documents-table-actions__preview"
                                                (click)="
                                                    onPreviewDocument(item.id)
                                                "
                                                nz-tooltip
                                                nzTooltipPlacement="top"
                                                nzTooltipTitle="View"
                                                nzTooltipOverlayClassName="primary-tooltip"
                                                class="action-icon"
                                                nz-icon
                                                nzType="attributes"
                                                nzTheme="outline"
                                            ></i>
                                            <i
                                                data-cy="help-page-documents-table-actions__download"
                                                (click)="
                                                    onDownloadDocument(item.id)
                                                "
                                                nz-tooltip
                                                nzTooltipPlacement="top"
                                                nzTooltipTitle="Download"
                                                nzTooltipOverlayClassName="primary-tooltip"
                                                class="action-icon download"
                                                nz-icon
                                                nzType="download"
                                                nzTheme="outline"
                                            ></i>
                                            <i
                                                data-cy="help-page-documents-table-actions__delete"
                                                *hasPermission="['HELP_MGMT']"
                                                (click)="
                                                    onDeleteDocument(
                                                        item.id,
                                                        item.docName
                                                    )
                                                "
                                                nz-tooltip
                                                nzTooltipPlacement="top"
                                                nzTooltipTitle="Delete"
                                                nzTooltipOverlayClassName="danger-tooltip"
                                                class="action-icon delete"
                                                nz-icon
                                                nzType="delete"
                                                nzTheme="outline"
                                            ></i>
                                            <i
                                                data-cy="help-page-documents-table-actions__edit"
                                                *hasPermission="[
                                                    'HELP_MGMT',
                                                    'HELP_MGMT_VIEW'
                                                ]"
                                                (click)="
                                                    onEditDocument(item.id)
                                                "
                                                nz-tooltip
                                                nzTooltipPlacement="top"
                                                nzTooltipTitle="Edit"
                                                nzTooltipOverlayClassName="primary-tooltip"
                                                class="action-icon"
                                                nz-icon
                                                nzType="edit"
                                                nzTheme="outline"
                                            ></i>
                                        </div>
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </nz-tab>
            <ng-template #technicalDocsTitle>
                <div data-cy="technical-docs__tab">Technical Documents</div>
            </ng-template>
        </nz-tabset>
    </ng-template>
    <nz-drawer
        nzMaskClosable
        [nzWidth]="1000"
        [nzVisible]="docAction"
        [nzTitle]="this.docAction !== 'edit' ? 'Add Document' : 'Edit Document'"
    >
        <ng-container *nzDrawerContent>
            <app-upload-form
                *ngIf="docAction"
                (callback)="onDocCallback($event)"
                [isSpinning]="isPopupSpinning"
                [action]="docAction"
                (spinning)="onDocSpin($event)"
                [selectedFile]="loadedFile"
                (closed)="onCloseDocDrawer()"
            ></app-upload-form>
        </ng-container>
    </nz-drawer>
    <nz-drawer
        nzMaskClosable
        [nzWidth]="360"
        [nzVisible]="showDescrAction"
        [nzTitle]="'View Description'"
    >
        <ng-container *nzDrawerContent>
            <app-description-form
                *ngIf="showDescrAction"
                [description]="selDocDescription"
                (closed)="onCloseDescrDrawer()"
            ></app-description-form>
        </ng-container>
    </nz-drawer>
</div>

<ng-template #ticketFormContentTemplate let-params>
    <app-ticketing-form [ticket]="params.ticket"></app-ticketing-form>
</ng-template>
