import Bugsnag from '@bugsnag/js';
import { environment } from 'src/environments/environment';

export const BugsnagClient = Bugsnag.start({
    apiKey: environment.bugsnagApiKey,
    collectUserIp: false,
    enabledReleaseStages: ['prod', 'canary', 'stage', 'dev'],
    maxBreadcrumbs: 50,
    releaseStage: environment.releaseStage,
    autoTrackSessions: true,
});
