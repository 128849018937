<div class="client-database-table-append-form-container">
    <form
        class="client-database-table-append__form"
        [formGroup]="appendTableFormGroup"
        nz-form
        (ngSubmit)="onSubmit()"
    >
        <nz-alert
            *ngIf="error"
            nzType="error"
            [nzMessage]="error"
            nzShowIcon
        ></nz-alert>
        <nz-alert
            nzType="info"
            nzMessage="The .csv file must include headers and all rows to be appended. All fields in the table must
    be included and in the same order as they appear in the database table even if they are null."
        ></nz-alert>
        <div nz-row nzGutter="4">
            <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label>PLease upload CSV file</nz-form-label>
                    <nz-form-control
                        [nzSpan]="24"
                        [nzValidateStatus]="
                            !fileList?.length && formSubmitted
                                ? 'error'
                                : 'success'
                        "
                        nzListType="text/csv"
                        [nzErrorTip]="csvFileUploadErrorTmpl"
                    >
                        <nz-upload
                            accept="text/csv"
                            [nzBeforeUpload]="beforeFileUpload"
                            [nzFileList]="fileList"
                        >
                            <button
                                class="attach-file-button"
                                nz-button
                                nzSize="large"
                                type="button"
                            >
                                <i nz-icon nzType="upload"></i>
                                <span>Choose File</span>
                            </button>
                        </nz-upload>
                        <ng-template #csvFileUploadErrorTmpl let-control>
                            <ng-container
                                *ngIf="!fileList?.length && formSubmitted"
                                >CSV file is required
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzRequired>Notes</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzValidateStatus>
                        <nz-input-group nzSize="large">
                            <textarea
                                style="min-height: 13.75rem"
                                formControlName="note"
                                nz-input
                                placeholder="Enter note here"
                            ></textarea>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control
                        class="drawer-actions-control"
                        [nzSpan]="24"
                    >
                        <div class="drawer-controls">
                            <button
                                type="submit"
                                nzSize="large"
                                [nzLoading]="loading"
                                nz-button
                                [disabled]="
                                    !appendTableFormGroup.valid ||
                                    !appendTableFormGroup.dirty
                                "
                                nzType="primary"
                            >
                                <span>Append Data</span>
                            </button>
                            <button
                                type="button"
                                nzSize="large"
                                nz-button
                                (click)="onClose()"
                            >
                                <span>Close</span>
                            </button>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </form>
</div>
