<div class="lca-impact-categories-selection-form-container">
    <h4 class="lca-sandbox-header">Impact Categories</h4>
    <h5 class="lca-sandbox-subheader">Please select all that apply</h5>
    <div class="lca-impact-categories-filter">
        <app-text-search-input
            (search)="onSearchImpactCategories($event)"
        ></app-text-search-input>
        <nz-switch
            [(ngModel)]="impactCategoriesFilter.showSelected"
            (ngModelChange)="onShowSelectedImpactCategoriesChanged()"
            nzCheckedChildren="Show all"
            nzUnCheckedChildren="Show selected"
        ></nz-switch>
        <p class="selected-items-text">
            {{ setOfImpactCategoriesChecked.size }} selected
        </p>
        <app-lca-impact-categories-filter
            (changed)="onImpactCategoriesFilterChanged($event)"
        ></app-lca-impact-categories-filter>
    </div>
    <nz-table
        #table
        [nzLoading]="impactCategoriesLoading"
        [nzSize]="'middle'"
        [nzFrontPagination]="false"
        nzHideOnSinglePage
        [nzData]="impactCategories"
    >
        <thead>
            <tr>
                <th
                    [nzChecked]="impactCategoriesAllChecked"
                    [nzIndeterminate]="impactCategoriesIndeterminate"
                    (nzCheckedChange)="onImpactCategoriesAllChecked($event)"
                ></th>
                <th class="th-number" nzWidth="10%">year</th>
                <th class="th-number" nzWidth="20%">impact serial</th>
                <th nzWidth="49%">impact name</th>
                <th nzWidth="21%">unit abbreviation</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of table.data; let i = index">
                <td
                    [nzChecked]="
                        setOfImpactCategoriesChecked.has(item.impactSerial)
                    "
                    (nzCheckedChange)="
                        onImpactCategoryChecked(item.impactSerial, $event)
                    "
                ></td>
                <td
                    class="td-number"
                    [innerHTML]="
                        item.year
                            | highlight: impactCategoriesFilter?.searchText
                            | safe: 'html'
                    "
                ></td>
                <td
                    class="td-number"
                    [innerHTML]="
                        item.impactSerial
                            | highlight: impactCategoriesFilter?.searchText
                            | safe: 'html'
                    "
                ></td>
                <td
                    [innerHTML]="
                        item.impactName
                            | highlight: impactCategoriesFilter?.searchText
                            | safe: 'html'
                    "
                ></td>
                <td
                    [innerHTML]="
                        item.unitAbbreviation
                            | highlight: impactCategoriesFilter?.searchText
                            | safe: 'html'
                    "
                ></td>
            </tr>
        </tbody>
    </nz-table>
</div>
