import {
    Component,
    OnInit,
    OnDestroy,
    Output,
    EventEmitter,
    Input,
    ChangeDetectorRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _first from 'lodash/first';
import { Customer } from '../../../store/models/customer.model';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import {
    AI_CLIENT_ID,
    CustomerService,
} from '../../services/customer/customer.service';
import { GlobalService } from '../../services/global/global.service';
@Component({
    selector: 'app-client-selector',
    templateUrl: './client-selector.component.html',
    styleUrls: ['./client-selector.component.less'],
})
export class ClientSelectorComponent implements OnInit, OnDestroy {
    @Output() selected: EventEmitter<string> = new EventEmitter();
    @Input() autoSelect: boolean = true;
    @Input() placeholder = 'Select client';
    @Input() disabled: boolean = false;
    @Input() mini: boolean = false;

    destroyed$: Subject<any> = new Subject();

    // customers
    customers: Customer[];
    customersLoading: boolean;
    selectedClientId: string;
    selectedClientLogo: string;
    //limit: number = 10000;

    constructor(
        private route: ActivatedRoute,
        private customersService: CustomerService,
        private cdr: ChangeDetectorRef,
        private globalService: GlobalService
    ) {}

    ngOnDestroy(): void {
        this.destroyed$.next(true);
    }

    ngOnInit() {
        this.customersLoading = true;
        this.customersService
            .getCustomers({
                excludeClientIds: [AI_CLIENT_ID],
            })
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: (payload) => {
                    this.customersLoading = false;
                    this.customers = _get(payload, 'payload', []);

                    this.route.queryParams
                        .pipe(takeUntil(this.destroyed$))
                        .subscribe((queryParams) => {
                            // if (queryParams['clientId']) {
                                // this.onClientChanged(queryParams['clientId']);
                            // } else {
                                if (
                                    this.customers &&
                                    this.customers.length &&
                                    this.autoSelect
                                ) {
                                    this.onClientChanged(
                                        this.customers[0].clientId
                                    );
                                }
                            // }
                        })
                        .unsubscribe();
                },
                error: (error) => {
                    this.customersLoading = false;
                },
                complete: () => {
                    this.customersLoading = false;
                    this.cdr.detectChanges();
                },
            });
    }

    onClientChanged(clientId: string) {
        this.selected.emit(clientId);
        console.log('client selector change')
        this.globalService.clientId$.next(clientId);
        this.selectedClientId = clientId;
        this.selectedClientLogo = _get(
            _find(this.customers, { clientId }),
            'logoImg'
        );
    }

    getCurrentClientId(): string {
        return this.selectedClientId;
    }
}
