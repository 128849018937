import {
    Component,
    OnInit,
    Input,
    ChangeDetectionStrategy,
} from '@angular/core';
import hexToRgba from 'hex-to-rgba';

@Component({
    selector: 'app-upload-portal-status',
    templateUrl: './upload-portal-status.component.html',
    styleUrls: ['./upload-portal-status.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadPortalStatusComponent implements OnInit {
    @Input() statusName: string;
    @Input() statusId: string;
    @Input() statusColor: string;
    @Input() query: string;

    constructor() {}

    ngOnInit() {}

    get color(): string {
        return this.statusColor || this.getColorByStatusId(this.statusId);
    }

    get bgColor(): string {
        return this.statusColor
            ? hexToRgba(this.statusColor, 0.08)
            : hexToRgba(
                  this.getColorByStatusId(this.statusId) || '#76e874',
                  0.08
              );
    }

    private getColorByStatusId(statusId: string): string {
        switch (statusId) {
            case 'DT_READY_FOR_DOWNLOAD':
                return '#00D789';
            case 'DT_PERFORMING_INTEGRITY_CHECKS':
                return '#3099CC';
            case 'DT_INTEGRITY_OK':
                return '#00D789';
            case 'DT_INTEGRITY_FAIL':
                return '#D76700';
            case 'DT_ACCEPTED':
                return '#00D789';
            case 'DT_NOT_COLLECTED':
                return '#3733FF';
            default:
                return '#3099cc';
        }
    }
}
