<div
    class="trigger-container"
    nz-popover
    (nzPopoverVisibleChange)="onShowPopover($event)"
    [nzPopoverVisible]="popoverVisible"
    nzPopoverTrigger="click"
    [nzPopoverContent]="contentTemplate"
    nzPopoverPlacement="bottom"
>
    <ng-content></ng-content>
</div>
<ng-template #contentTemplate>
    <div class="users-container">
        <h4 class="title">{{ !loading ? users.length : '' }} Assigned users</h4>
        <ng-container *ngIf="loading; else content">
            <div class="loading-container">
                <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
            </div>
        </ng-container>
        <ng-template #content>
            <ng-container *ngIf="users.length; else noUsers">
                <ul class="users-list">
                    <li class="users-list-item" *ngFor="let user of users">
                        <nz-avatar
                            class="avatar"
                            [nzText]="getUserAvatarText(user)"
                        ></nz-avatar>
                        <div class="user-list-item__name">
                            {{ user.userFirstName }} {{ user.userLastName }}
                        </div>
                    </li>
                </ul>
            </ng-container>
            <ng-template #noUsers>
                <p class="no-users-text">No assigned users.</p>
            </ng-template>
        </ng-template>
    </div>
</ng-template>
