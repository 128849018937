export const AUTH_LOGIN_REQUEST = 'Auth request';
export const AUTH_LOGIN_RECEIVE = 'Auth receive';

export const AUTHENTICATE_SSO_REQUEST = 'Authenticate SSO request';
export const AUTHENTICATE_SSO_RECEIVE = 'Authenticate SSO receive';
export const AUTHENTICATE_SSO_FAILED = 'Authenticate SSO failed';

export const AUTH_LOGOUT_REQUEST = 'Auth logout request';
export const AUTH_LOGOUT_RECEIVE = 'Auth logout receive';
export const AUTH_LOGIN_FAILED = 'Auth login failed';

export const LOGIN_TYPES_REQUEST = 'Login types request';
export const LOGIN_TYPES_RECEIVE = 'Login types receive';
export const LOGIN_TYPES_FAILED = 'Login types failed';

export const SWITCH_ACCOUNT_REQUEST = 'Switch account request';
