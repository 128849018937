<div class="reporting-table-runs__container">
    <app-infinite-scroll
        [loading]="loading"
        [loaded]="loaded"
        (scrolled)="onTableScrolled()"
    >
        <nz-table
            [nzPageSize]="99999999"
            #table
            [nzSize]="'middle'"
            nzHideOnSinglePage
            [nzData]="runs"
            [nzLoading]="loading"
        >
            <thead>
                <tr>
                    <th
                        *ngFor="let column of columns"
                        [nzWidth]="column.width"
                    >
                        {{ column.title }}
                    </th>
                    <th nzWidth="10%"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of table.data; let i = index">
                    <td>
                        <nz-tag
                            *ngFor="let tableName of data.tableNames ?? []"
                        >
                            {{ tableName }}
                        </nz-tag>
                    </td>
                    <td>
                        {{data.runBy}}
                    </td>
                    <td style="font-size: 0.875rem">
                        {{
                            data.startedStamp | date: 'dd MMM yyyy, h:mm a'
                        }}
                    </td>
                    <td style="font-size: 0.875rem">
                        {{ data.endedStamp | date: 'dd MMM yyyy, h:mm a' }}
                    </td>
                    <td class="status">
                        <div
                            class="status-name"
                            *ngIf="data.runStatus.statusName === 'Running'"
                        >
                            <div class="ring-container">
                                <div class="ringring"></div>
                                <div class="circle"></div>
                            </div>
                            <div [style.color]="'#3099cc'">Running</div>
                        </div>
                        <app-upload-portal-status
                            *ngIf="data.runStatus.statusName === 'Succeeded'"
                            [statusName]="'Succeeded'"
                            [statusColor]="'#00D789'"
                        ></app-upload-portal-status>
                        <app-upload-portal-status
                            *ngIf="data.runStatus.statusName === 'Failed'"
                            [statusName]="'Failed'"
                            [statusColor]="'#d61616'"
                        ></app-upload-portal-status>
                        <app-upload-portal-status
                            *ngIf="data.runStatus.statusName === 'Canceled'"
                            [statusName]="'Canceled'"
                            [statusColor]="'#f5a60a'"
                        ></app-upload-portal-status>
                    </td>
                    <td class="actions__cell">
                        <i nz-icon nzType="dots" nzTheme="fill"></i>
                        <div class="table-actions-container">
                            <i
                                (click)="
                                    onQueryParams(
                                        data.runParams,
                                        data.runId
                                    )
                                "
                                nz-tooltip
                                nzTooltipPlacement="top"
                                nzTooltipTitle="Query parameters"
                                nzTooltipOverlayClassName="primary-tooltip"
                                class="action-icon"
                                nz-icon
                                nzType="query-param"
                                nzTheme="outline"
                            ></i>
                            <i
                                (click)="onViewMessage(data)"
                                nz-tooltip
                                nzTooltipPlacement="top"
                                [nzTooltipArrowPointAtCenter]="true"
                                nzTooltipTitle="View Logs"
                                nzTooltipOverlayClassName="primary-tooltip"
                                class="action-icon"
                                nz-icon
                                nzType="error"
                                nzTheme="outline"
                            >
                            </i>
                        </div>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </app-infinite-scroll>
    <nz-drawer
        nzMaskClosable
        [nzWidth]="640"
        [nzVisible]="showRunParams"
        nzTitle="Run Params"
        (nzOnClose)="onCloseQueryParams()"
    >
        <ng-container *nzDrawerContent>
            <p [style.whiteSpace]="'pre-line'" nz-typography nzCopyable [nzCopyText]="messageToCopy">
                @if (isJSONViewObject(selectedRunParams)) {
                    <ngx-json-viewer [json]="selectedRunParams"></ngx-json-viewer>
                } @else {
                    {{selectedRunParams}}
                }
            </p>
        </ng-container>
    </nz-drawer>
</div>

<nz-drawer
    nzMaskClosable
    [nzWidth]="640"
    [nzVisible]="showLogs"
    nzTitle="Run Logs"
    (nzOnClose)="onCloseLogsDrawer()"
>
    <ng-container *nzDrawerContent>
       <ng-container *ngIf="logsLoading; else logsContent">
            <div class="loading-container">
                <nz-spin nzTip="Loading..." nzSimple></nz-spin>
            </div>
       </ng-container>
       <ng-template #logsContent>
            <ng-container *ngIf="filteredLogs.length > 0; else noLogs">
                <div nz-typography nzCopyable [nzCopyText]="getStringMessage(filteredLogs)">
                    <nz-timeline>
                        <nz-timeline-item  *ngFor="let log of filteredLogs">
                            <div nz-flex [nzVertical]="true" [nzGap]="8">
                                <div [style.marginTop.rem]="0.375" nz-flex [nzVertical]="true" [nzGap]="4">
                                    @for (message of log.data.messages; track message) {
                                        <div [style.whiteSpace]="'pre-line'" class="log-message">
                                            {{message}}
                                        </div>
                                    }
                                </div>
                                <div class="log-date">{{log.createdStamp | date: 'dd MMM yyyy, h:mm:ss a' }}</div>
                            </div>
                        </nz-timeline-item>
                    </nz-timeline>
                </div>
            </ng-container>
            <ng-template #noLogs>
                <p class="no-logs-text">No logs found</p>
            </ng-template>
        </ng-template>
    </ng-container>
</nz-drawer>