import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { UserService } from 'src/app/core/services/user/user.service';
import { User } from 'src/app/store/models/user.model';
import _get from 'lodash/get';

export enum UserPopoverTypes {
    Attributes = 'Attributes',
    PageTypes = 'PageTypes',
    ReportGroups = 'ReportGroups',
}

@Component({
    selector: 'app-users-popover',
    templateUrl: './users-popover.component.html',
    styleUrls: ['./users-popover.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersPopoverComponent implements OnInit {
    popoverVisible: boolean = false;

    @Input() entityId: string;
    @Input() entityType: UserPopoverTypes;

    loading: boolean = false;
    users: User[] = [];

    constructor(
        private cdr: ChangeDetectorRef,
        private userService: UserService
    ) {}

    ngOnInit(): void {}

    fetchEntities() {
        this.loading = true;
        this.cdr.detectChanges();

        switch (this.entityType) {
            case UserPopoverTypes.ReportGroups:
                this.userService
                    .getUsersByReportGroupId(this.entityId)
                    .subscribe({
                        next: (payload) => {
                            this.users = _get(payload, 'payload', []);
                        },
                        complete: () => {
                            this.loading = false;
                            this.cdr.detectChanges();
                        },
                    });
                break;
            case UserPopoverTypes.Attributes:
                this.userService
                    .getUsersByAttributeId(this.entityId)
                    .subscribe({
                        next: (payload) => {
                            this.users = _get(payload, 'payload', []);
                        },
                        complete: () => {
                            this.loading = false;
                            this.cdr.detectChanges();
                        },
                    });
                break;
            case UserPopoverTypes.PageTypes:
                this.userService
                    .getUsersByUploadPortalTypeId(this.entityId)
                    .subscribe({
                        next: (payload) => {
                            this.users = _get(payload, 'payload', []);
                        },
                        complete: () => {
                            this.loading = false;
                            this.cdr.detectChanges();
                        },
                    });
                break;
        }
    }

    onShowPopover(value: boolean) {
        this.popoverVisible = value;
        if (this.popoverVisible) {
            this.fetchEntities();
        }
        this.cdr.detectChanges();
    }

    getUserAvatarText(user: User) {
        return `${user.userFirstName?.[0] ?? ''}${
            user.userLastName?.[0] ?? ''
        }`;
    }
}
