<div class="portal-statuses__table">
    <app-infinite-scroll
        [loaded]="statusesLoaded"
        [loading]="statusesLoading"
        (scrolled)="onTableScrolled()"
    >
        <nz-table
            #table
            [nzLoading]="statusesLoading"
            [nzSize]="'middle'"
            [nzPageSize]="999999999"
            nzHideOnSinglePage
            [nzData]="statuses"
        >
            <thead (nzSortOrderChange)="orderChange($event)">
                <tr>
                    <th
                        *ngFor="let column of columns"
                        [nzSortFn]="true"
                        [nzWidth]="column.width"
                        [nzSortDirections]="column.sortDirections"
                        nzColumnKey="{{ column.title }}"
                    >
                        {{ column.title }}
                    </th>
                    <th nzWidth="15%">Color</th>
                    <th nzWidth="10%"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of table.data; let i = index">
                    <td
                        [innerHTML]="
                            data.collectionStatusName
                                | highlight: query
                                | safe: 'html'
                        "
                    ></td>
                    <td>
                        <div
                            class="status-color"
                            [style.backgroundColor]="data.statusColor"
                        ></div>
                    </td>
                    <td class="actions__cell">
                        <i
                            nz-icon
                            nzType="dots"
                            nzTheme="fill"
                            *hasPermission="['UPLOAD_PORTAL_MGMT']"
                        ></i>
                        <div
                            class="table-actions-container"
                            *hasPermission="[
                                'UPLOAD_PORTAL_MGMT',
                                'UPLOAD_PORTAL_MGMT_VIEW'
                            ]"
                        >
                            <i
                                *hasPermission="['UPLOAD_PORTAL_MGMT']"
                                (click)="
                                    onRemoveStatus(
                                        data.collectionStatusId,
                                        data.collectionStatusName
                                    )
                                "
                                nz-tooltip
                                nzTooltipPlacement="top"
                                nzTooltipTitle="Delete"
                                nzTooltipOverlayClassName="danger-tooltip"
                                class="action-icon delete"
                                nz-icon
                                nzType="delete"
                                nzTheme="outline"
                            ></i>
                            <i
                                *hasPermission="[
                                    'UPLOAD_PORTAL_MGMT',
                                    'UPLOAD_PORTAL_MGMT_VIEW'
                                ]"
                                (click)="onEditStatus(data)"
                                nz-tooltip
                                nzTooltipPlacement="top"
                                nzTooltipTitle="Edit"
                                nzTooltipOverlayClassName="primary-tooltip"
                                class="action-icon"
                                nz-icon
                                nzType="edit"
                                nzTheme="outline"
                            ></i>
                        </div>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </app-infinite-scroll>
</div>

<nz-drawer
    nzMaskClosable
    [nzWidth]="360"
    [nzVisible]="formMode"
    [nzTitle]="
        selectedStatus ? 'Edit portal status' : 'Create new portal status'
    "
    (nzOnClose)="closeStatusFormMode()"
>
    <ng-container *nzDrawerContent>
        <app-portal-status-form
            [status]="selectedStatus"
            [clientId]="clientId"
            *ngIf="formMode"
            (closed)="closeStatusFormMode($event)"
        ></app-portal-status-form>
    </ng-container>
</nz-drawer>