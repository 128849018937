<div class="upload-history-tile-container">
    <div nz-row class="upload-history-top1">
        <div nz-col nzSpan="12" class="upload-history-top1_login-id">
            <i nz-icon nzType="users"></i>
            {{ item?.userLoginId }}
        </div>
        <div nz-col nzSpan="12" class="upload-history-top1__portal-name">
            <i nz-icon nzType="portal-type"></i>
            {{ item?.uploadPortalName }}
        </div>
    </div>
    <div nz-row class="upload-history-top2">
        <div
            *hasPermission="['AIADMIN', 'AIUSER']"
            nz-col
            nzSpan="12"
            class="upload-history-top1_client-name"
        >
            {{ item?.clientName }}
        </div>
        <div nz-col nzSpan="12" class="upload-history-top1__portal-type">
            {{ item?.uploadPortalTypeName }}
        </div>
    </div>
    <div class="upload-history-action">
        <div class="upload-history-action__event">
            {{ item?.event }}
        </div>
        <div class="upload-history-action__item">
            {{ item?.itemName }}
        </div>
        <div
            nz-tooltip
            [nzTooltipTitle]="item.createdStamp | date: 'dd MMM yyyy, h:mm a'"
            class="upload-history-action__date"
        >
            {{ formatDate(item?.createdStamp) }}
        </div>
    </div>
</div>
