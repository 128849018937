import {
    Component,
    OnInit,
    OnDestroy,
    ViewChildren,
    QueryList,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';
import {
    AttributeService,
    AttributeTypesFilter,
} from '../../../core/services/attribute/attribute.service';
import { AttributeType } from '../../../store/models/attribute.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import _orderBy from 'lodash/orderBy';
import _last from 'lodash/last';
import _get from 'lodash/get';
import _find from 'lodash/find';
import { TextSearchInputComponent } from 'src/app/core/components/text-search-input/text-search-input.component';
import { GlobalService } from 'src/app/core/services/global/global.service';

const PAGE_SIZE = 100;

@Component({
    selector: 'app-attribute-types-page',
    templateUrl: './attribute-types-page.component.html',
    styleUrls: ['./attribute-types-page.component.less'],
})
export class AttributeTypesPageComponent implements OnInit, OnDestroy {
    selectedClientId: string;
    selectedAttributeType: AttributeType;
    attributeTypesLoading: boolean = true;
    attributeTypes: AttributeType[] = [];
    attributeTypesLoaded: boolean = false;
    attributeTypeFormMode: boolean = false;

    ngDestroyed$ = new Subject();
    filterForm: Subject<AttributeTypesFilter> = new Subject();

    @ViewChild(TextSearchInputComponent) searchBox: TextSearchInputComponent;

    query: string;
    searchFilter = {
        searchText: '',
        lastAttrTypeId: '',
        selectedAttrId: '',
        columnName: 'attr_type_name',
        sortDirection: 'ascend',
    };
    columns = [
        {
            title: 'Attribute type name',
            key: 'attr_type_name',
            width: '40%',
            dataCy: 'attr-types-table__name-sort',
            sortDirections: ['descend', 'ascend', null],
        },
        {
            title: 'Quantity attributes',
            key: 'Quantity attributes',
            width: '25%',
            class: 'th-number',
            dataCy: 'attr-types-table__attr-count-sort',
            sortDirections: [null],
        },
        {
            title: 'Date Created',
            key: 'created_stamp',
            width: '20%',
            dataCy: 'attr-types-table__created-at-sort',
            sortDirections: ['ascend', 'descend', null],
        },
    ];

    constructor(
        private attributeService: AttributeService,
        private message: NzMessageService,
        private modal: NzModalService,
        private router: Router,
        private globalService: GlobalService,
    ) {}

    ngOnInit() {
        this.globalService.clientId$.pipe(takeUntil(this.ngDestroyed$))
            .subscribe(
                clientId => {
                    this.attributeTypesLoading = true;
                    this.attributeTypesLoaded = false;
                    this.selectedClientId = clientId;
                    this.fetchAttributeTypes();
                }
            )
        this.filterForm
            .pipe(takeUntil(this.ngDestroyed$), debounceTime(400))
            .subscribe((filter) => {
                this.fetchAttributeTypes(filter);
            });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    private fetchAttributeTypes(filter?: AttributeTypesFilter) {
        if (this.attributeTypesLoaded) return;
        if (!this.selectedClientId) return;

        this.attributeService
            .getAttributeTypesByClient(this.selectedClientId, filter, PAGE_SIZE)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    const attrTypes = _get(payload, 'payload', []);
                    if (attrTypes.length < PAGE_SIZE) {
                        this.attributeTypesLoaded = true;
                    }
                    if (filter && filter.lastAttrTypeId) {
                        if (attrTypes.length) {
                            this.attributeTypes = [
                                ...this.attributeTypes,
                                ...attrTypes,
                            ];
                        }
                    } else {
                        this.attributeTypes = attrTypes;
                    }
                },
                complete: () => {
                    this.attributeTypesLoading = false;
                    this.query = this.searchFilter.searchText;
                },
            });
    }

    onRemoveAttributeType(attrTypeId: string, attrTypeName: string) {
        this.attributeTypesLoaded = false;
        this.modal.confirm({
            nzTitle: `Do you want to remove <b>${attrTypeName}</b>?`,
            nzContent: `
        If you delete it you will not be able to recover attribute type.<br>
        Do you want to delete the attribute type?
      `,
            nzOnOk: () =>
                this.attributeService
                    .deleteAttributeType(attrTypeId)
                    .subscribe(() => {
                        this.message.success(
                            `Attribute type <b>${attrTypeName}</b> has been deleted`,
                            { nzDuration: 3000 }
                        );
                        this.filterForm.next({
                            searchText: this.searchFilter.searchText,
                            columnName: this.searchFilter.columnName,
                            sortDirection: this.searchFilter.sortDirection,
                        });
                    }),
            nzOkText: 'Yes, remove',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzCancelText: 'Close',
            nzClosable: false,
            nzOnCancel: () => {},
        });
    }

    onEditAttributeType(attrTypeId: string) {
        this.selectedAttributeType = _find(this.attributeTypes, { attrTypeId });
        this.attributeTypeFormMode = true;
    }

    onCreateAttributeType() {
        this.attributeTypeFormMode = true;
        this.selectedAttributeType = null;
    }

    closeAttributeTypeFormMode(withReload: boolean) {
        this.attributeTypesLoaded = false;
        this.attributeTypeFormMode = false;
        if (!this.selectedAttributeType && withReload) {
            this.fetchAttributeTypes();
            this.searchBox.onClear();
        } else {
            this.filterForm.next({
                searchText: this.searchFilter.searchText,
                columnName: this.searchFilter.columnName,
                sortDirection: this.searchFilter.sortDirection,
            });
        }
    }

    onSearch(value: string) {
        this.searchFilter.searchText = value;
        this.attributeTypesLoaded = false;
        this.attributeTypesLoading = true;
        this.filterForm.next({
            searchText: this.searchFilter.searchText,
            columnName: this.searchFilter.columnName,
            sortDirection: this.searchFilter.sortDirection,
        });
    }

    onTableScrolled() {
        if (!this.attributeTypesLoaded && this.attributeTypes.length >= PAGE_SIZE) {
            const attr: AttributeType = _last(this.attributeTypes);
            if (attr && attr.attrTypeId) {
                const { attrTypeId, attrTypeName, createdStamp } = attr;
                this.filterForm.next({
                    searchText: this.searchFilter.searchText,
                    columnName: this.searchFilter.columnName,
                    sortDirection: this.searchFilter.sortDirection,
                    lastAttrTypeId: attrTypeId,
                    lastAttrTypeName: attrTypeName,
                    lastCreatedStamp: createdStamp,
                });
            }
        }
    }

    orderChange(col: any) {
        if (col.key === 'Quantity attributes') {
            return;
        }
        this.attributeTypesLoading = true;
        this.attributeTypesLoaded = false;
        this.searchFilter.columnName = col.key;
        this.searchFilter.sortDirection = col.value;
        this.filterForm.next({
            searchText: this.searchFilter.searchText,
            columnName: this.searchFilter.columnName,
            sortDirection: this.searchFilter.sortDirection,
        });
    }
}
