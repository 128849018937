<div class="roles-container">
    <div nz-flex nzJustify="space-between" nzAlign="center" class="roles__controls">
        <div class="roles-table__search">
            <app-text-search-input
                (search)="onSearch($event)"
            ></app-text-search-input>
        </div>
        <button
            data-cy="roles-controls__create-btn"
            *hasPermission="['ROLES_MGMT']"
            type="button"
            nz-button
            (click)="onCreateRole()"
            nzType="primary"
        >
            <i nz-icon nzType="plus" nzTheme="outline"></i>
            <span>Create role</span>
        </button>
    </div>
    <div class="roles__table">
        <app-infinite-scroll
            [loaded]="rolesLoaded"
            [loading]="rolesLoading"
            (scrolled)="onTableScrolled()"
        >
            <nz-table
                #table
                [nzLoading]="rolesLoading"
                [nzSize]="'middle'"
                [nzPageSize]="99999999"
                nzHideOnSinglePage
                [nzData]="roles"
            >
                <thead (nzSortOrderChange)="orderChange($event)">
                    <tr>
                        <th
                            *ngFor="let column of columns"
                            [attr.data-cy]="column.dataCy"
                            [nzSortFn]="true"
                            [nzWidth]="column.width"
                            [nzSortDirections]="column.sortDirections"
                            nzColumnKey="{{ column.key }}"
                        >
                            {{ column.title }}
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of table.data">
                        <td
                            data-cy="roles-table__name"
                            [innerHTML]="
                                data.roleName | highlight: query | safe: 'html'
                            "
                        ></td>
                        <td class="actions__cell">
                            <ng-container
                                *hasPermission="
                                    data.isAdmin
                                        ? ['SUPERUSER']
                                        : ['ROLES_MGMT', 'ROLES_MGMT_VIEW']
                                "
                            >
                                <i nz-icon nzType="dots" nzTheme="fill"></i>
                                <div
                                    class="table-actions-container"
                                    *hasPermission="[
                                        'ROLES_MGMT',
                                        'ROLES_MGMT_VIEW'
                                    ]"
                                >
                                    <i
                                        data-cy="roles-controls__delete-btn"
                                        *hasPermission="['ROLES_MGMT']"
                                        (click)="
                                            onRemoveRole(
                                                data.roleId,
                                                data.roleName
                                            )
                                        "
                                        nz-tooltip
                                        nzTooltipPlacement="top"
                                        nzTooltipTitle="Delete"
                                        nzTooltipOverlayClassName="danger-tooltip"
                                        class="action-icon delete"
                                        nz-icon
                                        nzType="delete"
                                        nzTheme="outline"
                                    ></i>
                                    <i
                                        data-cy="roles-controls__edit-btn"
                                        *hasPermission="[
                                            'ROLES_MGMT',
                                            'ROLES_MGMT_VIEW'
                                        ]"
                                        (click)="onEditRole(data.roleId)"
                                        nz-tooltip
                                        nzTooltipPlacement="top"
                                        nzTooltipTitle="Edit"
                                        nzTooltipOverlayClassName="primary-tooltip"
                                        class="action-icon"
                                        nz-icon
                                        nzType="edit"
                                        nzTheme="outline"
                                    ></i>
                                </div>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </app-infinite-scroll>
    </div>
    <nz-drawer
        nzMaskClosable
        [nzWidth]="360"
        [nzVisible]="roleFormMode"
        [nzTitle]="selectedRole ? 'Edit role' : 'Create new role'"
        (nzOnClose)="onCloseDrawer()"
    >
        <ng-container *nzDrawerContent>
            <app-role-form
                [clientId]="selectedClientId"
                (closed)="onCloseDrawer($event)"
                [role]="selectedRole"
                *ngIf="roleFormMode"
            ></app-role-form>
        </ng-container>
    </nz-drawer>
</div>
