import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-text-search-input',
    templateUrl: './text-search-input.component.html',
    styleUrls: ['./text-search-input.component.less'],
})
export class TextSearchInputComponent implements OnInit {
    value: string;

    @Output() search: EventEmitter<string> = new EventEmitter();
    @Input() placeholder: string = 'Search';
    @Input() initialValue: string = '';

    constructor() {}

    ngOnInit() {
        this.value = this.initialValue;
    }
    
    setValue(value: string) {
        this.value = value;
    }

    onSearchValueChanged(value: string) {
        this.search.emit(value);
    }

    onClear() {
        this.value = '';
        this.search.emit('');
    }
}
