import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import {
    ClientDatabaseService,
    ReportingTableInput,
} from '../../../core/services/client-database/client-database.service';
import {
    DbNaming,
    DB_NAMING_REGEXP_TYPES,
} from '../../../validators/dbNaming.validator';
import _get from 'lodash/get';
import _head from 'lodash/head';
import _sortBy from 'lodash/sortBy';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LCAImpactCateogry } from 'src/app/store/models/lca-impact-category';
import { NzMessageService } from 'ng-zorro-antd/message';
import { GlobalNotificationService } from 'src/app/core/services/global-notification/global-notification.service';
import { GlobalService } from 'src/app/core/services/global/global.service';
import { Subject, takeUntil } from 'rxjs';

export enum TableTypes {
    FACT_TABLE = 'FACT_TABLE',
    MATERIAL_FLOW_TABLE = 'MATERIAL_FLOW_TABLE',
    BREAKOUT_TABLE = 'BREAK_OUT_TABLE',
    ASSURANCE_TABLE = 'ASSURANCE_TABLE'
}

const DEFAULT_UNCHECKED_FIELDS = ['SBTDesignationID'];

@Component({
    selector: 'app-reporting-table-page',
    templateUrl: './reporting-table-page.component.html',
    styleUrls: ['./reporting-table-page.component.less'],
})
export class ReportingTablePageComponent implements OnInit, OnDestroy {
    tableFormGroup: UntypedFormGroup;

    tableNameLength: number = 20;

    saving: boolean = false;

    clientDataFieldsLoading: boolean = false;
    clientDataFields: string[] = [];
    selectedClientDataFields: string[] = [];

    factTableFieldsLoading: boolean = false;
    factTableFields: string[] = [];
    selectedFactTableFields: string[] = [];

    impactCategoriesLoading: boolean = false;
    impactCategories: LCAImpactCateogry[] = [];
    selectedImpactSerials: number[] = [];

    tableTypes = [
        {
            tableTypeId: TableTypes.FACT_TABLE,
            tableTypeName: 'Fact table',
        },
        {
            tableTypeId: TableTypes.MATERIAL_FLOW_TABLE,
            tableTypeName: 'Material flow table',
        },
        {
            tableTypeId: TableTypes.BREAKOUT_TABLE,
            tableTypeName: 'Breakout tables',
        },
        {
            tableTypeId: TableTypes.ASSURANCE_TABLE,
            tableTypeName: 'Assurance table',
            permissions: ['CLIENT_DB_TOOL_MGMT']
        }
    ];
    selectedTableTypeIds: string[] = [];
    tableTypesEnum = TableTypes;

    clientDatatableNames: string[] = [];
    clientDatatableNamesLoading: boolean;

    error: string;
    initError: string;
    breakoutError: string;
    
    clientId: string;

    defaultUncheckableFields: string[] = [];

    formMode: boolean = false;
    createStepIndex: number = 0;
    errorSteps: Set<number> = new Set();

    constructor(
        private fb: UntypedFormBuilder,
        private clientDatabaseService: ClientDatabaseService,
        private modals: NzModalService,
        private message: NzMessageService,
        private globalNotifications: GlobalNotificationService,
        private globalService: GlobalService
    ) {
        this.initTableForm();
    }

    private destroyed$: Subject<any> = new Subject();

    ngOnInit() {
        this.globalService.clientId$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
                clientId => {
                    if (clientId) {
                        this.clientId = clientId;
                        this.formMode = false;
                    }
                }
            )
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true)
    }

    onCreateStepIndexChange(index: number) {
        this.createStepIndex = index;
    }

    init() {
        this.clientDataFieldsLoading = true;
        this.clientDataFields = [];
        this.defaultUncheckableFields = [];
        this.selectedTableTypeIds = [];
        this.selectedImpactSerials = [];
        this.selectedFactTableFields = [];
        this.selectedClientDataFields = [];
        this.tableFormGroup.reset();

        this.clientDatabaseService
            .getClientDataFields(this.clientId)
            .subscribe({
                next: (payload) => {
                    this.clientDataFieldsLoading = false;
                    const clientDataFieldsMapping = _get(
                        payload,
                        'payload',
                        {}
                    );

                    Object.entries(clientDataFieldsMapping).forEach(
                        ([field, isDefault]) => {
                            if (isDefault) {
                                this.defaultUncheckableFields.push(field);
                            }

                            this.clientDataFields.push(field);

                            this.tableFormGroup.addControl(
                                `field#${field}`,
                                new UntypedFormControl(!DEFAULT_UNCHECKED_FIELDS.includes(field))
                            );

                            this.tableFormGroup.patchValue({ [`field#${field}`]: !DEFAULT_UNCHECKED_FIELDS.includes(field) });
                            
                            if (!DEFAULT_UNCHECKED_FIELDS.includes(field)) {
                                this.selectedClientDataFields.push(field);
                            }
                        }
                    );

                    this.clientDataFields = [
                        ...this.defaultUncheckableFields.sort((a, b) =>
                            a < b ? -1 : 1
                        ),
                        ...this.clientDataFields
                            .sort((a, b) => (a < b ? -1 : 1))
                            .filter(
                                (field) =>
                                    !this.defaultUncheckableFields.includes(
                                        field
                                    )
                            ),
                    ];
                },
                error: (error) => {
                    this.initError = _get(error, ['error', 'errors', 0]);
                    this.clientDataFieldsLoading = false;
                },
                complete: () => {
                    this.clientDataFieldsLoading = false;
                },
            });
        this.impactCategoriesLoading = true;
        this.clientDatabaseService
            .getImpactCategories(this.clientId)
            .subscribe({
                next: (payload) => {
                    this.selectedImpactSerials = [];
                    this.impactCategoriesLoading = false;
                    this.impactCategories = _get(payload, 'payload', []);
                    this.selectedImpactSerials = this.impactCategories.map(category => category.impactSerial);
                },
                error: (error) => {
                    this.initError = _get(error, ['error', 'errors', 0]);
                    this.impactCategoriesLoading = false;
                },
                complete: () => {
                    this.impactCategoriesLoading = false;
                },
            });

        this.factTableFieldsLoading = true;
        this.clientDatabaseService.getFactTableFields(this.clientId).subscribe({
            next: (payload) => {
                this.selectedFactTableFields = [];
                this.factTableFieldsLoading = false;
                this.factTableFields = _get(payload, 'payload', []);
                this.selectedFactTableFields = this.factTableFields.map(field => field);
            },
            error: (error) => {
                this.initError = _get(error, ['error', 'errors', 0]);
                this.factTableFieldsLoading = false;
            },
            complete: () => {
                this.factTableFieldsLoading = false;
            },
        });
        this.clientDatatableNamesLoading = true;
        this.clientDatabaseService
            .getClientDataTables(this.clientId)
            .subscribe({
                next: (payload) => {
                    this.clientDatatableNamesLoading = false;
                    this.clientDatatableNames = _sortBy(_get(payload, 'payload', []));
                },
                error: (error) => {
                    this.initError = _get(error, ['error', 'errors', 0]);
                    this.clientDatatableNamesLoading = false;
                },
                complete: () => {
                    this.clientDatatableNamesLoading = false;
                },
            });
    }

    onSubmit(overrideExistingReportingTables: boolean = false, useNewLcaEngine: boolean = false) {
        this.error = null;

        if (
            this.selectedTableTypeIds.includes(TableTypes.BREAKOUT_TABLE) &&
            !this.selectedImpactSerials.length
        ) {
            this.message.error('Please select at least 1 ImpactSerial');
            return;
        }

        this.saving = true;

        const data: ReportingTableInput = {
            tableSuffix: this.tableFormGroup.get('tableName').value,
            clientDataTable: this.tableFormGroup.get('clientDataTable').value,
            clientDataFields: this.selectedClientDataFields,
            factTableFields: this.selectedFactTableFields,
            includeFactTable: this.selectedTableTypeIds.includes(
                TableTypes.FACT_TABLE
            ),
            includeMaterialFlow: this.selectedTableTypeIds.includes(
                TableTypes.MATERIAL_FLOW_TABLE
            ),
            includeBreakoutTables: this.selectedTableTypeIds.includes(
                TableTypes.BREAKOUT_TABLE
            ),
            includeAssuranceTable: this.selectedTableTypeIds.includes(
                TableTypes.ASSURANCE_TABLE
            ),
            includedInSqlView: false,
            breakoutTablesImpactSerials: this.selectedImpactSerials,
            overrideExistingReportingTables,
            useNewLcaEngine
        };

        this.clientDatabaseService
            .postReportingTable(this.clientId, data)
            .subscribe({
                next: () => {
                    this.saving = false;
                    this.globalNotifications.informPipelinesTeamReminder();
                    this.formMode = false;
                    this.error = null;
                    this.breakoutError = null;
                    this.errorSteps.clear();
                },
                error: (error) => {
                    this.saving = false;

                    if (error.status === 409) {
                        this.error = _head(
                            _get(error, ['error', 'errors', 0]).split(' Do you')
                        );
                        this.modals.confirm({
                            nzTitle: 'Table name conflicts',
                            nzContent: _get(error, ['error', 'errors', 0]),
                            nzOnOk: () => {
                                this.onSubmit(true, useNewLcaEngine);
                            },
                            nzOnCancel: () => {
                                this.saving = false;
                            },
                            nzOkText: 'Yes, override',
                            nzOkType: 'primary',
                            nzOkDanger: true,
                            nzCancelText: 'Change table name',
                            nzClosable: false,
                        });
                    } else {
                        this.error = _get(error, ['error', 'errors', 0]);
                        this.message.error(this.error);
                    }
                },
                complete: () => {
                    this.saving = false;
                },
            });
    }

    reset() {
        this.formMode = false;
        this.error = null;
        this.breakoutError = null;
        this.errorSteps.clear();
    }

    onCancel() {
        if (this.tableFormGroup.dirty) {
            this.modals.confirm({
                nzTitle: 'Confirmation',
                nzContent: 'Are you sure you want to cancel? All unsaved progress will be lost',
                nzOkText: 'Yes, cancel',
                nzOnOk: () => this.reset(),
                nzOkDanger: true,
                nzCancelText: 'Close'
            })
        } else {
            this.reset();
        }
    }

    onPreviousClick() {
        this.createStepIndex -= 1;
    }

    private initTableForm() {
        this.tableFormGroup = this.fb.group(
            {
                tableName: ['', Validators.required],
                clientDataTable: ['', Validators.required],
                tableTypeIds: [[]],
                clientDataFields: [[]],
                factTableFields: [[]],
            },
            {
                validators: [
                    DbNaming('tableName', DB_NAMING_REGEXP_TYPES.SHORT),
                ],
            }
        );
    }

    onChangeClientDataFields(ids: string[] = []) {
        this.selectedClientDataFields = ids;
    }

    onChangeBreakoutTableImpactSerials(ids: number[] = []) {
        this.selectedImpactSerials = ids;
        if (
            this.selectedTableTypeIds.includes(TableTypes.BREAKOUT_TABLE) &&
            !this.selectedImpactSerials.length
        ) {
            this.breakoutError = 'Please select at least one ImpactSerial';
            this.errorSteps.add(this.selectedTableTypeIds.includes(this.tableTypesEnum.FACT_TABLE) ? 4 : 3);
        } else {
            this.errorSteps.delete(this.selectedTableTypeIds.includes(this.tableTypesEnum.FACT_TABLE) ? 4 : 3);
            this.breakoutError = null;
        }
    }

    onChangeFactTableFields(ids: string[] = []) {
        this.selectedFactTableFields = ids;
    }

    onChangeTableTypeIds(ids: string[] = []) {
        this.selectedTableTypeIds = ids;

        if (ids.includes(TableTypes.BREAKOUT_TABLE)) {
            this.onChangeBreakoutTableImpactSerials(
                this.impactCategories.map(({ impactSerial }) => impactSerial)
            );
        } else {
            this.onChangeBreakoutTableImpactSerials([]);
        }

        if (!ids.includes(TableTypes.FACT_TABLE)) {
            this.onChangeFactTableFields([]);
        }
    }

    isFieldIsDisabled(field: string) {
        return this.defaultUncheckableFields.includes(field);
    }

    get isClientFieldsAvailable(): boolean {
        return (
            this.selectedTableTypeIds.includes(TableTypes.BREAKOUT_TABLE) ||
            this.selectedTableTypeIds.includes(TableTypes.FACT_TABLE)
        );
    }

    get isFactFieldsAvailable(): boolean {
        return this.selectedTableTypeIds.includes(TableTypes.FACT_TABLE);
    }

    onCreateReportingTable() { 
        this.formMode = true;
        this.error = undefined;
        this.initError = undefined;
        this.createStepIndex = 0;
        this.init();
    }

    onNextClick() {
        switch(this.createStepIndex) {
            case 0:
                this.tableFormGroup.updateValueAndValidity();
                if (this.tableFormGroup.valid) {
                    this.errorSteps.delete(0);
                    this.createStepIndex++;
                } else {
                    this.errorSteps.add(0);
                }
                break;
            case this.finalStepIndex:
                this.onSubmit();
                break;
            default:
                this.createStepIndex++;
        }
    }

    get finalStepIndex() {
        let lastStepIndex = 2;

        if (this.selectedTableTypeIds.includes(this.tableTypesEnum.BREAKOUT_TABLE)) {
            lastStepIndex++;
        }

        if (this.selectedTableTypeIds.includes(this.tableTypesEnum.FACT_TABLE)) {
            lastStepIndex++;
        }

        return lastStepIndex;
    }
}
