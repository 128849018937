import { Action, createReducer, on } from '@ngrx/store';
import * as UserActions from '../../actions/user/user.actions';
import _get from 'lodash/get';
import _findIndex from 'lodash/findIndex';

import { CustomerUser } from '../../models/customer-user.model';

export const customerUsersFeatureKey = 'customerUsers';

interface EditCustomerUser {
    loading: boolean;
    customerUser: CustomerUser;
    error: Error;
}

export interface CustomerUsersState {
    loading: boolean;
    items: CustomerUser[];
    error: Error;
    edit: EditCustomerUser;
}

export const initialState: CustomerUsersState = {
    loading: false,
    items: null,
    error: null,
    edit: {
        loading: false,
        customerUser: null,
        error: null,
    },
};

const customerUserReducer = createReducer(
    initialState,
    on(UserActions.customerUsersListRequest, (state) => ({
        ...state,
        loading: true,
    })),
    on(UserActions.customerUsersListReceive, (state, { items }) => ({
        ...state,
        loading: false,
        items,
    })),
    on(UserActions.customerUsersFailed, (state, { error }) => ({
        ...state,
        loading: false,
        error: _get(error, ['error', 'errors', 0], null),
    })),
    on(UserActions.customerUserRequest, (state) => ({
        ...state,
        edit: {
            ...state.edit,
            loading: true,
        },
    })),
    on(UserActions.customerUserReceive, (state, { customerUser }) => ({
        ...state,
        edit: {
            customerUser,
            loading: false,
            error: null,
        },
    })),
    on(UserActions.updateCustomerUserRequest, (state) => ({
        ...state,
        edit: {
            ...state.edit,
            loading: true,
        },
    })),
    on(UserActions.updateCustomerUserReceive, (state, { user }) => {
        let items = _get(state, 'items', []);
        const index = _findIndex(items, { userId: user.userId });

        if (index >= 0) {
            items[index] = user;
        }

        return {
            ...state,
            items: [...items],
            edit: {
                customerUser: null,
                loading: false,
                error: null,
            },
        };
    }),
    on(UserActions.updateCustomerUserFailed, (state, { error }) => ({
        ...state,
        edit: {
            ...state.edit,
            loading: false,
            error: null,
        },
    })),
    on(UserActions.customerUserFailed, (state, { error }) => ({
        ...state,
        edit: {
            customerUser: null,
            loading: false,
            error: _get(error, ['error', 'errors', 0], null),
        },
    })),
    on(UserActions.createCustomerUserRequest, (state) => ({
        ...state,
        edit: {
            ...state.edit,
            loading: true,
        },
    })),
    on(UserActions.createCustomerUserReceive, (state) => ({
        ...state,
        edit: {
            ...state.edit,
            loading: false,
        },
    })),
    on(UserActions.createCustomerUserFailed, (state, { error }) => ({
        ...state,
        edit: {
            ...state.edit,
            error: _get(error, ['error', 'errors', 0], null),
        },
    }))
);

export function reducer(state: CustomerUsersState | undefined, action: Action) {
    return customerUserReducer(state, action);
}
