<nz-layout class="layout">
    <nz-sider
        [(nzCollapsed)]="isCollapsed"
        nzWidth="244px"
    >
        <div class="top-info">
            <div
                class="top-info__logo"
                [class.collapsed]="isCollapsed"
                routerLink="/"
            >
                <i
                    nz-icon
                    [nzType]="isCollapsed ? 'ai-logo-small' : 'ai-logo'"
                    nzTheme="fill"
                ></i>
            </div>
            <div
                class="top-info__collapse"
                (click)="isCollapsed = !isCollapsed"
            >
                <div class="collapse-control">
                    <i nz-icon [nzType]="isCollapsed ? 'arrow-right' : 'arrow-left'" nzTheme="outline"></i>
                </div>
            </div>
        </div>
        <ng-container>
            <ng-container *ngIf="!isCollapsed">
                <div *hasPermission="['AIUSER', 'AIADMIN', 'SUPERUSER']" style="width: 100%; padding: 0 0.25rem; margin-bottom: 1rem;">
                    <label class="menu-mode-label">Menu:</label>
                    <nz-segmented (ngModelChange)="onMenuModeChanged($event)" [ngModel]="menuModeIndex" [nzOptions]="menuModeOptions" [nzBlock]="true"></nz-segmented>
                </div>
            </ng-container>
            <ng-container *hasPermission="['AIUSER', 'AIADMIN', 'SUPERUSER']">
                <div class="navigation-client-selector" *ngIf="globalService.mode !== modes.Admin">
                    <app-client-selector [mini]="isCollapsed"></app-client-selector>
                </div>
            </ng-container>
        </ng-container>
        <nz-divider *ngIf="globalService.mode !== modes.Admin"></nz-divider>
        <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed" class="main-menu">
            <ng-container *ngIf="globalService.mode === modes.Admin">
                <li
                    nz-menu-item
                    *hasPermission="['CLIENT_MGMT', 'CLIENT_MGMT_VIEW']"
                >
                    <div
                        class="nav-container"
                        routerLinkActive="active"
                        routerLink="/clients"
                    >
                        <i
                            class="nav-icon"
                            nz-icon
                            nzType="subtract"
                            [nzTheme]="isNavActive('/clients') ? 'fill' : 'outline'"
                        ></i>
                        <div class="nav-title">Clients</div>
                    </div>
                </li>
                <li nz-submenu nzIcon="users" nzTitle="Internal Users" [(nzOpen)]="openMenuMap.internal" (nzOpenChange)="openMenuHandler('internal')" *hasPermission="['USER_MGMT', 'USER_MGMT_VIEW', 'ROLES_MGMT', 'APP_RESOURCE_MGMT', 'ROLES_MGMT_VIEW']">
                    <ul>
                    <li nz-menu-item *hasPermission="['USER_MGMT', 'USER_MGMT_VIEW']">
                            <div
                                class="nav-container"
                                routerLink="/admin/users"
                            >
                                <i
                                    class="nav-icon"
                                    nz-icon
                                    nzType="users"
                                    [nzTheme]="
                                        isNavActive('/admin/users')
                                            ? 'fill'
                                            : 'outline'
                                    "
                                ></i>
                                <div class="nav-title">Users</div>
                            </div>
                        </li>
                        <li
                            nz-menu-item
                            *hasPermission="[
                                'ROLES_MGMT',
                                'ROLES_MGMT_VIEW'
                            ]"
                        >
                            <div
                                class="nav-container"
                                routerLink="/admin/roles"
                            >
                                <i
                                    class="nav-icon"
                                    nz-icon
                                    nzType="roles"
                                    [nzTheme]="
                                        isNavActive('/admin/roles')
                                            ? 'fill'
                                            : 'outline'
                                    "
                                ></i>
                                <div class="nav-title">Roles</div>
                            </div>
                        </li>
                    </ul>
                </li>
                <li nz-submenu nzIcon="database" nzTitle="Internal Data Management" [(nzOpen)]="openMenuMap.internalData" (nzOpenChange)="openMenuHandler('internalData')" *hasPermission="['AI_DB_TOOL_MGMT', 'LCA_SANDBOX']">
                    <ul>
                        <li nz-menu-item *hasPermission="['AI_DB_TOOL_MGMT']">
                            <div
                                class="nav-container"
                                routerLink="/admin/database"
                            >
                                <i
                                    class="nav-icon"
                                    nz-icon
                                    nzType="database"
                                    [nzTheme]="isNavActive('/admin/database') ? 'fill' : 'outline'"
                                ></i>
                                <div class="nav-title">Database</div>
                            </div>
                        </li>
                        <li nz-menu-item *hasPermission="['AI_DB_TOOL_MGMT']">
                            <div
                                class="nav-container"
                                routerLink="/lca-engine/impact-coefficients"
                            >
                                <i
                                    class="nav-icon"
                                    nz-icon
                                    nzType="tool"
                                    [nzTheme]="
                                        isNavActive('/lca-engine/impact-coefficients') ? 'fill' : 'outline'
                                    "
                                ></i>
                                <div class="nav-title">Impact Coefficients</div>
                            </div>
                        </li>
                    </ul>
                </li>
                <li nz-menu-item *hasPermission="['LCA_SANDBOX']">
                    <div
                        class="nav-container"
                        routerLink="/lca-engine/sandbox"
                    >
                        <div nz-flex nzJustify="space-between" nzAlign="center" [style.width]="'100%'">
                            <div nz-flex [nzGap]="8" [style.width]="'100%'">
                                <i
                                    class="nav-icon"
                                    nz-icon
                                    nzType="lca"
                                    [nzTheme]="
                                        isNavActive('/lca-engine/sandbox') ? 'fill' : 'outline'
                                    "
                                ></i>
                                <div class="nav-title">LCA Sandbox</div>
                            </div>
                            <ng-container *ngIf="!isCollapsed">
                                <button *hasPermission="['SUPERUSER']" class="menu-settings" (click)="onOpenLCASandboxSettings($event)" nz-button nzType="default" nzSize="small">
                                    <span nz-icon nzType="setting"></span>
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </li>
                <li nz-menu-item *hasPermission="['SUPERUSER', 'AIADMIN']">
                    <div
                        class="nav-container"
                        routerLink="/process-history"
                    >
                        <div nz-flex nzJustify="space-between" nzAlign="center" [style.width]="'100%'">
                            <div nz-flex [nzGap]="8" [style.width]="'100%'">
                                <i
                                    class="nav-icon"
                                    nz-icon
                                    nzType="bulb"
                                    [nzTheme]="
                                        isNavActive('/process-history') ? 'fill' : 'outline'
                                    "
                                ></i>
                                <div class="nav-title">Process History</div>
                            </div>
                        </div>
                    </div>
                </li>
            </ng-container>
            <ng-container *ngIf="globalService.mode !== modes.Admin">
                <li [(nzOpen)]="openMenuMap.clients" (nzOpenChange)="openMenuHandler('clients')" *hasPermission="['ATTR_MGMT', 'ATTR_MGMT_VIEW', 'ROLES_MGMT', 'ROLES_MGMT_VIEW', 'USER_MGMT_VIEW', 'USER_MGMT']" nz-submenu nzTitle="Client Management" nzIcon="subtract">
                    <ul>
                        <li nz-menu-item *hasPermission="['USER_MGMT_VIEW', 'USER_MGMT']">
                            <div
                                class="nav-container"
                                routerLink="/users"
                            >
                                <i
                                    class="nav-icon"
                                    nz-icon
                                    nzType="users"
                                    [nzTheme]="
                                        isNavActive('/users')
                                            ? 'fill'
                                            : 'outline'
                                    "
                                ></i>
                                <div class="nav-title">Users</div>
                            </div>
                        </li>
                        <li nz-menu-item *hasPermission="['ATTR_MGMT', 'ATTR_MGMT_VIEW']">
                            <div
                                class="nav-container"
                                routerLink="/attributes"
                            >
                                <i
                                    class="nav-icon"
                                    nz-icon
                                    nzType="attributes"
                                    [nzTheme]="
                                        isNavActive('/attributes')
                                            ? 'fill'
                                            : 'outline'
                                    "
                                ></i>
                                <div class="nav-title">Attributes</div>
                            </div>
                        </li>
                        <li
                            nz-menu-item
                            *hasPermission="[
                                'ROLES_MGMT',
                                'ROLES_MGMT_VIEW'
                            ]"
                        >
                            <div
                                class="nav-container"
                                routerLink="/roles"
                            >
                                <i
                                    class="nav-icon"
                                    nz-icon
                                    nzType="roles"
                                    [nzTheme]="
                                        isNavActive('/roles')
                                            ? 'fill'
                                            : 'outline'
                                    "
                                ></i>
                                <div class="nav-title">Roles</div>
                            </div>
                        </li>
                    </ul>
                </li>
                <li nz-menu-item *hasPermission="['LTD_USER_ADMIN']">
                    <div
                        class="nav-container"
                        routerLink="/users"
                    >
                        <i
                            class="nav-icon"
                            nz-icon
                            nzType="users"
                            [nzTheme]="
                                isNavActive('/users')
                                    ? 'fill'
                                    : 'outline'
                            "
                        ></i>
                        <div class="nav-title">Users</div>
                    </div>
                </li>
                <li nz-menu-item *hasPermission="['REPORT_PORTAL']">
                    <div
                        class="nav-container"
                        routerLink="/reports"
                    >
                        <i
                            class="nav-icon"
                            nz-icon
                            nzType="reports"
                            [nzTheme]="isNavActive('/reports') ? 'fill' : 'outline'"
                        ></i>
                        <div class="nav-title">Reports</div>
                    </div>
                </li>
                <li nz-menu-item *hasPermission="['UPLOAD_PORTAL']">
                    <div
                        class="nav-container"
                        routerLink="/data"
                    >
                        <i
                            class="nav-icon"
                            nz-icon
                            nzType="portal-type"
                            [nzTheme]="isNavActive('/data') ? 'fill' : 'outline'"
                        ></i>
                        <div class="nav-title">Upload Portals</div>
                    </div>
                </li>
                <li [(nzOpen)]="openMenuMap.data" (nzOpenChange)="openMenuHandler('data')" *hasPermission="['CLIENT_DB_TOOL_MGMT', 'DATA_MAPPING_TOOL_USER', 'DATA_MAPPING_TOOL_MGMT', 'UPLOAD_PORTAL_MGMT', 'UPLOAD_PORTAL_MGMT_VIEW', 'REPORT_MGMT', 'REPORT_MGMT_VIEW', 'DATA_PIPELINE_MGMT', 'DATA_PIPELINE_MGMT_VIEW', 'DATA_PIPELINE_USER', 'DATA_PIPELINE_APPROVER']" nz-submenu nzTitle="Client Data Management" nzIcon="hdd">
                    <ul>
                        <li
                            nz-menu-item
                            *hasPermission="[
                                'UPLOAD_PORTAL_MGMT',
                                'UPLOAD_PORTAL_MGMT_VIEW'
                            ]"
                        >
                            <div
                                class="nav-container"
                                routerLink="/portal-types"
                            >
                                <i
                                    class="nav-icon"
                                    nz-icon
                                    nzType="portal-type"
                                    [nzTheme]="isNavActive('/portal-types')"
                                ></i>
                                <div class="nav-title">Upload Portals</div>
                            </div>
                        </li>
                        <li
                            nz-menu-item
                            *hasPermission="['REPORT_MGMT', 'REPORT_MGMT_VIEW']"
                        >
                            <div
                                class="nav-container"
                                routerLink="/manage-reports"
                            >
                                <i
                                    class="nav-icon"
                                    nz-icon
                                    nzType="reports"
                                    [nzTheme]="isNavActive('/manage-reports')
                                            ? 'fill'
                                            : 'outline'
                                    "
                                ></i>
                                <div class="nav-title">Reports</div>
                            </div>
                        </li>
                        <li nz-menu-item *hasPermission="['CLIENT_DB_TOOL_MGMT']">
                            <div
                                class="nav-container"
                                routerLink="/database"
                            >
                                <i
                                    class="nav-icon"
                                    nz-icon
                                    nzType="database"
                                    [nzTheme]="isNavActive('/database') ? 'fill' : 'outline'"
                                ></i>
                                <div class="nav-title">Client Database</div>
                            </div>
                        </li>
                        <li nz-menu-item *hasPermission="['CLIENT_DB_TOOL_MGMT']">
                            <div
                                class="nav-container"
                                routerLink="/database/generate-reporting-table"
                            >
                                <i
                                    class="nav-icon"
                                    nz-icon
                                    nzType="profile"
                                    [nzTheme]="isNavActive('/database/generate-reporting-table') ? 'fill' : 'outline'"
                                ></i>
                                <div class="nav-title">Reporting Tables Tool</div>
                            </div>
                        </li>
                        <li nz-menu-item *hasPermission="['CLIENT_DB_TOOL_MGMT']">
                            <div
                                class="nav-container"
                                routerLink="/lca-engine/client-database-spas"
                            >
                                <i
                                    class="nav-icon"
                                    nz-icon
                                    nzType="build"
                                    [nzTheme]="
                                        isNavActive('/lca-engine/client-database-spas') ? 'fill' : 'outline'
                                    "
                                ></i>
                                <div class="nav-title">SPA Tool</div>
                            </div>
                        </li>
                        <li nz-menu-item *hasPermission="['DATA_MAPPING_TOOL_USER', 'DATA_MAPPING_TOOL_MGMT']">
                            <div
                                class="nav-container"
                                routerLink="/data-mapping"
                            >
                                <i
                                    class="nav-icon"
                                    nz-icon
                                    nzType="highlight"
                                    [nzTheme]="
                                        isNavActive('/data-mapping') ? 'fill' : 'outline'
                                    "
                                ></i>
                                <div class="nav-title">Data Mapping</div>
                            </div>
                        </li>
                        <li nz-menu-item *hasPermission="['DATA_PIPELINE_MGMT', 'DATA_PIPELINE_MGMT_VIEW', 'DATA_PIPELINE_USER', 'DATA_PIPELINE_APPROVER']">
                            <div class="nav-container" [class.active]="isNavActive('/pipelines')" routerLink="/pipelines" queryParamsHandling="merge">
                                <i
                                    class="nav-icon"
                                    nz-icon
                                    nzType="play-circle"
                                    [nzTheme]="
                                        isNavActive('/pipelines') ? 'fill' : 'outline'
                                    "
                                ></i>
                                <div class="nav-title">Pipelines</div>
                            </div>
                        </li>
                    </ul>
                </li>
                <li
                    nz-menu-item
                    *hasPermission="[
                        'STRATEGY_MGMT',
                        'STRATEGY_APPROVER',
                        'STRATEGY_USER',
                        'STRATEGY_MODULE_VIEWER'
                    ]"
                >
                    <div
                        class="nav-container"
                        routerLink="/strategy"
                    >
                        <i
                            class="nav-icon"
                            nz-icon
                            nzType="snippets"
                            [nzTheme]="
                                isNavActive('/strategy') ? 'fill' : 'outline'
                            "
                        ></i>
                        <div class="nav-title">Strategy</div>
                    </div>
                </li>
                <li
                    nz-menu-item
                    *hasPermission="['ASSURANCE_MGMT', 'ASSURANCE_VIEW']"
                >
                    <div
                        class="nav-container"
                        routerLink="/assurance-report"
                    >
                        <i
                            class="nav-icon"
                            nz-icon
                            nzType="book"
                            [nzTheme]="
                                isNavActive('/assurance-report') ? 'fill' : 'outline'
                            "
                        ></i>
                        <div class="nav-title">Assurance</div>
                    </div>
                </li>
                <ng-container>
                    <li data-cy="procurement-menu-link" nz-submenu nzIcon="folder" nzTitle="Procurement" [(nzOpen)]="openMenuMap.procurement" (nzOpenChange)="openMenuHandler('procurement')" *hasPermission="['PROCUREMENT_ADMIN', 'PROCUREMENT_MGMT', 'PROCUREMENT_FP_INTEGRATION_USER', 'PROCUREMENT_USER']">
                        <ul>
                            <li data-cy="procurement-groups-link" nz-menu-item *hasPermission="['PROCUREMENT_ADMIN', 'PROCUREMENT_MGMT']">
                                <div
                                    class="nav-container"
                                    routerLink="/procurement/groups"
                                >
                                    <div nz-flex nzJustify="space-between" nzAlign="center" [style.width]="'100%'">
                                        <div nz-flex [nzGap]="8" [style.width]="'100%'">
                                            <i
                                                class="nav-icon"
                                                nz-icon
                                                nzType="users"
                                                [nzTheme]="
                                                    isNavActive('/procurement/groups') ? 'fill' : 'outline'
                                                "
                                            ></i>
                                            <div class="nav-title">Groups</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-cy="procurement-suppliers-link" nz-menu-item *hasPermission="['PROCUREMENT_USER', 'PROCUREMENT_ADMIN', 'PROCUREMENT_MGMT']">
                                <div
                                    class="nav-container"
                                    routerLink="/procurement/suppliers"
                                >
                                    <div nz-flex nzJustify="space-between" nzAlign="center" [style.width]="'100%'">
                                        <div nz-flex [nzGap]="8" [style.width]="'100%'">
                                            <i
                                                class="nav-icon"
                                                nz-icon
                                                nzType="shopping"
                                                [nzTheme]="
                                                    isNavActive('/procurement/suppliers') ? 'fill' : 'outline'
                                                "
                                            ></i>
                                            <div class="nav-title">Suppliers</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-cy="procurement-products-link" nz-menu-item *hasPermission="['PROCUREMENT_USER', 'PROCUREMENT_ADMIN', 'PROCUREMENT_MGMT']">
                                <div
                                    class="nav-container"
                                    routerLink="/procurement/products"
                                >
                                    <div nz-flex nzJustify="space-between" nzAlign="center" [style.width]="'100%'">
                                        <div nz-flex [nzGap]="8" [style.width]="'100%'">
                                            <i
                                                class="nav-icon"
                                                nz-icon
                                                nzType="appstore"
                                                [nzTheme]="
                                                    isNavActive('/procurement/products') ? 'fill' : 'outline'
                                                "
                                            ></i>
                                            <div class="nav-title">Products</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-cy="procurement-campaigns-link" nz-menu-item *hasPermission="['PROCUREMENT_USER', 'PROCUREMENT_ADMIN', 'PROCUREMENT_MGMT']">
                                <div
                                    class="nav-container"
                                    routerLink="/procurement/campaigns"
                                    [queryParams]="{ tab: 'Queued' }"
                                >
                                    <div nz-flex nzJustify="space-between" nzAlign="center" [style.width]="'100%'">
                                        <div nz-flex [nzGap]="8" [style.width]="'100%'">
                                            <i
                                                class="nav-icon"
                                                nz-icon
                                                nzType="notification"
                                                [nzTheme]="
                                                    isNavActive('/procurement/campaigns') ? 'fill' : 'outline'
                                                "
                                            ></i>
                                            <div class="nav-title">Engagement Campaigns</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-cy="procurement-fp-link" nz-menu-item *hasPermission="['PROCUREMENT_FP_INTEGRATION_USER', 'PROCUREMENT_ADMIN', 'PROCUREMENT_MGMT']">
                                <div
                                    class="nav-container"
                                    routerLink="/procurement/footprint"
                                >
                                    <div nz-flex nzJustify="space-between" nzAlign="center" [style.width]="'100%'">
                                        <div nz-flex [nzGap]="8" [style.width]="'100%'">
                                            <i
                                                class="nav-icon"
                                                nz-icon
                                                nzType="setting"
                                                [nzTheme]="
                                                    isNavActive('/procurement/footprint') ? 'fill' : 'outline'
                                                "
                                            ></i>
                                            <div class="nav-title">Footprint Configuration</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ng-container>
            </ng-container>
            <div class="help-menu">
                <li nz-menu-item> 
                    <div
                        class="nav-container"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}"
                        routerLink="/help"
                    >
                        <i
                            class="nav-icon"
                            nz-icon
                            nzType="question-circle"
                            [nzTheme]="isNavActive('/help') ? 'fill' : 'outline'"
                        ></i>
                        <div class="nav-title" *ngIf="!isCollapsed">Help</div>
                    </div>
                </li>
                <li *hasPermission="['AIUSER', 'AIADMIN', 'SUPERUSER']" nz-menu-item style="width: 100%; padding-right: 0px">
                    <div class="nav-container" queryParamsHandling="merge" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}" routerLink="/user-feedback">
                        <i class="nav-icon" nz-icon nzType="feedback" [nzTheme]="isNavActive('/user-feedback') ? 'fill' : 'outline'"></i>
                        <div class="nav-title" *ngIf="!isCollapsed">Feedback</div>
                    </div>
                </li>
            </div>
        </ul>
        <div
            data-cy="account-control"
            class="account"
            nz-dropdown
            [nzDropdownMenu]="menu"
            [nzOverlayStyle]="{ margin: '-2.5rem -3rem 0 11rem' }"
            [nzTrigger]="'click'"
        >
            <div
                class="account-picture"
                *ngIf="
                    (currentUser$ | async)?.clientLogoImg;
                    else clientLogoShortcut
                "
            >
                <img [src]="(currentUser$ | async)?.clientLogoImg" />
            </div>
            <ng-template #clientLogoShortcut>
                <div class="account-symbols">
                    {{ (currentUser$ | async)?.userLoginId | slice: 0 : 2 }}
                </div>
            </ng-template>
            <div
                *ngIf="!isCollapsed"
                data-cy="account-email"
                class="account-email"
            >
                <div>{{ (currentUser$ | async)?.userLoginId }}</div>
                <div class="account-email__client-name">
                    {{ (currentUser$ | async)?.clientName }}
                </div>
            </div>
        </div>

        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
                <li
                    data-cy="logout"
                    class="menu-action-item danger"
                    (click)="logout()"
                    nz-menu-item
                >
                    Logout
                </li>
            </ul>
        </nz-dropdown-menu>
    </nz-sider>
    <nz-layout>
        <nz-layout class="inner-layout">
            <nz-content>
                <router-outlet></router-outlet>
            </nz-content>
        </nz-layout>
    </nz-layout>
</nz-layout>

<ng-template #lcaSandboxReadyNotification>
    <h2>LCA Sandbox is ready</h2>
    <p>You can view the result</p>
    <button
        style="margin-top: 1.125rem"
        nz-button
        nzType="primary"
        routerLink="/lca-engine/sandbox"
    >
        View
    </button>
</ng-template>

<!-- LCA Sandbox Settings drawer -->
<nz-drawer
    nzMaskClosable
    [nzWidth]="460"
    [nzVisible]="lcaSettingsMode"
    nzTitle="LCA Sandbox Settings"
    (nzOnClose)="lcaSettingsMode = false"
>
    <ng-container *nzDrawerContent>
        <app-lca-settings-form
            *ngIf="lcaSettingsMode"
            (closed)="lcaSettingsMode = false"
        ></app-lca-settings-form>
    </ng-container>
</nz-drawer>