import { NgModule } from '@angular/core';
import { HasPermissionDirective } from './directives/has-permission/has-permission.directive';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { SafePipe } from './pipes/safe/safe.pipe';
import { HighlightPipe } from './pipes/highlight/highlight.pipe';
import { FileSizePipe } from './pipes/file-size/file-size.pipe';
import { ClientSelectorComponent } from './components/client-selector/client-selector.component';
import { ColorInputComponent } from './components/color-input/color-input.component';
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll.component';
import { TextSearchInputComponent } from './components/text-search-input/text-search-input.component';
import { AntModule } from '../modules/ant.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DecimalPipe } from '@angular/common';
import { TableRowsFilterComponent } from './components/table-rows-filter/table-rows-filter.component';
import { RoundPipe } from './pipes/round/round.pipe';
import { CurrencyFormatPipe } from './pipes/currency-format/currency-format.pipe';
import { NumberFormatComponent } from './components/number-format/number-format.component';
import { UploadPortalStatusComponent } from '../components/upload-portal-status/upload-portal-status.component';
import { NgxJsonViewerComponent, NgxJsonViewerModule } from 'ngx-json-viewer';
import { ProcessHistoryTableComponent } from './components/process-history-table/process-history-table.component';
import { ProcessHistoryFiltersComponent } from './components/process-history-filters/process-history-filters.component';
import { UploadFileTileComponent } from './components/upload-file-tile/upload-file-tile.component';

@NgModule({
    declarations: [
        HasPermissionDirective,
        SafePipe,
        HighlightPipe,
        FileSizePipe,
        CurrencyFormatPipe,
        RoundPipe,
        ClientSelectorComponent,
        ColorInputComponent,
        InfiniteScrollComponent,
        TextSearchInputComponent,
        TableRowsFilterComponent,
        NumberFormatComponent,
        UploadPortalStatusComponent,
        ProcessHistoryTableComponent,
        ProcessHistoryFiltersComponent,
        UploadFileTileComponent
    ],
    imports: [
        AntModule,
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        FormsModule,
        NgxJsonViewerModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    exports: [
        HasPermissionDirective,
        SafePipe,
        HighlightPipe,
        FileSizePipe,
        CurrencyFormatPipe,
        RoundPipe,
        ClientSelectorComponent,
        ColorInputComponent,
        InfiniteScrollComponent,
        TextSearchInputComponent,
        TableRowsFilterComponent,
        NumberFormatComponent,
        UploadPortalStatusComponent,
        NgxJsonViewerComponent,
        ProcessHistoryTableComponent,
        ProcessHistoryFiltersComponent,
        UploadFileTileComponent
    ],
})
export class CoreModule {}
