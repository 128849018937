<form nz-form [formGroup]="descrForm" data-cy="description-form">
    <div nz-row nzGutter="24">
        <nz-form-item style="width: 100%">
            <nz-form-label>Description</nz-form-label>
            <textarea
                [readonly]="true"
                rows="16"
                style="min-height: 14rem; width: 100%"
                nz-input
                >{{ description }}</textarea
            >
        </nz-form-item>
    </div>

    <nz-form-item>
        <nz-form-control class="drawer-actions-control" [nzSpan]="24">
            <div class="drawer-controls">
                <button
                    type="button"
                    nzSize="large"
                    nz-button
                    (click)="onCancel()"
                >
                    <span>Close</span>
                </button>
            </div>
        </nz-form-control>
    </nz-form-item>
</form>
