import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { mergeMap, first } from 'rxjs/operators';
import { AppState } from '../../store/state';
import { selectAuthToken } from '../../store/selectors/auth.selectors';
import { BrowserStorageService } from '../services/browser-storage/browser-storage.service';
import { AI_TOKEN_KEY, PROCUREMENT_TOKEN_KEY } from '../services/auth/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private store: Store<AppState>, private browserStorageService: BrowserStorageService) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const portalRoutes = [
            '/api/procurement/campaign/survey-submissions',
        ];

        if (portalRoutes.some(route => request.url.includes(route))) {
            return this.browserStorageService.getValue(PROCUREMENT_TOKEN_KEY)
                .pipe(
                    first(),
                    mergeMap(
                        token => {
                            if (token) {
                                request = request.clone({
                                    setHeaders: {
                                        Accept: `application/json`,
                                        Authorization: `Bearer ${token}`,
                                    },
                                });
                            }

                            return next.handle(request);
                        }
                    )
                )
        }

        return this.store.select(selectAuthToken).pipe(
            first(),
            mergeMap((token) => {
                if (request.url.includes('/api/') && token) {
                    request = request.clone({
                        setHeaders: {
                            Accept: `application/json`,
                            Authorization: `Bearer ${token}`,
                        },
                    });
                }

                return next.handle(request);
            })
        );
    }
}
