import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy,
    Input,
} from '@angular/core';
import _get from 'lodash/get';
import _first from 'lodash/first';

import { Customer } from '../../../store/models/customer.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Observer, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomerService } from '../../../core/services/customer/customer.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
    selector: 'app-client-form',
    templateUrl: './client-form.component.html',
    styleUrls: ['./client-form.component.less'],
})
export class ClientFormComponent implements OnInit, OnDestroy {
    customerForm: UntypedFormGroup;

    customer: Customer;
    customerLoading: boolean;

    //upload files
    fileList: NzUploadFile[] = [];

    clientSubmissionError: string;

    public ngDestroyed$ = new Subject();

    @Output() closed: EventEmitter<boolean>;
    @Input() clientId: string;

    constructor(
        private fb: UntypedFormBuilder,
        private customerService: CustomerService,
        private message: NzMessageService
    ) {
        this.closed = new EventEmitter<boolean>();
        // edit customer data
        this.customerForm = this.fb.group({
            clientName: ['', [Validators.required]],
            primaryContactName: ['', [Validators.required]],
            primaryContactEmail: ['', [Validators.required, Validators.email]],
            primaryContactPhone: ['', [Validators.required]],
            pbiWorkspaceId: ['', []],
            maxNumberOfUsers: ['', []],
        });
    }

    ngOnInit() {
        this.initClient();
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    onCancel() {
        this.ngDestroyed$.next(true);
        this.closed.emit(false);
        this.customerForm.reset();
    }

    private initClient() {
        if (this.clientId) {
            this.customerService
                .getCustomer(this.clientId)
                .pipe(takeUntil(this.ngDestroyed$))
                .subscribe((result) => {
                    this.customer = result.payload;
                    this.initCustomerForm(this.customer);
                });
        } else {
            this.initCustomerForm();
        }
    }

    initCustomerForm(customer?: Customer) {
        this.customerForm.patchValue({
            clientName: _get(customer, 'clientName'),
        });
        this.customerForm.patchValue({
            primaryContactName: _get(customer, 'primaryContactName'),
        });
        this.customerForm.patchValue({
            primaryContactEmail: _get(customer, 'primaryContactEmail'),
        });
        this.customerForm.patchValue({
            primaryContactPhone: _get(customer, 'primaryContactPhone'),
        });
        this.customerForm.patchValue({
            pbiWorkspaceId: _get(customer, 'pbiWorkspaceId'),
        });
        this.customerForm.patchValue({
            maxNumberOfUsers: _get(customer, 'maxNumberOfUsers'),
        });
    }

    async submitCustomerForm() {
        for (const key in this.customerForm.controls) {
            this.customerForm.controls[key].markAsDirty();
            this.customerForm.controls[key].updateValueAndValidity();
        }

        const logo =
            this.fileList && this.fileList.length
                ? _first(this.fileList)
                : undefined;

        const input = {
            clientName: _get(this.customerForm.value, 'clientName'),
            primaryContactEmail: _get(
                this.customerForm.value,
                'primaryContactEmail'
            ),
            primaryContactName: _get(
                this.customerForm.value,
                'primaryContactName'
            ),
            primaryContactPhone: _get(
                this.customerForm.value,
                'primaryContactPhone'
            ),
            maxNumberOfUsers: _get(this.customerForm.value, 'maxNumberOfUsers'),
            pbiWorkspaceId: _get(this.customerForm.value, 'pbiWorkspaceId'),
            logoImg: logo
                ? await this.toBase64(logo)
                : _get(this.customer, 'logoImg'),
        };

        this.customerLoading = true;

        if (!this.customer || !this.customer.clientId) {
            this.customerService
                .postCustomer(input)
                .pipe(takeUntil(this.ngDestroyed$))
                .subscribe({
                    next: (payload) => {
                        const customer = _get(payload, 'payload');
                        this.ngDestroyed$.next(true);
                        this.closed.emit(true);
                        this.fileList = [];
                        this.customerForm.reset();
                        this.message.success(
                            `Client <b>${customer.clientName}</b> has been created`,
                            { nzDuration: 4000 }
                        );
                    },
                    error: () => {
                        this.message.error(`Failed to save customer`);
                        this.customerLoading = false;
                    },
                    complete: () => {
                        this.customerLoading = false;
                    },
                });
        } else {
            this.customerService
                .putCustomer({ ...input, clientId: this.customer.clientId })
                .pipe(takeUntil(this.ngDestroyed$))
                .subscribe({
                    next: (payload) => {
                        const customer = _get(payload, 'payload');
                        this.ngDestroyed$.next(true);
                        this.closed.emit(false);
                        this.fileList = [];
                        this.customerForm.reset();
                        this.customerLoading = false;
                        this.message.success(
                            `Client <b>${customer.clientName}</b> has been updated`,
                            { nzDuration: 4000 }
                        );
                    },
                    error: (result) => {
                        this.customerLoading = false;
                        this.clientSubmissionError = result.error.message;
                    },
                    complete: () => {
                        this.customerLoading = false;
                    },
                });
        }
    }

    private toBase64(file: File): Promise<string | ArrayBuffer> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    beforeLogoUpload = (file: NzUploadFile) => {
        return new Observable((observer: Observer<boolean>) => {
            const isLt1M = file.size / 1024 / 1024 < 1;

            if (!isLt1M) {
                this.message.error('File should be smaller than 1MB!');
                observer.complete();
                return;
            }

            if (isLt1M) {
                this.fileList = [file];
            }

            if (this.fileList && this.fileList.length) {
                this.customerForm.markAsDirty();
            }

            // false for manual upload on form submit
            observer.next(isLt1M && false);
            observer.complete();
        });
    };
}
