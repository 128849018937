import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ReportGroup } from '../../store/models/report-group.model';

@Component({
    selector: 'app-report-group-item',
    templateUrl: './report-group-item.component.html',
    styleUrls: ['./report-group-item.component.less'],
})
export class ReportGroupItemComponent implements OnInit {
    @Input() reportGroup: ReportGroup;
    @Input() highlightQuery: string;
    @Output() edit: EventEmitter<any> = new EventEmitter();
    @Output() remove: EventEmitter<any> = new EventEmitter();
    @Output() groupId: string;
    constructor() {}

    ngOnInit() {}

    onEdit() {
        this.edit.emit(this.reportGroup.groupId);
    }

    onDelete() {
        this.remove.emit({
            groupId: this.reportGroup.groupId,
            groupName: this.reportGroup.groupName,
        });
    }
}
