import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-non-auth-layout',
  templateUrl: './non-auth-layout.component.html',
  styleUrl: './non-auth-layout.component.less'
})
export class NonAuthLayoutComponent implements OnInit {
  ngOnInit(): void {
    
  }
}
