import { createAction, props } from '@ngrx/store';
import * as CustomersActionTypes from './constants';
import { Customer } from '../../models/customer.model';
import { ClientRole } from '../../models/client-role.model';
import { CustomerInput } from '../../../core/services/customer/customer.service';

export const customersListRequest = createAction(
    CustomersActionTypes.CUSTOMERS_LIST_REQUEST
);

export const customersListReceive = createAction(
    CustomersActionTypes.CUSTOMERS_LIST_RECEIVE,
    props<{ items: Customer[] }>()
);

export const customersFailed = createAction(
    CustomersActionTypes.CUSTOMERS_LIST_FAILED,
    props<{ error: Error }>()
);

export const customerRequest = createAction(
    CustomersActionTypes.CUSTOMER_REQUEST,
    props<{ clientId: string }>()
);

export const customerReceive = createAction(
    CustomersActionTypes.CUSTOMER_RECEIVE,
    props<{ customer: Customer }>()
);

export const customerFailed = createAction(
    CustomersActionTypes.CUSTOMER_FAILED,
    props<{ error: Error }>()
);

export const createCustomerRequest = createAction(
    CustomersActionTypes.CREATE_CUSTOMER_REQUEST,
    props<{ input: CustomerInput }>()
);

export const createCustomerReceive = createAction(
    CustomersActionTypes.CREATE_CUSTOMER_RECEIVE,
    props<{ customer: Customer }>()
);

export const createCustomerFailed = createAction(
    CustomersActionTypes.CREATE_CUSTOMER_FAILED,
    props<{ error: Error }>()
);

export const updateCustomerRequest = createAction(
    CustomersActionTypes.UPDATE_CUSTOMER_REQUEST,
    props<{ input: CustomerInput }>()
);

export const updateCustomerReceive = createAction(
    CustomersActionTypes.UPDATE_CUSTOMER_RECEIVE,
    props<{ customer: Customer }>()
);

export const updateCustomerFailed = createAction(
    CustomersActionTypes.UPDATE_CUSTOMER_FAILED,
    props<{ error: Error }>()
);

// client roles
export const clientRolesRequest = createAction(
    CustomersActionTypes.CLIENT_ROLES_REQUEST,
    props<{ clientId: string }>()
);

export const clientRolesReceive = createAction(
    CustomersActionTypes.CLIENT_ROLES_RECEIVE,
    props<{ items: ClientRole[] }>()
);

export const clientRolesError = createAction(
    CustomersActionTypes.CLIENT_ROLES_FAILED,
    props<{ error: Error }>()
);
