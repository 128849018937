import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable, of } from 'rxjs';
import { BrowserStorageService } from '../browser-storage/browser-storage.service';
import { LoginType } from '../../../store/models/login-type';
import { User } from '../../../store/models/user.model';
import { HttpHeaders } from '@angular/common/http';

export const AI_TOKEN_KEY = 'ai_auth_tk';
export const PROCUREMENT_TOKEN_KEY = 'ai_procurement_auth_tk';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    appLoading: boolean = false;

    constructor(
        private apiService: ApiService,
        private browserStorageService: BrowserStorageService
    ) {}

    authenticate(
        userLoginId: string,
        step: number = 1,
        userPassword?: string
    ): Observable<any> {
        const data = {
            userLoginId,
            step,
        };

        if (userPassword) {
            data['userPassword'] = userPassword;
        }

        return this.apiService.post('authenticate', data);
    }

    getLocale() {
        return 'en-US';
    }

    authenticateSSO(authCode: string): Observable<any> {
        return this.apiService.post(`authenticate-sso`, { authCode });
    }

    getLoginTypes(): Observable<{ payload: LoginType[] }> {
        return this.apiService.get('login-types');
    }

    logout(): Observable<any> {
        return this.apiService.post('logout');
    }

    setToken(token: string): Observable<string> {
        return this.browserStorageService.setValue(AI_TOKEN_KEY, token);
    }

    removeToken(): void {
        return this.browserStorageService.removeValue(AI_TOKEN_KEY);
    }

    updateUserPassword(userId: string, userPassword: string): Observable<User> {
        return this.apiService.patch(`user/${userId}/password`, {
            userPassword,
        });
    }

    getUserProfile(token): Observable<{ payload: User }> {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', `Bearer ${token}`);

        return this.apiService.get(`user/profile`, {}, headers);
    }
}
