<nz-tabset>
    <nz-tab nzTitle="Reports">
        <ng-template nz-tab>
            <div class="reports-container">
                <div nz-flex nzAlign="center" nzJustify="space-between" class="reports__filter">
                    <div nz-flex nzAlign="center" nzGap="small">
                        <div class="reports-filter__search">
                            <app-text-search-input
                                (search)="onSearch($event)"
                            ></app-text-search-input>
                        </div>
                        <nz-select
                            nzAllowClear
                            nzShowSearch
                            [nzMaxTagCount]="1"
                            [nzMaxTagPlaceholder]="maxTagGroupsPlaceholder"
                            [(ngModel)]="searchFilter.selectedGroups"
                            (ngModelChange)="onFilterGroupsSelected($event)"
                            [nzDropdownStyle]="{ width: '17rem' }"
                            nzSize="large"
                            nzMode="multiple"
                            [nzPlaceHolder]="
                                searchFilter.selectedGroups?.length
                                    ? ''
                                    : 'Filter by Report Group'
                            "
                            [nzLoading]="reportGroupsLoading"
                            [nzOptionHeightPx]="37"
                        >
                            <nz-option
                                *ngFor="let group of reportGroups"
                                [nzValue]="group.groupId"
                                [nzLabel]="group.groupName"
                                nzCustomContent
                            >
                                <div class="group">
                                    <div
                                        class="group-color"
                                        [style.backgroundColor]="group.groupColor"
                                    ></div>
                                    <div class="group-name">
                                        {{ group.groupName }}
                                    </div>
                                </div>
                            </nz-option>
                        </nz-select>
                    </div>
                    <button
                        *hasPermission="['REPORT_MGMT']"
                        type="button"
                        nz-button
                        (click)="onCreateCustomerReport()"
                        nzType="primary"
                    >
                        <i nz-icon nzType="plus" nzTheme="outline"></i>
                        <span>Create new report</span>
                    </button>
                </div>
                <div class="reports__table">
                    <app-infinite-scroll
                        [loaded]="reportsLoaded"
                        [loading]="reportsLoading"
                        (scrolled)="onTableScrolled()"
                    >
                        <nz-table
                            #table
                            [nzLoading]="reportsLoading"
                            [nzSize]="'middle'"
                            [nzPageSize]="99999999"
                            nzHideOnSinglePage
                            [nzData]="reports"
                        >
                            <thead (nzSortOrderChange)="orderChange($event)">
                                <tr>
                                    <th
                                        *ngFor="let column of columns"
                                        [nzSortFn]="true"
                                        [nzWidth]="column.width"
                                        [nzSortDirections]="column.sortDirections"
                                        nzColumnKey="{{ column.title }}"
                                    >
                                        {{ column.title }}
                                    </th>
                                    <th nzWidth="15%">Report group</th>
                                    <th nzWidth="20%">Attributes</th>
                                    <th nzWidth="20%">Date Created</th>
                                    <th nzWidth="20%">Date Updated</th>
                                    <th nzWidth="10%"></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let data of table.data; let i = index"
                                    class="editable-row"
                                >
                                    <td>
                                        <div
                                            class="reports-table-cell__name"
                                            [innerHTML]="
                                                data.reportName
                                                    | highlight: query
                                                    | safe: 'html'
                                            "
                                        ></div>
                                    </td>
                                    <td>
                                        <div class="group">
                                            <div
                                                class="group-color"
                                                [style.backgroundColor]="data.reportGroup?.groupColor"
                                            ></div>
                                            {{ data.reportGroup?.groupName }}
                                        </div>
                                    </td>
                                    <td>
                                        <nz-tag
                                            *ngFor="let attribute of data?.attributes ?? []"
                                        >
                                            {{attribute.attrName}}
                                        </nz-tag>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="data.createdStamp">
                                            {{
                                                data.createdStamp
                                                    | date: 'dd MMM yyyy, h:mm a'
                                            }}
                                        </ng-container>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="data.updatedStamp">
                                            {{
                                                data.updatedStamp
                                                    | date: 'dd MMM yyyy, h:mm a'
                                            }}
                                        </ng-container>
                                    </td>
                                    <td>
                                        <button
                                            nz-button
                                            [routerLink]="'/reports/pbi/' + data.reportId"
                                            queryParamsHandling="merge"
                                        >
                                            View Report
                                        </button>
                                    </td>
                                    <td class="actions__cell">
                                        <i
                                            nz-icon
                                            nzType="dots"
                                            nzTheme="fill"
                                            *hasPermission="[
                                                'REPORT_MGMT',
                                                'REPORT_MGMT_VIEW'
                                            ]"
                                        ></i>
                                        <div
                                            class="table-actions-container"
                                            *hasPermission="[
                                                'REPORT_MGMT',
                                                'REPORT_MGMT_VIEW'
                                            ]"
                                        >
                                            <i
                                                *hasPermission="['REPORT_MGMT']"
                                                (click)="
                                                    onRemoveReport(
                                                        data.reportId,
                                                        data.reportName
                                                    )
                                                "
                                                nz-tooltip
                                                nzTooltipPlacement="top"
                                                nzTooltipTitle="Delete"
                                                nzTooltipOverlayClassName="danger-tooltip"
                                                class="action-icon delete"
                                                nz-icon
                                                nzType="delete"
                                                nzTheme="outline"
                                            ></i>
                                            <i
                                                *hasPermission="[
                                                    'REPORT_MGMT',
                                                    'REPORT_MGMT_VIEW'
                                                ]"
                                                (click)="onEditReport(data)"
                                                nz-tooltip
                                                nzTooltipPlacement="top"
                                                nzTooltipTitle="Edit"
                                                nzTooltipOverlayClassName="primary-tooltip"
                                                class="action-icon"
                                                nz-icon
                                                nzType="edit"
                                                nzTheme="outline"
                                            ></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </app-infinite-scroll>
                </div>
                <nz-drawer
                    nzMaskClosable
                    [nzWidth]="360"
                    [nzVisible]="reportFormMode"
                    [nzTitle]="
                        searchFilter.selectedReportId ? 'Edit report' : 'Create new report'
                    "
                    (nzOnClose)="closeEditReport()"
                >
                    <ng-container *nzDrawerContent>
                        <app-report-form
                            [reportId]="this.selectedReport?.reportId"
                            [clientAttributeTypes]="this.attributeTypes"
                            *ngIf="reportFormMode"
                            (closed)="closeEditReport($event)"
                            [clientId]="selectedClientId"
                        ></app-report-form>
                    </ng-container>
                </nz-drawer>
            </div>
        </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="reportGroupsTab">
        <app-report-groups-page (deleted)="onReportGroupDeleted()"></app-report-groups-page>
    </nz-tab>
    <ng-template #reportGroupsTab>
        <div data-cy="report-groups-tab">Report Groups</div>
    </ng-template>
</nz-tabset>

<ng-template #maxTagGroupsPlaceholder let-selectedList>
    +{{ selectedList.length }}
</ng-template>