import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _set from 'lodash/set';
import _first from 'lodash/first';
import { ReportGroup } from '../../../store/models/report-group.model';
import { reportGroupReceive } from '../../../store/actions/report/report.actions';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ReportGroupService } from '../../../core/services/report-group/report-group.service';
import { takeUntil } from 'rxjs/operators';
import { TextSearchInputComponent } from 'src/app/core/components/text-search-input/text-search-input.component';
import { GlobalService } from 'src/app/core/services/global/global.service';

@Component({
    selector: 'app-report-groups-page',
    templateUrl: './report-groups-page.component.html',
    styleUrls: ['./report-groups-page.component.less'],
})
export class ReportGroupsPageComponent implements OnInit, OnDestroy {
    reportGroup: ReportGroup;
    reportGroups: ReportGroup[];
    nonFilteredReportGroups: ReportGroup[];
    reportGroupsLoading: boolean = true;

    selectedClientId: string;
    query: string = '';
    @ViewChild(TextSearchInputComponent) child: TextSearchInputComponent;
    // edit customer user data
    reportGroupFormMode: boolean = false;

    @Output() deleted: EventEmitter<any> = new EventEmitter();

    public ngDestroyed$ = new Subject();

    constructor(
        private store: Store<AppState>,
        private modal: NzModalService,
        private messages: NzMessageService,
        private reportGroupsService: ReportGroupService,
        private globalService: GlobalService
    ) {}

    ngOnInit() {
        this.globalService.clientId$.pipe(takeUntil(this.ngDestroyed$))
            .subscribe(
                clientId => {
                    if (clientId) {
                        this.selectedClientId = clientId;
                        this.fetchReportGroups();
                    }
                }
            )
    }

    fetchReportGroups() {
        this.reportGroupsLoading = true;
        this.reportGroupsService
            .getReportGroups(this.selectedClientId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    this.reportGroups = _get(payload, 'payload');
                    this.nonFilteredReportGroups = this.reportGroups;
                },
                complete: () => {
                    this.reportGroupsLoading = false;
                },
            });
    }

    onEditReportGroup(reportGroupId: string) {
        this.reportGroupsService
            .getReportGroupById(reportGroupId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe({
                next: (payload) => {
                    this.reportGroup = _get(payload, 'payload');
                },
                complete: () => {
                    this.reportGroupFormMode = true;
                },
            });
    }

    onCreateReportGroup() {
        this.reportGroup = null;
        this.reportGroupFormMode = true;
    }

    closeReportGroupFormMode(withReload?: boolean) {
        this.store.dispatch(reportGroupReceive({ reportGroup: null }));
        this.reportGroupFormMode = false;
        if (withReload) {
            this.query = '';
            this.child.onClear();
            this.fetchReportGroups();
        }
    }

    onDeleteReportGroup({
        groupId,
        groupName,
    }: {
        groupId: string;
        groupName: string;
    }) {
        this.modal.confirm({
            nzTitle: `Do you want to remove report group <b>${groupName}</b>?`,
            nzContent: `
        Deleting report group will also delete all reports in the group. This action cannot be undone. Do you want to continue?
      `,
            nzOnOk: () => {
                this.reportGroupsService
                    .deleteReportGroup(groupId)
                    .subscribe(() => {
                        this.messages.success(
                            `Report group <b>${groupName}</b> has been deleted`,
                            { nzDuration: 4000 }
                        );
                        this.deleted.emit(true);
                        this.reportGroups = this.reportGroups.filter(group => group.groupId !== groupId);
                    });
            },
            nzOkText: 'Yes, remove',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzCancelText: 'Close',
            nzClosable: false,
            nzOnCancel: () => console.log('Cancel'),
        });
    }

    onSearch(value: string) {
        this.query = value;
        if (!value) {
            this.reportGroups = this.nonFilteredReportGroups;
        } else {
            this.reportGroups = this.nonFilteredReportGroups.filter(
                (reportGroup) =>
                    reportGroup.groupName
                        .toLowerCase()
                        .includes(this.query.toLowerCase())
            );
        }
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }
}
