import { Action, createReducer, on } from '@ngrx/store';
import * as AuthActions from '../../actions/auth/auth.actions';
import _get from 'lodash/get';
import { User } from '../../models/user.model';
import { LoginType } from '../../models/login-type';

export const authFeatureKey = 'auth';

export interface AuthState {
    loading: boolean;
    user: User;
    userLoginId: string;
    roles: string[];
    token: string;
    remember: boolean;
    error: string;
    step: number;
    waitingRedirectSSO: boolean;
    loginTypes: LoginType[];
    sso: string | undefined;
}

export const initialState: AuthState = {
    loading: false,
    user: null,
    userLoginId: null,
    roles: [],
    token: null,
    remember: true,
    error: null,
    step: 0,
    waitingRedirectSSO: false,
    loginTypes: [],
    sso: undefined,
};

const authReducer = createReducer(
    initialState,
    on(AuthActions.authLoginRequest, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(
        AuthActions.authLoginReceive,
        (
            state,
            {
                user,
                userLoginId,
                token,
                roles = [],
                ssoUrl,
                remember = initialState.remember,
                step,
            }
        ) => ({
            ...state,
            loading: false,
            waitingRedirectSSO: !!ssoUrl,
            user: {
                ..._get(state, 'user', {}),
                ...(user || {}),
            },
            userLoginId,
            roles,
            token,
            step: step - 1,
            remember,
        })
    ),
    on(AuthActions.authLoginFailed, (state, { error }) => {
        return {
            ...state,
            loading: false,
            sso: _get(error, 'error.sso', undefined),
            error: _get(error, ['error', 'errors', 0], null),
        };
    }),
    on(AuthActions.authenticateSSORequest, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(
        AuthActions.authenticateSSOReceive,
        (
            state,
            {
                user,
                userLoginId,
                token,
                roles,
                remember = initialState.remember,
            }
        ) => ({
            ...state,
            user: {
                ..._get(state, 'user', {}),
                ...(user || {}),
            },
            userLoginId,
            roles,
            token,
            loading: false,
            remember,
        })
    ),
    on(AuthActions.authenticateSSOFailed, (state, { error }) => {
        return {
            ...state,
            loading: false,
            sso: _get(error, 'error.sso', undefined),
            error: _get(error, ['error', 'errors', 0], null),
        };
    }),
    on(AuthActions.authLogoutRequest, (state) => ({
        ...initialState,
        token: state.token,
    })),
    on(AuthActions.loginTypesReceive, (state, { items }) => ({
        ...state,
        loginTypes: items,
    })),
    on(AuthActions.switchAccountRequest, () => initialState)
);

export function reducer(state: AuthState | undefined, action: Action) {
    return authReducer(state, action);
}
