import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    ClientDatabaseColumn,
    DatabaseSchema,
} from '../../../store/models/client-database-table.model';
import { ApiService } from '../api/api.service';

export interface AppendDataInput {
    dataFile: any;
    notes: string;
}

export interface UpdateDataInput {
    dataFile: any;
    notes: string;
}

export interface ColumnInput {
    columnName: string;
    dataType: string;
    nullable?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class AiDatabaseService {
    constructor(private api: ApiService) {}

    getSchemas(clientId: string): Observable<{ payload: DatabaseSchema[] }> {
        return this.api.get(`client-schema/${clientId}`);
    }

    appendDataToTable(
        tableName: string,
        input: AppendDataInput
    ): Observable<any> {
        const formData = new FormData();

        formData.append('notes', input.notes);
        formData.append('dataFile', input.dataFile);

        return this.api.postFormData(
            `client-schema/tables/${tableName}/data`,
            formData
        );
    }

    updateDataToTable(
        tableName: string,
        input: AppendDataInput
    ): Observable<any> {
        const formData = new FormData();

        formData.append('notes', input.notes);
        formData.append('dataFile', input.dataFile);

        return this.api.putFormData(
            `client-schema/tables/${tableName}/data`,
            formData
        );
    }

    getDatabaseDataTypes(): Observable<{ payload: string[] }> {
        return this.api.get(`db-tool/data-types`);
    }

    getTableColumns(
        tableName: string
    ): Observable<{ payload: ClientDatabaseColumn[] }> {
        return this.api.get(`client-schema/tables/${tableName}/columns`);
    }

    postTableColumn(
        tableName: string,
        input: ColumnInput
    ): Observable<{ payload: ClientDatabaseColumn[]; rowsCount: number }> {
        return this.api.post(
            `client-schema/tables/${tableName}/columns`,
            input
        );
    }

    deleteTableColumn(tableName: string, columnName: string): Observable<any> {
        return this.api.delete(
            `client-schema/tables/${tableName}/columns/${columnName}`
        );
    }
}
