import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnDestroy,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { LcaService } from 'src/app/core/services/lca/lca.service';
import { UploadProgressService } from 'src/app/core/services/upload-progress/upload-progress.service';
import _get from 'lodash/get';
import _find from 'lodash/find';
import { Subject } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';

export enum CSVFileKeys {
    ACTIVITY_MASTER_ENTRIES = 'activityMasterEntriesFile',
    A_VECTOR = 'aVectorFile',
    B_VECTOR = 'bVectorFile',
}

const CSV_FILE_LABELS = {
    [CSVFileKeys.ACTIVITY_MASTER_ENTRIES]: 'ActivityMaster Entries',
    [CSVFileKeys.A_VECTOR]: 'A Vector',
    [CSVFileKeys.B_VECTOR]: 'B Vector',
};

@Component({
    selector: 'app-lca-model-data-csv-form',
    templateUrl: './lca-model-data-csv-form.component.html',
    styleUrls: ['./lca-model-data-csv-form.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LcaModelDataCsvFormComponent implements OnInit, OnDestroy {
    @Output() closed: EventEmitter<boolean> = new EventEmitter();
    @Input() model: any;

    fileLabels = CSV_FILE_LABELS;

    fileList: { type: CSVFileKeys; file: any }[] = [];

    started: boolean = false;
    completed: boolean = false;
    cancelled: boolean = false;
    progress: number = 0;

    csvFilesForm: UntypedFormGroup;

    uploadKey: string;

    error: string;

    destroyed$ = new Subject();

    constructor(
        private fb: UntypedFormBuilder,
        private lcaService: LcaService,
        private uploadProgressService: UploadProgressService,
        private cdr: ChangeDetectorRef,
        private modal: NzModalService,
        private message: NzMessageService
    ) {
        this.initCSVFilesForm();
    }

    progressByUploadKey: Map<
        string,
        {
            started: boolean;
            cancelled: boolean;
            completed: boolean;
            progress: number;
        }
    > = new Map();

    ngOnInit() {}

    ngOnDestroy() {
        this.destroyed$.next(true);
    }

    onSave() {
        this.uploadKey = `UPLOADING_MODEL_DATA_CSV_${this.model.modelName}`;
        this.error = null;

        this.uploadProgressService.reset(this.uploadKey);

        this.uploadProgressService.upload$
            .pipe(
                takeUntil(this.destroyed$),
                filter((uploadInfo) => this.uploadKey === uploadInfo.key)
            )
            .subscribe(({ progress, started, cancelled, completed }) => {
                this.started = started;
                this.cancelled = cancelled;
                this.completed = completed;
                this.progress = progress;

                this.cdr.detectChanges();
            });

        this.lcaService
            .uploadModelDataFromCSV(this.model.modelName, this.fileList)
            .pipe(
                takeUntil(
                    this.uploadProgressService.getCloseSubscriptionByKey(
                        this.uploadKey
                    )
                ),
                tap((event: HttpEvent<any>) => {
                    switch (event.type) {
                        case HttpEventType.Sent:
                            this.uploadProgressService.start(this.uploadKey);
                            break;
                        case HttpEventType.UploadProgress:
                            this.uploadProgressService.calculateProgress(
                                this.uploadKey,
                                event.loaded,
                                event.total
                            );
                            break;
                        case HttpEventType.Response:
                            this.closed.emit(true);
                            this.uploadProgressService.complete(this.uploadKey);
                            break;
                    }
                })
            )
            .subscribe({
                error: (error) => {
                    this.error = _get(error, ['error', 'message'], null);
                    this.uploadProgressService.complete(this.uploadKey);
                    this.cdr.detectChanges();
                },
            });
    }

    onClose() {
        if (this.started && !this.cancelled && !this.completed) {
            this.modal.confirm({
                nzTitle: `Do you want to stop the uploading process?`,
                nzContent: `
      Do you want to cancel the upload process? Upload progress will be lost. 
    `,
                nzOnOk: () => {
                    this.uploadProgressService.cancel(this.uploadKey);
                    this.closed.emit(false);
                },
                nzOkText: 'Yes, cancel',
                nzOkType: 'primary',
                nzOkDanger: true,
                nzCancelText: 'Continue upload',
                nzClosable: false,
                nzOnCancel: () => {},
            });
        } else {
            this.closed.emit(false);
        }
    }

    private initCSVFilesForm() {
        this.csvFilesForm = this.fb.group({
            files: this.fb.array([
                this.fb.group({
                    type: [CSVFileKeys.ACTIVITY_MASTER_ENTRIES],
                    fileName: ['', Validators.required],
                }),
                this.fb.group({
                    type: [CSVFileKeys.A_VECTOR],
                    fileName: ['', Validators.required],
                }),
                this.fb.group({
                    type: [CSVFileKeys.B_VECTOR],
                    fileName: ['', Validators.required],
                }),
            ]),
        });
    }

    get files(): UntypedFormArray {
        return this.csvFilesForm.get('files') as UntypedFormArray;
    }

    get areProvidedFilesValid(): boolean {
        //Activity master file is always required
        const isActivityMasterExists = _find(this.fileList, {
            type: CSVFileKeys.ACTIVITY_MASTER_ENTRIES,
        });
        const isAVectorExists = _find(this.fileList, {
            type: CSVFileKeys.A_VECTOR,
        });
        const isBVectorExists = _find(this.fileList, {
            type: CSVFileKeys.B_VECTOR,
        });

        return isActivityMasterExists && (isAVectorExists || isBVectorExists);
    }

    beforeFileUpload(type: CSVFileKeys) {
        return (file: NzUploadFile) => {
            if (!this.isValidMimeType(file.type)) {
                this.message.error('Only .csv file types are allowed!', {
                    nzDuration: 5000,
                });
                return false;
            } else {
                (this.csvFilesForm.get('files') as UntypedFormArray).controls.map(
                    (control) => {
                        if (control.get('type').value === type) {
                            control.patchValue({ fileName: file.name });
                        }
                    }
                );
                this.fileList = this.fileList.filter(
                    ({ type: fileType }) => fileType !== type
                );
                this.fileList.push({ type, file });
                this.cdr.detectChanges();
                // false for manual upload on form submit

                return false;
            }
        };
    }
    private isValidMimeType(type: string): boolean {
        return type.includes('text/csv');
    }
    getFileByType(fileType: CSVFileKeys) {
        const file = _get(
            this.fileList.filter(({ type }) => fileType === type),
            [0, 'file']
        );
        return file ? [file] : [];
    }

    onFileRemove(type: CSVFileKeys) {
        (this.csvFilesForm.get('files') as UntypedFormArray).controls.map(
            (control) => {
                if (control.get('type').value === type) {
                    control.patchValue({ fileName: undefined });
                }
                this.fileList = this.fileList.filter(
                    ({ type: fileType }) => fileType !== type
                );
            }
        );
        this.cdr.detectChanges();
    }

    get warnMessage(): string {
        const enabledOpts = [];
        if (this.model.elemFlowEnabled) {
            enabledOpts.push('ElemFlow');
        }
        if (this.model.techFlowEnabled) {
            enabledOpts.push('TechFlow');
        }

        return enabledOpts && enabledOpts.length > 0
            ? `${enabledOpts.join(
                  ', '
              )} enabled, provide 5 or less ActivitySerials for review. This options will significantly increase runtimes and data weight.`
            : null;
    }
}
