import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable, of, Subject } from 'rxjs';
import { Role } from '../../../store/models/role.model';
import { Permission } from '../../../store/models/permission.model';

export interface RoleInput {
    roleId?: string;
    roleName: string;
    clientId?: string;
    appResourceIds: string[];
    isAdmin?: boolean;
}
export interface RolesFilter {
    searchText?: string;
    lastRoleId?: string;
    lastRoleName?: string;
    columnName?: string;
    sortDirection?: string;
    limit?: number;
}
@Injectable({
    providedIn: 'root',
})
export class CoreService {
    onRolesChanged$ = new Subject();

    constructor(private apiService: ApiService) {}

    getRoles(
        clientId?: string,
        filter?: RolesFilter
    ): Observable<{ payload: Role[] }> {
        const params = {};
        if (filter) {
            if (filter.searchText) {
                params['searchText'] = filter.searchText;
            }
            if (filter.lastRoleId) {
                params['lastRoleId'] = filter.lastRoleId;
            }
            if (filter.lastRoleName) {
                params['lastRoleName'] = filter.lastRoleName;
            }
            if (filter.columnName) {
                params['sortByColumnName'] = filter.columnName;
            }
            if (filter.sortDirection) {
                params['sortDirection'] = filter.sortDirection;
            }
            if (filter.limit) {
                params['limit'] = filter.limit;
            }
        }
        return this.apiService.get('roles', { clientId, ...params });
    }

    getPermissions(clientId: string): Observable<{ payload: Permission[] }> {
        return this.apiService.get('app-resources', { clientId });
    }

    updateProfile(
        userFirstName: string,
        userLastName: string
    ): Observable<any> {
        return this.apiService.patch('user', { userFirstName, userLastName });
    }

    getProfile(): Observable<any> {
        return this.apiService.get('user/profile');
    }

    updateRole(input: RoleInput): Observable<{ payload: Role }> {
        return this.apiService.put(`role`, input);
    }

    createRole(input: RoleInput): Observable<{ payload: Role }> {
        return this.apiService.post(`role`, input);
    }

    deleteRole(roleId: string): Observable<any> {
        return this.apiService.delete(`role/${roleId}`);
    }

    saveZIPResponse(fileName: string, data: any) {
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style.cssText = 'display:none;';
        const blob = new Blob([data], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }
}
