export enum FilterQueryParam {
    ReportingCategory = 'reportingCategories',
    Location = 'locations',
    ActivityUnit = 'activityUnits',
    ImpactAssessmentMethodology = 'impactSerial',
    Site = 'sites',
    InventoryItems = 'inventoryItemIds',
    EnergyAccountingType = 'energyAccountingType',
    ObservationID = 'observationID',
    DataSource = 'dataSourceNames',
    ModelName = 'modelNames',
    Date = 'date',
    ReportYear = 'reportYear',
    Grouping = 'groupings'
}

export type AssuranceReportFilter = {
    [FilterQueryParam.ReportYear]?: number;
    [FilterQueryParam.ReportingCategory]?: string[];
    [FilterQueryParam.Grouping]?: string[];
    [FilterQueryParam.ImpactAssessmentMethodology]?: number;
    [FilterQueryParam.ActivityUnit]?: string[];
    [FilterQueryParam.ModelName]?: string[];
    [FilterQueryParam.ObservationID]?: number;
    [FilterQueryParam.DataSource]?: string[];
    [FilterQueryParam.Date]?: Date[];
    locations?: string[];
    [FilterQueryParam.Site]?: string[];
    inventoryItems?: string[];
    [FilterQueryParam.EnergyAccountingType]?: EnergyAccountingTypes;
    lastClientDataID?: number;
    sortByColumnName?: string;
    sortDirection?: 'descend' | 'ascend' | null;
    limit?: number;
}

export type AssuranceReportItem = {
    accountingMultiplier: number;
    activityName: string;
    clientDataID: number;
    dataSourceName: string;
    impactCoefficient: number;
    impactCoefficientUnit: string;
    locationName: string;
    modelActivityAmount: number;
    modelName: string;
    modelFileLineReference: number;
    observationID: number;
    reportedActivityAmount: number;
    reportedActivityUnit: string;
    siteName: string;
    unitConversionFactor: string;
    includeAnalysis: boolean;
    reportYear: number;
    dataStartDate: number;
    dataEndDate: number;
    impactAssessmentMethod: string;
    source: string;
    reportingCategory: string;
    totalImpactAmount: number;
    impactUnit: string;
    totalImpactPercentage: number;
    methodology: string;
    activityDescription: string;
    percentOfTotalImpact?: number;
}

export type AssuranceReportItemKeys = keyof AssuranceReportItem;

export enum EnergyAccountingTypes {
    Location = 'Location',
    Market = 'Market'
}

type LocationNode = {
    title: string;
    value: string;
    key: string;
    isLeaf: boolean;
    children: LocationNode[];
}

export type AssuranceTableFilterValues<T extends FilterQueryParam = FilterQueryParam> =
    T extends FilterQueryParam.ActivityUnit
    ? string[]
    : T extends FilterQueryParam.ReportingCategory
    ? string[]
    : T extends FilterQueryParam.Site
    ? LocationNode[]
    : T extends FilterQueryParam.ReportYear
    ? string[]
    : T extends FilterQueryParam.DataSource
    ? string[]
    : T extends FilterQueryParam.ModelName
    ? string[]
    : T extends FilterQueryParam.ImpactAssessmentMethodology
    ? { label: string, value: number }[]
    : any[];

export type AssuranceReportConfigResultColumns = {
    tableName: string;
    columns: {columnName: string; columnLabel: string}[]
}

export type AssuranceReportConfigYear = {
    reportYear: number;
    assuranceTableName: string;
    resultColumns: AssuranceReportConfigResultColumns[]
}

export type AssuranceReportConfig = AssuranceReportConfigYear[]

export type AssuranceTotals = {
    reportedActivityAmountSum: number;
    modelActivityAmountSum: number;
    totalImpactAmountSum: number;
}