import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '../../../../store/state';
import { Store } from '@ngrx/store';
import { authenticateSSORequest } from '../../../../store/actions/auth/auth.actions';
import { Observable } from 'rxjs';
import {
    selectAuthLoading,
    selectAuthError,
} from '../../../../store/selectors/auth.selectors';

@Component({
    selector: 'app-mcdonalds',
    templateUrl: './mcdonalds.component.html',
    styleUrls: ['./mcdonalds.component.less'],
})
export class McdonaldsComponent implements OnInit {
    loading$: Observable<boolean>;
    error$: Observable<string>;

    constructor(
        private route: ActivatedRoute,
        private state: Store<AppState>
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            const { code, state } = params;
            if (code) {
                this.state.dispatch(authenticateSSORequest({ code, state }));
                this.loading$ = this.state.select(selectAuthLoading);
                this.error$ = this.state.select(selectAuthError);
            }
        });
    }
}
