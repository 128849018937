export const REPORTS_LIST_REQUEST = 'Reports list request';
export const REPORTS_LIST_RECEIVE = 'Reports list receive';
export const REPORTS_LIST_FAILED = 'Reports list failed';

export const REPORT_REQUEST = 'Report request';
export const REPORT_RECEIVE = 'Report receive';
export const REPORT_FAILED = 'Report failed';

export const CREATE_REPORT_FOR_CLIENT_REQUEST =
    'Create report for client request';
export const CREATE_REPORT_FOR_CLIENT_RECEIVE =
    'Create report for client receive';
export const CREATE_REPORT_FOR_CLIENT_FAILED =
    'Create report for client failed';

export const UPDATE_REPORT_FOR_CLIENT_REQUEST =
    'Update report for client request';
export const UPDATE_REPORT_FOR_CLIENT_RECEIVE =
    'Update report for client receive';
export const UPDATE_REPORT_FOR_CLIENT_FAILED =
    'Update report for client failed';

export const CREATE_REPORT_GROUP_REQUEST = 'Create report group request';
export const CREATE_REPORT_GROUP_RECEIVE = 'Create report group receive';
export const CREATE_REPORT_GROUP_FAILED = 'Create report group failed';

export const UPDATE_REPORT_GROUP_REQUEST = 'Update report group request';
export const UPDATE_REPORT_GROUP_RECEIVE = 'Update report group receive';
export const UPDATE_REPORT_GROUP_FAILED = 'Update report group failed';

export const UPDATE_REPORT_REQUEST = 'Update report request';
export const UPDATE_REPORT_RECEIVE = 'Update report receive';
export const UPDATE_REPORT_FAILED = 'Update report failed';

export const DELETE_REPORT_REQUEST = 'Delete report request';
export const DELETE_REPORT_RECEIVE = 'Delete report receive';
export const DELETE_REPORT_FAILED = 'Delete report failed';

export const DELETE_REPORT_GROUP_REQUEST = 'Delete report group request';
export const DELETE_REPORT_GROUP_RECEIVE = 'Delete report group receive';
export const DELETE_REPORT_GROUP_FAILED = 'Delete report group failed';

export const REPORT_GROUPS_LIST_REQUEST = 'Report groups lists request';
export const REPORT_GROUPS_LIST_RECEIVE = 'Report groups lists receive';
export const REPORT_GROUPS_LIST_FAILED = 'Report groups lists failed';

export const REPORT_GROUP_REQUEST = 'Report group request';
export const REPORT_GROUP_RECEIVE = 'Report group receive';
export const REPORT_GROUP_FAILED = 'Report group failed';
