import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import {
    AttributeType,
    Attribute,
} from '../../../store/models/attribute.model';

export interface AttributeTypesFilter {
    searchText?: string;
    lastAttrTypeId?: string;
    lastAttrTypeName?: string;
    columnName?: string;
    sortDirection?: string;
    lastCreatedStamp?: number;
}
export interface AttributesFilter {
    searchText?: string;
    lastAttrId?: string;
    lastAttrName?: string;
    columnName?: string;
    sortDirection?: string;
    lastCreatedStamp?: number;
}

@Injectable({
    providedIn: 'root',
})
export class AttributeService {
    constructor(private apiService: ApiService) {}

    getAttributeType(
        attrTypeId: string
    ): Observable<{ payload: AttributeType }> {
        return this.apiService.get(`attribute-types/${attrTypeId}`);
    }

    getAttributesByTypeId(
        attrTypeId: string,
        limit: number = 1000
    ): Observable<{ payload: Attribute[] }> {
        return this.apiService.get('attributes', { attrTypeId, limit });
    }

    getAttributeTypesByClient(
        clientId?: string,
        filter?: AttributeTypesFilter,
        limit: number = 1000
    ): Observable<{ payload: AttributeType[] }> {
        let params = {};
        if (limit) params = { limit };
        if (filter) {
            if (filter.searchText) {
                params['searchText'] = filter.searchText;
            }
            if (filter.lastAttrTypeId) {
                params['lastAttrTypeId'] = filter.lastAttrTypeId;
            }
            if (filter.lastAttrTypeName) {
                params['lastAttrTypeName'] = filter.lastAttrTypeName;
            }
            if (filter.columnName) {
                params['sortByColumnName'] = filter.columnName;
            }
            if (filter.sortDirection) {
                params['sortDirection'] = filter.sortDirection;
            }
            if (filter.lastCreatedStamp) {
                params['lastCreatedStamp'] = filter.lastCreatedStamp;
            }
        }
        return this.apiService.get('attribute-types', { clientId, ...params });
    }

    getAttributeTypesForUploadPortals(
        clientId?: string,
        limit: number = 1000
    ): Observable<{ payload: AttributeType[] }> {
        return this.apiService.get('attribute-types-for-upload-portals', { clientId, limit });
    }

    getAttributeTypesForReports(
        clientId?: string,
    ): Observable<{ payload: AttributeType[] }> {
        return this.apiService.get('attribute-types-for-reports', { clientId, limit: 1000 });
    }

    getAttributeTypesForUsers(
        clientId?: string,
    ): Observable<{ payload: AttributeType[] }> {
        return this.apiService.get('attribute-types-for-users', { clientId, limit: 1000 });
    }

    postAttributeType(data: {
        attrTypeName: string;
        clientId: string;
    }): Observable<{ payload: AttributeType }> {
        return this.apiService.post('attribute-type', data);
    }

    putAttributeType(data: {
        attrTypeId: string;
        attrTypeName: string;
        clientId: string;
    }): Observable<{ payload: AttributeType }> {
        return this.apiService.put('attribute-type', data);
    }

    deleteAttributeType(attrTypeId: string): Observable<any> {
        return this.apiService.delete(`attribute-type/${attrTypeId}`);
    }

    getAttributeTypeByReportId(
        reportId: string
    ): Observable<{ payload: AttributeType }> {
        return this.apiService.get(`reports/${reportId}/attribute-type`);
    }
}
