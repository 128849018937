import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { ReportGroup } from '../../../store/models/report-group.model';
import { Observable, Subject } from 'rxjs';

export interface ReportGroupInput {
    groupId?: string;
    groupName: string;
    groupColor: string;
    clientId: string;
    attrTypeIds: string[];
}
export interface ReportGroupFilter {
    selectedClientId?: string;
    lastReportGroupId?: ReportGroupFilter;
    selectedUploadPortalTypeIds?: string[];
    selectedReportGroupIds?: string[];
    selectedAttributeIds?: string[];
    searchText?: string;
    lastReportId?: string;
    lastReportName?: string;
    sortDirection?: string;
    reportGroups?: ReportGroup[];
}
@Injectable({
    providedIn: 'root',
})
export class ReportGroupService {
    onReportGroup$: Subject<{ reportGroup: ReportGroup; new: boolean }> =
        new Subject();

    constructor(private apiService: ApiService) {}

    createReportGroup(
        input: ReportGroupInput
    ): Observable<{ payload: ReportGroup }> {
        return this.apiService.post('report-group', input);
    }

    updateReportGroup(
        input: ReportGroupInput
    ): Observable<{ payload: ReportGroup }> {
        return this.apiService.put('report-group', input);
    }

    deleteReportGroup(groupId: string): Observable<any> {
        return this.apiService.delete(`report-group/${groupId}`);
    }

    getReportGroups(
        clientId: string,
        filter?: ReportGroupFilter,
        limit: number = 1000
    ): Observable<{ payload: ReportGroup[] }> {
        const params = {};
        if (filter) {
            if (filter.searchText) {
                params['searchText'] = filter.searchText;
            }
            if (filter.lastReportId) {
                params['lastReportId'] = filter.lastReportId;
            }
            if (filter.sortDirection) {
                params['sortDirection'] = filter.sortDirection;
            }
        }
        return this.apiService.get('report-groups', {
            clientId,
            ...params,
            limit,
        });
    }

    getReportGroupsForUsers(
        clientId: string,
    ): Observable<{ payload: ReportGroup[] }> {
        return this.apiService.get('report-groups-for-users', {
            clientId,
            limit: 1000
        });
    }

    getReportGroupById(
        reportGroupId: string
    ): Observable<{ payload: ReportGroup }> {
        return this.apiService.get(`report-group/${reportGroupId}`);
    }
}
