<div class="table-information-container">
    <nz-alert
        *ngIf="error"
        nzType="error"
        [nzMessage]="error"
        nzShowIcon
    ></nz-alert>
    <ng-container *ngIf="!loading; else loadingTmpl">
        <ng-container *ngIf="data && data?.length > 0; else noDataTmpl">
            <nz-table
                #table
                [nzLoading]="loading"
                [nzSize]="'middle'"
                [nzPageSize]="9999"
                nzHideOnSinglePage
                [nzData]="data"
            >
                <thead>
                    <tr>
                        <th
                            *ngFor="let column of columns"
                            [nzWidth]="column.width"
                            [nzColumnKey]="column.key"
                        >
                            {{ column.title }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-template ngFor let-data [ngForOf]="table.data">
                        <tr>
                            <td *ngFor="let column of columns">
                                <div class="code" [ngSwitch]="column.type">
                                    <ng-container *ngSwitchCase="'checkbox'">
                                        <label
                                            nz-checkbox
                                            nzDisabled
                                            [ngModel]="
                                                column.inverse
                                                    ? !data[column.key]
                                                    : !!data[column.key]
                                            "
                                        >
                                        </label>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'text'">
                                        {{ data[column.key] }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'sql'">
                                        <ng-container *ngIf="data[column.key]; else emptySqlCell">
                                            <markdown
                                                [data]="
                                                    data[column.key]
                                                        | language: 'sql'
                                                "
                                                inline
                                            ></markdown>
                                        </ng-container>
                                        <ng-template #emptySqlCell>
                                            -
                                        </ng-template>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        {{ data[column.key] }}
                                    </ng-container>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </nz-table>
        </ng-container>
    </ng-container>
    <ng-template #loadingTmpl>
        <nz-spin nzSimple [nzSize]="'large'" nzTip="Loading"></nz-spin>
    </ng-template>
    <ng-template #noDataTmpl>
        <nz-empty nzNotFoundContent="No metadata"></nz-empty>
    </ng-template>
</div>
