<div class="client-database-table-add-container">
    <form class="table__form" [formGroup]="tableFormGroup" nz-form>
        <div nz-row nzGutter="4">
            <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label nzRequired>Table purpose</nz-form-label>
                    <nz-form-control
                        [nzSpan]="24"
                        nzValidateStatus
                        [nzErrorTip]="tablePurposeErrorTmpl"
                    >
                        <nz-select
                            nzShowSearch
                            nzAllowClear
                            nzSize="large"
                            nzPlaceHolder="Choose Table Purpose"
                            [nzLoading]="loading"
                            (ngModelChange)="onChangeTablePurpose($event)"
                            formControlName="tablePurpose"
                        >
                            <nz-option
                                *ngFor="let purpose of tablePurposes"
                                [nzValue]="purpose.tablePurpose"
                                [nzLabel]="purpose.tablePurpose"
                            ></nz-option>
                        </nz-select>
                        <ng-template #tablePurposeErrorTmpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please select table purpose
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row nzGutter="4">
            <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label nzRequired>Table type</nz-form-label>
                    <nz-form-control
                        [nzSpan]="24"
                        nzValidateStatus
                        [nzErrorTip]="tableTypeErrorTmpl"
                    >
                        <nz-select
                            nzAllowClear
                            nzShowSearch
                            nzSize="large"
                            nzPlaceHolder="Choose Table Type"
                            [nzLoading]="loading"
                            formControlName="tableType"
                            (ngModelChange)="onChangeTableType($event)"
                        >
                            <nz-option
                                *ngFor="let type of tableTypes"
                                [nzValue]="type.tableType"
                                [nzLabel]="type.tableType"
                            ></nz-option>
                        </nz-select>
                        <ng-template #tableTypeErrorTmpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please select table type
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row nzGutter="4">
            <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label nzRequired>Table name</nz-form-label>
                    <nz-form-control
                        [nzSpan]="24"
                        nzValidateStatus
                        nzHasFeedback
                        [nzErrorTip]="tableNameErrorTmpl"
                    >
                        <nz-input-group
                            [nzAddOnBefore]="
                                tableNamePrefix ? prefixTableName : undefined
                            "
                            [nzAddOnAfter]="
                                tableNameSuffix ? suffixTableName : undefined
                            "
                            nzSize="large"
                        >
                            <input
                                [disabled]="loading"
                                data-cy="table__name"
                                formControlName="tableName"
                                nz-input
                                placeholder="Enter Table Name"
                            />
                        </nz-input-group>
                        <ng-template #prefixTableName>
                            {{ tableNamePrefix }}
                        </ng-template>
                        <ng-template #suffixTableName>
                            {{ tableNameSuffix }}
                        </ng-template>
                        <ng-template #tableNameErrorTmpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please enter table name
                            </ng-container>
                            <ng-container *ngIf="control.hasError('dbNaming')">
                                Please enter table name using ASCII alphanum and
                                underscore without spaces and special characters
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </form>
    <form [formGroup]="tableFormGroup" name="columns-form" class="columns-form">
        <div nz-row nzGutter="4">
            <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label>Columns</nz-form-label>
                    <nz-form-control>
                        <nz-table
                            #table
                            [nzLoading]="loading"
                            [nzSize]="'small'"
                            [nzNoResult]="noColumnsText"
                            nzShowPagination="false"
                            nzFrontPagination="false"
                            nzHideOnSinglePage
                        >
                            <thead>
                                <tr>
                                    <th nzWidth="20%">Field name</th>
                                    <th nzWidth="20%">Data Type</th>
                                    <th nzWidth="20%">Default Value</th>
                                    <th nzWidth="20%">Not null</th>
                                    <th nzWidth="13">FK</th>
                                    <th nzWidth="7%"></th>
                                </tr>
                            </thead>
                            <tbody formArrayName="columns">
                                <tr
                                    *ngFor="
                                        let columnForm of columnsFormArray.controls;
                                        let i = index
                                    "
                                    [formGroup]="columnForm"
                                >
                                    <td>
                                        <nz-form-item>
                                            <nz-form-control
                                                nzValidateStatus
                                                nzHasFeedback
                                                [nzErrorTip]="
                                                    columnNameErrorTmpl
                                                "
                                            >
                                                <nz-input-group nzSize="large">
                                                    <input
                                                        formControlName="columnName"
                                                        nz-input
                                                        placeholder="Enter Field Name"
                                                        [disabled]="
                                                            columnForm.get(
                                                                'columnName'
                                                            )?.disabled
                                                        "
                                                    />
                                                </nz-input-group>
                                                <ng-template
                                                    #columnNameErrorTmpl
                                                    let-control
                                                >
                                                    <ng-container
                                                        *ngIf="
                                                            control.hasError(
                                                                'required'
                                                            )
                                                        "
                                                        >Please enter field name
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="
                                                            control.hasError(
                                                                'dbNaming'
                                                            )
                                                        "
                                                    >
                                                        Please enter column
                                                        without special
                                                        characters #&#64;)([] and be
                                                        a max of
                                                        {{ columnNameLength }}
                                                        characters in length.
                                                    </ng-container>
                                                </ng-template>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </td>
                                    <td>
                                        <nz-form-item>
                                            <nz-form-control
                                                nzValidateStatus
                                                nzHasFeedback
                                                [nzErrorTip]="dataTypeErrorTmpl"
                                            >
                                                <nz-select
                                                    nzShowSearch
                                                    nzAllowClear
                                                    [nzDisabled]="
                                                        columnForm.get(
                                                            'dataType'
                                                        )?.disabled
                                                    "
                                                    [nzLoading]="
                                                        dataTypesLoading
                                                    "
                                                    (ngModelChange)="onColumnDataTypeChanged($event, i)"
                                                    nzSize="large"
                                                    nzPlaceHolder="Field Type"
                                                    formControlName="dataType"
                                                >
                                                    <nz-option
                                                        *ngFor="
                                                            let type of dataTypes
                                                        "
                                                        [nzValue]="type"
                                                        [nzLabel]="type"
                                                    ></nz-option>
                                                </nz-select>
                                                <ng-template
                                                    #dataTypeErrorTmpl
                                                    let-control
                                                >
                                                    <ng-container
                                                        *ngIf="
                                                            control.hasError(
                                                                'required'
                                                            )
                                                        "
                                                    >
                                                        Please select data type
                                                    </ng-container>
                                                </ng-template>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </td>
                                    <td>
                                        <nz-form-item>
                                            <nz-form-control
                                                nzValidateStatus
                                                nzHasFeedback
                                                [nzErrorTip]="
                                                    defaultValueErrorTmpl
                                                "
                                            >
                                                <nz-select *ngIf="columnForm.get('dataType')?.value === 'boolean'" nzPlaceHolder="Select value" formControlName="columnDefaultValue">
                                                    <nz-option *ngIf="!columnForm.get('isNotNull')?.value" [nzValue]="''" nzLabel="null"></nz-option>
                                                    <nz-option [nzValue]="true" nzLabel="True"></nz-option>
                                                    <nz-option [nzValue]="false" nzLabel="False"></nz-option>
                                                </nz-select>
                                                <nz-input-group *ngIf="columnForm.get('dataType')?.value !== 'boolean'" nzSize="large">
                                                    <input
                                                        formControlName="columnDefaultValue"
                                                        [disabled]="
                                                            columnForm.get(
                                                                'columnDefaultValue'
                                                            )?.disabled
                                                        "
                                                        nz-input
                                                        placeholder="Enter Default Value"
                                                    />
                                                </nz-input-group>
                                                <ng-template
                                                    #defaultValueErrorTmpl
                                                    let-control
                                                >
                                                    <ng-container
                                                        *ngIf="
                                                            control.hasError(
                                                                'required'
                                                            )
                                                        "
                                                    >
                                                        Please input default
                                                        value
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="
                                                            control.hasError(
                                                                'uuid'
                                                            )
                                                        "
                                                    >
                                                        Please input uuid
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="
                                                            control.hasError(
                                                                'pattern'
                                                            ) || control.hasError(
                                                                'max'
                                                            ) || control.hasError(
                                                                'min'
                                                            )
                                                        "
                                                    >
                                                        Please input valid <b>{{columnForm.get('dataType')?.value}}</b> value
                                                    </ng-container>
                                                </ng-template>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </td>
                                    <td>
                                        <div class="switch-container">
                                            <nz-switch
                                                [nzDisabled]="
                                                    columnForm.get('isNotNull')
                                                        ?.disabled
                                                "
                                                (ngModelChange)="onNotNullToggleChanged($event, i)"
                                                formControlName="isNotNull"
                                            ></nz-switch>
                                        </div>
                                    </td>
                                    <td>
                                        <nz-tag
                                            *ngIf="columnForm?.get('fk')?.value"
                                            [nzColor]="'#3099cc'"
                                            >{{
                                                columnForm?.get('fk')?.value
                                            }}</nz-tag
                                        >
                                    </td>
                                    <td class="actions__cell">
                                        <i
                                            *ngIf="
                                                !columnForm.get('columnName')
                                                    ?.disabled
                                            "
                                            (click)="onDeleteColumn(i)"
                                            nz-tooltip
                                            nzTooltipPlacement="top"
                                            nzTooltipTitle="Delete"
                                            nzTooltipOverlayClassName="danger-tooltip"
                                            class="action-icon delete"
                                            nz-icon
                                            nzType="delete"
                                            nzTheme="outline"
                                        ></i>
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>
                        <button
                            type="button"
                            nzType="primary"
                            nzGhost
                            nz-button
                            [nzLoading]="dataTypesLoading"
                            [disabled]="
                                !tableFormGroup?.get('tablePurpose')?.value ||
                                !tableFormGroup?.get('tableType')?.value
                            "
                            (click)="onAddColumn()"
                            class="new-column-btn"
                        >
                            <i nz-icon nzType="plus" nzTheme="outline"></i>
                            <span>New Column</span>
                        </button>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </form>
</div>
<div class="drawer-actions-control">
    <div class="drawer-controls">
        <button
            nz-button
            nzType="primary"
            (click)="onSave()"
            [disabled]="saving || !tableFormGroup.valid"
            [nzLoading]="saving"
        >
            Save
        </button>
        <button [disabled]="saving" type="button" nz-button (click)="onClose()">
            <span>Close</span>
        </button>
    </div>
</div>

<ng-template #noColumnsText> </ng-template>
