<nz-result
      nzTitle="Access Denied"
      nzStatus="error"
      [nzIcon]="'lock'"
      [nzSubTitle]="descriptionTmpl"
    >
    <div nz-result-extra>
    <a nz-button nzType="link" routerLink="/">Navigate to Home Page</a>
    </div>
</nz-result>

<ng-template #descriptionTmpl>
    <p class="access-denied-text">
        You do not have permissions to view page. <br />
        Contact your account administrator for more information.
    </p>
</ng-template>