<div class="color-picker-container">
    <div
        class="input-container"
        nz-popover
        [(nzPopoverVisible)]="visible"
        (nzPopoverVisibleChange)="change($event)"
        nzPopoverTrigger="click"
        [nzPopoverContent]="colorPickerContentTemplate"
        nzPopoverPlacement="leftTop"
        nzTooltipOverlayClassName="color-picker"
    >
        <div class="prefix-color" [style.backgroundColor]="color"></div>
        <input readonly nz-input placeholder="#D76700" [(ngModel)]="color" />
    </div>
    <ng-template #colorPickerContentTemplate>
        <div data-cy="color-picker" class="palette-container">
            <div class="palette">
                <canvas id="pallet-circle" width="296" height="296"></canvas>
            </div>
            <div class="palette-input">
                <nz-input-group nzSize="large">
                    <div
                        class="prefix-color"
                        [style.backgroundColor]="color"
                    ></div>
                    <input [(ngModel)]="color" nz-input placeholder="#D76700" />
                </nz-input-group>
                <button
                    data-cy="add-color"
                    nz-button
                    nzType="primary"
                    (click)="onAddColor()"
                >
                    <i nz-icon nzType="plus"></i>
                </button>
            </div>
        </div>
        <div
            data-cy="color-picker-defined-colors"
            class="palette-defined-colors"
        >
            <div class="defined-colors-row">
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#D61616'"
                    (click)="selectColor('#D61616')"
                    [style.backgroundColor]="'#D61616'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#EB5757'"
                    (click)="selectColor('#EB5757')"
                    [style.backgroundColor]="'#EB5757'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#D76700'"
                    (click)="selectColor('#D76700')"
                    [style.backgroundColor]="'#D76700'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#F2994A'"
                    (click)="selectColor('#F2994A')"
                    [style.backgroundColor]="'#F2994A'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#D7B400'"
                    (click)="selectColor('#D7B400')"
                    [style.backgroundColor]="'#D7B400'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#F2C94C'"
                    (click)="selectColor('#F2C94C')"
                    [style.backgroundColor]="'#F2C94C'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#219653'"
                    (click)="selectColor('#219653')"
                    [style.backgroundColor]="'#219653'"
                ></div>
            </div>
            <div class="defined-colors-row">
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#27AE60'"
                    (click)="selectColor('#27AE60')"
                    [style.backgroundColor]="'#27AE60'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#6FCF97'"
                    (click)="selectColor('#6FCF97')"
                    [style.backgroundColor]="'#6FCF97'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#2F80ED'"
                    (click)="selectColor('#2F80ED')"
                    [style.backgroundColor]="'#2F80ED'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#2D9CDB'"
                    (click)="selectColor('#2D9CDB')"
                    [style.backgroundColor]="'#2D9CDB'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#56CCF2'"
                    (click)="selectColor('#56CCF2')"
                    [style.backgroundColor]="'#56CCF2'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#9B51E0'"
                    (click)="selectColor('#9B51E0')"
                    [style.backgroundColor]="'#9B51E0'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#BB6BD9'"
                    (click)="selectColor('#BB6BD9')"
                    [style.backgroundColor]="'#BB6BD9'"
                ></div>
            </div>
            <div class="defined-colors-row">
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#333333'"
                    (click)="selectColor('#333333')"
                    [style.backgroundColor]="'#333333'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#4F4F4F'"
                    (click)="selectColor('#4F4F4F')"
                    [style.backgroundColor]="'#4F4F4F'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#828282'"
                    (click)="selectColor('#828282')"
                    [style.backgroundColor]="'#828282'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#BDBDBD'"
                    (click)="selectColor('#BDBDBD')"
                    [style.backgroundColor]="'#BDBDBD'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#E0E0E0'"
                    (click)="selectColor('#E0E0E0')"
                    [style.backgroundColor]="'#E0E0E0'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#EDEDEE'"
                    (click)="selectColor('#EDEDEE')"
                    [style.backgroundColor]="'#EDEDEE'"
                ></div>
                <div
                    data-cy="color"
                    class="defined-color"
                    [class.selected]="color === '#F2F2F2'"
                    (click)="selectColor('#F2F2F2')"
                    [style.backgroundColor]="'#F2F2F2'"
                ></div>
            </div>
        </div>
    </ng-template>
</div>
