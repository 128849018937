import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/core/services/api/api.service';
import { DefaultPipelinesConfig, Pipeline, PipelineClassifications, PipelineDefaultParamsMap, PipelineInput, PipelineParamsMap, PipelineRunHistory, PipelineRunLog, PipelineType, ReportYearsInput, UpdatePipelineInput } from 'src/app/store/models/pipeline.model';

@Injectable({
  providedIn: 'root'
})
export class PipelinesService {
  //use to update/insert pipeline object to the list after create/update operations
  updater$: Subject<Pipeline> = new Subject();

  constructor(private api: ApiService) { }

  getReportYears(clientId: string): Observable<{ payload: number[] }> {
    return this.api.get(`data-pipelines/report-years`, { clientId })
  }

  createReportYears(input: ReportYearsInput): Observable<any> {
    return this.api.post(`data-pipelines/report-years`, input)
  }

  getDefaultBuildPipelines(clientId: string, year: number): Observable<{ payload: DefaultPipelinesConfig }> {
    return this.api.get(`data-pipelines/clients/${clientId}/report-years/${year}/default-build-pipelines`)
  }

  deleteReportYear(clientId: string, year: number) {
    return this.api.delete(`data-pipelines/clients/${clientId}/report-years/${year}`)
  }

  getPipelinesByClassification(clientId: string, reportYear: number, classification?: PipelineClassifications): Observable<{ payload: Pipeline[] }> {
    const params = { clientId, reportYear };

    if (classification) {
      params['dataPipelineClassification'] = classification;
    }

    return this.api.get(`data-pipelines`, params)
  }

  getPipelineClientSchemaTables(clientId: string): Observable<{ payload: string[] }> {
    return this.api.get(`data-pipelines/client-schema-tables`, { clientId });
  }

  getPipeline(dataPipelineId: string): Observable<{ payload: Pipeline }> {
    return this.api.get(`data-pipelines/${dataPipelineId}`);
  }


  testPipelineConnection(dataPipelineId?: string, dataPipelineType?: PipelineType, dataFactoryPipelineName?: string): Observable<{ payload: PipelineParamsMap }> {
    let params = {};

    if (dataPipelineId) {
      params = { dataPipelineId };
    } else if (dataPipelineType && dataFactoryPipelineName) {
      params = {
        dataPipelineType,
        dataFactoryPipelineName
      }
    }

    return this.api.get(`data-pipelines/params-specs`, params);
  }

  getPipelineDefaultRunParams(dataPipelineId: string): Observable<{ payload: PipelineDefaultParamsMap }> {
    return this.api.get(`data-pipelines/${dataPipelineId}/default-run-params`);
  } 

  createPipeline(input: PipelineInput): Observable<{ payload: Pipeline }> {
    return this.api.post('data-pipelines', input);
  }

  updatePipeline(dataPipelineId: string, input: UpdatePipelineInput): Observable<{ payload: Pipeline }> {
    return this.api.put(`data-pipelines/${dataPipelineId}`, input);
  }

  deletePipeline(dataPipelineId: string) {
    return this.api.delete(`data-pipelines/${dataPipelineId}`);
  }

  updateSignOff(dataPipelineId: string, value: boolean): Observable<{ payload: Pipeline }> {
    return this.api.put(`data-pipelines/${dataPipelineId}/sign-offs/${value}`, {})
  }

  runPipeline(dataPipelineId: string, runParams: Record<string, string>, extraParams?: Record<string, string>) {
    return this.api.post(`data-pipelines/${dataPipelineId}/runs`, { runParams, ...(extraParams ?? {}) });
  }

  getPipelineRunHistory(dataPipelineId: string): Observable<{ payload: { runs: PipelineRunHistory[],  dataPipelineName?: string } }> {
    return this.api.get(`data-pipelines/${dataPipelineId}/runs-history`);
  }

  getPipelineRunLogs(dataFactoryPipelineRunId: string): Observable<{ payload: PipelineRunLog[] }> {
    return this.api.get(`data-pipelines/runs-history/${dataFactoryPipelineRunId}/run-logs`)
  }
  
  getPipelineTypes(clientId: string, reportYear: number): Observable<{ payload: string[] }> {
    return this.api.get(`data-pipelines/data-pipeline-types`, { clientId, reportYear });
  }
}
