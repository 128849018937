<div class="lca-model-data-database-form-container">
    <nz-alert
        nzType="info"
        nzMessage="Please select ActivitySerials."
        nzShowIcon
    ></nz-alert>
    <nz-alert
        *ngIf="warnMessage"
        nzType="warning"
        [nzMessage]="warnMessage"
        nzShowIcon
    ></nz-alert>
    <app-lca-activityserial-selection-form
        (changed)="onActivitySerialsChanged($event)"
    ></app-lca-activityserial-selection-form>
    <div nz-col class="drawer-actions-control" nzSpan="24">
        <div class="drawer-controls">
            <button
                type="button"
                nzSize="large"
                nz-button
                [disabled]="!selectedActivitySerials.length"
                (click)="onSave()"
                nzType="primary"
            >
                <span>Save Changes</span>
            </button>
            <button type="button" nzSize="large" nz-button (click)="onClose()">
                <span>Close</span>
            </button>
        </div>
    </div>
</div>
