import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import _get from 'lodash/get';
import {
    UploadPortalService,
    UploadPortalTypeFilter,
} from '../../../../core/services/upload-portal/upload-portal.service';
import { UploadPortalType } from '../../../../store/models/upload-portal-type.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import _orderBy from 'lodash/orderBy';
import { Router } from '@angular/router';
import _last from 'lodash/last';
import { GlobalService } from 'src/app/core/services/global/global.service';

const PAGE_SIZE = 100;

@Component({
    selector: 'app-portal-types-table',
    templateUrl: './portal-types-table.component.html',
    styleUrls: ['./portal-types-table.component.less'],
})
export class PortalTypesTableComponent implements OnInit, OnDestroy {
    clientId: string;
    formMode: boolean = false;
    selectedPortalTypeId: string;
    @Input() searchText: string;
    
    columns = [
        {
            title: 'Type name',
            key: 'upload_portal_type_name',
            sortDirections: ['descend', 'ascend', null],
            width: '25%',
        },
        {
            title: 'Attribute types',
            key: 'upload_portal_type_attribute_types',
            width: '40%',
        },
        {
            title: 'Date created',
            key: 'created_stamp',
            sortDirections: ['ascend', 'descend', null],
            width: '20%',
        },
    ];
    filterForm: Subject<UploadPortalTypeFilter> = new Subject();
    ngDestroyed$ = new Subject();

    performingOperationPortalTypeIds: string[] = [];

    query: string;
    searchFilter = {
        searchText: '',
        lastUploadPortalTypeId: '',
        lastCreatedStamp: '',
        lastUploadPortalTypeName: '',
        lastRecordId: '',
        columnName: 'upload_portal_type_name',
        sortDirection: 'ascend',
    };
    uploadPortalTypes: UploadPortalType[] = [];
    uploadPortalTypesLoaded: boolean = false;
    uploadPortalTypesLoading: boolean;

    constructor(
        private uploadPortalService: UploadPortalService,
        private modal: NzModalService,
        private message: NzMessageService,
        private router: Router,
        private globalService: GlobalService
    ) {
        this.globalService.clientId$.pipe(takeUntil(this.ngDestroyed$))
            .subscribe(
                clientId => {
                    if (clientId) {
                        this.clientId = clientId;
                        this.uploadPortalTypesLoaded = false;
                        this.searchFilter.lastCreatedStamp = '';
                        this.searchFilter.lastRecordId = '';
                        this.searchFilter.lastUploadPortalTypeId = '';
                        this.searchFilter.lastUploadPortalTypeName = '';
                        this.fetchTypes(this.clientId);
                    }
                }
            )
    }

    ngOnInit() {
        this.filterForm
            .pipe(takeUntil(this.ngDestroyed$), debounceTime(400))
            .subscribe((filter) => {
                this.fetchTypes(this.clientId, filter);
            });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }
    //instead of onSearch
    ngOnChanges(changes: SimpleChanges) {
        let change = changes['searchText'];
        if (change) {
            if (change.currentValue != change.previousValue) {
                this.uploadPortalTypesLoaded = false;
            }
            this.searchFilter.searchText = change.currentValue;
            this.filterForm.next({
                searchText: change.currentValue,
                columnName: this.searchFilter.columnName,
                sortDirection: this.searchFilter.sortDirection,
            });
        }
    }

    fetchTypes(clientId: string, filter?: UploadPortalTypeFilter) {
        if (!this.uploadPortalTypesLoaded) {
            this.uploadPortalTypesLoading = true;

            this.uploadPortalService
                .getUploadPortalTypes(clientId, filter, PAGE_SIZE)
                .subscribe({
                    next: (payload) => {
                        const uploadTypes = _get(payload, 'payload', []);
                        if (uploadTypes.length < PAGE_SIZE) {
                            this.uploadPortalTypesLoaded = true;
                        }
                        if (filter && filter.lastUploadPortalTypeId) {
                            if (uploadTypes.length) {
                                this.uploadPortalTypes = [...this.uploadPortalTypes, ...uploadTypes];
                            }
                        } else {
                            this.uploadPortalTypes = uploadTypes;
                        }
                    },
                    complete: () => {
                        this.uploadPortalTypesLoading = false;
                        this.query = this.searchText;
                    },
                });
        }
    }
    onRemoveType(uploadPortalTypeId: string, uploadPortalTypeName: string) {
        this.modal.confirm({
            nzTitle: `Do you want to remove <b>${uploadPortalTypeName}</b>?`,
            nzContent: `Deleting upload portal type will also delete all upload portals in this type. This action cannot be undone. Do you want to continue?`,
            nzOnOk: () =>
                this.uploadPortalService
                    .deleteUploadPortalType(uploadPortalTypeId)
                    .pipe(takeUntil(this.ngDestroyed$))
                    .subscribe(() => {
                        this.uploadPortalTypes = this.uploadPortalTypes.filter(type => type.uploadPortalTypeId !== uploadPortalTypeId);
                        this.message.success(
                            `Type <b>${uploadPortalTypeName}</b> has been deleted`,
                            { nzDuration: 3000 }
                        );
                    }),
            nzOkText: 'Yes, remove',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzCancelText: 'Close',
            nzClosable: false,
            nzOnCancel: () => console.log('Cancel'),
        });
    }

    onEditType(type: UploadPortalType) {
        this.formMode = true;
        this.selectedPortalTypeId = type.uploadPortalTypeId;
    }

    closeFormMode(shouldReload?: boolean) {
        this.formMode = false;
        this.selectedPortalTypeId = undefined;

        if (shouldReload) {
            this.searchFilter.lastCreatedStamp = '';
            this.searchFilter.lastRecordId = '';
            this.searchFilter.lastUploadPortalTypeId = '';
            this.searchFilter.lastUploadPortalTypeName = '';
            this.uploadPortalTypesLoaded = false;
            this.fetchTypes(this.clientId);
        }
    }

    orderChange(col: any) {
        this.uploadPortalTypesLoaded = false;
        this.uploadPortalTypesLoading = true;
        this.searchFilter.columnName = col.key;
        this.searchFilter.sortDirection = col.value;

        this.filterForm.next({
            columnName: this.searchFilter.columnName,
            sortDirection: this.searchFilter.sortDirection,
            searchText: this.searchFilter.searchText,
        });
    }

    onCreateUploadPortalType() {
        this.formMode = true;
        this.selectedPortalTypeId = undefined;
    }

    onTableScrolled() {
        if (this.uploadPortalTypesLoaded) {
            return;
        }

        const upload: UploadPortalType = _last(this.uploadPortalTypes);
        if (upload && upload.uploadPortalTypeId) {
            const { uploadPortalTypeId, uploadPortalTypeName, createdStamp } =
                upload;
            this.searchFilter.lastUploadPortalTypeId = uploadPortalTypeId.valueOf();
            this.filterForm.next({
                lastUploadPortalTypeId: uploadPortalTypeId,
                lastUploadPortalTypeName: uploadPortalTypeName,
                columnName: this.searchFilter.columnName,
                sortDirection: this.searchFilter.sortDirection,
                lastCreatedStamp: createdStamp,
            });
        }
    }

    onActivatePortalsByType(uploadPortalTypeId: string, active: boolean) {
        const portalType = this.uploadPortalTypes.find(item => item.uploadPortalTypeId === uploadPortalTypeId);
        
        if (!portalType) {
            return;
        }

        this.modal.confirm({
            nzTitle: `${active ? 'Activate all' : 'Deactivate all'} ${portalType.uploadPortalTypeName}'s portals`,
            nzContent: `Are you sure want to ${active ? 'activate' : 'deactivate'} all portals?`,
            nzOkText: `Yes, ${active ? 'activate' : 'deactivate'}`,
            nzOkDanger: !active,
            nzOnOk: () => {
                this.performingOperationPortalTypeIds.push(uploadPortalTypeId);

                this.uploadPortalService.patchAllActive(
                    uploadPortalTypeId,
                    active
                )
                    .pipe(takeUntil(this.ngDestroyed$))
                    .subscribe({
                        next: () => {
                            this.performingOperationPortalTypeIds = this.performingOperationPortalTypeIds.filter(id => id !== uploadPortalTypeId);
                            this.message.success(`All upload portals ${active ? 'activated' : 'deactivated'} successfully`);
                        },
                        error: () => {
                            this.message.error(`Failed to ${active ? 'activate' : 'deactivate'} portals`);
                            this.performingOperationPortalTypeIds = this.performingOperationPortalTypeIds.filter(id => id !== uploadPortalTypeId);
                        }
                    })
            }
        })
    }
}
