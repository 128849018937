<div class="reporting-table-page-container">
    <div nz-flex [nzGap]="8" nzJustify="space-between" nzAlign="center" class="reporting-table-runs__controls">
        <nz-breadcrumb nz-flex>
            <nz-breadcrumb-item>
              <a [routerLink]="['/database/generate-reporting-table']" (click)="reset()" queryParamsHandling="merge">Reporting Table Runs</a>
            </nz-breadcrumb-item>
            @if (formMode) {
                <nz-breadcrumb-item>
                    Create Reporting Table(s)
                </nz-breadcrumb-item>
            }
        </nz-breadcrumb>
        <button
            nz-button
            nzGhost
            nzType="primary"
            (click)="onCreateReportingTable()"
            nzSize="small"
            *ngIf="!formMode"
            [disabled]="!clientId"
        >
            <span nz-icon nzType="plus"></span>
            Create Reporting Table(s)
        </button>
    </div>
    <div class="reporting-table-page__content">
        <ng-container *ngIf="formMode; else runsTable">
            <ng-container *ngTemplateOutlet="createReportingTableTmpl">
                <!-- activity history -->
            </ng-container>
        </ng-container>
    </div>
</div>

<ng-template #runsTable>
    <app-reporting-table-runs></app-reporting-table-runs>
</ng-template>

<ng-template #createReportingTableTmpl>
    <ng-container *ngIf="initError; else formContent">
        <nz-alert
            nzType="error"
            [nzMessage]="initError"
            nzShowIcon
        ></nz-alert>
    </ng-container>
</ng-template>
<ng-template #formContent>
    <ng-container *ngIf="!clientDataFieldsLoading && !impactCategoriesLoading && !factTableFieldsLoading && !clientDatatableNamesLoading; else loadingContent">
        <div class="reporting-table-form-container">
            <div nz-flex [nzVertical]="true" [nzGap]="8">
                <nz-alert
                    class="error"
                    *ngIf="error"
                    nzType="error"
                    [nzMessage]="error"
                    nzShowIcon
                ></nz-alert>
                <nz-alert
                    *ngIf="!clientDatatableNamesLoading && !clientDatatableNames.length"
                    nzType="warning"
                    nzMessage="First create at least 1 ClientData table."
                ></nz-alert>
                <form
                    class="reporting-table__form"
                    [formGroup]="tableFormGroup"
                    nz-form
                >
                    <nz-steps [nzCurrent]="createStepIndex" (nzIndexChange)="onCreateStepIndexChange($event)">
                        <nz-step [nzDisabled]="saving" [nzStatus]="errorSteps.has(0) ? 'error' : createStepIndex === 0 ? 'process' : 'finish'" nzTitle="Basic Information"></nz-step>
                        <nz-step [nzDisabled]="saving || !tableFormGroup.valid" nzTitle="Tables to Generate"></nz-step>
                        <nz-step [nzDisabled]="saving || !tableFormGroup.valid || !selectedTableTypeIds.length" nzTitle="ClientData Fields"></nz-step>
                        @if (selectedTableTypeIds.includes(tableTypesEnum.FACT_TABLE)) {<nz-step [nzDisabled]="saving || !tableFormGroup.valid || !selectedTableTypeIds.length" nzTitle="Fact Table Fields"></nz-step>}
                        @if (selectedTableTypeIds.includes(tableTypesEnum.BREAKOUT_TABLE)) {<nz-step [nzDisabled]="saving || !tableFormGroup.valid ||!selectedTableTypeIds.length" [nzStatus]="errorSteps.has(selectedTableTypeIds.includes(tableTypesEnum.FACT_TABLE) ? 4 : 3) ? 'error' : createStepIndex < (selectedTableTypeIds.includes(tableTypesEnum.FACT_TABLE) ? 4 : 3) ? 'wait' : createStepIndex < (selectedTableTypeIds.includes(tableTypesEnum.FACT_TABLE) ? 4 : 3) ? 'process' : 'finish'" nzTitle="ClientData Breakout Table ImpactSerials"></nz-step>}
                    </nz-steps>
                    
                    <div class="step" *ngIf="createStepIndex === 0">
                        <div nz-row nzGutter="4">
                            <div nz-col [style.width]="'100%'" nzSpan="24" nz-flex [nzVertical]="true" [nzGap]="8">
                                <nz-alert nzType="info" nzMessage="Basic information about the reporting table run"></nz-alert>
                                <nz-alert nzType="info" nzMessage="Please enter table name suffix."></nz-alert>
                                <nz-form-item>
                                    <nz-form-label nzRequired>Table name</nz-form-label>
                                    <nz-form-control
                                        [nzSpan]="24"
                                        nzValidateStatus
                                        [nzErrorTip]="tableNameErrorTmpl"
                                    >
                                        <nz-input-group
                                            [nzSuffix]="inputLengthTracker"
                                            nzSize="large"
                                        >
                                            <input
                                                [maxlength]="tableNameLength"
                                                formControlName="tableName"
                                                nz-input
                                                placeholder="Enter Table Name"
                                            />
                                        </nz-input-group>
                                        <ng-template #tableNameErrorTmpl let-control>
                                            <ng-container *ngIf="control.hasError('required')">
                                                Please enter table name
                                            </ng-container>
                                            <ng-container>
                                                <span>
                                                    Table name should be in snake_case without
                                                    special characters #&#64;)([] and be a max of
                                                    {{ tableNameLength }} characters in length.
                                                </span>
                                            </ng-container>
                                        </ng-template>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-label
                                        >List of ClientData table available</nz-form-label
                                    >
                                    <nz-form-control
                                        [nzSpan]="24"
                                        nzValidateStatus
                                        nzHasFeedback
                                        [nzErrorTip]="clientDataAvailableErrorTmpl"
                                    >
                                        <nz-select
                                            nzShowSearch
                                            nzSize="large"
                                            nzPlaceHolder="Choose one"
                                            nzAllowClear
                                            [nzLoading]="clientDatatableNamesLoading"
                                            formControlName="clientDataTable"
                                        >
                                            <nz-option
                                                *ngFor="
                                                    let clientDatatableName of clientDatatableNames
                                                "
                                                [nzValue]="clientDatatableName"
                                                [nzLabel]="clientDatatableName"
                                            ></nz-option>
                                        </nz-select>
                                        <ng-template #clientDataAvailableErrorTmpl let-control>
                                            <ng-container *ngIf="control.hasError('required')">
                                                Please select ClientData table</ng-container
                                            >
                                        </ng-template>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="step" *ngIf="createStepIndex === 1">
                        <div nz-row nzGutter="4">
                            <div nz-col [style.width]="'100%'" nzSpan="24" nz-flex [nzVertical]="true" [nzGap]="8">
                                <nz-alert nzType="info" nzMessage="Please select one or more reporting table types to generate in this reporting table run"></nz-alert>
                                <nz-form-item *ngIf="tableTypes?.length">
                                    <nz-form-label nzRequired>Table type</nz-form-label>
                                    <nz-form-control [nzSpan]="24">
                                        <nz-checkbox-wrapper
                                            (nzOnChange)="onChangeTableTypeIds($event)"
                                        >
                                            <ng-container *ngFor="
                                                let tableType of tableTypes;
                                                let i = index
                                            ">
                                                <label
                                                    *hasPermission="tableType.permissions ?? []"
                                                    nz-checkbox
                                                    [nzValue]="tableType.tableTypeId"
                                                    [nzChecked]="selectedTableTypeIds.includes(tableType.tableTypeId)"
                                                >
                                                    {{ tableType.tableTypeName }}
                                                </label>
                                            </ng-container>
                                        </nz-checkbox-wrapper>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="step" *ngIf="createStepIndex === 2">
                        <div nz-row nzGutter="4">
                            <div nz-col [style.width]="'100%'" nzSpan="24" nz-flex [nzVertical]="true" [nzGap]="8">
                                <nz-alert nzType="info" nzMessage="Please select the fields from the ClientData table you want to include in the generated reporting tables"></nz-alert>
                                <nz-form-item
                                    *ngIf="clientDataFields?.length || clientDataFieldsLoading"
                                >
                                    <nz-skeleton
                                        [nzLoading]="clientDataFieldsLoading"
                                        [nzActive]="true"
                                    >
                                        <nz-form-label [style.marginBottom.rem]="0.5" nzRequired
                                            >Client data fields</nz-form-label
                                        >
                                        <nz-form-control
                                            [class.disabled]="!isClientFieldsAvailable"
                                            [nzSpan]="24"
                                        >
                                            <nz-checkbox-wrapper
                                                (nzOnChange)="onChangeClientDataFields($event)"
                                            >
                                                <label
                                                    [class.disabled]="isFieldIsDisabled(field)"
                                                    [formControlName]="'field#' + field"
                                                    *ngFor="
                                                        let field of clientDataFields;
                                                        let i = index
                                                    "
                                                    nz-checkbox
                                                    [nzValue]="field"
                                                >
                                                    {{ field }}
                                                </label>
                                            </nz-checkbox-wrapper>
                                        </nz-form-control>
                                    </nz-skeleton>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="step" *ngIf="selectedTableTypeIds.includes(tableTypesEnum.FACT_TABLE) && createStepIndex === 3">
                        <div nz-row nzGutter="4">
                            <div nz-col [style.width]="'100%'" nzSpan="24" nz-flex [nzVertical]="true" [nzGap]="8">
                                <nz-alert nzType="info" nzMessage="Please select additional optional fields to add to the generated fact table"></nz-alert>
                                <nz-form-item
                                    *ngIf="factTableFields?.length || factTableFieldsLoading"
                                >
                                    <nz-skeleton
                                        [nzLoading]="factTableFieldsLoading"
                                        [nzActive]="true"
                                    >
                                        <nz-form-label [style.marginBottom.rem]="0.5" nzRequired
                                            >Fact table fields</nz-form-label
                                        >
                                        <nz-form-control
                                            [class.disabled]="!isFactFieldsAvailable"
                                            [nzSpan]="24"
                                        >
                                            <nz-checkbox-wrapper
                                                (nzOnChange)="onChangeFactTableFields($event)"
                                            >
                                                <label
                                                    *ngFor="
                                                        let field of factTableFields;
                                                        let i = index
                                                    "
                                                    nz-checkbox
                                                    [nzChecked]="selectedFactTableFields.includes(field)"
                                                    [nzValue]="field"
                                                >
                                                    {{ field }}
                                                </label>
                                            </nz-checkbox-wrapper>
                                        </nz-form-control>
                                    </nz-skeleton>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="step" *ngIf="selectedTableTypeIds.includes(tableTypesEnum.FACT_TABLE) ? createStepIndex === 4 : createStepIndex === 3">
                        <div nz-row nzGutter="4">
                            <div nz-col [style.width]="'100%'" nzSpan="24" nz-flex [nzVertical]="true" [nzGap]="8">
                                <nz-alert
                                    class="error"
                                    *ngIf="breakoutError"
                                    nzType="error"
                                    [nzMessage]="breakoutError"
                                    nzShowIcon
                                ></nz-alert>
                                <nz-alert nzType="info" nzMessage="Please select one or more available ImpactSerials to use when generating the ClientData Breakout tables"></nz-alert>
                                <nz-form-item
                                    *ngIf="impactCategories?.length || impactCategoriesLoading"
                                >
                                    <nz-skeleton
                                        [nzLoading]="impactCategoriesLoading"
                                        [nzActive]="true"
                                    >
                                        <nz-form-label [style.marginBottom.rem]="0.5" nzRequired
                                            >Client breakout table ImpactSerials:</nz-form-label
                                        >
                                        <nz-form-control
                                            [class.disabled]="
                                                !selectedTableTypeIds.includes(
                                                    tableTypesEnum.BREAKOUT_TABLE
                                                )
                                            "
                                            [nzSpan]="24"
                                        >
                                            <nz-checkbox-wrapper
                                                (nzOnChange)="
                                                    onChangeBreakoutTableImpactSerials($event)
                                                "
                                            >
                                                <label
                                                    *ngFor="
                                                        let category of impactCategories;
                                                        let i = index
                                                    "
                                                    nz-checkbox
                                                    [nzChecked]="selectedImpactSerials.includes(category.impactSerial)"
                                                    [nzValue]="category.impactSerial"
                                                >
                                                    {{ category.impactSerial }} -
                                                    {{ category.impactName }}
                                                </label>
                                            </nz-checkbox-wrapper>
                                        </nz-form-control>
                                    </nz-skeleton>
                                </nz-form-item> 
                            </div>
                        </div>
                    </div>
                    <div class="step-action">
                        <button (click)="onCancel()"
                            type="button"
                            nzSize="large"
                            nz-button
                            [disabled]="saving"
                            nzType="default"
                            nzDanger
                        >
                            Cancel
                        </button>
                        <button (click)="onPreviousClick()"  type="button"
                            nzSize="large"
                            nz-button
                            [disabled]="saving || createStepIndex === 0"
                            nzType="default"
                        >
                            Previous
                        </button>
                        <button
                            (click)="onNextClick()"
                            type="button"
                            nzSize="large"
                            nz-button
                            [nzLoading]="saving"
                            [disabled]="!clientDatatableNames.length || saving || !tableFormGroup.valid || (createStepIndex === 1 && !selectedTableTypeIds.length)"
                            nzType="primary"
                        >
                            <span>
                                {{ finalStepIndex === createStepIndex ? 'Generate Reporting Tables' : 'Next'}}
                            </span>
                        </button>
                        @if (finalStepIndex === createStepIndex) {
                            <button (click)="onSubmit(false, true)" *hasPermission="['NEW_LCA_ENGINE']" nzSize="large" type="button" nz-button nzDanger nzType="primary" [nzLoading]="saving"  [disabled]="!clientDatatableNames.length || saving || !tableFormGroup.valid || (createStepIndex === 1 && !selectedTableTypeIds.length)">
                                Generate Reporting Tables (NEW ENGINE)
                            </button>
                        }
                    </div>
                </form>
            </div>
        </div>
    </ng-container>
</ng-template>
<ng-template #inputLengthTracker>
    <span class="input-length-tracker">
        {{ tableFormGroup.value?.tableName?.length || 0 }} /
        {{ tableNameLength }}
    </span>
</ng-template>

<ng-template #loadingContent>
    <div nz-flex nzAlign="center" nzJustify="center" class="loading-container">
        <nz-spin nzSimple [nzSize]="'large'" nzTip="Loading..."></nz-spin>
    </div>
</ng-template>