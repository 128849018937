import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
    selector: 'app-password-page',
    templateUrl: './password-page.component.html',
    styleUrls: ['./password-page.component.less'],
})
export class PasswordPageComponent implements OnInit, OnDestroy {
    enabled: boolean = false;
    email: string;

    errMessage: string =
        'This link is invalid, you will be redirected to the AITrack login page.';

    ngDestroyed$ = new Subject();

    constructor(
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private modal: NzModalService
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params) => {
            const token = params['t'];
            if (token) {
                this.userService
                    .validateToken(token)
                    .pipe(takeUntil(this.ngDestroyed$))
                    .subscribe((result) => {
                        console.log(result);
                        if (result.payload) {
                            this.enabled = true;
                            this.email = result.payload;
                        } else {
                            this.showModal('Error message', this.errMessage);
                        }
                    });
            } else {
                this.showModal('Error message', this.errMessage);
            }
        });
    }

    showModal(title, content) {
        this.modal.info({
            nzTitle: title,
            nzContent: content,
            nzClosable: false,
            nzOnOk: () => {
                this.router.navigateByUrl('/login');
            },
        });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }
}
