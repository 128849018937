<div style="overflow: auto; height: calc(100vh - 2rem)">
    <div
        nz-row
        [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }"
        style="width: 100%"
    >
        <div nz-col class="gutter-row" [nzSpan]="24">
            <h2 class="welcome-message">
                Welcome, <span class="login-id">{{ userFullName }}</span
                >!
                <span
                    nz-tooltip
                    [nzTooltipTitle]="
                        userLastLogin | date: 'dd MMM yyyy, h:mm a'
                    "
                    class="last-login"
                    *ngIf="userLastLogin"
                    >last login: {{ formatDate(userLastLogin) }}
                </span>
            </h2>
        </div>
    </div>

    <!-- activity history -->
    <ng-container *hasPermission="['AIADMIN']">
        <div
            nz-row
            [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }"
            style="width: 100%"
        >
            <div nz-col class="gutter-row" [nzSpan]="24">
                <ng-container *ngTemplateOutlet="activityHistory">
                    <!-- activity history -->
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-container *hasPermission="['AIUSER']">
        <ng-container
            *hasPermission="['UPLOAD_PORTAL_MGMT', 'UPLOAD_PORTAL_MGMT_VIEW']"
        >
            <div
                nz-row
                [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }"
                style="width: 100%"
            >
                <div nz-col class="gutter-row" [nzSpan]="24">
                    <ng-container *ngTemplateOutlet="activityHistory">
                        <!-- activity history -->
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *hasPermission="['CLIENTADMIN', 'CLIENTUSER']">
        <ng-container *hasPermission="['UPLOAD_PORTAL']">
            <div
                nz-row
                [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }"
                style="width: 100%"
            >
                <div nz-col class="gutter-row" [nzSpan]="24">
                    <ng-container *ngTemplateOutlet="activityHistory">
                        <!-- activity history -->
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <!-- reports & statuses -->
    <ng-container>
        <div
            *ngIf="showClientReports"
            nz-row
            [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }"
            style="width: 100%; padding-top: 0.5rem"
        >
            <div nz-col class="gutter-row" [nzSpan]="24">
                <ng-container *ngTemplateOutlet="recentlyViewedReports">
                    <!-- recently viewed reports -->
                </ng-container>
            </div>
        </div>
        <ng-container *ngIf="userUploadStatuses?.length">
            <div
                *hasPermission="['UPLOAD_PORTAL', 'CLIENTUSER']"
                nz-row
                [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }"
                style="width: 100%; padding-top: 0.5rem"
            >
                <div nz-col class="gutter-row" [nzSpan]="12">
                    <ng-container *ngTemplateOutlet="uploadPortalStatus">
                        <!-- upload portal status -->
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *hasPermission="['AIADMIN', 'AIUSER']">
        <ng-container
            *hasPermission="['REPORT_MGMT_VIEW', 'REPORT_MGMT', 'AIADMIN']"
        >
            <div
                nz-row
                [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }"
                style="width: 100%; padding-top: 0.5rem"
            >
                <div nz-col class="gutter-row" [nzSpan]="24">
                    <ng-container *ngTemplateOutlet="recentlyViewedReports">
                        <!-- recently viewed reports -->
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <!-- graphs -->
    <div
        *hasPermission="['AIADMIN']"
        nz-row
        [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }"
        style="width: 100%; padding-top: 0.5rem"
    >
        <div nz-col class="gutter-row" [nzSpan]="7">
            <div class="box">
                <div class="header" nz-row style="width: 100%">
                    <h5 nz-col class="box-header-text">
                        Number of Logins over the last 30 days
                    </h5>
                </div>
                <div nz-row style="height: 15rem">
                    <echarts
                        style="width: 100%"
                        [initOpts]="{ rendered: 'svg', height: '240px' }"
                        [options]="option"
                        class="chart"
                    ></echarts>
                </div>
            </div>
        </div>
        <div nz-col class="gutter-row" [nzSpan]="7">
            <div class="box">
                <div class="header" nz-row style="width: 100%">
                    <h5 nz-col class="box-header-text">Client Users</h5>
                </div>
                <div nz-row style="height: 15rem">
                    <echarts
                        style="width: 100%"
                        [initOpts]="{ rendered: 'svg', height: '240px' }"
                        [options]="clientMetricsOption"
                        class="chart"
                    ></echarts>
                </div>
            </div>
        </div>
        <div nz-col class="gutter-row" [nzSpan]="10">
            <div class="box" *hasPermission="['AIADMIN']">
                <div
                    class="header"
                    nz-row
                    style="
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    "
                >
                    <h5 nz-col class="box-header-text" [nzSpan]="14">
                        Recent User Logins
                    </h5>
                    <nz-form-item
                        nz-col
                        class="gutter-row"
                        [nzSpan]="10"
                        style="text-align: right"
                    >
                        <nz-form-control style="width: 100%">
                            <nz-select
                                nzShowSearch
                                nzAllowClear
                                nzSize="large"
                                [nzLoading]="userLoginsLoading"
                                [(ngModel)]="userLoginsClientId"
                                nzPlaceHolder="All clients"
                                (ngModelChange)="
                                    onClientOfUserLoginsSelected($event)
                                "
                            >
                                <nz-option
                                    nzValue=""
                                    nzLabel="All Clients"
                                ></nz-option>
                                <nz-option
                                    *ngFor="let customer of customers"
                                    [nzLabel]="customer.clientName"
                                    [nzValue]="customer.clientId"
                                >
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div class="content">
                    <nz-list nzSize="small">
                        <nz-list-item *ngFor="let item of userLogins">
                            <div class="user-login-info">
                                <div class="user-login-info__details">
                                    <b>
                                        <i nz-icon nzType="users"></i>
                                        {{ item.userLogin }}
                                    </b>
                                    <div>{{ item.clientName }}</div>
                                </div>
                                <div
                                    nz-tooltip
                                    [nzTooltipTitle]="
                                        item.createdStamp
                                            | date: 'dd MMM yyyy, h:mm a'
                                    "
                                    class="user-login-info__date"
                                >
                                    {{ formatDate(item.createdStamp) }}
                                </div>
                            </div>
                        </nz-list-item>
                    </nz-list>
                </div>
            </div>
        </div>
    </div>

    <!-- TEMPLATES -->
    <!-- RECENTLY VIEWED REPORTS -->
    <ng-template #recentlyViewedReports>
        <div class="box" *ngIf="userReportLogs?.length">
            <div class="header" nz-row style="width: 100%">
                <h5 nz-col class="box-header-text">Recently viewed reports</h5>
            </div>
            <div class="content">
                <ng-container
                    *ngIf="userReportLogsLoading; else userReportLogsList"
                >
                    <div class="loading-report-logs">
                        <nz-spin nzSimple></nz-spin>
                    </div>
                </ng-container>
                <ng-template #userReportLogsList>
                    <ul class="report-log-list">
                        <li
                            class="report-log-item"
                            *ngFor="let item of userReportLogs"
                        >
                            <div class="report-name">
                                <i nz-icon nzType="reports"></i>
                                {{ item.reportName }}
                            </div>
                            <div
                                *hasPermission="['AIADMIN', 'AIUSER']"
                                class="report-log-item__client"
                            >
                                {{ item.clientName }}
                            </div>
                            <div class="report-groups">
                                <div
                                    class="report-group"
                                    *ngFor="let rg of item.reportGroups"
                                >
                                    <div
                                        class="report-group-color"
                                        [style.backgroundColor]="rg.groupColor"
                                    ></div>
                                    <div class="report-group-name">
                                        {{ rg.groupName }}
                                    </div>
                                </div>
                            </div>
                            <a
                                nz-button
                                nzType="link"
                                nzBlock
                                [routerLink]="'/reports/pbi/' + item.reportId"
                            >
                                View report
                                <span
                                    nz-icon
                                    nzType="right"
                                    nzTheme="outline"
                                ></span>
                            </a>
                        </li>
                    </ul>
                </ng-template>
            </div>
        </div>
    </ng-template>
    <!-- ACTIVITY HISTORY -->
    <ng-template #activityHistory>
        <div class="box activity-history-content">
            <div class="header" nz-row style="width: 100%">
                <h5 nz-col [nzSpan]="7" class="box-header-text">
                    Upload Portal Activity History
                </h5>
                <div
                    nz-col
                    class="gutter-row"
                    [nzSpan]="17"
                    style="display: flex; align-items: center; gap: 0.5rem"
                >
                    <nz-form-item
                        style="width: 100%"
                        *hasPermission="['AIUSER', 'AIADMIN']"
                    >
                        <nz-form-label>Client</nz-form-label>
                        <nz-form-control style="width: 100%">
                            <nz-select
                                nzShowSearch
                                nzAllowClear
                                nzSize="large"
                                [nzLoading]="customersLoading"
                                [(ngModel)]="clientId"
                                nzPlaceHolder="All clients"
                                (ngModelChange)="onClientChanged($event)"
                                [nzCustomTemplate]="dropdownSelectedTmpl"
                            >
                                <nz-option
                                    nzValue=""
                                    nzLabel="All Clients"
                                ></nz-option>
                                <nz-option
                                    *ngFor="let customer of customers"
                                    [nzValue]="customer.clientId"
                                    [nzLabel]="customer.clientName"
                                    nzCustomContent
                                >
                                    <div class="dropdown-option-label">
                                        {{ customer.clientName }}
                                    </div>
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item style="width: 100%">
                        <nz-form-label>Upload Types</nz-form-label>
                        <nz-form-control style="width: 100%">
                            <nz-select
                                nzSize="large"
                                nzAllowClear
                                nzShowSearch
                                [nzLoading]="uploadPortalTypesLoading"
                                [(ngModel)]="selectedUploadPortalTypeId"
                                (ngModelChange)="
                                    onUploadPortalTypeChanged($event)
                                "
                                nzPlaceHolder="All upload types"
                                [nzCustomTemplate]="dropdownSelectedTmpl"
                            >
                                <nz-option
                                    nzValue=""
                                    nzLabel="All Types"
                                ></nz-option>
                                <nz-option
                                    *ngFor="
                                        let uploadPortalType of uploadPortalTypes
                                    "
                                    [nzLabel]="
                                        uploadPortalType.uploadPortalTypeName
                                    "
                                    [nzValue]="uploadPortalType.uploadPortalTypeId"
                                    nzCustomContent
                                >
                                    <div class="dropdown-option-label">
                                        {{ uploadPortalType.uploadPortalTypeName }}
                                    </div>
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item style="width: 100%">
                        <nz-form-label>Upload Portals</nz-form-label>
                        <nz-form-control style="width: 100%">
                            <nz-select
                                nzSize="large"
                                nzShowSearch
                                nzAllowClear
                                [nzLoading]="userUploadPortalsLoading"
                                [(ngModel)]="selectedUploadPortalId"
                                (ngModelChange)="onUploadPortalChanged()"
                                nzPlaceHolder="Upload portals"
                                [nzCustomTemplate]="dropdownSelectedTmpl"
                            >
                                <nz-option
                                    nzValue=""
                                    nzLabel="All Portals"
                                ></nz-option>
                                <nz-option
                                    *ngFor="let uploadPortal of userUploadPortals"
                                    [nzLabel]="uploadPortal.uploadPortalName"
                                    [nzValue]="uploadPortal.uploadPortalId"
                                    nzCustomContent
                                >
                                    <div class="dropdown-option-label">
                                        {{ uploadPortal.uploadPortalName }}
                                    </div>
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item style="width: 100%">
                        <nz-form-label>Events</nz-form-label>
                        <nz-form-control style="width: 100%">
                            <nz-select
                                nzShowSearch
                                nzAllowClear
                                nzSize="large"
                                [nzLoading]="eventsLoading"
                                [(ngModel)]="event"
                                nzPlaceHolder="All events"
                                (ngModelChange)="onEventChanged($event)"
                                [nzCustomTemplate]="dropdownSelectedTmpl"
                            >
                                <nz-option
                                    nzValue=""
                                    nzLabel="All Events"
                                ></nz-option>
                                <nz-option
                                    *ngFor="let evnt of events"
                                    [nzLabel]="evnt"
                                    [nzValue]="evnt"
                                    nzCustomContent
                                >
                                    <div class="dropdown-option-label">
                                        {{ evnt }}
                                    </div>
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div class="content upload-history-content">
                <ng-container
                    *ngIf="!userDataUploadHistoryLoading; else loadingBox"
                >
                    <ng-container
                        *ngIf="
                            userDataUploadHistoryVisibleRows.length > 0;
                            else noDataBox
                        "
                    >
                        <div
                            nz-row
                            [nzGutter]="8"
                            style="max-width: 100%"
                            *ngFor="
                                let row of userDataUploadHistoryVisibleRows;
                                let i = index
                            "
                        >
                            <div nz-col nzSpan="12">
                                <app-upload-history-tile
                                    [item]="userDataUploadHistory[i]"
                                ></app-upload-history-tile>
                            </div>
                            <div
                                nz-col
                                nzSpan="12"
                                *ngIf="userDataUploadHistory[i + 1]"
                            >
                                <app-upload-history-tile
                                    [item]="userDataUploadHistory[i + 1]"
                                ></app-upload-history-tile>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </ng-template>
    <!-- UPLOAD PORTAL STATUS -->
    <ng-template #uploadPortalStatus>
        <div class="box" *ngIf="userUploadStatuses?.length">
            <div class="header" nz-row style="width: 100%">
                <h5 nz-col class="box-header-text">Upload Portal Status</h5>
            </div>
            <div class="content">
                <nz-list nzSize="small">
                    <nz-list-item *ngFor="let item of userUploadStatuses">
                        <div class="upload-status-info">
                            <div class="upload-status-info__details">
                                <i nz-icon nzType="portal-type"></i>
                                <b>
                                    {{ item.uploadPortalName }}
                                </b>
                                <div>{{ item.uploadPortalTypeName }}</div>
                            </div>
                            <div class="upload-status-info__date">
                                <app-upload-portal-status
                                    [statusId]="item.collectionStatusId"
                                    [statusName]="item.collectionStatusName"
                                    [statusColor]="item.collectionStatusColor"
                                >
                                </app-upload-portal-status>
                            </div>
                        </div>
                    </nz-list-item>
                </nz-list>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #loadingBox>
    <div class="centered-content-box">
        <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
    </div>
</ng-template>

<ng-template #noDataBox>
    <div class="centered-content-box">
        <p class="no-data-text">No data</p>
    </div>
</ng-template>

<ng-template #dropdownSelectedTmpl let-selected>
    <div class="dropdown-selected-option">
        {{ selected.nzLabel }}
    </div>
</ng-template>
