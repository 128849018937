<div class="assurance-table-filters">
    <nz-alert  *ngIf="saveError" nzType="error" [nzMessage]="saveError" nzShowIcon></nz-alert>
    <div nz-flex nzAlign="flex-end" nzWrap="wrap" nzGap="4px 8px">
        @for (filterConfig of enabledFilters; track filterConfig.queryParam) {
            <div nz-flex nzAlign="flex-end" [nzGap]="8">
                <nz-form-item>
                    <nz-form-label>{{filterConfig.label}}</nz-form-label>
                    <nz-form-control>
                        @switch (true) {
                            @case (filterKeys.ReportYear === filterConfig.queryParam) {
                                <nz-select
                                    nzShowSearch
                                    [nzLoading]="isFilterLoading(filterConfig.queryParam)"
                                    [nzPlaceHolder]="filterConfig.placeholder"
                                    nzAllowClear
                                    nzSize="large"
                                    [nzDisabled]="isFilterLoading(filterConfig.queryParam)"
                                    [(ngModel)]="filter[filterConfig.queryParam]"
                                    (ngModelChange)="onTableFilterChanged($event, filterConfig.queryParam)"
                                >
                                    @for (value of getFilterValues(filterConfig.queryParam); track value) {
                                        <nz-option [nzValue]="value" [nzLabel]="value"></nz-option>
                                    }
                                </nz-select>
                            }
                            @case (filterKeys.ImpactAssessmentMethodology === filterConfig.queryParam) {
                                <nz-select
                                    nzShowSearch
                                    [nzLoading]="isFilterLoading(filterConfig.queryParam)"
                                    [nzPlaceHolder]="filterConfig.placeholder"
                                    nzAllowClear
                                    nzSize="large"
                                    [nzDisabled]="isFilterLoading(filterConfig.queryParam) || !filter[filterKeys.ReportYear]"
                                    [(ngModel)]="filter[filterConfig.queryParam]"
                                    (ngModelChange)="onTableFilterChanged($event, filterConfig.queryParam)"
                                >
                                    @for (item of getFilterValues(filterConfig.queryParam); track item.value) {
                                        <nz-option [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
                                    }
                                </nz-select>
                            }
                            @case ([filterKeys.ActivityUnit, filterKeys.ModelName, filterKeys.DataSource, filterKeys.ReportingCategory].includes(filterConfig.queryParam)) {
                                <nz-select
                                    nzShowSearch
                                    [nzLoading]="isFilterLoading(filterConfig.queryParam)"
                                    [nzPlaceHolder]="filterConfig.placeholder"
                                    nzAllowClear
                                    nzSize="large"
                                    [nzDisabled]="isFilterLoading(filterConfig.queryParam) || isNonRequiredFiltersDisabled"
                                    [(ngModel)]="filter[filterConfig.queryParam]"
                                    nzMode="multiple"
                                    [nzMaxTagCount]="1"
                                    (ngModelChange)="onTableFilterChanged($event, filterConfig.queryParam)"
                                >
                                    @for (value of getFilterValues(filterConfig.queryParam); track value) {
                                        <nz-option [nzValue]="value" [nzLabel]="value"></nz-option>
                                    }
                                </nz-select>
                            }
                            @case (filterKeys.ObservationID === filterConfig.queryParam) {
                                <nz-input-number-group nzSize="large">
                                    <nz-input-number
                                        nzSize="large"
                                        [style.width]="'100%'"
                                        [nzPlaceHolder]="filterConfig.placeholder"
                                        [nzDisabled]="isNonRequiredFiltersDisabled"
                                        [(ngModel)]="filter[filterConfig.queryParam]"
                                        (ngModelChange)="onTableFilterChanged($event, filterConfig.queryParam)"
                                        [nzStep]="1"
                                        [nzMin]="0"
                                    ></nz-input-number>
                                </nz-input-number-group>
                            }
                            @case (filterKeys.Date === filterConfig.queryParam) {
                                <nz-range-picker
                                    nzMode="date"
                                    nzSize="large"
                                    [nzDisabled]="isNonRequiredFiltersDisabled"
                                    [nzPlaceHolder]="['Start date', 'End date']"
                                    nzFormat="yyyy/MM/dd"
                                    [(ngModel)]="filter[filterConfig.queryParam]"
                                    (ngModelChange)="onTableFilterChanged($event, filterConfig.queryParam)"
                                >
                                </nz-range-picker>
                            }
                            @case (filterKeys.Site === filterConfig.queryParam) {
                                <nz-tree-select
                                    nzSize="large"
                                    [nzDropdownMatchSelectWidth]="false"
                                    [(ngModel)]="filter[filterConfig.queryParam]"
                                    [nzNodes]="getFilterValues(filterConfig.queryParam)"
                                    (ngModelChange)="onTableFilterChanged($event, filterConfig.queryParam)"
                                    [nzDisabled]="isFilterLoading(filterConfig.queryParam) || isNonRequiredFiltersDisabled"
                                    nzShowSearch
                                    nzCheckable
                                    nzAllowClear
                                    nzVirtualHeight="260px"
                                    [nzPlaceHolder]="filterConfig.placeholder"
                                    nzHideUnMatched
                                    [nzMaxTagCount]="1"
                                    [nzNotFoundContent]="noData"
                                ></nz-tree-select>
                            }
                            @case (filterKeys.Grouping === filterConfig.queryParam) {
                                <nz-tree-select
                                    nzSize="large"
                                    [nzDropdownStyle]="{ 'width.rem': 28 }"
                                    [nzDropdownMatchSelectWidth]="false"
                                    [(ngModel)]="filter[filterConfig.queryParam]"
                                    [nzNodes]="getFilterValues(filterConfig.queryParam)"
                                    (ngModelChange)="onTableFilterChanged($event, filterConfig.queryParam)"
                                    [nzDisabled]="isFilterLoading(filterConfig.queryParam) || isNonRequiredFiltersDisabled"
                                    nzShowSearch
                                    nzCheckable
                                    nzAllowClear
                                    nzVirtualHeight="260px"
                                    [nzPlaceHolder]="filterConfig.placeholder"
                                    nzHideUnMatched
                                    [nzMaxTagCount]="1"
                                    [nzNotFoundContent]="noData"
                                ></nz-tree-select>
                            }
                        }
                       
                    </nz-form-control>
                </nz-form-item>
                <ng-template #noData>
                    <div style="height: 200px; display: flex; justify-content: center; align-items: center; width: 100%; position: absolute; top: 0;">
                        @if (isFilterLoading(filterConfig.queryParam)) {
                            <nz-spin nzSimple></nz-spin>
                        } @else {
                            <div>No data</div>
                        }
                    </div>
                </ng-template>
                
            </div>
        }
        <nz-segmented [nzOptions]="accountingTypeOptions" [ngModel]="getEnergyAccountingIndex(filter.energyAccountingType)" (ngModelChange)="onTableFilterChanged($event, filterKeys.EnergyAccountingType)" nzSize="large"></nz-segmented>
        <button [style.width.px]="44" *hasPermission="['ASSURANCE_MGMT']" nz-tooltip nzTooltipPlacement="top" nzTooltipTitle="Report Configuration" [disabled]="!clientId" nz-button nzType="default" (click)="onSettingsClick()">
            <span nz-icon nzType="setting" nzTheme="outline"></span>
        </button>
        <a (click)="resetFilters()" class="reset-filter" [disabled]="hasLoadingFilter" *ngIf="isFiltersDirty" nz-button nzSize="small" nzType="link">
            Reset filters
        </a>
    </div>
</div>

<nz-drawer
    nzMaskClosable
    [nzWidth]="760"
    [nzVisible]="settingsView"
    nzTitle="Assurance Report Configuration"
    (nzOnClose)="onCloseSettings()"
>
    <ng-container *nzDrawerContent>
        @if (!configLoading) {
            <form *ngIf="reportYearsFormGroup" class="assurance-report-settings-form" [formGroup]="reportYearsFormGroup" nz-form>
                <div formArrayName="reportYears" nz-row nzGutter="4">
                    <ng-container *ngIf="reportYearsFormArray.controls?.length; else noReportYearsYet">
                        <ng-container *ngFor="let reportYearFormGroup of reportYearsFormArray.controls; let i = index">
                            <nz-alert
                                [style.width]="'100%'"
                                [style.marginBottom.rem]="0.5"
                                *ngIf="isRowDuplicated(i)"
                                nzType="error"
                                nzMessage="Duplicated row"
                                nzShowIcon
                            ></nz-alert>
                            <div class="report-year-form-container" [class.duplicated]="isRowDuplicated(i)" [id]="'new-report-year-' + i" [formGroup]="reportYearFormGroup" nz-col nzSpan="24">
                                <nz-form-item>
                                    <nz-form-label nzRequired>
                                        Report Year
                                    </nz-form-label>
                                    <nz-form-control
                                        [nzSpan]="24"
                                        nzValidateStatus
                                        nzHasFeedback
                                        [nzErrorTip]="reportYearErrorTmpl"
                                    >
                                        <nz-input-group nzSize="large">
                                            <input
                                                formControlName="reportYear"
                                                nz-input
                                                type="number"
                                                step="0"
                                                [min]="2000"
                                                placeholder="Enter report year"
                                            />
                                        </nz-input-group>
                                        <ng-template
                                            #reportYearErrorTmpl
                                            let-control
                                        >
                                            <ng-container
                                                *ngIf="control.hasError('required')"
                                            >
                                                Please enter report year
                                            </ng-container>
                                            <ng-container
                                                *ngIf="
                                                    control.hasError('min') ||
                                                    control.hasError('max')
                                                "
                                            >
                                                Please enter valid start year (min 2000)
                                            </ng-container>
                                        </ng-template>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-label>
                                        Select Assurance Table
                                    </nz-form-label>
                                    <nz-form-control>
                                        <nz-select
                                            nzShowSearch
                                            [nzLoading]="loadingAssuranceTables"
                                            nzPlaceHolder="Select table"
                                            nzAllowClear
                                            nzSize="large"
                                            formControlName="assuranceTableName"
                                        >
                                            @for (value of assuranceTables; track value) {
                                                <nz-option [nzValue]="value" [nzLabel]="value"></nz-option>
                                            }
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                                <a
                                    *ngIf="!saving"
                                    (click)="
                                        onRemoveReportYear(i)
                                    "
                                    nz-button
                                    nzSize="small"
                                    nzType="link"
                                    class="danger-link"
                                >
                                    <i nz-icon nzType="delete" nzTheme="outline"></i>
                                    <span>Remove</span>
                                </a>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="add-row-action">
                    <a
                        (click)="
                            onAddReportYearForm()
                        "
                        [disabled]="saving || loadingAssuranceTables || hasDuplicatedRows"
                        nz-button
                        nzSize="small"
                        nzType="link"
                    >
                        <i nz-icon nzType="plus" nzTheme="outline"></i>
                        <span>Add report year</span>
                    </a>
                </div>
            </form>
        } @else {
            <div class="loading-container">
                <nz-spin nzTip="Loading..." nzSimple></nz-spin>
            </div>
        }
       <nz-form-item>
            <nz-form-control
                class="drawer-actions-control"
                [nzSpan]="24"
            >
                <div class="drawer-controls">
                    <button
                        type="button"
                        nzSize="large"
                        nz-button
                        (click)="onSubmitReportConfig()"
                        nzType="primary"
                        [nzLoading]="saving"
                        [disabled]="saving || !reportYearsFormGroup?.valid || configLoading"
                    >
                        <span>Save</span>
                    </button>
                    <button
                        data-cy="attr-type-form__close-btn"
                        type="button"
                        nzSize="large"
                        nz-button
                        (click)="onCloseSettings()"
                        [disabled]="saving || configLoading"
                    >
                        <span>Close</span>
                    </button>
                </div>
            </nz-form-control>
        </nz-form-item>
        @for (tableName of columnsSettingTables; track tableName) {
            <div class="column-names-mapping">
                <h6>
                    <b>{{tableName}}</b> columns labels mapping
                </h6>
                <nz-table
                    #table
                    [nzSize]="'middle'"
                    [nzLoading]="getLocationColumnSettingsLoading(tableName)"
                    nzHideOnSinglePage
                    [nzData]="getTableColumnOptions(tableName)"
                    [nzFrontPagination]="false"
                    [nzShowPagination]="false"
                    [nzScroll]="{ y: '320px' }"
                >
                    <thead>
                        <tr>    
                            <th
                                nzWidth="60px"
                                [nzChecked]="getAllChecked(tableName)"
                                [nzIndeterminate]="getIndeterminate(tableName)"
                                (nzCheckedChange)="updateAllChecked(tableName)"
                            ></th>
                            <th>Column Name</th>
                            <th>Column Label</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of table.data">
                            <td  [nzChecked]="data.checked"
                                [nzDisabled]="data.disabled"
                                (nzCheckedChange)="updateSingleChecked(tableName, data.value)"
                            >
                            </td>
                            <td>{{data.value}}</td>
                            <td>
                                <input nz-input placeholder="Input column label" [(ngModel)]="data.label" [nzStatus]="!data.label ? 'warning' : undefined" nzSize="default" />
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </div>
        }
    </ng-container>
</nz-drawer>

<ng-template #noReportYearsYet>
    <div *ngIf="!loadingAssuranceTables" class="no-report-years-text">
        <p>No report years yet</p>
    </div>
</ng-template>