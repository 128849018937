<div class="login-form-container">
    <ng-container *ngIf="errorSSO$ | async">
        <nz-modal
            [nzClosable]="false"
            [nzVisible]="errorSSO$ | async"
            nzTitle="SSO Authentication Error"
            (nzOnOk)="handleSSOModalOk()"
            [nzCancelText]="null"
            [nzContent]="content"
        >
            <ng-template #content>
                There was an issue with signing you into AITrack using your
                identity provider.<br /><br />
                <b>Please exit the browser and try again.</b> <br /><br />
                If the issue persists, please contact Aligned Incentives support
                at: <i>support&#64;alignedincentives.com</i>
            </ng-template>
        </nz-modal>
    </ng-container>
    <ng-container *ngIf="(errorSSO$ | async) !== undefined">
        <nz-alert
            data-cy="error-alert"
            *ngIf="error$ | async"
            nzType="error"
            [nzMessage]="error$ | async"
            nzShowIcon
        ></nz-alert>
    </ng-container>
    <div class="logo">
        <i nz-icon nzType="ai-logo-detailed" nzTheme="fill"></i>
    </div>
    <form
        class="login-form"
        nz-form
        [formGroup]="loginForm"
        (ngSubmit)="submitForm()"
    >
        <div class="steps-content" [ngSwitch]="step$ | async">
            <ng-container *ngSwitchCase="0">
                <h1 data-cy="login-step-header" class="step-header">Log In</h1>
                <nz-form-item>
                    <nz-form-label nzRequired
                        >Enter e-mail address</nz-form-label
                    >
                    <nz-form-control
                        nzValidateStatus
                        nzHasFeedback
                        [nzErrorTip]="emailErrorTmpl"
                    >
                        <nz-input-group nzSize="large" nzPrefixIcon="mail">
                            <input
                                data-cy="email-input"
                                nz-input
                                formControlName="email"
                                placeholder="Email"
                            />
                        </nz-input-group>
                        <ng-template #emailErrorTmpl let-control>
                            <ng-container
                                *ngIf="
                                    control.hasError('required') &&
                                    (control.dirty || control.touched)
                                "
                            >
                                Please input your e-mail
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    control.hasError('email') &&
                                    (control.dirty || control.touched)
                                "
                            >
                                Please input correct e-mail
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <div class="form-buttons">
                    <button
                        data-cy="submit"
                        nzSize="large"
                        class="login-form-button"
                        nz-button
                        [nzLoading]="loading$ | async"
                        nzType="primary"
                        type="submit"
                    >
                        Continue
                    </button>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="1">
                <h1 data-cy="login-step-header" class="step-header">
                    Verification
                </h1>
                <ng-container
                    *ngIf="waitingRedirectSSO$ | async; else passwordForm"
                >
                    <div class="verification-spinner">
                        <nz-spin nzTip="Please, wait..."></nz-spin>
                    </div>
                </ng-container>
                <ng-template #passwordForm>
                    <nz-form-item>
                        <nz-form-label data-cy="password-input-label" nzRequired
                            >Password for
                            {{ userLoginId$ | async }}</nz-form-label
                        >
                        <nz-form-control
                            nzErrorTip="Please input your password"
                        >
                            <nz-input-group nzSize="large" nzPrefixIcon="lock">
                                <input
                                    data-cy="password-input"
                                    type="password"
                                    nz-input
                                    nzSize="large"
                                    formControlName="password"
                                    placeholder="Password"
                                />
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-control>
                            <label
                                data-cy="remember-me"
                                nz-checkbox
                                formControlName="remember"
                            >
                                <span>Remember me</span>
                            </label>
                            <button
                                (click)="onForgotPassword()"
                                nz-button
                                nzType="link"
                                type="button"
                                class="login-form-forgot"
                            >
                                Forgot password?
                            </button>
                        </nz-form-control>
                    </nz-form-item>
                    <div class="form-buttons">
                        <button
                            data-cy="submit"
                            nzSize="large"
                            nz-button
                            [nzLoading]="loading$ | async"
                            nzType="primary"
                            type="submit"
                        >
                            Log In
                        </button>
                        <button
                            data-cy="switch-account-btn"
                            nzSize="large"
                            nz-button
                            (click)="switchToAnotherAccount()"
                        >
                            Switch to another account
                        </button>
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </form>
</div>

<nz-modal
    [nzClosable]="false"
    [(nzVisible)]="isForgotModalVisible"
    nzTitle="Forgot your Password?"
    [nzFooter]="footer"
    [nzContent]="content"
>
    <ng-template #content>
        <nz-alert
            *ngIf="resetError"
            nzType="error"
            [nzMessage]="resetError"
            nzShowIcon
        ></nz-alert>
        <div *ngIf="!isResetRequestSent">
            <div style="padding-bottom: 20px">
                Please enter the email address associated with your AITrack
                account. An email containing a password reset link will be sent
                to this account.
            </div>
            <form nz-form [formGroup]="resetForm">
                <div>
                    <nz-form-item>
                        <nz-form-label data-cy="email-input-label" nzRequired
                            >Your email address</nz-form-label
                        >
                        <nz-form-control
                            nzValidateStatus
                            nzHasFeedback
                            [nzErrorTip]="emailErrorTmpl"
                        >
                            <nz-input-group nzSize="large" nzPrefixIcon="lock">
                                <input
                                    data-cy="email-input"
                                    type="email"
                                    nz-input
                                    nzSize="large"
                                    formControlName="email"
                                    placeholder="Email address"
                                />
                                <ng-template #emailErrorTmpl let-control>
                                    <ng-container
                                        *ngIf="control.hasError('required')"
                                        >* Email is required<br
                                    /></ng-container>
                                    <ng-container
                                        *ngIf="control.hasError('email')"
                                        >* Email should have a valid
                                        format</ng-container
                                    >
                                </ng-template>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </form>
        </div>
        <div *ngIf="isResetRequestSent">
            <div>
                Please follow the link in the email to reset your password.
            </div>
        </div>
    </ng-template>
    <ng-template #footer>
        <div class="action-btns">
            <button
                data-cy="submit"
                *ngIf="!isResetRequestSent"
                nz-button
                nzType="primary"
                type="submit"
                [disabled]="
                    !resetForm.valid || !resetForm.dirty || isResetRequestSent
                "
                (click)="resetPassword()"
            >
                Submit
            </button>
            <button
                nz-button
                nzType="default"
                *ngIf="!isResetRequestSent"
                (click)="handleCancel()"
            >
                Cancel
            </button>
            <button
                nz-button
                nzType="primary"
                *ngIf="isResetRequestSent"
                (click)="handleCancel()"
            >
                Close
            </button>
        </div>
    </ng-template>
</nz-modal>
