import {
    Component,
    OnInit,
    OnDestroy,
    Output,
    EventEmitter,
    Input,
    SimpleChanges,
} from '@angular/core';
import _get from 'lodash/get';
import { UploadPortalStatus, PortalStatus } from '../../../../store/models/upload-portal-status.model';
import {
    UploadPortalService,
    UploadPortalStatusFilter,
} from '../../../../core/services/upload-portal/upload-portal.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import _orderBy from 'lodash/orderBy';
import _last from 'lodash/last';
import { GlobalService } from 'src/app/core/services/global/global.service';

const PAGE_SIZE = 100;
@Component({
    selector: 'app-portal-statuses-table',
    templateUrl: './portal-statuses-table.component.html',
    styleUrls: ['./portal-statuses-table.component.less'],
})
export class PortalStatusesTableComponent implements OnInit, OnDestroy {
    clientId: string;
    statuses: PortalStatus[] = [];
    statusesLoading: boolean = false;

    formMode: boolean = false;
    selectedStatus: UploadPortalStatus;

    @Input() searchText: string;

    columns = [
        {
            title: 'Status name',
            key: 'status_name',
            sortDirections: ['descend', 'ascend', null],
            width: '68%',
        },
    ];

    filterForm: Subject<UploadPortalStatusFilter> = new Subject();
    searchFilter = {
        searchText: '',
        lastUploadPortalStatusId: '',
        lastCollectionStatusId: '',
        lastCreatedStamp: '',
        columnName: 'collection_status_name',
        sortDirection: 'ascend',
    };
    statusesLoaded: boolean = false;
    ngDestroyed$ = new Subject();
    query: string;

    constructor(
        private uploadPortalService: UploadPortalService,
        private modal: NzModalService,
        private message: NzMessageService,
        private globalService: GlobalService
    ) {
        this.globalService.clientId$.pipe(takeUntil(this.ngDestroyed$))
            .subscribe(
                clientId => {
                    if (clientId) {
                        this.clientId = clientId;
                        this.fetchStatuses(this.clientId)
                    }
                }
            );
    }

    ngOnInit() {
        this.filterForm
            .pipe(takeUntil(this.ngDestroyed$), debounceTime(400))
            .subscribe((filter) => {
                this.fetchStatuses(this.clientId, filter);
            });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next(true);
    }

    closeStatusFormMode(shouldReload?: boolean) {
        this.formMode = false;
        this.selectedStatus = undefined;

        if (shouldReload) {
            this.searchFilter.lastCollectionStatusId = '';
            this.searchFilter.lastCreatedStamp = '';
            this.searchFilter.lastUploadPortalStatusId = '';
            this.fetchStatuses(this.clientId);
        }
    }

    onEditStatus(status: UploadPortalStatus) {
        this.formMode = true;
        this.selectedStatus = status;
    }

    ngOnChanges(changes: SimpleChanges) {
        let change = changes['searchText'];
        if (change) {
            if (change.currentValue != change.previousValue) {
                this.statusesLoaded = false;
            }
            this.searchFilter.searchText = change.currentValue;
            this.filterForm.next({
                searchText: change.currentValue,
            });
            this.searchFilter.lastCollectionStatusId = '';
            this.searchFilter.lastCreatedStamp = '';
            this.searchFilter.lastUploadPortalStatusId = '';
        }
    }

    fetchStatuses(clientId: string, filter?: UploadPortalStatusFilter) {
        this.statusesLoading = true;
        this.uploadPortalService.getAllStatuses(clientId, filter ? {...filter, limit: PAGE_SIZE} : { limit: PAGE_SIZE }).subscribe({
            next: (payload) => {
                const uploadStatuses = _get(payload, 'payload', []);
                if (uploadStatuses.length < PAGE_SIZE) {
                    this.statusesLoaded = true;
                }
                if (filter && filter.lastCollectionStatusId) {
                    if (uploadStatuses.length) {
                        this.statuses = [...this.statuses, ...uploadStatuses];
                    }
                } else {
                    this.statuses = uploadStatuses;
                }
            },
            complete: () => {
                this.statusesLoading = false;
                this.query = this.searchFilter.searchText;
            },
        });
    }

    onRemoveStatus(collectionStatusId: string, collectionStatusName: string) {
        this.modal.confirm({
            nzTitle: `Do you want to remove <b>${collectionStatusName}</b>?`,
            nzContent: `
        If you delete it you will not be able to recover status.<br>
        Do you want to delete the status?
      `,
            nzOnOk: () =>
                this.uploadPortalService
                    .deleteCollectionStatus(collectionStatusId)
                    .pipe(takeUntil(this.ngDestroyed$))
                    .subscribe(() => {
                        this.message.success(
                            `Status <b>${collectionStatusName}</b> has been deleted`,
                            { nzDuration: 3000 }
                        );
                        this.statuses = this.statuses.filter(status => status.collectionStatusId !== collectionStatusId);
                    }),
            nzOkText: 'Yes, remove',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzCancelText: 'Close',
            nzClosable: false,
            nzOnCancel: () => {},
        });
    }

    orderChange(col: any) {
        this.statusesLoading = true;
        this.statusesLoaded = false;
        this.searchFilter.columnName = col.key;
        this.searchFilter.sortDirection = col.value;
        this.filterForm.next({
            columnName: this.searchFilter.columnName,
            sortDirection: this.searchFilter.sortDirection,
        });
    }

    onTableScrolled() {
        if (!this.statusesLoaded) {
            const coll: PortalStatus = _last(this.statuses);
            if (coll && coll.collectionStatusId) {
                const {
                    collectionStatusId,
                    collectionStatusName,
                    createdStamp,
                } = coll;
                this.searchFilter.lastCollectionStatusId =
                    collectionStatusId.valueOf();
                this.filterForm.next({
                    lastCollectionStatusId:
                        this.searchFilter.lastCollectionStatusId,
                    lastCollectionStatusName: collectionStatusName,
                    columnName: this.searchFilter.columnName,
                    sortDirection: this.searchFilter.sortDirection,
                    lastCreatedStamp: createdStamp,
                });
            }
        }
    }

    onCreateNewPortalStatus() {
        this.formMode = true;
        this.selectedStatus = undefined;
    }
}
