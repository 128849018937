<form
    *hasPermission="['ROLES_MGMT']; class: 'disabled'"
    nz-form
    [formGroup]="roleForm"
>
    <div nz-row nzGutter="4">
        <nz-alert
            *ngIf="error"
            nzType="error"
            [nzMessage]="error"
            nzShowIcon
        ></nz-alert>
        <div nz-col nzSpan="24">
            <nz-form-item>
                <nz-form-label nzRequired>Role name</nz-form-label>
                <nz-form-control
                    [nzSpan]="24"
                    nzValidateStatus
                    nzHasFeedback
                    [nzErrorTip]="roleNameErrorTmpl"
                >
                    <nz-input-group nzSize="large">
                        <input
                            data-cy="role-input__name"
                            formControlName="roleName"
                            nz-input
                            placeholder="Enter Role Name"
                        />
                    </nz-input-group>
                    <ng-template #roleNameErrorTmpl let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Please enter role name
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="permissions?.length || permissionsLoading">
                <nz-skeleton [nzLoading]="permissionsLoading" [nzActive]="true">
                    <nz-form-label [nzSpan]="24">Permissions</nz-form-label>
                    <nz-form-control
                        [nzSpan]="24"
                        [nzErrorTip]="permissionIdsErrorTmpl"
                    >
                        <nz-checkbox-wrapper
                            (nzOnChange)="onPermissionCheckboxesChange($event)"
                        >
                            <label
                                data-cy="role-form-checkbox__permission"
                                *ngFor="let permission of permissions"
                                [id]="'resourceId__' + permission.appResourceId"
                                [formControlName]="
                                    'permission#' + permission.appResourceId
                                "
                                nz-checkbox
                                [nzValue]="permission.appResourceId"
                                >{{ permission.appResourceName }}</label
                            >
                        </nz-checkbox-wrapper>
                    </nz-form-control>
                    <ng-template #permissionIdsErrorTmpl let-control>
                        Please select at least 1 permission
                    </ng-template>
                </nz-skeleton>
            </nz-form-item>
            <div *ngIf="clientService.aiClientId === clientId">
                <div
                    class="admin-role-form__status-switcher"
                    *hasPermission="['SUPERUSER']"
                >
                    <h3>Admin</h3>
                    <nz-switch formControlName="isAdmin"> </nz-switch>
                </div>
            </div>
            <nz-form-item>
                <nz-form-control class="drawer-actions-control" [nzSpan]="24">
                    <div class="drawer-controls">
                        <button
                            data-cy="role-form__submit-btn"
                            [nzLoading]="saving"
                            type="button"
                            nzSize="large"
                            nz-button
                            [disabled]="
                                !roleForm.valid || !roleForm.dirty || saving
                            "
                            (click)="submitRoleForm()"
                            nzType="primary"
                        >
                            <span>Save</span>
                        </button>
                        <button
                            data-cy="role-form__close-btn"
                            type="button"
                            nzSize="large"
                            nz-button
                            (click)="onCancel()"
                        >
                            <span>Close</span>
                        </button>
                    </div>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
</form>
