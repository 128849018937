import { LCASandBoxStatuses } from "src/app/components/pages/lca-engine-sandbox-page/lca-engine-sandbox-page.component";
import { LCAEngineStatus } from "./lca-impact-type";

export enum NotificationEventType {
    PROCESS_RUN_STATUS = 'PROCESS_RUN_STATUS',
    LCA_SANDBOX_STATUS = 'LCA_SANDBOX_STATUS',
    STRATEGY_REPORT_REFRESH_STATUS = 'STRATEGY_REPORT_REFRESH_STATUS'
}

export enum ProcessTypeId {
    StrategyReportRefresh = 'STRATEGY_REPORT_REFRESH'
}

export type NotificationMessage<T extends NotificationEventType = NotificationEventType> = {
    notificationTypeId: T;
    body: T extends NotificationEventType.PROCESS_RUN_STATUS
        ? { processTypeId: ProcessTypeId, statusId: string, runId: string, message: string }
        : T extends NotificationEventType.STRATEGY_REPORT_REFRESH_STATUS
        ? { powerbiRefreshStatus:  { powerbiRefreshing: boolean, lastPowerbiRefreshedStamp: number, powerbiRefreshSuccess: boolean } }
        : T extends NotificationEventType.LCA_SANDBOX_STATUS
        ? { status: LCAEngineStatus }
        : unknown;
}
