import { UntypedFormGroup, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';

const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

// custom validator to check that two fields match
export function UUIDValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
        if (control && control.value && !UUID_REGEX.test(control.value)) {
            return { uuid: true };
        }

        return null;
    };
}
