import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UploadPortal } from '../../../store/models/upload-portal';
import { ApiService } from '../api/api.service';
import { UploadPortalVersion } from '../../../store/models/upload-portal-version';
import { UploadPortalType } from '../../../store/models/upload-portal-type.model';
import { DataUploadHistory } from '../../../store/models/data-upload-history';
import {
    UploadPortalStatus,
    UploadPortalCollectionStatus,
    UserUploadCollectionStatus,
} from '../../../store/models/upload-portal-status.model';
import { UploadPortalStatusVersion } from '../../../store/models/upload-portal-status-version';
import { HttpHeaders } from '@angular/common/http';
import { DataDownloadHistoryItemAction } from 'src/app/store/models/data-download-history-item-action';
export interface UploadPortalTypeInput {
    uploadPortalTypeId?: string;
    uploadPortalTypeName: string;
    attrTypeIds?: string[];
    uploadPortalText?: string;
    clientId: string;
    collectionStatusEnabled?: boolean;
    collectionStatusIds?: string[];
}
export interface UploadPortalTypeFilter {
    clientId?: string;
    lastUploadPortalTypeName?: string;
    lastUploadPortalTypeId?: string;
    columnName?: string;
    sortDirection?: string;
    lastCreatedStamp?: number;
    searchText?: string;
    limit?: number;
}
export interface UploadPortalStatusFilter {
    searchText?: string;
    columnName?: string;
    sortDirection?: string;
    lastCollectionStatusId?: string;
    lastCollectionStatusName?: string;
    lastCreatedStamp?: number;
    limit?: number;
}
export interface UploadPortalHistoryFilter {
    searchText?: string;
    columnName?: string;
    sortDirection?: string;
    lastCollectionStatusId?: string;
    lastCollectionStatusName?: string;
    lastHistoryCreatedStamp?: number;
    lastRecordId?: string;
    eventTypes?: string[];
}
export interface UploadPortalFilterForm {
    clientId?: string;
    selectedStatuses?: string[];
    searchText?: string;
    columnName?: string;
    sortDirection?: string;
    lastCreatedStamp?: number;
    lastUploadPortalId?: string;
    lastUploadPortalName?: string;
    uploadPortalTypeId?: string;
    limit?: number;
}
@Injectable({
    providedIn: 'root',
})
export class UploadPortalService {
    constructor(private apiService: ApiService) {}

    getEvents(): Observable<{ payload: string[] }> {
        return this.apiService.get(`upload-portal-site/activity-history/events`);
    }

    getUploadPortals(
        clientId?: string,
        filter?: UploadPortalFilterForm
    ): Observable<{ payload: UploadPortal[] }> {
        const params = {
            clientId,
        };
        if (filter) {
            if (filter.columnName) {
                params['sortByColumnName'] = filter.columnName;
            }
            if (filter.sortDirection) {
                params['sortDirection'] = filter.sortDirection;
            }
            if (filter.lastUploadPortalId) {
                params['lastUploadPortalId'] = filter.lastUploadPortalId;
            }
            if (filter.lastCreatedStamp) {
                params['lastCreatedStamp'] = filter.lastCreatedStamp;
            }
            if (filter.lastUploadPortalName) {
                params['lastUploadPortalName'] = filter.lastUploadPortalName;
            }
            if (filter.uploadPortalTypeId) {
                params['uploadPortalTypeId'] = filter.uploadPortalTypeId;
            }
            if (filter.selectedStatuses) {
                params['statusIds'] = filter.selectedStatuses;
            }
            if (filter.searchText) {
                params['searchText'] = filter.searchText;
            }
            if (filter.limit) {
                params['limit'] = filter.limit;
            }
        }
    
        return this.apiService.get('upload-portals', params);
    }

    getUploadPortalVersions(
        uploadPortalId: string
    ): Observable<{ payload: UploadPortalVersion[] }> {
        return this.apiService.get('upload-portals/version-history', {
            uploadPortalId,
        });
    }

    getUploadPortalTypesByClientId(clientId: string) {
        return this.apiService.get(
            `upload-portal-site/activity-history/upload-portal-types`,
            { clientId }
        );
    }

    getUploadPortalTypesByUser() {
        return this.apiService.get(`upload-portal-types/user`);
    }

    getUploadPortalTypes(
        clientId: string,
        filter?: UploadPortalTypeFilter,
        limit: number = 1000
    ): Observable<{ payload: UploadPortalType[] }> {
        let params = {};
        if (limit) params = { limit };
        if (filter) {
            if (filter.columnName) {
                params['sortByColumnName'] = filter.columnName;
            }
            if (filter.sortDirection) {
                params['sortDirection'] = filter.sortDirection;
            }
            if (filter.lastCreatedStamp) {
                params['lastCreatedStamp'] = filter.lastCreatedStamp;
            }
            if (filter.lastUploadPortalTypeId) {
                params['lastUploadPortalTypeId'] = filter.lastUploadPortalTypeId;
            }
            if (filter.lastUploadPortalTypeName) {
                params['lastUploadPortalTypeName'] =
                    filter.lastUploadPortalTypeName;
            }
            if (filter.searchText) {
                params['searchText'] = filter.searchText;
            }
        }
        return this.apiService.get(`upload-portal-types`, {
            clientId,
            ...params,
        });
    }

    getUploadPortalTypesForUsers(
        clientId: string,
    ): Observable<{ payload: UploadPortalType[] }> {
        return this.apiService.get(`upload-portal-types-for-users`, {
            clientId,
            limit: 1000
        });
    }

    getUserUploadCollectionStatuses(): Observable<{
        payload: UserUploadCollectionStatus[];
    }> {
        return this.apiService.get(`upload-portals/collection-statuses/per-user`);
    }

    getUserUploadPortalUploadPortals(): Observable<{ payload: UploadPortal[] }> {
        return this.apiService.get(`upload-portal-site/upload-portals`);
    }

    getUploadPortalsByClientAndType(uploadPortalTypeId: string) {
        return this.apiService.get(
            `upload-portal-site/activity-history/upload-portals`,
            { uploadPortalTypeId }
        );
    }

    getUploadPortalUploadPortalsByType(
        uploadPortalTypeId: string
    ): Observable<{ payload: UploadPortal[] }> {
        return this.apiService.get(
            `upload-portal-site/upload-portals?type=${uploadPortalTypeId}`
        );
    }

    getUploadPortalHistoryByUser(
        uploadTypeId?: string,
        uploadPortalId?: string,
        event?: string,
        limit?: number
    ): Observable<{ payload: DataUploadHistory[] }> {
        let params = [];

        params.push('user=true');
        if (uploadTypeId) params.push('uploadTypeId=' + uploadTypeId);
        if (uploadPortalId) params.push('uploadPortalId=' + uploadPortalId);
        if (event) params.push('event=' + event);
        if (limit) params.push('limit=' + limit);

        const url =
            'upload-portal-site/upload-portals/history' +
            (params.length > 0 ? '?' + params.join('&') : '');
        return this.apiService.get(url);
    }

    getUploadPortalHistory(
        clientId?: string,
        uploadTypeId?: string,
        uploadPortalId?: string,
        event?: string,
        limit?: number
    ): Observable<{ payload: DataUploadHistory[] }> {
        let params = [];

        if (clientId) params.push('clientId=' + clientId);
        if (uploadTypeId) params.push('uploadTypeId=' + uploadTypeId);
        if (uploadPortalId) params.push('uploadPortalId=' + uploadPortalId);
        if (limit) params.push('limit=' + limit);
        if (event) params.push('event=' + event);

        const url =
            'upload-portal-site/upload-portals/history' +
            (params.length > 0 ? '?' + params.join('&') : '');
        return this.apiService.get(url);
    }

    getUserUploadPortalHistory(
        uploadPortalId?: string,
        limit?: number,
        filter?: UploadPortalHistoryFilter
    ): Observable<{ payload: DataUploadHistory[] }> {
        const params = new URLSearchParams();

        if (filter) {
            if (filter.columnName) {
                params.set('sortByColumnName', filter.columnName);
            }
            if (filter.sortDirection) {
                params.set('sortDirection', filter.sortDirection);
            }
            if (filter.lastHistoryCreatedStamp) {
                params.set('lastHistoryCreatedStamp', String(filter.lastHistoryCreatedStamp));
            }
            if (filter.lastRecordId) {
                params.set('lastRecordId', filter.lastRecordId);
            }
            if (filter.eventTypes?.length) {
                filter.eventTypes.forEach(eventType => {
                    params.append('eventTypes', eventType);
                })
            }
            
            params.set('limit', limit ? String(limit) : '100');
        }
        return this.apiService.get(
            `upload-portal-site/upload-portals/${uploadPortalId}/history?${params.toString()}`
        );
    }

    getDownloadActionsForSelectedUserPortalHistoryEntries(
        uploadPortalId: string,
        uploadHistoryIds: Array<string>
    ): Observable<{ payload: DataDownloadHistoryItemAction[] }> {
        return this.apiService.post(
            `upload-portal-site/upload-portals/${uploadPortalId}/history/download/actions`,
            uploadHistoryIds
        );
    }

    getUploadFileToken(uploadPortalId: string): Observable<any> {
        return this.apiService.get(
            `upload-portal-site/upload-portals/${uploadPortalId}/upload-file/token`
        );
    }

    completeClientFileUpload(
        uploadPortalId: string,
        fileData: any
    ): Observable<any> {
        return this.apiService.post(
            `upload-portal-site/upload-portals/${uploadPortalId}/upload-file/file`,
            fileData
        );
    }

    downloadUploadPortalFile(uploadPortalId: string): Observable<any> {
        return this.apiService.downloadFile(
            `upload-portal-site/upload-portals/${uploadPortalId}/file`
        );
    }

    uploadUploadPortalFile(uploadPortalId: string, uploadPortalFile: any) {
        const formData = new FormData();

        formData.append('uploadPortalFile', uploadPortalFile);

        const headers = new HttpHeaders();
        headers.set('Content-type', 'multipart/form-data');

        const options = {
            reportProgress: true,
            observe: 'events',
        };

        return this.apiService.request(
            'POST',
            `upload-portal-site/upload-portals/${uploadPortalId}/file`,
            {},
            formData,
            headers,
            options
        );
    }

    postUploadPortalFormData(createUploadPortalReqJson: any, uploadPortalFile: any) {
        const formData = new FormData();

        formData.append('createUploadPortalReqJson', createUploadPortalReqJson);
        formData.append('uploadPortalFile', uploadPortalFile);

        return this.apiService.postFormData('upload-portals', formData);
    }

    putUploadPortalFormData(createUploadPortalReqJson: any, uploadPortalFile?: any) {
        const formData = new FormData();

        formData.append('createUploadPortalReqJson', createUploadPortalReqJson);

        if (uploadPortalFile) {
            formData.append('uploadPortalFile', uploadPortalFile);
        }

        return this.apiService.putFormData('upload-portals', formData);
    }

    getUploadPortalStatuses(
        statusTypeId: string
    ): Observable<{ payload: UploadPortalStatus[] }> {
        return this.apiService.get('statuses', { statusTypeId });
    }

    postUploadPortalUploadStatus(
        uploadPortalId: string,
        collectionStatusId: string,
        hint: string
    ): Observable<any> {
        return this.apiService.post('upload-portals/collection-statuses', {
            uploadPortalId,
            collectionStatusId,
            hint,
        });
    }

    getUploadPortalCollectionStatusByUploadPortalId(
        uploadPortalId: string
    ): Observable<{ payload: UploadPortalCollectionStatus }> {
        return this.apiService.get('upload-portals/current-collection-status', {
            uploadPortalId,
        });
    }

    deleteUploadPortal(uploadPortalId: string): Observable<any> {
        return this.apiService.delete(`upload-portals/${uploadPortalId}`);
    }

    // NEW API
    deleteCollectionStatus(collectionStatusId: string): Observable<any> {
        return this.apiService.delete(
            `collection-status/${collectionStatusId}`
        );
    }

    deleteUploadPortalType(uploadPortalTypeId: string): Observable<any> {
        return this.apiService.delete(`upload-portal-types/${uploadPortalTypeId}`);
    }
    getAllStatuses(
        clientId: string,
        filter?: UploadPortalStatusFilter
    ): Observable<{ payload: UploadPortalStatus[] }> {
        const params = {};
        if (filter) {
            if (filter.columnName) {
                params['sortByColumnName'] = filter.columnName;
            }
            if (filter.searchText) {
                params['searchText'] = filter.searchText;
            }
            if (filter.sortDirection) {
                params['sortDirection'] = filter.sortDirection;
            }
            if (filter.lastCreatedStamp) {
                params['lastCreatedStamp'] = filter.lastCreatedStamp;
            }
            if (filter.lastCollectionStatusId) {
                params['lastCollectionStatusId'] =
                    filter.lastCollectionStatusId;
            }
            if (filter.lastCollectionStatusName) {
                params['lastCollectionStatusName'] =
                    filter.lastCollectionStatusName;
            }
            if (filter.limit) {
                params['limit'] =
                    filter.limit;
            }
        }
        return this.apiService.get('collection-statuses', {
            clientId,
            ...params,
        });
    }
    getUploadPortalType(
        uploadPortalTypeId: string
    ): Observable<{ payload: UploadPortalType }> {
        return this.apiService.get(`upload-portal-types/${uploadPortalTypeId}`);
    }

    postCollectionStatus(data: {
        collectionStatusName: string;
        statusColor: string;
        clientId: string;
    }): Observable<{ payload: UploadPortalStatus }> {
        return this.apiService.post('collection-status', data);
    }

    postUploadPortalType(
        uploadPortalTypeInput: UploadPortalTypeInput
    ): Observable<{ payload: UploadPortalType }> {
        return this.apiService.post('upload-portal-types', uploadPortalTypeInput);
    }

    putCollectionStatus(data: {
        collectionStatusId: string;
        collectionStatusName: string;
        statusColor: string;
        clientId: string;
    }): Observable<{ payload: UploadPortalStatus }> {
        return this.apiService.put('collection-status', data);
    }

    getUploadPortalStatusHistory(
        uploadPortalId: string,
        limit?: number,
        filter?: UploadPortalHistoryFilter
    ): Observable<{ payload: UploadPortalStatusVersion[] }> {
        const params = new URLSearchParams();
        params.set('uploadPortalId', uploadPortalId);

        if (filter) {
            if (filter.columnName) {
                params.set('sortByColumnName', filter.columnName);
            }
            if (filter.sortDirection) {
                params.set('sortDirection', filter.sortDirection);
            }
            if (filter.lastCollectionStatusId) {
                params.set('lastCollectionStatusId', filter.lastCollectionStatusId);
            }
            if (filter.lastCollectionStatusName) {
                params.set('lastCollectionStatusName', filter.lastCollectionStatusName);
            }
            if (filter.lastHistoryCreatedStamp) {
                params.set('lastHistoryCreatedStamp', String(filter.lastHistoryCreatedStamp));
            }
            if (filter.lastRecordId) {
                params.set('lastRecordId', filter.lastRecordId);
            }
            if (filter.eventTypes?.length) {
                filter.eventTypes?.forEach(eventType => {
                    params.append('eventTypes', eventType);
                }) 
            }
            if (limit) {
                params.set('limit', String(limit));
            }
        }

        return this.apiService.get(`upload-portals/activity-history?${params.toString()}`)
    }

    getUploadPortalAvailableStatuses(
        uploadPortalTypeId: string
    ): Observable<{ payload: UploadPortalStatus[] }> {
        return this.apiService.get(
            `upload-portal-types/${uploadPortalTypeId}/collection-statuses`
        );
    }

    putUploadPortalType(
        uploadPortalTypeInput: UploadPortalTypeInput
    ): Observable<{ payload: UploadPortalType }> {
        return this.apiService.put('upload-portal-types', uploadPortalTypeInput);
    }

    updatePageStatus(
        uploadPortalId: string,
        collectionStatusId: string
    ): Observable<any> {
        return this.apiService.post('upload-portals/collection-statuses', {
            uploadPortalId,
            collectionStatusId,
        });
    }

    patchActive(uploadPortalId: string, active: boolean): Observable<any> {
        return this.apiService.patch(
            `upload-portals/${uploadPortalId}/active/${active}`,
            {}
        );
    }

    patchAllActive(uploadPortalTypeId: string, active: boolean): Observable<any> {
        return this.apiService.patch(
            `upload-portal-types/${uploadPortalTypeId}/upload-portals/active/${active}`,
            {}
        );
    }

    getEventTypes(): Observable<{ payload: string[] }> {
        return this.apiService.get(`upload-portals/activity-history/event-types`)
    }
}
